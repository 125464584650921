import LiveProgressBarTemplate from './live-progress-bar.component.html';
import './live-progress-bar.component.scss';

/**
 * @ngdoc component
 * @name LiveProgressBarComponent
 * @module portfolium.entryEditor.entryAttachments
 * @param {Number} percentage current percentage (0-100)
 * @param {String} size pre-set dimensions of the bar (thin, thick)
 * @description Creates a bar that displays the
 *              completed percentage
 **/
export const LiveProgressBarComponent = {
    template: LiveProgressBarTemplate,
    bindings: {
        percentage: '<',
        size: '<',
    },
};
