import angular from 'angular';

import { UserCardExtraDenseComponent } from './user-card-extra-dense/user-card-extra-dense.component';

const MODULE_NAME = 'portfolium.component.userCard';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfUserCardExtraDense', UserCardExtraDenseComponent);
}());

export default MODULE_NAME;
