export const PATHWAY_REQUIREMENT_ADD_SUBMISSION  = 'PATHWAY_REQUIREMENT_ADD_SUBMISSION';
export const PATHWAY_REQUIREMENT_REMOVE_SUBMISSION = 'PATHWAY_REQUIREMENT_REMOVE_SUBMISSION';
export const PATHWAY_REQUIREMENT_ADD_FEEDBACK  = 'PATHWAY_REQUIREMENT_ADD_FEEDBACK';
export const PATHWAY_REQUIREMENT_UPDATE_ACTIONS = 'PATHWAY_REQUIREMENT_UPDATE_ACTIONS';
export const PATHWAY_REQUIREMENT_FETCH = 'PATHWAY_REQUIREMENT_FETCH';
export const PATHWAY_REQUIREMENT_FETCH_SUCCESS = 'PATHWAY_REQUIREMENT_FETCH_SUCCESS';
export const PATHWAY_REQUIREMENT_RESET = 'PATHWAY_REQUIREMENT_RESET';

export const addSubmission = submission => {
    return {
        type: PATHWAY_REQUIREMENT_ADD_SUBMISSION,
        payload: {
            submission
        }
    };
};

export const removeSubmission = () => {
    return {
        type: PATHWAY_REQUIREMENT_REMOVE_SUBMISSION,
    };
};

export const addFeedback = feedback => {
    return {
        type: PATHWAY_REQUIREMENT_ADD_FEEDBACK,
        payload: {
            feedback
        }
    };
};

export const updateActions = actions => {
    return {
        type: PATHWAY_REQUIREMENT_UPDATE_ACTIONS,
        payload: {
            actions,
        },
    };
};

/**
 * Initiates fetch requirement sets fetching to true
 * */
export const fetchRequirement = () => {
    return {
        type: PATHWAY_REQUIREMENT_FETCH,
    };
};

/**
 * Assigns and returns fetched requirement
 * */
export const onRequirementFetchSuccess = requirement => {
    return {
        type: PATHWAY_REQUIREMENT_FETCH_SUCCESS,
        payload: {
            requirement,
        },
    };
};

/**
 * Resets requirement
 * */
export const resetRequirement = () => {
    return {
        type: PATHWAY_REQUIREMENT_RESET,
    };
};
