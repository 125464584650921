import PaginationSortTemplate from './pagination-sort.component.html';

/**
 * @ngdoc controller
 * @name PaginationSortController
 * @module portfolium.component.PaginationSortComponent
 */
class PaginationSortController {
    /**
    * @ngdoc method
    * @name PaginationSortController#$constructor
    * @methodOf PaginationSortController
    * @description get the current sort status
    */
    constructor($mdDialog){
        this.$mdDialog = $mdDialog;
    }

    $onInit() {
        this.isPristine = true;
        this.selectedSort = !_.isUndefined(this.currentSort) ? this.currentSort : '';
    }

    setDirtyState() {
        if (!_.isEqual(this.selectedSort, this.currentSort)) {
            this.isPristine = false;
        } else {
            this.isPristine = true;
        }
    }

    handleApply() {
        this.onApply({ value: this.selectedSort });
        this.$mdDialog.hide();
    }

    handleCancel() {
        this.onCancel();
        this.$mdDialog.hide();
    }
}

PaginationSortController.$inject = ['$mdDialog'];

/**
 * @ngdoc component
 * @name PaginationSortComponent
 * @module portfolium.component.PaginationSortComponent
 */
export const PaginationSortComponent = {
    bindings: {
        currentSort: '<',
        sortOptions: '<',
        onApply: '&',
        onCancel: '&',
    },
    template: PaginationSortTemplate,
    controller: PaginationSortController,
    controllerAs: '$ctrl'
};
