class AddExperienceAction
{
    constructor($pfGameAction, $timeout) {
        this.$pfGameAction = $pfGameAction;
        this.$timeout = $timeout;
        this.name = 'add_more_experiences';
        this.label = 'Add work experience';
    }

    execute(next) {
        return this.$pfGameAction.openPanel('experience', next);
    }
}

AddExperienceAction.$inject = ['$pfGameAction', '$timeout'];

export default AddExperienceAction;
