import NavbarEmployerSwitchDropdownTemplate from './navbar-employer-switch-dropdown.component.html';
import './navbar-employer-switch-dropdown.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.navbar
 * @name NavbarEmployerSwitchDropdownController
 */
class NavbarEmployerSwitchDropdownController {
    constructor($pfAppSwitcherAuth) {
        this.$pfAppSwitcherAuth = $pfAppSwitcherAuth;
    }

    /**
     * Is the user type faculty
     */
    get isFaculty() {
        return this.$pfAppSwitcherAuth.isFaculty;
    }

    /**
     * Does the user have access to Canvas
     */
    get hasEduAccess() {
        return this.$pfAppSwitcherAuth.hasEduAccess;
    }

    /**
     * Redirects to Canvas
     */
    goToEduSso() {
        this.$pfAppSwitcherAuth.goToEduFromTalentMatch();
    }

    /**
     * Redirects to Web App
     */
    goToWebSso() {
        this.$pfAppSwitcherAuth.goToWebFromTalentMatch();
    }
}

NavbarEmployerSwitchDropdownController.$inject = [
    '$pfAppSwitcherAuth',
];

/**
 * @ngdoc component
 * @module portfolium.component.navbar
 * @name pfNavbarEmployerSwitchDropdown
 */
export const NavbarEmployerSwitchDropdownComponent = {
    controller: NavbarEmployerSwitchDropdownController,
    controllerAs: '$ctrl',
    template: NavbarEmployerSwitchDropdownTemplate,
};
