/**
 * Add target="_self" to all ui-sref links on PHP pages to make sure a page
 * reload happens instead of trying to transition to a state that doesn't exist
 */
export const srefDirective = ($state) => {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            let stateName = attrs.pfSref;

            if (!$state.get(stateName)) {
                elem.attr('target', '_self');
            }
        },
    };
};

srefDirective.$inject = ['$state'];
