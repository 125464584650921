export class YoutubeService {
    constructor($injector, $pfUrlUtils, $pfEnv) {
        this.$injector = $injector;
        this.$pfUrlUtils = $pfUrlUtils;
        this.$pfEnv = $pfEnv;

        // get the url for the origin
        const {url} = $pfEnv;

        this.provider = 'youtube';
        // these params will get added to the embed URL
        this.urlParams = {
            enablejsapi: 1,
            showinfo: 0,
            rel: 0,
            origin: url,
        };
    }

    /**
     * Get the URL used to embed the media
     * @param [String]          url        The media URL
     * @return [String]             The url used for embedded media
     */
    getEmbedUrl(url) {
        // MediaElement framework validates youtube urls
        return this.$pfUrlUtils.enforceHttps(url);
    }
}

YoutubeService.$inject = [
    '$injector',
    '$pfUrlUtils',
    '$pfEnv',
];
