import _ from 'lodash';
import {
    PATHWAY_BADGE_FETCH,
    PATHWAY_BADGE_FETCH_SUCCESS,
    PATHWAY_BADGE_RESET,
} from './badge-details.actions';

const initialState = {
    // TODO - this is based on mock data. This needs to be updated
    // when we have real data
    badge: {
        badge: {},
        issuer: {},
        recipient: {},
        earning_path: {},
        skills: [],
        evidence: [],
    },
    fetching: false,
};

export const currentPathwayBadge = (state = initialState, action = {}) => {
    switch (action.type) {
        case PATHWAY_BADGE_FETCH:
            return _.assign({}, state, {
                fetching: true,
            });
        case PATHWAY_BADGE_FETCH_SUCCESS:
            const {badge} = action.payload;
            return _.assign({}, state, {
                badge,
                fetching: false,
            });
        case PATHWAY_BADGE_RESET:
            return initialState;
        default:
            return state;
    }
};
