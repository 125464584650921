import angular from 'angular';

import { BadgeCardComponent } from './badge-card.component';

import './badge-card.component.scss';

const MODULE_NAME = 'portfolium.component.badgeCard';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfBadgeCard', BadgeCardComponent);
}());

export default MODULE_NAME;
