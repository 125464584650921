import {UnauthedJobApplyModalController} from '../controller/external-job-apply-modal.controller';
import {IntrestedModalController} from '../controller/intrested-modal.controller';
import ExternalAtsApplyModalTemplate from '../../template/external-ats-apply-modal.html'
import InterestedModalTemplate from '../../template/interested-modal.html'
import InternalAtsApplyModalTemplate from '../../template/internal-ats-apply-modal.html'

export class JobApplyService {
    constructor($q, $http, $window, $mdDialog, $mdMedia, $pfJobs, $pfUrlUtils,) {
        this.$q = $q;
        this.$http = $http;
        this.$window = $window;
        this.$mdDialog = $mdDialog;
        this.$mdMedia = $mdMedia;
        this.$pfJobs = $pfJobs;
        this.$pfUrlUtils = $pfUrlUtils;
    }

    /**
     * @ngdoc function
     * @description Show the intrested modal
     * @param  {Object} job the Job object
     * @param  {event}  $event Target event
     * @return {Promise}
     */
    showIntrestedModal(job, $event) {
        this.$mdDialog.show({
            targetEvent: $event,
            template: InterestedModalTemplate,
            fullscreen: !this.$mdMedia('gt-xs'),
            clickOutsideToClose: true,
            controller: IntrestedModalController,
            controllerAs: '$ctrl',
            bindToController: true,
            locals: {
                job: job,
            }
        });
    }

    /**
     * Open a modal for external job seekers to apply to a job without
     * first completing onboarding. A new user account will be created before
     * submitting the application and redirecting.
     * @param  {Event}   $event   ngClick event
     * @param  {Object}  job      Job entity with id/url fields required
     * @param  {Object}  locals   Properties to set on the modal controller
     * @return {Promise}          Resolved once modal is closed
     */
    unauthedJobApplyModal($event, job, locals = {}) {
        let template;
        const isExternalAts = (!_.isEmpty(job.url));

        if (isExternalAts) {
            template = ExternalAtsApplyModalTemplate;
        } else {
            template = InternalAtsApplyModalTemplate;
        }

        const modalConfig = {
            targetEvent: $event,
            template,
            controller: UnauthedJobApplyModalController,
            controllerAs: '$ctrl',
            locals: _.assign({}, locals, {
                isExternalAts,
            }),
            bindToController: true,
            fullscreen: !this.$mdMedia('gt-xs'),
        };

        // Show the modal
        return this.$mdDialog.show(modalConfig).then((auth) => {
            // Account has been created -- apply to the job
            return this.$pfJobs.applyByJobId(job.id)
                /**
                 * Fail silently if apply API request fails -- redirect the user
                 * anyways
                 */
                .finally(() => {
                    if (isExternalAts) {
                        // Redirect to the external URL
                        const url = this.$pfUrlUtils.addParamsToUrl(job.url, {src: 'portfolium'});
                        return this.$window.location.assign(url);
                    }

                    // Redirect back to the job
                    return this.$window.location.assign('/j/' + job.id);
                });
        });
    }

    /**
     * @ngdoc function
     * @description Show the apply modal
     * @param  {String} modalData the ajax data to populate the modal
     * @param  {event}  $event Target event
     * @return {Promise}
     */
    showApplyModal(modalData, $event) {
        this.$mdDialog.show({
            targetEvent: $event,
            template: modalData,
            fullscreen: !this.$mdMedia('gt-xs'),
            clickOutsideToClose: true,
        });
    }

    /**
     * @ngdoc function
     * @description Apply to an external job
     * @param {Object}  job     The job record
     * @param {Boolean} saveJob Save the job?
     * @return {Promise}
     */
    applyOutsideLink(job, saveJob) {
        if (_.isEmpty(job)) {
            return this.$q.reject();
        }

        if (saveJob) {
            this.$pfJobs.bookmarkJob(job.id);
        }

        return this.$pfJobs.applyByJobId(job.id)
            .then(() => {
                const url = this.$pfUrlUtils.addParamsToUrl(job.url, {src: 'portfolium'});
                return this.$window.location.assign(url);
            });
    }
}

JobApplyService.$inject = [
    '$q',
    '$http',
    '$window',
    '$mdDialog',
    '$mdMedia',
    '$pfJobs',
    '$pfUrlUtils',
];
