import StandardEntryCardController from '../controller/standard-entry-card-controller.js';

function entryCardDirective($templateCache, imageFilter) {
    let templateBase = 'template/components/entry-card/template/card';

    return {
        restrict: 'E',
        scope: {
            entry: '=pfEntry',
            showStrengthMeter: '=pfStrengthMeter',
            isEditable: '=pfEditable',
            onDeleteEvent: '&pfOnDelete',
        },
        bindToController: true,
        link: function(scope, elem, attrs, entryCardCtrl) {
            if (!_.isObject(entryCardCtrl.entry)) {
                throw new Error('[pfEntryCard] You must provide a project!');
            }

            elem.attr('tabindex', -1);
        },
        controller: StandardEntryCardController,
        controllerAs: 'entryCardCtrl',
        template: function(elem, attrs) {
            let template = $templateCache.get(`${templateBase}/standard.html`);

            if (_.isString(attrs.pfCardType)) {
                template = $templateCache.get(
                    `${templateBase}/${attrs.pfCardType}.html`,
                );
            }

            if (!template) {
                throw new Error('[pfEntryCard] Template not found!');
            }

            return template;
        },
    };
}

entryCardDirective.$inject = ['$templateCache', 'imageFilter'];

export default entryCardDirective;
