import moment from 'moment';
import BirthdayTemplate from './birthday.component.html';
import './birthday.component.scss';

/**
 * @ngdoc controller
 * @name BirthdayController
 * @module portfolium.component.forms.formComponents
 */
class BirthdayController {
    $onInit() {
        if (_.isNil(this.id)) {
            this.id = 'pf-birthday';
        }

        // default allowEmpty flag
        if (_.isNil(this.allowEmpty)) {
            this.allowEmpty = false;
        }

        let month = '';
        let day = '';
        let year = '';

        if (!_.isEmpty(this.defaultBirthday)) {
            const defaultBirthday = moment(this.defaultBirthday, 'YYYY-MM-DD');
            month = defaultBirthday.month() + 1;
            day = defaultBirthday.date();
            year = defaultBirthday.year();
        }

        // default birthday
        this.birthday = {
            month,
            day,
            year,
        };
        // get default days list
        this.days = this.getDays();
        // get an array with all months names and value in year
        this.months = _.map(moment.months(), (month, index) => {
            return {
                id: index + 1,
                name: month,
            }
        });
        // get current year
        this.currentYear = moment().year();
    }

    getDays() {
        // get selected year and month
        const {
            year: selectedYear,
            month: selectedMonth,
        } = this.birthday;
        // get selected year or current year
        const year = selectedYear || moment().year();
        // get selected month or current month
        const month = selectedMonth || moment().month();
        // get days in month for month and year combination
        const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
        // return an array containing the all day numbers for a month and a year
        return _.map([...Array(daysInMonth).keys()], index => { return index + 1; });
    }

    dateUpdated() {
        // update days list
        this.days = this.getDays();
        // get selected data
        const {
            year,
            day,
            month,
        } = this.birthday;

        const selectedDay = _.padStart(day, 2, '0');
        const selectedMonth = _.padStart(month, 2, '0');
        const selectedYear = _.padStart(year, 4, '0');
        const birthday = `${selectedMonth}/${selectedDay}/${selectedYear}`;

        // trigger change
        this.onChange({
            birthday,
        });

        // if user wants to be notified when someones tries to be underage
        if (this.onUnderage) {
            // get today as moment date
            const currentDate = moment();
            // get birthday as moment date
            const birthdayDate = moment(birthday, 'MM/DD/YYYY');
            // get difference in years
            const yearsDiff = currentDate.diff(birthdayDate, 'years');
            this.onUnderage({ isUnderage: yearsDiff < 13 });
        }
    }
}

/**
 * @ngdoc component
 * @name BirthdayComponent
 * @module portfolium.components.forms.formComponents
 * @param function onChange  to be trigger when date
        changes, it will send a formatted
        string as `birthday` as MM/DD/YYYY
 * @param String [defaultBirthday] birthday to pre fill
        in the component and the format has to be YYYY-MM-DD
 *
 **/
export const BirthdayComponent = {
    controller: BirthdayController,
    bindings: {
        id: '@pfId',
        inputClass: '@',
        allowEmpty: '<',
        onChange: '&',
        onUnderage: '&',
        defaultBirthday: '<',
    },
    template: BirthdayTemplate,
}
