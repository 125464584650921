class SocialLinkController
{
    constructor($pfToast, $pfSocialAuth, $filter) {
        this.$pfToast = $pfToast;
        this.$pfSocialAuth = $pfSocialAuth;
        this.$filter = $filter;
        this.disabled = false;
    }

    buttonText() {
        return this.linked ? this.$filter('i18n')('Disconnect') : this.$filter('i18n')('Connect');
    }

    /**
     * Toggle between linking and unlinking, based on current state
     * @return {Promise}
     */
    toggle() {
        if (this.linked) {
            return this.unlink();
        }

        return this.link();
    }

    /**
     * Link a social account
     * @return {Promise}
     */
    link() {
        return this.socialAuthClient.login({}, true)
            .then(() => {
                this.linked = true;
                if (this.disableOnLink === true) {
                    this.disabled = true;
                }
                this.$pfToast.success('Application connected');
            }, ({ error: { code } }) => {
                if (code !== 'cancelled') {
                    this.$pfToast.error('Something went wrong, please try again');
                }
            });
    }

    /**
     * Unlink a social account
     * @return {Promise}
     */
    unlink() {
        return this.socialAuthClient.login()
            .then(() => {
                this._logout();
            }, () => {
                this._logout();
            });
    }

    /**
     * Private logout method
     * @return {Promise}
     */
    _logout() {
        return this.socialAuthClient.logout()
            .then(() => {
                return this.$pfSocialAuth.removeSpoke(this.client)
                    .then(() => {
                        this.linked = false;
                        this.$pfToast.success('Application disconnected');
                    });
            }, () => {
                this.$pfToast.error('Something went wrong, please try again');
            });
    }
}

SocialLinkController.$inject = ['$pfToast', '$pfSocialAuth', '$filter'];

function socialLinkDirective($pfSocialAuth, $pfAuthProvider) {
    return {
        restrict: 'E',
        scope: {
            'client': '@pfSocialAuthClient',
            'trackingSource': '@pfTrackingSource',
        },
        controller: SocialLinkController,
        controllerAs: '$ctrl',
        bindToController: true,
        link: function (scope, elem, attrs) {
            let {$ctrl} = scope;
            let client = attrs.pfSocialAuthClient;
            let socialAuthClient = $pfSocialAuth[client];

            // if client is not found in social auth, check import auth provider
            if (!socialAuthClient) {
                socialAuthClient = $pfAuthProvider.get(client);
            }

            if (!socialAuthClient) {
                throw new Error(`No social auth client found for '${client}'`);
            }

            $ctrl.linked = socialAuthClient.hasSpoke();
            $ctrl.socialAuthClient = socialAuthClient;
            $ctrl.import = scope.$eval(attrs.pfSocialAuthImport) === true;
            $ctrl.disableOnLink = scope.$eval(attrs.pfDisableOnLink) === true;
            $ctrl.clientName = $ctrl.client == 'badgr' ? 'Canvas Credentials' : $ctrl.client;

            if ($ctrl.linked && $ctrl.disableOnLink === true) {
                $ctrl.disabled = true;
            }
        },
        template: `
            <md-button md-no-ink
                 type="button"
                 ng-show="!!$ctrl.client"
                 ng-class="{ 'md-primary': !$ctrl.linked }"
                 ng-click="$ctrl.toggle()"
                 ng-disabled="$ctrl.disabled"
                 layout="row"
                 layout-align="center"
                 aria-label="{{ $ctrl.buttonText() }} {{ ::$ctrl.clientName }}">
                {{ $ctrl.buttonText() }}
            </md-button>
        `
    };
}

socialLinkDirective.$inject = ['$pfSocialAuth', '$pfAuthProvider', '$filter'];

export default socialLinkDirective;
