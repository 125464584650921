import { BadgrSource } from '../../../../settings/settings-import-data/data-importer/badgr/badgr-source';
import { RedirectAuthModalController } from "../redirect-oauth/badgr/redirect-auth-modal.controller";
import RedirectAuthModal from '../redirect-oauth/badgr/redirect-auth-modal.html'

/**
 * @implements AuthProvider
 * @name BadgrAuthProvider
 * @description
 * Authenticate with Badgr
 */
export class BadgrAuthProvider {
    constructor(
        $pfUser,
        $q,
        $mdDialog,
        $http,
        $pfSocialAuth
    ) {
        this.$pfUser = $pfUser;
        this.$q = $q;
        this.$mdDialog = $mdDialog;
        this.$http = $http;
        this.$pfSocialAuth = $pfSocialAuth;
        // needed for auth provider identification
        this.name = 'badgr';
    }

    /**
     * Authenticate the user via Badgr
     * @return {Promise} Resolved after user has authenticated and spoke has been created
     */
    login() {
        return this.$q((resolve, reject) => {
            if (this.hasSpoke()) {
                return resolve();
            }
            this.$mdDialog.show({
                template: RedirectAuthModal,
                controller: RedirectAuthModalController,
                controllerAs: '$ctrl',
                locals: {
                    provider: BadgrSource,
                },
                clickOutsideToClose: true,
            }).then(({ loggedIn }) => {
                if (loggedIn) {
                    return resolve();
                }
                return reject();
            });
        });
    }

    /**
     * Log out
     * @return {Promise} Resolved after user has logged out of the service
     */
    logout() {
        /**
         * Nothing to log out of so resolve the promise to continue the unlink flow
         * Deleting the spoke is the next step in the flow
         */
        return this.$q.resolve();
    }

    /**
     * Login to badgr
     * @param  {Object} data Form data to login to badgr
     * @return {Promise}
     */
    storeToken(data) {
        return this.$http({
            method: 'POST',
            url: '/proxy/badgr/auth',
            data
        })
        .then(() => {
            // Update the user's CI session
            return this.$pfUser.updateSession();
        });
    }

    /**
     * Does the user have access
     * @return {boolean} Does the current user have access to this provider?
     */
    hasAccess() {
        return true;
    }

    /**
     * Has the user already authenticated with Badgr?
     * @return {boolean}
     */
    hasSpoke() {
        const { spokes } = this.$pfUser.getUser();

        return spokes.badgr === true;
    }
}

BadgrAuthProvider.$inject = [
    '$pfUser',
    '$q',
    '$mdDialog',
    '$http',
    '$pfSocialAuth',
];
