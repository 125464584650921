import angular from 'angular';

import {IdentifyMediaService} from './js/service/identify-media.service';
import {YoutubeService} from './js/service/youtube.service';
import {VimeoService} from './js/service/vimeo.service';
import {SoundcloudService} from './js/service/soundcloud.service';

import './index.scss';

const MODULE_NAME = 'portfolium.component.mediaEmbed';

(function () {
    angular.module(MODULE_NAME, [])
        // Services
        .service('$pfIdentifyMedia', IdentifyMediaService)
        .service('$pfYoutube', YoutubeService)
        .service('$pfVimeo', VimeoService)
        .service('$pfSoundcloud', SoundcloudService);
}());

export default MODULE_NAME;
