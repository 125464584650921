import angular from 'angular';

import { SearchInputComponent } from './search-input/search-input.component';
import { CardCheckboxComponent } from './card-checkbox/card-checkbox.component';
import { BirthdayComponent } from './birthday/birthday.component';
import { FieldLabelComponent } from './field-label/field-label.component';
import { FieldErrorComponent } from './field-error/field-error.component';
import { FieldHelpTextComponent } from './field-help-text/field-help-text.component';

const MODULE_NAME = 'portfolium.component.forms.formComponents';

(function () {
    angular.module(MODULE_NAME, [])
        .component('pfBirthday', BirthdayComponent)
        .component('pfSearchInput', SearchInputComponent)
        .component('pfCardCheckbox', CardCheckboxComponent)
        .component('pfFieldLabel', FieldLabelComponent)
        .component('pfFieldError', FieldErrorComponent)
        .component('pfFieldHelpText', FieldHelpTextComponent);
}());

export default MODULE_NAME;
