// TODO: kill supportedMimetypes

/**
 * Portfolium supported mimetypes
 *  organized by types ( word, powerpoint, excel, pdf, design and audio)
 * @type {Array}
 */
const supportedMimetypes = [
    {
        type: 'word',
        name: 'word',
        mimetypes: [
            'application/msword',
            'application/vnd.ms-word.document.macroenabled.12',
            'application/vnd.ms-word.template.macroenabled.12',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.text-template',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
            'application/x-iwork-pages-sffpages',
            'text/plain',
        ]
    },
    {
        type: 'powerpoint',
        name: 'powerpoint',
        mimetypes: [
            'application/vnd.ms-powerpoint',
            'application/vnd.ms-powerpoint.addin.macroenabled.12',
            'application/vnd.ms-powerpoint.presentation.macroenabled.12',
            'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
            'application/vnd.ms-powerpoint.template.macroenabled.12',
            'application/vnd.oasis.opendocument.presentation',
            'application/vnd.oasis.opendocument.presentation-template',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
            'application/vnd.openxmlformats-officedocument.presentationml.template',
            'application/x-iwork-keynote-sffkey',
        ]
    },
    {
        type: 'excel',
        name: 'excel',
        mimetypes: [
            'application/vnd.ms-excel',
            'application/vnd.ms-excel.addin.macroenabled.12',
            'application/vnd.ms-excel.sheet.binary.macroenabled.12',
            'application/vnd.ms-excel.sheet.macroenabled.12',
            'application/vnd.ms-excel.template.macroenabled.12',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.spreadsheet-template',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
            'application/x-iwork-keynote-sffnumbers',
        ]
    },
    {
        type: 'design',
        name: 'design',
        mimetypes: [
            'application/illustrator',
            'application/photoshop',
            'application/postscript',
            'application/psd',
            'application/tif',
            'application/tiff',
            'application/x-photoshop',
            'application/x-tif',
            'application/x-tiff',
            'image/photoshop',
            'image/psd',
            'image/tif',
            'image/tiff',
            'image/vnd.adobe.photoshop',
            'image/x-photoshop',
            'image/x-psd',
            'image/x-tif',
            'image/x-tiff',
            'zz-application/zz-winassoc-psd',
        ]
    },
    {
        type: 'audio',
        name: 'audio',
        mimetypes: [
            'audio/aac',
            'audio/aiff',
            'audio/basic',
            'audio/flac',
            'audio/l24',
            'audio/mid',
            'audio/mp3',
            'audio/mp4',
            'audio/mpeg',
            'audio/mpeg3',
            'audio/mpegurl',
            'audio/mpg',
            'audio/ogg',
            'audio/s-wav',
            'audio/vnd.wav',
            'audio/wav',
            'audio/wave',
            'audio/x-aac',
            'audio/x-aiff',
            'audio/x-flac',
            'audio/x-mp3',
            'audio/x-mp4',
            'audio/x-mpeg',
            'audio/x-mpg',
            'audio/x-wav',
            'audio/x-pn-wav',
        ]
    },
    {
        type: 'image',
        name: 'image',
        mimetypes: [
            'image/*',
        ]
    },
    {
        type: 'pdf',
        name: 'pdf',
        mimetypes: [
            'application/pdf',
        ]
    }
];

// TODO: move these into a media slider const.

/**
 * extensions and mimetypes for google frivefiles
 * @type {Array}
 */
const googleDocsTypes = [
    {
        mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        extension: 'xlsx',
    },
    {
        mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        extension: 'docx',
    },
    {
        mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        extension: 'pptx',
    },
];

/**
 * Extensions that FileStack support
 * when creating iFrame
 * https://www.filestack.com/docs/file-viewer/fs-v2
 * @type {Array}
 */
const iFrameSupportedExtensions = [
    'ai',
    'doc',
    'docx',
    'odp',
    'odt',
    'pdf',
    'ppt',
    'pptx',
    'psd',
    'tif',
    'tiff',
    'txt',
    'xls',
    'xlsx',
];

/**
 * Extensions that FileStack support
 * when creating preview
 * https://www.filestack.com/docs/document-transformations
 * @type {Array}
 */
const imagePreview = [
    'ai',
    'bmp',
    'doc',
    'docx',
    'html',
    'odp',
    'ods',
    'odt',
    'pdf',
    'ppt',
    'pptx',
    'psd',
    'tif',
    'tiff',
    'txt',
    'webp',
    'xls',
    'xlsx',
];

/**
 * Extensions that html img tag will
 * display with no problem
 * @type {Array}
 */
const imgHTMLSupportedExtensions = [
    'apng',
    'gif',
    'ico',
    'jpeg',
    'jpg',
    'png',
    //'svg',
];

/**
 * Providers that are supported in the
 * media slider audio/video iframe player
 * @type {Object}
 */
const supportedMediaProviders = {
    YOUTUBE: {
        name: 'youtube',
        urlValidators: [
            'youtube.com',
            'youtu.be',
        ],
        urlPatterns: [
            /youtube.com\/watch\?v=([a-zA-Z0-9_\-]+)/,
            /youtu.be\/([a-zA-Z0-9_\-]+)/,
            /youtube.com\/embed\/([a-zA-Z0-9_\-]+)/,
        ],
        embedUrl: 'https://www.youtube.com/embed/$1',
    },
    VIMEO: {
        name: 'vimeo',
        urlValidators: [
            'vimeo.com',
        ],
        urlPatterns: [
            /vimeo.com\/([0-9]+)/,
            /player.vimeo.com\/video\/([0-9]+)/,
            /vimeo.com\/channels\/[a-zA-Z0-9_\-]+\/([0-9]+)/,
        ],
        embedUrl: 'https://player.vimeo.com/video/$1',
    },
    SOUNDCLOUD: {
        name: 'soundcloud',
        urlValidators: [
            'soundcloud.com',
        ],
        urlPatterns: [
            /soundcloud.com\/[a-zA-Z0-9_\-]+\/[a-zA-Z0-9_\-]+/,
        ],
        embedUrl: 'https://w.soundcloud.com/player/?url=',
        embedUrlCreateMethod: 'append',
        urlParams: {
            auto_play: false,
            hide_related: true,
            show_comments: false,
            show_user: true,
            show_reposts: false,
            visual: true,
        },
    },
    PREZI_NEXT: {
        name: 'prezi',
        urlValidators: [
            'prezi.com',
        ],
        urlPatterns: [
            /prezi.com\/view\/([a-zA-Z0-9_\-]+)/,
        ],
        embedUrl: 'https://prezi.com/view/$1/embed',
    },
    PREZI: {
        name: 'prezi',
        urlValidators: [
            'prezi.com',
        ],
        urlPatterns: [
            /prezi.com\/p\/([a-zA-Z0-9_\-]+)/,
            /prezi.com\/embed\/([a-zA-Z0-9_\-]+)/,
            /prezi.com\/([a-zA-Z0-9_\-]+)\/[a-zA-Z0-9_\-]+/,
            /prezi.com\/([a-zA-Z0-9_\-]+)/,
        ],
        embedUrl: 'https://prezi.com/embed/$1',
    },
    SWAY: {
        name: 'sway',
        urlValidators: [
            'sway.com',
        ],
        urlPatterns: [
            /sway.com\/([a-zA-Z0-9_\-]+)/,
            /sway.com\/s\/([a-zA-Z0-9_\-]+)\/embed/,
        ],
        embedUrl: 'https://sway.com/s/$1/embed',
    },
    SKETCHFAB: {
        name: 'sketchfab',
        urlValidators: [
            'sketchfab.com',
        ],
        urlPatterns: [
            /sketchfab.com\/models\/([a-zA-Z0-9_\-]+)/,
            /sketchfab.com\/models\/([a-zA-Z0-9_\-]+)\/embed/,
        ],
        embedUrl: 'https://sketchfab.com/models/$1/embed',
    },
};

/**
 * Providers that are supported in the
 * attachment viewer audio/video iframe player
 * @type {Array}
 */
const attachmentViewerSupportedAudioVideo = [
    'youtube',
    'vimeo',
    'soundcloud',
];

/**
 * Audio MIME types that are supported in the
 * attachment viewer audio media player
 * @type {Array}
 */
const attachmentViewerSupportedAudioTypes = [
    'audio/aac',
    'audio/mp3',
    'audio/mpeg',
    'audio/mpeg3',
    'audio/x-aac',
    'audio/x-mp3',
    'audio/x-mpeg',
    'audio/x-mpg',
];

export const MEDIA_TYPES = {
    supportedMimetypes,
    iFrameSupportedExtensions,
    imagePreview,
    googleDocsTypes,
    imgHTMLSupportedExtensions,
    supportedMediaProviders,
    attachmentViewerSupportedAudioVideo,
    attachmentViewerSupportedAudioTypes,
}
