/**
 * @ngdoc filter
 * @name googleMapsAddressFilter
 * @module portfolium.core.filters
 * @description
 * Filter to display correctly the data sent from the Google API
 **/
 export const googleMapsAddressFilter = () => {
    return function (address, mode) {
        if (!address) {
            return '';
        }

        // Check the mode and return the desired string
        if (mode === 'street') {
            let filteredAddress = address.premise_number ? address.premise_number : '';
            filteredAddress += address.post_box_number ? ' ' + address.post_box_number : '';
            filteredAddress += address.thoroughfare_name ? ' ' + address.thoroughfare_name : '';
            filteredAddress += address.thoroughfare_trailing_type ? ' ' + address.thoroughfare_trailing_type : '';
            return filteredAddress;
        }

        if (mode === 'street_extra') {
            let filteredAddress = address.sub_building_type ? address.sub_building_type : '';
            filteredAddress += address.sub_building_number ? ' ' + address.sub_building_number : '';
            return filteredAddress;
        }

        if (mode === 'city') {
            let filteredAddress = address.locality ? address.locality : '';
            return filteredAddress;
        }

        if (mode === 'state') {
            let filteredAddress = address.administrative_area ? address.administrative_area : '';
            return filteredAddress;
        }

        if (mode === 'zipcode') {
            let filteredAddress = address.postal_code_short ? address.postal_code_short : '';
            return filteredAddress;
        }

        if (mode === 'city_state') {
            let filteredAddress = [];
            // city
            if (!_.isEmpty(address.locality)) {
                filteredAddress.push(address.locality);
            }
            // state
            if (!_.isEmpty(address.administrative_area)) {
                filteredAddress.push(address.administrative_area);
            }
            if (filteredAddress.length > 1) {
                filteredAddress = [filteredAddress.join(', ')];
            }
            return filteredAddress.join(' ');
        }

        if (mode === 'extra') {
            let filteredAddress = address.localty ? address.localty : '';
            filteredAddress += address.administrative_area ? ', ' + address.administrative_area : '';
            filteredAddress += address.postal_code_short ? ' ' + address.postal_code_short : '';
            return filteredAddress;
        }

        if (mode === 'country') {
            let filteredAddress = address.country_iso_3 ? address.country_iso_3 : '';
            return filteredAddress;
        }

        if (address.thoroughfare_name) {
            let filteredAddress = address.premise_number ? address.premise_number : '';
            filteredAddress += address.post_box_number ? ' ' + address.post_box_number : '';
            filteredAddress += address.thoroughfare_name ? ' ' + address.thoroughfare_name : '';
            filteredAddress += address.thoroughfare_trailing_type ? ' ' + address.thoroughfare_trailing_type : '';
            filteredAddress += address.sub_building_type ? '. ' + address.sub_building_type : '';
            filteredAddress += address.sub_building_number ? ' ' + address.sub_building_number : '';
            return filteredAddress;
        }

        return null;
    };
}

export default googleMapsAddressFilter;
