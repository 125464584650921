function followingChipDirective() {
    return {
        restrict: 'EA',
        link: function (scope, elem, attrs) {
            let myChip = elem.parent().parent();
            myChip.addClass('pf-following');

            scope.$watch(function() {
                return scope.$chip.following;
            }, function(newVal) {
                if (newVal) {
                    myChip.addClass('pf-following');
                } else {
                    myChip.removeClass('pf-following');
                }
            });
        }
    };
}

followingChipDirective.$inject = [];

export default followingChipDirective;
