/**
 * @ngdoc service
 * @name AssignmentsService
 * @module portfolium.assignments
 **/
 export class AssignmentsService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * Get current user assignments by assignmentId
     * @param  {Object} query
     * @param  {Number} [query.limit] limit of results
     * @param  {Number} [query.offset] offset of results
     * @param  {String} [query.status] either completed or a assigned
     * @return {Promise}
     */
    getMyAssignments(query = { limit, offset, status }) {
        return this.$http({
            method: 'GET',
            url: `/proxy/assignments/me`,
            params: query,
        }).then(response => response.data);
    }

    /**
     * Get an assignment by assignmentId
     * @param  {Number} assignmentId Assignment ID
     * @param  {object} params
     * @return {Promise}
     */
    getAssignment(assignmentId, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/assignments/assignment/${assignmentId}`,
            params,
        }).then(response => response.data);
    }

    /**
     * Mark an assignment entry as submitted
     * @param  {Number} assignmentId Assignment ID
     * @return {Promise}
     */
    submitAssignment(assignmentId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/assignments/submit/${assignmentId}`
        }).then(response => response.data);
    }

    /**
     * Mark an assignment entry as unsubmitted
     * @param  {Number} assignmentId Assignment ID
     * @return {Promise}
     */
    unSubmitAssignment(assignmentId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/assignments/unsubmit/${assignmentId}`
        }).then(response => response.data);
    }
}

AssignmentsService.$inject = [
    '$http',
];
