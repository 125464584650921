import {updateUnreadNotifications} from '../action/notification-center-notifications';

import {updateUnreadMessages} from '../action/notification-center-messages';

export class NotificationCenterService {
    constructor($http, $ngRedux, $timeout, $pfCookies, $pfEnv, isDefaultImageFilter, PF_COOKIES) {
        this._$http = $http;
        this._$ngRedux = $ngRedux;
        this._$timeout = $timeout;
        this._$pfCookies = $pfCookies;
        this._$pfEnv = $pfEnv;
        this.PF_COOKIES = PF_COOKIES;
        this._isDefaultImageFilter = isDefaultImageFilter;

        $ngRedux.connect(this._mapStateToThis)(this);
    }

    _mapStateToThis(state) {
        return {
            messageLimit: state.notificationCenter.notificationCenterMessages.limit,
            messageOffset: state.notificationCenter.notificationCenterMessages.offset,
            notificationLimit: state.notificationCenter.notificationCenterNotifications.limit,
            notificationOffset: state.notificationCenter.notificationCenterNotifications.offset,
        };
    }

    /**
     * Gets the number of unread notifications/messages
     * @return {Object}         [unread count]
     */
    getUnreadCount() {
        return this._$http({
            method: 'GET',
            url: '/proxy/notifications/counts',
            params: {
                preventCache: new Date().getTime()
            }
        }).then((unreadCount)=>{
            return unreadCount.data;
        });
    }

    /**
     * Get users notifications with the passed in parameters, assigns group based on type, and sets image url
     * @return {Array}         Notifications
     */
    getNotifications() {
        return this._$http({
            method: 'GET',
            url: `proxy/notifications/feed`,
            params: {
                preventCache: new Date().getTime(),
                limit: this.notificationLimit,
                offset: this.notificationOffset,
            }
        }).then((notifications) => {
            // Return notifications
            return notifications.data;
        });
    }

    /**
     * Get users messages with the passed in parameters
     * @return {Array}         Messages
     */
    getMessages(limit, offset) {
        return this._$http({
            method: 'GET',
            url: `proxy/messages/threads`,
            params: {
                preventCache: new Date().getTime(),
                limit: this.messageLimit,
                offset: this.messageOffset,
            }
        }).then((messages) => {
            // Set messages
            return messages.data;
        });
    }

    /**
     * Marks passed in notification ID as read
     * @param  {Object} notificationId  ID of the notification to mark as read
     * @return {Promise}
     */
    markNotificationAsRead(notificationId) {
        return this._$http({
            method: 'POST',
            url: `proxy/notifications/seen/${notificationId}`
        }).then((response) => {
            return response;
        });
    }

    /**
     * Marks all notifications as read
     * @return {Promise}
     */
    markAllNotificationsAsRead() {
        return this._$http({
            method: 'POST',
            url: `proxy/notifications/all`
        }).then((response) => {
            return response;
        });
    }

    /**
    * Store notification center count in cookie/reducer
    * @param {Object} unreadCount Number of unread notifications and messages
    */
    storeNotificationCenterCount(unreadCount) {
        // Store notification count in cookie for a cleaner transition between PHP and Angular pages
        this._$pfCookies.put(
            this.PF_COOKIES.JEWEL_COUNT,
            JSON.stringify({
                notifications: unreadCount.notifications,
                messages: unreadCount.messages,
            }),
            {
                path: '/',
                domain: this._$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
            }
        );

        // Update reducer store
        this._$ngRedux.dispatch(updateUnreadNotifications(unreadCount.notifications));
        this._$ngRedux.dispatch(updateUnreadMessages(unreadCount.messages));
    }
}

NotificationCenterService.$inject = [
    '$http',
    '$ngRedux',
    '$timeout',
    '$pfCookies',
    '$pfEnv',
    'isDefaultImageFilter',
    'PF_COOKIES',
];
