export class EmailService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * @description Get User Emails
     * @returns {Promise}
     */
    getEmails() {
        return this.$http({
            method: 'GET',
            url: `/proxy/emails/me`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Add Email to current user by using Portfolium API
     * @param   {Object}  data Object containing new email and if it is primary or not.
     * @returns {Promise}
     */
    addEmail(data) {
        return this.$http({
            method: 'POST',
            url: `/proxy/emails/create`,
            data: data
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Deletes one email address from the current User by using
     * the Portfolium API
     * @param   {Int}  emailId Email Id
     * @param   {String} email
     * @param   {String} recaptcha
     * @returns {Promise}
     */
    updateEmail(emailId, email, recaptcha) {
        return this.$http({
            method: 'POST',
            url: `/proxy/emails/email/${emailId}`,
            data: {
                'email': email,
                'recaptcha': recaptcha,
            }
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Delete Email
     * @param   {Int}  emailId Email Id
     * @returns {Promise}
     */
    deleteEmail(emailId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/emails/email/${emailId}`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Make Email Primary
     * @param   {Int}  emailId Email Id
     * @returns {Promise}
     */
    makeEmailPrimary(emailId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/emails/primary/${emailId}`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Resend verification email
     * @param   {Int}  emailId Email Id
     * @returns {Promise}
     */
    verifyEmail(emailId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/emails/resend/${emailId}`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Update a users digest frequency
     * @param   {String}  frequency Frequency
     * @param   {Object}  current user
     * @returns {Promise}
     */
    updateFrequency(frequency, user) {
        let data = {
            frequency: frequency
        };
        return this.$http({
            method: 'POST',
            url: `/proxy/emails/digest?X-USER-TOKEN=${user.token}`,
            data: data
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Unsubscribe from email
     * @param   {String}  type the type of email
     * @param   {String}  token a users auth token
     * @param   {String}  [saved_search_id] saved search id
     * @returns {Promise}
     */
    unsubscribe({type, token, saved_search_id = null}) {
        const data = {
            type,
            saved_search_id,
        };
        return this.$http({
            method: 'POST',
            url: `/proxy/users/email_unsubscribe/${token}`,
            data: data
        })
        .then((response) => {
            return response.data;
        });
    }
}

EmailService.$inject = [
    '$http'
];
