export const SET_CURRENT_USER_ABOUT                 = 'SET_CURRENT_USER_ABOUT';
export const SET_CURRENT_USER_LOCATION              = 'SET_CURRENT_USER_LOCATION';
export const SET_CURRENT_USER_PRIMARY_EDUCATION     = 'SET_CURRENT_USER_PRIMARY_EDUCATION';
export const SET_CURRENT_USER_PRIMARY_EXPERIENCE    = 'SET_CURRENT_USER_PRIMARY_EXPERIENCE';

/**
 * @description Set the user about data
 * @returns {Function}
 */
export const setUserAbout = (userAbout) => {
    return (dispatch, getState) => {
        // set user about
        dispatch(setUserAboutData(userAbout));
        dispatch(setPrimaryEducation(userAbout.educations));
        dispatch(setPrimaryExperience(userAbout.experiences));
    };
};

/**
 * @description Set the user locations
 * @returns {Object}
 */
export const setUserLocations = (locations) => {
    return {
        type: SET_CURRENT_USER_LOCATION,
        payload: {
            locations,
        }
    };
};

/**
 * @description Set the user about data
 * @returns {Object}
 */
export const setUserAboutData = (userAbout) => {
    return {
        type: SET_CURRENT_USER_ABOUT,
        payload: {
            userAbout,
        }
    };
};

/**
 * @description Set the primary education
 * @returns {Object}
 */
export const setPrimaryEducation = (educations) => {
    const educationCriteria = {
        filter: ['current', 'created_at', 'grad_year'],
        order: ['desc', 'desc', 'desc'],
    };

    const primaryEducation = _.head(_.orderBy(educations, educationCriteria.filter, educationCriteria.order));

    return {
        type: SET_CURRENT_USER_PRIMARY_EDUCATION,
        payload: {
            primaryEducation,
        }
    };
};

/**
 * @description Set the primary experience
 * @returns {Object}
 */
export const setPrimaryExperience = (experiences) => {
    const experienceCriteria = {
        filter: ['featured', 'end_date', 'created_at'],
        order: ['desc', 'desc', 'desc'],
    };

    return {
        type: SET_CURRENT_USER_PRIMARY_EXPERIENCE,
        payload: {
            primaryExperience: _.head(_.orderBy(experiences, experienceCriteria.filter, experienceCriteria.order)),
        }
    };
};
