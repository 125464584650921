import angular from 'angular';
import SystemService from './js/system-service';

(function() {
    let app = angular.module('portfolium.component.system', []);

    // Services
    app.service('$pfSystem', SystemService);
})();

export default 'portfolium.component.system';
