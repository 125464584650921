export class PathwaysService {
    constructor($q, $http, $state, $pfDate) {
        this.$q = $q;
        this.$http = $http;
        this.$state = $state;
        this.$pfDate = $pfDate;
    }

    /**
     * Fetch a transactional pathway by  pathwayId
     * @param  { String } pathwaySlug to fetch
     * @param { String } username optional, get user specific (transactional) pathway
     * @return { Promise }
     */
    getPathwayBySlug(pathwaySlug, username) {
        return this.$http({
            method: 'GET',
            url: `/proxy/pathways/details/${pathwaySlug}/slug`,
            params: {
                username
            }
        })
        .then(response => {
            /**
             * NOTE! ALERT! This sucks, how could I let this happen?
             *
             * This skews the actual requirement length, causing counts to be off.
             * There is a temporary fix in $pfPathwayDetails.getRequirementCount()
             */
            let pathwayDetails = response.data;
            pathwayDetails.pathway.milestones.forEach( milestone => {
                // add result badge to requirements to make it functional with ng-repeat
                if (milestone.results.length > 0) {
                    milestone.requirements.push({
                        type: 'result',
                        meta: milestone.results[0].meta,
                    })
                }
            });
            return pathwayDetails;
        })
        .catch(err => {
            return this.$q.reject(err);
        });
    }

    /**
     * Submit requirement project
     * @param  {int} requirementId
     * @param  {int} entry_id
     * @return {Promise}
     */
    submitRequirementProject(requirementId, entry_id) {
        return this.$http({
            method: 'POST',
            url: `/proxy/pathways/submission/${requirementId}`,
            data: { entry_id }
        }).then(response => response.data);
    }

    /**
     * Un-submit requirement project
     * @param  {int} requirementId
     * @return {Promise}
     */
    unSubmitRequirementProject(requirementId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/pathways/unsubmit/${requirementId}`
        }).then(response => response.data);
    }

    /**
     * Get requirement by id for pathway
     * @param  {int} requirementId
     * @return {Promise}
     */
    getRequirementById(requirementId) {
        return this.$http({
            method: 'GET',
            url: `/proxy/pathways/requirement/${requirementId}`,
        }).then(response => response.data);
    }

    /**
     * finds a requiremnt and returns it
     * @param  {int} requirementId
     * @param  {Object} pathway
     * @return {Object}
     */
    findRequirementInPathway(requirementId, pathway) {
        let currentRequirement = {};
        // get milestones
        const {milestones} = pathway;
        // loop over milestones
        milestones.forEach(milestone => {
            // get requirements
            const {requirements} = milestone;
            // loop over requirements
            requirements.forEach(requirement => {
                // try to find requiremnt
                if (parseInt(requirementId) === parseInt(requirement.id)) {
                    // return requiremnt if found
                    currentRequirement = requirement;
                    // break the loop
                    return;
                }
            });
            // Was it found?
            if (!_.isEmpty(currentRequirement)) {
                // break the loop
                return;
            }
        });
        // in case is not there
        return currentRequirement;
    }

    /**
     * Get non-transactional badge by slug
     * @param  {string} badgeSlug
     * @return {Promise}
     */
    getBadge(badgeSlug) {
        return this.$http({
            method: 'GET',
            url: `/proxy/badges/details/${badgeSlug}`,
        })
        .then(response => {
            let badge = response.data;

            if (badge.earning_paths) {
                // pull out earning paths so we can sort them
                const earningPaths = _.reduce(badge.earning_paths, (combined, earningPath) => {
                    return _.concat(combined, earningPath);
                }, []);

                // sort the earning paths by type
                const sortedEarningPaths = _.orderBy(earningPaths, ['type'], ['asc']);

                badge = _.assign({}, badge, {
                    earning_paths_sorted: sortedEarningPaths,
                });
            }

            return badge;
        });
    }

    /**
     * Get transactional badge by slug and username
     * @param  {string} badgeSlug
     * @param  {string} username
     * @return {Promise}
     */
    getUserBadge(badgeSlug, username) {
        return this.$http({
            method: 'GET',
            url: `/proxy/badges/details/${badgeSlug}?username=${username}`,
        })
        .then(response => {
            let badge = response.data;

            if (badge.earning_paths) {
                // pull out earning paths so we can sort them
                const earningPaths = _.reduce(badge.earning_paths, (combined, earningPath) => {
                    return _.concat(combined, earningPath);
                }, []);

                // sort the earning paths
                const sortedEarningPaths = _.orderBy(earningPaths, (earningPath) => {
                    // don't need utc-to-local conversion here, but for consistency wth
                    return this.$pfDate.utcToLocal(earningPath.started_date).valueOf();
                }, ['desc']);

                badge = _.assign({}, badge, {
                    earning_paths_sorted: sortedEarningPaths,
                });
            }

            return badge;
        });
    }
}

PathwaysService.$inject = ['$q', '$http', '$state', '$pfDate'];
