import { stateGo } from 'redux-ui-router';

import { getFilterActions } from './filter.actions';
import { getSelectionActions } from './selection.actions';

/**
 * Factory function for pagination actions
 * @param {Object} config Configuration options
 * @param {Object} config.actionTypes Pagination action types
 * @param {String} config.actionTypes.PAGE_INIT Initialize page action
 * @param {String} config.actionTypes.PAGE_PREVIOUS Previous page action
 * @param {String} config.actionTypes.PAGE_NEXT Next page action
 * @param {String} config.actionTypes.PAGE_REQUEST API request action
 * @param {String} config.actionTypes.PAGE_SUCCESS API request success action
 * @param {String} config.actionTypes.PAGE_ERROR API error action
 * @param {String} config.actionTypes.REMOVE_SINGLE_RESULT Remove single result action
 * @param {String} config.actionTypes.ADD_SINGLE_RESULT Add single result action
 * @param {String} config.actionTypes.TOGGLE_SELECTION Individual selection toggle action
 * @param {String} config.actionTypes.CLEAR_SELECTION Clear selection action
 * @param {String} config.actionTypes.TOGGLE_ALL Toggle all selection action
 * @param {String} config.actionTypes.ADD_FILTER Add filter action
 * @param {String} config.actionTypes.REMOVE_FILTER Remove filter action
 * @param {String} config.actionTypes.RESET_FILTERS Reset filters action
 * @param {Function<Promise>} config.getResults Function that returns a promise
 *     which makes an API call and returns a result set
 * @param {Boolean} [config.syncToRouter] If true, state changes will by synced
 *     to UI-Router using redux-ui-router
 * @param {Function<Object>} [config.getPaginationState] Selector function to locate
 *     and return the pagination state from the main state tree. This is required
 *     if you want to keep state changes in sync with UI-Router
 * @param {String} [config.paginationStyle] Pagination style ("standard" or "infinite")
 * @return {Object} Object containing all the public actions for pagination
 */
export const getPaginationActions = ({
    actionTypes,
    getResults,
    syncToRouter,
    getPaginationState,
    paginationStyle = 'standard',
}) => {
    const {
        PAGE_INIT,
        PAGE_PREVIOUS,
        PAGE_NEXT,
        PAGE_REQUEST,
        PAGE_SUCCESS,
        PAGE_ERROR,
        ADD_SINGLE_RESULT,
        PREPEND_SINGLE_RESULT,
        REMOVE_SINGLE_RESULT,
        UPDATE_SINGLE_RESULT,
        TOGGLE_SELECTION,
        CLEAR_SELECTION,
        TOGGLE_ALL,
        ADD_FILTER,
        REMOVE_FILTER,
        RESET_FILTERS,
    } = actionTypes;

    const filterActions = getFilterActions({ actionTypes });
    const selectActions = getSelectionActions({ actionTypes });

    /**
     * Keep UI-Router route params in sync with any changes made to the filters
     */
    const syncUrl = () => {
        return (dispatch, getState) => {
            // Get the pagination object from the state tree
            const pagination = getPaginationState(getState());

            // Update UI-Router with updated state
            dispatch(
                stateGo(
                    '.',
                    _.assign({}, pagination.filters, {
                        // Limit/offset is abstracted into a page param
                        page: pagination.pageNumber,
                    }),
                    {
                        // Important -- don't re-render the UI
                        notify: false,
                    },
                ),
            );
        };
    };

    /**
     * Parse the UI-Router route params and set filters on the pagination state
     */
    const parseUrl = () => {
        return (dispatch, getState) => {
            const { currentParams } = getState().router;
            const filters = _.omit(currentParams, 'page');

            _.forIn(filters, (value, key) => {
                // Add filter for each param (except page)
                dispatch(filterActions.addKeyValueFilter(key, value));
            });
        };
    };

    /**
     * Initialize the pagination by making the first request to get results
     */
    const initPage = (filters = {}) => {
        return (dispatch, getState) => {
            let page = 1;

            if (syncToRouter === true) {
                const { currentParams } = getState().router;

                if (currentParams.page) {
                    // Set page from route params if provided
                    page = Number(currentParams.page);
                }

                // Parse all other filters and update URL
                dispatch(parseUrl());
            }

            // Set the initial page
            dispatch({
                type: PAGE_INIT,
                payload: {
                    page,
                },
            });

            // Fetch results
            dispatch(pageRequest());
        };
    };

    /**
     * Update the query and request the next page of results
     */
    const nextPage = () => {
        return dispatch => {
            dispatch({
                type: PAGE_NEXT,
            });

            dispatch(pageRequest());
        };
    };

    /**
     * Update the query and request the previous page of results
     */
    const previousPage = () => {
        return dispatch => {
            dispatch({
                type: PAGE_PREVIOUS,
            });

            dispatch(pageRequest());
        };
    };

    /**
     * Request a new page of results, then dispatch success/error callbacks
     */
    const pageRequest = () => {
        return dispatch => {
            dispatch({
                type: PAGE_REQUEST,
            });

            if (syncToRouter === true) {
                // Sync updated state to UI-Router route params and update URL
                dispatch(syncUrl());
            }

            getResults().then(
                results => dispatch(pageSuccess(results)),
                response => dispatch(pageError()),
            );
        };
    };

    /**
     * Update the result set after a successful request.
     * @param {Array<any>} results Newly loaded results
     */
    const pageSuccess = results => {
        return dispatch => {
            dispatch({
                type: PAGE_SUCCESS,
                payload: {
                    results,
                },
            });

            if (paginationStyle !== 'infinite') {
                // Clear the set of selected items since we're on a new page
                dispatch({
                    type: CLEAR_SELECTION,
                });
            }
        };
    };

    /**
     * Reset the query after a failed request
     */
    const pageError = () => {
        return dispatch => {
            dispatch({
                type: PAGE_PREVIOUS,
            });

            dispatch({
                type: PAGE_ERROR,
            });
        };
    };

    /**
     * Add single result last of the results array
     * @param {Any} result Result to add
     */
    const addSingleResult = result => {
        return dispatch => {
            dispatch({
                type: ADD_SINGLE_RESULT,
                payload: {
                    result,
                },
            });
        };
    };

    /**
     * Add single result first of the results array
     * @param {Any} result Result to add
     */
    const prependSingleResult = result => {
        return dispatch => {
            dispatch({
                type: PREPEND_SINGLE_RESULT,
                payload: {
                    result,
                },
            });
        };
    };

    /**
     * Add a new key/value filter and reset the pagination to page 1
     * @param {String} name Filter name to add
     * @param {String} value Filter value
     */
    const addKeyValueFilter = (name, value) => {
        return dispatch => {
            dispatch({
                type: PAGE_INIT,
                payload: {
                    page: 1,
                },
            });

            dispatch(filterActions.addKeyValueFilter(name, value));

            dispatch(pageRequest());
        };
    };

    /**
     * Add a new key/value filter and reset the pagination to page 1
     * @param {Object} filters Filter map with multiple filters to remove. For
     *     each property, the key should be the filter name, and the value
     *     should be the filter value
     */
    const addMultipleKeyValueFilters = (filters = {}) => {
        return dispatch => {
            dispatch({
                type: PAGE_INIT,
                payload: {
                    page: 1,
                },
            });

            _.forIn(filters, (value, key) => {
                dispatch(filterActions.addKeyValueFilter(key, value));
            });

            dispatch(pageRequest());
        };
    };

    /**
     * Remove an existing key/value filter and reset the pagination to page 1
     * @param {String} name Filter name to remove
     */
    const removeKeyValueFilter = name => {
        return dispatch => {
            dispatch({
                type: PAGE_INIT,
                payload: {
                    page: 1,
                },
            });

            dispatch(filterActions.removeKeyValueFilter(name));

            dispatch(pageRequest());
        };
    };

    /**
     * Add a new key/value filter and reset the pagination to page 1
     * @param {Array} filters List of filters to remove, by name
     */
    const removeMultipleKeyValueFilters = (filters = []) => {
        return dispatch => {
            dispatch({
                type: PAGE_INIT,
                payload: {
                    page: 1,
                },
            });

            filters.forEach(filterName => {
                dispatch(filterActions.removeKeyValueFilter(filterName));
            });

            dispatch(pageRequest());
        };
    };

    /**
     * Update a result in the results by key/value
     * @param {String} keyName name of the unique key on the result array
     * @param {String} keyValue value of the to update result
     */
    const removeSingleResult = (keyName, keyValue) => {
        return {
            type: REMOVE_SINGLE_RESULT,
            payload: {
                keyName,
                keyValue,
            },
        };
    };

    /**
     * Update a result in the results by key/value
     * @param {String} keyName name of the unique key on the result array
     * @param {String} keyValue value of the to update result
     * @param {Object} result
     */
    const updateSingleResult = (keyName, keyValue, result) => {
        return {
            type: UPDATE_SINGLE_RESULT,
            payload: {
                keyName,
                keyValue,
                result,
            },
        };
    };

    return {
        initPage,
        nextPage,
        previousPage,
        addSingleResult,
        pageRequest,
        prependSingleResult,
        addKeyValueFilter,
        removeSingleResult,
        updateSingleResult,
        setKeyValueFilter: filterActions.addKeyValueFilter,
        addMultipleKeyValueFilters,
        setMultipleKeyValueFilters: filterActions.addMultipleKeyValueFilters,
        removeKeyValueFilter,
        removeMultipleKeyValueFilters,
        resetFilters: filterActions.resetFilters,
        toggleSelection: selectActions.toggleSelection,
        clearSelection: selectActions.clearSelection,
        toggleAll: selectActions.toggleAll,
    };
};
