/**
 * Takes a file size in bytes and converts to human readible file size
 */
function filesizeFilter() {
    return function(bytes, precision) {
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
            return '--';
        }

        if (_.isUndefined(precision)) {
            precision = 1;
        }

        let units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
        let number = Math.floor(Math.log(bytes) / Math.log(1024));

        return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
    };
}

export default filesizeFilter;
