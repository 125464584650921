import angular from 'angular';

/**
  * @ngdoc service
  * @name FilestackViewerService
  * @module portfolium.component.filestackViewer
  * @description
  * Defines and provides controls for the Filestack Viewer.
  */
export class FilestackViewerService {
    constructor($mdPanel) {
        this.$mdPanel = $mdPanel;
    }

    openMobileView($event, file) {
        return this.$mdPanel.open({
            attachTo: angular.element(document.body),
            controller: angular.noop,
            bindToController: true,
            targetEvent: $event,
            controllerAs: '$ctrl',
            zIndex: 6000, // above the EDP
            fullscreen: true,
            escapeToClose: true,
            locals: {
                handle: file.filestack.handle,
                policy: file.security.policy,
                signature: file.security.signature,
            },
            template:
            `<pf-filestack-viewer-mobile
                 pf-handle="$ctrl.handle"
                 pf-policy="$ctrl.policy"
                 pf-signature="$ctrl.signature"
                 md-panel-ref="$ctrl.mdPanelRef">
            </pf-filestack-viewer-mobile>`,
        });
    }
}

FilestackViewerService.$inject = [
    '$mdPanel',
];
