class AddSkillsAction {
    constructor($pfGameAction) {
        this.$pfGameAction = $pfGameAction;
        this.name = 'add_more_skills';
    }

    get label() {
        let label = 'Add a skill to a portfolio project';
        let currentLevel = this.$pfGameAction.getCurrentLevel() || {};

        if (this.type === 'Has_many') {
            return 'Add more skills to your projects';
        }

        if (currentLevel.name === 'rookie') {
            label = 'Add 3+ skills to your portfolio projects';
        }

        if (currentLevel.name === 'pro') {
            label = 'Add 5+ skills to your portfolio projects';
        }

        return label;
    }

    execute() {
        return this.$pfGameAction.redirectToPortfolio().then(() => {
            return this.$pfGameAction
                .scrollToElement(
                    '#pf-profile-content',
                    '.pf-view--portfolio',
                    64,
                )
                .then(() => {
                    return this.$pfGameAction.highlightElement(
                        '.pf-entry-card-container',
                    );
                })
                .catch(() => {});
        });
    }
}

AddSkillsAction.$inject = ['$pfGameAction'];

export default AddSkillsAction;
