
import angular from 'angular';

import { CONTENT_REPORTING_STEPS } from './content-reporting-steps.constant.js';
import { ContentReportingModalComponent } from './content-reporting-modal.component';
import { ContentReportFormComponent } from './content-report-form/content-report-form.component';
import { AdditionalFeedbackFormComponent } from './additional-feedback-form/additional-feedback-form.component';

import './content-reporting-modal.component.scss';

const MODULE_NAME = 'portfolium.component.contentReportingModal';

(function() {
    angular.module(MODULE_NAME, [])
        // constants
        .constant('PF_CONTENT_REPORTING_STEPS', CONTENT_REPORTING_STEPS)
        // Components
        .component('pfContentReportingModal', ContentReportingModalComponent)
        .component('pfContentReportForm', ContentReportFormComponent)
        .component('pfAdditionalFeedbackForm', AdditionalFeedbackFormComponent);
})();

export default MODULE_NAME;
