import SocialShareMenuProjectTemplate from './social-share-menu-project.component.html';
import './social-share-menu-project.component.scss';

/**
 * @ngdoc controller
 * @name SocialShareMenuProjectController
 * @module portfolium.component.socialShare
 */
class SocialShareMenuProjectController {
    constructor(
        $pfUser,
        $pfSocialShare,
        $pfClipboard,
        $pfEntryEditor,
        $pfEventTracker,
        $element,
        $stateParams,
        PF_ENTRY_VISIBILITY_TYPES_MAPPED
    ) {
        this.$pfUser = $pfUser;
        this.$pfSocialShare = $pfSocialShare;
        this.$pfClipboard = $pfClipboard;
        this.$pfEntryEditor = $pfEntryEditor;
        this.$pfEventTracker = $pfEventTracker;
        this.$element = $element;
        this.$stateParams = $stateParams;
        this.currentUser = $pfUser.getUser();
        this.isMobile = $pfUser.isMobile();
        this.PF_ENTRY_VISIBILITY_TYPES_MAPPED = PF_ENTRY_VISIBILITY_TYPES_MAPPED;
    }

    $onInit() {
        if (this.isPrivate()) {
            this.projectShareLink = `${this.$pfUser.globals.environment.PF_BASE_URL}pp/${this.project.token}`;
        } else {
            this.projectShareLink = `${this.$pfUser.globals.environment.PF_BASE_URL}entry/${this.project.slug}`;
        }

        // add the version param
        this.projectShareLink += this.getVersionParam();

        let isProjectOwner = false;

        if (!_.isNil(this.currentUser) && !_.isEmpty(this.currentUser)) {
            isProjectOwner = this.project.fk_user_id === this.currentUser.id;
        }

        // track the menu open event
        if (!_.isNil(this.source)) {
            this.$pfEventTracker
                .trackServer('Project Share Menu Opened', {
                    source: this.source,
                    isProjectOwner,
                });
        }
    }

    /**
     * Is this project private?
     * @return {boolean}
     */
    isPrivate() {
        return this.project.visibility === this.PF_ENTRY_VISIBILITY_TYPES_MAPPED.hidden;
    }

    /**
     * Is this an assignment
     * @return {boolean}
     */
    isAssignment() {
        return this.project.faculty_assigned === '1';
    }

    /**
     * Get the version param from the state params
     * @return {string}
     */
    getVersionParam() {
        return !_.isEmpty(this.$stateParams.version)
            ? `?version=${this.$stateParams.version}`
            : '';
    }

    /**
     * Copy project share link to clipboard
     */
    copyProjectLink() {
        this.$pfClipboard
            .copyText(
                this.projectShareLink,
                'Link copied'
            );
        this.mdPanelRef
            .close()
            .then(() => {
                this.mdPanelRef.destroy();
            });
    }

    /**
     * Open up project settings for editing
     */
    updatePrivacySettings() {
        this.mdPanelRef.close()
            .then(() => {
                this.mdPanelRef.destroy();
                this.$pfEntryEditor
                    .editEntry(this.project.id, {
                        source: 'socialShareMenu',
                        openSettings: true,
                    });
            });
    }

    /**
     * Share the entry to a social media service
     * @param {string} provider Social media provider
     */
    share(provider) {
        this.$pfSocialShare
            .shareData(
                provider,
                this.project,
                'entry'
            );
        this.mdPanelRef
            .close()
            .then(() => {
                this.mdPanelRef.destroy();
            });

    }
}

SocialShareMenuProjectController.$inject = [
    '$pfUser',
    '$pfSocialShare',
    '$pfClipboard',
    '$pfEntryEditor',
    '$pfEventTracker',
    '$element',
    '$stateParams',
    'PF_ENTRY_VISIBILITY_TYPES_MAPPED'
];

/**
 * @ngdoc component
 * @name SocialShareMenuProjectComponent
 * @module portfolium.component.socialShare
 * @param {object} project project to be shared
 * @param {string} source Menu open click source
 * @param {object} mdPanelRef current panel reference
 */
export const SocialShareMenuProjectComponent = {
    bindings: {
        project: '<',
        source: '<',
        mdPanelRef: '<',
    },
    controller: SocialShareMenuProjectController,
    controllerAs: '$ctrl',
    template: SocialShareMenuProjectTemplate,
};
