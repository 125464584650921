import _ from 'lodash';

import {
    ADD_NOTIFICATIONS,
    MARK_NOTIFICATION_AS_READ,
    MARK_ALL_NOTIFICATIONS_AS_READ,
    UPDATE_UNREAD_NOTIFICATIONS,
    UPDATE_NOTIFICATION_STATUS,
    CLEAR_NOTIFICATIONS_BY_TYPE_AND_STATUS,
} from '../action/notification-center-notifications';

const initialState = {
    unread: 0,
    limit: 15,
    offset: 0,
    finished: false,
    notifications: [],
};

export const notificationCenterNotifications = (state = initialState, action = {}) => {
    switch (action.type) {
        case ADD_NOTIFICATIONS:
            return _.assign({}, state, {
                offset: state.offset + action.payload.length,
                finished: !action.payload.length,
                notifications: [
                    ...state.notifications,
                    ...action.payload,
                ],
            });
        case MARK_NOTIFICATION_AS_READ:
            return _.assign({}, state, {
                notifications: state.notifications.map((notification) => {
                    // Match clicked notification and set as seen
                    if (notification.id === action.payload) {
                        return _.assign({}, notification, {
                            seen: '1',
                        });
                    }
                    // Return unmodified notifications
                    return notification;
                }),
            });
        case MARK_ALL_NOTIFICATIONS_AS_READ:
            return _.assign({}, state, {
                notifications: state.notifications.map((notification) => {
                    // Set notifications.seen to 1
                    return _.assign({}, notification, {
                        seen: '1',
                    });
                }),
            });
        case UPDATE_UNREAD_NOTIFICATIONS:
            return _.assign({}, state, {
                unread: action.payload,
            });
        case UPDATE_NOTIFICATION_STATUS:
            return _.assign({}, state, {
                notifications: state.notifications.map((notification) => {
                    // Check if passed in type matches either type or dynamic type, match against passed in user ID
                    if (notification.type === action.payload.type || notification.dynamic_type === action.payload.type) {
                        if (notification.object_id === action.payload.userId || notification.fk_notifier_id === action.payload.userId) {
                            return _.assign({}, notification, {
                                status: action.payload.status
                            });
                        }
                    }
                return notification;
                }),
            });
        case CLEAR_NOTIFICATIONS_BY_TYPE_AND_STATUS:
            const filteredNotifications = state.notifications.filter((notification) => {
                // filter passed in type and status
                if (notification.type === action.payload.type && notification.status === action.payload.status) {
                    return false;
                }
                return true;
            });

            return _.assign({}, state, {
                notifications: filteredNotifications,
            });

        default:
            return state;
    }
};
