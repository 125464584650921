import NavbarStandardSwitchDropdownTemplate from './navbar-standard-switch-dropdown.component.html';
import './navbar-standard-switch-dropdown.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.navbar
 * @name NavbarStandardSwitchDropdownController
 */
class NavbarStandardSwitchDropdownController {
    constructor($pfAppSwitcherAuth) {
        this.$pfAppSwitcherAuth = $pfAppSwitcherAuth;
    }

    /**
     * Is the user type faculty
     */
    get isFaculty() {
        return this.$pfAppSwitcherAuth.isFaculty;
    }

    /**
     * Does the user have access to the Canvas
     */
    get hasEduAccess() {
        return this.$pfAppSwitcherAuth.hasEduAccess;
    }

    /**
     * Does the user have access to TalentMatch
     */
    get hasTalentMatchAccess() {
        return this.$pfAppSwitcherAuth.hasTalentMatchAccess;
    }

    /**
     * Redirects to Canvas
     */
    goToEduSso() {
        this.$pfAppSwitcherAuth.goToEduFromWeb();
    }

    /**
     * Redirects to TalentMatch
     */
    goToTalentMatchSso() {
        this.$pfAppSwitcherAuth.goToTalentMatchFromWeb();
    }
}

NavbarStandardSwitchDropdownController.$inject = [
    '$pfAppSwitcherAuth',
];

/**
 * @ngdoc component
 * @module portfolium.component.navbar
 * @name pfNavbarStandardSwitchDropdown
 */
export const NavbarStandardSwitchDropdownComponent = {
    controller: NavbarStandardSwitchDropdownController,
    controllerAs: '$ctrl',
    template: NavbarStandardSwitchDropdownTemplate,
};
