import EntryCardRibbonTemplate from './entry-card-ribbon.component.html';
import './entry-card-ribbon.component.scss';

/**
 * @ngdoc controller
 * @name EntryCardRibbonController
 * @module portfolium.component.entryCard
 */
class EntryCardRibbonController {
    constructor($element, $pfAuth) {
        this.$element = $element;
        this.$pfAuth = $pfAuth;
    }

    get isSelf() {
        return this.$pfAuth.isAuthenticatedUser(this.userId);
    }

    $onChanges(changes) {
        const entry = changes.entry.currentValue;

        if (!entry) {
            return;
        }

        // Set the user ID
        this.userId = entry.fk_user_id;

        // Set the ribbon flags
        _.assign(this, {
            staffPick: entry.spotlighted === '1',
            expertPick: entry.expert === '1',
            featuredPick: entry.featured === '1',
            private: entry.visibility === '3',
            facultyAssigned: this.isSelf && entry.faculty_assigned === '1',
        });
    }

    $postLink() {
        this.$element.attr('tabindex', -1);
    }
}

EntryCardRibbonController.$inject = [
    '$element',
    '$pfAuth',
];

/**
 * @ngdoc component
 * @name pfEntryCardRibbon
 * @module portfolium.component.entryCard
 */
export const EntryCardRibbonComponent = {
    bindings: {
        entry: '<pfEntry',
    },
    controller: EntryCardRibbonController,
    controllerAs: '$ctrl',
    template: EntryCardRibbonTemplate,
};
