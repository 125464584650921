/**
 * @ngdoc directive
 * @name socialSharePanelTriggerDirective
 * @module portfolium.socialShare
 * @param {string} pfSocialShareType Type of thing being shared
 * @param {object} pfSocialShareData Share data to pass through to the panel
 * @param {string} pfSocialShareSource Click source
 * @description Opens a social share panel on the element
 **/
export const socialSharePanelTriggerDirective = ($pfSocialSharePanel) => {
    return {
        restrict: 'A',
        link: (
            scope,
            element,
            {
                pfSocialShareType: type,
                pfSocialShareData: data,
                pfSocialShareSource: source,
            },
            ctrl
        ) => {
            element.on('click', () => {
                $pfSocialSharePanel.open({
                    element,
                    type,
                    data: scope.$eval(data),
                    source,
                });
            });
        },
    };
};

socialSharePanelTriggerDirective.$inject = ['$pfSocialSharePanel'];
