import _ from 'lodash';

import {
    SETTINGS_IMPORT_DATA_CANCEL_IMPORT,
    SETTINGS_IMPORT_DATA_NEXT_STEP,
    SETTINGS_IMPORT_DATA_SET_IMPORT_SOURCES,
    SETTINGS_IMPORT_DATA_SET_SELECTED_DATA,
    SETTINGS_IMPORT_DATA_SET_SELECTED_SOURCE,
} from './settings-import-data.actions';

const initialState = {
    importSources: [],
    selectedSource: {},
    sourceData: [],
    steps: [
        {
            name: 'source',
            complete: false,
        },
        {
            name: 'select',
            complete: false,
        },
        {
            name: 'confirm',
            complete: false,
        },
    ],
    currentStep: {
        name: 'source',
        complete: false,
    },
};

export const settingsImportData = (state = initialState, action = {}) => {
    switch (action.type) {
        case SETTINGS_IMPORT_DATA_CANCEL_IMPORT: {
             const updatedSteps = state.steps
                .map(step => _.assign({}, step, {complete: false}));

            return _.assign({}, state, {
                selectedSource: {},
                steps: updatedSteps,
                currentStep: updatedSteps[0],
            });
        }
        case SETTINGS_IMPORT_DATA_NEXT_STEP: {
            const currentIndex = _.findIndex(state.steps, state.currentStep);

            if (currentIndex === -1 || currentIndex >= (state.steps.length - 1)) {
                // Protect against moving to a step that doesn't exist
                return state;
            }

            const updatedSteps = state.steps.map((step, index) => {
                if (index === currentIndex) {
                    // Mark current step as completed
                    return _.assign({}, step, {complete: true});
                }

                return step;
            });

            return _.assign({}, state, {
                currentStep: updatedSteps[currentIndex + 1],
                steps: updatedSteps,
            });
        }
        case SETTINGS_IMPORT_DATA_SET_IMPORT_SOURCES:
            return _.assign({}, state, {
                importSources: action.payload.sources,
            });
        case SETTINGS_IMPORT_DATA_SET_SELECTED_DATA: {
            let updatedSourceData;
            const selectedIndex = _.findIndex(state.sourceData, (data) => {
                // Check if user has already selected data for this source/type
                return data.type.name === action.payload.type.name;
            });

            if (selectedIndex > -1) {
                // Update existing `ImportSourceData` record
                updatedSourceData = state.sourceData.map((data, index) => {
                    if (index === selectedIndex) {
                        return _.assign({}, data, {
                            selected: action.payload.selected,
                        });
                    }

                    return data;
                });
            } else {
                // Create new `ImportSourceData` record
                updatedSourceData = [
                    ...state.sourceData,
                    _.assign({}, {
                        source: state.selectedSource,
                        type: action.payload.type,
                        selected: action.payload.selected,
                    }),
                ];
            }

            return _.assign({}, state, {
                sourceData: updatedSourceData,
            });
        }
        case SETTINGS_IMPORT_DATA_SET_SELECTED_SOURCE:
            return _.assign({}, state, {
                selectedSource: action.payload.source,
                sourceData: [],
            });
        default:
            return state;
    }
};
