import ApplicationTemplate from '../../template/notification-types/application.html'
import DefaultTemplate from '../../template/notification-types/default.html'
import EntryTemplate from '../../template/notification-types/entry.html'
import JobTemplate from '../../template/notification-types/job.html'
import JoinTemplate from '../../template/notification-types/join.html'
import NetworkTemplate from '../../template/notification-types/network.html'
import UserTemplate from '../../template/notification-types/user.html'
import EnrollmentTemplate from '../../template/notification-types/enrollment.html'
import Assignment_submitTemplate from '../../template/notification-types/assignment_submit.html'
import AssignmentTemplate from '../../template/notification-types/assignment.html'
import RequirementTemplate from '../../template/notification-types/requirement.html'

export const templateCache = ($templateCache) => {
    $templateCache.put('template/components/notification-center/template/notification-types/application.html', ApplicationTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/default.html', DefaultTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/entry.html', EntryTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/job.html', JobTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/join.html', JoinTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/network.html', NetworkTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/user.html', UserTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/enrollment.html', EnrollmentTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/assignment_submit.html', Assignment_submitTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/assignment.html', AssignmentTemplate);
    $templateCache.put('template/components/notification-center/template/notification-types/requirement.html', RequirementTemplate);
};

templateCache.$inject = ['$templateCache'];
