import EntryViewerCommentsToolbarMobileTemplate from './entry-viewer-comments-toolbar-mobile.component.html';
import EntryViewerCommentsToolbarTemplate from './entry-viewer-comments-toolbar.component.html';
import './entry-viewer-comments-toolbar.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerCommentsToolbarController
 */
class EntryViewerCommentsToolbarController {
    constructor(
        $pfEdpService,
        $anchorScroll,
        $timeout,
        $pfToast,
        $pfUser,
    ) {
        this.$pfEdpService = $pfEdpService;
        this.$anchorScroll = $anchorScroll;
        this.$timeout = $timeout;
        this.$pfToast = $pfToast;
        this.user = $pfUser.getUser();
        this.comment = '';
    }

    /**
     * @description Add a comment to an entry
     * @param {jqEvent}
     */
    addComment($event) {
        this.onAddComment({comment: this.comment});
        this.comment = '';
    }

    /**
     * Handle keydown events sends comments on enter
     * @param {jqEvent}
     */
    onKeyDown($event) {
        // Listen to enter
        if ($event.which === 13) {
            this.addComment($event)
        }
    }
}

EntryViewerCommentsToolbarController.$inject = [
    '$pfEdpService',
    '$anchorScroll',
    '$timeout',
    '$pfToast',
    '$pfUser',
];

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerCommentsToolbarComponent
 * @description Creates toolbar for commenting
 *
 * @param {boolean}     liked   Is entry liked?
 * @param {callback}    onAddComment  Trigger when user adds a comment
 *                                    to current entry.
 * @param {callback}    onLike  Trigger when user likes an entry.
 */
export const EntryViewerCommentsToolbarComponent = {
    bindings: {
        liked: '<',
        onAddComment: '&',
        onLike: '&',
    },
    template: ['$pfUser', $pfUser => {
        const isMobile = $pfUser.isMobile();

        if (isMobile) {
            return EntryViewerCommentsToolbarMobileTemplate;
        }

        return EntryViewerCommentsToolbarTemplate;
    }],
    controller: EntryViewerCommentsToolbarController,
    controllerAs: '$ctrl',
};
