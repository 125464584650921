/**
 * @ngdoc service
 * @module portfolium.core.services.filestack
 * @name $pfFilestackConvert
 * @description Handle conversion of files into images, using filestack v3
 */
export class FilestackConvertService {
    constructor($q, PF_FILESTACK_CONFIG, PF_MIMETYPES_CONFIG, PF_FILESTACK_CONVERT) {
        this.$q = $q;
        this.PF_FILESTACK_CONFIG = PF_FILESTACK_CONFIG;
        this.PF_MIMETYPES_CONFIG = PF_MIMETYPES_CONFIG;
        this.PF_FILESTACK_CONVERT = PF_FILESTACK_CONVERT;
    }

    /**
     * Checks if the file can be converted into an image
     * @param  {Object} file information of the file
     * @param {String} file.filename name of the file
     * @param {String} file.mimetype mimetype of the file
     * @return {Boolean}
     */
    canConvertToImage(file) {
        // Get all the mimetypes from the groups we want
        // to convert
        const wantToConvertMimetypes = [
            ...this.PF_MIMETYPES_CONFIG.document.mimetypes,
            ...this.PF_MIMETYPES_CONFIG.slideshow.mimetypes,
            ...this.PF_MIMETYPES_CONFIG.spreadsheet.mimetypes,
            ...this.PF_MIMETYPES_CONFIG.design.mimetypes,
            ...this.PF_MIMETYPES_CONFIG.pdf.mimetypes,
        ];

        // take out all of the mimetypes we know Cant be converted
        const supportedMimetypes = _.difference(
                wantToConvertMimetypes,
                this.PF_FILESTACK_CONVERT.unsupportedMimetypes);
        // check if there is any mimetype
        if (!_.isEmpty(file.mimetype)) {
            // check that the mimetype is supported
            if (supportedMimetypes.indexOf(file.mimetype) >= 0) {
                return true;
            }
        }

        // get extension of file when there is no mimetype
        // or the mimetype is weird (Microsoft likes doing this)
        const extension = this.getExtension(file.filename);
        // If there is an extension
        if (extension) {
            // check that the extension is supported
            return (this.PF_FILESTACK_CONVERT.supportedExtensions.indexOf(extension) >= 0);
        }
        // if the file has no mimetype and no extension, dont let it convert
        return false;
    }

    /**
     * Get extension of a filename
     * @param  {String} filename name of file
     * @return {String}          extension of the file
     */
    getExtension(filename) {
        const filenameClean = filename.split('?')[0];
        const filenameSplit = filenameClean.split('.');
        const extension = filenameSplit[filenameSplit.length - 1].toLowerCase();

        return '.' + extension;
    }

    /**
     * checks if a mimetype belongs to type group
     * @param  {String} mimetype mimetype of the file
     * @param  {String} typeName type of the file
     * @return {Boolean}
     */
    mimetypeBelongsToType(mimetype, typeName) {
        // get all mimetypes for type given
        const typeMimetypes = this.PF_MIMETYPES_CONFIG[typeName].mimetypes;
        // check if the mimetype belongs to the type group mimetypes
        return typeMimetypes.indexOf(mimetype) >= 0;
    }

    /**
     * checks if a extension belongs to type group
     * @param  {String} extension extension of the file
     * @param  {String} typeName type of the file
     * @return {Boolean}
     */
    extensionBelongsToType(extension, typeName) {
        // get all extensions for type given
        const typeExtensions = this.PF_MIMETYPES_CONFIG[typeName].extensions;
        // check if the extension belongs to the type group extensions
        return typeExtensions.indexOf(extension) >= 0;
    }

    /**
     * checks if a signature belongs to type group
     * @param  {String} signature signature of the file
     * @param  {String} typeName type of the file
     * @return {Boolean}
     */
    signatureBelongsToType(signature, typeName) {
        // get all signatures for type given
        const typeSignatures = this.PF_MIMETYPES_CONFIG[typeName].signatures;
        // check if the signature belongs to the type group signatures
        return typeSignatures.indexOf(signature) >= 0;
    }

    /**
     * Validate a file according to known file signatures
     * https://en.wikipedia.org/wiki/List_of_file_signatures
     * @param  {File}   file The File object from an upload
     * @param  {String} typeName type of the file
     * @param {String} source source of the file
     * @return {Promise<String|Null>} the signature of the file
     */
    validateFileSignature(file, typeName, source) {
        // when the source is a third party service, Filestack doesnt give us the
        // file object
        if (!_.isEmpty(source) && source !== 'local_file_system') {
            // the file is valid if the signature and the extension matches our approved list
            const validSignature = this.extensionBelongsToType(this.getExtension(file.filename), typeName);
            return this.$q.resolve(validSignature);
        }

        const deferred = this.$q.defer();
        const filereader = new FileReader();

        filereader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const uint = new Uint8Array(evt.target.result);
                const bytes = [];
                uint.forEach((byte) => {
                    // add the byte to the array, padding with '0' if it's a single character
                    bytes.push(_.padStart(byte.toString(16), 2, '0'));
                });
                const signature = bytes.join('').toUpperCase();

                // the file is valid if the signature and the extension matches our approved list
                if (
                    this.signatureBelongsToType(signature, typeName)
                    && this.extensionBelongsToType(this.getExtension(file.name), typeName)
                ) {
                    return deferred.resolve(signature);
                } else {
                    return deferred.resolve(null);
                }
            }
        }

        // get first 4 bytes of file
        const blob = file.slice(0, 4);
        filereader.readAsArrayBuffer(blob);

        // return promise
        return deferred.promise;
    }
}

FilestackConvertService.$inject = [
    '$q',
    'PF_FILESTACK_CONFIG',
    'PF_MIMETYPES_CONFIG',
    'PF_FILESTACK_CONVERT',
];
