import {CanvasAuthProvider} from './providers/canvas-auth-provider';
import {CredlyAuthProvider} from './providers/credly-auth-provider';
import {BadgrAuthProvider} from './providers/badgr-auth-provider';

/**
 * Register all of the supported auth providers when the app is bootstrapped
 */
export const authProviders = ($pfAuthProviderProvider) => {
    $pfAuthProviderProvider.register(CanvasAuthProvider, 'canvas');
    // $pfAuthProviderProvider.register(CredlyAuthProvider, 'credly');
    $pfAuthProviderProvider.register(BadgrAuthProvider, 'badgr');
};

authProviders.$inject = ['$pfAuthProviderProvider'];
