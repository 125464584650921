class UserAvatarController {
    constructor($state, $element, isDefaultImageFilter, $pfUser, $pfProfileQuickEdit) {
        this._$state = $state;
        this._$element = $element;
        this._$pfUser = $pfUser;
        this._$pfProfileQuickEdit = $pfProfileQuickEdit;
        this._isDefaultImageFilter = isDefaultImageFilter;
    }

    $onInit() {
        this.size2x = this.at2x(this.size);
    }

    $postLink() {
        this._$element.addClass(`pf-${this.size}`);
    }

    $onChanges() {
        const src = this.image && this.image.url_https;

        const isDefault = this._isDefaultImageFilter(src);

        this.imageSrc = (isDefault) ? null : src;
    }

    /**
     * Redirect to settings page to edit
     * @param {Object}  $event  The click event
     */
    edit($event) {
        if (this._$pfUser.isMobile()) {
            return this._$state.go('settings');
        }
        return this._$pfProfileQuickEdit.openDialog($event);
    }

    /**
     * Get the 2x dimension
     * @param  {String} size Size of the avatar
     * @return {Number}      2x size
     */
    at2x(size) {
        return parseInt(size, 10) * 2;
    }
}

UserAvatarController.$inject = [
    '$state',
    '$element',
    'isDefaultImageFilter',
    '$pfUser',
    '$pfProfileQuickEdit',
];

export const UserAvatarComponent = {
    bindings: {
        'size': '@pfSize',
        'image': '<pfImage',
        'name': '<pfName',
        'editable': '<pfEditable',
        'pfClass': '<',
    },
    controller: UserAvatarController,
    template: `
        <span ng-if="$ctrl.editable"
             class="{{$ctrl.pfClass}}">
            <md-button
                 ng-if="!$ctrl.imageSrc"
                 class="md-icon-button pf-user-avatar__add"
                 ng-click="$ctrl.edit()"
                 aria-label="Add avatar">
                <md-icon class="material-icons pf-edit-avatar-icon">photo_camera</md-icon>
            </md-button>
            <md-button
                 ng-if="$ctrl.imageSrc"
                 class="pf-user-avatar__edit"
                 ng-click="$ctrl.edit($event)"
                 aria-label="Edit avatar">
                <img ng-src="{{$ctrl.imageSrc|image:$ctrl.size2x:$ctrl.size2x}}"
                     height="{{::$ctrl.size}}"
                     width="{{::$ctrl.size}}"
                     alt="{{::$ctrl.name}}">
                <md-icon class="material-icons pf-edit-avatar-icon">photo_camera</md-icon>
            </md-button>
        </span>
        <span ng-if="!$ctrl.editable"
             class="{{$ctrl.pfClass}}">
            <img ng-if="$ctrl.imageSrc"
                 class="pf-user-avatar-image"
                 ng-src="{{$ctrl.imageSrc|image:$ctrl.size2x:$ctrl.size2x}}"
                 height="{{::$ctrl.size}}"
                 width="{{::$ctrl.size}}"
                 alt="{{::$ctrl.name}}">
            <img ng-if="!$ctrl.imageSrc"
                 class="pf-user-avatar-image"
                 ng-src="{{$ctrl.image.url_https|image:$ctrl.size2x:$ctrl.size2x}}"
                 height="{{::$ctrl.size}}"
                 width="{{::$ctrl.size}}"
                 alt="{{::$ctrl.name}}">
        </span>
    `,
};
