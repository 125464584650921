import {EntryViewerListController} from '../entry-viewer-list.controller';
import EntryViewerTeammatesTemplate from './entry-viewer-teammates.component.html';
import './entry-viewer-teammates.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerTeammatesController
 */
class EntryViewerTeammatesController extends EntryViewerListController {
    constructor(
        $mdDialog,
        $pfAuth,
        $element,
        $timeout,
        $filter,
        PF_USER_CARD_TEMPLATES,
        PF_POPOVER_STARTING_POSITIONS,
    ) {
        super($element, $timeout);
        this.$mdDialog = $mdDialog;
        this.$pfAuth = $pfAuth;
        this.$filter = $filter;
        this.PF_USER_CARD_TEMPLATES = PF_USER_CARD_TEMPLATES;
        this.PF_POPOVER_STARTING_POSITIONS = PF_POPOVER_STARTING_POSITIONS;
    }

    /**
     * check if the user id is the logged in one
     * @param  {number}  userId user id
     * @return {Boolean}
     */
    isSelf(userId) {
        return this.$pfAuth.isAuthenticatedUser(userId);
    }

    /**
     * Creates a modal to confirm if user wants to be removed from the list
     *     of teammates for current entry
     * @param  {object} teammate teammate as user object
     * @param  {jqEvent} $event
     * @return {Promise}
     */
    confirmRemove(teammate, $event) {
        const title = this.$filter('i18n')('Are you sure?');
        const textContent = this.$filter('i18n')('This will remove you from the project');
        const ariaLabel = this.$filter('i18n')('Confirm dialog');
        const ok = this.$filter('i18n')('Remove me');
        const cancel = this.$filter('i18n')('Cancel');

        const confirm = this.$mdDialog.confirm()
            .title(title)
            .ariaLabel(ariaLabel)
            .textContent(textContent)
            .targetEvent($event)
            .ok(ok)
            .cancel(cancel);


        return this.$mdDialog.show(confirm).then(() => {
            const userId = teammate.id;
            const event = {
                title: 'Remove myself as a teammate',
                properties: {
                    sourceName: teammate.username,
                    clickSource: 'prEDP Sidebar',
                    userAction: 'click',
                },
            };
            this.onViewTeammate({ event });
            this.onRemoveSelfAsTeammate({userId});
        });
    }

    /**
     * Triggers callback for on view teammate and redirects to tags
     * @param  {object} teammate
     */
    viewTeammate(teammate) {
        const event = {
            title: 'Teammate Selected',
            properties: {
                sourceName: teammate.username,
                clickSource: 'prEDP Sidebar',
                userAction: 'click',
            },
        };
        this.onViewTeammate({ event });
    }
}

EntryViewerTeammatesController.$inject = [
    '$mdDialog',
    '$pfAuth',
    '$element',
    '$timeout',
    '$filter',
    'PF_USER_CARD_TEMPLATES',
    'PF_POPOVER_STARTING_POSITIONS',
];

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerTeammatesComponent
 * @description Display list of teammates in the entry viewer sidebar
 *
 * @param {boolean} isPreview Is the user viewing a preview?
 * @param {number} listLength How many items are in the list
 * @param {object[]} teammates List of teammates objects
 * @param {callback} onViewTeammate trigger when a teammate is clicked
 * @param {callback} onRemoveSelfAsTeammate trigger when current user
 *                                          as a teammate wants to be removed
 */
export const EntryViewerTeammatesComponent = {
    bindings: {
        'isPreview': '<',
        'listLength': '<',
        'onViewTeammate' : '&',
        'onRemoveSelfAsTeammate': '&',
        'teammates': '<',
    },
    controller: EntryViewerTeammatesController,
    controllerAs: '$ctrl',
    template: EntryViewerTeammatesTemplate,
};
