export const NETWORK_DETAILS_REQUEST      = 'NETWORK_DETAILS_REQUEST';
export const NETWORK_DETAILS_UPDATE       = 'NETWORK_DETAILS_UPDATE';
export const RESET_NETWORK                = 'RESET_NETWORK';

export const networkRequest = () => {
    return {
        type: NETWORK_DETAILS_REQUEST,
    };
};

export const updateNetwork = (payload) => {
    return {
        type: NETWORK_DETAILS_UPDATE,
        payload,
    };
};

export const resetNetwork = () => {
    return {
        type: RESET_NETWORK,
    };
};
