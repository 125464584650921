class AddAvatarAction
{
    constructor($pfGameAction) {
        this.$pfGameAction = $pfGameAction;
        this.name = 'add_your_avatar';
        this.label = 'Add an avatar';
    }

    execute() {
        return this.$pfGameAction.redirect('/settings');
    }
}

AddAvatarAction.$inject = ['$pfGameAction'];

export default AddAvatarAction;
