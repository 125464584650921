export class UserConnectFormController {
    constructor($mdDialog, $pfUser, $pfUserConnect, $filter, user) {
        this.$mdDialog = $mdDialog;
        this.$pfUserConnect = $pfUserConnect;
        this.$filter = $filter;
        this.authedUser = $pfUser.getUser();
    }

    /**
     * Set the default connection request message
     */
    setDefaultMessage() {
        // Keep template literal formatting here, this affects how it is displayed
        this.message = 
`${this.$filter('i18n')('Hi')} ${this.user.firstname || ''},

${this.$filter('i18n')('I would like to connect with you on Portfolium')}.

${this.$filter('i18n')('Cheers')},
${this.authedUser.name || ''}
${this.authedUser.school || ''}`;
    }

    /**
     * Close the modal window
     */
    close() {
        this.$mdDialog.cancel();
    }

    /**
     * Submit the form and request connection, then close modal
     */
    submit() {
        let params = {};

        if (!this.form.$valid) {
            return;
        }

        if (!this.noMessage) {
            params.message = this.message;
        }

        this.$pfUserConnect.requestConnection(this.user.id, params).then(
            () => {
                this.$mdDialog.hide();
            },
            () => {},
        );
    }
}

UserConnectFormController.$inject = [
    '$mdDialog',
    '$pfUser',
    '$pfUserConnect',
    '$filter',
];
