import angular from 'angular';

import EmbeddedUrlsService from './js/embedded-urls.service';

const MODULE_NAME = 'portfolium.component.embeddedUrls';

(function() {
    let app = angular.module(MODULE_NAME, []);

    // Service
    app.service('$pfEmbeddedUrls', EmbeddedUrlsService);
})();

export default MODULE_NAME;
