import ReadMoreClampTemplate from './read-more-clamp.component.html';
import './read-more-clamp.component.scss';

/**
 * @ngdoc component
 * @name ReadMoreClampController
 * @module portfolium.readMore
 */
export class ReadMoreClampController {
    constructor($clamp, $element, $timeout, PF_CHAR_PER_PIXEL_CONSTANT) {
        this.$clamp = $clamp;
        this.$element = $element;
        this.$timeout = $timeout;
        this.PF_CHAR_PER_PIXEL_CONSTANT = PF_CHAR_PER_PIXEL_CONSTANT;
        this.isReady = false;
    }


    $postLink() {
        this.clampInstructions();
    }

    $onChanges(changes) {
        if (changes.content) {
            this.lineHeight = this.lineHeight ? this.lineHeight : 24;
            this.clampInstructions();
        }
    }

    /**
     * Finds the Dom containing the content
     * @return {HTMLElement} [description]
     */
    get instructionsDOM() {
        return this.$element.find('p');
    }

    getCopyClass() {
        if (_.isEmpty(this.className)) {
            return 'pf-read-more-clamp__copy wrap-advanced';
        } else {
            return `pf-read-more-clamp__copy wrap-advanced ${this.className}`
        }
    }

    /**
     * Apply the line clamp to the instructions
     */
    clampInstructions() {
        // check if this was already successful
        if (this.isReady) {
            return;
        }
        // sanity check for content available
        if (_.isEmpty(this.content)) {
            return;
        }
        // default initial lines
        this.clamp = this.clampLines ? parseInt(this.clampLines) : 10;
        // get the height based on the clampLines paramater
        const height = this.lineHeight * this.clamp;
        // height
        this.height = `${height}px`;
        // hide read more
        this.showToggle = false;
        // Default toggle to inactive
        this.toggleOpen = false;
        // apply the clamps only if theres is more than
        // the desire lines
        if (this.isLongEnough()) {
            this.showToggle = true;
            // init the clamp and show the see more button
            this.initClamp(true);
        }
        this.isReady = true;
    }

    /**
     * Create the clamp
     * @param  {Boolean} toggleOpen whether to show more or less
     */
    initClamp(toggleOpen) {
        this.toggleOpen = toggleOpen;
    }

    /**
     * Shows less, show more toggle.
     */
    toggleClamp($event) {
        this.initClamp(!this.toggleOpen);
    }

    /**
     * Determines if content is long enough to display
     * button depending on the num of clamp lines
     **/
    isLongEnough() {
        // determine element's width
        const elementWidth = this.instructionsDOM[0].offsetWidth;
        // determine character per pixel ratio
        const charPerPixRatio = elementWidth * this.PF_CHAR_PER_PIXEL_CONSTANT[this.fontSize];

        return (this.content.split('').length > (charPerPixRatio * parseInt(this.clampLines)));
    }
}

ReadMoreClampController.$inject = [
    '$clamp',
    '$element',
    '$timeout',
    'PF_CHAR_PER_PIXEL_CONSTANT',
];

/**
 * @ngdoc component
 * @name ReadMoreClampController
 * @module portfolium.readMore
 * @description
 * @param {String} content, text content
 * @param {Number} clampLines, number of allowed content lines
 * @param {String} backgroundColor, background color
 * @param {Number} fontSize, button label font size
 * @param {String} [className], element class name
 * @param {Number} [lineHeight], line height to use for button.
 * @param {String} [seeMoreText], seeMore label
 * @param {String} [seeLessText], see less label
 */
export const ReadMoreClampComponent = {
    template: ReadMoreClampTemplate,
    bindings: {
        content: '<',
        clampLines: '<',
        seeMoreTextAriaLabel: '@?',
        seeMoreText: '@?',
        seeLessText: '@?',
        backgroundColor: '@',
        className: '@?',
        lineHeight: '<?',
        fontSize: '<'
    },
    controller: ReadMoreClampController,
    controllerAs: '$ctrl'
};
