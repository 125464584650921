import {
    FOLLOW_CATEGORIES,
    FOLLOW_CATEGORY,
    UNFOLLOW_CATEGORY,
} from 'components/category-interests/category-interests.actions';
import {categoryInterests} from 'components/category-interests/category-interests.reducer';

import {
    SET_CURRENT_USER_ABOUT,
    SET_CURRENT_USER_PRIMARY_EDUCATION,
    SET_CURRENT_USER_PRIMARY_EXPERIENCE,
    SET_CURRENT_USER_LOCATION,
} from '../../core/reducers/user-about.actions';
import {userAbout} from '../../core/reducers/user-about.reducer';

const initialState = {
    interests: [],
    userAbout: {},
    primaryEducation: {},
    primaryExperience: {},
    primaryLocation: {},
};

export const currentUser = (state = initialState, action = {}) => {
    switch (action.type) {
        case FOLLOW_CATEGORIES:
        case FOLLOW_CATEGORY:
        case UNFOLLOW_CATEGORY:
            return _.assign({}, state, {
                interests: categoryInterests(state.interests, action),
            });
        case SET_CURRENT_USER_ABOUT:
        case SET_CURRENT_USER_PRIMARY_EDUCATION:
        case SET_CURRENT_USER_PRIMARY_EXPERIENCE:
            return _.assign({}, state, userAbout(state.userAbout, action));
        case SET_CURRENT_USER_LOCATION:
            return _.assign({}, state, userAbout(state.primaryLocation, action));
        default:
            return state;
    }
};
