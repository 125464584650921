export const OPEN_PMAIL_CENTER_PANEL = 'OPEN_PMAIL_CENTER_PANEL';
export const CLOSE_PMAIL_CENTER_PANEL = 'CLOSE_PMAIL_CENTER_PANEL';
export const TOGGLE_PMAIL_CENTER_PANEL_STEP = 'TOGGLE_PMAIL_CENTER_PANEL_STEP';
export const SET_PMAIL_RECIPIENTS_AND_REQUIRED_PMAIL = 'SET_PMAIL_RECIPIENTS_AND_REQUIRED_PMAIL';
export const SET_PMAIL_STATS = 'SET_PMAIL_STATS';
export const REMOVE_PMAIL_RECIPIENT = 'REMOVE_PMAIL_RECIPIENT';

export const PMAIL_CENTER_PANEL_STEPS = {
    VALIDATION: 'VALIDATION',
    COMPOSER: 'COMPOSER',
};

export const openPmailCenterPanel = (payload) => {
    return {
        type: OPEN_PMAIL_CENTER_PANEL,
        payload,
    };
};

export const closePmailCenterPanel = (payload) => {
    return {
        type: CLOSE_PMAIL_CENTER_PANEL,
        payload,
    };
};

export const togglePmailCenterPanelStep = (payload) => {
    return {
        type: TOGGLE_PMAIL_CENTER_PANEL_STEP,
        payload,
    };
};

export const setPmailRecipientsAndRequiredPmail = (payload) => {
    return {
        type: SET_PMAIL_RECIPIENTS_AND_REQUIRED_PMAIL,
        payload,
    };
};

export const setPmailStats = (payload) => {
    return {
        type: SET_PMAIL_STATS,
        payload,
    };
};

export const removePmailRecipient = (payload) => {
    return {
        type: REMOVE_PMAIL_RECIPIENT,
        payload,
    };
};
