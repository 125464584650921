 /**
  * @ngdoc service
  * @name EntryStrengthService
  * @module portfolium.core
  * @description Service for calculating entry strength based on api definition;
  * @class EntrySettingsService
  */
export class EntryStrengthService {
    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
    }

    /**
     * Calculates the entry strength based on the api config JSON
     * @param  {object} draft
     * @return {Promise<number>}       calculated strength percentage in a promise
     */
    calculateDraftStrength(draft, debounce = 0) {

        if (_.isEmpty(draft)) {
            return this.$q.resolve('0');
        }

        let def = this.$q.defer();

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            def.resolve(
                this.$http({
                    method: 'POST',
                    url: `/proxy/drafts/strength`,
                    data: {data: JSON.stringify(draft)},
                })
                .then((response) => {
                    return response.data;
                })
            );
        }, debounce);

        return def.promise;
    }
}

EntryStrengthService.$inject = [
    '$http',
    '$q',
];
