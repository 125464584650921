export const DISCOVER_UPDATE_CATEGORY = 'DISCOVER_UPDATE_CATEGORY';
export const DISCOVER_UPDATE_DROPDOWN_OPTIONS = 'DISCOVER_UPDATE_DROPDOWN_OPTIONS';

/**
 * Update the dropdown navigation options for mobile view
 * @param {object} currentCategory The current category (if category view is active)
 */
export const updateDropdownOptions = (currentCategory) => {
    return (dispatch, getState) => {
        let options;
        const { categories } = getState().entities;

        if (currentCategory.id) {
            const parentId = currentCategory.parent_id || currentCategory.id;

            // Current category is a child
            options = _.filter(categories.data, (category) => {
                return category.parent_id === parentId;
            });
        } else {
            options = categories.parents.map(categoryId => categories.data[categoryId]);
        }

        dispatch({
            type: DISCOVER_UPDATE_DROPDOWN_OPTIONS,
            payload: {
                options,
            },
        });
    };
};

/**
 * Set the current category when transitioning to category view
 * @param {object} category New active category
 */
export const updateCategory = (category) => {
    return (dispatch, getState) => {
        dispatch(updateDropdownOptions(category));

        dispatch({
            type: DISCOVER_UPDATE_CATEGORY,
            payload: {
                category,
            },
        });
    };
};
