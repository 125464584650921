import { combineReducers } from 'redux';
import _ from 'lodash';
import {
    TASK_DATA_FETCH,
    TASK_PATHWAYS_FETCH_SUCCESS,
    TASK_COURSES_FETCH_SUCCESS,
    TASKS_RESET,
    TASK_DATA_FETCH_SUCCESS,
} from './tasks.actions';

const initialState = {
    pathways: [],
    courses: [],
    fetching: false,
};

const currentTasks = (state = initialState, action = {}) => {
    switch (action.type) {
        case TASK_DATA_FETCH:
            return _.assign({}, state, {
                fetching: true,
            });
        case TASK_DATA_FETCH_SUCCESS:
            return _.assign({}, state, {
                fetching: false,
            });
        case TASK_PATHWAYS_FETCH_SUCCESS:
            const { pathways } = action.payload;
            return _.assign({}, state, {
                pathways,
            });
        case TASK_COURSES_FETCH_SUCCESS:
            const { courses } = action.payload;
            return _.assign({}, state, {
                courses,
            });
        case TASKS_RESET:
            return initialState;
        default:
            return state;
    }
};

export const tasks = combineReducers({
    currentTasks,
});
