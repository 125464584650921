import BaseModalController from 'components/modal/js/controller/base-modal-controller';

import { addSubmission, updateActions } from '../../../pathways/pathway-requirement/pathway-requirement.actions';

import { onAssignmentFetchSuccess } from '../../../assignment/assignment.actions';

/**
 * @ngdoc AssignmentSubmitModalController
 * @name SettingsEmailEditModalController
 * @module portfolium.assignments
 * @param {Object} assignment Assignment to be submitted or unsubmitted
 **/
export class AssignmentSubmitModalController extends BaseModalController {
    constructor(
        $mdDialog,
        $ngRedux,
        $pfToast,
        mdPanelRef,
        $pfEntryEditor,
        $pfAssignments,
        $pfPathways,
        $pfAssignmentsUtilities
    ) {
        super($mdDialog);
        this.$ngRedux = $ngRedux;
        this.$pfToast = $pfToast;
        this.mdPanelRef = mdPanelRef;
        this.$pfEntryEditor = $pfEntryEditor;
        this.$pfAssignments = $pfAssignments;
        this.$pfPathways = $pfPathways;
        this.$pfAssignmentsUtilities = $pfAssignmentsUtilities;
        this.init();
    }

    init() {
        if (!this.assignment) {
            this.assignment = this.mdPanelRef.config?.locals?.assignment;
        }

        // default label name
        this.labelName = 'assignment';
        // check for edu course id
        if (_.isEmpty(this.assignment.course_id)) {
            // set label name for component
            this.labelName = 'project';
        }
    }

    /**
     * Get id of the entry related to the current assignment
     * @return {String}
     */
    get entryId() {
        return this.assignment.user_entry_id;
    }

    /**
     * Get current assignment id
     * @return {String}
     */
    get assignmentId() {
        return this.assignment.assignment_id;
    }

    /**
     * Get date of the assignment submission
     * @return {String}
     */
    get submittedAt() {
        return this.assignment.user_submitted_at;
    }

    /**
     * Get current status of the assignment
     * @return {String}
     */
    get status() {
        return this.assignment.status;
    }

    /**
     * Does the current assignment have an LMS name available
     * @return {boolean}
     */
    hasLmsName() {
        return !_.isEmpty(this.assignment.assignment_lti_lms_name);
    }

    getLmsName() {
        if (!this.hasLmsName()) {
            return;
        }

        // sanity check for blackboard because it's the only one that sends different data
        if (this.assignment.assignment_lti_lms_name.includes('blackboard')) {
            return 'blackboard';
        }

        return this.assignment.assignment_lti_lms_name;
    }

    /**
     * Does the current assignment have an LMS return URL available
     * @return {boolean}
     */
    hasLmsReturnUrl() {
        return !_.isEmpty(this.assignment.assignment_lti_return_url);
    }

    /**
     * Closes the panel
     */
    cancel() {
        this.mdPanelRef.close();
    }

    /**
     * Unsubmits an assignment and closes the panel
     */
    unSubmit() {
        this.mdPanelRef.close().then(() => {
            this.$pfAssignments.unSubmitAssignment(this.assignmentId)
                .then((assignment) => {
                    // call success callback
                    this.onUnsubmitSuccess({assignment});
                    // update assignment in the state
                    this.updateAssignment(assignment);
                }, (response) => {
                    // default error message
                    let status = `Something went wrong with unsubmitting your ${this.labelName}, please try again later.`;
                    // checks if there is an error sent
                    if (response.data && response.data.error) {
                        // get error from the API
                        status = response.data.error;
                    }
                    this.$pfToast.error(status);
                });
        });
    }

    /**
     * Submits an assignment/or requirement and closes the panel
     */
    submit() {
        // if this is a pathway requirement assignment, use the pathway requirement submit service
        if (_.isEmpty(this.assignment.course_id)) {
            return this.mdPanelRef.close()
                .then(() => this.onSubmit())
                .then(() => {
                    return this.$pfPathways.submitRequirementProject(
                        this.assignment.requirement_id,
                        this.assignment.user_entry_id
                    ).then(({ submission, actions }) => {
                        // dispatch response
                        this.$ngRedux.dispatch(addSubmission(submission));
                        this.$ngRedux.dispatch(updateActions(actions));
                        // show success message
                        this.$pfToast.success('Your work has been submitted.');
                    }).catch(() => {
                        this.$pfToast.error('Something went wrong, please try again.');
                    });
                });
        }

        return this.mdPanelRef.close()
            .then(() => this.onSubmit())
            .then(() => {
                return this.$pfAssignments.submitAssignment(this.assignmentId)
                    .then((assignment) => {
                        // call success callback
                        this.onSubmitSuccess({ assignment });
                        // update assignment in the state
                        this.updateAssignment(assignment);
                        // pop toast
                        this.$pfToast.success(`${this.labelName} submitted!`);
                    }, (response) => {
                        // default error message
                        let status = `Something went wrong with submitting your ${this.labelName}, please try again later.`;
                        // checks if there is an error sent
                        if (response.data && response.data.error) {
                            // get error from the API
                            status = response.data.error;
                        }
                        // display the error
                        this.$pfToast.error(status);
                    });
            });
    }

    updateAssignment(assignment) {
        // Update the assignment state
        this.$ngRedux.dispatch(onAssignmentFetchSuccess(assignment));
    }

    /**
     * Redirects to Entry Wizard to edit the entry
     */
    editAssignment() {
        this.mdPanelRef.close().then(() => {
            this.$pfEntryEditor.editAssignment(this.entryId, {
                source: 'adp',
            });
        });
    }
}

AssignmentSubmitModalController.$inject = [
    '$mdDialog',
    '$ngRedux',
    '$pfToast',
    'mdPanelRef',
    '$pfEntryEditor',
    '$pfAssignments',
    '$pfPathways',
    '$pfAssignmentsUtilities',
];
