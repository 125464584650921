import PmailCardBtnTemplate from '../../template/pmail-card-btn.html';
import PmailCardBtnController from '../controller/pmail-card-btn-controller';

function pmailCardBtnDirective() {
    return {
        restrict: 'E',
        scope: {
            pmail: '=pfPmail',
            pmailType: '=pfPmailType',
        },
        bindToController: true,
        controller: PmailCardBtnController,
        controllerAs: 'pmailCardBtnCtrl',
        template: PmailCardBtnTemplate,
    };
}

export default pmailCardBtnDirective;
