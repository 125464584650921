import angular from 'angular';

import HttpModule from './http/http.module';

const MODULE_NAME = 'portfolium.core.config';

(function () {
    angular.module(MODULE_NAME, [
        HttpModule,
    ]);
}());

export default MODULE_NAME;
