/**
 * @ngdoc service
 * @name FilestackPresetService
 * @module portfolium.core.services.filestack
 * @description This service is a wrapper on top of the filestack
 *              service to get the default options by upload type
 **/
export class FilestackPresetService {
    constructor(
        $pfFilestack,
        $pfFilestackConvert,
        $pfFilestackSigner,
        $pfUser,
        PF_FILESTACK_PRESETS
    ) {
        this.$pfFilestack = $pfFilestack;
        this.$pfFilestackConvert = $pfFilestackConvert;
        this.$pfFilestackSigner = $pfFilestackSigner;
        this.$pfUser = $pfUser;
        this.presets = PF_FILESTACK_PRESETS;
    }

    /**
     * Get the storing path for a preset
     * @param  {string} presetName
     * @return {string}
     */
    getPresetStoreOptions(presetName) {
        // get preset for
        const {
            storeOptions,
        } = this.presets[presetName];

        if (presetName === 'resume') {
            const { id } = this.$pfUser.getUser() || 'unknown';
            const path = storeOptions.path + id + '/';
            return _.assign({}, storeOptions, { path });
        }
        return storeOptions;
    }

    /**
     * Get the storing path for a preset
     * @param  {string} presetName
     * @return {string}
     */
    getPresetPath(presetName) {
        // get preset for
        const {
            path,
        } = this.getPresetStoreOptions(presetName);

        return path;
    }

    /**
     * Get the storing container for a preset
     * @param  {string} presetName
     * @return {string}
     */
    getPresetContainer(presetName) {
        // get preset for
        const {
            container,
        } = this.getPresetStoreOptions(presetName);

        return container;
    }

    /**
     * Get the type of file for a preset
     * @param  {string} presetName
     * @return {string}
     */
    getPresetType(presetName) {
        // get preset for
        const {
            acceptedTypes,
        } = this.presets[presetName];

        return acceptedTypes;
    }

    /**
     * Opens the Filestack picker for a preset.
     * @param  {String}   presetName         name of the preset 'attachment', 'avatar', 'cover'
     * @param  {Object}   [filestackCallbacks] Filestack callbacks functions
     * @param  {function} [filestackCallbacks.onStart] Callback for file upload start
     * @param  {function} [filestackCallbacks.onProgress] Callback for file upload progress
     * @param  {function} [filestackCallbacks.onSuccess] Callback for file upload success
     * @param  {function} [filestackCallbacks.onFail] Callback for file upload failure
     * @param  {String|Array} acceptedTypesOverride optional accepted types override for preset
     * @param  {Object}   [security] Filestack security
     * @return {Promise}  Metadata returned by the picker
     */
    pick(presetName, filestackCallbacks = {}, acceptedTypesOverride = null, security = {}) {
        // sanity check for preset
        if (!this.presets[presetName]) {
            throw new Error(`No preset found for "${presetName}"`);
        }
        // get preset for
        const {
            acceptedTypes,
            filestackOptions,
        } = this.presets[presetName];
        // create the preset store options here
        const storeOptions = this.getPresetStoreOptions(presetName);
        // sanity check
        if (security.path) {
            // set the store to
            storeOptions.path = security.path;
        }
        // set the options and the callbacks
        const presetFilestackOptions = _.assign(
            {},
            filestackOptions,
            filestackCallbacks
        );
        // call the fs picker
        return this.$pfFilestack.pick(
            storeOptions,
            { acceptedTypes: acceptedTypesOverride || acceptedTypes },
            presetFilestackOptions,
            security
        );
    }

    /**
     * Opens the Filestack file crop UI for a preset.
     * @param  {array<string|object>} filesOrUrls An array of URL or File/Blob values to transform.
     * @param  {String}   presetName         name of the preset 'attachment', 'avatar', 'cover'
     * @param  {Object}   [filestackCallbacks] Filestack callbacks functions
     * @param  {function} [filestackCallbacks.onStart] Callback for file upload start
     * @param  {function} [filestackCallbacks.onProgress] Callback for file upload progress
     * @param  {function} [filestackCallbacks.onSuccess] Callback for file upload success
     * @param  {function} [filestackCallbacks.onFail] Callback for file upload failure
     * @param  {Object}   [security] Filestack security
     * @return {Promise}  Metadata returned by the picker
     */
    cropFiles(filesOrUrls, presetName, filestackCallbacks = {}, security = {}) {
        // sanity check for preset
        if (!this.presets[presetName]) {
            // throw new error here and go
            throw new Error(`No preset found for "${presetName}"`);
        }
        // get preset for
        const {
            acceptedTypes,
            filestackOptions,
        } = this.presets[presetName];
         // get the preset store option here and go ...
        const storeOptions = this.getPresetStoreOptions(presetName);
        // sanity check
        if (security.path) {
            // set the store to
            storeOptions.path = security.path;
        }
        // set the options and the callbacks
        const presetFilestackOptions = _.assign(
            {},
            filestackOptions,
            filestackCallbacks
        );
        // call the fs picker
        return this.$pfFilestack.cropFiles(
            filesOrUrls,
            storeOptions,
            {acceptedTypes},
            presetFilestackOptions,
            security
        );
    }

    /**
     * Opens the Filestack upload for a preset.
     * @param  {String}   presetName         name of the preset 'attachment', 'avatar', 'cover'
     * @param {File|Blob|string} file Valid file, blob, or base64 encoded string to upload
     * @param  {Object}   [filestackCallbacks] Filestack callbacks functions
     * @param  {Object}   [storeOptions] Filestack storeOptions
     * @param  {Object}   [security] Filestack security
     * @param  {function} [filestackCallbacks.onProgress] Callback for file upload progress
     * @param  {Object}   [security] Filestack security
     * @return {Promise}  Metadata returned by the picker
     */
    upload(presetName, file = {}, filestackCallbacks = {}, storeOptions = {}, security = {}) {
        // sanity check for preset
        if (!this.presets[presetName]) {
            // throw a new error here and go ...
            throw new Error(`No preset found for "${presetName}"`);
        }
        // get preset for
        const {
            acceptedTypes,
            filestackOptions,
        } = this.presets[presetName];
        // get the preset store option here and go ...
        const _storeOptions = this.getPresetStoreOptions(presetName);
        // set the path here and go
        _storeOptions.path = storeOptions.path;
        // set the filename here and go
        _storeOptions.filename = this.sanitizeFilename(file.name);
        // set the options and the callbacks
        const presetFilestackOptions = _.assign(
            {},
            filestackOptions,
            filestackCallbacks
        );
        // call the fs picker
        return this.$pfFilestack.upload(
            file,
            _storeOptions,
            presetFilestackOptions,
            security
        );
    }

    /**
     * Convert an existing file into another format and upload it to Amazon S3.
     *
     * @param {string}  presetName name of the preset 'attachment', 'avatar', 'cover'
     * @param {string}  fileHandle UUID from Filestack pointing to the existing file
     * @param {string}  convertTo file extension to convert to
     * @param {Object}  [security] Filestack security
     *
     * @return {Promise<object>} Resolved with file metadata when upload is complete
     */
    convert(
        presetName,
        fileHandle,
        convertTo,
        path,
        security
    ) {
        // sanity check for preset
        if (!this.presets[presetName]) {
            throw new Error(`No preset found for "${presetName}"`);
        }
        // get preset path
        let storeOptions = this.getPresetStoreOptions(presetName);
        // set the store path here
        storeOptions.path = path;
        // call the fs convert
        return this.$pfFilestack.convert(
            fileHandle,
            convertTo,
            storeOptions,
            security
        );
    }

    /**
     * Upload a new file to Amazon S3 using multi-part chunking. The uploaded
     * content is served immediately via [Filestack's CIN](https://www.filestack.com/docs/advanced-uploads)
     *
     * @param {string}   presetName         name of the preset 'attachment', 'avatar', 'cover'
     * @param {string}   url              file url to store
     *
     * @return {Promise<object>} Resolved with file metadata when upload is complete
     * @memberof FilestackPresetService
     **/
    storeUrl(presetName, url, storeOptions = {}, security = {}) {
        // sanity check for preset
        if (!this.presets[presetName]) {
            // throw and new error here
            throw new Error(`No preset found for "${presetName}"`);
        }
        // get preset path
        let path = this.getPresetPath(presetName);
        // sanity check
        if (storeOptions.path) {
            // overwrite the path
            path = storeOptions.path;
        }
        // get preset container
        let container = this.getPresetContainer(presetName);
        // sanity check
        if (storeOptions.container) {
            // overwrite the container
            container = storeOptions.container;
        }
        // call the fs store url
        return this.$pfFilestack.storeUrl(url, { path, container: container }, security);
    }

    /**
     * Remove a file by handle
     *
     * @param {String} [handle] Handle of file to delete
     * @param {Object} [security] Filestack security
     *
     * @return {Promise<object>}
     * @memberof FilestackPresetService
     */
    remove(handle, security = {}) {
        // remove here with the handle
        return this.$pfFilestack.remove(handle, security);
    }

    /**
     * Validate a mimetype for a preset
     *
     * @param {string} presetName name of the preset 'attachment', 'avatar', 'cover'
     * @param {Object} meta the file to verify
     *
     * @return {Promise<object>}
     * @memberof FilestackPresetService
     */
    isValidMimeTypeForPreset(presetName, meta) {
        // sanity check for preset
        if (!this.presets[presetName]) {
            // throw and new error here
            throw new Error(`No preset found for "${presetName}"`);
        }
        // get the file here
        const file = meta.originalFile || meta;
        // get the mimetype
        const mimetype = file.type || file.mimetype;
        // sanity check
        if (mimetype) {
            // get the preset type here
            const presetType = this.getPresetType(presetName);
            // sanity check the mimetype for this type
            return this.$pfFilestackConvert.mimetypeBelongsToType(mimetype, presetType);
        }
        // return here
        return false;
    }

    /**
     * Launch the picker
     *
     * @return {Promise<object>}
     * @memberof FilestackPresetService
     */
    picker(presetName, { onFileUploadStarted }, success, failure) {
        // get the security policy for an upload
        return this.$pfFilestackSigner.getPickerPolicy(presetName).then(security => {
            let activeElement;
            // call the picker here
            this.pick(
                presetName,
                {
                    onOpen: () => {
                        activeElement = document.activeElement;
                        // Work around missing keyboard focus in the modal
                        // https://github.com/filestack/filestack-js/issues/295
                        const observer = new MutationObserver(() => {
                            const el = document.querySelector('#__filestack-picker [tabindex]');
                            if (el) {
                                el.focus();
                                observer.disconnect();
                            }
                        });
                        observer.observe(document.body, { childList: true, subtree: true });
                    },
                    onClose: () => {
                        activeElement.focus();
                    },
                    // on file selected
                    onFileSelected: file => {
                        // sanity check
                        this.mimeTypePanic(presetName, file);
                        // sanitize the file
                        return this.sanitizeFile(file);
                    },
                    onFileUploadStarted,
                    // Configure picker callbacks to execute our callbacks
                    onFileUploadFinished: original => {
                        // need this
                        let doUpload = false;
                        // need this to get the presets
                        const presets = this.presets[presetName];
                        // sanity check
                        if (presets.filestackOptions.disableTransformer === false) {
                            // need this
                            const localUpload = (original.source && original.source === 'local_file_system');
                            // sanity check
                            if (original.cropped || localUpload) {
                                // set the flag
                                doUpload = true;
                            }
                        } else {
                            // set the flag
                            doUpload = true;
                        }
                        // sanity check
                        if (doUpload) {
                            // go through all the crazy goodness to store and delete
                            this.storeAndDelete(presetName, original, security, file => {
                                // call the callback
                                success(original, file, security);
                            }, error => {
                                // call the callback
                                failure(error);
                            });
                        }
                    },
                    onFileUploadFailed: (file, error) => {
                        // call the callback
                        failure(error);
                    },
                },
                null,
                security
            );
        }).catch(error => {
            // call the callback
            failure(error);
        });
    }

    /**
     * Do the Dew
     *
     * @param {string} presetName name of the preset 'attachment', 'avatar', 'cover'
     * @param {Object} meta the file to verify
     * @param {Object} [security] Filestack security
     *
     * @return {Promise<object>}
     * @memberof FilestackPresetService
     */
    storeAndDelete(presetName, original, security, success, failure) {
        // sanity check for preset
        if (!this.presets[presetName]) {
            // throw and new error here
            throw new Error(`No preset found for "${presetName}"`);
        }
        // get preset path
        let storeOptions = this.getPresetStoreOptions(presetName);
        // reset the store path to the proper preset
        storeOptions.path = original.key + '_' + original.filename;
        // create the signed url here - we need to add it for the read
        const signedUrl = this.$pfFilestackSigner.signUrl(original.url, security);
        // Trigger the Filestack picker UI
        this.storeUrl(presetName, signedUrl, storeOptions, security).then(file => {
            // get the security policy for a remove
            return this.$pfFilestackSigner.getRemovePolicy(original.key).then(security => {
                // remove the file handle here and go
                this.remove(original.handle, security).then((message) => {
                    // call the success, jack
                    success(file);
                // catch here
                }).catch((error) => {
                    // on error
                    failure(error);
                });
            }).catch(error => {
                // on error
                failure(error);
            });
        }).catch(error => {
            // on error
            failure(error);
        });
    }

    /**
     * Panic!
     *
     * @param {string} presetName name of the preset 'attachment', 'avatar', 'cover'
     * @param {Object} file the file to verify
     *
     * @throws Error
     * @memberof FilestackPresetService
     */
    mimeTypePanic(presetName, file) {
        // sanity check for preset
        if (!this.presets[presetName]) {
            // throw and new error here
            throw new Error(`No preset found for "${presetName}"`);
        }
        // sanity check
        if (!this.isValidMimeTypeForPreset(presetName, file)) {
            // this error is thrown in the picker viewer
            throw new Error('Sorry, that file type isn\'t supported');
        }
    }

    /**
     * convertPath
     *
     * @param {string} path Path to the new file
     * @param {string} convertTo The new file extension
     *
     * @return {string}
     * @memberof FilestackPresetService
     */
    convertPath(path, convertTo) {
        // sanity check
        if (!path.endsWith('/')) {
            // get the extension here
            const extension = path.slice((Math.max(0, path.lastIndexOf(".")) || Infinity) + 1);
            // sanity check
            if (extension) {
                // sanity check
                if (extension !== convertTo) {
                    // set the path
                    path = path.replace(new RegExp(extension + '$'), convertTo);
                }
            } else {
                // add the convert to extension
                path = `${path}.${convertTo}`;
            }
        }
        // return here
        return path;
    }

    /**
     * sanitizeFilename
     *
     * @param {string} filename
     *
     * @return {string}
     * @memberof FilestackPresetService
     */
    sanitizeFilename(filename) {
        // return the sanitized filename
        return filename.replace(/([^a-zA-Z0-9\._-]+)/gi, '_');
    }

    /**
     * sanitizeFile
     *
     * @param {File} file
     *
     * @return {string}
     * @memberof FilestackPresetService
     */
    sanitizeFile(file) {
        // return
        return {
            name: this.sanitizeFilename(file.filename),
            filename: file.filename,
            handle: file.handle,
            mimetype: file.mimetype,
            originalPath: file.originalPath,
            size: file.size,
            source: file.source,
            url: file.url,
            uploadId: file.uploadId,
            originalFile: file.originalFile,
        };
    }
}

FilestackPresetService.$inject = [
    '$pfFilestack',
    '$pfFilestackConvert',
    '$pfFilestackSigner',
    '$pfUser',
    'PF_FILESTACK_PRESETS',
];
