import angular from 'angular';
import {
    ABOUT_PANEL_TABS,
    openAboutPanel,
    closeAboutPanel,
} from '../action/profile-about-panel';

export class ProfileAboutPanelService {
    constructor(
        $q,
        $timeout,
        $mdPanel,
        $mdDialog,
        $mdMedia,
        $ngRedux,
        panelConfig
    ) {
        this._$q = $q;
        this._$timeout = $timeout;
        this._$mdDialog = $mdDialog;
        this._$mdPanel = $mdPanel;
        this._$ngRedux = $ngRedux;
        this._panelConfig = panelConfig;
        this.$mdMedia = $mdMedia;
    }

    /**
     * Create a new panel instance
     * @param  {Object}     options Panel configuration options
     * @return {MdPanelRef}
     */
    _createPanel(options = {}) {
        // Assign default option values with optional overrides
        options = _.assign({}, {
            onSubmit: angular.noop,
            locals: {},
            panelClass: '',
        }, options);

        const isMobile = !this.$mdMedia('gt-xs');
        const width = 512;
        const windowHeight = angular.element(window).height();
        const animationPosition = {
            top: (isMobile) ? windowHeight : 0,
            left: (isMobile) ? 0 : -width,
        };
        const position = this._$mdPanel.newPanelPosition()
            .absolute()
            .top(0)
            .left(0);
        const animation = this._$mdPanel.newPanelAnimation()
            .openFrom(animationPosition)
            .closeTo(animationPosition)
            .withAnimation(this._$mdPanel.animation.SLIDE);
        const config = {
            controller: angular.noop,
            controllerAs: '$ctrl',
            template: `
                <pf-profile-about-panel
                     pf-on-submit="$ctrl.onSubmit"
                     md-panel-ref="$ctrl.mdPanelRef">
                </pf-profile-about-panel>
            `,
            locals: _.assign({}, options.locals, {
                onSubmit: options.onSubmit,
            }),
            panelClass: 'pf-panel pf-sidenav-panel ' + options.panelClass,
            attachTo: angular.element(document.body),
            position: position,
            animation: animation,
            hasBackdrop: true,
            trapFocus: true,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 70,
            onCloseSuccess: options.onCloseSuccess,
            onDomRemoved: () => {
                // Update application state
                this._$ngRedux.dispatch(closeAboutPanel());
                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
        };
        const panel = this._$mdPanel.create(config);

        return panel;
    }

    /**
     * Open a panel by name
     * @param  {String}              panelName       Name of the registered panel
     * @param  {Object}              [data]          Data to pass as view model
     * @param  {String}              [focusSelector] Element selector to focus after opening
     * @param  {Boolean}             [attachEntries] If true, panel will open to attach entries tab
     * @param  {Function}            [onCloseSuccess] The function to call when the panel is closed
     * @return {Promise<MdPanelRef>}
     */
    _open(panelName, data = {}, focusSelector = '', attachEntries = false, onCloseSuccess) {
        const panelConfig = _.assign({}, this._panelConfig[panelName], {onCloseSuccess});

        if (!panelConfig) {
            return this._$q.reject('Panel not found.');
        }

        // Initialize state
        this._$ngRedux.dispatch(openAboutPanel({
            title: panelConfig.locals.title,
            label: data[panelConfig.locals.labelField],
            aboutType: panelName,
            activeTab: (attachEntries) ? ABOUT_PANEL_TABS.WORK_SAMPLES : ABOUT_PANEL_TABS.EDIT_DETAILS,
            data: angular.copy(data), // Important -- needs to be a copy
        }));

        const panel = this._createPanel(panelConfig);

        // Hide any dialogs if they are open
        return this._$mdDialog.hide().then(() => {
            // Open the panel
            return panel.open().then(() => {
                const focusEl = angular.element(focusSelector);

                this._$timeout(() => {
                    // Set focus once panel is open
                    if (focusEl.length) {
                        focusEl.eq(0).focus();
                    } else {
                        // TODO: Add default focus case
                    }
                });
            });
        });
    }

    /**
     * Open a panel to add an about record
     * @param  {String}              panelName       Name of the registered panel
     * @param  {Function}            onCloseSuccess  The function called when the panel is close
     * @return {Promise<MdPanelRef>}           Resolved when panel opens
     */
    addAbout(panelName, onCloseSuccess) {
        return this._open(panelName, {}, '', false, onCloseSuccess);
    }

    /**
     * Open a panel to edit an about record
     * @param  {String}              panelName       Name of the registered panel
     * @param  {Object}              data            Data to pass as view model
     * @param  {String}              [focusSelector] Element selector to focus after opening
     * @param  {Boolean}             [attachEntries] If true, panel will open to attach entries tab
     * @param  {Function}            onCloseSuccess  The function called when the panel is close
     * @return {Promise<MdPanelRef>}                 Resolved when panel opens
     */
    editAbout(panelName, data, focusSelector = '', attachEntries = false, onCloseSuccess) {
        return this._open(panelName, data, focusSelector, attachEntries, onCloseSuccess);
    }
}
