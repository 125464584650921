/**
 * @ngdoc service
 * @name BaseAssignmentUtilitiesService
 * @description A collection of shared utilities to be used between assignments and requirements.
 * Don't forget the super() when extending
 *      super(
 *           $filter,
             $pfEntryDetails,
 *           PF_ASSIGNMENTS_STATUS,
 *           PF_ASSIGNMENTS_STATUS_MAPPED,
 *           PF_ASSIGNMENT_ACTION_TYPE_CODES,
 *           PF_REQUIREMENT_ACTION_TYPE_CODES,
 *           PF_ENTRY_CLASSIFICATIONS,
 *           PF_PATHWAY_REQUIREMENT_TYPES
 *      );
 * **/
 export class BaseAssignmentUtilitiesService {
     constructor(
        $filter,
        $pfEntryDetails,
        PF_ASSIGNMENTS_STATUS,
        PF_ASSIGNMENTS_STATUS_MAPPED,
        PF_ASSIGNMENT_ACTION_TYPE_CODES,
        PF_REQUIREMENT_ACTION_TYPE_CODES,
        PF_ENTRY_CLASSIFICATIONS,
        PF_PATHWAY_REQUIREMENT_TYPES,
     ) {
        this.$filter = $filter;
        this.$pfEntryDetails = $pfEntryDetails;
        this.PF_ASSIGNMENTS_STATUS = PF_ASSIGNMENTS_STATUS;
        this.PF_ASSIGNMENTS_STATUS_MAPPED = PF_ASSIGNMENTS_STATUS_MAPPED;
        this.PF_ASSIGNMENT_ACTION_TYPE_CODES = PF_ASSIGNMENT_ACTION_TYPE_CODES;
        this.PF_REQUIREMENT_ACTION_TYPE_CODES = PF_REQUIREMENT_ACTION_TYPE_CODES;
        this.PF_ENTRY_CLASSIFICATIONS = PF_ENTRY_CLASSIFICATIONS;
        this.PF_PATHWAY_REQUIREMENT_TYPES = PF_PATHWAY_REQUIREMENT_TYPES;
        this.LETTER_GRADES = {
            a: 'A',
            b: 'B',
            c: 'C',
            d: 'D',
            f: 'F',
        };
        this.LETTER_GRADE_NUMERIC_SCORES = {
            a: 100,
            b: 85,
            c: 75,
            d: 65,
            f: 50,
        };
    }

    /**
     * Determine if an entry is classified as either a course assignment or pathway requirement
     * "isAssignment applies to both throughout the code
     * @param  {Object}  entry
     * @return {Boolean}
     */
    isAssignment(entry) {
        const entryClassification = this.$pfEntryDetails.getEntryClassification(entry);
        if (entryClassification === this.PF_ENTRY_CLASSIFICATIONS.default) {
            return false;
        }
        return true;
    }

    /**
     * Checks if an assignment type is assigned (not completed or incomplete score)
     * @param  {Object}  assignment
     * @return {Boolean}
     */
    isAssigned(assignment) {
        return !this.isCompleted(assignment) && !this.isIncomplete(assignment);
    }

    /**
     * Checks if an assignment type is finished (completed or incomplete score)
     * @param  {Object}  assignment
     * @return {Boolean}
     */
    isFinished(assignment) {
        return !this.isAssigned(assignment);
    }

    /**
     * Checks if an assignment type is not started
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isNotStarted(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.getStatusCode(assignment) === 'NOT_STARTED';
    }

    /**
     * Checks if an assignment type is in progress
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isInProgress(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.getStatusCode(assignment) === 'IN_PROGRESS';
    }

    /**
     * Checks if an assignment type is submitted
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isSubmitted(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.getStatusCode(assignment) === 'SUBMITTED';
    }

        /**
     * Checks if an assignment needs revision
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    needsRevision(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.getStatusCode(assignment) === 'NEEDS_REVISION';
    }

        /**
     * Checks if an assignment type is completed
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isCompleted(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.getStatusCode(assignment) === 'COMPLETED';
    }

    /**
     * Checks if an assignment was scored as incomplete
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isIncomplete(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.getStatusCode(assignment) === 'INCOMPLETE';
    }

    /**
     * Checks if an requirement was scored as not satisfied
     * @param  {Object}  requirement
     * @return {Boolean|undefined}
     */
    isNotSatisfied(requirement) {
        if (_.isEmpty(requirement)) {
            return;
        }
        return this.getStatusCode(requirement) === 'NOT_SATISFIED';
    }

    /**
     * Checks if a submission is required for this requirement type
     * @param  {Object}  requirement
     * @return {Boolean}
     */
    isSubmissionRequired(requirement) {
        // if there is no requirement, this is an assignment and a submission IS required
        if (_.isEmpty(requirement)) {
            return true;
        }
        return requirement.type !== this.PF_PATHWAY_REQUIREMENT_TYPES.experience;
    }

    isInASubmittedLikeState(assignment) {
        const {
            submitted,
            completed,
            pendingReview,
            incomplete,
            notSatisfied,
        } = this.PF_ASSIGNMENTS_STATUS_MAPPED;

        switch (parseInt(assignment.status)) {
            case submitted:
            case completed:
            case incomplete:
            case pendingReview:
            case notSatisfied:
                return true;
            default:
                return false;
        }
    }

    isInAFinishedLikeState(assignment) {
        const {
            completed,
            notSatisfied,
            incomplete,
        } = this.PF_ASSIGNMENTS_STATUS_MAPPED;

        switch (parseInt(assignment.status)) {
            case completed:
            case notSatisfied:
            case incomplete:
                return true;
            default:
                return false;
        }
    }

    /**
     * Checks if a duedate is passed todays date
     * @param  {String}  duedate
     * @return {Boolean}
     */
    isLateBase(dueDate) {
        if (_.isNil(dueDate)) {
            return false;
        }
        const date = new Date();
        const dueDateTimezone = this.$filter('momentDatetime')(dueDate);
        const dateDiff = this.$filter('momentDiff')(dueDateTimezone, date);
        return dateDiff < 0;
    }

    getInstructionClampLines(assignment) {
        if (this.isInAFinishedLikeState(assignment)) {
            return 4;
        }
        return 12;
    }

    getScoredCriteriaDescription({
        level_of_achievements: levelOfAchievements,
        fk_loa_id: selectedLevelOfAchievementId
    }) {
        const levelOfAchievement = _.find(
            levelOfAchievements,
            ['id', selectedLevelOfAchievementId]
        );

        if (_.isNil(levelOfAchievement) || _.isNil(levelOfAchievement.description)) {
            return;
        }

        return levelOfAchievement.description;
    }

    /**
     * Finds the type status name of an assignment
     * @param  {Object} assignment
     * @return {String|undefined} assignment status name
     */
    getStatusName(assignment) {
        // sanity check for assignment
        if (_.isEmpty(assignment)) {
            // return undefined
            return;
        }
        // try get status
        const status = this.getBaseStatus(assignment);
        // sanity check for status
        if (!status) {
            // return undefined
            return;
        }
        // return the name if found
        return status.name;
    }

    /**
     * Finds the type status code of an assignment
     * @param  {Object} assignment
     * @return {String|undefined} assignment status code
     */
    getStatusCode(assignment) {
        const status = this.getBaseStatus(assignment);
        if (!status) {
            return;
        }
        return status.code;
    }

    /**
     * Builds and returns the sub title for the submission card
     * @param {Boolean} isSubmitted Is the submission submitted
     * @param {String} submittedAt Date string of when the submission was submitted
     * @param {String} updatedAt Date string of when the submission was updated
     * @param {Number} attachmentCount How many attachments does the submission have
     * @return {String}
     */
    getSubmissionSubTitleBase({
        isSubmitted,
        submittedAt,
        updatedAt,
        attachmentCount,
    }) {
        const pluralizedAttachments = attachmentCount === 1 ? this.$filter('i18n')('Attachment') : this.$filter('i18n')('Attachments');
        const attachmentCountString = `${attachmentCount} ${pluralizedAttachments}`;
        if (isSubmitted) {
            const submittedAtTimezone = this.$filter('momentDatetime')(submittedAt, 'M/D/YY');
            return `${attachmentCountString} • ${this.$filter('i18n')('Submitted')} ${submittedAtTimezone}`;
        }
        if (!_.isNil(updatedAt)) {
            const updatedAtTimezone = this.$filter('momentDatetime')(updatedAt, 'M/D/YY');
            return `${attachmentCountString} • ${this.$filter('i18n')('Last modified')} ${updatedAtTimezone}`;
        }
        return attachmentCountString;
    }

    /**
     * Finds the status of an Assignment
     * @param  {Object} assignment
     * @return {Object|undefined} assignment status
     */
    getBaseStatus(assignment) {
        // sanity check for assignment
        if (_.isEmpty(assignment)) {
            return;
        }
        // sanity check for status
        if (_.isNil(assignment.status)) {
            return;
        }
        // get int version of code
        const currentStateCode = parseInt(assignment.status, 10);
        // get state object for assignment status code
        const stateObject = _.find(this.PF_ASSIGNMENTS_STATUS, assignmentStatus => {
            return assignmentStatus.id === currentStateCode;
        });
        // get state object
        return stateObject;
    }

    getLetterGrade(currentScore) {
        const letterGradeNumericScore = this.calculateLetterGradeScore(currentScore);
        let letterGrade = null;

        if (letterGradeNumericScore === this.LETTER_GRADE_NUMERIC_SCORES.a) {
            letterGrade = this.LETTER_GRADES.a;
        } else if (letterGradeNumericScore === this.LETTER_GRADE_NUMERIC_SCORES.b) {
            letterGrade = this.LETTER_GRADES.b;
        } else if (letterGradeNumericScore === this.LETTER_GRADE_NUMERIC_SCORES.c) {
            letterGrade = this.LETTER_GRADES.c;
        } else if (letterGradeNumericScore === this.LETTER_GRADE_NUMERIC_SCORES.d) {
            letterGrade = this.LETTER_GRADES.d;
        } else {
            letterGrade = this.LETTER_GRADES.f;
        }

        return letterGrade;
    }

    /**
     * Calculates letter grade version of passed in score
     * @param  {Object} currentScore
     * @return {Number}
     */
    calculateLetterGradeScore(currentScore) {
        if (currentScore <= 1.5) {
            // score is ~about an A
            currentScore = this.LETTER_GRADE_NUMERIC_SCORES.a;
        } else if (currentScore > 1.5 && currentScore <= 2.5) {
            // score is ~about a B
            currentScore = this.LETTER_GRADE_NUMERIC_SCORES.b;
        } else if (currentScore > 2.5 && currentScore <= 3.5) {
            // score is ~about a C
            currentScore = this.LETTER_GRADE_NUMERIC_SCORES.c;
        } else if (currentScore > 3.5 && currentScore <= 4.5) {
            // score is ~about a D
            currentScore = this.LETTER_GRADE_NUMERIC_SCORES.d;
        } else {
            // score is ~about an F
            currentScore = this.LETTER_GRADE_NUMERIC_SCORES.f;
        }

        return currentScore;
    }

    /**
     * Calculates pass/fail version of passed in score
     * @param  {Object} currentScore
     * @return {Number}
     */
    calculatePassFailScore(currentScore) {
        return currentScore === 1 ? 1 : 0;
    }

    getBaseRequestedRevisionsForFeedbackComponent(actions) {
        if (_.isEmpty(actions)) {
            return false;
        }
        const { REVISION_REQUESTED } = this.PF_ASSIGNMENT_ACTION_TYPE_CODES;
        const requestRevisionActions = _.filter(actions, { 'type': REVISION_REQUESTED });

        return requestRevisionActions.map((action) => {
            return {
                id: action.id,
                message: action.comment,
                profile: action.faculty,
                created_at: action.created_at,
            }
        });
    }

    getAllLearningOutcomesBase(assignment) {
        const criterias = _.get(assignment, 'rubric.criterias');
        if (_.isEmpty(criterias)) {
            return;
        }
        let learningOutcomes = [];
        _.each(criterias, ({ outcomes }) => {
            if (!_.isEmpty(outcomes)) {
                _.each(outcomes, ({ title }) => {
                    if (!_.isEmpty(title)) {
                        learningOutcomes.push(title);
                    }
                });
            }
        });
        return _.uniq(learningOutcomes);
    }

    showInstructionsHeader(assignment) {
        if (this.showReviewSection(assignment)) {
            return false;
        }
        return true;
    }

    showReviewSection(assignment) {
        return this.isInAFinishedLikeState(assignment);
    }

    processCriteriaScore(score) {
        if (_.isEmpty(score)) {
            return 0;
        }
        return parseFloat(score);
    }
}

BaseAssignmentUtilitiesService.$inject = [
    '$filter',
    '$pfEntryDetails',
    'PF_ASSIGNMENTS_STATUS',
    'PF_ASSIGNMENTS_STATUS_MAPPED',
    'PF_ASSIGNMENT_ACTION_TYPE_CODES',
    'PF_REQUIREMENT_ACTION_TYPE_CODES',
    'PF_ENTRY_CLASSIFICATIONS',
    'PF_PATHWAY_REQUIREMENT_TYPES',
];
