/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerPanelService
 * @class EntryViewerPreviewController
 */
class EntryViewerPreviewController {
    /**
     * Close the panel
     */
    close() {
        this.mdPanelRef.close();
    }
}

/**
 * @ngdoc service
 * @module portfolium.component.entryViewer
 * @name EntryViewerPanelService
 * @class EntryViewerPanelService
 * @description View an entry's details inside a popup panel
 */
export class EntryViewerPanelService {
    constructor($mdPanel, PF_ENTRY_VERSION_STATES) {
        this.$mdPanel = $mdPanel;
        this.PF_ENTRY_VERSION_STATES = PF_ENTRY_VERSION_STATES;
    }

    /**
     * Create an $mdPanel instance with the entry viewer
     * @param {object} options Configuration options (see `createPanel`) for details
     * @param {object} options.entry Entry data to display in the preview
     * @param {boolean} options.isPreview Is the user viewing a preview or the real EDP?
     * @param {Event} [options.targetEvent] Click event that triggered the preview
     * @param {function} [callbacks.onPanelOpen] Callback for modal open action
     * @param {function} [callbacks.onPanelClose] Callback for modal close action
     * @return {MdPanelRef}
     */
    createPanel(
        {
            targetEvent = null,
            locals = {
                entry: entry = {},
                isPreview: isPreview = true,
                version: version = this.PF_ENTRY_VERSION_STATES.LIVE,
            },
            controller = EntryViewerPreviewController,
        },
        {
            onPanelOpen = () => { },
            onPanelClose = () => { },
        },
    ) {
        const attachTo = angular.element(document.body);
        const config = {
            attachTo,
            bindToController: true,
            clickOutsideToClose: true,
            controller,
            controllerAs: '$ctrl',
            escapeToClose: true,
            focusOnOpen: true,
            fullscreen: true,
            hasBackdrop: true,
            locals,
            targetEvent,
            onDomAdded: () => {
                // add class to be able to darken the panel backdrop
                attachTo.addClass('pf-entry-viewer-panel-active');
                // call the callback caller
                onPanelOpen();
            },
            onRemoving: () => {
                // remove class so it doesnt affect other panels
                attachTo.removeClass('pf-entry-viewer-panel-active');
            },
            onDomRemoved: () => {
                // remove class so it doesnt affect other panels
                attachTo.removeClass('pf-entry-viewer-panel-active');
                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
            onCloseSuccess: (mdpanelRef, reason) => {
                // call the callback caller
                onPanelClose(reason);
            },
            panelClass: 'pf-entry-viewer-panel',
            template: `
                <div class="pf-entry-viewer-wrapper"
                     ng-click="$ctrl.close()"
                     tabindex="-1">
                    <pf-entry-viewer
                        contests="$ctrl.contests"
                        is-preview="$ctrl.isPreview"
                        is-loading="$ctrl.isLoading"
                        version="$ctrl.version"
                        entry="$ctrl.entry"
                        entry-comments="$ctrl.entryComments"
                        on-add-comment="$ctrl.addComment(comment)"
                        on-close="$ctrl.close()"
                        on-delete-comment="$ctrl.deleteComment(commentId)"
                        on-remove-self-as-teammate="$ctrl.removeSelfAsTeammate(userId)"
                        on-like-entry="$ctrl.likeEntry()"
                        on-next-comments="$ctrl.onNextComments()">
                    </pf-entry-viewer>
                    <md-button type="button"
                         aria-label="Close"
                         class="md-icon-button pf-entry-viewer-close"
                         ng-click="$ctrl.onClose()">
                        <md-icon>close</md-icon>
                    </md-button>
                </div>
            `,
            zIndex: 78, // keep this one level lower than standard mddialogs, fixes dialog on dialog backdrop
            trapFocus: true,
        };

        const panel = this.$mdPanel.create(config);

        return panel;
    }

    /**
     * Open the entry viewer in preview mode (UI-Router state change will not be
     * initiated)
     * @param {object} options Configuration options (see `createPanel`) for details
     * @param {object} options.entry Entry data to display in the preview
     * @param {Event} [options.targetEvent] Click event that triggered the preview
     * @param {function} [callbacks.onPanelOpen] Callback for modal open action
     * @param {function} [callbacks.onPanelClose] Callback for modal close action
     * @return {Promise} Resolved when the preview is opened
     */
    openPreview(options = {}, callbacks = {}) {
        return this.createPanel(options, callbacks).open();
    }

    closePreview() {
        if (!this.panel) {
            return;
        }
        return this.panel.close();
    }
}

EntryViewerPanelService.$inject = [
    '$mdPanel',
];
