import angular from 'angular';

import { infiniteScrollDirective } from './infinite-scroll.directive';
import { reduxInfiniteScrollDirective } from './redux-infinite-scroll.directive';
import { infiniteScrollLoaderDirective } from './infinite-scroll-loader.directive';
import { InfiniteScrollService } from './rx-infinite-scroll.service';
import PfInfiniteScrollService from './infinite-scroll.service';

const MODULE_NAME = 'portfolium.component.infiniteScroll';

(function() {
    /**
     * @ngdoc module
     * @name portfolium.component.infiniteScroll
     * @author Chris Guerrero <chris@portfolium.com>
     */
    angular
        .module(MODULE_NAME, [])
        // Directive
        .directive('pfInfiniteScroll', infiniteScrollDirective)
        .directive('pfInfiniteScrollLoader', infiniteScrollLoaderDirective)
        .directive('pfReduxInfiniteScroll', reduxInfiniteScrollDirective)
        // Service
        .service('$pfInfinite', InfiniteScrollService)
        // Deprecated
        .service('$pfInfiniteScroll', PfInfiniteScrollService);
})();

export default MODULE_NAME;
