import _ from 'lodash';

import {getFilterReducer} from './filter.reducer';
import {getSelectionReducer} from './selection.reducer';

/**
 * Creates a reducer for managing pagination, given the action types to handle
 * @param {Object} config Configuration options for the reducer
 * @param {Object} config.actionTypes Pagination action types
 * @param {Int} config.limit Default limit for query
 * @param {Object} config.paginationStyle Pagination style ("standard" or "infinite")
 * @return {Function} Pagination reducer
 */
export const getPaginationReducer = ({
    actionTypes = {},
    limit = 10,
    paginationStyle = 'standard',
}) => {
    const {
        PAGE_INIT,
        PAGE_PREVIOUS,
        PAGE_NEXT,
        PAGE_REQUEST,
        PAGE_SUCCESS,
        PAGE_ERROR,
        ADD_SINGLE_RESULT,
        PREPEND_SINGLE_RESULT,
        REMOVE_SINGLE_RESULT,
        UPDATE_SINGLE_RESULT,
        TOGGLE_SELECTION,
        CLEAR_SELECTION,
        TOGGLE_ALL,
        ADD_FILTER,
        REMOVE_FILTER,
        RESET_FILTERS,
    } = actionTypes;

    const selectionReducer = getSelectionReducer({actionTypes});
    const filterReducer = getFilterReducer({actionTypes});

    const initialState = {
        isFetching: false,
        noMoreResults: false,
        results: [],
        selected: [],
        isAllSelected: false,
        pageNumber: 1,
        offset: 0,
        limit,
        filters: {},
        sorting: {},
        query: {
            offset: 0,
            limit: 11,
        },
    };

    const pagination = (state = initialState, action) => {
        switch (action.type) {
            case PAGE_INIT: {
                const {page} = action.payload;
                const offset = ((page - 1) * state.limit);

                return _.assign({}, initialState, {
                    offset,
                    filters: state.filters,
                    sorting: state.sorting,
                    query: _.assign({}, state.query, {
                        offset,
                        limit: state.limit + 1,
                    }),
                    pageNumber: page,
                });
            }
            case PAGE_PREVIOUS: {
                const offset = state.offset - state.limit;

                if (offset < 0) {
                    // Can't go back any further
                    return state;
                }

                return _.assign({}, state, {
                    offset,
                    query: _.assign({}, state.query, {
                        offset,
                        limit: state.limit + 1,
                    }),
                    pageNumber: state.pageNumber - 1,
                });
            }
            case PAGE_NEXT:
                if (state.noMoreResults) {
                    // No more results to fetch
                    return state;
                }

                return _.assign({}, state, {
                    offset: state.offset + state.limit,
                    query: _.assign({}, state.query, {
                        offset: state.offset + state.limit,
                        limit: state.limit + 1,
                    }),
                    pageNumber: state.pageNumber + 1,
                });
            case PAGE_REQUEST:
                return _.assign({}, state, {
                    isFetching: true,
                });
            case PAGE_SUCCESS: {
                let updatedResults;
                const newResults = action.payload.results || [];

                if (paginationStyle === 'infinite') {
                    updatedResults = [
                        ...state.results,
                        ...newResults.slice(0, state.limit),
                    ];

                    updatedResults = _.uniqBy(updatedResults ,'id');
                } else {
                    updatedResults = newResults.slice(0, state.limit);
                }

                return _.assign({}, state, {
                    isFetching: false,
                    noMoreResults: !(newResults.length > state.limit),
                    results: updatedResults,
                });
            }
            case PAGE_ERROR:
                return _.assign({}, state, {
                    isFetching: false,
                });
            case ADD_SINGLE_RESULT: {
                const newResult = action.payload.result;

                let updatedResults = [
                    ...state.results,
                    newResult,
                ];

                updatedResults = _.uniqBy(updatedResults ,'id');

                return _.assign({}, state, {
                    results: updatedResults,
                });
            }
            case PREPEND_SINGLE_RESULT: {
                const newResult = action.payload.result;

                let updatedResults = [
                    newResult,
                    ...state.results,
                ];

                updatedResults = _.uniqBy(updatedResults ,'id');

                return _.assign({}, state, {
                    results: updatedResults,
                });
            }
            case REMOVE_SINGLE_RESULT: {
                // get payload values
                const {keyName, keyValue} = action.payload;

                // find the index in the results of the updated object
                const index = _.findIndex(state.results, {
                    [keyName]: keyValue,
                });

                // return state if not found
                if (_.isUndefined(index)) {
                    return state;
                }

                // update and return new result
                return _.assign({}, state, {
                    results: [
                        ...state.results.slice(0, index),
                        ...state.results.slice(index + 1, state.results.length)
                    ]
                });
            }
            case UPDATE_SINGLE_RESULT: {
                // get payload values
                const {result, keyName, keyValue} = action.payload;

                // find the index in the results of the updated object
                const index = _.findIndex(state.results, {
                    [keyName]: keyValue,
                });

               // return state if not found
                if (_.isUndefined(index)) {
                    return state;
                }

                // update and return new result
                return _.assign({}, state, {
                    results: [
                        ...state.results.slice(0, index),
                        result,
                        ...state.results.slice(index + 1, state.results.length)
                    ]
                });
            }
            case TOGGLE_SELECTION:
            case CLEAR_SELECTION:
                const toggleSelected = selectionReducer(state.selected, action);

                return _.assign({}, state, {
                    selected: toggleSelected,
                    isAllSelected: toggleSelected.length === state.results.length,
                });
            case TOGGLE_ALL:
                const toggleAllSelected = selectionReducer(state.selected, {
                    type: action.type,
                    payload: state.results,
                });

                return _.assign({}, state, {
                    selected: toggleAllSelected,
                    isAllSelected: toggleAllSelected.length === state.results.length,
                });
            case ADD_FILTER:
            case REMOVE_FILTER:
            case RESET_FILTERS:
                const filters = filterReducer(state.filters, action);

                return _.assign({}, state, {
                    filters,
                    query: _.assign({}, state.query, filters),
                });
            default:
                return state;
        }
    };

    return pagination;
};
