import angular from 'angular';

import { CHAR_PER_PIXEL_CONSTANTS } from 'components/read-more/read-more-clamp/read-more-clamp.constants';
import { ReadMoreComponent } from './read-more.component.js';
import { ReadMoreClampComponent } from './read-more-clamp/read-more-clamp.component.js';

const MODULE_NAME = 'portfolium.components.readMore';

(function () {
    angular.module(MODULE_NAME,[])
    // Components
    .component('pfReadMore', ReadMoreComponent)
    .component('pfReadMoreClamp', ReadMoreClampComponent)
    // Constants
    .constant('PF_CHAR_PER_PIXEL_CONSTANT', CHAR_PER_PIXEL_CONSTANTS)
}());

export default MODULE_NAME;
