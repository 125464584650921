export const JOB_APPLY_CENTER_OPEN  = 'JOB_APPLY_CENTER_OPEN';
export const JOB_APPLY_CENTER_CLOSE = 'JOB_APPLY_CENTER_CLOSE';
export const JOB_APPLY_CENTER_USER_UPDATE = 'JOB_APPLY_CENTER_USER_UPDATE';
export const JOB_APPLY_CENTER_SEARCH_RESULTS_UPDATE = 'JOB_APPLY_CENTER_SEARCH_RESULTS_UPDATE';
export const JOB_APPLY_CENTER_SEARCH_RESULTS_RESET = 'JOB_APPLY_CENTER_SEARCH_RESULTS_RESET';
export const JOB_APPLY_CENTER_RESET = 'JOB_APPLY_CENTER_RESET';

/**
 * Opens panel
 */
export const openPanel = () => {
    return {
        type: JOB_APPLY_CENTER_OPEN,
    };
};

/**
 * Closes panel
 */
export const closePanel = () => {
    return {
        type: JOB_APPLY_CENTER_CLOSE,
    };
};

/**
 * Updates user
 * @param {Object} user User object
 */
export const userUpdate = (user) => {
    return {
        type: JOB_APPLY_CENTER_USER_UPDATE,
        payload: {
            user,
        },
    };
};

/**
 * Updates search results
 * @param {Array<Objects>} results Search results returned
 */
export const searchResultsUpdate = (results) => {
    return {
        type: JOB_APPLY_CENTER_SEARCH_RESULTS_UPDATE,
        payload: {
            results,
        },
    };
};

/**
 * Resets search results list and properties
 */
export const searchResultsReset = () => {
    return {
        type: JOB_APPLY_CENTER_SEARCH_RESULTS_RESET,
    };
};

/**
 * Resets panel properties
 */
export const jobApplyCenterReset = () => {
    return {
        type: JOB_APPLY_CENTER_RESET,
    };
};

export const entryActionTypes = {
    'PAGE_INIT': 'JOB_APPLY_CENTER_ENTRY_PAGE_INIT',
    'PAGE_NEXT': 'JOB_APPLY_CENTER_ENTRY_PAGE_NEXT',
    'PAGE_PREVIOUS': 'JOB_APPLY_CENTER_ENTRY_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'JOB_APPLY_CENTER_ENTRY_PAGE_REQUEST',
    'PAGE_SUCCESS': 'JOB_APPLY_CENTER_ENTRY_PAGE_SUCCESS',
    'PAGE_ERROR': 'JOB_APPLY_CENTER_ENTRY_PAGE_ERROR',
    'ADD_SINGLE_RESULT': 'JOB_APPLY_CENTER_ENTRY_ADD_SINGLE_RESULT',
    'TOGGLE_SELECTION': 'JOB_APPLY_CENTER_ENTRY_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'JOB_APPLY_CENTER_ENTRY_CLEAR_SELECTION',
    'TOGGLE_ALL': 'JOB_APPLY_CENTER_ENTRY_TOGGLE_ALL',
};
