import angular from 'angular';

import {exportProfileToggleDirective} from './export-profile-toggle/export-profile-toggle.directive';
import {ExportProfileComponent} from './export-profile.component';
import {ExportProfilePanelService} from './export-profile-panel.service';
import {ExportProfileService} from './export-profile.service';

const MODULE_NAME = 'portfolium.component.exportProfile';

(function() {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfExportProfile', ExportProfileComponent)
        // Directives
        .directive('pfExportProfileToggle', exportProfileToggleDirective)
        // Services
        .service('$pfExportProfilePanel', ExportProfilePanelService)
        .service('$pfExportProfile', ExportProfileService);
}());

export default MODULE_NAME;
