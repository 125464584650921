import {getPaginationActions} from 'components/pagination/pagination.actions';

import {
    panelClose,
    searchResultsUpdate,
    searchResultsReset,
    entryActionTypes,
} from './export-profile.actions';

import ExportProfileTemplate from './export-profile.component.html';
import './export-profile.component.scss';

/**
 * @ngdoc controller
 * @name ExportProfileController
 * @module portfolium.component.exportProfile
 **/
class ExportProfileController {
    constructor(
        $pfUser,
        $ngRedux,
        $pfEntriesFeed,
        $pfEntrySearch,
        $pfToast,
        $pfProfile,
        $pfExportProfile,
        $pfEntryEditor,
    ) {
        this.$pfUser = $pfUser;
        this.$ngRedux = $ngRedux;
        this.$pfEntriesFeed = $pfEntriesFeed;
        this.$pfEntrySearch = $pfEntrySearch;
        this.$pfToast = $pfToast;
        this.$pfProfile = $pfProfile;
        this.$pfExportProfile = $pfExportProfile;
        this.$pfEntryEditor = $pfEntryEditor;
    }

    /**
    * @ngdoc method
    * @name $onInit
    * @description
    * Initialize user and entry pagination
    **/
    $onInit() {
        // get user
        this.user = this.$pfUser.getUser();
        // get user about record
        this.$pfProfile.getAboutByUsername(this.user.username).then((abouts) => {
            // Set about/objective field (600 is the max length set by the controller and form validation)
            this.objective = (abouts.introduction && !_.isEmpty(abouts.introduction[0])) ? abouts.introduction[0].intro.substring(0, 600) : '';
        });

        // Configure pagination actions
        const paginationActions = getPaginationActions({
            actionTypes: entryActionTypes,
            getResults: this.getEntries.bind(this),
            paginationStyle: 'infinite'
        });

        // Subscribe to application state changes and map actions to this
        this._unsubscribe = this.$ngRedux.connect(
            this._mapStateToThis,
            paginationActions
        )(this);

        // Init page
        this.initPage();
    }

    /**
    * @ngdoc method
    * @name $onDestroy
    * @description
    * Update panel state and UnSubscribe from ngRedux
    **/
    $onDestroy() {
        // Update state with panel close
        this.$ngRedux.dispatch(panelClose());

        this._unsubscribe();
    }

    /**
    * @ngdoc method
    * @name _mapStateToThis
    * @description
    * Maps entries to the controller
    * @param  {Object} state current state.
    * @return {Object} New state of entries.
    **/
    _mapStateToThis(state) {
        return {
            searchResults: state.exportProfile.meta.searchResults,
            searchResultsVisible: state.exportProfile.meta.searchResultsVisible,
            pagination: state.exportProfile.entries,
        };
    }

    /**
     * @ngdoc method
     * @name searchEntries
     * @description
     * Search the user's entries based on keyword matching
     * @param  {String} searchText Search query
     * @return {void|Promise<Array>}
     **/
    searchEntries(searchText) {
        this.searchLoading = true;

        if (!searchText.length) {
            // No search text -- reset search state
            this.$ngRedux.dispatch(searchResultsReset());
            this.searchLoading = false;

            return;
        }

        return this.$pfEntrySearch.searchEntriesByUserId(this.user.id, { q: searchText })
            .then((entries) => {
                // Update application state
                this.$ngRedux.dispatch(searchResultsUpdate(entries));
            })
            .finally(() => {
                this.searchLoading = false;
            });
    }

     /**
      * @ngdoc method
      * @name clearEntrySearch
      * @description
      * Clear the entry search query (show all entries)
      **/
    clearEntrySearch() {
        // clear search text
        this.searchText = '';
        // clear search results
        this.$ngRedux.dispatch(searchResultsReset());
    }

    /**
     * @description Pluralize the passed in string
     * @param {String} singularString
     * @param {Integer} count Number of singularStrings
     */
    pluralize(singularString, count) {
        if (count === 1) {
            return singularString;
        } else {
            return singularString + 's';
        }
    }

    /**
      * @ngdoc method
      * @name getEntries
      * @description
      * Gets users entries
      * @return {Array<Object>} List of user entries
      */
    getEntries() {
        // set the sort
        const sort = {'sort': 'order'};
        // set the params
        const params = _.assign(sort, this.pagination.query);
        // Get entries
        return this.$pfEntriesFeed.getEntriesByUserId(this.user.id, params);
    }

    /**
      * @ngdoc method
      * @name hasEntries
      * @description
      * Checks for results
      * @return {Boolean} Does the user have entries
      */
    get hasEntries() {
        if (this.pagination.isFetching && this.pagination.results.length === 0) {
            return undefined;
        }

        return this.pagination.results.length > 0;
    }

    /**
     * Is the entry selected?
     * @param {String} id entry ID
     * @return {Boolean}
     */
    isSelected(id) {
        return this.pagination.selected.indexOf(id) > -1;
    }

    /**
     * Add a new project
     * @param {jqEvent} $event event on click
     * @return {Promise}
     */
    addEntry($event) {
        return this.$pfEntryEditor.newEntry({
            source: 'exportProfilePanel',
            targetEvent: $event,
        })
        .then(() => {
            // Finished creating entry and transitioning to entry wizard,
            // close the panel
            return this.close();
        });
    }

    /**
      * @ngdoc method
      * @name toggleEntry
      * @description
      * Add or Remove an entry from application
      * @param {Object} entry Entry to add
      */
    toggleEntry(entry) {
        const hasMaxEntries = (this.pagination.selected.length === 4);
        const isSelected = this.isSelected(entry.id);

        if (!isSelected && hasMaxEntries) {
            return this.$pfToast.error('Only 4 projects allowed in export, please remove one before adding another.');
        }

        this.toggleSelection(entry.id);
        this.addSingleResult(entry);
    }

    /**
      * @ngdoc method
      * @name exportProfile
      * @description
      * Export profile to pdf
      * @return {Void}
      */
    exportProfile() {
        this.form.$setSubmitted();

        // Stop export if no entries selected
        if (this.pagination.selected.length === 0) {
            return this.$pfToast.error('Please select at least one project.');
        }

        // check for validation
        if (this.form.$valid) {
            // Prepare export data
            const {objective} = this;
            const entryIds = _.toString(this.pagination.selected);

            // Post objective and entry_ids as flash data
            return this.$pfExportProfile.exportToPdf(objective, entryIds).then(() => {
                // Close panel
                this.close();
                // Notify user
                this.$pfToast.success('Your Profile PDF is being prepared and will download shortly.');
            });
        }
    }

    /**
      * @ngdoc method
      * @name close
      * @description
      * Close panel
      */
    close() {
        this.mdPanelRef.close();
    }
}

ExportProfileController.$inject = [
    '$pfUser',
    '$ngRedux',
    '$pfEntriesFeed',
    '$pfEntrySearch',
    '$pfToast',
    '$pfProfile',
    '$pfExportProfile',
    '$pfEntryEditor',
];

/**
 * @ngdoc component
 * @name ExportProfileComponent
 * @module portfolium.component.exportProfile
 * @param {mdPanelRef} md-panel-ref Panel instance from $mdPanel
 **/
export const ExportProfileComponent = {
    bindings: {
        'mdPanelRef': '<',
    },
    controller: ExportProfileController,
    controllerAs: '$ctrl',
    template: ExportProfileTemplate,
}
