import config from '../config';

function imageFilter($pfEnv) {
    return function(url, height, width) {
        let filteredUrl = '';

        if (!url) {
            return;
        }

        if (url[0] === '/') {
            // Remove preceding slash if present
            url = url.slice(1);
        }

        if (height && width) {
            // Add cloud image cropping prefix if height and width are provided
            filteredUrl += config.cloudImgUrl.replace('{{height}}', height).replace('{{width}}', width) + '/';
        }

        if (url.indexOf('ugcs3') === 0) {
            // Add cdn path if source is a URI to CDN
            filteredUrl += $pfEnv.getEnv('PF_CDN_URL') + '/';
        }

        // Append the source
        filteredUrl += url;

        return filteredUrl;
    };
}

imageFilter.$inject = ['$pfEnv'];

export default imageFilter;
