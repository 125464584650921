import angular from 'angular';
import { navbarDirective } from './js/directive/navbar.directive';
import { footerNavbarDirective } from './js/directive/footer-navbar.directive';
import { srefDirective } from './js/directive/sref.directive';
import { previousStateBtnDirective } from './js/directive/previous-state-btn.directive';
import { userProfileMenuDirective } from './js/directive/user-profile-menu.directive';
import { navbarScrollAndHideDirective } from './navbar-scroll-and-hide/navbar-scroll-and-hide.directive';
import {
    sectionNameDirective,
    SectionNameService,
} from './js/directive/section-name';
import { MainNavService } from './js/service/main-nav.service';
import { NavbarService } from './js/service/navbar.service';
import { setSectionNameHook } from './js/config/set-section-name-hook.config';

import { NavbarMobileComponent } from './navbar-mobile/navbar-mobile.component';
import { NavbarEmployerComponent } from './navbar-employer/navbar-employer.component';
import { NavbarStandardComponent } from './navbar-standard/navbar-standard.component';
import { NavbarEmptyComponent } from './navbar-empty/navbar-empty.component';
import { NavbarUserDropdownComponent } from './navbar-user-dropdown/navbar-user-dropdown.component';
import { NavbarEmployerSwitchDropdownComponent } from './navbar-employer-switch-dropdown/navbar-employer-switch-dropdown.component';
import { NavbarStandardSwitchDropdownComponent } from './navbar-standard-switch-dropdown/navbar-standard-switch-dropdown.component';
import { BottomNavTabComponent } from './bottom-nav-tab/bottom-nav-tab.component.js';

import EmployerSearchNavbarController from './js/controller/employer-navbar-controller.js';
import MainNavController from './js/controller/main-nav-controller';

import './index.scss';

const MODULE_NAME = 'portfolium.component.navbar';

(function() {
    // Define module
    angular
        .module(MODULE_NAME, [])
        // Components
        .component('pfNavbarMobile', NavbarMobileComponent)
        .component('pfNavbarEmployer', NavbarEmployerComponent)
        .component('pfNavbarStandard', NavbarStandardComponent)
        .component('pfNavbarEmpty', NavbarEmptyComponent)
        .component('pfNavbarUserDropdown', NavbarUserDropdownComponent)
        .component(
            'pfNavbarEmployerSwitchDropdown',
            NavbarEmployerSwitchDropdownComponent,
        )
        .component(
            'pfNavbarStandardSwitchDropdown',
            NavbarStandardSwitchDropdownComponent,
        )
        .component('pfBottomNavTab', BottomNavTabComponent)
        // Directives
        .directive('pfHeroNavbar', navbarDirective)
        .directive('pfFooterNavbar', footerNavbarDirective)
        .directive('pfPreviousStateBtn', previousStateBtnDirective)
        .directive('pfSref', srefDirective)
        .directive('pfSectionName', sectionNameDirective)
        .directive('pfUserProfileMenu', userProfileMenuDirective)
        .directive('pfNavbarScrollAndHide', navbarScrollAndHideDirective)
        // Controllers
        .controller('EmployerNavbarController', EmployerSearchNavbarController)
        .controller('MainNavController', MainNavController)
        // Services
        .service('$pfNavbar', NavbarService)
        .service('$pfMainNav', MainNavService)
        .service('$pfSectionName', SectionNameService)
        // Runtime
        .run(setSectionNameHook);
})();

export default MODULE_NAME;
