import {combineReducers} from 'redux';
import _ from 'lodash';
import {
    COMPANY_DETAILS_COMPANY_REQUEST,
    COMPANY_DETAILS_UPDATE_COMPANY,
    RESET_COMPANY,
} from './company-details.actions';

import {getPaginationReducer} from '../components/pagination/pagination.reducer';
import {companyJobsActionTypes} from './company-jobs.actions';


const initialState = {
    loadingCompany: false,
    company: {},
};

export const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case COMPANY_DETAILS_COMPANY_REQUEST:
            return _.assign({}, state, {
                loadingCompany: true
            });
        case COMPANY_DETAILS_UPDATE_COMPANY:
            return _.assign({}, state, {
                loadingCompany: false,
                company: action.payload
            });
        case RESET_COMPANY:
            return {
                company: {},
            };
        default:
            return state;
    }
};

export const companyDetails = combineReducers({
    meta,
    jobs: getPaginationReducer({
        limit: 5,
        actionTypes: companyJobsActionTypes,
    }),
});
