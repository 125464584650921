import BaseModalController from '../../components/modal/js/controller/base-modal-controller';

/**
 * @ngdoc service
 * @name UserBlockingService
 * @module portfolium.core
 * @description Used to block fools
 **/
 export class UserBlockingService {
     constructor(
        $q,
        $http,
        $mdDialog,
        fullNameFilter
    ) {
        this.$q = $q;
        this.$http = $http;
        this.$mdDialog = $mdDialog;
        this.fullNameFilter = fullNameFilter;
    }

    /**
     * Open the block user modal
     * @param  {Event} targetEvent Triggering event
     * @param  {Object} user User profile
     * @returns {Promise}
     */
    openUserBlockingModal(targetEvent, user) {
        const dialogSettings = {
            template: `
                <pf-user-blocking-modal
                    pf-user="::$ctrl.user"
                    pf-on-cancel="$ctrl.cancel()">
                </pf-user-blocking-modal>
            `,
            locals: {
                user,
            },
            controller: BaseModalController,
            bindToController: true,
            controllerAs: '$ctrl',
            hasBackdrop: true,
            clickOutsideToClose: true,
            targetEvent,
            autoWrap: false,
        };

        this.$mdDialog.show(dialogSettings);
    }

    /**
     * Block a user by user ID
     * @param  {Number} userId ID of the user to be blocked
     * @return {Promise}
     */
    blockUser(userId) {
        // block it
        return this.$http({
            method: 'POST',
            url: `/proxy/users/block/${userId}`,
        }).then((response) => {
            // tell em its aight
            return this.$q.resolve(response);
        }, (error) => {
            // reject that shit son
            return this.$q.reject(error);
        });
    }

    /**
     * Get the currently authed users blocked user list
     * @return {Promise}
     */
    getBlockedUsers() {
        // get em
        return this.$http({
            method: 'GET',
            url: `/proxy/users/blocked`,
        }).then((response) => {
            // tell em its aight
            return this.$q.resolve(response);
        }, (error) => {
            // reject that shit son
            return this.$q.reject(error);
        });
    }

    /**
     * Unblock the user
     * @param  {Number} userId
     * @return {Promise}
     */
    unblockUser(userId) {
        // block it
        return this.$http({
            method: 'DELETE',
            url: `/proxy/users/block/${userId}`,
        }).then((response) => {
            // tell em its aight
            return this.$q.resolve(response);
        }, (error) => {
            // reject that shit son
            return this.$q.reject(error);
        });
    }
}

UserBlockingService.$inject = [
    '$q',
    '$http',
    '$mdDialog',
    'fullNameFilter',
];
