class CompanyAdminService {
    constructor($http, $pfUser, $pfSession) {
        this.$http = $http;
        this.currentUser = $pfUser.getUser();
        this.$pfSession = $pfSession;
    }

    /**
     * Get the IDs of all companies for which the current user has been granted
     * admin privileges
     * @return {Array} Company IDs
     */
    getAuthorizedCompanyIds() {
        if (!this.currentUser || !this.currentUser.isCompanyAdmin()) {
            return [];
        }

        // Grab the first company, multiple companies are not yet supported
        return this.$pfSession._data.company_admin_ids;
    }

    /**
     * Get a collection of companies for which the current user has been granted
     * admin privileges
     * @return {Promise} Promise returning collection of companies on success
     */
    getAuthorizedCompanies() {
        let authorizedCompanies = this.getAuthorizedCompanyIds();

        if (!authorizedCompanies.length) {
            return this.$q.reject();
        }

        // Use the user's first authorized company for now, since we don't
        // support context switching yet
        return this.getCompanyById(authorizedCompanies[0])
            .then((company) => {
                // Return data as collection for future-proofing
                return [company];
            });
    }

    /**
     * Get a company by ID
     * @return {Promise}
     */
    getCompanyById(companyId) {
        return this.$http({
            method: 'GET',
            url: `/proxy/companies/company/${companyId}`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get company by slug
     * @param   {String}  slug Slug of the company to get
     * @param   {Object}  params Filters
     * @return {promise}
     */
    getCompanyBySlug(slug) {
        return this.$http({
            method: 'GET',
            url: `/proxy/companies/company/${slug}/slug`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Create new company
     * @param {Object} data company data
     * @param {String} data.name name of the company
     * @param {String} data.phone company phone number
     * @param {String} data.title Job title
     */
    createCompany(data = {name, phone, title}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/companies/create`,
            data
        }).then((response) => response.data);
    }

    /**
     * Updates the company record
     * @param {Object} company The company data
     * @return {Promise} $http promise
     */
    updateCompany(company = {id, name, description, location, industry_id, size}) {

        if (!_.isEmpty(company.logo) && !_.isEmpty(company.logo.source)) {
            // workaround so the api doesn't overwrite our images
            company.logo_uri = company.logo.source;
            // api doesnt like arrays
            delete company.logo;
        }
        if (!_.isEmpty(company.cover) && !_.isEmpty(company.cover.source)) {
            // workaround so the api doesn't overwrite our images
            company.cover_uri = company.cover.source;
            // api doesnt like arrays
            delete company.cover;
        }
        if (!_.isEmpty(company.landing) && !_.isEmpty(company.landing.source)) {
            // workaround so the api doesn't overwrite our images
            company.landing_uri = company.landing.source;
            // api doesnt like arrays
            delete company.landing;
        }

        return this.$http({
            method: 'POST',
            url: `/proxy/companies/company/${company.id}`,
            data: company
        })
        .then((response) => response.data);
    }

    /**
     * Updates the company cover image
     * @param {Integer} companyId The company id
     * @param {String} uri The company cover image uri
     * @return {Promise} $http promise
     */
    updateCompanyCover(companyId, uri) {
        return this.$http({
            method: 'POST',
            url: `/proxy/companies/cover/${companyId}`,
            data: {
                file_uri: uri,
            }
        })
        .then((response) => response.data);
    }

    /**
     * Updates the company logo image
     * @param {Integer} companyId The company id
     * @param {String} uri The company logo image uri
     * @return {Promise} $http promise
     */
    updateCompanyLogo(companyId, uri) {
        return this.$http({
            method: 'POST',
            url: `/proxy/companies/logo/${companyId}`,
            data: {
                file_uri: uri,
            }
        })
        .then((response) => response.data);
    }
}

CompanyAdminService.$inject = ['$http', '$pfUser', '$pfSession'];

export default CompanyAdminService;
