import EntryViewerFullTemplate from './entry-viewer-full.component.html';
import EntryViewerMobileTemplate from './entry-viewer-mobile.component.html';
import './entry-viewer.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerController
 **/
class EntryViewerController {
    constructor(
        $pfUser,
        $timeout,
        $element,
        $pfEventTracker,
        $pfContentReporting,
        $pfAssignmentsUtilities,
        PF_ENTRY_VISIBILITY_TYPES_MAPPED,
        PF_ENTRY_VERSION_STATES,
    ) {
        this.$element = $element;
        this.$pfEventTracker = $pfEventTracker;
        this.$timeout = $timeout;
        this.$pfContentReporting = $pfContentReporting;
        this.$pfAssignmentsUtilities = $pfAssignmentsUtilities;
        this.PF_ENTRY_VISIBILITY_TYPES_MAPPED = PF_ENTRY_VISIBILITY_TYPES_MAPPED;
        this.PF_ENTRY_VERSION_STATES = PF_ENTRY_VERSION_STATES;

        this.currentUser = $pfUser.getUser();
    }

    $postLink() {
        // Prevent click from bubbling up to panel backdrop
        this.$element.on('click', $event => $event.stopPropagation());
    }

    /**
     * Tracks an event made in the Entry viewer
     * @param  {String} options.title:      title         Event name
     * @param  {Object} options.properties: properties    custom properties that
     *                                          will be send to the tracking
     */
    trackEvent(
        {
            title = '',
            properties = {},
        }
    ) {
        this.$pfEventTracker
            .trackServer(title, properties);
    }

    /**
     * Remove current user from entry as a collaborator
     * @param  {string} userId id of current user
     * @return {Promise}
     */
    removeSelfAsTeammate(userId) {
        this.onRemoveSelfAsTeammate({ userId });
    }

    /**
     * Is curren user owner of this entry
     * @return {boolean}
     */
    isOwner() {
        if (_.isNil(this.currentUser)) {
            return false;
        }

        return this.entry.fk_user_id === this.currentUser.id;
    }

    /**
     * Is this entry private
     * @return {boolean}
     */
    isPrivate() {
        return this.entry.visibility === this.PF_ENTRY_VISIBILITY_TYPES_MAPPED.hidden;
    }

    /**
     * Determine when to show social share menu
     * @return {boolean}
     */
    shouldShowSocialShareMenu() {
        if (_.isNil(this.entry) || _.isEmpty(this.entry)) {
            return undefined;
        }

        if (this.isSensitiveAssignment()) {
            return false;
        }

        if (this.isOwner()) {
            return true;
        }

        if (!this.isPrivate()) {
            return true;
        }

        return false;
    }

    shouldShowComments() {
        if (_.isEmpty(this.entry)) {
            return undefined;
        }
        return this.isEditable() && this.entry.allow_comments === '1'
    }

    isSensitiveAssignment() {
        return this.$pfAssignmentsUtilities.isSensitive(this.entry.assignment)
    }

    /**
     * Determine if this project is a submitted assignment
     * @return {Boolean}
     */
    isEditable() {
        if (_.isNil(this.version)) {
            return true;
        }
        return this.version === this.PF_ENTRY_VERSION_STATES.LIVE;
    }

    /**
     * Likes current entry for current user
     * @return {Promise}
     */
    likeEntry() {
        this.onLikeEntry();
    }

    /**
     * Request to add a comment
     * @param  {object} comment data
     */
    addComment(comment) {
        this.onAddComment({comment});
    }

    /**
     * Request to remove comment
     * @param  {number} commentId
     */
    deleteComment(commentId) {
        this.onDeleteComment({commentId});
    }

    onKeyDown($event) {
        // listen to space
        if ($event.which === 32) {
            // Dont close on spacebar
            $event.stopImmediatePropagation();
        }
        // Listen to enter
        if ($event.which === 13) {
            // Dont close on enter
            $event.stopImmediatePropagation();
        }
    }

    handleReportContent(targetEvent) {
        const entryId = _.get(this.entry, 'id');

        if (_.isNil(entryId)) {
            return;
        }

        this.$pfContentReporting.openProjectReportModal(
            targetEvent,
            entryId,
            this.version
        );
    }
}

EntryViewerController.$inject = [
    '$pfUser',
    '$timeout',
    '$element',
    '$pfEventTracker',
    '$pfContentReporting',
    '$pfAssignmentsUtilities',
    'PF_ENTRY_VISIBILITY_TYPES_MAPPED',
    'PF_ENTRY_VERSION_STATES',
];

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerComponent
 * @description View entry details in the entry
 * editor (preview mode) or the EDP route.
 *
 * @param {boolean}     isPreview              Is the user viewing a preview?
 * @param {boolean}     isLoading              Is the current entry loading
 * @param {string}      version                Entry version ('live' or 'current')
 * @param {object}      entry                  Entry
 * @param {object}      entryComments          Pagination object for comments
 * @param {callback}    onNextComments         On request more comments
 * @param {callback}    onAddComment           On add a comment to entry
 * @param {callback}    onClose                On close callback
 * @param {callback}    onDeleteComment        On delete a comment
 * @param {callback}    onLikeEntry            On like an entry
 * @param {callback}    onRemoveSelfAsTeammate On remove self as a teammate
 */
export const EntryViewerComponent = {
    bindings: {
        isPreview: '<',
        isLoading: '<',
        version: '<',
        entry: '<',
        entryComments: '<',
        onNextComments: '&',
        onAddComment: '&',
        onClose: '&',
        onDeleteComment: '&',
        onLikeEntry: '&',
        onRemoveSelfAsTeammate: '&',
    },
    controller: EntryViewerController,
    controllerAs: '$ctrl',
    template: ['$pfUser', $pfUser => {
        const isMobile = $pfUser.isMobile();

        if (isMobile) {
            return EntryViewerMobileTemplate;
        }

        return EntryViewerFullTemplate;
    }],
};
