class MessagingService
{
    constructor($http) {
        this.$http = $http;
    }

    /**
     * @description Create a new message thread
     * @param  {Array}   userIds Array of user IDs to add to thread
     * @param  {Object}  message Message object with subject and body
     * @return {Promise}
     */
    createMessage(userIds = [], message = {}) {
        return this.$http({
            method: 'POST',
            url: '/proxy/messages/create',
            data: {
                'to_user_ids': userIds.join(','),
                'subject': message.subject,
                'message': message.body
            }
        })
        .then((response) => {
            return response.data;
        });
    }
}

MessagingService.$inject = ['$http'];

export default MessagingService;
