import angular from 'angular';

import {LightFooterComponent} from './light-footer.component.js';

import './index.scss';

const MODULE_NAME = 'portfolium.component.footer';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfLightFooter', LightFooterComponent);
}());

export default MODULE_NAME;
