export const INITIAL_PROFILE_SECTIONS = [
    {
        name: 'introduction',
        aboutType: 'introduction',
        title: 'Introduction',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add an introduction',
        helpText: 'If history wrote a sentence about you, what would it say?',
        icon: 'intro.svg',
    },
    {
        name: 'accomplishments',
        aboutType: 'accomplishment',
        title: 'Accomplishments',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add an accomplishment',
        helpText: 'Show what you\'re made of by taking credit for the completed tasks, projects, or milestones',
        icon: 'award.svg',
    },
    {
        name: 'athletics',
        aboutType: 'athletic',
        title: 'Athletics',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add athletics',
        helpText: 'Highlight your competitive spirit, and status as a team-player',
        icon: 'sport.svg',
    },
    {
        name: 'certifications',
        aboutType: 'certification',
        title: 'Certifications',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add a certification',
        helpText: 'List any certificates, licenses, or assessments that certify your knowledge or experience',
        icon: 'cert.svg',
    },
    {
        name: 'clubs',
        aboutType: 'club',
        title: 'Clubs, Affiliations, & Programs',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add a club or affiliation',
        helpText: 'Did you start, lead, or make interesting contributions to a student group?',
        icon: 'group.svg',
    },
    {
        name: 'courses',
        aboutType: 'course',
        title: 'Courses',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add a course',
        helpText: 'List current or past courses that you\'ve learned a lot from',
        icon: 'class.svg',
    },
    {
        name: 'educations',
        aboutType: 'education',
        title: 'Education',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add education',
        helpText: 'List all schools or learning institutions that you\'ve attended',
        icon: 'edu.svg',
    },
    {
        name: 'experiences',
        aboutType: 'experience',
        title: 'Work Experience',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add work experience',
        helpText: 'State experience from jobs, internships, and/or co-ops',
        icon: 'work.svg',
    },
    {
        name: 'publications',
        aboutType: 'publication',
        title: 'Publications',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add a publication',
        helpText: 'Showcase your published works, e.g.: research, essays, articles, books, etc.',
        icon: 'pub.svg',
    },
    {
        name: 'volunteers',
        aboutType: 'volunteer',
        title: 'Volunteer Work',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add volunteer work',
        helpText: 'Describe your involvement in community service or other volunteer effort(s)',
        icon: 'volunteer.svg',
    },
    {
        name: 'badges',
        aboutType: 'badge',
        title: 'Badges',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add badges',
        helpText: 'Have proof elsewhere? Link it here to show the world.',
        icon: 'badges.svg',
    },
    {
        name: 'resume',
        aboutType: 'resume',
        title: 'a Resume',
        collapsed: false,
        visibleItem: null,
        order: null,
        addText: 'Add a Resume',
        helpText: 'Showcase your work and make a favorable impression on a prospective employer.',
        icon: 'resume.svg',
    },
];
