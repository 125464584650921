export const inputFocusDirective = () => {
    return {
        restrict: 'A',
        scope: {
            setFocus: '=pfInputFocus',
        },
        link: (scope, elem, attrs) => {
            scope.$watch('setFocus', (currVal, prevVal) => {
                if (currVal) {
                    elem[0].focus();
                }
            });

            elem.bind('blur', function() {
                // reset value when field is blurred
                scope.setFocus = false;
            });
        },
    };
};
