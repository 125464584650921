export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';

import {followCategories} from 'components/category-interests/category-interests.actions';

/**
 * Update the normalized categories in cache
 * @param {Object} categories Normalized categories object
 */
export const updateCategories = (categories) => {
    return (dispatch) => {
        let following = [];

        _.forIn(categories.data, (category) => {
            if (category.interested === '1') {
                following = [...following, category.id];
            }
        });

        // Set the categories that the current user is following
        dispatch(followCategories(following));

        // Update the cached categories data
        dispatch({
            type: UPDATE_CATEGORIES,
            payload: {
                categories,
            },
        });
    };
};
