import MobileMenuTemplate from '../../template/mobile-menu.html';

/**
 * @ngdoc controller
 * @name mobileMenuController
 * @param {Object} mdPanelRef Panel instance from $mdPanel
 * @module portfolium.component.mobileMenu
 **/
 class MobileMenuController {
    constructor(
        $scope,
        $pfUser,
        $pfEnv,
    ) {
        this.user = $pfUser.getUser();
        this.$pfEnv = $pfEnv;
        // Close the panel on state change
        $scope.$on('$stateChangeSuccess', () => {
            this.closePanel();
        });
    }

    /**
     * Checks if user is authed
     * @return {Boolean} Is the user authed?
     */
    get isAuthed() {
        return !!this.user;
    }

    /**
     * Close the panel
     * @return {Promise} Resolved when panel is closed
     */
    closePanel() {
        return this.mdPanelRef.close();
    }

    /**
     * Convenience method
     * return {boolean}
     */
    jobsEnabled() {
        return this.$pfEnv.jobsEnabled();
    }
}

MobileMenuController.$inject = [
    '$scope',
    '$pfUser',
    '$pfEnv',
];

/**
 * @ngdoc component
 * @name mobileMenuComponent
 * @module portfolium.component.mobileMenu
 **/
export const MobileMenuComponent = {
    bindings: {
        'mdPanelRef': '<',
    },
    template: MobileMenuTemplate,
    controller: MobileMenuController,
};
