import _ from 'lodash';

/**
 * Creates a reducer for managing a selection of items in a collection, given
 * the action types to handle
 * @param {Object} config Configuration options for the reducer
 * @param {Object} config.actionTypes Selection action types
 * @return {Function<Function>} Function that returns the selection reducer
 */
export const getSelectionReducer = ({actionTypes}) => {
    const {
        TOGGLE_SELECTION,
        CLEAR_SELECTION,
        TOGGLE_ALL,
    } = actionTypes;

    const selection = (state = [], action) => {
        switch (action.type) {
            case TOGGLE_SELECTION:
                const isSelected = state.indexOf(action.payload) > -1;

                if (isSelected) {
                    return _.without(state, action.payload);
                }

                return _.uniq([...state, action.payload]);
            case CLEAR_SELECTION:
                return [];
            case TOGGLE_ALL:
                const isAllSelected = state.length && state.length === action.payload.length;

                if (isAllSelected) {
                    return [];
                }

                return action.payload.map(result => result.id);
            default:
                return state;
        }
    };

    return selection;
};

