import {
    JOB_SUMMARY_JOB_REQUEST,
    JOB_SUMMARY_JOB_SUCCESS,
    JOB_SUMMARY_JOB_PUBLISH,
    JOB_SUMMARY_JOB_UNPUBLISH,
} from '../action/job';

export const job = (state = {}, action = {}) => {
    switch (action.type) {
        case JOB_SUMMARY_JOB_REQUEST:
            return {
                id: action.payload,
            };
        case JOB_SUMMARY_JOB_SUCCESS:
            return action.payload;
        case JOB_SUMMARY_JOB_PUBLISH:
            return _.assign({}, state, {
                published: '1',
                expires_on: action.payload,
            });
        case JOB_SUMMARY_JOB_UNPUBLISH:
            return _.assign({}, state, {
                published: '0',
            });
        default:
            return state;
    }
};
