export class JobsService {
    constructor($http, $pfFilestackSigner) {
        this.$http = $http;
        this.$pfFilestackSigner = $pfFilestackSigner;
    }

    /**
     * Get all the jobs for a company
     * @param   {String}  companyId Id of the company to get jobs for
     * @param   {Object}  params Filters
     * @return {promise}
     */
    getJobsByCompanyId(companyId, params = {}) {
        // prevent jobs from caching, include passed in params
        let noCache = _.assign({}, params, {preventCache: new Date().getTime()});

        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/me/${companyId}`,
            params: noCache,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get all the jobs for a company by slug
     * @param   {String}  slug Slug of the company to get jobs for
     * @param   {Object}  params Filters
     * @return {promise}
     */
    getJobsByCompanySlug(slug, params = {}) {
        // prevent jobs from caching, include passed in params
        let noCache = _.assign({}, params, {preventCache: new Date().getTime()});

        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/company/${slug}/slug`,
            params: noCache,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get the job by id
     * @param   {String}  id Id of the job you want
     * @param   {Object}  params Filters
     * @return {promise}
     */
    getJobById(id, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/editor/${id}`,
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get the job by slug
     * @param   {String}  slug Slug of the job you want
     * @param   {Object}  params Filters
     * @return {promise}
     */
    getJobDetailsBySlug(slug, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/details/${slug}/slug`,
            params: params
        }).then(response => response.data);
    }

    /**
     * Get the job preview details by a job id
     * @param   {String}  id Id of the job you want to preview
     * @return {promise}
     */
    getJobPreviewById(id) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/prepay/${id}`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get the jobs user applied to
     * @param   {Object}  params Filters
     * @return {promise}
     */
    getJobsAppliedTo(params) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/applied`,
            params: params,

        })
        .then(response => response.data);
    }

    /**
     * Get the jobs user has bookmarked (interested)
     * @param   {Object}  params Filters
     * @return {promise}
     */
    getJobsBookmarked(params) {
        return this.$http({
            method: 'GET',
            url: `/proxy/bookmarks/feed/jobs`,
            params: params,
        })
        .then(response => response.data);
    }

    /**
     * Get a count of candidates for a job
     * @param   {String}  type The job type
     * @param   {String}  function The job function
     * @param   {Object}  params Optional params
     * @return {promise}
     */
    getCandidates(jobType, jobFunction, limit) {
        let query = {
            type: jobType,
            function_id: jobFunction,
            limit: limit,
        };
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/candidates`,
            params: query,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get the job you want to edit
     * @param   {String}  code Code of the coupon to get the discounted price.
     * @return {promise}
     */
    getDiscountedPricebyCoupon(code) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/coupon/${code}`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @ngdoc function
     * @description Get similar or related jobs
     * @param  {string} jobId Id of the job to find similar jobs
     * @return {Promise}
     */
    relatedJobs(jobId) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/similar/${jobId}`
        }).then(response => response.data);
    }

    /**
     * @ngdoc function
     * @description Get the job apply modal data (TODO: DEPRECATE THIS)
     * @param  {string} slug the slug of the job you are applying to
     * @return {Promise}
     */
    applyModalData(jobSlug) {
        return this.$http({
            method: 'GET',
            url: `/job/${jobSlug}/apply`
        }).then(response => response.data);
    }

    /**
     * Boost a job (can be used to convert a Network only job)
     * @param  {String}  id Id of the job you want to boost
     * @param  {Object}  params data about the job
     * @return {promise}
     */
    boostJob(id, params = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/boost/${id}`,
            data: params
        }).then(response => response.data);
    }

    /**
     * Repost and Publish a job
     * @param   {String}  id Id of the job you want to repost
     * @param   {Object}  params Filters
     * @return {promise}
     */
    repostAndPublishJob(id, params = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/repost/${id}`,
            params: params
        })
        .then(() => {
            return this.$http({
                method: 'POST',
                url: `/proxy/jobs/publish/${id}`,
                params: params
            });
        });
    }

    /**
     * Repost job
     * @param   {String}  id Id of the job you want to repost
     * @param   {Object}  params Filters
     * @return {promise}
     */
    repostJob(id, params = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/repost/${id}`,
            params: params
        });
    }

    /**
     * Update a job image
     * @param   {String}  id Id of the job you want to update the image for
     * @param   {Object}  params Filters
     * @return {promise}
     */
    updateJobImage(id, params = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/image/${id}`,
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @ngdoc function
     * @description Bookmark a job by its ID
     * @param  {string} jobId Id of the job to bookmark
     * @return {Promise}
     */
    bookmarkJob(jobId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/bookmarks/job/${jobId}`
        }).then(response => response.data);
    }

    /**
     * Apply to a job (authenticated users only)
     * @param  {String}  jobId ID of the job
     * @param  {Object}  data  Data to send
     * @return {Promise}
     */
    applyByJobId(jobId, data = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/apply/${jobId}`,
            data: data,
        }).then(response => response.data);
    }

    /**
     * @ngdoc function
     * @description Submit the 'tell us you got hired' form
     * @param  {Object} data Form details
     * @return {Promise}
     */
    sendHiredForm(data = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/hired`,
            data: data,
        }).then(response => response.data);
    }

    /**
     * Edit an existing job
     * @param   {String}  id Id of the job you want to edit
     * @param   {Object}  job The job to save
     * @param   {Object}  params Filters
     * @return {promise}
     */
    editJob(id, obj, params = {}) {
        // sanitize the job
        let job = this.$pfFilestackSigner.sanitizeJob(obj);
        // set the data
        let data = {
            job_id: job.id,
            title: job.title,
            description: job.description,
            anywhere: job.anywhere,
            industry_id: job.fk_industry_id,
            function_id: job.fk_function_id,
            competencies: job.competencies.map((competency) => {
                return {
                    subscriber_competency_id: competency.id,
                    description: competency.job_description,
                };
            }),
            wanted: job.wanted,
            country: job.country,
            state: job.state,
            city: job.city,
            zip: job.zip,
            email: job.email,
            url: job.url,
            image_uri: job.image && job.image.image_uri,
            type: job.type
        };
        // call the api
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/job/${id}`,
            params: params,
            data: data
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Create new job
     * @param   {Object}  data The new job to create
     * @param   {String}  companyId Id of the company creating the job
     * @param   {Object}  params Filters
     * @return {promise}
     */
    createJob(obj, companyId, params = {}) {
        // sanitize the job
        let job = this.$pfFilestackSigner.sanitizeJob(obj);
        // set the data
        let data = {
            title: job.title,
            description: job.description,
            anywhere: job.anywhere,
            industry_id: job.fk_industry_id,
            function_id: job.fk_function_id,
            competencies: job.competencies.map((competency) => {
                return {
                    subscriber_competency_id: competency.id,
                    description: competency.job_description,
                };
            }),
            wanted: job.wanted,
            country: job.country,
            state: job.state,
            city: job.city,
            zip: job.zip,
            email: job.email,
            url: job.url,
            image_uri: job.image && job.image.image_uri,
            image: job.image,
            type: job.type,
            tags: job.tags,
            auto_email: job.auto_email,
        };
        // call the api
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/create/${companyId}`,
            params: params,
            data: data
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Publish a saved job
     * @param  {String}  id       Id of the job you want to publish
     * @param  {Object}  options  Optional data about the job
     * @return {promise} promise
     */
    publishJob(id, options = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/publish/${id}`,
            data: options
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Unpublish a job
     * @param   {String}  id Id of the job you want to unpublish
     * @param   {Object}  params Filters
     * @return {promise}
     */
    unpublishJob(id, params = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/jobs/unpublish/${id}`,
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Delete a job
     * @param   {String}  id Id of the job you want to delete
     * @param   {Object}  params Filters
     * @return {promise}
     */
    deleteJob(id, params = {}) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/jobs/job/${id}`,
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }
}

JobsService.$inject = ['$http', '$pfFilestackSigner'];
