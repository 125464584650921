export const messagesActionTypes = {
    'PAGE_INIT': 'MESSAGES_PAGE_INIT',
    'PAGE_NEXT': 'MESSAGES_PAGE_NEXT',
    'PAGE_PREVIOUS': 'MESSAGES_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'MESSAGES_PAGE_REQUEST',
    'PAGE_SUCCESS': 'MESSAGES_PAGE_SUCCESS',
    'PAGE_ERROR': 'MESSAGES_PAGE_ERROR',
    'TOGGLE_SELECTION': 'MESSAGES_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'MESSAGES_CLEAR_SELECTION',
    'TOGGLE_ALL': 'MESSAGES_TOGGLE_ALL',
    'ADD_FILTER': 'MESSAGES_ADD_FILTER',
    'REMOVE_FILTER': 'MESSAGES_REMOVE_FILTER',
    'REMOVE_SINGLE_RESULT': 'MESSAGES_REMOVE_SINGLE_RESULT',
    'UPDATE_SINGLE_RESULT': 'MESSAGES_UPDATE_SINGLE_RESULT',
};
