import FormWizardController from './form-wizard-controller';

function FormWizardDirective() {
    return {
        restrict: 'A',
        scope: {},
        controller: FormWizardController,
    };
}

export default FormWizardDirective;
