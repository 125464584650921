/**
  * @ngdoc service
  * @name AttachmentViewerService
  * @module portfolium.core
  */
export class AttachmentViewerService {
    constructor(
        PF_MEDIA_TYPES,
        PF_ENTRY_ATTACHMENTS_TYPES,
        PF_ATTACHMENT_VIEWER_TYPES,
        $pfMediaTypes,
        $pfUrlUtils,
        $sce,
    ) {
        this.PF_MEDIA_TYPES = PF_MEDIA_TYPES;
        this.PF_ENTRY_ATTACHMENTS_TYPES = PF_ENTRY_ATTACHMENTS_TYPES;
        this.PF_ATTACHMENT_VIEWER_TYPES = PF_ATTACHMENT_VIEWER_TYPES;
        this.$pfMediaTypes = $pfMediaTypes;
        this.$pfUrlUtils = $pfUrlUtils;
        this.$sce = $sce;
    }

    /**
     * Is this an 'image' attachment
     * @param  {Object}  attachment The attachment object
     * @return {Boolean}
     */
    isTypeImage(attachment) {
        if (_.isNil(attachment)) {
            return;
        }
        return attachment.type === this.PF_ENTRY_ATTACHMENTS_TYPES.IMAGE.name;
    }

    /**
     * Is this a 'document' attachment
     * @param  {Object}  attachment The attachment object
     * @return {Boolean}
     */
    isTypeDocument(attachment) {
        if (_.isNil(attachment)) {
            return;
        }
        return attachment.type === this.PF_ENTRY_ATTACHMENTS_TYPES.DOCUMENT.name;
    }

    /**
     * Is this a 'website' attachment
     * @param  {Object}  attachment The attachment object
     * @return {Boolean}
     */
    isTypeWebsite(attachment) {
        if (_.isNil(attachment)) {
            return;
        }
        return attachment.type === this.PF_ENTRY_ATTACHMENTS_TYPES.WEBSITE.name;
    }

    /**
     * Is this an 'oembed' attachment
     * @param  {Object}  attachment The attachment object
     * @return {Boolean}
     */
    isTypeOembed(attachment) {
        if (_.isNil(attachment)) {
            return;
        }
        return attachment.type === this.PF_ENTRY_ATTACHMENTS_TYPES.OEMBED.name;
    }

    /**
     * Is this supported in the media player
     * @param  {Object}  attachment The attachment object
     * @param  {Object}  provider   The media provider object (from PF_MEDIA_TYPES.supportedMediaProviders)
     * @return {Boolean}
     */
    isSupportedInPlayer(attachment, provider = {}) {
        const url = this.getSourceUrl(attachment);
        if (!url) {
            return false;
        }

        // get the provider name for this url, if needed
        if (_.isEmpty(provider)) {
            provider = this.getWebsiteProvider(attachment);
            // if we still don't have a provider, nothing left to do
            if (_.isEmpty(provider)) {
                return false;
            }
        }
        return _.indexOf(this.PF_MEDIA_TYPES.attachmentViewerSupportedAudioVideo, provider.name) >= 0;
    }

    /**
     * Is this a supported audio mimetype
     * @param  {Object}  attachment The attachment object
     * @return {Boolean}
     */
    isSupportedAudioMimetype(attachment) {
        const mimetype = this.getMimeType(attachment);
        if (!mimetype) {
            return false;
        }
        return _.indexOf(this.PF_MEDIA_TYPES.attachmentViewerSupportedAudioTypes, mimetype) >= 0;
    }

    /**
     * Is this an audio attachment
     * @param  {Object}  attachment The attachment object
     * @return {Boolean}
     */
    isAudio(attachment) {
        const type = this.getMimeType(attachment);
        if (!_.isString(type)) {
            return false;
        }
        // check mimetype for 'audio/'
        const matched = type.match(/^audio\//);
        return !_.isEmpty(matched);
    }

    /**
     * Is this attachment supported in Filestack iframe
     * @param  {Object}  attachment The attachment object
     * @return {Boolean}
     */
    isFilestackSupported(attachment) {
        // make sure it has a filestack handle
        if (!this.getFilestackHandle(attachment)) {
            return false;
        }
        return this.$pfMediaTypes.isFSIframeSupported(this.getDocumentFilename(attachment));
    }

    /**
     * @description Determine if the attachment is a valid image
     * @param {String} attachment The attachment to validate
     * @return {Boolean}
     */
    isSupportedAttachmentImage(attachment) {
        // any image of type IMAGE
        return this.isTypeImage(attachment);
    }

    /**
     * @description Determine if the attachment is a valid document
     * @param {String} attachment The attachment to validate
     * @return {Boolean}
     */
    isSupportedAttachmentDocument(attachment) {
        // any document of type DOCUMENT
        if (!this.isTypeDocument(attachment)) {
            return false;
        }
        // needs to be supported by filestack
        if (!this.isFilestackSupported(attachment)) {
            return false;
        }
        return true;
    }

    /**
     * @description Determine if the attachment is a valid format for the audio media player
     * @param {String} attachment The attachment to validate
     * @return {Boolean}
     */
    isSupportedAttachmentAudio(attachment) {
        if (_.isEmpty(attachment)) {
            return;
        }

        // any document of type DOCUMENT
        if (!this.isTypeDocument(attachment)) {
            return false;
        }
        // has file url
        if (_.isEmpty(this.getDocumentUrl(attachment))) {
            return false;
        }
        // is supported audio/video
        if (!this.isSupportedAudioMimetype(attachment)) {
            return false;
        }
        return true;
    }

    /**
     * @description Determine if the attachment is a valid format for the iframe media player
     * @param {String} attachment The attachment to validate
     * @return {Boolean}
     */
    isSupportedAttachmentPlayer(attachment) {
        // of type WEBSITE
        if (!this.isTypeWebsite(attachment)) {
            return false;
        }
        // has source_url
        if (_.isEmpty(this.getSourceUrl(attachment))) {
            return false;
        }
        // has supported embed url
        const provider = this.getWebsiteProvider(attachment);
        if (_.isEmpty(provider)) {
            return false;
        } else {
            // is supported audio/video
            if (!this.isSupportedInPlayer(attachment, provider)) {
                return false;
            }
        }
        return true;
    }

    /**
     * @description Determine if the attachment is a valid format for the oEmbed
     * @param {Object} attachment The attachment to validate
     * @return {Boolean}
     */
    isSupportedAttachmentOembed(attachment) {
        if (!_.has(attachment, 'meta.oembed.embed')) {
            return false;
        }
        return true;
    }

    /**
     * @description Determine if the attachment is a valid format for the iframe standard embed
     * @param {String} attachment The attachment to validate
     * @return {Boolean}
     */
    isSupportedAttachmentEmbed(attachment) {
        // of type WEBSITE
        if (!this.isTypeWebsite(attachment)) {
            return false;
        }
        // has source_url
        if (_.isEmpty(this.getSourceUrl(attachment))) {
            return false;
        }
        // has supported embed url
        const provider = this.getWebsiteProvider(attachment);
        if (_.isEmpty(provider)) {
            return false;
        } else {
            // is NOT supported audio/video
            if (this.isSupportedInPlayer(attachment, provider)) {
                return false;
            }
        }
        return true;
    }

    /**
     * @description Determine if the attachment is a valid link for the website viewer
     * @param {String} attachment The attachment to validate
     * @return {Boolean}
     */
    isSupportedAttachmentWebsite(attachment) {
        // of type WEBSITE
        if (!this.isTypeWebsite(attachment)) {
            return false;
        }
        // has source_url
        if (_.isEmpty(this.getSourceUrl(attachment))) {
            return false;
        }
        // does NOT have a supported embed url
        const provider = this.getWebsiteProvider(attachment);
        if (!_.isEmpty(provider)) {
            return false;
        }
        return true;
    }

    /**
     * @description Determine if the attachment is a valid format for the iframe standard embed
     * @param {String} attachment The attachment to validate
     * @return {Boolean}
     */
    isSupportedAttachmentDownload(attachment) {
        // is a document that can only be downloaded
        //   - is a document type
        //   - has a url
        //   - is non-audio and has no filestack handle OR not a supported audio file
        if (
            this.isTypeDocument(attachment) &&
            !_.isEmpty(this.getDocumentUrl(attachment)) &&
            ((!this.isAudio(attachment) && _.isEmpty(this.getFilestackHandle(attachment))) ||
            !this.isSupportedAudioMimetype(attachment))
        ) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'DOWNLOAD.name');
        }

        // of type DOCUMENT
        if (!this.isTypeDocument(attachment)) {
            return false;
        }
        // has url
        if (_.isEmpty(this.getDocumentUrl(attachment))) {
            return false;
        }
        // is non-audio and has no filestack handle OR not a supported audio file
        if (
            !((!this.isAudio(attachment) && _.isEmpty(this.getFilestackHandle(attachment)))
            || !this.isSupportedAudioMimetype(attachment))
        ) {
            return false;
        }
        return true;
    }

    /**
     * @description Get the attachment type
     * @return {String|Undefined}
     */
    getAttachmentType(attachment) {
        if (_.isEmpty(attachment)) {
            return;
        }

        // is an image
        if (this.isSupportedAttachmentImage(attachment)) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'IMAGE.name');
        }

        // is a document
        if (this.isSupportedAttachmentDocument(attachment)) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'DOCUMENT.name');
        }

        // is audio
        if (this.isSupportedAttachmentAudio(attachment)) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'AUDIO.name');
        }

        // is an oembed
        if (this.isSupportedAttachmentOembed(attachment)) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'OEMBED.name');
        }

        // is a video
        if (this.isSupportedAttachmentPlayer(attachment)) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'PLAYER.name');
        }

        // is an embed
        if (this.isSupportedAttachmentEmbed(attachment)) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'EMBED.name');
        }

        // is an non-media and non-embed website
        if (this.isSupportedAttachmentWebsite(attachment)) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'WEBSITE.name');
        }

        // is a document that can only be downloaded
        if (this.isSupportedAttachmentDownload(attachment)) {
            return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'DOWNLOAD.name');
        }

        // else unsupported
        return _.get(this.PF_ATTACHMENT_VIEWER_TYPES, 'UNSUPPORTED.name');
    }

    /**
     * Get the website provider
     * @param  {Object}  attachment The attachment object
     * @return {Object|undefined}
     */
    getWebsiteProvider(attachment) {
        const url = this.getSourceUrl(attachment);
        if (_.isEmpty(url)) {
            return;
        }
        let provider;

        _.each(this.PF_MEDIA_TYPES.supportedMediaProviders, (p, key) => {
            _.each(p.urlPatterns, (pattern) => {
                const match = url.match(pattern);
                if (match) {
                    // create the embedUrl
                    let embedUrl;
                    switch (p.embedUrlCreateMethod) {
                        case 'append':
                            embedUrl = p.embedUrl + encodeURIComponent(url);
                            break;
                        default:
                        // use only matched part of url, so we can enforce the protocol in embedUrl
                        embedUrl = match[0].replace(pattern, p.embedUrl);
                    }
                    // add the params, if needed
                    if (p.urlParams && !_.isEmpty(embedUrl)) {
                        embedUrl = this.$pfUrlUtils.addParamsToUrl(embedUrl, p.urlParams);
                    }
                    // set provider
                    provider = {
                        name: p.name,
                        url,
                        embedUrl,
                    };
                    // TODO - (angular 1.6.x) don't need to pass <audio>, <video> tags
                    //        to $sce.trustAsResourceUrl()
                    if (provider.embedUrl) {
                        provider.trustedUrl = this.$sce.trustAsResourceUrl(provider.embedUrl);
                    }
                    // exit _.each() loop
                    return false;
                }
            });
            if (provider) {
                // exit outer _.each()
                return false;
            }
        });
        return provider;
    }

    /**
     * Get the url based off type for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String|Null}
     */
    getSupportedUrl(attachment) {
        // get url for a image
        if (this.isSupportedAttachmentImage(attachment)) {
            return this.getImageUrl(attachment);
        }
        // get url for a document
        if (this.isSupportedAttachmentDocument(attachment)) {
            return this.getDocumentUrl(attachment);
        }
        // get url for a document
        if (this.isSupportedAttachmentAudio(attachment)) {
            return this.getDocumentUrl(attachment);
        }
        return null;
    }

    /**
     * Get the image url for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getImageUrl(attachment) {
        return _.get(attachment, 'meta.image.file.url');
    }

    /**
     * Get the document url for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getDocumentUrl(attachment) {
        return _.get(attachment, 'meta.file.url');
    }

    /**
     * Get the document filename for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getDocumentFilename(attachment) {
        const path = this.getFilestackKey(attachment) || '';
        // return the last part of the url path, which should be the filename
        return path.split('/').pop();
    }

    /**
     * Get the filestack key for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getFilestackKey(attachment) {
        return _.get(attachment, 'meta.file.filestack.key');
    }

    /**
     * Get the filestack handle for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getFilestackHandle(attachment) {
        return _.get(attachment, 'meta.file.filestack.handle');
    }

    /**
     * Get the source url for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getSourceUrl(attachment) {
        return _.get(attachment, 'meta.source_url');
    }

    /**
     * Get the source url for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getEmbedUrl(attachment) {
        return _.get(attachment, 'meta.oembed.embed');
    }

    /**
     * Get the preview url for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getPreviewUrl(attachment) {
        return _.get(attachment, 'meta.preview.file.url');
    }

    /**
     * Get the domain name for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getDomain(attachment) {
        return _.get(attachment, 'meta.domain.domain');
    }

    /**
     * Get the domain title for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getDomainName(attachment) {
        return _.get(attachment, 'meta.domain.name');
    }

    /**
     * Get the domain favicon url for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getDomainIconUrl(attachment) {
        return _.get(attachment, 'meta.domain.favicon');
    }

    /**
     * Get the MIME type for this attachment
     * @param  {Object}  attachment The attachment object
     * @return {String}
     */
    getMimeType(attachment) {
        if (this.isTypeImage(attachment)) {
            return _.get(attachment, this.PF_ENTRY_ATTACHMENTS_TYPES.IMAGE.mimetypePath);
        }
        if (this.isTypeDocument(attachment)) {
            return _.get(attachment, this.PF_ENTRY_ATTACHMENTS_TYPES.DOCUMENT.mimetypePath);
        }
        if (this.isTypeWebsite(attachment)) {
            return _.get(attachment, this.PF_ENTRY_ATTACHMENTS_TYPES.WEBSITE.mimetypePath);
        }
        return null;
    }
}

AttachmentViewerService.$inject = [
    'PF_MEDIA_TYPES',
    'PF_ENTRY_ATTACHMENTS_TYPES',
    'PF_ATTACHMENT_VIEWER_TYPES',
    '$pfMediaTypes',
    '$pfUrlUtils',
    '$sce',
];
