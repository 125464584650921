import AdditionalFeedbackFormTemplate from './additional-feedback-form.component.html';
import './additional-feedback-form.component.scss';

export const AdditionalFeedbackFormComponent = {
    bindings: {
        onCancel: '&',
        onAdditionalFeedback: '&',
    },
    template: AdditionalFeedbackFormTemplate,
};
