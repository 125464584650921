function truncateStringFilter() {
    /**
     * Truncate a string, adds ellipsis, and optionally, preserves filename extension
     * @param  {String} text            Input value
     * @param  {Number} length          Length of resulting string
     * @param  {String} fill            The text to replace the removed text
     * @param  {Boolean} preserveExt    Preserve filename extension
     * @return {String}                 Truncated string
     */
    return function (text, length, fill = '...', preserveExt) {
        if (!_.isString(text)) {
            return text;
        }
        if (text.length <= length) {
            return text;
        }

        let ext = '';

        if (preserveExt) {
            const lastIndex = text.lastIndexOf('.');
            if (lastIndex > 0) {
                ext = text.substr(lastIndex);
                text = text.substr(0, lastIndex);
            }
        }

        text = text.substr(0, (length - fill.length - ext.length)) + fill + ext;

        return text;
    };
}

function descriptionToHtmlFilter($sce, $pfStringToLink) {
    /**
     * converts markdown links to <a> tags and line breaks to <br/> tags
     * @param  {String} text        Input value
     * @param  {Array} exclusions   An array of filters to exclude
     * @return {String}           String with HTML links and breaks
     */
    return function (text, exclusions) {
        if (!_.isString(text)) {
            return text;
        }
        if (!_.isArray(exclusions)) {
            exclusions = [];
        }

        let filters = _.difference([
            'url',
            'domain',
            'hashtag',
            'email',
            'markdown',
        ], exclusions);

        _.each(filters, (filter) => {
            text = $pfStringToLink[filter](text);
        });

        // replace line breaks
        text = text.replace(/\n/gi, '<br/>');

        return $sce.trustAsHtml(text);
    };
}

descriptionToHtmlFilter.$inject = ['$sce', '$pfStringToLink'];

export {
    descriptionToHtmlFilter,
    truncateStringFilter
};

