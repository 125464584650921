import _ from 'lodash';

import {
    SETTINGS_EMAILS_REQUEST,
    SETTINGS_EMAILS_ADD,
    SETTINGS_UPDATE_EMAIL,
    SETTINGS_UPDATE_EMAILS,
    SETTINGS_REMOVE_EMAIL,
    SETTINGS_MAKE_EMAIL_PRIMARY,
    SETTINGS_ORDER_EMAILS
} from './settings-email.actions';

export const settingsEmails = (state = {}, action = {}) => {

    switch (action.type) {
        case SETTINGS_EMAILS_REQUEST:
            return _.assign({}, state, {
                loading: true
            });
        case SETTINGS_EMAILS_ADD:
            return _.assign({}, state, {
                emails: [...state.emails,
                        action.payload.email]
            });
        case SETTINGS_UPDATE_EMAIL:
            return _.assign({}, state, {
                emails: [...(_.filter(state.emails,(email) => {
                            return email.id !== action.payload.email.id;
                        })),
                        action.payload.email]
            });
        case SETTINGS_UPDATE_EMAILS:
            return _.assign({}, state, {
                loading: false,
                emails: action.payload.emails
            });
        case SETTINGS_REMOVE_EMAIL:
            return _.assign({}, state, {
                emails: _.filter(state.emails,(email) => {
                    return email.id !== action.payload.emailId;
                })
            });
        case SETTINGS_ORDER_EMAILS:
            return _.assign({}, state, {
                emails: _.orderBy(state.emails,
                    ['primary','email'],
                    ['desc','asc'])
                });
        case SETTINGS_MAKE_EMAIL_PRIMARY:
            return _.assign({}, state, {
                emails: state.emails.map((email)=>{
                        let isPrimary = '0';
                        if (email.id === action.payload.emailId) {
                            isPrimary = '1';
                        }

                        return _.assign({}, email, {
                            primary: isPrimary
                        })
                    })
                });
        default:
            return state;
    }
};
