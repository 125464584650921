import angular from 'angular';

export class ScrollService {
    constructor(
        $document,
        $timeout,
    ) {
        this.$document = $document;
        this.$timeout = $timeout;
    }

    /**
     * Scroll to the specified element within the container
     * @param   {Object}    args    The properties that are passed to $pfSpotlightPanel._createPanel()
     * @return  {Void}
     */
    scrollTo(container, selector, delay = 0, offset = 0, duration = 1000) {
        container = (container) ? angular.element(container) : this.$document;

        if (!selector) {
            return;
        }

        this.$timeout(() => {
            const elem = angular.element(selector);
            container.scrollToElementAnimated(elem, offset, duration);
        }, delay);
    }
}

ScrollService.$inject = [
    '$document',
    '$timeout',
];
