import EntryCardMobileDiscoverTemplate from './entry-card-mobile-discover.component.html';
import './entry-card-mobile-discover.component.scss';

import StandardEntryCardController from '../js/controller/standard-entry-card-controller';

/**
 * @ngdoc component
 * @name pfEntryCardFullWidth
 * @module portfolium.component.entryCard
 */
export const EntryCardMobileDiscoverComponent = {
    bindings: {
        entry: '<pfEntry',
        editable: '<pfEditable'
    },
    controller: StandardEntryCardController,
    controllerAs: '$ctrl',
    template: EntryCardMobileDiscoverTemplate,
};
