/**
* @ngdoc service
* @name $pfIdentifyMedia
* @module portfolium.component.mediaEmbed
* @description Tools to identify and validate embedded (playable) media
*              Currently we support youtube, vimeo, and soundcloud
*/
export class IdentifyMediaService {
    constructor(
        PF_MEDIA_TYPES,
        $pfYoutube,
        $pfVimeo,
        $pfSoundcloud,
    ) {
        this.PF_MEDIA_TYPES = PF_MEDIA_TYPES;
        this.providers = {
            youtube: $pfYoutube,
            vimeo: $pfVimeo,
            soundcloud: $pfSoundcloud,
        };
    }

    /**
     * @description Get the media provider by URL.
     * @param  {String} url  The media URL
     * @return {Object}      The returned object will have 3 properties: provider, embedUrl, url;
     */
    byUrl(url) {
        let provObj = {provider: null, embedUrl: null, url: null};
        _.each(this.providers, (p) => {
            const supportedMedia = this.getSupportedMediaTypeByName(p.provider);
            if (supportedMedia.urlValidators && supportedMedia.urlValidators.length) {
                _.each(supportedMedia.urlValidators, (str) => {
                    const idx = url.indexOf(str);
                    if (idx >= 0) {
                        provObj.provider = p.provider;
                        provObj.url = url;
                        // get the embed url
                        if (p.getEmbedUrl && _.isFunction(p.getEmbedUrl)) {
                            provObj.embedUrl = p.getEmbedUrl(url);
                        }
                    }
                });
            }
            if (provObj.provider) {
                // break out of loop early if we found the provider
                return false;
            }
        });
        return provObj;
    }

    /**
     * @description Get the media provider by name.
     * @param  {String} providerName  The provider name
     * @param  {String} url           The media URL
     * @return {Object}               The returned object will have 3 properties: provider, embedUrl, url;
     */
    byName(providerName, url) {
        let provObj = {provider: providerName, embedUrl: null, url: url};
        const provider = this.providers[providerName];
        if (provider) {
            provObj.provider = provider.provider;
            provObj.url = url;
            // get the embed url
            if (provider.getEmbedUrl && _.isFunction(provider.getEmbedUrl)) {
                provObj.embedUrl = provider.getEmbedUrl(url);
            }
        }
        return provObj;
    }

    /**
     * Get the media provider by name
     * @param  {String}  name The name of the media provider
     * @return {Object}
     */
    getSupportedMediaTypeByName(name) {
        return _.find(this.PF_MEDIA_TYPES.supportedMediaProviders, {name});
    }
}

IdentifyMediaService.$inject = [
    'PF_MEDIA_TYPES',
    '$pfYoutube',
    '$pfVimeo',
    '$pfSoundcloud',
];
