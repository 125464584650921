import BottomNavTabTemplate from './bottom-nav-tab.component.html';
import './bottom-nav-tab.component.scss';

/**
 * @ngdoc controller
 * @name BottomNavTabController
 * @module portfolium.component.bottomNavTab
 */
class BottomNavTabController {
    constructor($state, $window, $element) {
        this.$state = $state;
        this.$window = $window;
        this.$element = $element;
    }

    /**
     * Split out current state and check if it includes the parent state
     * @return {Boolean} [description]
     */
    get isStateActive() {
        if (!this.stateName) {
            return false;
        }

        if (
            !_.isEmpty(this.activeStates) &&
            _.isArray(this.activeStates)
        ) {
            return !!_.find(
                this.activeStates,
                stateName => this.$state.includes(stateName)
            );
        } else {
            const [parentState] = this.stateName.split('.');
            if (this.$state.includes(parentState)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Transitions to passed in state with any params,
     * or if on a PHP page, transitions to href
     * @return {undefined|Promise}
     */
    onClick() {
        // do nothing if no state name is passed in
        if (!this.stateName) {
            return;
        }

        // check for state and transition
        if (!_.isNull(this.$state.get(this.stateName)) &&
            !!this.$state.current.name) {
            // if the current state is child, and there is
            // no desire to reload just dont do anything
            if (this.reloadOnParent && (this.$state.includes(this.stateName))) {
                return false;

            }
            return this.$state.go(this.stateName, this.stateParams);
        }

        // For php pages
        this.$window.location.assign(`/${this.pfHref}`);

        return;
    }
}

BottomNavTabController.$inject = ['$state', '$window', '$element'];

/**
 * @ngdoc component
 * @name BottomNavTabComponent
 * @module portfolium.component.bottomNavTab
 * @description Displays icon and text, button transitions to state-name. Accepts custom classes.
 * @param {string} icon Ligature value for the material icon to display
 * @param {string} text Link text to display
 * @param {string} state-name State to transition to on click
 * @param {array} active-states Collection of parent states to make button active on
 * @param {object} [state-params] Key/value params map for state transition
 * @param {boolean} [notification] if true, notification jewel will be used
 * @param {string} pf-class Custom class applied to button
 * @param {string} pf-href Used to transition from php pages
 * @param {boolean} reload-on-parent if true, it will check if the current state is child of
 *                                   state that user wants to go, and disables the transition
*/
export const BottomNavTabComponent = {
    transclude: {
        'icon': '?iconReplacement',
    },
    bindings: {
        icon: '<?',
        text: '<',
        stateName: '<?',
        activeStates: '<?',
        stateParams: '<?',
        notification: '<?',
        pfHref: '<?',
        pfClass: '<?',
        reloadOnParent: '<',
    },
    template: BottomNavTabTemplate,
    controller: BottomNavTabController,
    controllerAs: '$ctrl'
};
