import angular from 'angular';

import addEntryDirective from './js/add-entry-directive'
import AddEntryService from './js/add-entry-service'

(function () {
    // Define add project app module
    let app = angular.module('portfolium.addEntry', []);

    // Directives
    app.directive('pfAddEntry', addEntryDirective);
    // Services
    app.service('$pfAddEntry', AddEntryService);
}());

export default 'portfolium.addEntry';
