import {combineReducers} from 'redux';
import {getPaginationReducer} from 'components/pagination/pagination.reducer';

import {
    EXPORT_PROFILE_OPEN,
    EXPORT_PROFILE_CLOSE,
    EXPORT_PROFILE_SEARCH_RESULTS_UPDATE,
    EXPORT_PROFILE_SEARCH_RESULTS_RESET,
    entryActionTypes,
} from './export-profile.actions';

const initialState = {
    open: false,
    searchResults: [],
    searchResultsVisible: false,
}

export const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case EXPORT_PROFILE_OPEN:
            return _.assign({}, state, {
                open: true
            });
        case EXPORT_PROFILE_CLOSE:
            return _.assign({}, state, {
                open: false,
                user: {},
                searchResults: [],
                searchResultsVisible: false,
            });
        case EXPORT_PROFILE_SEARCH_RESULTS_UPDATE:
            return _.assign({}, state, {
                searchResults: action.payload.results,
                searchResultsVisible: true,
            });
        case EXPORT_PROFILE_SEARCH_RESULTS_RESET:
            return _.assign({}, state, {
                searchResults: [],
                searchResultsVisible: false
            });
        default:
            return state;
    }
};

export const exportProfile = combineReducers({
    meta,
    entries: getPaginationReducer({
        limit: 15,
        actionTypes: entryActionTypes,
        paginationStyle: 'infinite',
    }),
});
