import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/startWith';

/**
 * @ngdoc service
 * @name $pfInfinite
 * @module portfolium.component.infiniteScroll
 *
 * @description
 * `$pfInfinite` is a helper for creating an infinite scrolling list of elements.
 */
export class InfiniteScrollService {
    /**
     * @ngdoc method
     * @name $pfInfinite#getPosition
     *
     * @description
     * Get the height/offset position of a container/element relative to the
     * document
     *
     * @param  {HTMLElement} container Container element
     * @param  {HTMLElement} element   Element within the container
     * @return {Object}                Position object
     */
    getPosition(container, element) {
        return {
            container: {
                height: container.height(),
                offset: container.offset().top,
            },
            element: {
                height: element.height(),
                offset: element.offset().top,
            },
        };
    }

    /**
     * @ngdoc method
     * @name $pfInfinite#hasReachedBottom
     *
     * @description
     * Determine whether the bottom of an element has reached the bottom of a
     * container element
     *
     * @param  {Object}  containerPos Height/offset of the container
     * @param  {Object}  elementPos   Height/offset of the element
     * @param  {Number}  distance     Offset distance (measured in units of
     *     container height)
     * @return {Boolean}
     */
    hasReachedBottom(containerPos, elementPos, distance = 0) {
        const bottomEl = elementPos.offset + elementPos.height;
        let bottomContainer = (containerPos.offset + containerPos.height);

        if (distance > 0) {
            // If distance option is provided, increase the bottom out distance
            bottomContainer += (containerPos.height * (distance + 1));
        }

        if (bottomEl <= bottomContainer) {
            return true;
        }

        return false;
    }

    /**
     * @ngdoc method
     * @name $pfInfinite#scrollStream
     *
     * @description
     * Get a stream of events triggered when the bottom of an element reaches
     * the bottom of a container element
     *
     * @param  {HTMLElement}                container Container element
     * @param  {HTMLElement}                element   Element within the container
     * @param  {Number}  offsetDistance     Offset distance (measured in units of
     *     container height)
     * @return {Observable<Object>}         Position object
     */
    scrollStream(container, element, offsetDistance = 1) {
        return Observable
            .fromEvent(container, 'scroll')
            .startWith(true)
            .map(() => this.getPosition(container, element))
            .filter((pos) => this.hasReachedBottom(pos.container, pos.element, offsetDistance));
    }
}
