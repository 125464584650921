import moment from 'moment';
import { USER_TYPES } from './user-types';
import { USER_STATUSES } from './user-statuses';
import { fullNameFilter } from '../../../pf-utils/js/filter/name-filters';

export class PortfoliumUser {
    constructor(data) {
        this._data = data;
    }

    /**
     * @param  {Object} value Avatar object
     */
    set avatar(value = {}) {
        this.data = _.assign({}, this._data, {
            avatar: value,
        });
    }

    /**
     * @return {String}
     */
    get avatar() {
        if (!this._data.avatar) {
            return '';
        }

        return this._data.avatar.url_https;
    }

    /**
     * @return {Undefined|String} User's birthday in 'YYYY-MM-DD' format
     */
    get birthday() {
        return this._data.birthday;
    }

    /**
     * Is this user under 13 years old?
     * @return {Bool}
     */
    get isUnderage() {
        // sanity check for birthday
        if (_.isEmpty(this._data.birthday)) {
            return false;
        }
        const today = moment();
        const birthday = moment(this._data.birthday);
        const age = moment.duration(today.diff(birthday)).years();
        return age < 13;
    }

    /**
     * @return {Number} Connections count
     */
    get connections() {
        return parseInt(this._data.connections, 10);
    }

    /**
     * @param  {Object} value Cover object
     */
    set cover(value = {}) {
        this.data = _.assign({}, this._data, {
            cover: value,
        });
    }

    /**
     * @return {String} Cover photo URL
     */
    get cover() {
        if (!this._data.cover) {
            return '';
        }

        return this._data.cover.url_https;
    }

    /**
     * @return {String} Cover photo URL
     */
    get coverObject() {
        if (!this._data.cover) {
            return '';
        }

        return this._data.cover;
    }

    /**
     * @return {Undefined|String} Created at date in ISO-8601 format
     */
    get createdAt() {
        if (!this._data.created_at) {
            return undefined;
        }

        return moment.utc(this._data.created_at, 'YYYY-MM-DD HH:mm:ss').toISOString();
    }

    /**
     * @return {String} Gender
     */
    get gender() {
        return this._data.gender;
    }

    /**
     * @return {String} Race
     */
    get race() {
        return this._data.race;
    }

    /**
     * @return {String} Language
     */
    get language() {
        return this._data.language;
    }

    /**
     * @return {String}
     */
    get gradYear() {
        return this._data.grad_year;
    }

    /**
     * @return {String}
     */
    get email() {
        return this._data.email;
    }

    /**
     * @return {Number} Entries count
     */
    get entries() {
        return parseInt(this._data.entries, 10);
    }

    /**
     * @param  {String} value First name
     */
    set firstName(value = '') {
        this._data = _.assign({}, this._data, {
            firstname: value,
        });
    }

    /**
     * @return {String} First name
     */
    get firstName() {
        return this._data.firstname;
    }

    /**
     * @return {String} User ID
     */
    get id() {
        return this._data.id;
    }

    /**
     * @return {String}
     */
    get intro() {
        return this._data.intro;
    }

    /**
     * @param  {String} value Introduction
     * @return {String}
     */
    set intro(value = '') {
        this.data = _.assign({}, this._data, {
            intro: value,
        });

        return this._data.intro;
    }

    /**
     * @param  {String} value Last name
     */
    set lastName(value = '') {
        this._data = _.assign({}, this._data, {
            lastname: value,
        });
    }

    /**
     * @return {String}
     */
    get lastName() {
        return this._data.lastname;
    }

    /**
     * Get the user's full name
     * @return {String}
     */
    get name() {
        /**
         * Kinda hacky, unable to inject angular filters into this model so we're using the filter function directly
         * This will cover every case where the 'name' property is accessed
         */
        return fullNameFilter()(this._data);
    }

    /**
     * @return {String}
     */
    get major() {
        return this._data.major;
    }

    /**
     * @return {String}
     */
    get phone() {
        return this._data.phone;
    }

    /**
     * @return {String}
     */
    get school() {
        return this._data.school;
    }

    /**
     * @return {Number} Skills count
     */
    get skills() {
        return parseInt(this._data.skills, 10);
    }

    /**
     * @return {Object}
     */
    get spokes() {
        return this._data.spokes;
    }

    /**
     * @return {string}
     */
    get status() {
        return this._data.status;
    }

    /**
     * @returns {String[]|null}
     */
    get features() {
        return this._data.features;
    }

    /**
     * @param  {String} value tagline
     */
    set tagline(value = '') {
        this._data = _.assign({}, this._data, {
            tagline: value,
        });
    }

    /**
     * @return {String} tagline
     */
    get tagline() {
        return this._data.tagline;
    }

    /**
     * @return {Object} User type
     */
    get type() {
        let typeId = parseInt(this._data.type, 10);

        return _.find(USER_TYPES, {id: typeId}) || {};
    }

    /**
     * @param  {String} value Username
     */
    set username(value = '') {
        this._data = _.assign({}, this._data, {
            username: value,
        });
    }

    /**
     * @return {String}
     */
    get username() {
        return this._data.username;
    }

    /**
     * @return {String}
     */
    get resume() {
        return this._data.resume;
    }

    /**
     * @return {String}
     */
    get lms() {
        return this._data.lms_access;
    }

    /**
     * Does the user have access to assignments?
     * @return {Boolean}
     */
    get hasTasksAccess() {
        return this._data.tasks_access === true;
    }

    /**
     * Is the user a company administrator/owner?
     * @return {Boolean}
     */
    isCompanyAdmin() {
        return this._data.company_admin === true;
    }

    /**
     * Does the user have a temporary password?
     * @return {Boolean}
     */
    isTempPassword() {
        return this._data.temp_pw === true;
    }

    /**
     * Is the user a pre-populated account?
     * @return {Boolean}
     */
    isPrepop() {
        return this._data.prepop === true;
    }

    /**
     * Did the user auth via SSO (Shibboleth)
     * @return {Boolean}
     */
    isSsoUser() {
        return this._data.auth_source === 'shibboleth';
    }

    /**
     * Check if user authed via LTI (Simple or Course Flow)
     * @return {Boolean}
     */
    isLtiUser() {
        return this._data.auth_source === 'lti';
    }

    /**
     * Is the user an extracted account?
     * @return {Boolean}
     */
    isExtracted() {
        return this._data.extracted === true;
    }

    /**
     * Is the user a first generation student?
     * @return {Boolean}
     */
    isFirstGen() {
        return this._data.first_gen === true;
    }

    /**
     * Is the user actively seeking a job?
     * @return {Boolean}
     */
    isSeekingJob() {
        return this._data.seeking_job === true;
    }

    /**
     * Is the user actively seeking an internship?
     * @return {Boolean}
     */
    isSeekingInternship() {
        return this._data.seeking_internship === true;
    }

    /**
     * Has the user completed onboarding?
     * @return {Boolean}
     */
    isOnboarded() {
        return this._data.onboarded === true;
    }

    /**
    * Is users primary email verified?
    * @return {Boolean}
    */
    isConfirmedEmail() {
        return this._data.status === USER_STATUSES.active;
    }

    /**
    * Is user set to private?
    * @return {Boolean}
    */
    isPrivate() {
        return this._data.privacy === true;
    }

    /**
     * Is the user active?
     * @return {Boolean}
     */
    isActive() {
        return this._data.status === USER_STATUSES.active;
    }

    /**
     * Is the the user being 'acted as' by an administrator?
     * @return {Boolean}
     */
    isActingAs() {
        return !!this._data.actor;
    }

    /**
     * Update user data with new raw data
     * @param  {Object}         data Raw json data to set on user
     * @return {PortfoliumUser}      Updated user object
     */
    update(data = {}) {
        this._data = _.assign({}, this._data, data);
        return this;
    }
}
