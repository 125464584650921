class AddEntriesAction
{
    constructor($pfGameAction, $pfEntryEditor) {
        this.$pfGameAction = $pfGameAction;
        this.$pfEntryEditor = $pfEntryEditor;
        this.name = 'add_more_entries';
    }

    get label() {
        let label = 'Add a portfolio project';
        let currentLevel = this.$pfGameAction.getCurrentLevel() || {};

        if (this.type === 'Has_many') {
            return 'Add more portfolio projects';
        }

        if (currentLevel.name === 'rookie') {
            label = 'Add 2+ portfolio projects';
        }

        if (currentLevel.name === 'pro') {
            label = 'Add 3+ portfolio projects';
        }

        if (currentLevel.name === 'champ') {
            label = 'Add more portfolio projects';
        }

        return label;
    }

    execute() {
        return this.$pfEntryEditor.newEntry({
            source: 'profileStrengthSuggestion',
        });
    }
}

AddEntriesAction.$inject = ['$pfGameAction', '$pfEntryEditor'];

export default AddEntriesAction;
