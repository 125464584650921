import PaginationFilterTypeCheckboxTemplate from './pagination-filter-type-checkbox.component.html';

/**
 * @ngdoc controller
 * @name PaginationFilterTypeCheckboxController
 * @module portfolium.component.PaginationFilterTypeCheckboxComponent
 */
class PaginationFilterTypeCheckboxController {
    /**
    * @ngdoc method
    * @name PaginationFilterTypeCheckboxController#$constructor
    * @methodOf PaginationFilterTypeCheckboxController
    * @description get the current filter status
    */
    constructor(){
        this.selected = false;
    }

    $onInit(){
        this.selected = this.isSelected(this.selectedOptions);
    }

    $onChanges(changed){
        // update the checkboxes if they are changed in redux, i.e. reset
        if (changed.selectedOptions) {
            const values = changed.selectedOptions.currentValue;
            const isSelected = this.isSelected(values);
            if (this.selected !== isSelected) {
                this.selected = isSelected;
            }
        }
    }

    isSelected(selectedOptions){
        if (_.isNil(selectedOptions)) {
            return false;
        }
        return _.includes(selectedOptions, this.filterOption.value);
    }

    handleChange(){
        this.onChange({
            value: this.filterOption.value,
            filterName: this.filterName,
        });
    }
}

/**
 * @ngdoc component
 * @name PaginationFilterTypeCheckboxComponent
 * @module portfolium.component.PaginationFilterTypeCheckboxComponent
 */
export const PaginationFilterTypeCheckboxComponent = {
    bindings: {
        filterName: '@',
        filterOption: '<',
        selectedOptions: '<',
        onChange: '&',
    },
    template: PaginationFilterTypeCheckboxTemplate,
    controller: PaginationFilterTypeCheckboxController,
    controllerAs: '$ctrl'
};
