export const edpCommentsActionTypes = {
    'PAGE_INIT': 'EDP_COMMENTS_PAGE_INIT',
    'PAGE_NEXT': 'EDP_COMMENTS_PAGE_NEXT',
    'PAGE_PREVIOUS': 'EDP_COMMENTS_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'EDP_COMMENTS_PAGE_REQUEST',
    'PAGE_SUCCESS': 'EDP_COMMENTS_PAGE_SUCCESS',
    'PAGE_ERROR': 'EDP_COMMENTS_PAGE_ERROR',
    'TOGGLE_SELECTION': 'EDP_COMMENTS_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'EDP_COMMENTS_CLEAR_SELECTION',
    'TOGGLE_ALL': 'EDP_COMMENTS_TOGGLE_ALL',
    'ADD_FILTER': 'EDP_COMMENTS_ADD_FILTER',
    'REMOVE_FILTER': 'EDP_COMMENTS_REMOVE_FILTER',
    'PREPEND_SINGLE_RESULT': 'EDP_COMMENT_PREPEND_SINGLE_RESULT',
    'REMOVE_SINGLE_RESULT': 'EDP_COMMENT_REMOVE_SINGLE_RESULT',
};

export const EDP_COMMENTS_DECREMENT = 'EDP_COMMENTS_DECREMENT';
export const EDP_COMMENTS_INCREMENT = 'EDP_COMMENTS_INCREMENT';
export const EDP_ADD_LIKER = 'EDP_ADD_LIKER';
export const EDP_LIKE_ENTRY = 'EDP_LIKE_ENTRY';
export const EDP_UNLIKE_ENTRY = 'EDP_UNLIKE_ENTRY';
export const EDP_INIT = 'EDP_INIT';
export const EDP_RESET_ENTRY = 'EDP_RESET_ENTRY';
export const EDP_REMOVE_TEAMMATE_FROM_ENTRY = 'EDP_REMOVE_TEAMMATE_FROM_ENTRY';
export const EDP_UPDATE_ENTRY = 'EDP_UPDATE_ENTRY';

export const decrementEntryComments = () => {
    return {
        type: EDP_COMMENTS_DECREMENT,
    };
}

export const incrementEntryComments = () => {
    return {
        type: EDP_COMMENTS_INCREMENT,
    };
}

export const initEntry = () => {
    return {
        type: EDP_INIT,
    };
}

export const addLiker = (liker) => {
    return {
        type: EDP_ADD_LIKER,
        payload: {
            liker,
        }
    };
};

export const likeEntry = () => {
    return {
        type: EDP_LIKE_ENTRY,
    };
};

export const unlikeEntry = () => {
    return {
        type: EDP_UNLIKE_ENTRY,
    };
};

export const resetEntry = () => {
    return {
        type: EDP_RESET_ENTRY,
    };
};

export const removeSelfAsTeammate = (teammateId) => {
    return {
        type: EDP_REMOVE_TEAMMATE_FROM_ENTRY,
        payload: {
            teammateId,
        }
    };
}

export const updateEntry = (entry) => {
    return {
        type: EDP_UPDATE_ENTRY,
        payload: {
            entry,
        },
    };
};

