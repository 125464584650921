import JobApplyBtnTemplate from './job-apply-btn.component.html';

/**
 * @ngdoc controller
 * @name JobApplyBtnController
 * @module portfolium.component.jobApplyBtn
 */
class JobApplyBtnController {
    constructor(
        $pfUser,
        $pfSubscription,
        $mdDialog,
        $mdMedia,
        $pfJobApply,
        $state,
        $stateParams,
        $pfToast,
        $pfCookies,
        $pfJobs,
        $ngRedux,
        PF_COOKIES,
        PF_JOB_APPLY_ACTIONS,
        $pfJobApplyCenterPanel,
        $pfEnv,
    ){
        this.$pfUser = $pfUser;
        this.$pfSubscription = $pfSubscription;
        this.$mdDialog = $mdDialog;
        this.$mdMedia = $mdMedia;
        this.$pfJobApply = $pfJobApply;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$pfToast = $pfToast;
        this.$pfCookies = $pfCookies;
        this.$pfJobs = $pfJobs;
        this.$ngRedux = $ngRedux;
        this.PF_COOKIES = PF_COOKIES;
        this.PF_JOB_APPLY_ACTIONS = PF_JOB_APPLY_ACTIONS;
        this.$pfJobApplyCenterPanel = $pfJobApplyCenterPanel;
        this.$pfEnv = $pfEnv;
        this.user = $pfUser.getUser();
        this.isCompanyAdmin = !!$pfSubscription.getCompanyId();
        this.jobApplied = false;
        this.jobBookmarked = false;
        this.checkedPendingActions = false;
    }

    $onInit() {
        // Subscribe to application state changes and map actions to this
        this.unsubscribe = this.$ngRedux.connect(this._mapStateToThis)(this);
    }

    $onDestroy() {
        // unsubscribe from redux
        this.unsubscribe();
    }

    $onChanges(changed) {
        if (changed.job.currentValue) {
            this.jobBookmarked = this._isBookmarked();
            this.jobApplied = this._isApplied();

            // check for pending actions now that we have job details
            this.checkPendingActions();
        }
    }

    /**
    * @ngdoc method
    * @description Maps entries to the controller
    * @param  {Object} state current state.
    * @return {Object} New state of entries.
    **/
    _mapStateToThis(state) {
        return {
            applyPanelIsOpen: state.jobApplyCenter.meta.open,
        };
    }

    /**
    * @ngdoc method
    * @description Check pending actions that should happen after a user authenticated
    * @return {Void}
    **/
    checkPendingActions() {
        // don't check more than once
        if (!this.checkedPendingActions) {
            this.checkedPendingActions = true;

            // see if the job apply panel should be opened
            const nextAction = this.$pfCookies.get(this.PF_COOKIES.NEXT_ACTION);
            // remove the cookie
            this.$pfCookies.remove(this.PF_COOKIES.NEXT_ACTION, {
                domain: this.$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                path: '/',
            });

            // open the panel if it's not already opened
            if ((nextAction === this.PF_JOB_APPLY_ACTIONS.OPEN_APPLY_PANEL) && !this.applyPanelIsOpen) {
                // sanity check
                if (this.user) {
                    this.applyToJob();
                }
                return;
            }

            // save the job
            if (nextAction === this.PF_JOB_APPLY_ACTIONS.SAVE_JOB) {
                // sanity check
                if (this.user) {
                    this.bookmarkJob();
                }
                return;
            }
        }
    }

    /**
     * @ngdoc function
     * @description Bookmark the job
     * @return {Promise}
     */
    bookmarkJob() {
        this.jobBookmarked = true;
        return this.$pfJobs
            .bookmarkJob(this.job.id)
                .then(null, (err) => {
                    this.jobBookmarked = false;
                    this.$pfToast
                        .error('There was a problem saving this job. Please try again');
                });
    }

    /**
     * @ngdoc function
     * @description Is the job bookmarked
     * @return {Boolean}
     */
    isBookmarked() {
        return this.jobBookmarked;
    }

    /**
     * @ngdoc function
     * @description If you are the admin for this job
     * @return {Boolean}
     */
    isJobAdmin() {
        if (!this.job) {
            return;
        }

        if (!this.isCompanyAdmin) {
            return false;
        }

        return this.$pfSubscription.getCompanyId() === this.job.company.id;
    }

    /**
     * @ngdoc function
     * @description Can we show the apply button?
     * @return {Boolean}
     */
    canShowApplyButton() {
        if (!this.job) {
            return;
        }

        return !this._isExternal();
    }

    /**
     * @ngdoc function
     * @description Can we show the "apply on company website" button?
     * @return {Boolean}
     */
    canShowApplyExternalButton() {
        if (!this.job) {
            return;
        }

        return this._isExternal();
    }

    /**
     * @ngdoc function
     * @description Go to the job details page
     * @return {Promise}
     */
    goToJob() {
        return this.$state.go('jobApplications', {
            jobId: this.job.id,
        });
    }

    /**
     * @ngdoc function
     * @description Determine if the job is sourced
     * @return {Boolean}
     */
    isSourced() {
        return this._isSourced();
    }

    /**
     * @ngdoc function
     * @description Determine if the job is external
     * @return {Boolean}
     */
    isExternal() {
        return this._isExternal();
    }

    /**
     * @ngdoc function
     * @description Set the next job apply action after authentication
     * @return {String}
     */
    setNextJobApplyAction() {
        return this.PF_JOB_APPLY_ACTIONS.OPEN_APPLY_PANEL;
    }

    /**
     * @ngdoc function
     * @description Set the next job bookmark action after authentication
     * @return {String}
     */
    setNextJobBookmarkAction() {
        return this.PF_JOB_APPLY_ACTIONS.SAVE_JOB;
    }

    /**
     * @ngdoc function
     * @description Apply to an internal job
     * @param  {Event}   $event Click event from ng-click
     * @param  {Boolean} campaignTargetMet For tracking external job applications
     * @return {Promise}
     */
    applyToJob($event, campaignTargetMet) {
        /** unauthenticated
         * We also bypass authentication (handled in jobApplyPreAuthDirective)
         * when we have a case where:
         *    * job is internal
         *    * job is sourced
         *    * user is un-authenticated
         */
        if (!this.user) {
            // is the job sourced from zip or fbg
            return this._unauthedApply($event, campaignTargetMet);
        }
        // authenticated
        return this._showJobApplyCenter();
    }

    /**
     * @ngdoc function
     * @description Apply to an external job
     * @param  {Event} $event Click event from ng-click
     * @return {Promise}
     */
    applyToExternalJob($event) {
        /**
         * Open a new tab to the external job
         * We do this here to avoid popup blockers
         * because if you use a window.open in an AJAX response, it blocks it.
         */
        return this.applyOutsideLink($event, this.job);
    }

    /**
     * @ngdoc function
     * @description Apply to an external job
     * @param  {Event}  $event  Click event from ng-click
     * @param {Object}  job     The job record
     * @param {Boolean} saveJob Save the job?
     * @return {Promise}
     */
    applyOutsideLink($event, job, saveJob) {
        if ($event) {
            $event.preventDefault();
        }
        return this.$pfJobApply.applyOutsideLink(job, saveJob);
    }

    /**
     * @ngdoc function
     * @description Check if there is a job url, if not this is an Apply on Portfolium job
     * @return {Boolean}
     */
    _isExternal() {
        return !_.isEmpty(this.job.url);
    }

    /**
     * @ngdoc function
     * @description Check for a user AND if the job is external to show a link
     * @return {Boolean}
     */
    _showExternal() {
        return this._isExternal() && this.user;
    }

    /**
     * @ngdoc function
     * @description Check if the user has applied to the job
     * @return {Boolean}
     */
    _isApplied() {
        return this.job.applied === '1';
    }

    /**
     * @ngdoc function
     * @description Check if the user has bookmarked the job
     * @return {Boolean}
     */
    _isBookmarked() {
        return this.job.bookmarked === '1';
    }

    /**
     * @ngdoc function
     * @description Check if the job was scraped off the web
     * @return {Boolean}
     */
    _isScraped() {
        return this.job.scraped === '1';
    }

    /**
     * @ngdoc function
     * @description Check if the job is sourced
     * @return {Boolean}
     */
    _isSourced() {
        const {src} = this.$stateParams;
        return src === 'zip' || src === 'fbg';
    }

    /**
     * @ngdoc function
     * @description Return a jobs tier (1, 2 or 3)
     * @return {Boolean}
     */
    _getTier() {
        return this.job.tier;
    }

    /**
     * @ngdoc function
     * @description Check if there is marketing tracking cookie set and it's
     * this job we are viewing
     * @return {Boolean}
     */
    _isTargetJob(targetJobId) {
        return targetJobId === this.job.id;
    }

    /**
     * @ngdoc function
     * @description Apply to a job unauthed
     * @param {Boolean} campaignTargetMet is there a marketing cookie
     * @return {Promise}
     */
    _unauthedApply($event, campaignTargetMet) {
        // Show the job apply modal for unauthed user
        return this.$pfJobApply.unauthedJobApplyModal($event, this.job, {
            campaignTargetMet,
        });
    }

    /**
     * @ngdoc function
     * @description Show the intrested modal
     * @return {Promise}
     */
    _showIntrestedModal() {
        // Show the intrested modal
        return this.$pfJobApply.showIntrestedModal(this.job);
    }

    /**
     * @ngdoc function
     * @description Show the apply modal
     * @return {Promise}
     */
    _showJobApplyCenter() {
        // Show the apply modal
        return this.$pfJobApplyCenterPanel.open({
            job: this.job,
            onJobApplied: () => {
                this.jobApplied = true;
            },
        });
    }
}

JobApplyBtnController.$inject = [
    '$pfUser',
    '$pfSubscription',
    '$mdDialog',
    '$mdMedia',
    '$pfJobApply',
    '$state',
    '$stateParams',
    '$pfToast',
    '$pfCookies',
    '$pfJobs',
    '$ngRedux',
    'PF_COOKIES',
    'PF_JOB_APPLY_ACTIONS',
    '$pfJobApplyCenterPanel',
    '$pfEnv',
];

export const JobApplyBtnComponent = {
    bindings: {
        job: '<',
    },
    template: JobApplyBtnTemplate,
    controller: JobApplyBtnController,
    controllerAs: '$ctrl',
};
