import angular from 'angular';

class GameActionService {
    constructor(
        $q,
        $state,
        $timeout,
        $window,
        $animate,
        $pfGameEngine,
        $pfUser,
        $pfProfileAboutPanel,
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$animate = $animate;
        this.$pfGameEngine = $pfGameEngine;
        this.$pfProfileAboutPanel = $pfProfileAboutPanel;
        this.currentUser = $pfUser.getUser() || {};
    }

    /**
     * Alias to $pfGameEngine.getCurrentLevel()
     * @return {Boolean|Object} User's current level object
     */
    getCurrentLevel() {
        return this.$pfGameEngine.getCurrentLevel();
    }

    /**
     * Scroll to an element on the page
     * @param  {String}  container DOM selector for container element
     * @param  {String}  selector  DOM selector for element to scroll to
     * @param  {Number}  offset    Offset from top of page (defaults to middle of the container)
     * @return {Promise}
     */
    scrollToElement(container, selector, offset) {
        let containerEl = angular.element(container);

        return this.$timeout(() => {
            let elem = angular.element(selector).filter(':visible');
            let defaultOffset = containerEl.height() / 2 - elem.height() / 2;

            if (!elem.length) {
                return this.$q.reject();
            }

            return containerEl.scrollToElementAnimated(
                elem,
                offset || defaultOffset,
            );
        });
    }

    /**
     * Find correct button and trigger click event
     * @param  {String}  panelName Name of the panel we need to open
     */
    openPanel(panelName, onCloseSuccess) {
        this.$pfProfileAboutPanel
            .addAbout(panelName, onCloseSuccess)
            .then(null, err => {
                throw new Error(
                    `[GameActionService#openPanel] Error opening panelName -> ${panelName}: ${err}`,
                );
            });
    }

    /**
     * Find correct button and trigger click event
     * @param  {String}  panelName Name of the panel we need to open
     */
    openPanelEdit(
        panelName,
        data,
        focusSelector,
        attachEntries,
        onCloseSuccess,
    ) {
        this.$pfProfileAboutPanel
            .editAbout(
                panelName,
                data,
                focusSelector,
                attachEntries,
                onCloseSuccess,
            )
            .then(null, err => {
                throw new Error(
                    `[GameActionService#openPanelEdit] Error opening panelName -> ${panelName}: ${err}`,
                );
            });
    }

    /**
     * Highlight an element on the page using ngAnimate
     * @param  {String} selector DOM selector for element to highlight
     * @return {Promise}
     */
    highlightElement(selector) {
        let elements = angular.element(selector).filter(':visible');

        _.forEach(elements, elem => {
            this.$animate.addClass(elem, 'pf-highlighted-element');
        });

        return this.$timeout(() => {
            _.forEach(elements, elem => {
                this.$animate.removeClass(elem, 'pf-highlighted-element');
            });
        }, 1500);
    }

    /**
     * Redirect to the given path
     * @param  {String} uri URI to redirect to
     */
    redirect(uri = '') {
        this.$window.location.assign(uri);
    }

    /**
     * Redirect to the current user's portfolio
     * @param  {Object}  [params] Optional params hash
     * @return {Promise}
     */
    redirectToPortfolio(params = {}) {
        return this.$state.go(
            'profile.portfolio',
            _.assign(
                {},
                {
                    username: this.currentUser.username,
                },
                params,
            ),
        );
    }

    /**
     * Redirect to the current user's about page
     * @param  {Object}  [params] Optional params hash
     * @return {Promise}
     */
    redirectToAbout(params = {}) {
        return this.$state.go(
            'profile.about',
            _.assign(
                {},
                {
                    username: this.currentUser.username,
                },
                params,
            ),
        );
    }
}

GameActionService.$inject = [
    '$q',
    '$state',
    '$timeout',
    '$window',
    '$animate',
    '$pfGameEngine',
    '$pfUser',
    '$pfProfileAboutPanel',
];

export default GameActionService;
