import {combineReducers} from 'redux';
import {competencies} from './competencies';
import {competencyCategories} from './competency-categories';
import {competencyList} from './competency-list';
import {competencyPanel} from './competency-panel';

export const competencyLibrary = combineReducers({
    competencies,
    competencyCategories,
    competencyList,
    competencyPanel,
});
