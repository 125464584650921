/**
 * @ngdoc service
 * @module portfolium.auth.authProviders
 * @name $pfAuthProvider
 * @description
 * Retrieve any of the supported authentication providers and allow the user to
 * grant access to Portfolium.
 */
export class AuthProviderService {
    constructor($injector, providers) {
        // We use $injector to allow auth providers access to the DI container
        this.providers = providers.map(
            provider => $injector.instantiate(provider)
        );
    }

    /**
     * Retrieve a provider from the set of supported/registered providers
     * @param {string} provider Name of the provider to fetch
     * @return {AuthProvider}
     */
    get(provider) {
        const authProvider = _.find(this.providers, { name: provider });
        if (!authProvider) {
            throw new Error(`No provider found for "${provider}"`);
        }
        return authProvider;
    }
}
