export class SitewideSearchService
{
    constructor($http) {
        this.$http = $http;
    }

    /**
     * @description Get People that matches the query
     * @param {Object} query Search query
     * @return {Promise<Array<Object>>} Promise resolved with collection of user objects
     */
    getPeople(query = {limit, offset, q}) {
        query = _.assign(query,{'top_entries': 'true'});
        return this.$http({
            method: 'GET',
            url: `/proxy/es/users`,
            params: query,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Get People that matches the query
     * @param {Object} query Search query
     * @return {Promise<Array<Object>>} Promise resolved with collection of network objects
     */
    getNetworks(query = {limit, offset, q}) {
        query = _.assign(query, {
            'launched': 'true',
            'top_entries': 'true',
        });
        return this.$http({
            method: 'GET',
            url: `/proxy/es/networks`,
            params: query,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Get Companies that matches the query
     * @param {Object} query Search query
     * @return {Promise<Array<Object>>} Promise resolved with collection of company objects
     */
    getCompanies(query = {limit, offset, q}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/search/companies`,
            params: query,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Get Jobs that matches the query
     * @param {Object} query Search query
     * @return {Promise<Array<Object>>} Promise resolved with collection of job objects
     */
    getJobs(query = {limit, offset, q}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/es/jobs`,
            params: query,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Get Featured Jobs
     * @param {Object} query Search query
     * @return {Promise<Array<Object>>} Promise resolved with collection of job objects
     */
    getFeaturedJobs(query = {limit, offset}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/feed`,
            params: query,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Get Entries that matches the query
     * @param {Object} query Search query
     * @return {Promise<Array<Object>>} Promise resolved with collection of entry objects
     */
    getEntries(query = {limit, offset, q}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/es/entries`,
            params: query,
        })
        .then((response) => {
            return response.data;
        });
    }
}

SitewideSearchService.$inject = ['$http'];
