import EntryViewerCommentListTemplate from './entry-viewer-comment-list.component.html';
import './entry-viewer-comment-list.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerCommentListController
 *
 */
class EntryViewerCommentListController {
    /**
     * Request to remove comment
     * @param  {number} commentId
     */
    deleteComment(commentId) {
        this.onDeleteComment({commentId});
    }
}


/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerCommentListComponent
 * @description Display the entry comments on the entry viewer
 *
 * @param {Boolean}     isEntryOwner is currently authed user the entry owner
 * @param {string}      commentCount    Total number of comments
 * @param {object[]}    entryComments   List of comment objects
 * @param {boolean}     isPreview       Is the user viewing a preview?
 * @param {callback}    onDeleteComment Request to delete a comment
 * @param {callback}    onNextComments  Request to load more comments
 *
 */
export const EntryViewerCommentListComponent = {
    bindings: {
        isEntryOwner: '<',
        commentCount: '<',
        entryComments: '<',
        isPreview: '<',
        onDeleteComment: '&',
        onNextComments: '&',
    },
    controller: EntryViewerCommentListController,
    controllerAs: '$ctrl',
    template: EntryViewerCommentListTemplate,
};
