export const PATHWAY_DETAILS_FETCH = 'PATHWAY_DETAILS_FETCH';
export const PATHWAY_DETAILS_FETCH_SUCCESS = 'PATHWAY_DETAILS_FETCH_SUCCESS';
export const PATHWAY_RESET = 'PATHWAY_RESET';

/**
 * Initiates fetch pathway sets fetching to true
 * */
export const fetchPathway = () => {
    return {
        type: PATHWAY_DETAILS_FETCH,
    };
};

/**
 * Assigns and returns fetched pathway
 * */
export const onPathwayFetchSuccess = ({
        pathway,
        pathway_user: pathwayUser,
        network
    }) => {
    return {
        type: PATHWAY_DETAILS_FETCH_SUCCESS,
        payload: {
           pathway,
           pathwayUser,
           network
        },
    };
};

/**
 * Resets Pathway
 * */
export const resetPathway = () => {
    return {
        type: PATHWAY_RESET,
    };
};
