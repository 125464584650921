import {combineReducers} from 'redux';

import {getPaginationReducer} from 'components/pagination/pagination.reducer';
import {
    DISCOVER_UPDATE_CATEGORY,
    DISCOVER_UPDATE_DROPDOWN_OPTIONS,
} from './discover.actions';
import {discoverEntriesFeedActionTypes} from './discover-entries-feed/discover-entries-feed.actions';

export const discoverCategory = (state = {
    current: {},
    dropdownOptions: [],
}, action = {}) => {
    switch (action.type) {
        case DISCOVER_UPDATE_CATEGORY:
            return _.assign({}, state, {
                current: action.payload.category,
            });
        case DISCOVER_UPDATE_DROPDOWN_OPTIONS:
            return _.assign({}, state, {
                dropdownOptions: action.payload.options,
            });
        default:
            return state;
    }
};

export const discover = combineReducers({
    entriesFeed: getPaginationReducer({
        actionTypes: discoverEntriesFeedActionTypes,
        limit: 12,
        paginationStyle: 'infinite',
    }),
    category: discoverCategory,
});
