class AddResumeAction
{
    constructor($pfGameEngine, $pfResumeUpload) {
        this.$pfGameEngine = $pfGameEngine;
        this.$pfResumeUpload = $pfResumeUpload;
        this.name = 'add_your_resume';
        this.label = 'Add your resume';
    }

    execute() {
        const updateGameEngine  = () => {
            this.$pfGameEngine.updateResults();
        };
        return this.$pfResumeUpload.openDialog(null, false, updateGameEngine);
    }
}

AddResumeAction.$inject = ['$pfGameEngine', '$pfResumeUpload'];

export default AddResumeAction;
