const MODULE_NAME = 'portfolium.core.services.filestack';

import {FILESTACK_CONFIG} from './filestack-config.constant';
import {FILESTACK_CONVERT} from './filestack-convert.constant';
import {FILESTACK_PRESETS} from './filestack-presets.constant';
import {FilestackConvertService} from './filestack-convert.service';
import {FilestackOptionsService} from './filestack-options.service';
import {FilestackPresetService} from './filestack-preset.service';
import {FilestackSignerService} from './filestack-signer.service';
import {FilestackService} from './filestack.service';
import {MIMETYPES_CONFIG} from './mimetypes-config.constant';

(function () {
    angular.module(MODULE_NAME, [])
        // Constants
        .constant('PF_FILESTACK_CONFIG', FILESTACK_CONFIG)
        .constant('PF_FILESTACK_CONVERT', FILESTACK_CONVERT)
        .constant('PF_MIMETYPES_CONFIG', MIMETYPES_CONFIG)
        .constant('PF_FILESTACK_PRESETS', FILESTACK_PRESETS)
        // Services
        .service('$pfFilestack', FilestackService)
        .service('$pfFilestackConvert', FilestackConvertService)
        .service('$pfFilestackOptions', FilestackOptionsService)
        .service('$pfFilestackPreset', FilestackPresetService)
        .service('$pfFilestackSigner', FilestackSignerService);
}());

export default MODULE_NAME;
