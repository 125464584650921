/* exported modal */
/* exported navigation */

import angular from 'angular';
import utilities from 'components/utilities';
// import navigation from 'components/navigation';
// import modal from 'components/modal';
import 'jquery-infinite-scroll';
import 'jquery-scrolllock';
import 'components/social-share';
import 'script-loader!sweetalert';
import Cookies from 'js-cookie';

export default (function () {
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE
    let content;

    function refreshCsrf() {
        $.ajax('/proxy/users/me', {
            type: 'GET',
            data: null,
            complete: setupAjax
        });

        setTimeout(refreshCsrf, HOUR);
    }

    function setupAjax() {
        // this bit needs to be loaded on every page where an ajax POST
        let setupData = {};
        let csrfCookieName = 'pf-web-csrf';
        let env = window.Portfolium.environment;
        if (env.PF_CSRF_WEB_COOKIE_NAME) {
            csrfCookieName = env.PF_CSRF_WEB_COOKIE_NAME;
        } else {
            csrfCookieName = env.PF_ENVIRONMENT === 'production' ? csrfCookieName : `${csrfCookieName}-${env.PF_ENVIRONMENT}`;
        }
        // add the csrf token to the setup data
        setupData.csrf_token = Cookies.get(csrfCookieName);
        // setup ajax forms for csrf
        $.ajaxSetup({
            data: setupData
        });
        // listen for all AJAX complete calls
        $(document).ajaxComplete(function () {
            // re-get the new CSRF token
            setupData.csrf_token = Cookies.get(csrfCookieName);
            // reset all hidden CSRF inputs on forms
            $('input[name=csrf_token]').val(setupData.csrf_token);
            // resetup ajax forms for csrf
            $.ajaxSetup({
                data: setupData
            });
        });
    }

    // run the saved actions in the run-action cookie
    function runSavedActions() {
        // sanity check that the user is authenticated
        if ($('body.authed').length) {
            const { PF_COOKIE_DOMAIN } = window.Portfolium.environment;
            // get the data in the run-action cookie
            let runActionCookieName = 'pf-run-action',
                action = Cookies.get(runActionCookieName);
            // sanity check that there is an action to run
            if (action) {
                // delete the cookie
                let deleteStatus = $.removeCookie(runActionCookieName, {path: '/', domain: PF_COOKIE_DOMAIN});
                // sanity check for success that the cookie was deleted
                if (deleteStatus) {
                    // redirect to the action route
                    window.location = action;
                }
            }
        }
    }

    function bindInfiniteScroll() {
        let parent = $('#pf-content');
        // init infinitescroll
        content.infinitescroll({
            // set data type
            dataType: 'json',
            // disable callback for json
            appendCallback: false,
            // selector for the paged navigation (it will be hidden)
            navSelector: '.pagination',
            // selector for the NEXT link (to page 2)
            nextSelector: '.page-next a',
            // selector for all items you'll retrieve
            itemSelector: '.cards .card',
            // loading box
            loading: {
                // clear text
                msg: $('<div class="inf-card-spinner"><hr><div><md-icon>autorenew</md-icon></div></div>'),
                // define the loading selector
                selector: content
            },
            // disable animate
            animate: false,
            // force the original behavior
            behavior: undefined,
            // force the original binder
            binder: parent,
            // set id
            infid: 1
        }, function(json) {
            // cache the data content
            let newContent = $(json.feed);
            // append in the next set cards
            content.append(newContent);
            // make sure newContent had content
            if (newContent.length) {
                // compile the new content using Angular's compile service
                angular.element(document).injector().invoke(['$compile', function ($compile) {
                    let scope = angular.element(newContent).scope();
                    $compile(newContent)(scope);
                }]);
            }
        });
    }

    function bindCloseFlashBox() {
        // listen for the click of the close button
        $(document).on('click', '.flash-box .close', function() {
            // hide the flash box
            $(this).parent().fadeOut();
        });
    }

    function onLikeClick(jqEvent) {
        // break js chain
        jqEvent.preventDefault();
        // set like button
        let likeBtn = $(this),
            likedClass = 'liked disabled',
            likedUrl = likeBtn.data('url') || likeBtn.data('href'),
            cardStats = likeBtn.closest('.card-extras'),
            cardCount = cardStats.find('.like-count'),
            cardCountVal = parseInt(cardCount.html()),
            cardCountValPlusOne = cardCountVal + 1,
            detailStats = likeBtn.closest('.entry-body').find('.stats'),
            detailCount = detailStats.find('.like-count'),
            detailCountVal = parseInt(detailCount.html()),
            detailCountValPlusOne = detailCountVal + 1,
            likesContainer = $('#likes'),
            profileLink = $('<a>'),
            userAvatar = $('<img>'),
            jsonError = false,
            json;
        // sanity check for already liked
        if (likeBtn.hasClass(likedClass) === false) {
            // add liked class
            likeBtn.addClass(likedClass);
            // disabled since you can't unlike (commented out since this breaks accessibility)
            // likeBtn.attr('disabled', 'disabled').addClass('disabled');
            // bump up the count on card and detail page
            cardCount.html(cardCountValPlusOne);
            detailCount.html(detailCountValPlusOne);
            // add the current user's avatar and profile link to the likes container
            userAvatar.attr('src', utilities.dynamicImage(window.Portfolium.current_user.avatar.dynamic_https, 'resize', '100x100'));
            userAvatar.attr('alt', window.Portfolium.current_user.firstname + ' ' + window.Portfolium.current_user.lastname);
            userAvatar.addClass('avatar small');
            profileLink.attr('href', '/' + window.Portfolium.current_user.username);
            profileLink.attr('title', window.Portfolium.current_user.firstname + ' ' + window.Portfolium.current_user.lastname);
            profileLink.append(userAvatar);
            likeBtn.find('md-icon:contains("favorite")').html('favorite');
            likesContainer.append(profileLink);
            // show the likes container
            likesContainer.show();
            // POST the like
            $.ajax(likedUrl, {
                type: 'POST',
                dataType: 'json',
                complete: function (jqXHR) {
                    // get the response
                    try {
                        json = $.parseJSON(jqXHR.responseText);
                        if (!json.success) {
                            jsonError = true;
                        }
                    } catch (e) {
                        jsonError = true;
                        json = {
                            alert: 'There was an error performing this action',
                            alert_type: 'error'
                        };
                    }
                    if (jsonError) {
                        // remove the liked class
                        likeBtn.removeClass(likedClass);
                        // remove disabled state
                        // likeBtn.removeAttr('disabled').removeClass('disabled');
                        // revert the count change on card and detail page
                        cardCount.html(cardCountVal);
                        detailCount.html(detailCountVal);
                        // remove the current user's avatar and profile link from the likes container
                        profileLink.remove();
                        // hide the likes container if there are no other likers
                        if ($('a', likesContainer).length === 0) {
                            likesContainer.hide();
                        }
                        // show the growl alert
                        // utilities.showGrowl(json.alert, json.alert_type);
                        // shake the button
                        utilities.shake(likeBtn);
                    }
                }
            });
        }
    }

    function onVoteClick(jqEvent) {
        // break js chain
        jqEvent.preventDefault();
        // set vote button
        let voteBtn = $(this),
            votedClass = 'voted',
            jsonError = false,
            json;
        // sanity check for already voted
        if (voteBtn.hasClass(votedClass) === false) {
            // add voted class
            voteBtn.addClass(votedClass);
            // POST the vote
            $.ajax(voteBtn.data('url'), {
                type: 'POST',
                complete: function (jqXHR) {
                    // get the response
                    try {
                        json = $.parseJSON(jqXHR.responseText);
                        if (!json.success) {
                            jsonError = true;
                        }
                    } catch (e) {
                        jsonError = true;
                        json = {
                            alert: 'There was an error performing this action',
                            alert_type: 'error'
                        };
                    }
                    if (jsonError) {
                        // remove the voted class
                        voteBtn.removeClass(votedClass);
                        // remove disabled
                        // voteBtn.removeAttr('disabled').removeClass('disabled');
                        // show the growl alert
                        utilities.showGrowl(json.alert, json.alert_type);
                        // shake the button
                        utilities.shake(voteBtn);
                    } else {
                        voteBtn.find('md-icon').text('check');
                        voteBtn.find('.voted-text').text('Voted');
                    }
                }
            });
        }
    }

    function bindEntryCards() {
        // listen for clicks to open the EDP modal
        $(document).on('click', '.entry-card .modal-link', function() {
            // add the entry-sidebar class to the modal
            $('.modal-window').addClass('entry-sidebar');
        });
    }

    function onResendEmailClick(jqEvent) {
        // break js chain
        jqEvent.preventDefault();
        // get the href
        let href = $(this).attr('href');
        // resend the email
        $.get(href, function (data) {
            // show the growl message
            utilities.showGrowl(data.alert, data.alert_type);
        });
    }

    function bindPreventDragAndDrop() {
        document.addEventListener('drop', preventDragAndDrop);
        document.addEventListener('dragenter', preventDragAndDrop);
        document.addEventListener('dragover', preventDragAndDrop);
    }

    function preventDragAndDrop(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    function initSimpleAccordion() {
        $('.pf-simple-accordion-header').on('click', function ($event) {
            $event.preventDefault();
            $event.stopPropagation();

            let header = $(this);
            let body = header.siblings('.pf-simple-accordion-body');
            let icon = header.find('.pf-simple-accordion-header__icon i');
            let showOnCollapse = header.find('.pf-simple-accordion__show-on-collapse');

            if (body.hasClass('collapsed')) {
                $event.target.setAttribute('aria-expanded', 'true');
                header.removeClass('collapsed');
                body.removeClass('collapsed').show();
                showOnCollapse.hide();
                icon.text('remove');
            } else {
                $event.target.setAttribute('aria-expanded', 'false');
                header.addClass('collapsed');
                body.addClass('collapsed').hide();
                showOnCollapse.show();
                icon.text('add');
            }
        });
    }

    function initLtiLanderBanner() {
        let banner = $('.pf-lti-lander-banner');
        let closeButton = $('.pf-lti-lander-banner__close-button');

        closeButton.on('click', function ($event) {
            // hide the banner
            banner.hide();
            // tell em we closed the banner
            $.ajax(closeButton.data('route'), { type: 'POST' });
        });
    }

    // delegated events
    $(document).on('click', '.entry-like.ajax', onLikeClick);
    $(document).on('click', '.entry-vote.ajax', onVoteClick);
    $(document).on('click', '.growl.flash-box', utilities.hideGrowl);
    $(document).on('click', '.resend-email', onResendEmailClick);

    content = $('.cards').not('.no-scroll');

    refreshCsrf();
    runSavedActions();
    bindInfiniteScroll();
    bindCloseFlashBox();
    utilities.bindFormValidation();
    bindEntryCards();
    bindPreventDragAndDrop();
    initSimpleAccordion();
    initLtiLanderBanner();
}());
