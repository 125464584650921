export const myJobsAppliedActionTypes = {
    'PAGE_INIT': 'MY_JOBS_APPLIED_PAGE_INIT',
    'PAGE_NEXT': 'MY_JOBS_APPLIED_PAGE_NEXT',
    'PAGE_PREVIOUS': 'MY_JOBS_APPLIED_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'MY_JOBS_APPLIED_PAGE_REQUEST',
    'PAGE_SUCCESS': 'MY_JOBS_APPLIED_PAGE_SUCCESS',
    'PAGE_ERROR': 'MY_JOBS_APPLIED_PAGE_ERROR',
    'TOGGLE_SELECTION': 'MY_JOBS_APPLIED_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'MY_JOBS_APPLIED_CLEAR_SELECTION',
    'TOGGLE_ALL': 'MY_JOBS_APPLIED_TOGGLE_ALL',
    'ADD_FILTER': 'MY_JOBS_APPLIED_ADD_FILTER',
    'REMOVE_FILTER': 'MY_JOBS_APPLIED_REMOVE_FILTER',
};
