export class NetworkConnectService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * @description Get User Address
     * @returns {Promise}
     */
    requestToJoinNetwork(networkId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/networks/request/${networkId}`,
            params: {
                'network_id': networkId
            }
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Remove user from network
     * @returns {Promise}
     */
    leaveNetwork(networkId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/networks/membership/${networkId}`,
        })
        .then((response) => {
            return response.data;
        });
    }
}

NetworkConnectService.$inject = [
    '$http'
];
