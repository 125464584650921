export const JOB_SUMMARY_JOB_REQUEST    = 'JOB_SUMMARY_JOB_REQUEST';
export const JOB_SUMMARY_JOB_SUCCESS    = 'JOB_SUMMARY_JOB_SUCCESS';
export const JOB_SUMMARY_JOB_PUBLISH    = 'JOB_SUMMARY_JOB_PUBLISH';
export const JOB_SUMMARY_JOB_UNPUBLISH  = 'JOB_SUMMARY_JOB_UNPUBLISH';

export const jobRequest = (jobId) => {
    return {
        type: JOB_SUMMARY_JOB_REQUEST,
        payload: jobId,
    };
};

export const jobSuccess = (job) => {
    return {
        type: JOB_SUMMARY_JOB_SUCCESS,
        payload: job,
    };
};

export const jobPublish = (expiresOn) => {
    return {
        type: JOB_SUMMARY_JOB_PUBLISH,
        payload: expiresOn,
    };
};

export const jobUnpublish = () => {
    return {
        type: JOB_SUMMARY_JOB_UNPUBLISH,
    };
};
