import MobileMenuHeaderTemplate from '../../template/mobile-menu-header.html';

/**
 * @ngdoc component
 * @name mobileMenuHeaderComponent
 * @module portfolium.component.mobileMenu
 **/
 export const MobileMenuHeaderComponent = {
    bindings: {
        'closePanel': '&',
    },
    template: MobileMenuHeaderTemplate,
};
