import angular from 'angular';
import imagesLoaded from 'imagesloaded';
import tokenfield from 'components/tokenfield';
import growl from 'components/growl';
import dynamicImage from 'components/dynamic-image';
import 'jquery-validation';
import 'jquery-flexslider';

// validate the forms
function bindFormValidation() {
    // we must do an each to apply the validation to EACH form instance as the validation class is a singleton
    $.each($('form.ajax-validate'), function (index, ele) {
        $(ele).submit(function (e) {
            // stops form submit if autocomplete suggestions are visible
            if ($('.smarty-active-suggestion:visible').length > 0) {
                return suppress(e);
            }
            // stop the form submit
            e.preventDefault();
        }).validate({
            errorClass: 'has-error',
            highlight: function (element, errorClass, validClass) {
                // add error class
                $(element).parent().addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                // remove error class
                $(element).parent().removeClass(errorClass).addClass(validClass);
            },
            errorPlacement: function (error, element) {
                // break from errorPlacement
                return false;
            },
            submitHandler: function (form) {
                // submit the AJAX form
                submitAjaxForm($(form));
            },
            invalidHandler: function (event, validator) {
                // cache needed vars
                let form = $(event.target),
                    isFormInModal = form.closest('.modal-inner').length,
                    scrollTopSelector = isFormInModal ? '.modal-inner' : '#pf-content',
                    flash_box = form.find('.flash-box:first'),
                    hideErrors = form.data('pf-no-errors'),
                    noScroll = form.data('pf-no-scroll'),
                    showErrorToast = form.data('pf-error-toast'),
                    injector = angular.element('body').injector(),
                    $pfToast = injector && injector.get('$pfToast');

                // sanity check for NO flash box
                if (flash_box.length === 0 && !hideErrors && !showErrorToast) {
                    // set the new flash box
                    let new_flash = $('<div class="flash-box error" style="display: none;"><button type="button" class="close">&times;</button><span></span></div>');
                    // prepend flash div to the form
                    form.prepend(new_flash);
                    // re-get the flash box
                    flash_box = form.find('.flash-box:first');
                }
                // get the message of the flash box
                let message = flash_box.find('span');
                // remove old errors
                message.empty();
                // loop through each error
                for (let i = 0; i < validator.errorList.length; i++) {
                    // get the text of the element's label
                    let label = $(validator.errorList[i].element).prevAll('label').text();
                    // set the error field text
                    let field = label ? label + ' - ' : '';
                    // add to the flash message
                    message.append('<p>' + field + validator.errorList[i].message + '</p>');
                }
                // show toast if attribute is set
                if (showErrorToast && $pfToast && !flash_box.length) {
                    // show the first error
                    $pfToast.error(validator.errorList[0].message);
                }
                // remove all flash box classes
                flash_box.removeClass();
                // give flash box class of error
                flash_box.addClass('flash-box error');
                // show the alert box
                flash_box.fadeIn();
                // scroll to top of defined container
                if (!noScroll) {
                    $(scrollTopSelector).animate({scrollTop: 0}, 300);
                }
            }
        });
    });
}

function submitAjaxForm(form) {
    // get the flash box
    let flash_box = form.find('.flash-box:first');
    let hideErrors = form.data('pf-no-errors');
    let showErrorToast = form.data('pf-error-toast');
    let noScroll = form.data('pf-no-scroll');
    let injector = angular.element('body').injector();
    let $pfToast = injector && injector.get('$pfToast');

    // sanity check for NO flash box
    if (flash_box.length === 0 && !hideErrors && !showErrorToast) {
        // set the new flash box
        let new_flash = $('<div class="flash-box error" style="display: none;"><button type="button" class="close">&times;</button><span></span></div>');
        // prepend flash div to the form
        form.prepend(new_flash);
        // re-get the flash box
        flash_box = form.find('.flash-box:first');
    }
    // get the submit button
    let submit = form.find('[type=submit]');
    // store original button text
    let og_text = submit.val();
    // disable button
    disableButton(submit, true);
    // blur any selected form field
    $(':focus').blur();
    // get the post data
    let data = form.serializeArray();
    // make AJAX call
    $.ajax({
        url: form.attr('action'),
        data: data,
        type: 'post',
        dataType: 'json',
        success: function (json) {
            // Trigger on user settings page update, used to update session data
            $('#settings-profile-form').trigger('pf-settings-profile-update-success');

            // sanity check for a redirect url
            if (json.redirect) {
                // redirect to the set url right away
                window.location = json.redirect;
            } else {
                // set needed variables
                let isFormInModal = form.closest('.modal-inner').length,
                    scrollTopSelector = isFormInModal ? '.modal-inner' : '#pf-content';
                // remove all classes on flash box
                flash_box.removeClass().addClass('flash-box');
                // sanity check for success
                if (json.success) {
                    // sanity check for the retain flag and continue if it wasn't set or set to false
                    if (!json.retain) {
                        // reset the form
                        clearAllFormFields(form);
                    }
                    // add success class to make the flash box green
                    flash_box.addClass('success');
                    // add success message
                    flash_box.find('span').html(json.alert || 'Success!');
                    // fire the success event - pass the returned json
                    form.trigger({
                        type: 'ajax-submit-success',
                        json: json
                    });
                } else {
                    // reset just the password fields in the form
                    clearPasswordsFormFields(form);
                    // add error class to make the flash box red
                    flash_box.show().addClass('error');
                    // add error message
                    flash_box.find('span').html(json.alert || 'Error!');
                    // fire the error event - pass the returned json
                    form.trigger({
                        type: 'ajax-submit-error',
                        json: json
                    });
                    // show toast if attribute is set
                    if (showErrorToast && $pfToast) {
                        // error text is returned as html -- convert to text string
                        let errorTxt = $(json.alert).text();
                        $pfToast.error(errorTxt);
                    }
                }
                // re-enable button
                enableButton(submit, og_text, true);
                // fade in the flash box
                flash_box.fadeIn();
                // sanity check if the noScroll flag was NOT set
                if (!noScroll) {
                    // scroll to top of defined container
                    $(scrollTopSelector).animate({scrollTop: 0}, 300);
                }
            }
        }
    });
}

function disableButton(button, swapText) {
    // disable button
    button.attr('disabled', 'disabled');
    // add disabled class
    button.addClass('disabled');
    // sanity check to swap the text or not
    if (swapText) {
        // sanity check if the button is an anchor
        if (button.is('a')) {
            // update button text
            button.html('Loading...');
        } else {
            // update button text
            button.val('Loading...');
        }
    }
}

function enableButton(button, og_text, swapText) {
    // re-enable button
    button.removeAttr('disabled');
    // remove disabled class
    button.removeClass('disabled');
    // sanity check to swap the text or not
    if (swapText) {
        // sanity check if the button is an anchor
        if (button.is('a')) {
            // update button text
            button.html(og_text || 'Submit');
        } else {
            // revert button text
            button.val(og_text || 'Submit');
        }
    }
}

function clearPasswordsFormFields(form) {
    // loop through all elements in the form
    form.find(':input').each(function () {
        // sanity check for the password type
        if (this.type === 'password') {
            // clear field
            $(this).val('');
        }
    });
}

function clearAllFormFields(form) {
    // loop through all elements in the form
    form.find(':input').each(function () {
        // find the field type
        switch (this.type) {
            case 'password':
            case 'select-multiple':
            case 'select-one':
            case 'text':
            case 'textarea':
                // clear field
                $(this).val('');
                // break from switch
                break;
            case 'checkbox':
            case 'radio':
                // clear the checkbox or radio
                this.checked = false;
                // break from switch
                break;
        }
    });
}

function getScrollableRoot() {
    let {body} = document;
    let prev = body.scrollTop;
    body.scrollTop++;
    if (body.scrollTop === prev) {
        return document.documentElement;
    } else {
        body.scrollTop--;
        return body;
    }
}

function isMobile() {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
            check = true;
        }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}

function removeQueryStringParameter(uri, parameter) {
    return uri
        .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
        .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
}

function updateQueryStringParameter(uri, key, value) {
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    let separator = uri.indexOf('?') !== -1 ? '&' : '?';

    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        return uri + separator + key + '=' + value;
    }
}

function bindFlexSlider(selector) {
    // check for jQuery obj
    if (selector && selector.length) {
        // load a flexslider
        selector.flexslider({
            prevText: '',
            nextText: '',
            start: function (slider) {
                // wait for the images
                imagesLoaded($('.slides'), function () {
                    // center image in Flexslider
                    $('.slides li').each(function () {
                        // get heights, and get set offset.
                        let height = $(this).height(),
                            media = $(this).find('img, iframe'),
                            imageHeight = media.height(),
                            offset = (height - imageHeight) / 2;
                        // apply css offset to margin top with math
                        media.css({'margin-top': offset + 'px', 'visibility': 'visible'});
                    });
                });
            }
        });
    }
}

function suppress(event) {
    // Used to prevent form submits, and stop other events if needed
    if (!event) { return false; }
    if (event.preventDefault) { event.preventDefault(); }
    if (event.stopPropagation) { event.stopPropagation(); }
    if (event.stopImmediatePropagation) { event.stopImmediatePropagation(); }
    event.cancelBubble = true;
    return false;
}

function shake(jqObject) {
    // shake the object
    jqObject.addClass('animated shake');
    // wait till done animating
    jqObject.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
        // remove animated classes
        jqObject.removeClass('animated shake');
    });
}

function closeFilePicker() {
    // click the "x" to trigger the close method. There currently isn't a close function in the JS SDK
    $('#filepicker_dialog_container > a').click();
}

function isFilePickerOpen() {
    // check if the FilePicker dialog is open
    return $('#filepicker_dialog_container').is(':visible');
}

function startsWith(str, prefix) {
    return str ? (str.lastIndexOf(prefix, 0) === 0) : false;
}

function endsWith(str, suffix) {
    return str ? (str.indexOf(suffix, str.length - suffix.length) !== -1) : false;
}

function usernameAvailabilityCheck(jqEvent) {
    let input = $(this),
        container = input.closest('p, .pf-field'),
        label = $('label', container),
        i = $('i', label),
        val = input.val();
    // sanity check for a value
    if (val) {
        // check the username
        $.ajax('/system/username/check', {
            type: 'POST',
            data: {
                username: val
            },
            complete: function (jqXHR, textStatus) {
                // get the response
                let json = (jqXHR.status === 200) ? $.parseJSON(jqXHR.responseText) : null;
                // sanity check for success
                if (json && json.success === true) {
                    if (i.length === 0) {
                        i = $('<i>');
                        label.append(i);
                    }
                    if (json.available) {
                        i.attr('title', 'Username is available');
                    } else {
                        i.attr('title', 'Username is NOT available');
                    }
                } else {
                    // set growl vars
                    let message = json.alert || 'Error!',
                        alert_type = json.alert_type || 'error';
                    // show growl message
                    growl.show(message, alert_type);
                }
            }
        });
    } else {
        i.removeClass();
    }
}

function getParameterByName(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function nl2br(str, is_xhtml) {
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (htmlEntities(str) + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

function guid() {
    return getFourRandomHexValues() + getFourRandomHexValues() + '-' + getFourRandomHexValues() + '-' + getFourRandomHexValues() + '-' + getFourRandomHexValues() + '-' + getFourRandomHexValues() + getFourRandomHexValues() + getFourRandomHexValues();
}

function getFourRandomHexValues() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

function formatFilename(fullFileName) {
    // default the file name to what was passed in
    let formattedFilename = fullFileName;

    if (!fullFileName) {
        // Filename is undefined, return empty string
        return '';
    }

    // sanity check if the filename has a period in it
    if (fullFileName.indexOf('.') !== -1) {
        // get the filename
        let fileName = fullFileName.substring(0, fullFileName.lastIndexOf('.'));
        // get the file extension
        let fileExt = fullFileName.substring(fullFileName.lastIndexOf('.'));
        // format the filename
        formattedFilename = fileName.replace(/\W/g, '').substring(0, 100) + fileExt;
    }

    // return the formatted filename here
    return formattedFilename;
}

function removeSpinner() {
    $('.avatar-spinner, .hero-spinner').remove();
}

function htmlEntities(str) {
    return String(str)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
}

export default {
    // validate the forms
    bindFormValidation: bindFormValidation,

    // get the root object to scroll to the top (different in different browsers)
    getScrollableRoot: getScrollableRoot,

    // show a growl notification
    showGrowl: growl.show,

    // hide a growl notification with a 3 second timer on it
    hideGrowl: growl.hide,

    // disables a button and styles it
    disableButton: disableButton,

    // re-enables a button and styles it
    enableButton: enableButton,

    // flag to determine if mobile
    isMobile: isMobile,

    // remove query prams from a URL
    removeQueryStringParameter: removeQueryStringParameter,

    // append query params to a URL
    updateQueryStringParameter: updateQueryStringParameter,

    // bind a flexSlider
    bindFlexSlider: bindFlexSlider,

    // don't shake the baby
    shake: shake,

    // swap out the dynamicImage URL with the function and size you want
    dynamicImage: dynamicImage,

    // close the Filepicker.io modal
    closeFilePicker: closeFilePicker,

    // check if filepicker is open
    isFilePickerOpen: isFilePickerOpen,

    // string starts with a substring
    startsWith: startsWith,

    // string ends with a substring
    endsWith: endsWith,

    // username availability check
    usernameAvailabilityCheck: usernameAvailabilityCheck,

    // get parameter by name
    getParameterByName: getParameterByName,

    // replace the new lines with BR tags
    nl2br: nl2br,

    // get a unique random GUID
    guid: guid,

    // Create tokenfield from text input (for tags, etc.)
    tokenfield: tokenfield,

    // Strip all non alpha-numeric characters from filename
    formatFilename: formatFilename,

    // remove filepicker spinner
    removeSpinner: removeSpinner,

    // htmlEntities
    htmlEntities: htmlEntities
};
