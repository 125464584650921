import moment from 'moment';

class SubscriptionService
{
    constructor(
        $http,
        $q,
        $timeout,
        $window,
        $pfUser,
        $mdDialog,
        $pfSession,
        $pfDate,
        $pfCompanyAdmin
    ) {
        this.$http = $http;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.$pfSession = $pfSession;
        this.$pfDate = $pfDate;
        this.$pfCompanyAdmin = $pfCompanyAdmin;
        this.globals = $window.Portfolium;
        this.currentUser = $pfUser.getUser();
        this.getSessionData();
        this.getAsyncData();
    }

    /**
     * @description Sets data for subscription from Session
     */
    getSessionData() {
        // Pre-set state with session data
        this._data = _.assign({}, {
            subscriber: this.$pfSession.data.subscriber,
            companyAdminIds: this.$pfSession.data.company_admin_ids,
            competencyManager: this.$pfSession.data.competency_management,
            scrapedJobAccess: this.$pfSession.data.scraped_jobs,
            postForFree: this.$pfSession.data.post_for_free,
        });
    }

    /**
     * @description Fetchs data for the Networks and trial.usage statistics
     *   and creates promises for them.
     */
    getAsyncData() {
        // Store a promise to be resolved once pmal and statistics
        //  are fetched for the first time
        let deferredPmail = this.$q.defer();
        this._subscribedPmailPromise = deferredPmail.promise;

        // Make network requests to fetch data not available in session
        if (this.isSubscriber()) {
            // Get the subscriber's trial/usage statistics
            this.getSubscriberAndPmailStatistics().then((subscriber) => {
                // Store the stats
                this._data = _.assign({}, this._data, {
                    pmailUsage: subscriber.pmail,
                    trialUntil: subscriber.trial_until,
                    jobPrice: subscriber.job_price,
                    prepop: (subscriber.prepop === '1'),
                    sponsoredNetwork: subscriber.prepop_network,
                    allSchoolsAccess: (subscriber.all_schools === '1')
                });
                // Resolve the trial/usage statistics promise
                deferredPmail.resolve(subscriber);
            });
        }
    }

    /**
     * @description Get a list of the user's subscribed networks
     * Note: Use this getter to get the data async and have a callback
     * @return {Promise}
     */
    get subscribedNetworksPromise() {
        return this._subscribedNetworksPromise;
    }

    /**
     * @description Get a list of the user's trial/usage statistics
     * Note: Use this getter to get the data async and have a callback
     * @return {Promise}
     */
    get subscribedPmailPromise() {
        return this._subscribedPmailPromise;
    }

    /**
     * Get the network that sponsored a trial for the subscriber
     * @return {Undefined|Object}
     */
    get sponsoredNetwork() {
        return this._data.sponsoredNetwork;
    }

    /**
     * Get a status of if the user can post jobs for free
     * @return {Boolean} Can this user post jobs for free
     */
    get postForFree() {
        return this._data.postForFree === true;
    }

    /**
     * Get a status of if the user has access to scraped jobs for their company
     * @return {Boolean} Can this user access to scraped jobs for their company
     */
    get scrapedJobAccess() {
        return this._data.scrapedJobAccess === true;
    }

    /**
     * Get subscribers trial end date
     * @return {Null|Integer}
     */
    get trialUntil() {
        // get the trial time (MySQL time stamp)
        let trial = this._data.trialUntil;

        if (!trial) {
            // short-circut the trialUntil value
            return null;
        }

        // Parse unix timestamp string to get time in milliseconds since 1/1/1970
        return this.$pfDate.utcToLocal(trial).valueOf();
    }

    /**
     * Set a new value for trial until date
     * @param  {String} trialDate Unix timestamp for new trial expiration date
     */
    set trialUntil(trialDate) {
        this._data = _.assign({}, this._data, {
            trialUntil: trialDate,
        });
    }

    /**
     * Is the current user a customer with a subscription?
     * @return {Boolean}
     */
    isSubscriber() {
        return this._data.subscriber === true;
    }

    /**
     * Is the current subscriber pre-populated?
     * @return {Boolean}
     */
    isPrepop() {
        return this._data.prepop === true;
    }

    /**
     * Return trial status
     * @return {Boolean}
     */
    isTrial() {
        return !!this.trialUntil;
    }

    /**
     * Has the subscriber onboarded (aka opted in by setting a password)?
     * @return {Boolean}
     */
    isOnboarded() {
        if (!this.isSubscriber()) {
            return false;
        }

        if (this.currentUser.isPrepop() && this.currentUser.isTempPassword()) {
            // Subscriber hasn't onboarded if prepop and temp password flags are true
            return false;
        }

        return true;
    }

    /**
     * Does the subscriber have permission to create custom competencies?
     * @return {Boolean}
     */
    isCompetencyManager() {
        return this._data.competencyManager === true;
    }

    /**
     * Calculate days until end of trial
     * @return {Integer}
     */
    getTrialDaysRemaining() {
        let now = moment();
        // this.trialUntil is already converted to a local timestamp
        let trialUntil = moment(this.trialUntil);

        if (!trialUntil) {
            return null;
        }

        let daysRemaining = trialUntil.diff(now, 'hours') / 24;

        // return days remaining
        return _.floor(daysRemaining);
    }

    /**
     * Get the current user's company ID
     * @return {String} Company ID
     */
    getCompanyId() {
        if (!this.currentUser || !this.currentUser.isCompanyAdmin()) {
            return false;
        }

        // Grab the first company, multiple companies are not yet supported
        return this.$pfCompanyAdmin.getAuthorizedCompanyIds()[0];
    }

    /**
     * Get a subscriber's company by ID (with additional subscriber copmany data)
     * @return {Promise} Promise resolved with company object
     */
    getCompanyById(companyId) {
        return this.$http({
            method: 'GET',
            url: `/proxy/subscribers/company/${companyId}`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get a list of jobs that the current user created by company ID
     * @param  {String}  companyId Company ID
     * @param  {Object}  params    Query parameters
     * @return {Promise}
     */
    getMyJobsByCompanyId(companyId, params) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/me/${companyId}`,
            params: params,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get a status of if the user has access to all network
     * @return {Boolean}
     */
    get allNetworksAccess() {
        return this._data.allSchoolsAccess === true;
    }

    /**
     * Get subscriber information and PMail usage statistiscs for the current user
     * @return {Promise} Promise resolved with usage statistiscs metadata object
     */
    getSubscriberAndPmailStatistics() {
        return this.$http({
            method: 'GET',
            url: '/proxy/subscribers/me',
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Query the current user's subcribed networks
     * @return {Promise} Promise resolved with networks collection
     */
    querySubscribedNetworks(params = {}) {
        // Only makes sense to call the API if the user is a subscriber
        if (!this.isSubscriber()) {
            return this.$q.resolve([]);
        }
        // If the subscription allows all schools, there is
        // no point to ask for the list of the network schools
        if (this.allNetworksAccess) {
            return this.$q.resolve([]);
        }

        return this.$http({
            method: 'GET',
            url: '/proxy/subscribers/networks',
            params: {
                q: params.q,
            },
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * @description Send a request to knock knock message
     * @param   {String} userId User ID
     * @returns {Promise}
     */
    sendKnockKnock(userId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/pmail/knock_knock`,
            data: {
                to_user_ids: userId
            }
        });
    }
}

SubscriptionService.$inject = [
    '$http',
    '$q',
    '$timeout',
    '$window',
    '$pfUser',
    '$mdDialog',
    '$pfSession',
    '$pfDate',
    '$pfCompanyAdmin',
];

export default SubscriptionService;
