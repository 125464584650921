import {combineReducers} from 'redux';

import {getPaginationReducer} from '../../components/pagination/pagination.reducer';

import {
    UPDATE_DRAFT_COUNT,
    DECREMENT_DRAFT_COUNT,
    profileDraftsPaginationActions
} from './profile-drafts.component.actions';

let initialState = {
    draftCount: 0,
}

const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case UPDATE_DRAFT_COUNT:
            return _.assign({}, state, {
                draftCount: action.payload.draftCount
            });
        case DECREMENT_DRAFT_COUNT:
            if (state.draftCount === 0) {
                return state;
            }
            return _.assign({}, state, {
                draftCount: state.draftCount - 1,
            });
        default:
            return state;
    }
};

export const profileDrafts = combineReducers({
    meta,
    pagination: getPaginationReducer({
        actionTypes: profileDraftsPaginationActions,
        paginationStyle: 'infinite',
        limit: 12,
    }),
});
