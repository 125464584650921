import {CONNECTION_STATUS} from '../../../user-connect/js/model/connection-status';
import {NOTIFICATION_GROUP} from '../model/notification-type';

class NotificationListController
{
    constructor ($pfSubscription) {
        this._$pfSubscription = $pfSubscription;
    }

    /**
     * Check notification group and object presence to show user connect button
     * @param  {Object} notification Notification data
     * @return {Boolean}              Should the user connect button be shown
     */
    showConnectButton(notification) {
        // if user object exists, not connected, and not subscriber
        if (notification.group === NOTIFICATION_GROUP.connect) {
            if (this._$pfSubscription.isSubscriber() || notification.status === CONNECTION_STATUS.accepted) {
                return false;
            }

            if (notification.notifier_profile !== null && notification.notifier_profile.connected !== CONNECTION_STATUS.accepted) {
                return true;
            }
        }

        // if user object exists
        if (notification.group === NOTIFICATION_GROUP.confirm && notification.object !== null) {
            return true;
        }

        return false;
    }
}

NotificationListController.$inject = ['$pfSubscription'];

export const NotificationListComponent = {
    bindings: {
        'notification': '<pfNotification',
    },
    controller: NotificationListController,
    template: `
        <span class="pf-notification-center-template-holder"
             ng-include="'template/components/notification-center/template/notification-types/' + $ctrl.notification.dynamic_type + '.html'"
             layout="row"
             flex>
        </span>
    `
};
