import {CanvasAssignmentType} from './canvas-assignment/canvas-assignment-type';

/**
 * @implements ImportSource
 * @type CanvasSource
 */
export const CanvasSource = {
    name: 'canvas',
    displayName: 'Canvas',
    logoUrl: 'images/auth/logo_auth-provider_canvas.svg',
    isConnected: false,
    supportedTypes: [
        CanvasAssignmentType,
    ],
};
