/**
 * @ngdoc controller
 * @name ProfileStrengthCompTipsController
 * @module portfolium.component.profileStrengthComp
 */
class ProfileStrengthCompTipsController {
    constructor(
        $scope,
        $pfGameEngine,
        $pfGameEngineAction,
        $pfEventTracker,
        $pfDashboardOverview,
    ) {
        this.$pfGameEngineAction = $pfGameEngineAction;
        this.$pfEventTracker = $pfEventTracker;
        this.$pfDashboardOverview = $pfDashboardOverview;
        this.hasMoreSuggestions = false;
        this.suggestionsExpanded = false;
        this.suggestions = [];

        // Watch game engine results object for changes, then update actions list
        $scope.$watch(
            () => {
                return $pfGameEngine.results;
            },
            newVal => {
                this.gameEngine = newVal;
                this.actions = this.getActions();
            },
        );
    }

    $onInit() {
        // set default limit
        this.limit = this.limit || 5;
    }

    /**
     * @description Build the list of actions based on game engine recommendations
     * @return {Array} Collection of action objects
     */
    getActions() {
        let { limit } = this;

        if (!this.gameEngine || !this.gameEngine.current_level) {
            return;
        }

        let requiredActions = [];
        let bonusActions = [];
        const nextLevelName = this.gameEngine.next_level
            ? this.gameEngine.next_level.name
            : null;
        const suggestions = nextLevelName
            ? this.gameEngine.suggestions[nextLevelName]
            : [];

        // Fetch required actions for current level
        _.forEach(suggestions, suggestion => {
            const action = this.$pfGameEngineAction.getActionByName(
                suggestion.name,
            );

            if (!action) {
                return;
            }

            // set complete value
            action.complete = suggestion.complete;
            action.type = suggestion.type;

            requiredActions.push(action);
        });

        // Fetch bonus actions
        _.forEach(this.gameEngine.suggestions.bonus, suggestion => {
            const action = this.$pfGameEngineAction.getActionByName(
                suggestion.name,
            );
            // exit fast if we don't support this action
            if (!action) {
                return;
            }

            // find duplicates in required suggestions
            const dupeAction = _.find(requiredActions, {
                name: suggestion.name,
            });

            // find duplicates in bonus suggestions
            const dupeBonus = _.find(this.gameEngine.suggestions.bonus, b => {
                return b.name === suggestion.name && b.type !== suggestion.type;
            });

            if (dupeBonus) {
                // don't show Has_one bonus if there is a Has_many bonus with the same name
                // because the Has_many will always take precedence
                if (suggestion.type !== 'Has_many') {
                    return;
                }
            }

            if (dupeAction) {
                // don't show bonus if we have a required suggestion with the same complete status
                // for example, we don't want to show an incomplete "add skills" bonus if there is an incomplete "add skills" requirement
                if (suggestion.complete === dupeAction.complete) {
                    return;
                }
            }

            // set complete value
            action.complete = suggestion.complete;
            action.type = suggestion.type;
            action.weight = suggestion.weight;

            bonusActions.push(action);
        });

        // Order bonus actions by weight desc
        bonusActions = _.orderBy(bonusActions, ['weight'], ['desc']);

        // save all suggestions
        this.suggestions = _.concat([], requiredActions, bonusActions);
        // this.suggestions = _.unionBy(requiredActions, bonusActions, 'name');

        // if the user expanded suggestions, remember that when we reload the data
        if (this.suggestionsExpanded) {
            limit = this.suggestions.length;
        }

        this.hasMoreSuggestions = this.suggestions.length > limit;

        // get the first subset and return
        return this.suggestions.splice(0, limit);
    }

    /**
     * @description Show more click action
     * @return {Array} The actions
     */
    showMore() {
        this.suggestionsExpanded = true;
        this.hasMoreSuggestions = false;
        this.actions = _.concat(
            [],
            this.actions,
            this.suggestions.splice(0, this.suggestions.length),
        );
        return this.actions;
    }

    /**
     * @description Wrapper method for executing an action
     * @param  {Object}  action Action object
     * @return {Promise}        Promise resolved when action's execute method is completed
     */
    executeAction(action) {
        // Track event
        this.$pfEventTracker.trackServer('Profile Progress Link Clicked', {
            clickSource: 'activityProfileStrength',
            linkType: action.name,
            userProfileStrength: this.gameEngine.current_level.name,
        });

        // execute the action then reload user about data
        return action.execute();
    }
}

ProfileStrengthCompTipsController.$inject = [
    '$scope',
    '$pfGameEngine',
    '$pfGameEngineAction',
    '$pfEventTracker',
    '$pfDashboardOverview',
];

/**
 * @ngdoc component
 * @name pfProfileStrengthCompTips
 * @module portfolium.component.profileStrengthComp
 * @description
 * A composition of complete and incomplete profile strength tips that uses the game engine service
 */
export const ProfileStrengthCompTipsComponent = {
    bindings: {
        limit: '@pfTipLimit',
    },
    controller: ProfileStrengthCompTipsController,
    controllerAs: 'tipsCtrl',
    template: `
        <div ng-if="::tipsCtrl.gameEngine && tipsCtrl.actions.length">
            <p class="pf-profile-strength-comp-tips__desc">
                {{ "Complete the steps below to help customize your Portfolium experience:" | i18n }}
            </p>
            <ul class="pf-profile-strength-comp-tips">
                <li
                     layout="row" layout-align="start center"
                     class="pf-profile-strength-comp-tip"
                     ng-repeat="action in tipsCtrl.actions track by $index">
                    <div ng-switch="action.complete">
                        <div ng-switch-when="false">
                            <a href
                                 class="ellipsis-variable-width pf-profile-tip-{{::action.name}}"
                                 ng-click="tipsCtrl.executeAction(action)"
                                 aria-label="{{action.label}}. Step Incomplete">
                                 <span>{{action.label | i18n}}</span>
                            </a>
                        </div>
                        <div ng-switch-when="true"
                             class="ellipsis-variable-width">
                            <div aria-label="{{action.label}}. Step Complete">
                                <span class="pf-muted" aria-hidden="true">{{action.label | i18n}}</span>
                            </div>
                        </div>
                    </div>
                    <div flex></div>
                    <div
                         alt="{{(action.complete) ? ('Completed' | i18n) : ('Incomplete' | i18n)}}"
                         layout="row" layout-align="center center"
                         class="pf-profile-strength-comp-tip__check-outline"
                         ng-class="{'pf-profile-strength-comp-tip__check-outline--complete': action.complete}">
                        <i class="material-icons" aria-hidden="true">check</i>
                    </div>
                </li>
            </ul>
            <md-button md-no-ink
                 class="md-primary pf-outline pf-profile-strength-show-more-btn"
                 ng-show="tipsCtrl.hasMoreSuggestions"
                 ng-click="tipsCtrl.showMore()">
                {{ "See {length} More" | i18n:{length:tipsCtrl.suggestions.length} }}
            </md-button>
        </div>
    `,
};
