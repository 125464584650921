export class SoundcloudService {
    constructor($q, $injector, $pfUrlUtils) {
        this.$q = $q;
        this.$injector = $injector;
        this.$pfUrlUtils = $pfUrlUtils;

        this.provider = 'soundcloud';
        // these params will get added to the embed URL
        this.urlParams = {
            auto_play: false,
            hide_related: true,
            show_comments: false,
            show_user: true,
            show_reposts: false,
            visual: true
        };
    }

    /**
     * Get the URL used to embed the media
     * @param [String]   url        The media URL
     * @return [String]             The url used for embedded media
     */
    getEmbedUrl(url) {
        // soundcloud wants the song url passed as a parameter
        const params = _.assign({
            url: url
        }, this.urlParams);
        if (url.indexOf('/player/?url=') === -1) {
            url = this.$pfUrlUtils.addParamsToUrl('https://w.soundcloud.com/player/', params);
        }
        return this.$pfUrlUtils.enforceHttps(url);
    }
}

SoundcloudService.$inject = [
    '$q',
    '$injector',
    '$pfUrlUtils',
];
