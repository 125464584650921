function helpChatLauncherDirective($window, $document, $pfCookies, $pfUser) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            const authedUser = $pfUser.getUser();
            const areAnalyticalCookiesEnabled = $pfCookies.isAnalyticalCookiesEnable();
            // sanity check for analytical cookies enable
            if (areAnalyticalCookiesEnabled) {
                elem.on('click', (event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (authedUser) {
                        // sanity check for the SalesForce embedded_svc
                        if (!$window.embedded_svc) {
                            // bounce out yo!
                            return;
                        }
                        // open the SalesForce widget
                        $document.find('.embeddedServiceHelpButton button').trigger('click');
                    }
                });
            }
        }
    };
}

helpChatLauncherDirective.$inject = ['$window', '$document', '$pfCookies', '$pfUser'];

export default helpChatLauncherDirective;
