class AddCoverPhotoAction
{
    constructor($pfGameAction) {
        this.$pfGameAction = $pfGameAction;
        this.name = 'add_your_cover';
        this.label = 'Add a cover photo';
    }

    execute() {
        return this.$pfGameAction.redirect('/settings');
    }
}

AddCoverPhotoAction.$inject = ['$pfGameAction'];

export default AddCoverPhotoAction;
