import angular from 'angular';
import { MODULE_NAME } from 'components/pf-utils/index.js';
const $pfEnv = angular.injector(['ng', MODULE_NAME]).get('$pfEnv');

/**
 * @ngdoc constant
 * @name siteWideSearchMenuConstant
 * @module portfolium.component.pfSitewideSearch
 * @description constant for pfSitewideSearchMenu
 * @type {Object}
 */
const menuItems = {
    entries: {
        title: 'Projects containing',
        icon: 'library_books',
        state: 'searchEntries',
        name: 'Projects',
        route: 'work-samples',
    },
    users: {
        title: 'People named',
        icon: 'people',
        state: 'searchPeople',
        name: 'People',
        route: 'people',
    },
    networks: {
        title: 'Networks named',
        icon: 'account_balance',
        state: 'searchNetworks',
        name: 'Networks',
        route: 'networks',
    },
    jobs: {
        title: 'Jobs containing',
        icon: 'business_center',
        state: 'searchJobs',
        name: 'Jobs',
        route: 'jobs',
    },
    companies: {
        title: 'Companies called',
        icon: 'domain',
        state: 'searchCompanies',
        name: 'Companies',
        route: 'companies',
    },
};

// Remove the jobs search result menu if the jobs are disabled
if (!$pfEnv.jobsEnabled()) {
    delete menuItems.jobs
    delete menuItems.companies
}

if ($pfEnv.inWalledGarden()) {
    delete menuItems.networks
}

export const SITE_WIDE_SEARCH_MENU = Object.values(menuItems)

export const SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX = 'pf-sitewide-search-selected-item-'

export const SITE_WIDE_SEARCH_MENU_ID = 'pf-sitewide-search-menu'
