import EntryCardBlankTemplate from './entry-card-blank.component.html';
import './entry-card-blank.component.scss';

/**
 * @ngdoc component
 * @name pfEntryCardBlank
 * @module portfolium.component.entryCardBlank
 */
export const EntryCardBlankComponent = {
    template: EntryCardBlankTemplate,
};
