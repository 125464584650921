import angular from 'angular';

/**
* @ngdoc component
* @name pfFilestackViewerMobile
* @module portfolium.component.filestackViewer
* @description Opens the fullscreen media slider
*/
export const FilestackViewerMobileComponent = {
    bindings: {
        'handle': '<pfHandle',
        'policy': '<pfPolicy',
        'signature': '<pfSignature',
        'mdPanelRef': '<mdPanelRef',
    },
    controller: angular.noop,
    controllerAs: '$ctrl',
    bindToController: true,
    template: `
        <div class="pf-filestack-viewer-mobile">
            <md-button
                 md-no-ink
                 class="pf-filestack-viewer-mobile__close md-icon-button md-fab md-primary"
                 aria-label="Exit file viewer"
                 ng-click="$ctrl.mdPanelRef.close()">
                <md-icon class="material-icons">close</md-icon>
            </md-button>
            <div class="pf-filestack-viewer-mobile__content">
                <pf-filestack-viewer
                    file-handle="{{::$ctrl.handle}}"
                    policy="{{::$ctrl.policy}}"
                    signature="{{::$ctrl.signature}}"
                    height="100%"
                    width="100%">
                </pf-filestack-viewer>
            </div>
        </div>
    `,
};
