import LightFooterTemplate from './light-footer.html';

/**
 * @ngdoc component
 * @name pfLightFooter
 * @module portfolium.component.footer
 * @description
 * Creates a light footer
 */

export const LightFooterComponent = {
    template: LightFooterTemplate,
    controllerAs: 'footerCtrl',
    bindToController: true
};
