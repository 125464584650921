export const SETTINGS_ACCOUNT_DISABLE_REQUEST         = 'SETTINGS_ACCOUNT_DISABLE_REQUEST';
export const SETTINGS_ACCOUNT_DISABLE_REQUEST_SUCCESS = 'SETTINGS_ACCOUNT_DISABLE_REQUEST_SUCCESS';
export const SETTINGS_ACCOUNT_DISABLE_REQUEST_ERROR   = 'SETTINGS_ACCOUNT_DISABLE_REQUEST_ERROR';
export const SETTINGS_ACCOUNT_DISABLE_RESET_ERROR     = 'SETTINGS_ACCOUNT_DISABLE_RESET_ERROR';


export const settingsAccountDisableRequest = () => {
    return {
        type: SETTINGS_ACCOUNT_DISABLE_REQUEST,
    };
};

export const settingsAccountDisableRequestSuccess = () => {
    return {
        type: SETTINGS_ACCOUNT_DISABLE_REQUEST_SUCCESS,
    };
};

export const settingsAccountDisableRequestError = error => {
    return {
        type: SETTINGS_ACCOUNT_DISABLE_REQUEST_ERROR,
        payload: {
            error
        }
    };
};

export const settingsAccountDisableResetError = () => {
    return {
        type: SETTINGS_ACCOUNT_DISABLE_RESET_ERROR,
    };
};
