class ButtonLoadingController
{
    constructor($scope, $element, $timeout) {
        this.$scope = $scope;
        this.$element = $element;
        this.$timeout = $timeout;
        this.showTimer = null;
        this.visible = false;

        // Setup scope watch to show/hide spinner
        $scope.$watch('loadingCtrl.loading', (newVal) => {
            if (newVal === true) {
                return this.show();
            }

            return this.hide();
        });
    }

    /**
     * Show the loading spinner (with a delay for fast requests)
     */
    show() {
        let parent = this.$element.parent();

        // Set parent element to disabled
        parent.attr('disabled', true);
        // Add a class to signify the button is loading
        parent.addClass('pf-btn-loading');

        if (this.showTimer) {
            return;
        }

        // Delay the showing of the loadiner spinner with a timeout
        this.showTimer = this.$timeout(() => {
            // Add a class to signify the loading spinner is visible
            parent.addClass('pf-btn-loading-visible');
            // Show loading spinner
            this.visible = true;
        }, this.getDelay());
    }

    /**
     * Hide the loading spinner (cancel the delay if set)
     */
    hide() {
        let parent = this.$element.parent();

        // Set parent element to disabled
        if (parent.hasClass('pf-btn-loading')) {
            parent.attr('disabled', false);
        }
        // Remove loading and spinner classes
        parent.removeClass('pf-btn-loading pf-btn-loading-visible');

        if (this.showTimer) {
            // Cancel timeout if in progress
            this.$timeout.cancel(this.showTimer);
        }

        // Hide loading spinner and reset timeout
        this.showTimer = null;
        this.visible = false;
    }

    /**
     * Check for a delay config, otherwise return default
     */
    getDelay() {
        return (this.delay) ? parseInt(this.delay, 10) : 400;
    }
}

ButtonLoadingController.$inject = ['$scope', '$element', '$timeout'];

function buttonLoadingDirective() {
    return {
        restrict: 'E',
        scope: {
            'loading': '=pfLoading',
            'diameter': '@pfDiameter',
            'delay': '@pfDelay'
        },
        transclude: true,
        controller: ButtonLoadingController,
        controllerAs: 'loadingCtrl',
        bindToController: true,
        template: `
            <md-progress-circular
                 ng-if="loadingCtrl.visible"
                 md-mode="indeterminate"
                 md-diameter="24">
            </md-progress-circular>
            <div ng-transclude ng-class="{'pf-hidden': loadingCtrl.visible}"></div>
        `
    };
}

export default buttonLoadingDirective;
