import BalanceMeterBarTemplate from './balance-meter-bar.component.html';

/**
 * @ngdoc component
 * @name pfBalanceMeterBar
 * @module portfolium.component.pfBalanceMeterBar
 * @description controller for pfBalanceMeterBar
 * @param {Int} pf-total-value Total Value
 * @param {Int} pf-current-value Current Value left
 * @param {Int} [pf-danger-percent] Percentage where the bar is in
 *  danger, default to 5
 * @param {Int} [pf-warning-percent] Percentage where the bar is in
 * warning, default to 30
 */
class BalanceMeterBarController {
    /**
    * @ngdoc method
    * @name BalanceMeterBarController#$constructor
    * @methodOf BalanceMeterBarController
    * @description sets default value for optional params to 5% and 30%
    */
    constructor(){
        this.dangerPercentage = _.parseInt(this.pfDangerPercent) || 5;
        this.warningPercentage = _.parseInt(this.pfWarningPercent) || 30;
    }

    /**
    * @ngdoc method
    * @name BalanceMeterBarController#$currentPercent
    * @methodOf BalanceMeterBarController
    * @description calculates the Percent of the pmails remaining
    * @param {Int}
    */
    get currentPercent() {
        return _.round((_.parseInt(this.pfCurrentValue * 100)) / _.parseInt(this.pfTotalValue));
    }

    /**
    * @ngdoc method
    * @name BalanceMeterBarController#$barStatus
    * @methodOf BalanceMeterBarController
    * @description will tell if it is under, danger, warning or
    *   it is good ( red, orange or green)
    * @param {Object}
    */
    get barStatus() {
        let danger = this.currentPercent <= this.dangerPercentage;
        let warning = !danger && this.currentPercent <= this.warningPercentage;
        let good = !danger && !warning;

        return {
            danger,
            warning,
            good
        }
    }
}

export const BalanceMeterBarComponent = {
    bindings: {
        pfTotalValue: '<',
        pfCurrentValue: '<',
        pfDangerPercent: '@',
        pfWarningPercent: '@'
    },
    template: BalanceMeterBarTemplate,
    controller: BalanceMeterBarController,
    controllerAs: '$ctrl'
};
