import angular from 'angular';

import { ProgressBarComponent } from 'components/progress-bar/progress-bar.component';

const MODULE_NAME = 'portfolium.component.progressBar';

(function () {
    angular.module(MODULE_NAME, [])
        .component('pfProgressBar', ProgressBarComponent);
})();

export default MODULE_NAME;
