import angular from 'angular';
import ngRedux from 'ng-redux';

import { registerPanels } from './js/config/register-panels.config';
import { templateCache } from './js/config/template-cache.config';

import { ProfileAboutPanelEntryComponent } from './js/component/profile-about-panel-entry.component';
import { ProfileAboutPanelEntryOrderComponent } from './js/component/profile-about-panel-entry-order.component';
import { ProfileAboutPanelComponent } from './js/component/profile-about-panel.component';
import { ProfileAboutPanelFormComponent } from './js/component/profile-about-panel-form.component';
import { ProfileAboutPanelWorkSamplesComponent } from './js/component/profile-about-panel-work-samples.component';
import { ProfileAboutPanelProvider } from './js/service/profile-about-panel.provider';
import datePickerDirective from './js/directive/date-picker-directive';

import './profile-about-panel.scss';

const MODULE_NAME = 'portfolium.component.profileAboutPanel';

(function() {
    angular
        .module(MODULE_NAME, [ngRedux])
        // Components
        .component('pfProfileAboutPanelEntry', ProfileAboutPanelEntryComponent)
        .component(
            'pfProfileAboutPanelEntryOrder',
            ProfileAboutPanelEntryOrderComponent,
        )
        .component('pfProfileAboutPanel', ProfileAboutPanelComponent)
        .component('pfProfileAboutPanelForm', ProfileAboutPanelFormComponent)
        .component(
            'pfProfileAboutPanelWorkSamples',
            ProfileAboutPanelWorkSamplesComponent,
        )
        // Directives
        .directive('pfDatePicker', datePickerDirective)
        // Services
        .provider('$pfProfileAboutPanel', ProfileAboutPanelProvider)
        // Config
        .config(registerPanels)
        // Runtime
        .run(templateCache);
})();

export default MODULE_NAME;
