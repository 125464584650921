export const SETTINGS_CLEARANCES_REQUEST   = 'SETTINGS_CLEARANCES_REQUEST';
export const SETTINGS_UPDATE_CLEARANCES    = 'SETTINGS_UPDATE_CLEARANCES';
export const SETTINGS_UPDATE_CLEARANCE     = 'SETTINGS_UPDATE_CLEARANCE';

/**
 * Request Clearances
 */
export const settingsClearancesRequest = () => {
    return {
        type: SETTINGS_CLEARANCES_REQUEST,
    };
};

/**
 * Update all the clearances
 */
export const settingsUpdateClearances = (clearances) => {
    return {
        type: SETTINGS_UPDATE_CLEARANCES,
        payload: {
            clearances: clearances,
        },
    };
};

/**
 * Update
 * @param {Int} clearanceId Id of the clearance
 * @param {Boolean} isGranted Whether the clearance is or not granted
 */
export const settingsUpdateClearance = (clearanceId, isGranted) => {
    return {
        type: SETTINGS_UPDATE_CLEARANCE,
        payload: {
            clearanceId: clearanceId,
            isGranted: isGranted,
        },
    };
};
