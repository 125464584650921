import angular from 'angular';
import BaseModalController from 'components/modal/js/controller/base-modal-controller';

/**
 * @ngdoc controller
 * @name PaginationSortBtnController
 * @module portfolium.components.paginationFilter.paginationSort
 */
class PaginationSortBtnController {
    constructor($mdMedia, $mdDialog) {
        this.$mdMedia = $mdMedia;
        this.$mdDialog = $mdDialog;
    }

    isMobile() {
        return !this.$mdMedia('gt-sm');
    }

    /**
     * Open the modal
     * @param  {Event}  $event  Event
     * @return {Promise}
     */
    openModal($event) {
        return this.$mdDialog.show({
            locals: {
                currentSort: this.currentSort,
                sortOptions: this.sortOptions,
                handleOnApply: this.onApply,
                handleOnCancel: this.onCancel,
            },
            template: `
                <md-dialog
                     class="pf-dialog pf-dialog--white pf-dialog--v2 pf-pagination-filter-modal"
                     aria-label="Pagination Sort">
                    <pf-pagination-sort
                         current-sort="$ctrl.currentSort"
                         sort-options="::$ctrl.sortOptions"
                         on-apply="::$ctrl.handleOnApply({ value })"
                         on-cancel="::$ctrl.handleOnCancel">
                    </pf-pagination-sort>
                </md-dialog>
            `,
            parent: angular.element(document.body),
            multiple: true,
            focusOnOpen: false,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            controller: BaseModalController,
            bindToController: true,
            controllerAs: '$ctrl',
            onComplete: () => {
                // since we have no button to focus on, set focusOnOpen to false and manually do it to the window
                // see: https://github.com/angular/material/issues/1190
                angular.element('md-dialog').focus();
            },
        });
    }
}

PaginationSortBtnController.$inject = [
    '$mdMedia',
    '$mdDialog',
];

export const PaginationSortBtnComponent = {
    controller: PaginationSortBtnController,
    bindings: {
        currentSort: '<',
        sortOptions: '<',
        onCancel: '&',
        onApply: '&',
    },
    template: `
        <md-button
             ng-if="$ctrl.isMobile()"
             ng-click="$ctrl.openModal($event)"
             class="md-button pf-outline"
             md-no-ink>
            {{ "Sorting" | i18n }}
        </md-button>
        <div ng-if="!$ctrl.isMobile()"
             layout-align="center center"
             layout="row">
            <pf-field-label
                 pf-for="pf-pagination-sort-btn">
                {{ "Sort:" | i18n }}
            </pf-field-label>
            <md-select
                 id="pf-pagination-sort-btn"
                 class="pf-form-control pf-form-control--borderless"
                 placeholder='{{ "Choose a value..." | i18n }}'
                 ng-model="$ctrl.currentSort"
                 ng-change="$ctrl.onApply({ value: $ctrl.currentSort })"
                 aria-label="Sorting filter">
                <md-option
                     ng-repeat="option in ::$ctrl.sortOptions track by option.value"
                     ng-value="::option.value">
                    {{ ::option.title |i18n }}
                </md-option>
            </md-select>
        </div>
    `,
};
