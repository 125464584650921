export const SOCIAL_AUTH_SET_TWITTER_USER_DATA = 'SOCIAL_AUTH_SET_TWITTER_USER_DATA';
export const SOCIAL_AUTH_RESET_USER_DATA = 'SOCIAL_AUTH_RESET_USER_DATA';

/**
 * Set the userdata for twitter
 * @param {object} userData  The user data object
 */
export const setTwitterUserData = (userData = null) => ({
    type: SOCIAL_AUTH_SET_TWITTER_USER_DATA,
    payload: {
        userData,
    },
});

/**
 * Reset social sharing flags
 */
export const resetSocialSharing = () => ({
    type: SOCIAL_AUTH_RESET_USER_DATA,
});
