export class ProfileBucketService {
    constructor($http) {
        this._$http = $http;
    }

    /**
     * Add an entry to a bucket for the current user
     * @param  {String}        bucketId Bucket to add the entry to
     * @param  {String}        entryId  Entry to add
     * @return {Promise<void>}
     */
    addEntryToBucket(bucketId, entryId) {
        return this._$http({
            method: 'POST',
            url: `/proxy/buckets/map/${bucketId}/${entryId}`,
        })
        .then(response => response.data);
    }

    /**
     * Remove an entry from a bucket for the current user
     * @param  {String}        bucketId Bucket to remove the entry from
     * @param  {String}        entryId  Entry to remove
     * @return {Promise<void>}
     */
    removeEntryFromBucket(bucketId, entryId) {
        return this._$http({
            method: 'DELETE',
            url: `/proxy/buckets/map/${bucketId}/${entryId}`,
        })
        .then(response => response.data);
    }

    /**
     * Get a list of the current user's entries by bucket ID
     * @param  {String}         bucketId Bucket ID
     * @return {Promise<Array>}          Resolved with entries collection
     */
    getEntriesByBucketId(bucketId) {
        return this._$http({
            method: 'GET',
            url: '/proxy/entries/bucket',
            params: {
                bucket_id: bucketId,
            },
        })
        .then(response => response.data);
    }

    /**
     * Update an entry's order within a bucket
     * @param  {String}          bucketId Bucket ID
     * @param  {String}          entryId  Entry to order
     * @param  {String}          order    New order value
     * @return {Promise<Object>}
     */
    orderEntryInBucket(bucketId, entryId, order) {
        return this._$http({
            method: 'POST',
            url: `/proxy/buckets/order/${bucketId}/${entryId}`,
            data: {
                order,
            },
        })
        .then(response => response.data);
    }
}

ProfileBucketService.$inject = ['$http'];
