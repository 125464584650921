class MainNavController {
    constructor($pfMainNav) {
        this.$pfMainNav = $pfMainNav;
    }

    /**
     * Open the main navigation menu
     * @return {Promise}
     */
    open() {
        return this.$pfMainNav.open();
    }

    /**
     * Close the main navigation menu
     * @return {Promise}
     */
    close() {
        return this.$pfMainNav.close();
    }
}

MainNavController.$inject = ['$pfMainNav'];

export default MainNavController;
