 /**
  * @ngdoc service
  * @name EntrySettingsService
  * @module portfolium.core
  * @description Provides direct access to update individual entry settings
  * @class EntrySettingsService
  */
export class EntrySettingsService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * Updates entry visibility to the passed in visibility type
     * @param  {Number}  entryId ID of the entry to update
     * @param  {String}  visibility Visibility to set on the entry
     * @return {Promise}
     */
    updateVisibility(entryId, visibility) {
        return this.$http({
            method: 'POST',
            url: `/proxy/entries/visibility/${entryId}`,
            data: { visibility }
        })
        .then((response) => {
            return response.data;
        });
    }
}

EntrySettingsService.$inject = ['$http'];
