function fullNameFilter() {
    /**
     * Combine a user's first and last name into a single value
     * @param  {Object} profile User profile
     * @return {String}         User's full name
     */
    return function(profile = {}) {
        if (_.isEmpty(profile)) {
            return;
        }
        const firstName = profile.firstname || '';
        const lastName = profile.lastname || '';
        const fullName = _.trim(`${firstName} ${lastName}`);

        return _.isEmpty(fullName) ? '(Unnamed User)' : fullName;
    };
}

function possessiveNameFilter() {
    /**
     * Format a name to show possession.
     * @param  {String} name Name value
     * @return {Undefined|String} Name with possession
     */
    return function(name) {
        if (!name) {
            return;
        }

        let lastChar = name.substr(name.length - 1);

        if (lastChar === 's') {
            // Name ends in the letter s, only append appostrophe
            return name + '\'';
        }

        // Append appostrophe + s
        return name + '\'s';
    };
}

export { fullNameFilter, possessiveNameFilter };
