export const EXPORT_PROFILE_OPEN  = 'EXPORT_PROFILE_OPEN';
export const EXPORT_PROFILE_CLOSE = 'EXPORT_PROFILE_CLOSE';
export const EXPORT_PROFILE_SEARCH_RESULTS_UPDATE = 'EXPORT_PROFILE_SEARCH_RESULTS_UPDATE';
export const EXPORT_PROFILE_SEARCH_RESULTS_RESET = 'EXPORT_PROFILE_SEARCH_RESULTS_RESET';

/**
 * Opens panel
 */
export const panelOpen = () => {
    return {
        type: EXPORT_PROFILE_OPEN,
    };
};

/**
 * Closes panel
 */
export const panelClose = () => {
    return {
        type: EXPORT_PROFILE_CLOSE,
    };
};

/**
 * Updates search results
 * @param {Array<Objects>} results Search results returned
 */
export const searchResultsUpdate = (results) => {
    return {
        type: EXPORT_PROFILE_SEARCH_RESULTS_UPDATE,
        payload: {
            results,
        },
    };
};

/**
 * Resets search results list and properties
 */
export const searchResultsReset = () => {
    return {
        type: EXPORT_PROFILE_SEARCH_RESULTS_RESET,
    };
};

export const entryActionTypes = {
    'PAGE_INIT': 'EXPORT_PROFILE_ENTRY_PAGE_INIT',
    'PAGE_NEXT': 'EXPORT_PROFILE_ENTRY_PAGE_NEXT',
    'PAGE_PREVIOUS': 'EXPORT_PROFILE_ENTRY_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'EXPORT_PROFILE_ENTRY_PAGE_REQUEST',
    'PAGE_SUCCESS': 'EXPORT_PROFILE_ENTRY_PAGE_SUCCESS',
    'PAGE_ERROR': 'EXPORT_PROFILE_ENTRY_PAGE_ERROR',
    'ADD_SINGLE_RESULT': 'EXPORT_PROFILE_ENTRY_ADD_SINGLE_RESULT',
    'TOGGLE_SELECTION': 'EXPORT_PROFILE_ENTRY_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'EXPORT_PROFILE_ENTRY_CLEAR_SELECTION',
    'TOGGLE_ALL': 'EXPORT_PROFILE_ENTRY_TOGGLE_ALL',
};
