import { ENTRY_ATTACHMENTS_TYPES } from '../../core/constants/attachment-types.constant';

// TODO: Fix this with prettier changes
// eslint-disable-next-line import/no-cycle
import { setCoverPhoto } from '../entry-editor.actions';

export const ENTRY_ATTACHMENTS_UPDATE_ATTACHMENTS =
    'ENTRY_ATTACHMENTS_UPDATE_ATTACHMENTS';
export const ENTRY_ATTACHMENTS_START_ATTACHMENT =
    'ENTRY_ATTACHMENTS_START_ATTACHMENT';
export const ENTRY_ATTACHMENTS_UPDATE_PROGRESS_ATTACHMENT =
    'ENTRY_ATTACHMENTS_UPDATE_PROGRESS_ATTACHMENT';
export const ENTRY_ATTACHMENTS_UPDATE_ATTACHMENT =
    'ENTRY_ATTACHMENTS_UPDATE_ATTACHMENT';
export const ENTRY_ATTACHMENTS_DELETE_ATTACHMENT =
    'ENTRY_ATTACHMENTS_DELETE_ATTACHMENT';
export const ENTRY_ATTACHMENTS_RESET_ATTACHMENTS =
    'ENTRY_ATTACHMENTS_RESET_ATTACHMENTS';
export const ENTRY_ATTACHMENTS_SWAP_NEXT = 'ENTRY_ATTACHMENTS_SWAP_NEXT';
export const ENTRY_ATTACHMENTS_SWAP_PREVIOUS =
    'ENTRY_ATTACHMENTS_SWAP_PREVIOUS';

/**
 * Update the current list of attachments
 * @param  {Array}  attachments List of attachments
 * @return {Object}
 */
export const updateAttachments = (attachments = []) => {
    return {
        type: ENTRY_ATTACHMENTS_UPDATE_ATTACHMENTS,
        payload: {
            attachments,
        },
    };
};

/**
 * Creates the initial attachment and adds it to the list
 *  on loading state
 * @param {Array}  attachment
 * @param {Number} attachment._id temporary Id
 * @param {String} attachment.title Title of the attachment
 * @return {Object}
 */
export const startAttachment = (attachment = { _id, title }) => {
    return {
        type: ENTRY_ATTACHMENTS_START_ATTACHMENT,
        payload: {
            attachment,
        },
    };
};

/**
 * Finds an attachment and updates the progress of the
 *     upload
 * @param {Object} attachment
 * @param {Number} attachment._id temporary Id
 * @param {String} attachment.percentage percentage of the
 *                                       attachment upload
 * @return {Object}
 */
export const updateProgressAttachment = (attachment = { _id, percentage }) => {
    return {
        type: ENTRY_ATTACHMENTS_UPDATE_PROGRESS_ATTACHMENT,
        payload: {
            attachment,
        },
    };
};

/**
 * Finds an attachment and updates the values of it on
 *     the attachments list
 * @param {Object} attachment
 * @param {Number} attachment.id temporary Id
 * @param {Object} attachment.meta information to update for attachment
 * @return {Object}
 */
export const updateAttachment = (attachment = { id, meta }) => {
    return {
        type: ENTRY_ATTACHMENTS_UPDATE_ATTACHMENT,
        payload: {
            attachment,
        },
    };
};

/**
 * Finds an attachment and removes it from the list
 * @param {Number} _id temporary Id
 * @return {Object}
 */
export const deleteAttachment = _id => {
    return (dispatch, getState) => {
        // get current state of entry editor
        const { entryEditor } = getState();
        // get current cover photo
        const currentCover = entryEditor.currentDraft.cover;
        // get the attachment object by id
        const attachment = _.find(entryEditor.entryEditorAttachments, { _id });

        // delete attachment now that we have the attachment object
        dispatch({
            type: ENTRY_ATTACHMENTS_DELETE_ATTACHMENT,
            payload: {
                _id,
            },
        });

        // get remaining attachments from new state
        const attachments = getState().entryEditor.entryEditorAttachments;

        // set cover to null if no remaining attachments
        if (_.isEmpty(attachments)) {
            return dispatch(setCoverPhoto(null));
        }

        // get deleted attachment preview
        const attachmentPreview = getAttachmentPreview(attachment);

        // if deleted attachment is the current cover photo, find and set new cover photo
        if (isAttachmentPreviewCoverPhoto(currentCover, attachmentPreview)) {
            // defualt new cover
            let newCover = null;

            // find next attachment with a preview
            attachments.every(att => {
                newCover = getAttachmentPreview(att);
                // break out if new cover is found
                return newCover ? false : true;
            });

            // set new cover photo
            dispatch(setCoverPhoto(newCover));
        }
    };
};

/**
 * Reset state of attachments
 * @return {Object}
 */
export const resetEntryEditorAttachments = () => {
    return {
        type: ENTRY_ATTACHMENTS_RESET_ATTACHMENTS,
    };
};

/**
 * Swap attachment to next position
 * @return {Object}
 */
export const swapNext = _id => {
    return {
        type: ENTRY_ATTACHMENTS_SWAP_NEXT,
        payload: {
            _id,
        },
    };
};

/**
 * Swap attachment to previous position
 * @return {Object}
 */
export const swapPrevious = _id => {
    return {
        type: ENTRY_ATTACHMENTS_SWAP_PREVIOUS,
        payload: {
            _id,
        },
    };
};

/**
 * Get preview image object from attachment based on attachment type
 * @param {object} attachment
 * @return {object|null}
 */
function getAttachmentPreview(attachment) {
    switch (attachment.type) {
        case ENTRY_ATTACHMENTS_TYPES.IMAGE.name:
            // should always be null or an image object
            return attachment.meta.image;
        case ENTRY_ATTACHMENTS_TYPES.DOCUMENT.name:
        case ENTRY_ATTACHMENTS_TYPES.WEBSITE.name:
            // should always be null or an image object
            return attachment.meta.preview;
        default:
            return null;
    }
}

/**
 * Determine if image is the current cover photo
 * @param {object} cover
 * @param {object} preview
 * @return {boolean}
 */
function isAttachmentPreviewCoverPhoto(cover, preview) {
    if (!cover || !cover.file) {
        return false;
    }
    if (!preview || !preview.file) {
        return false;
    }

    return cover.file.filestack.key === preview.file.filestack.key;
}
