class FormWizardGroupController
{
    constructor($scope, $q, $document, $timeout) {
        this.$scope = $scope;
        this.$q = $q;
        this.$document = $document;
        this.$timeout = $timeout;
    }

    get isValid() {
        return this.$scope.formCtrl.$valid;
    }

    nextGroup() {
        let def = this.$q.defer();

        if (!this.isValid) {
            // Form is invalid
            def.reject();
            return def.promise;
        }

        if (this.$scope.nextAction) {
            // Async next action was provided, call it and then move to the next group
            this.$scope.nextAction()
                .then(() => {
                    // Next action was successfull, show the next group
                    this.showNextGroup();
                    def.resolve();
                }, () => {
                    // Next action failed, don't show the next group
                    // @todo: show error message to alert the user what went wrong
                    def.reject();
                });
        } else {
            // No async action was provided, show the next group now
            this.showNextGroup();
            def.resolve();
        }

        return def.promise;
    }

    showNextGroup() {
        let nextGroup = this.$scope.formWizardCtrl.nextGroup();

        if (nextGroup) {
            this.$timeout(() => {
                this.scrollTo(nextGroup.elem);
            }, 500);
        }
    }

    scrollTo(elem) {
        this.$document.scrollToElementAnimated(elem, 60, 1000);
    }
}

FormWizardGroupController.$inject = ['$scope', '$q', '$document', '$timeout'];

export default FormWizardGroupController;
