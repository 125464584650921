import ListDraftCardTemplate from './list-draft-card.component.html';
import './list-draft-card.component.scss'

/**
 * @ngdoc controller
 * @name ListDraftCardController
 * @module portfolium.entryCard
 **/
class ListDraftCardController {
    $onInit() {
        // set title
        this.title = this.draft.title || 'Untitled Work';
    }
}

/**
 * @ngdoc component
 * @name ListDraftCardComponent
 * @module portfolium.entryCard
 * @param {object} draft A draft object containing an ID, updated at, and json data
 * @param {callback} onDelete function called on draft delete
 **/
export const ListDraftCardComponent = {
    bindings: {
        'draft': '<',
        'onDelete': '&',
    },
    template: ListDraftCardTemplate,
    controller: ListDraftCardController,
    controllerAs: '$ctrl',
};
