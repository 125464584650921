 /**
  * @ngdoc service
  * @name EntryEditorService
  * @module portfolium.core
  * @description Handle routing to entry wizard for new entries, existing entries, and assignments
  * @class EntryEditorService
  */
export class EntryEditorService {
    constructor($pfAddEntryModal, $pfEventTracker, $q, $state, $http, $window, $pfUser) {
        this.$pfAddEntryModal = $pfAddEntryModal;
        this.$pfEventTracker = $pfEventTracker;
        this.$q = $q;
        this.$state = $state;
        this.$http = $http;
        this.$window = $window;
        this.isMobile = $pfUser.isMobile();
    }

    /**
     * Redirect to the entry wizard to edit an assignment
     * @param {string} entryId Entry ID
     * @param {object} [config] Optional configuration options
     * @param {string} [config.source] Location in the UI triggering the action (for event tracking)
     * @param {object} [config.redirectRoute] Redirect config object
     * @param {string} [config.redirectRoute.stateName] Redirect state name
     * @param {object} [config.redirectRoute.params] Redirect state params
     * @return {Promise}
     */
    editAssignment(entryId, {
        source,
        redirectRoute = {
            stateName: null,
            params: {},
        },
    }) {
        if (!entryId) {
            return this.$q.reject();
        }

        // Track open event
        this.$pfEventTracker.trackServer('Project Builder Opened', {
            source,
            type: 'editAssignment',
        });

        return this.$state.go('entryEditor.edit', {
            entryId,
            source,
            redirectRoute,
        });
    }

    /**
     * Redirect to the entry wizard to edit an existing entry
     * @param {string} entryId Entry ID
     * @param {object} [config] Optional configuration options
     * @param {boolean} [config.returnToPortfolio] Tell the editor to go to the portfolio page when finished
     * @param {string} [config.source] Location in the UI triggering the action (for event tracking)
     * @param {boolean}[config.openSettings=false] Should the settings menu open after loading the entry editor
     * @param {object} [config.redirectRoute] Redirect config object
     * @param {string} [config.redirectRoute.stateName] Redirect state name
     * @param {object} [config.redirectRoute.params] Redirect state params
     * @return {Promise}
     */
    editEntry(entryId, {
        source,
        openSettings = false,
        redirectRoute = {
            stateName: null,
            params: {},
        },
    }) {
        if (!entryId) {
            return this.$q.reject();
        }

        // Track open event
        this.$pfEventTracker.trackServer('Project Builder Opened', {
            source,
            type: 'editEntry',
        });

        return this.$state.go('entryEditor.edit', {
            entryId,
            source,
            openSettings,
            redirectRoute,
        })
        .catch(() => {
            // catch transition superseded error
        });
    }

    /**
     * Redirect to the entry wizard to edit an existing draft
     * @param {String} entryId Entry ID
     * @param {object} [config] Optional configuration options
     * @param {string} [config.source] Location in the UI triggering the action (for event tracking)
     * @param {object} [config.redirectRoute] Redirect config object
     * @param {string} [config.redirectRoute.stateName] Redirect state name
     * @param {object} [config.redirectRoute.params] Redirect state params
     * @return {Promise}
     */
    editDraft(draftId, {
        source,
        redirectRoute = {
            stateName: null,
            params: {},
        },
    }) {
        if (!draftId) {
            return this.$q.reject();
        }

        // Track open event
        this.$pfEventTracker.trackServer('Project Builder Opened', {
            source,
            type: 'editDraft',
        });

        return this.$state.go('entryEditor.draft', {
            draftId,
            source,
            redirectRoute,
        });
    }

    /**
     * Redirect to the entry editor to create a new entry
     * @param  {object}      [config]                       Optional configuration options
     * @param  {string|null} [config.attachments=[]]        List of attachments to pre load in the draft
     * @param  {string|null} [config.bucketId=null]         Bucket to insert the new entry into on publish
     * @param  {string|null} [config.challengeId=null]      Id of the challenge to enter
     * @param  {boolean}     [config.chesterEnable=false]   Whether to open the chester or not before transitioning
     *                                                      to the project editor
     * @param  {object[]}    [config.skills=[]]             List of skills to pre-populate on the new entry
     * @param  {string[]}    [config.tags=[]]               List of tags to pre-populate on the new entry
     * @param  {object}      [config.redirectRoute]         Redirect config object
     * @param  {string}      [config.redirectRoute.stateName] Redirect state name
     * @param  {object}      [config.redirectRoute.params]  Redirect state params
     * @return {Promise}
     */
    newEntry({
        attachments = [],
        bucketId = null,
        challengeId = null,
        chesterEnable = false,
        skills = [],
        source = undefined,
        tags = [],
        targetEvent = null,
        redirectRoute = {
            stateName: null,
            params: {},
        },
    } = {}) {
        // Track open event
        this.$pfEventTracker.trackServer('Project Builder Opened', {
            source,
            type: 'newEntry',
        });

        const redirect = (attachments = []) => {
             if (this.$state.get('entryEditor')) {
                // Already in single page app -- go to entry wizard state
                return this.$q.resolve().then(() => {
                    /**
                     * Resolve promise immediately, then transition. This is because
                     * the initial $state.go transition is blocked by the lazy load
                     * hook, rejecting the promise. In order for the add entry modal
                     * to close properly, this promise must be resolved.
                     *
                     * TODO: Use the $transition$ service in UI-Router 1.0 to handle
                     * the lazy load hook without rejecting the initial $state.go
                     */
                    // WIZARD_TODO: Remove all the chester code and make sure no special functionality is lost (e.g. resume upload warning)

                    // Transition to entry editor with a new blank draft
                    this.$state.go('entryEditor.draft', {
                        bucketId,
                        challengeId,
                        skills,
                        source,
                        tags,
                        attachments,
                        redirectRoute,
                    });
                });
            }

            // On php page -- do page redirect
            return this.$q.resolve().then(() => {
                this.$window.location.assign('/project/draft');
            });
        }

        if (this.isMobile || !chesterEnable ) {
            return redirect();
        }

        return this.$pfAddEntryModal
            .open({targetEvent})
            .then(redirect);
    }

    /**
     * calls the API to delete the entry
     * @param  {Int} entryId id of the entry
     * @return {Promise}
     */
    deleteEntry(entryId) {
        return this.$http({
            method: 'DELETE',
            url: '/proxy/entries/entry/' + entryId
        });
    }
}

EntryEditorService.$inject = [
    '$pfAddEntryModal',
    '$pfEventTracker',
    '$q',
    '$state',
    '$http',
    '$window',
    '$pfUser',
];
