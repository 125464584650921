import EntryCardPreviewTemplate from './entry-card-preview.component.html';
import './entry-card-preview.component.scss';

/**
 * @ngdoc controller
 * @name EntryCardPreviewController
 * @module portfolium.component.entryCardPreview
 */
class EntryCardPreviewController {

    // constructor
    constructor(
        imageFilter,
        $pfEnv,
        $pfFilestackSigner
    ) {
        this.imageFilter = imageFilter;
        this.$pfEnv = $pfEnv;
        this.$pfFilestackSigner = $pfFilestackSigner;
    }

    $onInit() {
        // set the cover image
        this.coverImage = this.getCoverImage();
    }

    /**
     * Return a cover image
     * @return {string}
     */
    getCoverImage() {
        // sanity check
        if (_.has(this.draft.cover, 'file.clean_url') && _.has(this.draft.cover, 'file.security')) {
            // return the filtered secure image
            return this.imageFilter(this.$pfFilestackSigner.signUrl(this.draft.cover.file.clean_url, this.draft.cover.file.security), 219, 390);
        }
        // get the cdn
        const cdnUrl = this.$pfEnv.getEnv('PF_CDN_URL');
        // return the default image
        return this.imageFilter(`${cdnUrl}/img/defaults/default.png`, 219, 390);
    }
}

// inject the nuggets
EntryCardPreviewController.$inject = [
    'imageFilter',
    '$pfEnv',
    '$pfFilestackSigner',
];

/**
 * @ngdoc component
 * @name pfEntryCardPreview
 * @module portfolium.component.entryCardPreview
 * @param {object} draft draft object being previewed
 */
export const EntryCardPreviewComponent = {
    bindings: {
        draft: '<',
    },
    template: EntryCardPreviewTemplate,
    controller: EntryCardPreviewController,
};
