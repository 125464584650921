import angular from 'angular';

import AddResumeAction from './js/action/add-resume-action';
import AddEducationAction from './js/action/add-education-action';
import AddMajorAction from './js/action/add-major-action';
import AddDualMajorAction from './js/action/add-dual-major-action';
import AddMinorAction from './js/action/add-minor-action';
import AddDualMinorAction from './js/action/add-dual-minor-action';
import AddConcentrationAction from './js/action/add-concentration-action';
import AddStartDateAction from './js/action/add-start-date-action';
import AddDegreeAction from './js/action/add-degree-action';
import AddGradYearAction from './js/action/add-grad-year-action';
import AddGpaAction from './js/action/add-gpa-action';
import AddEntriesAction from './js/action/add-entries-action';
import AddSkillsAction from './js/action/add-skills-action';
import AddExperienceAction from './js/action/add-experience-action';
import AddCourseAction from './js/action/add-course-action';
import AddClubAction from './js/action/add-club-action';
import AddAvatarAction from './js/action/add-avatar-action';
import AddCoverPhotoAction from './js/action/add-cover-photo-action';
import AddConnectionsAction from './js/action/add-connections-action';
import AddCompaniesAction from './js/action/add-companies-action';

import profileStrengthDirective from './js/directive/profile-strength-directive';
import profileStrengthTipsDirective from './js/directive/profile-strength-tips-directive';
import GameEngineService from './js/service/game-engine-service';
import GameActionService from './js/service/game-action-service';
import GameEngineActionService from './js/service/game-engine-action-service';

import './index.scss';

(function() {
    let app = angular.module('portfolium.component.gameEngine', []);

    // Directive
    app.directive('pfProfileStrength', profileStrengthDirective);
    app.directive('pfProfileStrengthTips', profileStrengthTipsDirective);

    // Service
    app.service('$pfGameEngine', GameEngineService);
    app.service('$pfGameAction', GameActionService);
    app.provider('$pfGameEngineAction', GameEngineActionService);

    // Register Actions
    app.config([
        '$pfGameEngineActionProvider',
        $pfGameEngineActionProvider => {
            $pfGameEngineActionProvider
                .registerAction(AddResumeAction)
                .registerAction(AddEducationAction)
                .registerAction(AddMajorAction)
                .registerAction(AddDualMajorAction)
                .registerAction(AddMinorAction)
                .registerAction(AddDualMinorAction)
                .registerAction(AddConcentrationAction)
                .registerAction(AddStartDateAction)
                .registerAction(AddDegreeAction)
                .registerAction(AddGradYearAction)
                .registerAction(AddGpaAction)
                .registerAction(AddEntriesAction)
                .registerAction(AddSkillsAction)
                .registerAction(AddExperienceAction)
                .registerAction(AddCourseAction)
                .registerAction(AddClubAction)
                .registerAction(AddAvatarAction)
                .registerAction(AddCoverPhotoAction)
                .registerAction(AddConnectionsAction)
                .registerAction(AddCompaniesAction);
        },
    ]);
})();

export default 'portfolium.component.gameEngine';
