import ProfileRankTemplate from './profile-rank.component.html';

/**
 * @ngdoc controller
 * @name pfProfileRank
 * @module portfolium.component.profile-rank
 * @description
 * Display The rank based on the percentage.
 * @param {String} percent percent to display
 *
 */
class ProfileRankController {

    /**
    * @ngdoc method
    * @name profileRank#constructor
    * @methodOf profileRank
    * @description
    * Set rank and divisions as default
    */
    constructor() {
        this.rank = 0;
    }


    /**
    * @ngdoc method
    * @name profileRank#$onInit
    * @methodOf profileRank
    * @description
    * Set the rank once the component is initialized
    **/
    $onInit() {
        this.rank = this._calculateRank(this.percent);
    }

    /**
    * @ngdoc method
    * @name profileRank#constructor
    * @methodOf profileRank
    * @description
    * Calculate Rank of a user based on its percentage. 0-30 is rank 0, 31 to 50 is rank 1,
    * 51 to 65 is rank 2 and 66 to 100 is rank 3.
    * @param  {Int} percent User percentage.
    * @return {Int} User rank 0 to 3.
    **/
    _calculateRank(percent) {
        if (percent <= 30) {
            return 0;
        }

        if (percent <= 50) {
            return 1;
        }

        if (percent <= 65) {
            return 2;
        }

        return 3;
    }
}

export const ProfileRankComponent = {
   bindings: {
       percent: '<'
   },
   template: ProfileRankTemplate,
   controller: ProfileRankController,
   controllerAs: '$ctrl'
};
