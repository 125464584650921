import angular from 'angular';

import {googleMapsAddressFilter} from './address.filter';
import {ordinalFilter} from './ordinal.filter';
import {imageFitFilter} from './image-fit.filter';
import {nl2brFilter} from './nl2br.filter';
import { shortCourseTitleFilter } from './short-course-title.filter';

const MODULE_NAME = 'portfolium.core.filters';

(function () {
    angular.module(MODULE_NAME,[])
        // Filters
        .filter('address', googleMapsAddressFilter)
        .filter('imageFit', imageFitFilter)
        .filter('ordinal', ordinalFilter)
        .filter('nl2br', nl2brFilter)
        .filter('shortCourseTitle', shortCourseTitleFilter);
}());

export default MODULE_NAME;
