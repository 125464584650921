import InlineEditTemplate from '../../template/inline-edit.html';

function inlineEditDirective($timeout) {
    return {
        restrict: 'EA',
        require: 'ngModel',
        replace: true,
        transclude: true,
        scope: {
            'maxlength': '@',
            'selectAll': '=pfSelectAll'
        },
        link: function (scope, elem, attrs, ngModel) {
            let $viewField = elem.find('.pf-editable');
            let $editField = elem.find('textarea');

            if (!ngModel) {
                // No model provided, do nothing
                return;
            }

            // Hide edit field by default
            scope.showEdit = false;

            // Specify how UI should be updated
            ngModel.$render = function () {
                $editField.val(ngModel.$viewValue || '');
            };

            // Listen for change events to enable binding
            $editField.on('blur keyup change', ($event) => {
                scope.$evalAsync(function () {
                    ngModel.$setViewValue($editField.val());
                });

                if ($event.which === 27) {
                    $editField.blur();
                }
            });

            scope.toggleEdit = function () {
                if (!scope.showEdit) {
                    // Show edit field
                    scope.showEdit = true;
                    // Auto focus and select field contents
                    $timeout(() => {
                        $editField.focus();

                        if (scope.selectAll) {
                            // Preselect textarea text
                            $editField.select();
                        }
                    });
                } else {
                    // Hide edit field
                    scope.showEdit = false;
                    // Return focus
                    $timeout(() => {
                        $viewField.focus();
                    });
                }
            };
        },
        template: InlineEditTemplate
    };
}

inlineEditDirective.$inject = ['$timeout'];

export default inlineEditDirective;
