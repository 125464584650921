import PaginationSelectTemplate from './pagination-select.component.html';

/**
 * @ngdoc controller
 * @name PaginationSelectController
 * @module portfolium.component.PaginationSelectComponent
 */
class PaginationSelectController {
    /**
    * @ngdoc method
    * @name PaginationSelectController#$constructor
    * @methodOf PaginationSelectController
    * @description get the current select status
    */
    constructor($mdDialog){
        this.$mdDialog = $mdDialog;
    }

    $onInit() {
        this.isPristine = true;
        this.selected = !_.isUndefined(this.current) ? this.current : '';
    }

    setDirtyState() {
        if (!_.isEqual(this.selected, this.current)) {
            this.isPristine = false;
        } else {
            this.isPristine = true;
        }
    }

    handleApply() {
        this.onApply({ value: this.selected });
        this.$mdDialog.hide();
    }

    handleCancel() {
        this.onCancel();
        this.$mdDialog.hide();
    }
}

PaginationSelectController.$inject = ['$mdDialog'];

/**
 * @ngdoc component
 * @name PaginationSelectComponent
 * @module portfolium.component.PaginationSelectComponent
 */
export const PaginationSelectComponent = {
    bindings: {
        current: '<',
        options: '<',
        onApply: '&',
        onCancel: '&',
        modalTitle: '<',
    },
    template: PaginationSelectTemplate,
    controller: PaginationSelectController,
    controllerAs: '$ctrl'
};
