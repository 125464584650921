import angular from 'angular';

import { SkillPillListComponent } from './skill-pill-list/skill-pill-list.component';

const MODULE_NAME = 'portfolium.component.skills';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfSkillPillList', SkillPillListComponent);
}());

export default MODULE_NAME;
