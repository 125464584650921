export class FeatureSwitchService {

    /**
     * @param $http {Function: $http}
     */
    constructor($http) {
        this.$http = $http;
        this._features = null;
        this._loading = false;
    }

    /** @returns {Promise<boolean>} */
    init() {
        if (!this.isInited) {
            this._loading = true;
            return this.$http({
                method: 'GET',
                url: `/proxy/features/user`,
            }).then(response => {
                if (response.data) {
                    this._features = response.data;
                    return true;
                }
                return false;
            }).finally(() => {
                this._loading = false;
            });
        } else {
            return Promise.resolve(true);
        }
    }

    /** @returns {string[]} */
    get features() {
        return this._features;
    }

    /** @returns {boolean} */
    get isInited() {
        return this._features !== null;
    }

    /** @returns {boolean} */
    get isLoading() {
        return this._loading;
    }

    /**
     * @description Check if a feature is enabled for the current user
     * @param {string} feature The feature to check (e.g. PF_FEATURE_SWITCHES.RECAPTCHA
     * @returns {boolean}
     */
    isOn(feature) {
        if (!this.isInited) {
            throw new Error('FeatureSwitchService: features not set.');
        }
        return this.features.includes(feature);
    }
}

FeatureSwitchService.$inject = ['$http'];
