import _ from 'lodash';

import {
    ADD_MESSAGES,
    UPDATE_UNREAD_MESSAGES,
} from '../action/notification-center-messages';

const initialState = {
    unread: 0,
    limit: 15,
    offset: 0,
    finished: false,
    messages: [],
};

export const notificationCenterMessages = (state = initialState, action = {}) => {
    switch (action.type) {
        case ADD_MESSAGES:
            return _.assign({}, state, {
                offset: state.offset + action.payload.length,
                finished: !action.payload.length,
                messages: [
                    ...state.messages,
                    ...action.payload,
                ],
            });
        case UPDATE_UNREAD_MESSAGES:
            return _.assign({}, state, {
                unread: action.payload,
            });
        default:
            return state;
    }
};
