/**
 * @ngdoc controller
 * @name ProfileStrengthCompController
 * @module portfolium.component.profileStrengthComp
 */
class ProfileStrengthCompController {
    constructor($scope, $pfGameEngine) {
        // TODO - need to move this to redux
        $scope.$watch(() => {
            return $pfGameEngine.results;
        }, (newVal) => {
            this.gameEngine = newVal;
        });
    }

    /**
     * @description Get the current level
     * @return {Object}        The current level object
     */
    get currentLevel() {
        return this.gameEngine.current_level;
    }

    /**
     * @description Get the next level
     * @return {Promise}        The next level object
     */
    get nextLevel() {
        return this.gameEngine.next_level;
    }

    /**
     * @description Get the level percentage
     * @return {Integer}        The level percentage
     */
    get levelPercent() {
        let {points} = this.gameEngine;
        let currentLevelPoints = this.currentLevel.required_points;
        let nextLevelPoints = (this.nextLevel) ? this.nextLevel.required_points : this.gameEngine.total_points;
        let percent = ((points - currentLevelPoints) / (nextLevelPoints - currentLevelPoints)) * 100;

        if (percent > 100) {
            percent = 100;
        }

        if (percent > 80) {
            return percent - 5;
        }

        return percent;
    }

    /**
     * @description Get the levels
     * @return {Object}        The levels
     */
    get levels() {
        return this.gameEngine.levels;
    }
}

ProfileStrengthCompController.$inject = ['$scope', '$pfGameEngine', '$pfGameEngineAction', '$pfEventTracker'];

/**
 * @ngdoc component
 * @name pfProfileStrengthComp
 * @module portfolium.component.profileStrengthComp
 * @description
 * Show a user their profile strength
 */
export const ProfileStrengthCompComponent = {
    controller: ProfileStrengthCompController,
    controllerAs: 'strengthCtrl',
    template: `
        <div class="pf-profile-strength-comp" ng-if="strengthCtrl.gameEngine && strengthCtrl.currentLevel"
             aria-label="Progress from {{ strengthCtrl.currentLevel.display_name }} to {{ strengthCtrl.nextLevel.display_name }}, {{ strengthCtrl.levelPercent }}%">
            <div class="pf-strength-meter-bar"
                 aria-hidden="true">
                <div class="pf-strength-meter-bar-fill"
                     ng-style="{width: strengthCtrl.levelPercent + '%'}">
                </div>
            </div>
            <div class="pf-strength-meter-labels"
                 layout="row"
                 aria-hidden="true">
                <div class="pf-strength-label">
                    {{ strengthCtrl.currentLevel.display_name | i18n }}
                </div>
                <div flex></div>
                <div class="pf-strength-label">
                    {{ strengthCtrl.nextLevel.display_name | i18n }}
                </div>
            </div>
        </div>
    `
};
