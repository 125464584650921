import _ from 'lodash';

import {UPDATE_COMPETENCY_DATA} from '../action/competency-panel';

export const competencyPanel = (state = {
    data: {},
}, action = {}) => {
    switch (action.type) {
        case UPDATE_COMPETENCY_DATA:
            return _.assign({}, state, {
                data: action.payload,
            });
        default:
            return state;
    }
};
