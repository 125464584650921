import './blank-field.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component
 * @name BlankFieldController
 */
class BlankFieldController {
    constructor($element) {
        this.$element = $element;
    }

    $postLink() {
        const backgroundColor = '0, 64, 102'; // $color-electric-dark-24 in RGB
        const opacity = this.opacity || '.12';
        const borderRadius = (this.borderRadius) ? this.borderRadius + 'px' : '2px';

        const styles = {
            'background-color': `rgba(${backgroundColor}, ${opacity})`,
            'border-radius': borderRadius,
            'height': this.height,
            'width': this.width,
        };

        this.$element.css(styles);
    }
}

BlankFieldController.$inject = ['$element'];

/**
 * @ngdoc component
 * @module portfolium.component
 * @name BlankFieldComponent
 * @description Display list of likers in the entry viewer sidebar
 *
 * @param {string} borderRadius
 * @param {string} height
 * @param {string} opacity
 * @param {string} width
 */
export const BlankFieldComponent = {
    bindings: {
        borderRadius: '@',
        height: '@',
        opacity: '@',
        width: '@',
    },
    controller: BlankFieldController,
    controllerAs: '$ctrl',
};
