import ComparisonSliderTemplate from '../template/comparison-slider.html';

function resizeSliderDirective($document) {
    return {
        restrict: 'EA',
        scope: {
            leftImg: '@pfLeftImg',
            rightImg: '@pfRightImg'
        },
        replace: true,
        link: function (scope, elem, attrs) {
            let handle = elem.find('.pf-comparison-slider-handle');
            let left = elem.find('.pf-comparison-slider-left');

            handle.on('mousedown', function(event) {
                event.preventDefault();
                $document.on('mousemove', mousemove);
                $document.on('mouseup', mouseup);
            });

            function mousemove(event) {
                let offset = elem.offset();
                let x = event.pageX - offset.left;

                if (x <= 0 || x >= (elem.width() - 30)) {
                    return false;
                }

                if (x > 450) {
                    x = 450;
                }

                handle.css({
                    left: x + 'px'
                });

                left.css({
                    width: x + 15 + 'px'
                });
            }

            function mouseup() {
                $document.unbind('mousemove', mousemove);
                $document.unbind('mouseup', mouseup);
            }
        },
        template: ComparisonSliderTemplate
    };
}

resizeSliderDirective.$inject = ['$document'];

export default resizeSliderDirective;
