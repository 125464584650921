import angular from 'angular';

import {PaginationSortComponent} from './pagination-sort/pagination-sort.component';
import {PaginationSortBtnComponent} from './pagination-sort/pagination-sort-btn.component';
import {PaginationSelectComponent} from './pagination-select/pagination-select.component';
import {PaginationSelectBtnComponent} from './pagination-select/pagination-select-btn.component';
import {PaginationFilterComponent} from './pagination-filter.component';
import {PaginationFilterBtnComponent} from './pagination-filter-btn.component';
import {PaginationFilterTypeTextComponent} from './filter-types/pagination-filter-type-text/pagination-filter-type-text.component';
import {PaginationFilterTypeCheckboxComponent} from './filter-types/pagination-filter-type-checkbox/pagination-filter-type-checkbox.component';

import './pagination-filter.component.scss';

const MODULE_NAME = 'portfolium.component.paginationFilter';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfPaginationSort', PaginationSortComponent)
        .component('pfPaginationSortBtn', PaginationSortBtnComponent)
        .component('pfPaginationSelect', PaginationSelectComponent)
        .component('pfPaginationSelectBtn', PaginationSelectBtnComponent)
        .component('pfPaginationFilter', PaginationFilterComponent)
        .component('pfPaginationFilterBtn', PaginationFilterBtnComponent)
        .component('pfPaginationFilterTypeText', PaginationFilterTypeTextComponent)
        .component('pfPaginationFilterTypeCheckbox', PaginationFilterTypeCheckboxComponent);
}());

export default MODULE_NAME;
