import {
    initSitewideSearch,
    queryUpdate,
    previousMenuItem,
    nextMenuItem,
    menuItemIndexUpdate,
} from './sitewide-search.actions';

/**
 * @ngdoc controller
 * @name sitewideSearchController
 * @module portfolium.component.pfSitewideSearch
 * @description controller for pfSitewideSearch
 */
export class SitewideSearchController {
    constructor(
        $state,
        $stateParams,
        $window,
        $timeout,
        $element,
        $pfEventTracker,
        $mdMedia,
        $pfSitewideSearchPanel,
        $pfSitewideSearchMobilePanel,
        $ngRedux,
        PF_SITEWIDE_SEARCH_MENU,
        PF_SITE_WIDE_SEARCH_MENU_ID,
        PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX
    ) {
        this.PF_SITEWIDE_SEARCH_MENU = PF_SITEWIDE_SEARCH_MENU;
        this.PF_SITE_WIDE_SEARCH_MENU_ID = PF_SITE_WIDE_SEARCH_MENU_ID;
        this.PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX = PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$window = $window;
        this.$timeout = $timeout;
        this.$element = $element;
        this.$mdMedia = $mdMedia;
        this.$pfEventTracker = $pfEventTracker;
        this.$pfSitewideSearchPanel = $pfSitewideSearchPanel;
        this.$pfSitewideSearchMobilePanel = $pfSitewideSearchMobilePanel;
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        // get query from state params
        this.searchValue = this.$stateParams.q;
        // default current state to nothing
        this.currentStateName = null;
        // init state with the quantity of items
        this.$ngRedux.dispatch(initSitewideSearch(this.PF_SITEWIDE_SEARCH_MENU.length));

        this._unsubscribe = this.$ngRedux.connect(
            this._mapStateToThis,
        )(this);
    }

    $postLink() {
        // this setup is only for the desktop version
        if (this.isMobile) {
            return;
        }
        // get the input of the search so we could focus it
        this.input = angular.element('#pf-navbar-search-form--search').find('input');
        /**
         * initialize the search panel
         * we need to keep it in the DOM for accessibility
         * do not destroy and rebuild panels everytime
         * this should be the pattern we follow for future panels, or at least for panels that tie back to an input/combobox
         */
        this.$pfSitewideSearchPanel.initializePanel(this.input);
        this.input.on('click', () => {
            this.openPanel();
        });
        // update the current item
        this.updateCurrentItem();
        // bind keys for navigations
        this.$element.bind('keydown', (event) => {
            // identify which key is being press
            switch (event.keyCode) {
                // arrow up
                case 38:
                    // ADA NOTE since we're still focused on the input, prevent up and down arrows from moving the cursor
                    event.preventDefault();
                    // dont do anything if there is no query (panel is closed)
                    if (!_.isEmpty(this.searchValue)) {
                        // execute in the end of the stack
                        this.$timeout(() => {
                            this.$ngRedux.dispatch(previousMenuItem());
                        });
                    }
                    break;
                // arrow down
                case 40: {
                    // ADA NOTE since we're still focused on the input, prevent up and down arrows from moving the cursor
                    event.preventDefault();
                    // dont do anything if there is no query (panel is closed)
                    if (!_.isEmpty(this.searchValue)) {
                        // execute in the end of the stack
                        this.$timeout(() => {
                            this.$ngRedux.dispatch(nextMenuItem());
                        });
                    }
                    break;
                }
            }
        });
    }

    $onDestroy() {
        this._unsubscribe();
    }

    _mapStateToThis(state) {
        return {
            isMenuOpen: state.sitewideSearch.open,
            menuItemIndex: state.sitewideSearch.menuItemIndex
        };
    }

    get isMobile() {
        return !this.$mdMedia('gt-sm');
    }

    get selectedMenuItem() {
        return `${this.PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX}${this.menuItemIndex}`
    }

    /**
     * Find the current selected item when state changes
     */
    updateCurrentItem() {
        // get current state
        const { name } = this.$state.current;
        // if state wast changed...
        if (this.currentStateName !== name) {
            // store item
            this.currentStateName = name;
            // find the state in the possible search states
            const initialIndex = _.findIndex(this.PF_SITEWIDE_SEARCH_MENU, { state: name });
            // if exists
            if (initialIndex >= 0) {
                // select the item that belongs to the state
                this.$ngRedux.dispatch(menuItemIndexUpdate(initialIndex));
            } else {
                this.$ngRedux.dispatch(menuItemIndexUpdate(0));
            }
        }
    }

    /**
     * The search input is not using ng model so
     * the value has to be updated in every change
     * @param  {String} newValue updated value of the input
     */
    updateSearch(newValue) {
        // update controller
        this.searchValue = newValue;
        // sanity check query is empty
        if (_.isEmpty(newValue)) {
            // close panel if is empty
            this.$pfSitewideSearchPanel.close()
                .then(() => {
                    this.focusInput();
                });
                return;
        }
        this.openPanel();
    }

    /**
     * opens the md menu, focus the input and
     * updates the selecte item
     */
    openPanel() {
        if (_.isEmpty(this.searchValue)) {
            return;
        }
        this.updateCurrentItem();
        this.$ngRedux.dispatch(queryUpdate(this.searchValue));
        if (!this.isMobile) {
            // check for menu reference
            this.$pfSitewideSearchPanel.open(this.input)
                .then(() => {
                    this.focusInput();
                }
            );
        }
    }

    /**
    * @ngdoc method
    * @name pfSitewideSearchController#$submitSearch
    * @methodOf pfSitewideSearchController
    * @param {Reference} containerReference a reference to the currently open container
    * @description changes the state to the search
    *   entries with the query typed
    */
    submitSearch() {
        const searchValue = this.searchValue || '';
        this.updateCurrentItem();
        // this will close search if it is on mobile
        if (this.onSearchSubmit) {
            this.onSearchSubmit();
        }
        // if no selected item, default to Universal Search(projects for now)
        const itemKey = (this.menuItemIndex > 0) ? this.menuItemIndex : 0;
        // get the item object
        const menuItem = this.PF_SITEWIDE_SEARCH_MENU[itemKey];
        // get item name
        const { name } = menuItem;
        // track event on it
        this.trackCategory( (this.menuItemIndex > 0) ? name : 'default');
        // do we have a current state to transition from?
        if (this.$state.current.status === 'active') {
            // get item state
            const { state } = menuItem;
            // Clear current state query param if search value is empty
            if (_.isEmpty(searchValue)) {
                return this.$state.go(state, { 'q': searchValue })
            }
            // close panel
            this.closePanel()
                .then(() => {
                    // focus input
                    this.focusInput();
                    // go to state
                    this.$state.go(state, { 'q': searchValue, 'page': '1' });
                }
            );
        } else {
            // get item state
            const { route } = menuItem;
            // the current page does not have a state (php page, for example)
            this.$window.location.assign(`/search/${route}?q=${searchValue}&page=1`);
        }
    }

    /**
     * Tracks category search performed
     * @param  {String} categoryName name of the category being performed
     */
    trackCategory(categoryName) {
        // set click source
        const clickSource = this.isMobile ? 'mobileNavbar' : 'desktopNavbar';

        // send track event
        this.$pfEventTracker.trackServer('Categorical Search Performed',{
            clickSource,
            categoryName,
            userAction: 'search',
        });
    }

    /**
     * focus the input elemenet at the
     * end of the stack
     */
    focusInput() {
        // execute in the end of the stack
        this.$timeout(() => {
            // focus the search input
            this.input.focus();
        });
    }

    /**
     * close panel from service, either from mobile
     * or desktop
     * @return {Promise}
     */
    closePanel() {
        // sanity check is mobile
        if (this.isMobile) {
            return this.$pfSitewideSearchMobilePanel.close();
        }
        return this.$pfSitewideSearchPanel.close();
    }
}

SitewideSearchController.$inject = [
    '$state',
    '$stateParams',
    '$window',
    '$timeout',
    '$element',
    '$pfEventTracker',
    '$mdMedia',
    '$pfSitewideSearchPanel',
    '$pfSitewideSearchMobilePanel',
    '$ngRedux',
    'PF_SITEWIDE_SEARCH_MENU',
    'PF_SITE_WIDE_SEARCH_MENU_ID',
    'PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX',
];
