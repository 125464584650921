import angular from 'angular';

import StandardTemplate from './template/card/standard.html'
import FeedTemplate from './template/card/feed.html'
import MicroTemplate from './template/card/micro.html'
import './company-connect.module.scss';

const MODULE_NAME = 'portfolium.component.company.companyConnect';

import { companyConnectBtnDirective } from './js/directive/company-connect-btn-directive';
import { companyCardDirective } from './js/directive/company-card-directive';
import CompanyConnectService from './js/service/company-connect-service';

(function() {
    angular
        .module(MODULE_NAME, [])
        // Directives
        .directive('pfCompanyConnectBtn', companyConnectBtnDirective)
        .directive('pfCompanyCard', companyCardDirective)
        // Service
        .service('$pfCompanyConnect', CompanyConnectService)

        .run([
            '$templateCache',
            $templateCache => {
                $templateCache.put(
                    'template/components/company/company-connect/template/card/micro.html',
                    MicroTemplate,
                );
                $templateCache.put(
                    'template/components/company/company-connect/template/card/feed.html',
                    FeedTemplate,
                );
                $templateCache.put(
                    'template/components/company/company-connect/template/card/standard.html',
                    StandardTemplate,
                );
            },
        ]);
})();

export default MODULE_NAME;
