import {combineReducers} from 'redux';
import {getPaginationReducer} from 'components/pagination/pagination.reducer';

import {
    PATHWAY_REQUIREMENT_SUBMISSION_SELECT,
    PATHWAY_REQUIREMENT_SUBMISSION_RESET,
    PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_DESCRIPTION_TOGGLE,
    PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_ADD_TO_LIST,
    pathwayRequirementSubmissionPanelActionTypes,
} from './pathway-requirement-submission-panel.actions';

const initialState = null;

const currentSubmissionDraft = (state = initialState, action = {}) => {
    switch (action.type) {
        case PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_DESCRIPTION_TOGGLE:
            const { includeDescription } = state;

            return _.assign({}, state, {
                includeDescription: !includeDescription,
            });
        case PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_ADD_TO_LIST:
            // get payload values
            const {
                field,
                keyName,
                keyValue,
            } = action.payload;
            // get the list
            const fieldList = state[field];
            // find the index in the results of the updated object
            const index = _.findIndex(fieldList, {
                [keyName]: keyValue,
            });

           // return state if not found
            if (_.isUndefined(index)) {
                return state;
            }
            // update the field with the toggled value
            const updatedField = _.assign({}, fieldList[index], {
                include: !fieldList[index].include,
            });
            // update and return the state with new value
            return _.assign({}, state,
                {
                    [field]: [
                        ...fieldList.slice(0, index),
                        updatedField,
                        ...fieldList.slice(index + 1, fieldList.length)
                    ]
                });
        case PATHWAY_REQUIREMENT_SUBMISSION_SELECT:
            const { submissionDraft } = action.payload;
            return _.assign({}, submissionDraft);
        case PATHWAY_REQUIREMENT_SUBMISSION_RESET:
            return initialState;
        default:
            return state;
    }
};

export const pathwayRequirementSubmissionPanel = combineReducers({
    currentSubmissionDraft,
    pagination: getPaginationReducer({
        limit: 12,
        actionTypes: pathwayRequirementSubmissionPanelActionTypes,
        paginationStyle: 'infinite',
    }),
});
