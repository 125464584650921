class AddConnectionsAction
{
    constructor($state, $pfGameAction) {
        this.$state = $state;
        this.$pfGameAction = $pfGameAction;
        this.name = 'add_more_connections';
        this.label = 'Connect with your friends and classmates';
    }

    execute() {
        return this.$state.go('dashboard.suggestions');
    }
}

AddConnectionsAction.$inject = ['$state', '$pfGameAction'];

export default AddConnectionsAction;
