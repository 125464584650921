class ProfileStrengthController
{
    constructor($scope, $pfGameEngine) {
        $scope.$watch(() => {
            return $pfGameEngine.results;
        }, (newVal) => {
            this.gameEngine = newVal;
        });
    }

    get currentLevel() {
        return this.gameEngine.current_level;
    }

    get nextLevel() {
        return this.gameEngine.next_level;
    }

    get levelPercent() {
        let {points} = this.gameEngine;
        let currentLevelPoints = this.currentLevel.required_points;
        let nextLevelPoints = (this.nextLevel) ? this.nextLevel.required_points : this.gameEngine.total_points;
        let percent = ((points - currentLevelPoints) / (nextLevelPoints - currentLevelPoints)) * 100;

        if (percent > 100) {
            percent = 100;
        }

        if (percent > 80) {
            return percent - 5;
        }

        return percent;
    }

    get levels() {
        return this.gameEngine.levels;
    }
}

ProfileStrengthController.$inject = ['$scope', '$pfGameEngine'];

function profileStrengthDirective() {
    return {
        restrict: 'E',
        scope: true,
        bindToController: true,
        require: ['pfProfileStrength'],
        controller: ProfileStrengthController,
        controllerAs: 'strengthCtrl',
        template: `
            <div class="pf-strength-meter" ng-if="strengthCtrl.gameEngine && strengthCtrl.currentLevel"
                 aria-label="Progress from {{ strengthCtrl.currentLevel.display_name }} to {{ strengthCtrl.nextLevel.display_name }}, {{ strengthCtrl.levelPercent }}%">
                <div class="pf-strength-meter-bar"
                     aria-hidden="true">
                    <div class="pf-strength-meter-bar-fill" ng-style="{width: strengthCtrl.levelPercent + '%'}"></div>
                </div>
                <div class="pf-strength-meter-labels"
                     layout="row"
                     aria-hidden="true">
                    <span class="pf-strength-label">{{strengthCtrl.currentLevel.display_name | i18n}}</span>
                    <span flex></span>
                    <span class="pf-strength-label">{{strengthCtrl.nextLevel.display_name | i18n}}</span>
                </div>
            </div>
        `
    };
}

export default profileStrengthDirective;
