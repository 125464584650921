import { PortfoliumUser } from './model/portfolium-user';

class UserService {

    /**
     *
     * @param $window
     * @param $http
     * @param $pfSession {SessionService}
     */
    constructor($window, $http, $pfSession) {
        this.$http = $http;
        this.$pfSession = $pfSession;
        this.globals = $window.Portfolium;
        this._currentUser = new PortfoliumUser($pfSession.data);
    }

    /**
     * Get a reference to the current logged in user
     * @return {null|PortfoliumUser}
     */
    getUser() {
        if (!this._currentUser.id) {
            return null;
        }

        return this._currentUser;
    }

    /**
     * Remove user session and update the reference to the current logged in user
     * @return {PortfoliumUser}
     */
    removeUser() {
        // kill the session
        this.$pfSession.removeSession();
        // refresh currentUser with the deleted session data
        this._currentUser = new PortfoliumUser(this.$pfSession.data);
        return this._currentUser;
    }

    /**
     * TODO Move this to a new service, $pfUserSearch
     * @description Get a list of user profiles
     * @param  {Object}  params Query parameters object
     * @return {Promise}
     */
    searchUsers(params) {
        return this.$http({
            method: 'GET',
            url: '/proxy/es/users',
            params: params,
        }).then(response => {
            if (response.data) {
                return response.data.results;
            }
        });
    }

    /**
     * Detect if user is on a mobile device
     * @return {Boolean} Is the device mobile?
     */
    isMobile() {
        return this.globals.isMobile;
    }

    /**
     * Get the networks to which a user belongs
     * @param   {String}  username Username of the user
     * @returns {Promise}
     */
    getNetworksByUsername(username, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/networks/user/${username}/slug`,
            params: params,
        }).then(response => {
            return response.data;
        });
    }

    /**
     * Make API call to update the user's session
     * @returns {Promise}
     */
    updateSession(params = {}) {
        return this.$pfSession.updateSession(params).then(session => {
            this._currentUser.update(session);
        });
    }

    /**
     * Get a user by Toekn
     * @param  {String}  token the Token of the user we need to fetch
     * @returns {Object} User object
     */
    getUserByToken(token) {
        return this.$http({
            method: 'GET',
            url: `/proxy/users/profile/${token}/token`,
        });
    }

    /**
     * Get a user by ID
     * @param  {String}  userId the ID of the user we need to fetch
     * @param  {Boolean}  subscriber is this a subsciber
     * @returns {Object} User object
     */
    getUserById(userId, subscriber = false) {
        let params = {
            subscriber: subscriber,
        };

        return this.$http({
            method: 'GET',
            params: params,
            url: `/proxy/users/user/${userId}`,
        });
    }
}

UserService.$inject = ['$window', '$http', '$pfSession'];

export default UserService;
