import { combineReducers } from 'redux';
import { pathwayRequirementSubmissionPanel } from '../../components/pathway-requirement-submission-panel/pathway-requirement-submission-panel.reducer';

import {
    PATHWAY_REQUIREMENT_ADD_SUBMISSION,
    PATHWAY_REQUIREMENT_REMOVE_SUBMISSION,
    PATHWAY_REQUIREMENT_ADD_FEEDBACK,
    PATHWAY_REQUIREMENT_UPDATE_ACTIONS,
    PATHWAY_REQUIREMENT_RESET,
    PATHWAY_REQUIREMENT_FETCH,
    PATHWAY_REQUIREMENT_FETCH_SUCCESS
} from './pathway-requirement.actions';

const initialState = {
    fetching: false,
    requirement: {},
    submission: {},
    feedback: [],
    actions: {},
    skills: [],
}

const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case PATHWAY_REQUIREMENT_FETCH:
            return _.assign({}, state, {
                fetching: true,
            });
        case PATHWAY_REQUIREMENT_FETCH_SUCCESS:
            const { requirement } = action.payload;
            return _.assign({}, state, {
                requirement,
                skills: _.get(requirement, 'meta.pathway_assignment.skills') || [],
                fetching: false,
            });
        case PATHWAY_REQUIREMENT_RESET:
            return initialState;
        case PATHWAY_REQUIREMENT_ADD_SUBMISSION:
            const { submission } = action.payload;
            return _.assign({}, state, {
                submission,
            });
        case PATHWAY_REQUIREMENT_ADD_FEEDBACK:
            const { feedback } = action.payload;
            return _.assign({}, state, {
                feedback,
            });
        case PATHWAY_REQUIREMENT_UPDATE_ACTIONS:
            const { actions } = action.payload;
            return _.assign({}, state, {
                actions,
            });
        case PATHWAY_REQUIREMENT_REMOVE_SUBMISSION:
            return _.assign({}, state, {
                submission: {},
            });
        default:
            return state;
    }
};

export const currentRequirement = combineReducers({
    meta,
    pathwayRequirementSubmissionPanel
});
