import moment from 'moment';

export class GdprService {
    constructor(
        $http,
        $pfCookies,
        $pfUser,
        $pfEnv,
        $q,
        PF_COOKIES
    ) {
        this.$http = $http;
        this.$pfCookies = $pfCookies;
        this.user = $pfUser.getUser();
        this.$pfEnv = $pfEnv;
        this.$q = $q;
        this.PF_COOKIES = PF_COOKIES;
    }

    /**
     * Make API call to accept GDPR cookies
     * @param {Object} params
     * * @param {String} params.message
     * * @param {String} params.current_url
     * * @param {Object} params.cookieOptions
     * @returns {Promise} Promise
     */
    acceptCookies({
        message,
        currentUrl: current_url,
        cookieOptions,
    }) {
        // set the cookie
        this.setCookie({
            message,
            current_url,
            cookieOptions,
        });
        // check if the user is not logged in
        if (_.isEmpty(this.user)) {
            // return here
            return this.$q.resolve({ loggedIn: false });
        }
        // send the gdpr accept
        return this.$http({
            method: 'POST',
            url: '/proxy/gdpr/accept',
            data: {
                message,
                current_url,
            }
        }).then(response => response.data);
    }

    getCookies() {
        return this.$http({
            method: 'GET',
            url: '/proxy/gdpr/cookies',
        }).then(response => response.data);
    }

    showSnackbar() {
        // get the cookie
        const gdprCookieString = this.$pfCookies.get(this.PF_COOKIES.GDPR);
        // // determine that the user is signed in
        if (!_.isEmpty(this.user)) {
            // is acting as someone else ?
            if (this.user.isActingAs()) {
                // dont show
                return this.$q.resolve(false);
            }
            // sanity check for cookie
            if (_.isEmpty(gdprCookieString)) {
                // update the cookie if needed
                return this.updateGdprCookie();
            }
            // get the json of the cookie string
            const gdprCookie = JSON.parse(gdprCookieString);
            // check if it is the current user
            if (parseInt(gdprCookie.user_id) === parseInt(this.user.id)) {
                // update the cookie if needed
                return this.updateGdprCookie();
            }
            // cookie was set as logged out and now is log in ( we suppose is the same user)
            if (gdprCookie.user_id === -1) {
                // store in the db
                this.acceptCookies(gdprCookie);
            }
            // update the cookie if needed
            return this.updateGdprCookie();
        }
        // return flag here
        return this.$q.resolve(_.isEmpty(gdprCookieString));
    }

    updateGdprCookie() {
        // get the cookies array
        return this.getCookies()
            .then(gdprCookies => {
                // check if the cookie can be updated
                if (!_.isEmpty(gdprCookies)) {
                    const {
                        fk_user_id: user_id,
                        message,
                        url: current_url,
                        cookieOptions
                    } = gdprCookies[0];
                    // update the cookie
                    this.setCookie({
                        user_id,
                        message,
                        current_url,
                        cookieOptions,
                    });
                    // dont show the gdpr
                    return false;
                }
                // show the gdpr
                return true;
            });
    }

    setCookie({
        current_url,
        message,
        cookieOptions,
    }) {
        // set expiration to a year from today
        const expiresOn =  _.isEmpty(this.user) ? 0 : moment().add(365, 'days').toDate();
        // put that cookie
        this.$pfCookies.put(this.PF_COOKIES.GDPR,
            JSON.stringify({
                current_url,
                user_id: _.isEmpty(this.user) ? -1 : this.user.id,
                message,
                cookieOptions,
            }),
            {
                path: '/',
                domain: this.$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                expires: expiresOn,
            }
        );
    }
}

GdprService.$inject = [
    '$http',
    '$pfCookies',
    '$pfUser',
    '$pfEnv',
    '$q',
    'PF_COOKIES',
];
