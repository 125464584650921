import BlockedUserTemplate from './blocked-user.component.html';

/**
 * @ngdoc controller
 * @name BlockedUserController
 * @module portfolium.component.lockedUser
 **/
class BlockedUserController {
    constructor(
        $pfUserBlocking,
        $window,
        $pfToast,
        $mdDialog,
        $filter
    ) {
        this.$pfUserBlocking = $pfUserBlocking;
        this.$window = $window;
        this.$pfToast = $pfToast;
        this.$mdDialog = $mdDialog;
        this.$filter = $filter;
    }

    /**
     * Is this the blocker, or the blockee
     * @returns {Boolean}
     */
    isBlocker() {
        if (_.isNil(this.blockedUser)) {
            return false;
        }
        return true;
    }

    /**
     * Get formatted blocked date
     * @returns {String}
     */
    getBlockedDate() {
        return this.$filter('momentDatetime')(this.blockedDate, 'MM/DD/YY');
    }

    /**
     * Make the call to unblock this user, if you are the block-ER
     * @param {Event} $event
     * @returns {Promise}
     */
    unblockUser($event) {
        if (!this.isBlocker()) {
            return;
        }

        const title = `${this.$filter('i18n')('Unblock')} ${this.blockedUser.firstname}?`;
        const textContent = this.$filter('i18n')("This action will allow you to view each other's profile and portfolio");
        const ariaLabel = this.$filter('i18n')('Confirm Unblock');
        const ok = this.$filter('i18n')('Yes, Unblock');
        const cancel = this.$filter('i18n')('Cancel');

        // build it
        let confirm = this.$mdDialog.confirm()
            .title(title)
            .textContent(textContent)
            .ariaLabel(ariaLabel)
            .targetEvent($event)
            .ok(ok)
            .cancel(cancel);

        return this.$mdDialog
            .show(confirm)
            .then(() => {
                // unblock user
                this.$pfUserBlocking
                    .unblockUser(this.blockedUser.id)
                    .then(() => {
                        this.$pfToast.success('User unblocked');
                        // window reload because $state.reload() wants to reload the blocked page
                        this.$window.location.reload();
                    }, () => {
                        this.$pfToast.error('Oops, something went wrong');
                    });
            });
    }
}

BlockedUserController.$inject = [
    '$pfUserBlocking',
    '$window',
    '$pfToast',
    '$mdDialog',
    '$filter',
];

export const BlockedUserComponent = {
    bindings: {
        blockedUser: '<pfBlockedUser',
        blockedDate: '@pfBlockedDate',
    },
    template: BlockedUserTemplate,
    controller: BlockedUserController,
    controllerAs: '$ctrl',
};
