/**
 * @ngdoc service
 * @name CourseService
 * @module portfolium.core
 * @description Service for EDU course actions
 * @class CourseService
 */
export class CourseService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * @description Enroll the logged in student to the course
     * @param {Object} data Data to pass to the query
     * @param {String} data.network_id Network ID of the network course belongs to
     * @param {String} data.token Slug of the course to enroll in
     * @returns {Promise}
     */
    enrollInNetworkCourse(data) {
        return this.$http({
            method: 'POST',
            url: '/proxy/courses/enroll',
            data: data,
        }).then(response => response.data);
    }
}

CourseService.$inject = ['$http'];
