import NavbarUserDropdownTemplate from './navbar-user-dropdown.component.html';
import './navbar-user-dropdown.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.navbar
 * @name NavbarUserDropdownController
 */
class NavbarUserDropdownController {
    constructor($pfUser, $pfEnv) {
        this.currentUser = $pfUser.getUser();
        this.$pfEnv = $pfEnv;
    }

    get isActingAs() {
        return this.currentUser.isActingAs();
    }

    get userGuide() {
        if (this.currentUser.isCompanyAdmin()) {
            return 'https://community.canvaslms.com/docs/DOC-18216';
        }

        return 'https://community.canvaslms.com/community/answers/guides/portfolio';
    }
}

NavbarUserDropdownController.$inject = [
    '$pfUser',
    '$pfEnv',
];

/**
 * @ngdoc component
 * @module portfolium.component.navbar
 * @name pfNavbarUserDropdown
 */
export const NavbarUserDropdownComponent = {
    controller: NavbarUserDropdownController,
    controllerAs: '$ctrl',
    template: NavbarUserDropdownTemplate,
};
