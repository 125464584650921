import angular from 'angular';
import {updateEntriesOrder} from '../action/profile-about-panel';

class ProfileAboutPanelEntryOrderController {
    constructor(
        $ngRedux,
        $mdMedia,
        $pfProfileBucket,
        $pfToast,
        $mdLiveAnnouncer
    ) {
        this._$ngRedux = $ngRedux;
        this._$pfProfileBucket = $pfProfileBucket;
        this._$pfToast = $pfToast;
        this.$mdMedia = $mdMedia;
        this.$mdLiveAnnouncer = $mdLiveAnnouncer;
    }

    $onInit() {
        this.sortableConfig = {
            animation: 150,
            chosenClass: 'pf-drag-chosen',
            ghostClass: 'pf-drag-ghost',
            handle: '.pf-order-icon',
            onUpdate: ($event) => {
                if ($event.oldIndex === $event.newIndex) {
                    return;
                }

                this.setOrder($event.model, $event.newIndex + 1);
            },
        };
    }

    $onChanges() {
        this.entriesCopy = angular.copy(this.entries);
        this.options = this.entries.map(entry => entry.bucketOrder);
    }

    /**
     * Update the entry's order inside the bucket
     * @return {Promise<Object>}
     */
    setOrder(entry, selectedEntryOrder) {
        return this._$pfProfileBucket
            .orderEntryInBucket(this.bucketId, entry.id, selectedEntryOrder)
            .then((order) => {
                this.$mdLiveAnnouncer.announce(`${entry.title} was moved to position ${selectedEntryOrder}`, 'assertive');
                // Update application state
                this._$ngRedux.dispatch(updateEntriesOrder(order));
            }, () => {
                // Reset order
                this.entriesCopy = angular.copy(this.entries);
                // Show toast
                this._$pfToast.error('Something went wrong, please try again');
            });
    }
}

ProfileAboutPanelEntryOrderController.$inject = [
    '$ngRedux',
    '$mdMedia',
    '$pfProfileBucket',
    '$pfToast',
    '$mdLiveAnnouncer',
];

export const ProfileAboutPanelEntryOrderComponent = {
    bindings: {
        'entries': '<pfEntries',
        'bucketId': '@pfBucketId',
    },
    controller: ProfileAboutPanelEntryOrderController,
    template: `
        <md-list ng-sortable="$ctrl.sortableConfig">
            <md-list-item class="md-3-line"
                 ng-repeat="entry in $ctrl.entriesCopy track by entry.id">
                <md-icon class="material-icons pf-order-icon" aria-hidden="true">reorder</md-icon>
                <img ng-src="{{::entry.media[0].filename.url_https|image:108:192}}"
                     class="md-avatar"
                     alt=""
                     aria-hidden="true">
                <div class="md-list-item-text">
                    <h3>{{::entry.title}}</h3>
                    <p>{{::entry.category}}</p>
                </div>
                <md-select
                     ng-model="entry.bucketOrder"
                     ng-if="$ctrl.$mdMedia('gt-xs')"
                     aria-label="Change order of {{::entry.title}}"
                     ng-change="$ctrl.setOrder(entry, entry.bucketOrder)">
                    <md-option
                         ng-repeat="option in $ctrl.options"
                         ng-value="option"
                         aria-label="Position {{option}}">
                        {{option}}
                    </md-option>
                </md-select>
            </md-list-item>
        </md-list>
    `,
};
