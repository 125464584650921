export const ATTACHMENT_VIEWER_TYPES = {
    IMAGE: {
        name: 'image',
    },
    DOCUMENT: {
        name: 'document',
    },
    PLAYER: {
        name: 'player',
    },
    AUDIO: {
        name: 'audio',
    },
    EMBED: {
        name: 'embed',
    },
    OEMBED: {
        name: 'oembed',
    },
    WEBSITE: {
        name: 'website',
    },
    DOWNLOAD: {
        name: 'download',
    },
    UNSUPPORTED: {
        name: 'unsupported',
    },
};
