import angular from 'angular';
import BaseModalController from 'components/modal/js/controller/base-modal-controller';

/**
 * @ngdoc controller
 * @name PaginationSelectBtnController
 * @module portfolium.components.paginationFilter.paginationSelect
 */
class PaginationSelectBtnController {
    constructor($mdMedia, $mdDialog) {
        this.$mdMedia = $mdMedia;
        this.$mdDialog = $mdDialog;
        // default show label to show(true)
        this.showLabel = (this.showLabel === false) ? false : true;
    }

    isMobile() {
        return !this.$mdMedia('gt-sm');
    }

    /**
     * Open the modal
     * @param  {Event}  $event  Event
     * @return {Promise}
     */
    openModal($event) {
        const {
            current,
            options,
            modalTitle,
            onApply,
            onCancel,
        } = this;

        return this.$mdDialog.show({
            locals: {
                current,
                options,
                modalTitle,
                handleOnApply: onApply,
                handleOnCancel: onCancel,
            },
            template: `
                <md-dialog aria-label="Pagination Select" class="pf-dialog pf-dialog--white pf-pagination-filter-modal">
                    <pf-pagination-select
                         current="$ctrl.current"
                         options="::$ctrl.options"
                         on-apply="::$ctrl.handleOnApply({ value })"
                         on-cancel="::$ctrl.handleOnCancel"
                         modal-title="::$ctrl.modalTitle">
                    </pf-pagination-select>
                </md-dialog>
            `,
            parent: angular.element(document.body),
            multiple: true,
            focusOnOpen: false,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            controller: BaseModalController,
            bindToController: true,
            controllerAs: '$ctrl',
            onComplete: () => {
                // since we have no button to focus on, set focusOnOpen to false and manually do it to the window
                // see: https://github.com/angular/material/issues/1190
                angular.element('md-dialog').focus();
            },
        });
    }
}

PaginationSelectBtnController.$inject = [
    '$mdMedia',
    '$mdDialog',
];

export const PaginationSelectBtnComponent = {
    controller: PaginationSelectBtnController,
    bindings: {
        current: '<',
        options: '<',
        onCancel: '&',
        onApply: '&',
        btnText: '<',
        modalTitle: '<',
        label: '<',
        showLabel: '<',
    },
    template: `
        <md-button
             ng-if="$ctrl.isMobile()"
             ng-click="$ctrl.openModal($event)"
             class="md-button pf-outline pf-btn-block"
             md-no-ink>
            {{ ::$ctrl.btnText }}
        </md-button>
        <div ng-if="!$ctrl.isMobile()"
             class="pf-field pf-field--inline"
             layout-align="center center"
             layout="row">
            <pf-field-label
                 ng-if="::$ctrl.showLabel">
                {{ ::$ctrl.label }}:
            </pf-field-label>
            <md-select
                 class="pf-form-control pf-form-control--borderless"
                 placeholder='{{ "Choose a value..." | i18n }}'
                 ng-model="$ctrl.current"
                 ng-change="$ctrl.onApply({ value: $ctrl.current })"
                 aria-label="{{ ::$ctrl.btnText }} filter">
                <md-option
                     ng-repeat="option in ::$ctrl.options track by option.value"
                     ng-value="::option.value">
                    {{ ::option.title | i18n }}
                </md-option>
            </md-select>
        </div>
    `,
};
