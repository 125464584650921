import raven from 'raven-js';

function ngRavenExceptionHander($delegate) {
    if (!raven.isSetup()) {
        throw new Error('Raven must be set before using this');
    }

    return function angularExceptionHandler(exception, cause) {
        $delegate(exception, cause);
        raven.captureException(exception, {extra: {cause: cause}});
    };
}

export default ngRavenExceptionHander;
