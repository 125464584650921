import ProfileAboutPanelTemplate from '../../template/profile-about-panel.html';
import { ABOUT_PANEL_TABS, updateEntries } from '../action/profile-about-panel';

class ProfileAboutPanelController {
    constructor($ngRedux, $pfUser, $pfProfileBucket) {
        this._$ngRedux = $ngRedux;
        this._$pfUser = $pfUser;
        this._$pfProfileBucket = $pfProfileBucket;
        this.tabs = ABOUT_PANEL_TABS;
    }

    $onInit() {
        // Subscribe to state updates
        this.unsubscribe = this._$ngRedux.connect(this._mapStateToThis)(this);
        this.currentUser = this._$pfUser.getUser();
        this._getAllBucketEntries();
    }

    $onDestroy() {
        // Unsubscribe when panel is closed
        this.unsubscribe();
    }

    /**
     * The entries collection on an about record is limited to 6. Make sure we
     * have all the entries attached to the record in the list
     */
    _getAllBucketEntries() {
        const bucketId = this.panel.data.fk_bucket_id;

        if (!bucketId) {
            return;
        }

        // Get all entries for the about record's bucket
        this._$pfProfileBucket.getEntriesByBucketId(bucketId).then(entries => {
            // Update the panel state
            this._$ngRedux.dispatch(updateEntries(entries));
        });
    }

    _mapStateToThis(state) {
        return {
            panel: state.profileAboutPanel,
            userEntries: state.userEntries,
        };
    }

    /**
     * Close the panel
     * @param  {Boolean} didCreateEntry   whether to close the overview profile edit
     * @return {Promise} Resolved when panel is closed
     */
    close({ didCreateEntry = false } = {}) {
        return this.mdPanelRef.close({ didCreateEntry });
    }
}

ProfileAboutPanelController.$inject = [
    '$ngRedux',
    '$pfUser',
    '$pfProfileBucket',
];

export const ProfileAboutPanelComponent = {
    bindings: {
        onSubmit: '<pfOnSubmit',
        mdPanelRef: '<mdPanelRef',
    },
    template: ProfileAboutPanelTemplate,
    controller: ProfileAboutPanelController,
};
