import ResumeUploadTemplate from './resume-upload.component.html';
import './resume-upload.component.scss';

/**
 * @ngdoc ResumeUploadController
 * @name ResumeUploadController
 * @module portfolium.components.uploadResume
 **/
class ResumeUploadController {
    constructor(
        $element,
        $q,
        $pfDropZone,
        $pfResumeUpload,
        $pfFilestack,
        $pfFilestackPreset,
        $pfFilestackConvert,
        $pfFilestackSigner,
        $timeout,
        $pfProfileAbout,
        $ngRedux,
        $mdDialog,
        $pfUser,
        $filter
    ) {
        this.$pfProfileAbout = $pfProfileAbout;
        this.$q = $q;
        this.$element = $element;
        this.$pfDropZone = $pfDropZone;
        this.$pfResumeUpload = $pfResumeUpload;
        this.$pfFilestack = $pfFilestack;
        this.$pfFilestackPreset = $pfFilestackPreset;
        this.$pfFilestackConvert = $pfFilestackConvert;
        this.$pfFilestackSigner = $pfFilestackSigner;
        this.$timeout = $timeout;
        this.$ngRedux = $ngRedux;
        this.$mdDialog = $mdDialog;
        this.$pfUser = $pfUser;
        this.$filter = $filter;
    }

    $onInit() {
        this.presetName = 'resume';
        this.user = this.$pfUser.getUser();
    }

    $onDelete() {
        this.dropZoneRef.destroy();
        delete this.dropZoneRef;
    }

    $postLink() {
        // get the element here
        this.$element.attr('tabindex', -1);
        // sanity check
        if (this.autofocus) {
            // focus here
            this.$element.find('#pf-resume-upload-btn').focus();
        }
        // init the drop zone
        this.$pfDropZone
            .init(this.$element, {
                onDrop: file => {
                    // set the flag
                    this.loading = true;
                    // get the preset store options
                    const storeOptions = this.$pfFilestackPreset.getPresetStoreOptions(
                        this.presetName,
                    );
                    // get the preset type
                    const presetType = this.$pfFilestackPreset.getPresetType(
                        this.presetName,
                    );
                    // set the flag
                    let validMimetypeForType = false;

                    /**
                     * validateFileSignature is a fix for IE, which can't get the mimetype for some
                     * files, like - wait for it................................... MS Word docs
                     * this only happens on Windows if MS Office is not installed, in which case
                     * it will show the mime type as application/octet-stream or an empty string
                     * so we will validate the file signature and extension
                     * https://en.wikipedia.org/wiki/List_of_file_signatures
                     */

                    // convert the file here
                    this.$pfFilestackConvert
                        .validateFileSignature(file, 'resume')
                        .then(validSignature => {
                            // set the flag here
                            let skipWatermark = false;
                            // sanity check for file and type
                            if (file && file.type) {
                                // set the flag after a check
                                validMimetypeForType = this.$pfFilestackConvert.mimetypeBelongsToType(
                                    file.type,
                                    presetType,
                                );
                            } else if (file && _.isEmpty(file.type)) {
                                // check the signature
                                skipWatermark = !!validSignature;
                            }
                            // check if we have a valid mime type OR signature
                            if (validMimetypeForType || validSignature) {
                                // get the security policy for an upload
                                this.$pfFilestackSigner
                                    .getUploadPolicy(this.presetName, file.name)
                                    .then(security => {
                                        // upload the file here
                                        this.$pfFilestackPreset
                                            .upload(
                                                this.presetName,
                                                file,
                                                {},
                                                { path: security.path },
                                                security,
                                            )
                                            .then(meta => {
                                                // set the security here
                                                meta.security = security;
                                                // call the upload finished callback
                                                this.onFileUploadFinished(
                                                    meta,
                                                    skipWatermark,
                                                );
                                            })
                                            .catch(err => {
                                                // set the flag
                                                this.loading = false;
                                                // call the on error here
                                                this.onResumeError({
                                                    err:
                                                        'Something went wrong, please try again.',
                                                });
                                            });
                                        // return
                                        return;
                                    })
                                    .catch(error => {
                                        // set loading
                                        this.loading = false;
                                        // on resume error
                                        this.onResumeError({
                                            err: this.$pfFilestackSigner.simpleErrorMessage(),
                                        });
                                    });
                            } else {
                                // set the flag
                                this.loading = false;
                                // call the on error here
                                this.onResumeError({
                                    err: `Sorry, that file type isn't supported.`,
                                });
                            }
                        });
                },
                multipleFiles: false,
                fullScreenMode: false,
            })
            .then(dropZoneRef => {
                // set the drop zone ref
                this.dropZoneRef = dropZoneRef;
            });
    }

    /**
     * Manage the image upload finish
     * @param  {object}   meta image data
     * @param  {boolean}  skipWatermark flag to tell the api to not add the watermark
     *                                  we want to do this if the user is uplaoding a
     *                                  ms word doc but their pc doesn't have a valid
     *                                  mime type for the file
     * @return {Promise}
     */
    onFileUploadFinished(meta, skipWatermark = false) {
        // convert the file to PDF
        return this.$pfResumeUpload.convertToPDF(meta).then(pdfMeta => {
            // sanity check
            if (!this.processResume) {
                // fire the callback
                this.onResumeUpdate({ resume: pdfMeta.key });
                // set timeout
                this.$timeout(() => {
                    // set the flag
                    this.loading = false;
                });
                // return
                return;
            }
            // call the profile about
            return this.$pfProfileAbout
                .updateResume({
                    file_uri: pdfMeta.key,
                    resume_privacy: this.privacy,
                    skip_watermark: skipWatermark,
                })
                .then(resume => {
                    // on resume update
                    this.onResumeUpdate({ resume });
                })
                .catch(() => {
                    // on resume error
                    this.onResumeError({
                        err: 'Something went wrong, please try again.',
                    });
                })
                .finally(() => {
                    // set timeout
                    this.$timeout(() => {
                        // set the flag
                        this.loading = false;
                    });
                });
        });
    }

    /**
     * Opens the file stack picker, and manages the loading events
     */
    pickResume() {
        // set the scope
        let self = this;
        // run the picker
        return this.$pfFilestackPreset.picker(
            this.presetName,
            {
                // on file selected
                onFileUploadStarted: file => {
                    // sanity check
                    this.$pfFilestackPreset.mimeTypePanic(
                        this.presetName,
                        file,
                    );
                    // set the timeout
                    this.$timeout(() => {
                        // set loading
                        this.loading = true;
                    });
                },
            },
            (original, file, security) => {
                // get the file here
                const originalFile = original.originalFile || original;
                // get the mimetype
                const mimetype = originalFile.type || originalFile.mimetype;
                // convert the file here
                self.$pfFilestackConvert
                    .validateFileSignature(
                        originalFile,
                        self.presetName,
                        original.source,
                    )
                    .then(validSignature => {
                        // need this
                        let skipWatermark = false;
                        // sanity check the deets
                        if (
                            originalFile &&
                            _.isEmpty(mimetype) &&
                            validSignature
                        ) {
                            // skip the agua
                            skipWatermark = true;
                        }
                        // set the handle
                        original.handle = file.handle;
                        // set the url
                        original.url = file.url;
                        // set the key
                        original.key = file.key;
                        // set the security
                        original.security = security;
                        // call the callback
                        self.onFileUploadFinished(original, skipWatermark);
                    });
            },
            error => {
                // set the timeout
                this.$timeout(() => {
                    // set loading
                    this.loading = false;
                });
                // on resume error
                this.onResumeError({
                    err: this.$pfFilestackSigner.simpleErrorMessage(),
                });
            },
        );
    }

    /**
     * Deletes resume for current user
     */
    deleteResume($event) {
        $event.preventDefault();
        $event.stopImmediatePropagation();

        if (!this.processResume) {
            this.onResumeUpdate({ resume: null });
            this.$timeout(() => {
                this.loading = false;
            });
            return;
        }

        const title = this.$filter('i18n')('Are you sure you want to delete your resume?');
        const ariaLabel = this.$filter('i18n')('Delete Resume');
        const ok = this.$filter('i18n')('Yes, delete');
        const cancel = this.$filter('i18n')('Cancel');

        const confirm = this.$mdDialog
            .confirm()
            .title(title)
            .ariaLabel(ariaLabel)
            .targetEvent($event)
            .ok(ok)
            .cancel(cancel);

        this.$mdDialog
            .show(confirm)
            .then(() => {
                return this.$pfProfileAbout
                    .deleteResume()
                    .then(() => {
                        this.onResumeUpdate({ resume: this.resume });
                        // Update the user's CI session
                        this.$pfUser.updateSession();
                    })
                    .catch(() => {
                        this.onResumeError({
                            err: 'Something went wrong, please try again.',
                        });
                    });
            })
            .catch(() => {});
    }
}

ResumeUploadController.$inject = [
    '$element',
    '$q',
    '$pfDropZone',
    '$pfResumeUpload',
    '$pfFilestack',
    '$pfFilestackPreset',
    '$pfFilestackConvert',
    '$pfFilestackSigner',
    '$timeout',
    '$pfProfileAbout',
    '$ngRedux',
    '$mdDialog',
    '$pfUser',
    '$filter',
];

/**
 * @ngdoc component
 * @name ResumeUploadComponent
 * @module portfolium.components.uploadResume
 * @param {Object} autofocus the autofocus target
 * @param {Boolean} privacy the privacy flag
 * @param {callback} processResume callback for process Resume
 * @param {callback} onResumeUpdate callback for upload success
 * @param {callback} onResumeError callback for upload error
 * @description component for drop zone for resumes on the profile page
 */
export const ResumeUploadComponent = {
    bindings: {
        autofocus: '<',
        privacy: '<',
        layoutColumn: '<',
        processResume: '<',
        resume: '<',
        deleteEnable: '<',
        onResumeUpdate: '&',
        onResumeError: '&',
    },
    controller: ResumeUploadController,
    controllerAs: '$ctrl',
    template: ResumeUploadTemplate,
};
