import angular from 'angular';

import './index.scss';

import { UserAvatarComponent } from './js/component/user-avatar.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';

import ImageWrapDirective from './js/directive/image-wrap-directive';
import imageFilter from './js/filter/image-filter';
import assetFilter from './js/filter/asset-filter';
import isDefaultImageFilter from './js/filter/is-default-image-filter';

const MODULE_NAME = 'portfolium.component.image';

(function() {
    angular
        .module(MODULE_NAME, [])
        // Component
        .component('pfUserAvatar', UserAvatarComponent)
        .component('pfImagePicker', ImagePickerComponent)
        // Directive
        .directive('pfImageWrap', ImageWrapDirective)
        // Filters
        .filter('image', imageFilter)
        .filter('asset', assetFilter)
        .filter('isDefaultImage', isDefaultImageFilter);
})();

export default MODULE_NAME;
