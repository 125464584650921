import _ from 'lodash';

import {
    UPDATE_ABOUTS,
    RESET_ABOUTS,
    ADD_ABOUT,
    ADD_MULTIPLE_ABOUTS,
    UPDATE_ABOUT,
    DELETE_ABOUT,
    REMOVE_ENTRY_FROM_ABOUTS,
} from '../action/abouts';

export const about = (state = [], action = {}) => {
    switch (action.type) {
        case ADD_ABOUT:
            return [
                ...state,
                action.payload
            ];
        case ADD_MULTIPLE_ABOUTS:
            return [
                ...state,
                ...action.payload.abouts,
            ];
        case UPDATE_ABOUT:
            return state.map(about => {
                if (action.id !== about.id) {
                    return about;
                }

                return _.assign({}, about, action.payload);
            });
        case DELETE_ABOUT:
            return state.filter(about => about.id !== action.id);
        case REMOVE_ENTRY_FROM_ABOUTS:
            return state.map((about) => {
                return _.assign({}, about, {
                    entries: about.entries.filter(entry => entry.id !== action.id),
                });
            });
        default:
            return state;
    }
};

export const abouts = (state = {}, action = {}) => {
    switch (action.type) {
        case UPDATE_ABOUTS:
            return action.payload;
        case RESET_ABOUTS:
            return {};
        case ADD_ABOUT:
        case ADD_MULTIPLE_ABOUTS:
        case UPDATE_ABOUT:
        case DELETE_ABOUT:
            return _.mapValues(state, (aboutCollection, sectionName) => {
                if (action.sectionName !== sectionName) {
                    return aboutCollection;
                }

                return about(aboutCollection, action);
            });
        case REMOVE_ENTRY_FROM_ABOUTS:
            return _.mapValues(state, (aboutCollection, sectionName) => {
                if (['order', 'skills',].indexOf(sectionName) > -1) {
                    // Skip non-regular sections
                    return about(aboutCollection);
                }

                return about(aboutCollection, action);
            });
        default:
            return state;
    }
};
