import {combineReducers} from 'redux';

import {getPaginationReducer} from 'components/pagination/pagination.reducer';
import {messagesActionTypes} from './messages.actions';
import {currentThread} from './thread/thread.reducer';

export const messages = combineReducers({
    currentThread,
    myThreads: getPaginationReducer({
        actionTypes: messagesActionTypes,
        limit: 10,
        paginationStyle: 'infinite',
    }),
});
