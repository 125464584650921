import PathwayRequirementSubmissionPanelListTemplate from './pathway-requirement-submission-panel-list.component.html';
import './pathway-requirement-submission-panel-list.component.scss';
import { getPaginationActions } from 'components/pagination/pagination.actions';

import {
    selectProject,
    pathwayRequirementSubmissionPanelActionTypes,
} from '../pathway-requirement-submission-panel.actions';

/**
 * @ngdoc controller
 * @name PathwayRequirementSubmissionPanelListController
 * @module portfolium.component.pathways.requirement.submissionPanel
 **/
class PathwayRequirementSubmissionPanelListController {
    constructor(
        $ngRedux,
        $mdMedia,
        $pfUser,
        $state,
        $pfEntriesFeed,
        $pfEntryDrafts,
        $pfEntrySearch,
        $pfEntryEditor,
        imageFilter
    ) {
        this.$ngRedux = $ngRedux;
        this.$mdMedia = $mdMedia;
        this.$pfUser = $pfUser;
        this.$state = $state;
        this.$pfEntriesFeed = $pfEntriesFeed;
        this.$pfEntryDrafts = $pfEntryDrafts;
        this.$pfEntrySearch = $pfEntrySearch;
        this.$pfEntryEditor = $pfEntryEditor;
        this.imageFilter = imageFilter;
        this.selectedId = null;
    }

    $onInit() {
        // set user
        this.user = this.$pfUser.getUser();
        // Configure pagination actions
        const actions = getPaginationActions({
            actionTypes: pathwayRequirementSubmissionPanelActionTypes,
            getResults: this.getEntries.bind(this),
            paginationStyle: 'infinite',
        });

        // Subscribe to state updates and map actions to the controller
        this._unsubscribe = this.$ngRedux.connect(
            this._mapStateToThis,
            actions
        )(this);

        // Fetch the first set of results
        this.initPage();
    }

    /**
      * @ngdoc method
      * @name getEntries
      * @description
      * Gets users entries
      * @return {Array<Object>} List of user entries
      */
    getEntries() {
        // Get entries
        return this.$pfEntrySearch
            .searchOwnEntries(this.pagination.query)
    }

    onSearchChange(query) {
        this.addKeyValueFilter('q', query);
    }

    goToEditor($event) {
        return this.$pfEntryEditor.newEntry({
            source: 'chooseExistingEntryForRequirement',
            targetEvent: $event,
        })
        .then(() => {
            // Finished creating entry and transitioning to entry wizard,
            // close the panel
            return this.onClose();
        });
    }

    isResultsEmpty() {
        // get the values
        const { isFetching, results } = this.pagination;
        // sanity check for fetching
        if (isFetching) {
            return false;
        }
        // return true if there are no results
        return results.length === 0;

    }

    isProjectsResultsEmpty() {
        // sanity check for results
        if (!this.isResultsEmpty()) {
            return false;
        }
        // check if there is any search going on
        return _.isNil(this.pagination.query.q);
    }

    isSearchResultsEmpty() {
        // sanity check for results
        if (!this.isResultsEmpty()) {
            return false;
        }
        // check if there is any search going on
        return !_.isNil(this.pagination.query.q);
    }

    getEmptyResultsTitle() {
        // get the query q
        let { q } = this.pagination.query;
        // sanity check for value
        if (_.isNil(q)) {
            // set the empty string
            q = '';
        }
        // send the message
        return `No projects found matching "${q}"`;
    }

    getProjectImage(project) {
        if (_.has(project, 'media[0].filename.url_https')) {
            return this.imageFilter(project.media[0].filename.url_https, 372, 661);
        }

        return this.imageFilter(`${this.URL_PREFIX.cdnUrl}/img/defaults/default.png`, 372, 661);
    }

    selectProject(projectId) {
        // chill brah, this project is already selected
        if (this.selectedId === projectId) {
            return;
        }
        // do this here so we dont wait the API
        this.selectedId = projectId;
        // set loading to true
        this.isContinueButtonLoading = true;
        // get draft from project
        this.$pfEntryDrafts
            .getSubmissionDraftByEntryId(projectId)
            .then(submissionDraft => {
                // select the draft
                this.$ngRedux.dispatch(selectProject(submissionDraft));
                // when in desktop
                if (this.$mdMedia('gt-sm')) {
                    // go to next step
                    this.onDone();
                }
                // set it baack to no loading
                this.isContinueButtonLoading = false;
            })
            .catch(() => {
                this.selectedId = null;
                // set it baack to no loading
                this.isContinueButtonLoading = false;
            });
    }

    isContinueButtonDisable() {
        return _.isEmpty(this.currentSubmissionDraft);
    }

    _mapStateToThis({
        pathways: {
            currentRequirement: {
                pathwayRequirementSubmissionPanel: {
                    pagination,
                    currentSubmissionDraft,
                },
            },
        },
    }) {
        return {
            pagination,
            currentSubmissionDraft,
        };
    }

    $onDestroy() {
        // Unsubscribe from state updates
        this._unsubscribe();

        // Clear search filter when closing modal
        this.resetFilters();
    }
}

PathwayRequirementSubmissionPanelListController.$inject = [
    '$ngRedux',
    '$mdMedia',
    '$pfUser',
    '$state',
    '$pfEntriesFeed',
    '$pfEntryDrafts',
    '$pfEntrySearch',
    '$pfEntryEditor',
    'imageFilter',
];

/**
 * @ngdoc component
 * @name PathwayRequirementSubmissionPanelComponent
 * @module portfolium.component.pathways.requirement.submissionPanel
 **/
export const PathwayRequirementSubmissionPanelListComponent = {
    bindings: {
        'onClose': '&',
        'onSelectProject': '&',
        'onDone': '&',
    },
    controller: PathwayRequirementSubmissionPanelListController,
    template: PathwayRequirementSubmissionPanelListTemplate,
}
