export const ASSIGNMENT_FETCH = 'ASSIGNMENT_FETCH';
export const ASSIGNMENT_FETCH_SUCCESS = 'ASSIGNMENT_FETCH_SUCCESS';
export const ASSIGNMENT_RESET = 'ASSIGNMENT_RESET';

/**
 * Initiates fetch assignment sets fetching to true
 * */
export const fetchAssignment = () => {
    return {
        type: ASSIGNMENT_FETCH,
    };
};

/**
 * Assigns and returns fetched assignment
 * */
export const onAssignmentFetchSuccess = assignment => {
    return {
        type: ASSIGNMENT_FETCH_SUCCESS,
        payload: {
            assignment,
        },
    };
};

/**
 * Resets assignment
 * */
export const resetAssignment = () => {
    return {
        type: ASSIGNMENT_RESET,
    };
};
