class TogglePopoverController
{
    constructor ($pfPopoverPanel, $templateCache) {
        this._$pfPopoverPanel = $pfPopoverPanel;
        this._$templateCache = $templateCache;
    }

    /**
     * @description Get correct template for passed in popover type
     * @param {String} popoverType Type of popover to use
     * @return {String} Template
     */
    getTemplate(popoverType) {
        let templateBase = 'template/components/popover/template';

        let template = this._$templateCache.get(`${templateBase}/popover-text.html`);

        if (_.isString(popoverType)) {
            template = this._$templateCache.get(`${templateBase}/${popoverType}.html`);
        }

        if (!template) {
            throw new Error('[pfPopover] Template not found!');
        }

        return template;
    }

    /**
     * Open the popover panel and set panel ref for closing
     * @param {Object} config Configuration params for popover
     * @param {String} [config.type] Type of popover template to use (if not default)
     */
    openPopover(config) {
        // if no template was passed in, get template from popover type
        if (_.isEmpty(config.template)) {
            // set template
            config.template = this.getTemplate(config.type);
        }

        return this._$pfPopoverPanel.open(config)
            .then((mdPanelRef) => {
                return this.mdPanelRef = mdPanelRef;
            });
    }

    /**
    * Close popover panel
    */
    closePopover(mdPanelRef = null) {
        if (mdPanelRef) {
            return mdPanelRef.close()
                .then(() => {
                    mdPanelRef.destroy();
                });
        }

        if (this.mdPanelRef) {
            this.mdPanelRef.close()
                .then(() => {
                    this.mdPanelRef.destroy();
                });
        }
    }
}

TogglePopoverController.$inject = ['$pfPopoverPanel', '$templateCache'];

/**
  * @description Shows panel containing passed in content on mouseover
  * @param {string} [pf-popover-text] Text to display *Use this or pf-popover-data*
  * @param {object} [pf-popover-data] Object containing profile info to display
  * @param {string} [pf-popover-type] Tell the popover which built in template to use.
  *                                   Use this or pf-popover-template, not both.
  *                                   Types available are in popover.constant POPOVER_TYPES
  * @param {string} [pf-popover-template] Custom template for popover to use.
  *                                       Can be a custom component <pf-custom-component></pf-custom-component>
  *                                       Use this or a pf-popover-type, not both.
  * @param {integer} [pf-popover-offset-y=4] Defines offsetY of popover (default 4px)
  *                                          panel by moving mouse from target element INTO the panel. Adds an exit delay of 300ms
  * @param {boolean} [pf-popover-fixed=false] Sets position of popover as fixed center/below target element
  *                                           to start on. Starting positions available are in popover.constant POPOVER_STARTING_POSITIONS
  * @param {boolean} [pf-popover-pointer=false] Tells popover to use pointer style template
  * @param {boolean} [pf-popover-enable-mobile=false] Allows popover use in mobile mode
  * @param {string}  [pf-popover-starting-position=bottom] Tells popover which side of the element
  * @param {boolean} [pf-popover-allow-panel-actions=false] Allows user to perform actions within
  * @param {boolean} [pf-popover-close-on-scroll=false] Popover will close on page scroll
  * @param {String}  [pf-popover-scroll-wrapper-selector=null] jQlite selector to the element that is scrollable
  *
  */
export const togglePopoverDirective = ($timeout, $window, $mdMedia) => {
    return {
        restrict: 'A',
        require: 'pfTogglePopover',
        controller: TogglePopoverController,
        link: (scope, element, attrs, ctrl) => {
            // initialize popover config options
            const {
                pfPopoverType:                  type,
                pfPopoverOffsetY:               offsetY,
                pfPopoverTemplate:              template,
                pfPopoverLinkTarget:            linkTarget,
                pfPopoverStartingPosition:      startingPosition,
                pfPopoverScrollWrapperSelector: scrollWrapperSelector,
            } = attrs;

            // initialize popover boolean attributes
            const fixed = _.has(attrs, 'pfPopoverFixed');
            const usePointer = _.has(attrs, 'pfPopoverPointer');
            const instantOpen = _.has(attrs, 'pfPopoverInstant');
            const enableMobile = _.has(attrs, 'pfPopoverEnableMobile');
            const allowPanelActions = _.has(attrs, 'pfPopoverAllowPanelActions');
            const closeOnScroll = _.has(attrs, 'pfPopoverCloseOnScroll');

            // exit if mobile is not enabled
            if (!enableMobile && $mdMedia('xs')) {
                return;
            }

            // default timeout open
            let timeoutPromise = null;
            let timeoutTime = 300;
            let exitTimeoutPromise = null;
            let exitTimeoutTime = 0;

            if (instantOpen) {
                timeoutTime = 0;
            }

            if (allowPanelActions) {
                exitTimeoutTime = 300;
            }

            element.on('mouseenter focus', () => {
                /**
                 * Set content for popover
                 * This is inside the mouseenter so we can get fresh data if it changes
                 */
                const content = attrs.pfPopoverText || scope.$eval(attrs.pfPopoverData);

                // clear timeout if still running
                if (timeoutPromise !== null) {
                    $timeout.cancel(timeoutPromise);

                    timeoutPromise = null;
                }

                timeoutPromise = $timeout(() => {
                    ctrl.openPopover({
                        type,
                        offsetY,
                        template,
                        linkTarget,
                        startingPosition,
                        fixed,
                        usePointer,
                        content,
                        element,
                    }).then((mdPanelRef) => {
                        /**
                         * Minimize md-panel-outer-wrapper
                         * Known bug, doing this prevents focus from being trapped on panel while open
                         * DON'T UNDO THIS!
                         * You will break panels on IE11 and have issues with panels opening in the top left corner of the page.
                         * https://github.com/angular/material/issues/8908
                         */
                        mdPanelRef.panelContainer.css({
                            height: '1px',
                            width: '1px',
                        });

                        $window.onblur = () => {
                            $timeout(() => {
                                ctrl.closePopover(mdPanelRef);
                            });
                        };

                        if (allowPanelActions) {
                            mdPanelRef.panelEl.on('mouseover focus', () => {
                                if (exitTimeoutPromise) {
                                    $timeout.cancel(exitTimeoutPromise);
                                }
                            });

                            mdPanelRef.panelEl.on('mouseleave blur', () => {
                                ctrl.closePopover();
                            });
                        }
                        // check if the close on scroll flag is on
                        if (closeOnScroll) {
                            // listen to page scroll
                            angular.element(scrollWrapperSelector).on('scroll', () => {
                                element.blur();
                            });
                        }
                    });
                }, timeoutTime);
            });

            element.on('mouseleave blur', () => {
                // clear timeout if still running
                if (timeoutPromise !== null) {
                    $timeout.cancel(timeoutPromise);

                    timeoutPromise = null;
                }

                exitTimeoutPromise = $timeout(() => {
                    ctrl.closePopover();
                }, exitTimeoutTime);
            });

            element.on('$destroy', () => {
                // clear timeout if still running
                if (timeoutPromise !== null) {
                    $timeout.cancel(timeoutPromise);

                    timeoutPromise = null;
                }

                $timeout(() => {
                    ctrl.closePopover();
                });
            });
        },
    };
};

togglePopoverDirective.$inject = ['$timeout', '$window', '$mdMedia'];
