import _ from 'lodash';

import {
    LOAD_USER_ENTRIES,
    ADD_USER_ENTRIES,
    RESET_USER_ENTRIES,
} from './user-entries.action';

export const initialState = {
    limit: 10,
    offset: 0,
    finished: false,
    loading: false,
    entries: [],
};

export const userEntries = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOAD_USER_ENTRIES:
            return _.assign({}, state, {loading: true});
        case ADD_USER_ENTRIES:
            return _.assign({}, state, {
                offset: state.offset + action.payload.length,
                finished: action.payload.length < state.limit,
                loading: false,
                entries: [
                    ...state.entries,
                    ...action.payload,
                ],
            });
        case RESET_USER_ENTRIES:
            return _.assign({}, initialState);
        default:
            return state;
    }
};
