import ListEntryCardTemplate from '../../template/list-entry-card.html';
import StandardEntryCardController from '../controller/standard-entry-card-controller.js';

function listEntryCardDirective() {
    return {
        restrict: 'E',
        require: ['ngModel'],
        scope: {
            showStrengthMeter: '=pfStrengthMeter',
            isEditable: '=pfEditable',
            onDeleteEvent: '&pfOnDelete',
        },
        bindToController: true,
        link: function(scope, elem, attrs, ctrls) {
            let ngModelCtrl = ctrls[0];

            ngModelCtrl.$render = function() {
                scope.entryCardCtrl.entry = ngModelCtrl.$viewValue || {};
            };
        },
        controller: StandardEntryCardController,
        controllerAs: 'entryCardCtrl',
        template: ListEntryCardTemplate,
    };
}

export default listEntryCardDirective;
