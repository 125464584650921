import angular from 'angular';
import ResumeUploadModalTemplate from './resume-upload-modal.component.html';
import './resume-upload-modal.component.scss';

class ResumeUploadModalController
{
    constructor(
        $http,
        $pfUser,
        $mdDialog,
        $ngRedux,
        $pfToast,
    ) {
        // Bind deps
        this.$http       = $http;
        this.$pfUser     = $pfUser;
        this.$mdDialog   = $mdDialog;
        this.$ngRedux    = $ngRedux;
        this.$pfToast    = $pfToast;

        this.user        = {};
        this.resume      = this.user.resume;
        this.loading     = false;
        this.uploaded    = false;
    }

    $onInit() {
        // Subscribe to changes
        this._unsubscribe = this.$ngRedux.connect(this._mapStateToThis)(this);
    }

    $onDestroy() {
        this._unsubscribe();
    }

    _mapStateToThis(state) {
        return {
            user: state.profile.profileUser,
        };
    }

    /**
     * @description Close the modal, rejecting the promise
     */
    close() {
        this.$mdDialog.cancel();
    }

    /**
     * Displays an error when the upload a resume files
     * @param  {string} err description of the error
     */
    onResumeError(err) {
        this.$pfToast.error(err);
    }

    /**
     * @description This is called when the upload is successful
     */
    onResumeUpdate() {
        // close the modal
        this.close();
        // show success message
        this.$pfToast.success('Resume was uploaded!');
        // call onSuccess callback
        this.onSuccess();
    }

    /**
     * Has the user set their resume to private?
     * @return {Boolean}
     */
    getResumePrivacy() {
        if (!this.user) {
            return false;
        }

        return this.user.resume_privacy === '1';
    }
}

ResumeUploadModalController.$inject = [
    '$http',
    '$pfUser',
    '$mdDialog',
    '$ngRedux',
    '$pfToast',
];

/**
 * @ngdoc component
 * @module portfolium.component.resume
 * @name pfResumeUploadModal
 * @description
 * Resume uploader
 */
export const ResumeUploadModalComponent = {
    bindings: {
        'onSuccess': '&pfOnSuccess',
    },
    controllerAs: '$ctrl',
    controller: ResumeUploadModalController,
    template: ResumeUploadModalTemplate,
};
