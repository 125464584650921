import PaginationFilterTypeTextTemplate from './pagination-filter-type-text.component.html';

/**
 * @ngdoc controller
 * @name PaginationFilterTypeTextController
 * @module portfolium.component.PaginationFilterTypeTextComponent
 */
class PaginationFilterTypeTextController {
    /**
    * @ngdoc method
    * @name PaginationFilterTypeTextController#handleChange
    * @methodOf PaginationFilterTypeTextController
    * @description changes callback
    */
    handleChange(){
        this.onChange({
            value: this.value,
            filterName: this.filterName,
        });
    }
}

/**
 * @ngdoc component
 * @name PaginationFilterTypeTextComponent
 * @module portfolium.component.PaginationFilterTypeTextComponent
 */
export const PaginationFilterTypeTextComponent = {
    bindings: {
        filterName: '@',
        filterPlaceholder: '@',
        value: '<',
        onChange: '&',
    },
    template: PaginationFilterTypeTextTemplate,
    controller: PaginationFilterTypeTextController,
    controllerAs: '$ctrl'
};
