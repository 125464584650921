// TODO Move this into entry-audit.service
/**
 * @ngdoc service
 * @name $pfTagTeammateReminder
 * @module portfolium.core.services
 * @description Service for checking a draft for potential collaborators
 * @class TagTeammateReminderService
 */
export class TagTeammateReminderService
{
    constructor(
        $q,
        $pfSpotlightPanel,
        $pfPhraseFinder,
    ) {
        this.$q = $q;
        this.$pfSpotlightPanel = $pfSpotlightPanel;
        this.$pfPhraseFinder = $pfPhraseFinder;
        this.defaultTitle = 'Is this a group project?';
        this.defaultMsg = `
            It looks like you may have worked on this project with a team.
            Boost your project’s strength and gain additional exposure by
            adding the people you worked with.
        `;
    }

    /**
     * Open the tag teammate reminder panel
     * @param   {Object}  args  The properties that are passed to $pfSpotlightPanel._createPanel()
     * @return  {Void}
     */
    open(args) {
        if (!args.title) {
            args.title = this.defaultTitle;
        }
        if (!args.message) {
            args.message = this.defaultMsg;
        }
        this.$pfSpotlightPanel.open(args);
    }

    /**
     * @description Determine if we should ask the user to tag teammates
     * @param  {Object}  draft    The project draft
     * @param  {Object}  options  The options for the dialog
     * @return {Promise} resolved value determines if the dialog was shown
     */
    shouldTagTeammates(draft, options = {}) {
        const deferred = this.$q.defer();
        // ask to tag teammates if no teammates are already tagged
        if (!_.isEmpty(draft.collaborators)) {
            // proceed
            deferred.resolve(false);
        } else {
            // search title and description
            const hasCollabDetails = this.$pfPhraseFinder.match([draft.title, draft.description], 'PHRASES_TAG_TEAMMATES');
            // search skills
            const skills = _.map(draft.skill_list, 'skill');
            const hasCollabSkills = this.$pfPhraseFinder.match(skills, 'SKILLS_TAG_TEAMMATES');
            // did we detect any indication that another teammate may be involved?
            const hasCollab = hasCollabDetails || hasCollabSkills;

            // if so, open the dialog
            if (hasCollab) {
                this.open(_.assign({}, {
                    submitText: 'Add Teammates',
                    cancelText: 'No Thanks',
                    onSubmit: () => deferred.reject(),
                    onCancel: () => deferred.resolve(true),
                }, options));
            } else {
                // no teammates found, let's proceed
                deferred.resolve(false);
            }
        }
        return deferred.promise;
    }
}

TagTeammateReminderService.$inject = [
    '$q',
    '$pfSpotlightPanel',
    '$pfPhraseFinder',
];
