export const OPEN_NOTIFICATION_CENTER_PANEL = 'OPEN_NOTIFICATION_CENTER_PANEL';
export const CLOSE_NOTIFICATION_CENTER_PANEL = 'CLOSE_NOTIFICATION_CENTER_PANEL';
export const TOGGLE_NOTIFICATION_CENTER_PANEL_TAB = 'TOGGLE_NOTIFICATION_CENTER_PANEL_TAB';

export const NOTIFICATION_CENTER_PANEL_TABS = {
    NOTIFICATIONS: 'NOTIFICATIONS',
    MESSAGES: 'MESSAGES',
};

export const openNotificationCenterPanel = (payload) => {
    return {
        type: OPEN_NOTIFICATION_CENTER_PANEL,
        payload,
    };
};

export const closeNotificationCenterPanel = (payload) => {
    return {
        type: CLOSE_NOTIFICATION_CENTER_PANEL,
        payload,
    };
};

export const toggleNotificationCenterPanelTab = (tabName) => {
    return {
        type: TOGGLE_NOTIFICATION_CENTER_PANEL_TAB,
        tabName,
    };
};
