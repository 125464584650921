import angular from 'angular';

import './index.scss';

import {NetworkConnectComponent} from './network-connect.component';
import {NetworkConnectService} from './network-connect.service';

const MODULE_NAME = 'portfolium.component.networkConnect';

(function () {
    angular.module(MODULE_NAME, [])
        .service('$pfNetworkConnect', NetworkConnectService)
        .component('pfNetworkConnectBtn', NetworkConnectComponent);
}());

export default MODULE_NAME;
