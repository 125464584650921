export class LazyLoadService {
    constructor($ocLazyLoad, $pfEnv) {
        this.$ocLazyLoad = $ocLazyLoad;
        this.$pfEnv = $pfEnv;
        this.loaded = {};
        this.assetsPath = $pfEnv.getEnv('PF_ASSETS_URL');
    }

    /**
     * Check is a module has been loaded
     * @param  {String}  moduleName Name of the module to load
     * @return {Boolean}
     */
    isModuleLoaded(moduleName = '') {
        return this.loaded[moduleName] === true;
    }

    /**
     * Lazy load a module's assets
     * @param  {String|Array<String>} module Name of the module(s) to load
     * @return {Promise}                     Resolved once module has been loaded
     */
    load(module) {
        // Convert string to array if only loading single module
        const modules = (_.isArray(module)) ? [...module] : [module];
        // Build filenames using module names
        const fileNames = modules.map((moduleName) => {
            if (this.$pfEnv.isProduction || this.$pfEnv.isQa || this.$pfEnv.isDev || this.$pfEnv.isCloudgate) {
                // Append webpack hash to module name for prod/qa/dev (or in cloudgate environments)
                return `${moduleName}-${__webpack_hash__}`;
            }

            return moduleName;
        });
        // Map filenames to include JS & CSS
        const files = _.flatMap(fileNames, (fileName) => {
            return [
                `${this.assetsPath}build/${fileName}.js`,
                `${this.assetsPath}build/${fileName}.css`,
            ];
        });

        // Fetch the module assets
        return this.$ocLazyLoad.load({files})
            .then(() => {
                _.forEach(modules, (module) => {
                    // Set a flag to indicate the module(s) have been loaded
                    this.loaded[module] = true;
                });
            }, (error) => {
                // Handle exceptions
                throw new Error(error);
            });
    }
}

LazyLoadService.$inject = [
    '$ocLazyLoad',
    '$pfEnv',
];
