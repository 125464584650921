/**
 * @ngdoc controller
 * @name ExportProfileToggleController
 * @module portfolium.component.exportProfile
 **/
class ExportProfileToggleController {
    constructor($element, $pfExportProfilePanel) {
        this.$element = $element;
        this.$pfExportProfilePanel = $pfExportProfilePanel;
    }

    $postLink() {
        this.$element.on('click', this.onClick.bind(this));
    }

    /**
      * @ngdoc method
      * @name openPanel
      * @description
      * Open export profile panel
      * @return {Promise}
      */
    onClick() {
        return this.$pfExportProfilePanel.open();
    }
}

ExportProfileToggleController.$inject = ['$element', '$pfExportProfilePanel'];

/**
 * @ngdoc directive
 * @name exportProfileToggleDirective
 * @module portfolium.component.exportProfile
 **/
 export const exportProfileToggleDirective = () => {
    return {
        restrict: 'A',
        controller: ExportProfileToggleController,
        controllerAs: '$ctrl',
        bindToController: true,
    };
};
