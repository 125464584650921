import angular from 'angular';

import app from './app';

const MODULE_NAME = 'portfolium.legacy';

(function () {
    angular.module(MODULE_NAME, [
        /**
         * Internal Dependencies
         */
        app,
    ]);

    // Bootstrap the app
    angular.bootstrap(document, [MODULE_NAME], {
        strictDi: true,
    });
}());
