export const LOAD_USER_ENTRIES = 'LOAD_USER_ENTRIES';
export const ADD_USER_ENTRIES = 'ADD_USER_ENTRIES';
export const RESET_USER_ENTRIES = 'RESET_USER_ENTRIES';

export const loadUserEntries = () => {
    return {
        type: LOAD_USER_ENTRIES,
    };
};

export const addUserEntries = (entries) => {
    return {
        type: ADD_USER_ENTRIES,
        payload: entries,
    };
};

export const resetUserEntries = () => {
    return {
        type: RESET_USER_ENTRIES,
    };
};
