export const USER_TYPES = [
    {
        id: 1,
        name: 'undergrad',
        displayName: 'Undergraduate Student'
    },
    {
        id: 2,
        name: 'grad',
        displayName: 'Graduate Student'
    },
    {
        id: 3,
        name: 'communitycollege',
        displayName: 'Community College Student'
    },
    {
        id: 4,
        name: 'k12',
        displayName: 'K12 Student'
    },
    {
        id: 5,
        name: 'alumni',
        displayName: 'Alumni'
    },
    {
        id: 6,
        name: 'professor',
        displayName: 'Faculty Member'
    },
    {
        id: 7,
        name: 'employer',
        displayName: 'Employer'
    },
    {
        id: 8,
        name: 'staff',
        displayName: 'School Administrator'
    },
    {
        id: 0,
        name: 'unknown',
        displayName: 'None of the Above'
    }
];
