function megaNumberFilter() {
    /**
     * Format large numbers into shortened abbreviations
     * Based on gist: https://gist.github.com/timgit/7bc5896f5297301afb02
     * @param  {Number|String} input        Number to be converted
     * @param  {Number}        fractionSize Decimal places to round to
     * @return {String}                     Formatted mega number
     */
    return function (input, fractionSize) {
        let number = parseInt(input, 10);

        if (number === 0) {
            return '0';
        }

        if (!fractionSize || fractionSize < 0) {
            fractionSize = 1;
        }

        let abs = Math.abs(number);
        let rounder = Math.pow(10, fractionSize);
        let isNegative = number < 0;
        let key = '';
        let powers = [
            {key: 'Q', value: Math.pow(10,15)},
            {key: 'T', value: Math.pow(10,12)},
            {key: 'B', value: Math.pow(10,9)},
            {key: 'M', value: Math.pow(10,6)},
            {key: 'K', value: 1000}
        ];

        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;

            reduced = Math.round(reduced * rounder) / rounder;

            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key;
                break;
            }
        }

        return (isNegative ? '-' : '') + abs + key;
    };
}

function ordinalNumberFilter(numberFilter) {
    /**
     * Convert number to ordinal number
     * Based on: http://ecommerce.shopify.com/c/ecommerce-design/t/ordinal-number-in-javascript-1st-2nd-3rd-4th-29259
     * @param  {Number|String} input Number to be converted
     * @return {String}              Formatted ordinal number
     */
    return function (input) {
        let number = parseInt(input, 10);
        let superscripts = ['th', 'st', 'nd', 'rd'];
        let remainder = number % 100;

        if (number === 0) {
            return '0';
        }

        return numberFilter(number) + (superscripts[(remainder - 20) % 10] || superscripts[remainder] || superscripts[0]);
    };
}

ordinalNumberFilter.$inject = ['numberFilter'];

export {
    megaNumberFilter,
    ordinalNumberFilter
};
