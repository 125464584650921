import angular from 'angular';

import {LiveProgressBarComponent} from './live-progress-bar.component';

const MODULE_NAME = 'portfolium.components.liveProgressBar';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfLiveProgressBar', LiveProgressBarComponent)
}());

export default MODULE_NAME;
