import angular from 'angular';

class ToastService {
    constructor($window, $mdToast, $filter) {
        this.globals = $window.Portfolium || {};
        this.$mdToast = $mdToast;
        this.$filter = $filter;
    }

    /**
     * Show a simple toast with deafult configuration values
     * @param  {String}  type    Type of toast to display
     * @param  {String}  msg     Message to display
     * @param  {Object}  options Configuration object
     * @return {Promise}         Promise that can be resolved with $pfToast.hide() or rejected with $pfToast.cancel()
     */
    showSimple(
        type,
        msg = 'Something went wrong',
        options,
        translationParams = null,
    ) {
        const cleanedMsg = msg.replace(/<.*?>/g, '');
        const textContent = this.$filter('i18n')(cleanedMsg, translationParams);
        const defaults = {
            textContent,
            hideDelay: 4000,
            position: 'bottom left',
            theme: type + '-toast',
        };

        if (options) {
            // Extend default options with options passed in
            angular.extend(defaults, options);
        }
        // Show the toast and return promise
        return this.$mdToast.showSimple(defaults);
    }

    /**
     * Hide the existing toast and resolve promise returned from $pfToast.show()
     * @return {Promise} Promise that is called when the existing element is removed from the DOM
     */
    hide() {
        return this.$mdToast.hide();
    }

    /**
     * Hide the existing toast and reject the promise returned from $pfToast.show()
     * @return {Promise} Promise that is called when the existing element is removed from the DOM
     */
    cancel() {
        return this.$mdToast.cancel();
    }

    /**
     * Alias to $pfToast.showSimple() for a simple error toast
     * @param  {String} msg     Message to display
     * @param  {Object} options Configuration object
     * @return {Promise}        Promise that can be resolved with $pfToast.hide() or rejected with $pfToast.cancel()
     */
    error(msg, options, translationParams) {
        return this.showSimple('error', msg, options, translationParams);
    }

    /**
     * Alias to $pfToast.showSimple() for a simple success toast
     * @param  {String} msg Message to display
     * @param  {Object} options Configuration object
     * @return {Promise}        Promise that can be resolved with $pfToast.hide() or rejected with $pfToast.cancel()
     */
    success(msg, options, translationParams) {
        return this.showSimple('success', msg, options, translationParams);
    }

    /**
     * Show a toast with the data stored in CI's session flashdata
     * @param  {String} key Flash data key name
     */
    showFlashData(key) {
        let data = this.globals.flashData && this.globals.flashData[key];

        if (data) {
            this.showSimple(data.alert_type, data.alert, {
                hideDelay: 4000,
            });
        }
    }
}

ToastService.$inject = ['$window', '$mdToast', '$filter'];

export default ToastService;
