import _ from 'lodash';

import {
    OPEN_ABOUT_PANEL,
    CLOSE_ABOUT_PANEL,
    ABOUT_PANEL_SAVE_FORM,
    ABOUT_PANEL_ATTACH_ENTRY,
    ABOUT_PANEL_DETACH_ENTRY,
    ABOUT_PANEL_UPDATE_ENTRIES,
    ABOUT_PANEL_UPDATE_ENTRIES_ORDER,
    ABOUT_PANEL_UPDATE_SEARCH_RESULTS,
    ABOUT_PANEL_UPDATE_ENTRY_LIST,
    ABOUT_PANEL_REORDER_ENTRY_LIST,
    ABOUT_PANEL_CLEAR_SEARCH_RESULTS,
    ABOUT_PANEL_TOGGLE_ORDERING,
    TOGGLE_ABOUT_PANEL_TAB,
} from '../action/profile-about-panel';

export const initialState = {
    open: false,
    activeTab: 'EDIT_DETAILS',
    tabs: [
        {
            name: 'EDIT_DETAILS',
            displayName: 'Edit Details',
            icon: 'edit',
            active: true,
        },
        {
            name: 'WORK_SAMPLES',
            displayName: 'Projects',
            icon: 'playlist_add',
            active: false,
        },
    ],
    title: null,
    label: null,
    sectionName: null,
    aboutType: null,
    data: {},
    entryList: [],
    searchText: '',
    searchResults: [],
    showSearchResults: false,
    enableOrdering: false,
};

export const profileAboutPanel = (state = initialState, action = {}) => {
    switch (action.type) {
        case OPEN_ABOUT_PANEL:
            return _.assign({}, state, {
                open: true,
                title: action.payload.title,
                label: action.payload.label,
                sectionName: action.payload.sectionName,
                aboutType: action.payload.aboutType,
                activeTab: action.payload.activeTab,
                tabs: state.tabs.map((tab) => {
                    return _.assign({}, tab, {
                        active: tab.name === action.payload.activeTab,
                    });
                }),
                data: action.payload.data,
                entryList: action.payload.data.entries || [],
            });
        case CLOSE_ABOUT_PANEL:
            return _.assign({}, initialState);
        case TOGGLE_ABOUT_PANEL_TAB:
            return _.assign({}, state, {
                activeTab: action.tabName,
                tabs: state.tabs.map((tab) => {
                    return _.assign({}, tab, {active: tab.name === action.tabName});
                }),
            });
        case ABOUT_PANEL_SAVE_FORM:
        case ABOUT_PANEL_ATTACH_ENTRY:
        case ABOUT_PANEL_DETACH_ENTRY:
        case ABOUT_PANEL_UPDATE_ENTRIES:
        case ABOUT_PANEL_UPDATE_ENTRIES_ORDER:
            return _.assign({}, state, {
                data: profileAboutPanelData(state.data, action),
            });
        case ABOUT_PANEL_UPDATE_SEARCH_RESULTS:
            return _.assign({}, state, {
                searchResults: action.payload,
                showSearchResults: true,
            });
        case ABOUT_PANEL_CLEAR_SEARCH_RESULTS:
            return _.assign({}, state, {
                searchText: '',
                searchResults: [],
                showSearchResults: false,
            });
        case ABOUT_PANEL_UPDATE_ENTRY_LIST:
            const newEntries = action.payload
                .filter(entry => !_.find(state.entryList, {id: entry.id}));

            return _.assign({}, state, {
                entryList: [
                    ...state.entryList,
                    ...newEntries,
                ],
            });
        case ABOUT_PANEL_REORDER_ENTRY_LIST:
            const filteredEntryList = state.entryList
                .filter(entry => !_.find(state.data.entries, {id: entry.id}));

            return _.assign({}, state, {
                entryList: [
                    ...state.data.entries,
                    ...filteredEntryList,
                ],
            });
        case ABOUT_PANEL_TOGGLE_ORDERING:
            return  _.assign({}, state, {
                enableOrdering: !state.enableOrdering,
                searchText: '',
                searchResults: [],
                showSearchResults: false,
            });
        default:
            return state;
    }
};

const profileAboutPanelData = (state = {}, action = {}) => {
    switch (action.type) {
        case ABOUT_PANEL_SAVE_FORM:
            return _.assign({}, state, action.payload);
        case ABOUT_PANEL_ATTACH_ENTRY:
        case ABOUT_PANEL_DETACH_ENTRY:
            return _.assign({}, state, {
                skills: action.payload.skills,
                entries: profileAboutPanelEntries(state.entries, {
                    type: action.type,
                    payload: action.payload.entry,
                }).map((entry, index) => {
                    return _.assign({}, entry, {bucketOrder: index + 1});
                }),
            });
        case ABOUT_PANEL_UPDATE_ENTRIES:
            return _.assign({}, state, {
                entries: action.payload.map((entry, index) => {
                    return _.assign({}, entry, {
                        bucketOrder: index + 1,
                    });
                }),
            });
        case ABOUT_PANEL_UPDATE_ENTRIES_ORDER:
            return _.assign({}, state, {
                entries: _.orderBy(state.entries.map((entry) => {
                    return _.assign({}, entry, {
                        bucketOrder: parseInt(action.payload[entry.id], 10),
                    });
                }), ['bucketOrder'], ['asc']),
            });
        default:
            return state;
    }
};

const profileAboutPanelEntries = (state = [], action = {}) => {
    switch (action.type) {
        case ABOUT_PANEL_ATTACH_ENTRY:
            return [
                ...state,
                action.payload,
            ];
        case ABOUT_PANEL_DETACH_ENTRY:
            return state.filter((entry) => {
                return entry.id !== action.payload.id;
            });
        default:
            return state;
    }
};
