import BreadcrumbsTemplate from './breadcrumbs.component.html';
import './breadcrumbs.component.scss';

/**
 * @ngdoc component
 * @name BreadcrumbsComponent
 * @module portfolium.components
 */
export const BreadcrumbsComponent = {
    template: BreadcrumbsTemplate,
    bindings: {
        pfLabel: '@?',
        pfRoute: '<?',
        pfLoading: '<?'
    }
};
