import angular from 'angular';

/**
  * @ngdoc service
  * @name EntryAudit
  * @module portfolium.component.entry-audit.EntryAudit
  * @description
  * Audit uploaded files with certain properties and take action, depending on the outcome
  */
export class EntryAuditService {
    /**
      * @ngdoc method
      * @name EntryAudit#constructor
      * @methodOf EntryAudit
      * @description
      * Set properties of the controller
      */
    constructor(
        $q,
        $pfFilestackOptions,
        $pfSpotlightPanel,
    ) {
        this.$q = $q;
        this.$pfFilestackOptions = $pfFilestackOptions;
        this.$pfSpotlightPanel = $pfSpotlightPanel;
    }

    /**
      * @ngdoc method
      * @name EntryAudit#matchTranscriptAndConfirm
      * @methodOf EntryAudit
      * @description This is a wrapper for matchAndConfirm
      * @param  {Object}                options  Same options as matchAndConfirm
      * @return {Promise}  The promise is rejected if there is no match or the user clicks the 'decline' button.
      *                    The promise is resolved if matched and the user clicks the 'accept' button.
      */
    matchTranscriptAndConfirm(options = {}) {
        return this.matchAndConfirm(
            _.assign({
                patterns: ['transcript'],
                submitText: 'Continue',
                cancelText: 'Discard',
                title: 'Uploading a transcript?',
                message: `Looks like you're trying to add a transcript as a project.
                    Please be mindful of exposing your personal information provided on your transcript.
                    If you want to hide your transcript from others,
                    <a href="https://community.canvaslms.com/docs/DOC-17850" target="_blank">you can change the privacy setting on the third step</a>`,
            }, options));
    }

    /**
      * @ngdoc method
      * @name EntryAudit#matchResumeAndConfirm
      * @methodOf EntryAudit
      * @description This is a wrapper for matchAndConfirm
      * @param  {Object}                options  Same options as matchAndConfirm
      * @return {Promise}  The promise is rejected if there is no match or the user clicks the 'decline' button.
      *                    The promise is resolved if matched and the user clicks the 'accept' button.
      */
    matchResumeAndConfirm(options = {}) {
        const allowedTypes = this.$pfFilestackOptions.getAcceptedTypes('resume');
        return this.matchAndConfirm(
            _.assign({
                patterns: [
                    'resume',
                    'curriculum vitae',
                    'curriculumvitae',
                    'curriculum_vitae',
                    'cv',
                ],
                allowedTypes: allowedTypes,
                submitText: 'Add to my profile',
                cancelText: 'No thanks',
                title: 'Uploading a resume?',
                message: `Looks like you're trying to add your resume to your project.
                    Instead, try uploading the resume to your profile. This will make it easier for employers to find it, and can save you time by importing your jobs, skills, and other content automatically.`,
            }, options));
    }

    /**
      * @ngdoc method
      * @name EntryAudit#matchAndConfirm
      * @methodOf EntryAudit
      * @description If the string matches the pattern, open a dialog to ask the user a question.
      *              The users answer will determine how to proceed.
      *              Optionally, if type and allowedTypes are set, the type MUST match one of the allowedTypes
      * @param  {Object}                options  The options as follows
      * @param  {String}                options.string  The string to earch for a match
      * @param  {String|Array<String>}  options.patterns  The pattern used to match. This can be a string, regex, or an array of either
      * @param  {String}                options.type  The type of the file
      * @param  {Array<String>}         options.allowedTypes  The type needs to match one of these
      * @param  {String}                options.title  The title of the dialog
      * @param  {String}                options.message  The message in the dialog
      * @param  {String}                options.submitText  The text in the submit button
      * @param  {String}                options.cancelText  The text in the cancel button
      * @return {Promise}  The promise is rejected if there is no match or the user clicks the 'decline' button.
      *                    The promise is resolved if matched and the user clicks the 'accept' button.
      */
    matchAndConfirm({
        string,
        patterns,
        type,
        allowedTypes,
        title,
        message,
        submitText,
        cancelText,
    }) {
        return this.match({
            string,
            patterns,
            type,
            allowedTypes
        }).then(() => {
            const deferred = this.$q.defer();
            this.$pfSpotlightPanel.open({
                title,
                message,
                submitText,
                cancelText,
                onSubmit: () => deferred.resolve(),
                onCancel: () => deferred.reject(),
            });
            return deferred.promise;
        });
    }

    /**
      * @ngdoc method
      * @name EntryAudit#match
      * @methodOf EntryAudit
      * @description Check if the string matches the pattern
      *              Optionally, if type and allowedTypes are set, the type MUST match one of the allowedTypes
      * @param  {Object}                options  The arguments as follows
      * @param  {String}                options.string  The string to earch for a match
      * @param  {String|Array<String>}  options.patterns  The pattern used to match. This can be a string, regex, or an array of either
      * @param  {String}                options.type  The type of the file
      * @param  {Array<String>}         options.allowedTypes  The type needs to match one of these
      * @return {Promise}  The promise is rejected if there is no match or resolved if matched.
      */
    match({
        string,
        patterns,
        type,
        allowedTypes,
    }) {
        let pattern = patterns || null;
        if (_.isArray(patterns)) {
            pattern = patterns.join('|');
        }

        let hasMatch = false;

        if (!_.isEmpty(pattern)) {
            // search string
            const re = new RegExp(pattern, 'gi');
            const matched = string.search(re);
            if (matched >= 0) {
                hasMatch = true;
            }
        }

        // only check type if the string matched the pattern
        if (hasMatch && !_.isEmpty(type) && !_.isEmpty(allowedTypes)) {
            if (_.indexOf(allowedTypes, type) === -1) {
                // type was not found in allowedTypes
                hasMatch = false;
            }
        }

        if (hasMatch) {
            return this.$q.resolve({matched: hasMatch});
        }

        return this.$q.reject({matched: hasMatch});
    }
}

EntryAuditService.$inject = [
    '$q',
    '$pfFilestackOptions',
    '$pfSpotlightPanel',
];
