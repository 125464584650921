export const NETWORK_TYPES = [
    {id: '1', title:'University', value: 'university'},
    {id: '2', title:'Department', value: 'department'},
    {id: '3', title:'Accelerator', value: 'accelerator'},
    {id: '4', title:'K12 School', value: 'k12'},
    {id: '5', title:'Honors', value: 'honors'},
    {id: '6', title:'Company', value: 'company'},
    {id: '7', title:'Mentor', value: 'mentor'},
    {id: '8', title:'Research', value: 'research'},
    {id: '9', title:'Club', value: 'club'},
    {id: '10', title:'Bootcamp', value: 'cootcamp'},
    {id: '11', title:'Community College', value: 'communitycollege'},
    {id: '12', title:'System', value: 'system'},
    {id: '13', title:'Trade', value: 'trade'}
];
