import $script from 'scriptjs';
/**
 * @ngdoc service
 * @name LiveAddressService
 * @module portfolium.component.LiveAddressService
 * @description
 * Creates Address form pulling information from GoogleAPI
 *
 */
export class LiveAddressService {
    /**
    * @ngdoc method
    * @name LiveAddressService#constructor
    * @methodOf LiveAddressService
    * @description
    * Set q and sets the current View so it can be
    */
    constructor($q) {
        this.$q = $q;
        this.searchText = '';
        $script.ready('googleMaps', () => {
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.geocoder = new google.maps.Geocoder();
        });
    }

    /**
    * @ngdoc method
    * @name LiveAddressService#getGoogleSuggestions
    * @methodOf LiveAddressService
    * @description get Address from Google API
    * @param {String} street string to look for similarities
    * @return {Promise<Object>} Promise resolved with a google address object
    */
    getGoogleSuggestions(street, onlyCities = false) {
        if (!street) {
            return;
        }
        let deferred = this.$q.defer();

        let options = {
            'input': street,

            'types': (onlyCities) ? ['(cities)'] : ['address']
        };

        this.autocompleteService
            .getPlacePredictions(options, (response, status)=>{
                if (status === 'OK') {
                    deferred.resolve(response);
                }
            }
        );
        return deferred.promise;
    }

    /**
    * @ngdoc method
    * @name LiveAddressService#getGoogleSuggestions
    * @methodOf LiveAddressService
    * @description after the Google API gets suggestions, with the address
    * result have to look for the rest of the information using, again, the
    * Google API geocode
    * @param {Object} address string to look for similarities
    * @return {Promise<Object>} Promise resolved with a google geocode object
    */
    updateStreet(address) {
        let deferred = this.$q.defer();
        this.geocoder.geocode({'placeId': address.place_id}, function(response) {
          deferred.resolve(response[0]);
        });
        return deferred.promise;
    }
}

LiveAddressService.$inject = ['$q'];
