const MEMO_NAME = 'pf.navbar.returnUrl';

export class NavbarService {
    constructor($q, $window, $state, $transitions) {
        this.$q = $q;
        this.$window = $window;
        this.$state = $state;
        this._previousState = null;

        // Make sure return URL is cleared on successfull state change
        $transitions.onSuccess({}, trans => {
            const toState = trans.to();
            const toParams = trans.params('to');
            const fromState = trans.from();
            const fromParams = trans.params('from');

            if (!fromState.$$state) {
                return;
            }

            let toStateObj = toState.$$state();
            let fromStateObj = fromState.$$state();

            if (fromStateObj.includes.profile && toStateObj.includes.profile) {
                if (fromParams.username === toParams.username) {
                    return;
                }
            }

            this.setPreviousState(fromState);

            // don't clear previous state if the EDP is opened
            if (
                fromStateObj.includes.entryDetailsPage ||
                toStateObj.includes.entryDetailsPage
            ) {
                return;
            }

            this.clearPreviousState();
        });
    }

    get previousState() {
        return this._previousState;
    }

    setPreviousState(prevState) {
        this._previousState = prevState;
    }

    clearPreviousState() {
        this._previousState = null;
    }

    goToPreviousState() {
        this._previousState.go(MEMO_NAME).then(() => {
            this.clearPreviousState();
        });
    }
}

NavbarService.$inject = ['$q', '$window', '$state', '$transitions'];
