import AttachmentDownloadTemplate from './attachment-download.component.html';

/**
 * @ngdoc controller
 * @name AttachmentDownloadController
 * @module portfolium.component.mediaSlider
 **/
class AttachmentDownloadController {
    constructor(
        $pfAttachmentViewer,
        $pfFilestackSigner,
    ) {
        this.$pfAttachmentViewer = $pfAttachmentViewer;
        this.$pfFilestackSigner = $pfFilestackSigner;
    }

    $onInit() {

        // need this
        let unsignedUrl = this.getURL();
        // get the read policy here and put into the url
        this.$pfFilestackSigner.getReadPolicy(unsignedUrl).then(security => {
            // set the url
            this.url = this.$pfFilestackSigner.signUrl(unsignedUrl, security);
        });

        // set the assignment here and get going
        this.isAssignment = !_.isNil(this.isAssignment) ? this.isAssignment : false;
    }

    /**
     * Get the URL of the file
     *
     * @return {string|undefined} The URL
     * @memberof AttachmentDownloadController
     */
    getURL() {
        return this.$pfAttachmentViewer.getDocumentUrl(this.attachment);
    }

    /**
     * Get the mimetype of the file
     *
     * @return {string|undefined} The mimetype
     * @memberof AttachmentDownloadController
     */
    getMimetype() {
        return this.$pfAttachmentViewer.getMimeType(this.attachment);
    }
}

AttachmentDownloadController.$inject = [
    '$pfAttachmentViewer',
    '$pfFilestackSigner',
];

/**
 * @ngdoc component
 * @name AttachmentDownloadComponent
 * @module portfolium.component.mediaSlider
 * @description Attachment viewer to download documents that don't have a supported viewer
 *
 * @param {object}   pfAttachment The attachment object
 * @param {boolean}  pfIsAssignment A flag used to determine if the current project is an assignment
 * @param {boolean}  pfIsMobile   A flag used to determine if the browser is on a mobile device
 **/
export const AttachmentDownloadComponent = {
    bindings: {
        attachment: '<pfAttachment',
        isAssignment: '<pfIsAssignment',
        isMobile: '<pfIsMobile',
    },
    controller: AttachmentDownloadController,
    controllerAs: '$ctrl',
    template: AttachmentDownloadTemplate,
};
