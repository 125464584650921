import {EntryViewerListController} from '../entry-viewer-list.controller';
import EntryViewerLikersTemplate from './entry-viewer-likers.component.html';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerLikersController
 */
class EntryViewerLikersController extends EntryViewerListController {
    constructor(
        $element,
        $timeout,
        PF_USER_CARD_TEMPLATES,
        PF_POPOVER_STARTING_POSITIONS,
    ) {
        super($element, $timeout);
        this.PF_USER_CARD_TEMPLATES = PF_USER_CARD_TEMPLATES;
        this.PF_POPOVER_STARTING_POSITIONS = PF_POPOVER_STARTING_POSITIONS;
    }

    /**
     * Triggers callback for on view liker and redirects to tags
     * @param  {object} liker
     */
    viewliker(liker) {
        const event = {
            title: 'Liker Selected',
            properties: {
                sourceName: liker.username,
                clickSource: 'prEDP Sidebar',
                userAction: 'click',
            },
        };
        this.onViewLiker({ event });
    }
}

EntryViewerLikersController.$inject = [
    '$element',
    '$timeout',
    'PF_USER_CARD_TEMPLATES',
    'PF_POPOVER_STARTING_POSITIONS',
];

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerLikersComponent
 * @description Display list of likers in the entry viewer sidebar
 *
 * @param {boolean} isPreview Is the user viewing a preview?
 * @param {object[]} likers List of likers objects
 * @param {number} likerCount Count of the total likers
 * @param {number} listLength How many items are in the list
 * @param {callback} onViewLiker trigger when a liker is clicked
 */
export const EntryViewerLikersComponent = {
    bindings: {
        isPreview: '<',
        likers: '<',
        likerCount: '<',
        listLength: '<',
        onViewLiker : '&',
    },
    controller: EntryViewerLikersController,
    controllerAs: '$ctrl',
    template: EntryViewerLikersTemplate,
};
