/**
 * Set the section name on state change success. This name is displayed in the navbar
 */
export const setSectionNameHook = ($transitions, $pfSectionName) => {
    $transitions.onSuccess({}, trans => {
        const toState = trans.to();

        const sectionName = toState.data && toState.data.sectionName;

        if (!sectionName) {
            // If state contains no name data, don't update value
            return;
        }

        // Update the value
        $pfSectionName.setName(sectionName);
    });
};

setSectionNameHook.$inject = ['$transitions', '$pfSectionName'];
