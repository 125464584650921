class MessageBtnController
{
    constructor($pfSubscriptionActions, $pfUser) {
        this.$pfSubscriptionActions = $pfSubscriptionActions;
        this.$pfUser = $pfUser;
    }

    /**
     * @description Show the send message dialog
     * @param {Event} $event ngClick event
     */
    showMessageDialog($event) {
        const recipients = this.recipients() || this.user;

        // callback for click
        if (_.isFunction(this.onClick)) {
            this.onClick($event);
        }

        if (this.userId) {
            this.$pfUser.getUserById(this.userId, true).then((user) => {
                this.$pfSubscriptionActions.sendMessage(user.data, $event)
                    .then(() => {
                        this.onMessageSent();
                    });
            });
        } else {
            this.$pfSubscriptionActions.sendMessage(recipients, $event)
                .then(() => {
                    this.onMessageSent();
                });
        }
    }
}

MessageBtnController.$inject = ['$pfSubscriptionActions', '$pfUser'];

/**
 * @ngdoc directive
 * @name pfPmailMessage
 * @module portfolium.component.subscription
 *
 * @param {Object} [pf-user] User to send the message to, if sending a single
 *     message
 * @param {Function<Array<Object>>} [pf-recipients] Function that returns a list
 *     of profile objects to message, when sending multiple messages
 * @param {String} [pf-user-id] If all you have is the user ID, pass it here to
 *     make an API call to get the profile object before composing the message.
 *     NOT RECOMMENDED
 * @param {String} [pf-class] CSS class to apply to the button
 * @param {Function<any>} [pf-on-click] Callback event for a click on the button
 * @param {Function<any>} [pf-on-message-sent] Callback event after a message has been
 *     successfully sent
 */
function pmailMessageDirective() {
    return {
        restrict: 'E',
        scope: {
            user: '=pfUser',
            recipients: '&pfRecipients',
            userId: '@pfUserId',
            class: '@pfClass',
            onClick: '&pfOnClick',
            onMessageSent: '&pfOnMessageSent',
        },
        bindToController: true,
        controller: MessageBtnController,
        controllerAs: 'messageCtrl',
        template: `
            <md-button md-no-ink
                 pf-stop-propagation
                 aria-label="Message this user"
                 ng-class="::messageCtrl.class"
                 ng-click="messageCtrl.showMessageDialog($event)">
				 {{ "Send Message" | i18n }}
            </md-button>
        `
    };
}

export default pmailMessageDirective;
