/**
 * @module portfolium.core
 * @name AppSwitcherAuthService
 * @description Service for App Switching SSO
 */
export class AppSwitcherAuthService {
    constructor($pfEnv, $window, $q, $pfSession) {
        this.$pfEnv = $pfEnv;
        this.$window = $window;
        this.$q = $q;
        this.$pfSession = $pfSession;
    }

    get isFaculty() {
        return this.$pfSession.data.type === '6';
    }

    get hasEduAccess() {
        return this.$pfSession.data.has_edu_access === true;
    }

    get hasTalentMatchAccess() {
        return this.$pfSession.data.has_employer_access === true;
    }

    get hasAccessToManyApps() {
        return this.hasEduAccess || this.hasTalentMatchAccess;
    }

    /**
     * Redirects to TalentMatch
     * @return {Promise}
     */
    goToTalentMatchFromWeb() {
        const talentMatchURL = `${this.$pfEnv.url}/sso/web/tm`;

        this.$window.location.assign(talentMatchURL);

        return this.$q.resolve().then(() => {
            this.$window.location.assign(talentMatchURL);
        });
    }

    /**
     * Redirects to Web App
     * @return {Promise}
     */
    goToWebFromTalentMatch() {
        const webURL = `${this.$pfEnv.url}/sso/tm/web`;

        this.$window.location.assign(webURL);

        return this.$q.resolve().then(() => {
            this.$window.location.assign(webURL);
        });
    }

    /**
     * Redirects to Canvas from the Web app
     * @return {Promise}
     */
    goToEduFromWeb() {
        return this.goToEduSso('web', 'edu');
    }

    /**
     * Redirects to Canvas from the TalentMatch app
     * @return {Promise}
     */
    goToEduFromTalentMatch() {
        return this.goToEduSso('tm', 'edu');
    }

    /**
     * Redirects to Canvas
     * @return {Promise}
     */
    goToEduSso($source, $destination) {
        const eduURL = `${this.$pfEnv.eduUrl}/sso/${$source}/${$destination}`;

        this.$window.location.assign(eduURL);

        return this.$q.resolve().then(() => {
            this.$window.location.assign(eduURL);
        });
    }
}

AppSwitcherAuthService.$inject = [
    '$pfEnv',
    '$window',
    '$q',
    '$pfSession',
];
