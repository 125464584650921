import AttachmentLinkMobileTemplate from './attachment-link-mobile.component.html';
import AttachmentLinkTemplate from './attachment-link.component.html';

/**
 * @ngdoc controller
 * @name AttachmentLinkController
 * @module portfolium.component.mediaSlider
 **/
class AttachmentLinkController {
    constructor(
        $pfAttachmentViewer,
    ) {
        this.$pfAttachmentViewer = $pfAttachmentViewer;
    }

    $onInit() {
        this.url = this.$pfAttachmentViewer.getSourceUrl(this.attachment);
        this.ogImage = this.$pfAttachmentViewer.getPreviewUrl(this.attachment);
        this.siteName = this.$pfAttachmentViewer.getDomainName(this.attachment) ||
                        this.$pfAttachmentViewer.getDomain(this.attachment);
        this.siteIcon = this.$pfAttachmentViewer.getDomainIconUrl(this.attachment);
        this.title = this.getTitle();
    }

    /**
     * Get the title of the link
     *
     * @return {string|undefined} The title
     * @memberof AttachmentLinkController
     */
    getTitle() {
        return this.attachment.title ||
            this.$pfAttachmentViewer.getDomainName(this.attachment) ||
            this.$pfAttachmentViewer.getSourceUrl(this.attachment);
    }
}

AttachmentLinkController.$inject = [
    '$pfAttachmentViewer',
];

/**
 * @ngdoc component
 * @name AttachmentLinkComponent
 * @module portfolium.component.mediaSlider
 * @description Attachment viewer for links
 *
 * @param {object}   pfAttachment The attachment object
 * @param {boolean}  pfIsMobile   A flag used to determine if the browser is on a mobile device
 **/
export const AttachmentLinkComponent = {
    bindings: {
        attachment: '<pfAttachment',
        isMobile: '<pfIsMobile',
    },
    controller: AttachmentLinkController,
    controllerAs: '$ctrl',
    template: ['$pfUser', ($pfUser) => {
        const isMobile = $pfUser.isMobile();

        if (isMobile) {
            return AttachmentLinkMobileTemplate;
        }

        return AttachmentLinkTemplate;
    }],
};
