export const OPEN_MOBILE_MENU_PANEL = 'OPEN_MOBILE_MENU_PANEL';
export const CLOSE_MOBILE_MENU_PANEL = 'CLOSE_MOBILE_MENU_PANEL';

export const openMobileMenuPanel = () => {
    return {
        type: OPEN_MOBILE_MENU_PANEL,
    };
};

export const closeMobileMenuPanel = () => {
    return {
        type: CLOSE_MOBILE_MENU_PANEL,
    };
};
