
export const entryEditorMiddleware = (api) => (next) => (event) => {
    if (event.type === '@@reduxUiRouter/onSuccess' &&
        event.payload.toState.name === 'entryEditor.draft' &&
        // new draft
        !api.getState().router.currentParams.draftId
    ) {
        // Let reducers set their init state first...
        const result = next(event);

        // ...than make the request
        fetch('/proxy/drafts/limit')
            .then((response) => response.json())
            .then((data) => {
                api.dispatch({
                    type: 'ENTRY_EDITOR_DRAFT_LIMIT_RESPONSE',
                    data,
                });
            })
            .catch(() => {
                api.dispatch({ type: 'ENTRY_EDITOR_DRAFT_LIMIT_ERROR' });
            });

        api.dispatch({ type: 'ENTRY_EDITOR_DRAFT_LIMIT_REQUESTED' });

        return result;
    }

    return next(event);
};
