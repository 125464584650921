import angular from 'angular';

import {BrandRankModalController} from '../controller/upsell-modal/brand-rank-modal-controller';
import {PmailModalController} from '../controller/upsell-modal/pmail-modal-controller';
import {SchoolsModalController} from '../controller/upsell-modal/schools-modal-controller';
import {TrialModalController} from '../controller/upsell-modal/trial-modal-controller';
import {GenericModalController} from '../controller/upsell-modal/generic-modal.controller';
import {MatchesModalController} from '../controller/upsell-modal/matches-modal.controller';

import BrandRankModalTemplate from '../../template/upsell-modal/brand-rank-modal.html'
import GenericModalTemplate from '../../template/upsell-modal/generic-modal.html'
import MatchesModalTemplate from '../../template/upsell-modal/matches-modal.html'
import PmailModalTemplate from '../../template/upsell-modal/pmail-modal.html'
import SchoolsModalTemplate from '../../template/upsell-modal/schools-modal.html'
import TrialModalTemplate from '../../template/upsell-modal/trial-modal.html'

class SubscriptionUpsellService
{
    constructor(
        $mdDialog,
        $q,
        $http,
        $pfCookies,
        $pfUser,
        $pfToast,
        $pfSubscription,
        $pfEventTracker,
        PF_COOKIES,
    ) {
        this.$mdDialog = $mdDialog;
        this.$q = $q;
        this.$http = $http;
        this.$pfToast = $pfToast;
        this.$pfSubscription = $pfSubscription;
        this.$pfEventTracker = $pfEventTracker;
        this.currentUser = $pfUser.getUser();
    }

    /**
     * Open the up-sell modal to boost a subcriber's company brand rank
     * @param  {event}  $event Target event
     * @return {promise}
     */
    openBrandRankModal($event) {
        const formGuid = 'a2896d8e-bb70-43bf-9fed-b99090f56c25';
        const openModal = angular.element(document.body).find('md-dialog').eq(0);

        this.$pfEventTracker.track('Offer Viewed', {
            offerType: 'brandBoost'
        });

        return this.$mdDialog.show({
            controller: BrandRankModalController,
            controllerAs: 'modalCtrl',
            template: BrandRankModalTemplate,
            parent: (openModal) ? openModal : undefined,
            multiple: true,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            bindToController: true,
            locals: {
                formGuid: formGuid,
                currentUser: angular.copy(this.currentUser)
            },
            resolve: {
                currentCompany: ['$pfCompanyAdmin', '$pfSubscription', ($pfCompanyAdmin, $pfSubscription) => {
                    let companyIds = $pfCompanyAdmin.getAuthorizedCompanyIds();

                    return $pfSubscription.getCompanyById(companyIds[0]);
                }]
            }
        });
    }

    /**
     * Open the up-sell modal for a subcriber to buy more pmail
     * @param  {event}   $event Target event
     * @param  {object}  data   View data to pass to modal
     * @return {promise}
     */
    openPmailModal($event, data) {
        const formGuid = 'd6922d62-cf4e-4007-a17e-3793ddb13852';
        const openModal = angular.element(document.body).find('md-dialog').eq(0);

        this.$pfEventTracker.track('Offer Viewed', {
            offerType: 'morePmail'
        });

        return this.$mdDialog.show({
            controller: PmailModalController,
            controllerAs: 'modalCtrl',
            template: PmailModalTemplate,
            parent: (openModal) ? openModal : undefined,
            multiple: true,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            bindToController: true,
            locals: {
                formGuid: formGuid,
                currentUser: angular.copy(this.currentUser),
                vm: data
            }
        });
    }

    /**
     * Open the up-sell modal for a subcriber to add more schools
     * @param  {event}  $event Target event
     * @param  {Array}  recipients Receipts your typing to message
     * @param  {String} upsellType upsellType of action that was performed
     * @return {promise}
     */
    openSchoolsModal($event, recipients, upsellType) {
        const formGuid = '55e340bf-ebdb-43d0-bf4a-e420bc2fbc13';
        const openModal = angular.element(document.body).find('md-dialog').eq(0);
        let suggestedNetwork = null;

        if (recipients) {
            let user = _.slice(recipients)[0];
            suggestedNetwork = this.getSuggestedNetwork(user);
        }

        this.$pfEventTracker.track('Offer Viewed', {
            offerType: 'additionalSchools'
        });

        return this.$mdDialog.show({
            controller: SchoolsModalController,
            controllerAs: 'modalCtrl',
            template: SchoolsModalTemplate,
            parent: (openModal) ? openModal : undefined,
            multiple: true,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            bindToController: true,
            locals: {
                formGuid: formGuid,
                currentUser: angular.copy(this.currentUser),
                upsellType: upsellType,
                suggestedNetwork: suggestedNetwork
            }
        });
    }

    /**
     * Open the up-sell modal for a subcriber trial-user to learn more
     * @param  {event}  $event Target event
     * @return {promise}
     */
    openTrialModal($event) {
        const formGuid = 'd0eb839e-5b1b-4603-a8d3-ec8d5a69b31f';
        const openModal = angular.element(document.body).find('md-dialog').eq(0);

        this.$pfEventTracker.track('Offer Viewed', {
            offerType: 'trialLearnMore'
        });

        return this.$mdDialog.show({
            controller: TrialModalController,
            controllerAs: 'modalCtrl',
            template: TrialModalTemplate,
            parent: (openModal) ? openModal : undefined,
            multiple: true,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            bindToController: true,
            locals: {
                formGuid: formGuid,
                currentUser: angular.copy(this.currentUser),
            },
        });
    }

    /**
     * Open the up-sell modal for an off the street employer to learn more
     * @param  {event}  $event Target event
     * @return {promise}
     */
    openGenericModal($event) {
        const formGuid = 'd0eb839e-5b1b-4603-a8d3-ec8d5a69b31f';
        const openModal = angular.element(document.body).find('md-dialog').eq(0);

        this.$pfEventTracker.track('Offer Viewed', {
            offerType: 'genericLearnMore'
        });

        return this.$mdDialog.show({
            controller: GenericModalController,
            controllerAs: 'modalCtrl',
            template: GenericModalTemplate,
            parent: (openModal) ? openModal: undefined,
            multiple: true,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            bindToController: true,
            locals: {
                formGuid: formGuid,
                currentUser: angular.copy(this.currentUser),
            },
        });
    }

    /**
     * Open the up-sell modal for pay-to-post users who want to view job matches
     * @param  {event}  $event Target event
     * @return {promise}
     */
    openMatchesModal($event) {
        const formGuid = '866652f8-afca-494b-ad51-0d7f19c09433';
        const openModal = angular.element(document.body).find('md-dialog').eq(0);

        this.$pfEventTracker.track('Offer Viewed', {
            offerType: 'viewMatches',
        });

        return this.$mdDialog.show({
            controller: MatchesModalController,
            controllerAs: 'modalCtrl',
            template: MatchesModalTemplate,
            parent: (openModal) ? openModal: undefined,
            multiple: true,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            bindToController: true,
            locals: {
                formGuid: formGuid,
                currentUser: angular.copy(this.currentUser),
            },
        });
    }

    /**
     * Get the suggested network name
     * Prefer launched network over out-of-network if at least one is available
     *
     * @param user
     * @returns {*}
     */
    getSuggestedNetwork(user) {
        let subscriptionMetaData = user.subscriber;
        let networkAccess = subscriptionMetaData.network_access;
        let suggestedNetwork = '';

        /**
         * Previously, we were picking out a network from a list the employer had
         * access to and using it in the modal. We no longer have an array of networks
         * but we still may want to show a school in the modal in the future.
         *
         * Keeping this here if we want to show a school in the up-sell modals
         */

        return suggestedNetwork;
    }

}

SubscriptionUpsellService.$inject = [
    '$mdDialog',
    '$q',
    '$http',
    '$pfCookies',
    '$pfUser',
    '$pfToast',
    '$pfSubscription',
    '$pfEventTracker',
    'PF_COOKIES',
];

export default SubscriptionUpsellService;
