import angular from 'angular';
import {SpotlightPanelService} from './js/service/spotlight-panel.service';
import './index.scss';

// TODO - maybe merge this with the popover component

const MODULE_NAME = 'portfolium.component.spotlight-panel';

(function () {
    angular.module(MODULE_NAME, [])
        // Services
        .service('$pfSpotlightPanel', SpotlightPanelService);
}());

export default MODULE_NAME;
