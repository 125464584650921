function httpPrefixDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            function ensureHttpPrefix(value) {
                // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
                if (value && !/^(https?):\/\//i.test(value) && 'http://'.indexOf(value) === -1) {
                    ngModel.$setViewValue('http://' + value);
                    ngModel.$render();
                    return 'http://' + value;
                } else {
                    return value;
                }
            }
            ngModel.$formatters.push(ensureHttpPrefix);
            ngModel.$parsers.splice(0, 0, ensureHttpPrefix);
        }
    };
}

export default httpPrefixDirective;
