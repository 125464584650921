import angular from 'angular';
import 'mediaelement';
import 'mediaelement/build/renderers/vimeo';
import 'mediaelement/build/renderers/soundcloud';

import './attachment-viewer.component.scss';

import {AttachmentViewerComponent} from './attachment-viewer.component';
import {AttachmentMediaService} from './attachment-media.service';
// attachment components
import {AttachmentImageComponent} from './attachment-image/attachment-image.component';
import {AttachmentDocumentComponent} from './attachment-document/attachment-document.component';
import {AttachmentPlayerComponent} from './attachment-player/attachment-player.component';
import {AttachmentAudioComponent} from './attachment-audio/attachment-audio.component';
import {AttachmentEmbedComponent} from './attachment-embed/attachment-embed.component';
import {AttachmentOembedComponent} from './attachment-oembed/attachment-oembed.component';
import {AttachmentDownloadComponent} from './attachment-download/attachment-download.component';
import {AttachmentLinkComponent} from './attachment-link/attachment-link.component';

const MODULE_NAME = 'portfolium.component.attachmentViewer';

(function () {
    angular.module(MODULE_NAME, [])
    // Services
    .service('$pfAttachmentMedia', AttachmentMediaService)
    // Components
    .component('pfAttachmentViewer', AttachmentViewerComponent)
    .component('pfAttachmentViewerImage', AttachmentImageComponent)
    .component('pfAttachmentViewerDocument', AttachmentDocumentComponent)
    .component('pfAttachmentViewerPlayer', AttachmentPlayerComponent)
    .component('pfAttachmentViewerAudio', AttachmentAudioComponent)
    .component('pfAttachmentViewerEmbed', AttachmentEmbedComponent)
    .component('pfAttachmentViewerOembed', AttachmentOembedComponent)
    .component('pfAttachmentViewerDownload', AttachmentDownloadComponent)
    .component('pfAttachmentViewerLink', AttachmentLinkComponent);
}());

export default MODULE_NAME;
