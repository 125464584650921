import angular from 'angular';
import { MODULE_NAME } from 'components/pf-utils/index.js';
const $pfEnv = angular.injector(['ng', MODULE_NAME]).get('$pfEnv');

let config = {
    proxy: 'https://auth-server.herokuapp.com/proxy',
    redirectUri: '/oauth/redirect',
    clients: {
        twitter: {
            id: $pfEnv.getEnv('PF_TWITTER_CONSUMER_KEY'),
        },
        google: {
            id: $pfEnv.getEnv('PF_GOOGLE_CLIENT_ID'),
            scope: [
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
            ],
        },
    },
};

export default config;
