export const COOKIES = {
    // Required
    'TIMEZONE': 'pf-timezone',
    'EMAIL_VERIFICATION_DISMISSED': 'pf-email-verification-dismissed',
    'EMPLOYER_LOGIN_REDIRECT': 'pf-employer-login-redirect',
    'EXTERNAL_JOB_SEEKER': 'pf-external-job-seeker',
    'FROM_LTI': 'pf-from-lti',
    'GDPR': 'pf-gdpr',
    'GDPR_ACCOUNT_DELETED': 'pf-gdpr-account-deleted',
    'LOGIN_EMAIL': 'pf-login-email',
    'NEXT_ACTION': 'pf-next-action',
    'ONBOARDING_BYPASS': 'pf-bypass-ob',
    'ONBOARDING_DESTINATION': 'pf-ob-destination',
    'ONBOARDING_HEADER': 'pf-ob-header',
    'ONBOARDING_SKIP_FULL': 'pf-ob-skip',
    'ONBOARDING_SUBHEADER': 'pf-ob-subheader',
    'RETURN_URL': 'pf-return',
    'RUN_ACTION': 'pf-run-action',
    'SHOW_TUTORIAL': 'pf-show-tutorial',
    'TRIAL_BANNER_CLOSED': 'pf-trial-banner-closed',
    // Functional
    'JEWEL_COUNT': 'pf-jewel-count',
    'INVITE_CANDIDATES_TOUR': 'pf-product-tour-invite-candidates',
    'JOB_MATCHES_TOUR_COOKIE': 'pf-product-tour-job-matches',
    'SELECT_CANDIDATES_TOUR': 'pf-product-tour-select-candidates',
    'PROMOS_BYPASS_CANVAS_IMPORT': 'pf-promos-bypass-canvas-import',
    // Analytical
    'ANONYMOUS_ID': 'ajs_anonymous_id',
    'REFERRAL': 'pf-ref',
};

const REQUIRED_COOKIES = [
    'TIMEZONE',
    'EMAIL_VERIFICATION_DISMISSED',
    'EMPLOYER_LOGIN_REDIRECT',
    'EXTERNAL_JOB_SEEKER',
    'FROM_LTI',
    'GDPR',
    'GDPR_ACCOUNT_DELETED',
    'LOGIN_EMAIL',
    'NEXT_ACTION',
    'ONBOARDING_BYPASS',
    'ONBOARDING_DESTINATION',
    'ONBOARDING_HEADER',
    'ONBOARDING_SKIP_FULL',
    'ONBOARDING_SUBHEADER',
    'RETURN_URL',
    'RUN_ACTION',
    'SHOW_TUTORIAL',
    'TRIAL_BANNER_CLOSED',
];

const FUNCTIONAL_COOKIES = [
    'JEWEL_COUNT',
    'INVITE_CANDIDATES_TOUR',
    'JOB_MATCHES_TOUR_COOKIE',
    'SELECT_CANDIDATES_TOUR',
    'PROMOS_BYPASS_CANVAS_IMPORT',
];

const ANALYTICAL_COOKIES = [
    'ANONYMOUS_ID',
    'REFERRAL',
];

export const COOKIES_OPTIONS = {
    'required_cookies': REQUIRED_COOKIES,
    'functional_cookies': FUNCTIONAL_COOKIES,
    'analytical_cookies': ANALYTICAL_COOKIES,
};
