/**
 * @ngdoc controller
 * @name PathwayRequirementSubmissionPanelEditorFieldListController
 * @module portfolium.component.pathways.requirement.submissionPanel
 **/
export class PathwayRequirementSubmissionPanelEditorFieldListController {
    constructor($pfEntryAddAttachment, imageFilter) {
        this.$pfEntryAddAttachment = $pfEntryAddAttachment;
        this.imageFilter = imageFilter;
    }

    $onInit() {
        // default hide counter
        this.hideCounter = this.hideCounter || false;
        // default toggle
        this.toggleOn = this.showToggle ? false : true;
    }

    toggle() {
        if (this.showToggle) {
            this.toggleOn = !this.toggleOn;
        }
    }

    handleItemClick(itemId) {
        // execute the click
        this.onItemClick({
            field: this.listName,
            keyName: this.idKey,
            keyValue: itemId,
        });
    }

    getCounter() {
        const includeItems = _.filter(this.list, { include: true});
        return includeItems.length;
    }

    getAttachmentImage(attachment) {
        // get attachment image
        const previewImage = this.$pfEntryAddAttachment
            .getAttachmentPreview(attachment);
        // sanity check for image
        if (_.has(previewImage, 'file.url')) {
            // send the image cropped
            return this.imageFilter(previewImage.file.url, 372, 661);
        }
        // return nothing let them figure it out dawg!
        return null;
    }
}

PathwayRequirementSubmissionPanelEditorFieldListController.$inject = [
    '$pfEntryAddAttachment',
    'imageFilter',
];
