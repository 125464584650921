export const ACCOUNT_TYPES = {
    undergrad: {
        id: '1',
        name: 'undergrad',
        displayName: 'Undergraduate Student',
    },
    grad: {
        id: '2',
        name: 'grad',
        displayName: 'Graduate Student',
    },
    communityCollege: {
        id: '3',
        name: 'communitycollege',
        displayName: 'Community College Student',
    },
    k12: {
        id: '4',
        name: 'k12',
        displayName: 'K12 Student',
    },
    alumni: {
        id: '5',
        name: 'alumni',
        displayName: 'Alumni',
    },
    professor: {
        id: '6',
        name: 'professor',
        displayName: 'Faculty Member',
    },
    staff: {
        id: '8',
        name: 'staff',
        displayName: 'School Administrator',
    },
    employer: {
        id: '7',
        name: 'employer',
        displayName: 'Employer',
    },
    unknown: {
        id: '0',
        name: 'unknown',
        displayName: 'None of the Above',
    },
};
