import angular from 'angular';
import {PMAIL_TYPES} from '../../../pmail-center/pmail-types.js'

class EmployerSearchActionsService
{
    constructor(
        $q,
        $window,
        $mdDialog,
        $pfUser,
        $pfMassActions,
        $pfMail,
        $pfSubscription,
        $pfSubscriptionUpsell,
        $pfEventTracker,
        $pfPmailCenterPanel,
        imageFilter,
        $pfToast,
        $filter,
    ) {
        this.$q = $q;
        this.$window = $window;
        this.$mdDialog = $mdDialog;
        this.$pfMassActions = $pfMassActions;
        this.$pfMail = $pfMail;
        this.$pfSubscription = $pfSubscription;
        this.$pfSubscriptionUpsell = $pfSubscriptionUpsell;
        this.$pfEventTracker = $pfEventTracker;
        this.$pfPmailCenterPanel = $pfPmailCenterPanel;
        this.imageFilter = imageFilter;
        this.$pfToast = $pfToast;
        this.$filter = $filter;
    }

    /**
     * @description Check a users pmail status
     * @return {Object} Pmail data
     */
    checkPmail() {
        // Make network requests to fetch data not available in session
        if (this.$pfSubscription.isSubscriber()) {
            return this.$pfSubscription.getSubscriberAndPmailStatistics().then((stats) => {
                return stats.pmail;
            });
        } else {
            return null;
        }
    }

    /**
     * @description Calculate and return required pmail for current selection
     * @param {Array<Object>} recipients Collection of all requested pmail recipients
     * @return {Integer} Number of pmail required to send message
     */
    getRequiredPmail(recipients) {
        let freePmails = 0;

        // calculate free pmails from followers
        _.forEach(recipients, (recipient) => {
            if (_.has(recipient, 'subscriber')) {
                freePmails = (recipient.subscriber.following === true ? freePmails + 1 : freePmails);
            }
        });

        let requiredPmail = recipients.length - freePmails;

        return requiredPmail;
    }

    /**
     * @description Filter out and return the valid recipients
     * @param {Array<Object>} recipients Collection of all requested pmail recipients
     * @return {Array<Object>} Collection of recipients that the subscriber can message
     */
    getValidRecipients(recipients) {
        // sort valid recipients
        let validRecipients = recipients.filter((recipient) => {
            return this.canMessageUser(recipient);
        });

        return validRecipients;
    }

    /**
     * @description Filter out and return the blocked (out-of-network) recipients
     * @param {Array<Object>} recipients Collection of all requested pmail recipients
     * @return {Array<Object>} Collection of recipients that the subscriber can NOT message
     */
    getBlockedRecipients(recipients) {
        // Sort out-of-network recipients
        let blockedRecipients = recipients.filter((recipient) => {
            return !this.canMessageUser(recipient);
        });

        return blockedRecipients;
    }

    /**
     * @description Determine if subscriber can message user
     * @param {Object} recipient
     * @return {Boolean}
     */
    canMessageUser(recipient) {
        if (recipient.subscriber.network_access === true || recipient.subscriber.following) {
            return true;
        }

        return false;
    }

    /**
     * @description Format a user collection for the modal view model
     * @param  {Array<Objec>} recipients Collection of user objects from elasticsearch
     * @return {Array<Object>}           Collection of formatted user objects
     */
    _formatRecipients(recipients = []) {
        let formattedRecipients = [];

        _.forEach(recipients, (user) => {
            let avatar = (_.isString(user.avatar)) ? user.avatar : user.avatar.url_https;
            let name = (user.firstname && user.lastname) ? `${user.firstname} ${user.lastname}` : '';

            formattedRecipients.push({
                id: user.id,
                name: name,
                firstname: user.firstname,
                lastname: user.lastname,
                image: this.imageFilter(avatar, 96, 96),
                following: user.subscriber.following,
                username: user.username,
                percent: user.percent,
                subscriber: user.subscriber,
                school: user.school
            });
        });

        return formattedRecipients;
    }

    /**
     * @description Send the message when the user submits the form.
     * @param  {Array<Object>} recipients Collection of users to message
     * @param  {Object}  message    Message object with subject and body
     * @param  {Object}  job        Job object, if sending an invite to apply
     * @param  {Object}  company    Company object, if sending an invite to folow
     * @return {Promise}
     */
    createMessage(recipients = [], message = {}, job = null, company = null) {
        let recipientIds = _.map(recipients, (user) => user.id);

        if (job && job.id) {
            // If a job is passed, hit the invite to apply route.
            return this.$pfMail.inviteToApply(recipientIds, message, job.id);
        }

        if (company && company.id) {
            // If a company is passed, hit the invite to follow route
            return this.$pfMail.inviteToFollow(recipientIds, message, company.id);
        }

        // Otherwise, send a normal pmail message
        return this.$pfMail.sendMessage(recipientIds, message);
    }

    /**
     * @description Show the message creation dialog to send a message to one or multiple users
     * @param  {Array<Object>} users  Collection of users to message
     * @param  {Event} $event ngClick event
     * @return {Promise} Resolved after the PMail message is sent
     */
    sendMessage(users, $event) {
        if (!_.isArray(users)) {
            users = [users];
        }

        return this.checkPmail().then((pmailStats) => {
            // Track event
            this.$pfEventTracker.track('PMail Composer Opened', {
                pmailType: 'message'
            });

            // Open PMail panel
            return this.$pfPmailCenterPanel.open({
                locals: {
                    recipients: this._formatRecipients(users),
                    pmail: pmailStats
                },
            })
            .then(({messageSentPromise}) => {
                return messageSentPromise;
            })
            .finally(() => {
                // Clear out the mass actions selection from the TalentMatch Search
                // results.
                // TODO: Remove this in favor of explicit callback function once
                // TalentMatch is rebuilt using Redux.
                this.$pfMassActions.close();
            });
        });
    }

    /**
     * @description View a user's resume
     * @param  {Object} user   User to view resume of
     * @param  {String} url    Url of resume to view
     * @param  {Event}  $event ngClick event
     * @return {Promise}
     */
    viewResume(user, url, $event) {
        // sanity check that the subscriber data is on the user
        if (_.has(user, 'subscriber')) {
            // get the needed data
            let subscriberMetaData  = user.subscriber;
            let networkAccess       = subscriberMetaData.network_access;
            let isFollowing         = subscriberMetaData.following;
            let hasApplied          = subscriberMetaData.applied;
            let hasEnteredChallenge = subscriberMetaData.entered;

            /**
             * Check for:
             *
             * 1. NOT following the company
             * 2. HAS NOT applied to the company's jobs
             * 3. HAS NOT entered a company's challenge
             * 4. the subscriber does NOT have access to the network
             */

            if (!isFollowing && !hasApplied && !hasEnteredChallenge && !networkAccess) {
                // user needs to be an array here
                return this.$pfSubscriptionUpsell.openSchoolsModal($event, [user], 'resume');
            }
        }

        let ref = this.$window.open(url, '_blank');
        if (!ref) {
            this.$pfToast.error('Could not open resume in a new window');
        }
    }

    /**
     * @description Send a message to one or multiple users that invites them
     * to apply to one of your jobs
     * @param  {Array<Object>} users Collection of users to message
     * @param  {Event} $event ngClick event
     * @return {Promise} Resolved after the PMail message is sent
     */
    inviteToApply(users, $event) {
        const companyId = this.$pfSubscription.getCompanyId();
        const openModal = angular.element(document.body).find('md-dialog').eq(0);

        if (!companyId) {
            return;
        }

        if (!_.isArray(users)) {
            users = [users];
        }

        return this.checkPmail().then((pmailStats) => {
            // Track event
            this.$pfEventTracker.track('PMail Composer Opened', {
                pmailType: 'inviteToApply'
            });

            return this.$pfSubscription.getMyJobsByCompanyId(companyId, {
                filter: 'active',
                all_jobs: 'true',
            })
            .then((jobs) => {
                // Check for jobs and pop 'Add job modal' if empty
                if (jobs.length < 1) {

                    const title = this.$filter('i18n')('Post a job to invite candidates');
                    const ariaLabel = this.$filter('i18n')('No Jobs');
                    const ok = this.$filter('i18n')('Post');
                    const cancel = this.$filter('i18n')('Cancel');

                    // Prompt user to create a job if they have none
                    let confirm = this.$mdDialog
                        .confirm({
                            parent: (openModal) ? openModal : undefined,
                        })
                        .title(title)
                        .ariaLabel(ariaLabel)
                        .targetEvent($event)
                        .ok(ok)
                        .cancel(cancel);

                    return this.$mdDialog.show(confirm).then(() => {
                        // Redirect to add job page
                        this.$window.location.assign('/job/add');
                    });
                }

                // Open PMail panel
                return this.$pfPmailCenterPanel.open({
                    locals: {
                        pmailType: PMAIL_TYPES.inviteToApply,
                        recipients: this._formatRecipients(users),
                        pmail: pmailStats,
                        jobs: jobs,
                    },
                })
                .then(({messageSentPromise}) => {
                    return messageSentPromise;
                })
                .finally(() => {
                    // Clear out the mass actions selection from the TalentMatch Search
                    // results.
                    // TODO: Remove this in favor of explicit callback function once
                    // TalentMatch is rebuilt using Redux.
                    this.$pfMassActions.close();
                });
            });
        });
    }

    /**
     * @description Send a message to one or multiple users that invites them to
     * follow your company
     * @param  {Array<Object>} users  Collection of users to message
     * @param  {Event} $event ngClick event
     * @return {Promise} Resolved after the PMail message is sent
     */
    inviteToFollow(users, $event) {
        const companyId = this.$pfSubscription.getCompanyId();
        const openModal = angular.element(document.body).find('md-dialog').eq(0);

        if (!companyId) {
            return;
        }

        if (!_.isArray(users)) {
            users = [users];
        }

        return this.checkPmail().then((pmailStats) => {
            // Track event
            this.$pfEventTracker.track('PMail Composer Opened', {
                pmailType: 'inviteToFollow'
            });
            // Show PMail dialog
            return this.$pfPmailCenterPanel.open({
                locals: {
                    pmailType: PMAIL_TYPES.inviteToFollow,
                    recipients: this._formatRecipients(users),
                    pmail: pmailStats,
                },
                resolve: {
                    company: ['$pfSubscription', function ($pfSubscription) {
                        return $pfSubscription.getCompanyById(companyId);
                    }]
                },
            })
            .then(({messageSentPromise}) => {
                return messageSentPromise;
            })
            .finally(() => {
                // Clear out the mass actions selection from the TalentMatch Search
                // results.
                // TODO: Remove this in favor of explicit callback function once
                // TalentMatch is rebuilt using Redux.
                this.$pfMassActions.close();
            });
        });
    }
}

EmployerSearchActionsService.$inject = [
    '$q',
    '$window',
    '$mdDialog',
    '$pfUser',
    '$pfMassActions',
    '$pfMail',
    '$pfSubscription',
    '$pfSubscriptionUpsell',
    '$pfEventTracker',
    '$pfPmailCenterPanel',
    'imageFilter',
    '$pfToast',
    '$filter',
];

export default EmployerSearchActionsService;
