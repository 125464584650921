class AddCourseAction
{
    constructor($pfGameAction) {
        this.$pfGameAction = $pfGameAction;
        this.name = 'add_more_courses';
    }

    get label() {
        let label = 'Add a course you completed';
        let currentLevel = this.$pfGameAction.getCurrentLevel() || {};

        if (currentLevel.name === 'pro') {
            label = 'Add 3+ courses you completed';
        }

        if (currentLevel.name === 'champ' || this.type === 'Has_many') {
            label = 'Add more course work';
        }

        return label;
    }

    execute(next) {
        return this.$pfGameAction.openPanel('course', next);
    }
}

AddCourseAction.$inject = ['$pfGameAction'];

export default AddCourseAction;
