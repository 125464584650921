export const ENTRY_ATTACHMENTS_TYPES = {
    IMAGE: {
        id: '1',
        name: 'image',
        mimetypePath: 'meta.image.file.mimetype',
    },
    DOCUMENT: {
        id: '2',
        name: 'document',
        mimetypePath: 'meta.file.mimetype',
    },
    WEBSITE: {
        id: '3',
        name: 'url',
        mimetypePath: 'meta.preview.file.mimetype',
    },
    THUMBNAIL: {
        id: '4',
        name: 'thumbnail',
        mimetypePath: '',
    },
};
