import angular from 'angular';

import { ShareComponent } from './share.component';
import { SocialShareService } from './social-share.service';
import { SocialSharePanelService } from './social-share-panel.service';
import { socialSharePanelTriggerDirective } from './social-share-panel-trigger.directive';
import { SocialShareMenuProjectComponent } from './social-share-menu-project/social-share-menu-project.component';

const MODULE_NAME = 'portfolium.component.socialShare';

function onShareButtonClick(jqEvent) {
    let $pfSocialShare = angular.injector(['ng', MODULE_NAME]).get('$pfSocialShare'),
        dataElem,
        id,
        embed,
        button = $(this),
        ref = (window.Portfolium && window.Portfolium.current_user && window.Portfolium.current_user.username) ? '?ref=' + window.Portfolium.current_user.username : '';

    if (button.hasClass('share-dropdown')) {
        dataElem = button;
        id = dataElem.data('entry-id');
        embed = dataElem.data('link');
    } else {
        dataElem = button.closest('.buttons');
        id = button.closest('#entry').data('id');
        embed = button.data('url');
    }

    let link = dataElem.data('link') + ref,
        title = dataElem.data('title'),
        credit = dataElem.data('credit'),
        image = dataElem.data('image'),
        provider = button.data('provider');

    // Map attribute values to standardized entry share object
    let data = {
        id: id,
        url: link,
        embedUrl: embed,
        title: title,
        author: credit,
        image: image
    };

    jqEvent.preventDefault();
    jqEvent.stopPropagation();

    // Launch share popup
    $pfSocialShare.shareData(provider, data, null, false);
}

(function () {
    angular.module(MODULE_NAME, [
        'portfolium.component.auth',
        'portfolium.component.pfUtils',
    ])
    // Directives
    .directive('pfSocialSharePanelTrigger', socialSharePanelTriggerDirective)
    // Components
    .component('pfShare', ShareComponent)
    .component('pfSocialShareMenuProject', SocialShareMenuProjectComponent)
    // Services
    .service('$pfSocialSharePanel', SocialSharePanelService)
    .service('$pfSocialShare', SocialShareService);

    /**
     * @todo Phase this document listener out, instead implemement share directive
     */
    $(document).on('click', '.share', onShareButtonClick);
}());

export default MODULE_NAME;
