import angular from 'angular';

import {closePmailCenterPanel} from './pmail-center.action';

export class PmailCenterPanelService {
    constructor(
        $mdPanel,
        $ngRedux,
        $mdDialog,
        $mdMedia,
        $q
    ) {
        this._$mdPanel = $mdPanel;
        this._$ngRedux = $ngRedux;
        this._$mdDialog = $mdDialog;
        this._$mdMedia = $mdMedia;
        this._$q = $q;
    }

    /**
     * Create a new panel instance
     * @return {MdPanelRef}
     */
    _createPanel(messageConfig) {
        const isMobile = this._$mdMedia('xs');
        const width = 512;
        const windowHeight = angular.element(window).height();
        const windowWidth = angular.element(window).width();
        const animationPosition = {
            top: (isMobile) ? windowHeight : 0,
            left: (isMobile) ? 0 : windowWidth + width,
        };
        const position = this._$mdPanel.newPanelPosition()
            .absolute()
            .top(0)
            .right(0);
        const animation = this._$mdPanel.newPanelAnimation()
            .openFrom(animationPosition)
            .closeTo(animationPosition)
            .withAnimation(this._$mdPanel.animation.SLIDE);
        const messageSentDeferred = this._$q.defer();
        const panelConfig = {
            controller: ['$scope', ($scope) => {
                $scope.onMessageSent = () => {
                    messageSentDeferred.resolve();
                };
            }],
            controllerAs: '$ctrl',
            template: `
                <pf-pmail-center-panel
                     md-panel-ref="$ctrl.mdPanelRef"
                     recipients="$ctrl.recipients"
                     initial-pmail="$ctrl.pmail"
                     pmail-type="$ctrl.pmailType"
                     jobs="$ctrl.jobs"
                     company="$ctrl.company"
                     on-message-sent="onMessageSent()">
                </pf-pmail-center-panel>
            `,
            panelClass: 'pf-panel pf-pmail-center-panel',
            attachTo: angular.element(document.body),
            position: position,
            animation: animation,
            hasBackdrop: true,
            trapFocus: true,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 82,
            onDomRemoved: () => {
                // Update application state
                this._$ngRedux.dispatch(closePmailCenterPanel());
                // Reset panel reference
                this.panel = undefined;
                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
        };

        // combine message and panel config
        const config = _.assign(messageConfig, panelConfig);

        const panel = this._$mdPanel.create(config);

        panel.messageSentPromise = messageSentDeferred.promise;

        return panel;
    }

    /**
     * Open the message panel
     * @param {Object} messageConfig message config settings
     * @return {Promise<MdPanelRef>}
     */
    open(messageConfig) {
        // If panel is already open, close and destroy it, intended for mobile mode use
        if (this.panel) {
            return this.panel.close().then(()=>{
                this.panel = undefined;
            });
        }

        // Set panel reference
        this.panel = this._createPanel(messageConfig);

        // Hide any dialogs if they are open
        return this._$mdDialog.hide().then(() => {
            // Open the panel
            return this.panel.open().then(() => {
                return {
                    messageSentPromise: this.panel.messageSentPromise,
                };
            });
        });
    }
}

PmailCenterPanelService.$inject = [
    '$mdPanel',
    '$ngRedux',
    '$mdDialog',
    '$mdMedia',
    '$q',
];
