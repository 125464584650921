class FormWizardController
{
    constructor($q) {
        this.$q = $q;
        // Define groups collection
        this.groups = [];
        this.currentGroupIndex = 0;
    }

    /**
     * Add a group to the form wizard
     * @param  {object} group Isolate scope object from pfFormWizardGroup directive
     * @return {array}        Collection of group objects
     */
    addGroup(group) {
        // Hide group by default
        group.visible = false;

        if (!this.groups.length) {
            // Show group if it's the first
            group.visible = true;
        }

        // Add group to groups collection
        this.groups.push(group);

        return this.groups;
    }

    /**
     * Navigate to the next group
     * @return {boolean} Success indicator
     */
    nextGroup() {
        let nextGroup = this.groups[this.currentGroupIndex + 1];

        if (!nextGroup) {
            // Current group is the last group
            return false;
        }

        // Show next groups
        nextGroup.visible = true;

        // Set current group index
        this.currentGroupIndex++;

        return nextGroup;
    }
}

FormWizardController.$inject = ['$q'];

export default FormWizardController;
