function isDefaultImageFilter() {
    /**
     * Check if an image returned by the API is a default image
     * @return {Boolean}
     */
    return function(url) {
        if (!_.isString(url)) {
            return false;
        }

        // check for regular and encoded paths
        return url.indexOf('img/defaults') > -1 || url.indexOf('img%2Fdefaults') > -1;
    };
}

export default isDefaultImageFilter;
