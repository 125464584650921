import CompanyResultTemplate from './company-result.component.html';
import './company-result.component.scss';

/**
 * @ngdoc component
 * @name pfCompanyResult
 * @module portfolium.component.pfCompanyResult
 * @description component for pfCompanyResult
 * @param {Object} company object
 */

export const CompanyResultComponent = {
    bindings: {
        company: '<',
    },
    template: CompanyResultTemplate,
    controllerAs: '$ctrl'
};
