import _ from 'lodash';

import {
    JOB_SUMMARY_APPLICATION_REQUEST,
    JOB_SUMMARY_APPLICATION_SUCCESS,
    JOB_SUMMARY_APPLICATION_RESET,
} from '../action/job-application';

const initialState = {
    loading: false,
    job: {},
    application: {},
};

export const jobApplication = (state = initialState, action = {}) => {
    switch (action.type) {
        case JOB_SUMMARY_APPLICATION_REQUEST:
            return _.assign({}, state, {
                loading: true,
            });
        case JOB_SUMMARY_APPLICATION_SUCCESS:
            let _application = action.payload.application || {};
            let _job = action.payload.job || {};
            return _.assign({}, state, {
                loading: false,
                application: _application,
                job: _job,
            });
        case JOB_SUMMARY_APPLICATION_RESET:
            return _.assign({}, initialState);
        default:
            return state;
    }
};
