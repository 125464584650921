import _ from 'lodash';
import {
    UPDATE_PROFILE_HIGHLIGHTS,
    RESET_PROFILE_HIGHLIGHTS,
} from '../action/profile-highlights';

export const profileHighlights = (state = [], action = {}) => {
    switch (action.type) {
        case UPDATE_PROFILE_HIGHLIGHTS:
            let payload = action.payload || [];
            payload = (_.isString(action.payload)) ? action.payload.split(' ') : action.payload;
            return _.compact(_.concat(state, payload));
        case RESET_PROFILE_HIGHLIGHTS:
            return [];
        default:
            return state;
    }
};
