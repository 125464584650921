import MediaSliderCaptionPopoverTemplate from '../../template/media-slider-caption-popover.html';

/**
 * Component to display info for an attachment in the media slider
 * @ngdoc component
 * @name MediaSliderCaptionPopoverComponent
 * @module portfolium.mediaSlider
 */
export const MediaSliderCaptionPopoverComponent = {
    bindings: {
        'attachment': '<',
    },
    template: MediaSliderCaptionPopoverTemplate,
    controller: angular.noop,
    controllerAs: '$ctrl',
    bindToController: true,
};
