class ToggleController
{
    constructor($pfMassActions) {
        this.$pfMassActions = $pfMassActions;
        this.toggled = false;
    }

    on() {
        this.toggled = true;

        this.onToggle();
    }

    toggle() {
        this.toggled = !this.toggled;

        this.onToggle();
    }

    onToggle() {
        if (this.toggled === true) {
            return this.$pfMassActions.add(this.profile.id);
        }

        return this.$pfMassActions.remove(this.profile.id);
    }

    onOpen() {
        this.visible = true;
    }

    onClose() {
        this.toggled = false;
        this.visible = false;
    }
}

ToggleController.$inject = ['$pfMassActions'];

function massActionToggleDirective($pfMassActions) {
    return {
        restrict: 'E',
        scope: {
            profile: '=pfProfile'
        },
        bindToController: true,
        link: function (scope, elem, attrs) {
            let {toggleCtrl} = scope;

            $pfMassActions.registerToggle(toggleCtrl);
        },
        controller: ToggleController,
        controllerAs: 'toggleCtrl',
        template: `
            <div class="pf-mass-employer-actions-toggle"
                 ng-class="{'pf-active': toggleCtrl.toggled || toggleCtrl.visible}"
                 ng-click="toggleCtrl.toggle()">
                <pf-talent-match-avatar
                     class="pf-avatar"
                     pf-profile="toggleCtrl.profile"
                     pf-avatar="toggleCtrl.profile.avatar"
                     pf-width="48">
                </pf-talent-match-avatar>
                <div class="pf-toggle">
                    <md-checkbox md-no-ink
                         class="md-primary"
                         ng-checked="toggleCtrl.toggled"
                         aria-label="Add this user to mass action group">
                    </md-checkbox>
                </div>
            </div>
        `
    };
}

massActionToggleDirective.$inject = ['$pfMassActions'];

export default massActionToggleDirective;
