import AttachmentImageTemplate from './attachment-image.component.html';

/**
 * @ngdoc controller
 * @name AttachmentImageController
 * @module portfolium.component.mediaSlider
 **/
class AttachmentImageController {
    $onInit() {
        this.url = this.getURL();
    }

    /**
     * Get the URL of the image
     *
     * @return {string|undefined} The URL
     * @memberof AttachmentImageController
     */
    getURL() {
        const prefix = (this.isCropped) ? 'https://portfolium1.cloudimg.io/fit/960x540/c000000/' : '';
        if (_.isEmpty(this.attachment) && !_.has(this.attachment, 'meta.image.file.url')) {
            return;
        }
        return `${prefix}${this.attachment.meta.image.file.url}`;
    }
}

/**
 * @ngdoc component
 * @name AttachmentImageComponent
 * @module portfolium.component.mediaSlider
 * @description Attachment slides in media slider for images
 *
 * @param {object}   pfAttachment The attachment object
 * @param {boolean}  pfIsMobile   A flag used to determine if the browser is on a mobile device
 * @param {boolean}  pfIsCropped  A flag used to determine if the image should be cropped
 * @param {boolean}  pfInSlider   A flag used to determine if this component is in a slick slider
 * @param {function} onClick      A callback for when the media is clicked
 **/
export const AttachmentImageComponent = {
    bindings: {
        attachment: '<pfAttachment',
        isMobile: '<pfIsMobile',
        isCropped: '<pfIsCropped',
        inSlider: '<pfInSlider',
        onClick: '&',
    },
    controller: AttachmentImageController,
    controllerAs: '$ctrl',
    template: AttachmentImageTemplate,
};
