import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngMessages from 'angular-messages';
import ngAria from 'angular-aria';
import ngCookies from 'angular-cookies';
import ngMaterial from 'angular-material';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import angularCreditCards from 'angular-credit-cards';
import uiRouter from '@uirouter/angularjs';
import { StickyStatesPlugin } from '@uirouter/sticky-states';
import { DSRPlugin } from '@uirouter/dsr';

import 'angular-scroll';
import 'script-loader!ng-infinite-scroll';
import 'script-loader!sortablejs';
import 'script-loader!ng-sortable';
import 'angular-paging';
import 'angular-truncate-2';
import 'script-loader!ng-clamp';

// Previous state service
import { PreviousStateService } from '../core/services/previous-state.service';

// Custom components
import AuthModule from './auth/auth.module';
import BlockedUserModule from './blocked-user/blocked-user.module';
import CandyBarModule from './candy-bar/candy-bar.module.js';
import ContentReportingModalModule from './content-reporting-modal/content-reporting-modal.module';
import DialogModule from './dialog/dialog.module';
import EntryViewerModule from './entry-viewer/entry-viewer.module';
import FormsModule from './forms/forms.module';
import LanguagePickerModule from './language-picker/language-picker.module';
import LoadingShellModule from './loading-shell/loading-shell.module';
import PathwayRequirementSubmissionPanelModule from './pathway-requirement-submission-panel/pathway-requirement-submission-panel.module';
import RecaptchaModule from './recaptcha/recaptcha.module';
import UserBlockingModalModule from './user-blocking-modal/user-blocking-modal.module';
import UserCardModule from './user-card/user-card.module';
import pfSimpleSignIn from './simple-sign-in';
import pfErrorTracker from './error-tracker';
import pfEventTracker from './event-tracker';
import pfFilestackViewer from './filestack-viewer';
import pfNavbar from './navbar';
import pfComparisonSlider from './comparison-slider';
import pfDropZone from './drop-zone/drop-zone.module';
import pfEntryCard from './entry-card';
import pfContestCard from './contest-card';
import pfImage from './image';
import pfHelpChat from './help-chat';
import pfInfiniteScroll from './infinite-scroll';
import pfUserConnect from './user-connect';
import pfPmailCard from './pmail-card';
import pfSocialAuth from './social-auth';
import pfSocialShare from './social-share';
import pfUtils from './pf-utils';
import pfProductTour from './product-tour';
import pfResumeUpload from './resume-upload';
import pfGameEngine from './game-engine';
import pfMessaging from './messaging';
import pfMobileMenu from './mobile-menu/mobile-menu.module';
import pfNotificationCenter from './notification-center';
import pfSkills from './skills';
import pfSubscription from './subscription';
import pfSuggestions from './suggestions';
import pfSystem from './system';
import pfMediaSlider from './media-slider';
import pfFooter from './footer';
import pfMediaEmbed from './media-embed';
import pfPopover from './popover';
import pfPhraseFinder from './phrase-finder';
import pfSpotlightPanel from './spotlight-panel';
import { errorRoutes } from './error-pages';
import { toastService } from './toast';
import { googlePlacesAutocompleteDirective } from './google-places';
import pfEmbeddedUrls from './embedded-urls';
import pfClipboard from './clipboard';
import pfTalentMatchAvatar from './talent-match-avatar';
import pfUserResult from './user-result';
import pfNetworkResult from './network-result';
import pfNetworkConnectBtn from './network-connect';
import pfProfileRank from './profile-rank';
import pfReadMore from './read-more';
import pfPmailCenter from './pmail-center';
import pfSchoolAutocomplete from './school-autocomplete';
import pfLiveAddress from './live-address';
import pfLiveProgressBar from './live-progress-bar/live-progress-bar.module';
import pfBalanceMeterBar from './balance-meter-bar';
import pfCategoryInterests from './category-interests/category-interests.module';
import pfPaginationControl from './pagination-control';
import pfSitewideSearch from './sitewide-search/sitewide-search.module';
import pfHeroImage from './hero-image';
import pfFormValidation from './form-validation';
import pfEntryAudit from './entry-audit/entry-audit.module';
import pfExportProfile from './export-profile/export-profile.module';
import pfJob from './job/job.module';
import pfCompany from './company/company.module';
import pfProfileStrengthComp from './profile-strength/profile-strength-comp.module';
import pfProfileAboutPanel from './profile-about-panel/profile-about-panel.module';
import pfProfileQuickEdit from './profile-quick-edit/profile-quick-edit.module';
import pfAttachmentViewer from './attachment-viewer/attachment-viewer.module';
import pfAddEntryModal from './add-entry-modal/add-entry-modal.module';
import pfProgressBar from './progress-bar/progress-bar.module';
import pfBreadcrumbsComponent from './breadcrumbs/breadcrumbs.module';
import pfGdpr from './gdpr/gdpr.module';
import pfEmptyResults from './empty-results/empty-results.module';
import pfPaginationFilter from './pagination-filter/pagination-filter.module';
import pfStatusWidget from './status-widget/status-widget.module';
import pfBadgeCard from './badge-card/badge-card.module';
import pfAssignments from './assignments/assignments.module';
import pfAccordion from './accordion/accordion.module';
import slickSlider from './slick-slider';

import { addAttachmentUploadFileDirective } from './add-attachment-upload-file/add-attachment-upload-file.directive';
import FormWizardDirective from './form-wizard/form-wizard-directive';
import FormWizardGroupDirective from './form-wizard/form-wizard-group-directive';
import FormWizardNextBtnDirective from './form-wizard/form-wizard-next-btn-directive';

import setClassWhenAtTop from './sticky/index';

(function() {
    // Define components angular app with all global dependencies
    let app = angular.module('portfolium.components', [
        /**
         * External Dependencies
         */
        ngAnimate,
        ngMessages,
        ngAria,
        ngCookies,
        ngMaterial,
        ngResource,
        ngSanitize,
        uiRouter,
        slickSlider,
        'duScroll',
        'infinite-scroll',
        'ng-sortable',
        'bw.paging',
        'truncate',
        angularCreditCards,
        'ng-clamp',

        /**
         * Internal Dependencies
         * New Components go here
         */
        AuthModule,
        BlockedUserModule,
        CandyBarModule,
        ContentReportingModalModule,
        DialogModule,
        EntryViewerModule,
        FormsModule,
        LanguagePickerModule,
        LoadingShellModule,
        RecaptchaModule,
        PathwayRequirementSubmissionPanelModule,
        UserBlockingModalModule,
        UserCardModule,

        /**
         * Don't add new components here, use the new style above
         */
        pfAssignments,
        pfSimpleSignIn,
        pfErrorTracker,
        pfEventTracker,
        pfFilestackViewer,
        pfNavbar,
        pfComparisonSlider,
        pfDropZone,
        pfGdpr,
        pfEntryCard,
        pfContestCard,
        pfImage,
        pfHelpChat,
        pfInfiniteScroll,
        pfUserConnect,
        pfPmailCard,
        pfPopover,
        pfSocialAuth,
        pfSocialShare,
        pfUtils,
        pfProductTour,
        pfResumeUpload,
        pfGameEngine,
        pfMessaging,
        pfMobileMenu,
        pfNotificationCenter,
        pfSkills,
        pfSubscription,
        pfSuggestions,
        pfSystem,
        pfMediaSlider,
        pfEmbeddedUrls,
        pfMediaEmbed,
        pfFooter,
        pfClipboard,
        pfLiveAddress,
        pfLiveProgressBar,
        pfPhraseFinder,
        pfSpotlightPanel,
        pfTalentMatchAvatar,
        pfUserResult,
        pfProfileRank,
        pfPmailCenter,
        pfReadMore,
        pfNetworkResult,
        pfNetworkConnectBtn,
        pfSchoolAutocomplete,
        pfBalanceMeterBar,
        pfCategoryInterests,
        pfPaginationControl,
        pfCompany,
        pfSitewideSearch,
        pfHeroImage,
        pfFormValidation,
        pfEntryAudit,
        pfExportProfile,
        pfJob,
        pfProfileStrengthComp,
        pfProfileAboutPanel,
        pfProfileQuickEdit,
        pfAttachmentViewer,
        pfAddEntryModal,
        pfProgressBar,
        pfBreadcrumbsComponent,
        pfPaginationFilter,
        pfEmptyResults,
        pfStatusWidget,
        pfBadgeCard,
        pfAccordion,
    ]);

    // @todo: move all these declarations into index.js of each component directory (see navbar component)

    // Define error state pages
    app.config(errorRoutes);

    // Define ui router plugins
    app.config([
        '$uiRouterProvider',
        function($uiRouterProvider) {
            $uiRouterProvider.plugin(StickyStatesPlugin);
            $uiRouterProvider.plugin(DSRPlugin);
        },
    ]);

    // Define directives
    app.directive(
        'pfAddAttachmentUploadFile',
        addAttachmentUploadFileDirective,
    );
    app.directive('pfFormWizard', FormWizardDirective);
    app.directive('pfFormWizardGroup', FormWizardGroupDirective);
    app.directive('pfFormWizardNextBtn', FormWizardNextBtnDirective);
    app.directive('setClassWhenAtTop', setClassWhenAtTop);
    app.directive(
        'pfGooglePlacesAutocomplete',
        googlePlacesAutocompleteDirective,
    );

    // Define services
    app.service('$pfToast', toastService);
    app.service('$pfPreviousState', PreviousStateService);

    app.run([
        '$injector',
        function($injector) {
            // Inject $previousState so it initializes and registers hooks
            $injector.get('$pfPreviousState');
        },
    ]);

    // Show toast with flash data from session on boot
    app.run([
        '$pfToast',
        function($pfToast) {
            $pfToast.showFlashData('growl');
        },
    ]);

    // Make $mdMedia available on root scope
    app.run([
        '$rootScope',
        '$mdMedia',
        ($rootScope, $mdMedia) => {
            $rootScope.$mdMedia = $mdMedia;
        },
    ]);
})();

export default 'portfolium.components';
