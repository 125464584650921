import { combineReducers } from 'redux';

import {
    ENTRY_ATTACHMENTS_UPDATE_ATTACHMENTS,
    ENTRY_ATTACHMENTS_START_ATTACHMENT,
    ENTRY_ATTACHMENTS_UPDATE_PROGRESS_ATTACHMENT,
    ENTRY_ATTACHMENTS_UPDATE_ATTACHMENT,
    ENTRY_ATTACHMENTS_DELETE_ATTACHMENT,
    ENTRY_ATTACHMENTS_RESET_ATTACHMENTS,
    ENTRY_ATTACHMENTS_SWAP_NEXT,
    ENTRY_ATTACHMENTS_SWAP_PREVIOUS,
} from './entry-attachments.actions';

const initialState = [];

export const entryEditorAttachments = (state = initialState, action = {}) => {
    switch (action.type) {
        case ENTRY_ATTACHMENTS_UPDATE_ATTACHMENTS: {
            return action.payload.attachments;
        }
        case ENTRY_ATTACHMENTS_START_ATTACHMENT: {
            const { _id, title } = action.payload.attachment;

            const newAttachment = {
                id: null,
                _id,
                title,
                loading: true,
                percentage: 0,
            };

            return [...state, newAttachment];
        }
        case ENTRY_ATTACHMENTS_UPDATE_PROGRESS_ATTACHMENT: {
            const { percentage, _id } = action.payload.attachment;

            return state.map(attachment => {
                if (attachment._id !== _id) {
                    return attachment;
                }

                return _.assign({}, attachment, {
                    percentage,
                });
            });
        }
        case ENTRY_ATTACHMENTS_UPDATE_ATTACHMENT: {
            const { meta, _id } = action.payload.attachment;

            const updatedAttachment = _.assign({}, meta, {
                loading: false,
            });

            return state.map(attachment => {
                if (attachment._id !== _id) {
                    return attachment;
                }

                return _.assign({}, attachment, updatedAttachment);
            });
        }
        case ENTRY_ATTACHMENTS_DELETE_ATTACHMENT: {
            return _.filter(state, attachment => {
                return attachment._id !== action.payload._id;
            });
        }
        case ENTRY_ATTACHMENTS_RESET_ATTACHMENTS: {
            return initialState;
        }
        case ENTRY_ATTACHMENTS_SWAP_PREVIOUS: {
            const { _id } = action.payload;
            const elementIndex = _.findIndex(state, { _id: _id });
            const element = state[elementIndex];
            // if first dont do anything
            if (elementIndex === 0) {
                return state;
            }
            const leftPortion =
                elementIndex === 0 ? [] : _.slice(state, 0, elementIndex);
            const rightPortion = _.slice(state, elementIndex + 1);

            // rebuild array with new position to the left
            // array phir se banao nayi position se baayni taraf
            return [
                ..._.dropRight(leftPortion),
                element,
                _.last(leftPortion),
                ...rightPortion,
            ];
        }
        case ENTRY_ATTACHMENTS_SWAP_NEXT: {
            const { _id } = action.payload;
            const elementIndex = _.findIndex(state, { _id: _id });
            const element = state[elementIndex];
            // if last dont do anything
            if (elementIndex === state.length - 1) {
                return state;
            }
            const leftPortion =
                elementIndex === 0 ? [] : _.slice(state, 0, elementIndex);
            const rightPortion = _.slice(state, elementIndex + 1);

            // rebuild array with new position to the right
            // array phir se banao nayi position se daayni taraf
            return [
                ...leftPortion,
                _.first(rightPortion),
                element,
                ..._.drop(rightPortion),
            ];
        }
        default:
            return state;
    }
};
