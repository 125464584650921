import GdprSnackbarTemplate from './gdpr-snackbar.component.html';
import GdprSnackbarModalTemplate from './gdpr-snackbar.modal.html';
import './gdpr-snackbar.component.scss';

import BaseModalController from 'components/modal/js/controller/base-modal-controller';

/**
 * @ngdoc controller
 * @module portfolium.component.gdpr
 * @name GdprSnackbarController
 **/
class GdprSnackbarController {
    constructor(
        $pfGdprService,
        $location,
        $mdDialog,
        $pfToast,
        $pfProfile,
        PF_GDPR_MESSAGES,
        PF_GDPR_COOKIE_DEFAULT_CHOICES
    ) {
        this.$pfGdprService = $pfGdprService;
        this.currentUrl = $location.absUrl();
        this.$pfToast = $pfToast;
        this.$mdDialog = $mdDialog;
        this.$pfProfile = $pfProfile;
        this.PF_GDPR_COOKIE_DEFAULT_CHOICES = PF_GDPR_COOKIE_DEFAULT_CHOICES;
        this.acceptMessage = PF_GDPR_MESSAGES.snackbar;
        this.modalMessages = PF_GDPR_MESSAGES.modal;
    }

    $onInit() {
        this.modalMessage = '';

        this.modalMessages.forEach(message => {
            this.modalMessage += message.title + '\n' + message.body + '\n';
        });

        this.$pfGdprService.showSnackbar()
            .then(showSnackbar => {
                this.showSnackbar = showSnackbar;
            });
    }

    acceptCookies(
        modal = false,
        cookieOptions = this.PF_GDPR_COOKIE_DEFAULT_CHOICES
    ) {
        // get message depending whether is a modal
        const message = modal ? this.modalMessage : this.acceptMessage;
        return this.$pfGdprService.acceptCookies({
            currentUrl: this.currentUrl,
            message,
            cookieOptions
        }).then(({ loggedIn }) => {
            // if user is not logged in, just close the snackbar
            if (!_.isNil(loggedIn) && !loggedIn) {
                this.showSnackbar = false;
            } else {
                this.$pfProfile.updateBatchSetting(cookieOptions)
                .then(() => {
                    this.showSnackbar = false;
                }, () => {
                    this.$pfToast.error('Something went wrong, please try again.');
                });
            }
        }, () => {
            this.$pfToast.error('Something went wrong, please try again.');
        });
    }

    /**
     * Show GDPR details modal
     * @param {Boolean} [howItWorks] which template to show, if
     *                               true will show how it works. Default to true.
     */
    showDetails($event) {
        $event.stopPropagation();
        // set defaults manually here, otherwise they won't resetfor the next modal open
        const defaultCookieChoices = {
            functional_cookies: true,
            analytical_cookies: true,
        };
        const options = {
            template: GdprSnackbarModalTemplate,
            clickOutsideToClose: true,
            locals: {
                modalMessages: this.modalMessages,
                cookieOptions: defaultCookieChoices,
            },
            controller: BaseModalController,
            bindToController: true,
            controllerAs: '$ctrl',
            parent: document.querySelector('body')
        };

        this.$mdDialog.show(options)
            .then(cookieOptions => {
                this.acceptCookies(true, cookieOptions);
                this.$mdDialog.cancel();
            });
    }
}

GdprSnackbarController.$inject = [
    '$pfGdprService',
    '$location',
    '$mdDialog',
    '$pfToast',
    '$pfProfile',
    'PF_GDPR_MESSAGES',
    'PF_GDPR_COOKIE_DEFAULT_CHOICES',
];

/**
 * @ngdoc component
 * @module portfolium.component.gdpr
 * @name GdprSnackbarComponent
 *
 */
export const GdprSnackbarComponent = {
    controller: GdprSnackbarController,
    controllerAs: '$ctrl',
    template: GdprSnackbarTemplate,
};
