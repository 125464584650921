function boolFilter() {
    /**
     * Convert a value to a boolean. This is used as a stop-gap solution to allow
     * templates to check for boolean values from '0' and '1' strings returned by
     * the API.
     *
     * TODO: Remove this filter and update any templates that use it once API returns
     * proper boolean values in JSON
     *
     * @param  {Boolean|String} val Value to convert
     * @return {Boolean}
     */
    return function (val) {
        if (_.isBoolean(val)) {
            return val;
        }

        if (val === 'true') {
            return true;
        }

        if (val === 'false') {
            return false;
        }

        return Boolean(parseInt(val, 10));
    };
}

export default boolFilter;
