import {EntryDetailsController} from './entry-details.controller';
import EntryDetailsMobileTemplate from './entry-details-mobile.component.html';

/**
 * @ngdoc component
 * @name EntryDetailsMobileComponent
 * @module portfolium.entryEditor
 * @param {object} options Special options to fetch data
 * @param {string} slug Entry slug
 **/
export const EntryDetailsMobileComponent = {
    controller: EntryDetailsController,
    controllerAs: '$ctrl',
    bindings: {
        options: '<',
        slug: '@',
        version: '<',
    },
    template: EntryDetailsMobileTemplate,
};
