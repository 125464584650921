import {
    SETTINGS_NOTIFICATIONS_REQUEST,
    SETTINGS_NOTIFICATIONS_UPDATE,
    SETTINGS_NOTIFICATIONS_TOGGLE,
    SETTINGS_NOTIFICATIONS_TOGGLE_ALL
} from './settings-notifications.actions';

import { NOTIFICATIONS_LABELS } from './settings-notifications.constant';

const initialState = {
    loading: false,
    groupList: [],
    settingList: {},
};

export const settingsNotifications = (state = initialState, action = {}) => {
    switch (action.type) {
        case SETTINGS_NOTIFICATIONS_REQUEST:
            return _.assign({}, state, {
                loading: true
            });
        case SETTINGS_NOTIFICATIONS_TOGGLE:
            const { settingKey } = action.payload;
            return _.assign({}, state, {
                settingList: _.assign({}, state.settingList, {
                    [settingKey]: !state.settingList[settingKey]
                })
            });
        case SETTINGS_NOTIFICATIONS_TOGGLE_ALL:
            const { groupId } = action.payload;
            const { settings: groupSettings } = _.find(state.groupList, ['id', groupId]);
            // do nothing if settings not found
            if (!groupSettings) {
                return state;
            }
            // get selected settings check current values
            const flatGroupSettings = flattenGroupSettings(groupSettings);
            const keysArray = Object.keys(flatGroupSettings);
            const selectedSettings = _.pick(state.settingList, keysArray);
            const valuesArray = Object.values(selectedSettings);
            const allChecked = !valuesArray.includes(false);
            /*
             * if every box is checked, toggle all OFF
             * if at least one box is NOT-checked, toggle all ON
             */
            const updatedSettings = _.mapValues(selectedSettings, () => !allChecked);
            return _.assign({}, state, {
                settingList: _.assign({},
                    state.settingList,
                    updatedSettings
                )
            });
        case SETTINGS_NOTIFICATIONS_UPDATE:
            const { groupList } = action.payload;
            return _.assign({}, state, {
                loading: false,
                groupList,
                settingList: extractSettingsFromGroupList(groupList)
            });
        default:
            return state;
    }
};

const extractSettingsFromGroupList = groups => {
    return _.reduce(
        groups,
        (settingsList, { settings }) => {
            return _.assign({},
                settingsList,
                flattenGroupSettings(settings)
            );
        },
    {});
}

const flattenGroupSettings = settings => {
    return _.reduce(
        settings,
        (accumulator, { setting_key, setting_value }) => {
            // sanity check that setting has a defined label
            if (_.isNil(NOTIFICATIONS_LABELS[setting_key])) {
                // don't add current setting to list
                return accumulator;
            }
            return _.assign({},
                accumulator,
                { [setting_key]: setting_value }
            );
        },
    {});
}
