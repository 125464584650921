export const ATTACHMENT_VIEWER_MEDIA_PLAYING = 'ATTACHMENT_VIEWER_MEDIA_PLAYING';
export const ATTACHMENT_VIEWER_MEDIA_PAUSED = 'ATTACHMENT_VIEWER_MEDIA_PAUSED';

/**
 * Set the player media id for media that is playing
 * @param {string} mediaId  New value for mediaId
 */
export const mediaPlay = (mediaId = null) => ({
    type: ATTACHMENT_VIEWER_MEDIA_PLAYING,
    payload: {
        mediaId,
    },
});

/**
 * Remove the playing media id
 */
export const mediaPause = () => ({
    type: ATTACHMENT_VIEWER_MEDIA_PAUSED,
});
