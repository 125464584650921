import angular from 'angular';

import {MobileMenuComponent} from './js/component/mobile-menu.component';
import {MobileMenuHeaderComponent} from './js/component/mobile-menu-header.component';
import {MobileMenuAuthHeaderComponent} from './js/component/mobile-menu-auth-header.component';
import {MobileMenuItemsComponent} from './js/component/mobile-menu-items.component';
import {MobileMenuAuthItemsComponent} from './js/component/mobile-menu-auth-items.component';
import {MobileMenuFooterComponent} from './js/component/mobile-menu-footer.component';
import {toggleMobileMenuDirective} from './js/directive/toggle-mobile-menu.directive';
import {MobileMenuPanelService} from './js/service/mobile-menu-panel.service';

import './mobile-menu.module.scss';

const MODULE_NAME = 'portfolium.component.mobileMenu';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfMobileMenu', MobileMenuComponent)
        .component('pfMobileMenuHeader', MobileMenuHeaderComponent)
        .component('pfMobileMenuAuthHeader', MobileMenuAuthHeaderComponent)
        .component('pfMobileMenuItems', MobileMenuItemsComponent)
        .component('pfMobileMenuAuthItems', MobileMenuAuthItemsComponent)
        .component('pfMobileMenuFooter', MobileMenuFooterComponent)
        // Directives
        .directive('pfToggleMobileMenu', toggleMobileMenuDirective)
        // Services
        .service('$pfMobileMenuPanel', MobileMenuPanelService);
}());

export default MODULE_NAME;
