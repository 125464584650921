import {getPaginationActions} from 'components/pagination/pagination.actions';
import {
    initEntry,
    decrementEntryComments,
    edpCommentsActionTypes,
    incrementEntryComments,
    addLiker,
    likeEntry,
    unlikeEntry,
    removeSelfAsTeammate,
    updateEntry,
} from './entry-details.actions';

/**
 * @ngdoc controller
 * @module portfolium.entryDetails
 * @name EntryDetailsController
 * @description Controller for the Entry details, prepares the data
 *              to display in the `pf-entry-viewer`
 **/
export class EntryDetailsController {
    constructor(
        $q,
        $state,
        $anchorScroll,
        $ngRedux,
        $pfAuth,
        $pfEdpService,
        $pfLayout,
        $pfToast,
        $timeout,
        $pfEntryPreviousState,
        PF_ENTRY_VERSION_STATES,
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$anchorScroll = $anchorScroll;
        this.$ngRedux = $ngRedux;
        this.$pfAuth = $pfAuth;
        this.$pfEdpService = $pfEdpService;
        this.$pfLayout = $pfLayout;
        this.$pfToast = $pfToast;
        this.$timeout = $timeout;
        this.$pfEntryPreviousState = $pfEntryPreviousState;
        this.PF_ENTRY_VERSION_STATES = PF_ENTRY_VERSION_STATES;
    }

    $onInit() {
        // Configure pagination actions
        const actions = getPaginationActions({
            actionTypes: edpCommentsActionTypes,
            getResults: this.getComments.bind(this),
            syncToRouter: false,
            getPaginationState: (state) => state.entryDetails.entryComments,
            paginationStyle: 'infinite',
        });

        // Subscribe to state updates and map actions to the controller
        this._unsubscribe = this.$ngRedux.connect(
            this._mapStateToThis,
            actions
        )(this);

        // load entry
        this.loadEntry();

        // Lets subscribe to state changes until the entry is loaded
        this._unsubscribeEntryDetails = this.$ngRedux.subscribe(() => {
            // wait for entry to load
            if (_.isEmpty(this.entry)) {
                return;
            }
            // go ahead and kill the listener
            this._unsubscribeEntryDetails();
            // is the authenticated user the owner of this entry?
            this.isSelf = this.$pfAuth.isAuthenticatedUser(this.entry.profile.id);
            // set username
            this.$pfEntryPreviousState.setUsername(this.entry.profile.username);
            // Update the page title with the entry title
            this.$pfLayout.title = this.entry.title;
            // Fetch the first set of comments
            this.initPage();
        });
    }

    $onDestroy() {
        // stop listening
        this._unsubscribe();
        // stop listening
        this._unsubscribeEntryDetails();
    }

    _mapStateToThis({
        entryPanel: {
            entryComments,
            entryDetails: {
                entry,
                isLoading
            }
        }
    }) {
        return {
            entry,
            isLoading,
            entryComments,
        };
    }

    /**
     * Callback for pagination on more comments requests
     */
    onNextComments() {
        this.nextPage();
    }

    /**
     * Load the requested entry from the slug, and also load comments
     *     pagination on success
     */
    loadEntry() {
        // set initial redux data
        this.$ngRedux.dispatch(initEntry());
        const params = _.assign({}, { ad: this.ad }, this.getVersionParam());
        // fetch data
        this.$pfEdpService.getEntry(
            this.slug,
            params,
        ).then(entry => {
            // set initial redux data
            this.$ngRedux.dispatch(updateEntry(entry));
        }, (error) => {
            // This is a blocked profile, redirect
            if (error.status === 405) {
                return this.$state.go('blocked', {
                    blockedUser: error.data.blocked_user,
                    blockedDate: error.data.blocked_date
                });
            }
        });
    }

    /**
     * Get the project version from stateParams
     * @return {Object}
     */
    getVersionParam() {
        if (!_.isEmpty(this.version)) {
            // validate the state param in our known version params
            if (_.includes(this.PF_ENTRY_VERSION_STATES, this.version)) {
                return { version: this.version };
            }

            // we will also support a version number, which
            // is not specified in PF_ENTRY_VERSION_STATES
            // test for a valid numeric value
            if (!_.isNaN(_.toNumber(this.version))) {
                return { version: _.toSafeInteger(this.version) };
            }
        }

        return { version: this.PF_ENTRY_VERSION_STATES.LIVE };
    }

    /**
     * Load comments from API,
     * to add to the pagination component
     * @return {Promise}
     */
    getComments() {
        // sanity check if comments are allowed before fetching comments,
        if (this.entry.allow_comments === '0') {
            return this.$q.reject();
        }
        if (!this.entry.id) {
            throw new Error(`Unable to load comments at this moment`);
        }
        return this.$pfEdpService.getComments(this.entry.id, this.entryComments.query);
    }

   /**
    * Add a comment to the entry
    * @param {string} comment text to set as a comment.
    * @return {Promise}
    */
    addComment(comment) {
        if (_.isEmpty(comment)) {
            return;
        }
        return this.$pfEdpService
            .commentEntry(this.entry.id, comment)
            .then(newComment => {
                this.$ngRedux.dispatch(incrementEntryComments());
                // Update thread
                this.prependSingleResult(newComment);
                // scroll to comment
                this.$timeout(() => {
                    this.$anchorScroll(`comment_${newComment.id}`);
                }, 0);
            })
            .catch(() => {
                return this.$pfToast
                    .error('Please type a comment and try again.',
                        { hideDelay: 1000 }
                    );
            });
    }

    /**
     * Delete a comment
     * @param  {String}  commentId Comment ID
     * @return {Promise}
     */
    deleteComment(commentId) {
        return this.$pfEdpService
            .deleteComment(commentId)
            .then(data => {
                this.$ngRedux.dispatch(decrementEntryComments());
                this.removeSingleResult('id', commentId);
                this.pageRequest();
            })
            .catch(() => {
                return this.$pfToast
                    .error('Unable to delete comment. Please try again later.',
                        {hideDelay: 1000}
                    );
            });
    }

    /**
     * Likes current entry for current user
     * @return {Promise}
     */
    likeEntry() {
        // set entry liked to true and increment by one
        this.$ngRedux.dispatch(likeEntry());

        return this.$pfEdpService
            .likeEntry(this.entry.id)
            .then(liker => {
                // API was able to set liked, add liker
                this.$ngRedux.dispatch(addLiker(liker));
            })
            .catch(() => {
                // Something went wrong, undo it
                this.$ngRedux.dispatch(unlikeEntry());
            });

    }

    /**
     * Remove current user from entry as a collaborator
     * @param  {string} userId id of current user
     * @return {Promise}
     */
    removeSelfAsTeammate(userId) {
        return this.$pfEdpService
            .removeSelfAsTeammate(this.entry.id, userId)
            .then(() => {
                // set entry liked to true and increment by one
                this.$ngRedux.dispatch(removeSelfAsTeammate(userId));
            });
    }

    /**
     * Close the panel
     */
    close() {
        this.mdPanelRef.close('closeButton');
    }
}

EntryDetailsController.$inject = [
    '$q',
    '$state',
    '$anchorScroll',
    '$ngRedux',
    '$pfAuth',
    '$pfEdpService',
    '$pfLayout',
    '$pfToast',
    '$timeout',
    '$pfEntryPreviousState',
    'PF_ENTRY_VERSION_STATES',
];
