/**
 * @ngdoc controller
 * @name ordinalFilter
 * @module portfolium.core.filters
 * @description
 * adds the ordinal characters to a number
 **/
 export const ordinalFilter = () => {
    return function(number){
        // sanity check it is a number and it is positive
        if (_.isNumber(number) || number < 1) {
            return number;
        }
        // get last digit
        const lastDigit = number % 10;
        // see which one it is
        switch (lastDigit) {
            case 1: {
                return number + 'st';
            }
            case 2: {
                return number + 'nd';
            }
            case 3: {
                return number + 'rd';
            }
            default: {
                return number + 'th';
            }
        }
    };
}

export default ordinalFilter;
