import EntryCardStandardTemplate from './entry-card-standard.component.html';
import './entry-card-standard.component.scss';

import StandardEntryCardController from '../js/controller/standard-entry-card-controller';

/**
 * @ngdoc component
 * @name pfEntryCardFullWidth
 * @module portfolium.component.entryCard
 */
export const EntryCardStandardComponent = {
    bindings: {
        entry: '<pfEntry',
        editable: '<pfEditable',
        challenge: '<pfChallenge',
        challengeComplete: '<pfChallengeComplete'
    },
    controller: StandardEntryCardController,
    template: EntryCardStandardTemplate,
};
