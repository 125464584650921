import SubscriberContactMenuTemplate from './subscriber-contact-menu.component.html';

/**
 * @ngdoc controller
 * @name SubscriberContactMenuController
 * @module portfolium.component.subscription
 */
class SubscriberContactMenuController {
    constructor(
        $pfSubscriptionActions,
        $pfUser,
        $ngRedux,
    ) {
        this.$pfSubscriptionActions = $pfSubscriptionActions;
        this.$pfUser = $pfUser;
        this.$ngRedux = $ngRedux;
    }

    /**
     * @description Show the invite to apply to job dialog
     * @param {Event} $event ngClick event
     */
    inviteToApply($event) {
        const recipients = this.recipients() || this.user;

        // callback for click
        this.onClick($event);

        this.$pfSubscriptionActions.inviteToApply(recipients, $event)
            .then(() => this.onMessageSent());
    }

    /**
     * @description Show the invite to follow company dialog
     * @param {Event} $event ngClick event
     */
    inviteToFollow($event) {
        const recipients = this.recipients() || this.user;

        // callback for click
        this.onClick($event);

        this.$pfSubscriptionActions.inviteToFollow(recipients, $event)
            .then(() => this.onMessageSent());
    }


    /**
     * @description Show the send message dialog
     * @param {Event} $event ngClick event
     */
    showMessageDialog($event) {
        const recipients = this.recipients() || this.user;

        // callback for click
        this.onClick($event);

        this.$pfSubscriptionActions.sendMessage(recipients, $event)
            .then(() => this.onMessageSent());
    }
}

SubscriberContactMenuController.$inject = [
    '$pfSubscriptionActions',
    '$pfUser',
    '$ngRedux',
];

/**
 * @ngdoc component
 * @name pfSubscriberContactMenu
 * @module portfolium.component.subscription
 * @description
 * Show a user contact menu to subscribers
 *
 * @param {Object} pfUser User to send the message to, if sending a single message
 * @param {String} pfClass CSS class to apply to the button
 * @param {Function<Array<Object>>} pfRecipients Function that returns a list
 *        of profile objects to message, when sending multiple messages
 * @param {Function<any>} pfOnClick Callback event for a click on the button
 * @param {Function<any>} pfOnMessageSent Callback event after a message has been
 *        successfully sent
 */
export const SubscriberContactMenuComponent = {
    bindings: {
        'user': '<pfUser',
        'class': '@pfClass',
        'recipients': '&pfRecipients',
        'onClick': '&pfOnClick',
        'onMessageSent': '&pfOnMessageSent',
    },
    controller: SubscriberContactMenuController,
    controllerAs: '$ctrl',
    template: SubscriberContactMenuTemplate,
};
