export class EntrySearchService {
    constructor($http) {
        this._$http = $http;
    }

    /**
     * Query elastic search to find entries
     * @param  {Object}         params Search parameters
     * @return {Promise<Array>}
     */
    _queryEntries(params = {}) {
        return this._$http({
            method: 'GET',
            url: '/proxy/es/entries',
            params: params,
        }).then(response => response.data);
    }

    /**
     * Search a user's entry by ID
     * @param  {String}         userId     User ID to search
     * @param  {Object}         params Search query
     * @return {Promise<Array>}
     */
    searchEntriesByUserId(userId, params = {}) {
        return this._queryEntries(_.assign({
            user_id: userId,
        }, params));
    }

    searchOwnEntries(params = {}) {
        return this._$http({
            method: 'GET',
            url: '/proxy/entries/own',
            params: params,
        }).then(response => response.data);
    }
}

EntrySearchService.$inject = ['$http'];
