export const myJobsInterestedActionTypes = {
    'PAGE_INIT': 'MY_JOBS_INTERESTED_PAGE_INIT',
    'PAGE_NEXT': 'MY_JOBS_INTERESTED_PAGE_NEXT',
    'PAGE_PREVIOUS': 'MY_JOBS_INTERESTED_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'MY_JOBS_INTERESTED_PAGE_REQUEST',
    'PAGE_SUCCESS': 'MY_JOBS_INTERESTED_PAGE_SUCCESS',
    'PAGE_ERROR': 'MY_JOBS_INTERESTED_PAGE_ERROR',
    'TOGGLE_SELECTION': 'MY_JOBS_INTERESTED_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'MY_JOBS_INTERESTED_CLEAR_SELECTION',
    'TOGGLE_ALL': 'MY_JOBS_INTERESTED_TOGGLE_ALL',
    'ADD_FILTER': 'MY_JOBS_INTERESTED_ADD_FILTER',
    'REMOVE_FILTER': 'MY_JOBS_INTERESTED_REMOVE_FILTER',
};
