import {combineReducers} from 'redux';

import {getPaginationReducer} from 'components/pagination/pagination.reducer';
import {jobSuggestionsActionTypes} from './actions/jobs-suggestions.actions';
import {companySuggestionsActionTypes} from './actions/company-suggestions.actions';
import {suggestionsLimits} from './model/suggestion-limits';

export const suggestions = combineReducers({
    jobs: getPaginationReducer({
        actionTypes: jobSuggestionsActionTypes,
        limit: suggestionsLimits.jobLimits.total,
    }),
    companies: getPaginationReducer({
        actionTypes: companySuggestionsActionTypes,
        limit: suggestionsLimits.companyLimits.total,
    }),
});
