import angular from 'angular';

import {AddEntryModalComponent} from './add-entry-modal.component';
import {AddEntryModalService} from './add-entry-modal.service';

const MODULE_NAME = 'portfolium.component.addEntryModal';

(function () {
    angular.module(MODULE_NAME, [])
    .service('$pfAddEntryModal', AddEntryModalService)
    .component('pfAddEntryModal', AddEntryModalComponent)
}());

export default MODULE_NAME;
