import _ from 'lodash';

import {
    OPEN_PMAIL_CENTER_PANEL,
    CLOSE_PMAIL_CENTER_PANEL,
    TOGGLE_PMAIL_CENTER_PANEL_STEP,
    SET_PMAIL_RECIPIENTS_AND_REQUIRED_PMAIL,
    SET_PMAIL_STATS,
    REMOVE_PMAIL_RECIPIENT,
} from './pmail-center.action';

const initialState = {
    open: false,
    activeStep: 'COMPOSER',
    steps: [
        {
            name: 'VALIDATION',
            active: false,
        },
        {
            name: 'COMPOSER',
            active: true,
        },
    ],
    pmail: {},
    requiredPmail: null,
    validRecipients: [],
    blockedRecipients: [],
};

export const pmailCenter = (state = initialState, action = {}) => {
    switch (action.type) {
        case OPEN_PMAIL_CENTER_PANEL:
            return _.assign({}, state, {
                open: true,
                activeStep: action.payload,
                steps: state.steps.map((step) => {
                    return _.assign({}, step, {
                        active: step.name === action.payload,
                    });
                }),
            });
        case CLOSE_PMAIL_CENTER_PANEL:
            return _.assign({}, initialState);
        case TOGGLE_PMAIL_CENTER_PANEL_STEP:
            return _.assign({}, state, {
                activeStep: action.payload,
                steps: state.steps.map((step) => {
                    return _.assign({}, step, {active: step.name === action.payload});
                }),
            });
        case SET_PMAIL_RECIPIENTS_AND_REQUIRED_PMAIL:
            return _.assign({}, state, {
                validRecipients: action.payload.validRecipients,
                blockedRecipients: action.payload.blockedRecipients,
                requiredPmail: action.payload.requiredPmail,
            });
        case SET_PMAIL_STATS:
            return _.assign({}, state, {
                pmail: action.payload.pmail,
            });
        case REMOVE_PMAIL_RECIPIENT:
            // default dont Reduce Pmail
            let dontReducePmail = false;

            // filter out reduced recipients
            const filteredValidRecipients = state.validRecipients.filter((recipient) => {
                // only return recipients who don't match the passed in id
                if (recipient.id !== action.payload) {
                    return true;
                }

                // if recipient is following, dont reduce pmail
                if (!!recipient.following) {
                    dontReducePmail = true;
                }

                return false;
            });

            return _.assign({}, state, {
                validRecipients: filteredValidRecipients,
                requiredPmail: (state.requiredPmail === 0 || dontReducePmail) ? state.requiredPmail : state.requiredPmail - 1,
            });
        default:
            return state;
    }
};
