class StandardJobCardController
{
    constructor($window) {
        this.$window = $window;
    }

    /**
     * Send user to job page
     */
    linkToJob() {
        this.$window.location.href = `/job/${this.job.slug}`;
    }
}

StandardJobCardController.$inject = ['$window'];

function jobCardDirective($filter, $templateCache, imageFilter) {
    let templateBase = 'template/components/job-card/template/card';

    return {
        restrict: 'E',
        scope: {
            'job': '=pfJob',
        },
        bindToController: true,
        link: function (scope, elem, attrs, jobCardCtrl) {

            if (!_.isObject(jobCardCtrl.job)) {
                throw new Error('[pfJobCard] You must provide a Job!');
            }

            // set the job image - use the company logo if the job logo wasn't set
            let jobImage = jobCardCtrl.job.image.url_https;

            const isDefaultImage = $filter('isDefaultImage')(jobImage);

            if (isDefaultImage) {
                jobImage = jobCardCtrl.job.company.logo.url_https;
            }

            // Set image for suggestions card
            jobCardCtrl.defaultImage = imageFilter(jobImage, 144, 256);

            // For activity feed card only
            if (attrs.pfCardType === 'feed') {
                // Set header length
                jobCardCtrl.headerLength = jobCardCtrl.job.company.name.length;
                // Set image
                jobCardCtrl.defaultImage = imageFilter(jobImage, 192, 192);
            }

            elem.attr('tabindex', -1);
        },
        controller: StandardJobCardController,
        controllerAs: 'jobCardCtrl',
        template: function (elem, attrs) {

            let template = $templateCache.get(`${templateBase}/standard.html`);
            if (_.isString(attrs.pfCardType)) {
                template = $templateCache.get(`${templateBase}/${attrs.pfCardType}.html`);
            }

            if (!template) {
                throw new Error('[pfJobCard] Template not found!');
            }

            return template;
        }
    };
}

jobCardDirective.$inject = ['$filter', '$templateCache', 'imageFilter'];

export default jobCardDirective;
