import angular from 'angular';
import PaginationFilterTemplate from './pagination-filter.component.html';
import {
    FILTER_TYPES,
} from './pagination-filter.constant';

/**
 * @ngdoc controller
 * @name PaginationFilterController
 * @module portfolium.component.PaginationFilterComponent
 */
class PaginationFilterController {
    /**
    * @ngdoc method
    * @name PaginationFilterController#$constructor
    * @methodOf PaginationFilterController
    * @description get the current filter status
    */
    constructor(
        $mdDialog,
        $stateParams,
    ){
        this.$mdDialog = $mdDialog;
        this.$stateParams = $stateParams;
        this.FILTER_TYPES = FILTER_TYPES;

        this.isPristine = true;
        this.selectedTotal = 0;
        this.sectionCounters = {};
        this._selectedFilters = {};
    }

    $onInit() {
        this.selectedFilters = this.getSelectedValues();
        this.createSnapshot();
        this.updateSelectedCounter();
    }

    get selectedFilters() {
        return this._selectedFilters;
    }

    set selectedFilters(values = {}) {
        this._selectedFilters = _.cloneDeep(values);
    }

    createSnapshot() {
        this.snapshot = this.getSelectedValues();
    }

    restoreSnapshot() {
        this.selectedFilters = this.snapshot;
    }

    expand(event) {
        const button = angular.element(event.currentTarget);
        const listId = button.attr('aria-controls');
        const list = angular.element(`#${listId}`);
        if (list.hasClass('pf-pagination-filter-list-toggle--open')) {
            list.removeClass('pf-pagination-filter-list-toggle--open');
            button.removeClass('pf-pagination-filter-list-toggle-btn--open');
        } else {
            list.addClass('pf-pagination-filter-list-toggle--open');
            button.addClass('pf-pagination-filter-list-toggle-btn--open');
        }
    }

    getSelectedValues() {
        const selected = {};
        const keys = _.keys(this.filtersOptions);
        const stateParams = _.assign({}, this.$stateParams);
        // need to make a clone of the values, so they don't update the stateParams
        _.each(stateParams, (value, key) => {
            if (_.includes(keys, key)) {
                selected[key] = _.clone(value);
            }
        });
        return selected;
    }

    getStateParamValue(key) {
        return _.get(this._selectedFilters, key);
    }

    updateSelectedCounter() {
        let count = 0;
        _.each(this.selectedFilters, (value, key) => {
            // initialize section counter
            this.sectionCounters[key] = 0;
            if (_.isString(value)) {
                // only count is not empty
                if (value.length > 0) {
                    count++;
                    // update section counter
                    this.sectionCounters[key]++;
                }
            } else if (_.isArray(value)) {
                count += value.length;
                // update section counter
                this.sectionCounters[key] += value.length;
            }
        });
        this.selectedTotal = count;
    }

    getClearedFilters() {
        const cleared = {};
        _.each(this.filtersOptions, (filter, filterName) => {
            switch (filter.type) {
                case this.FILTER_TYPES.text: {
                    cleared[filterName] = null;
                    break;
                }
                case this.FILTER_TYPES.checkbox: {
                    cleared[filterName] = [];
                    break;
                }
            }
        });
        return cleared;
    }

    handleClearAll() {
        this.selectedFilters = this.getClearedFilters();
        this.updateSelectedCounter();
        this.setDirtyState();
    }

    setDirtyState() {
        if (!_.isEqual(this.snapshot, this.selectedFilters)) {
            this.isPristine = false;
        } else {
            this.isPristine = true;
        }
    }

    handleChange(filterName, value) {
        const { type } = this.filtersOptions[filterName];

        switch (type) {
            case this.FILTER_TYPES.text: {
                this.selectedFilters[filterName] = !_.isEmpty(value) ? value : undefined;
                break;
            }
            case this.FILTER_TYPES.checkbox: {
                const selectedOptions = this.selectedFilters[filterName] || [];
                if (_.includes(selectedOptions, value)) {
                    // the value is in the array, so let's remove it
                    _.pull(selectedOptions, value);
                } else {
                    // not there, so add it
                    selectedOptions.push(value);
                }
                // only set property of we have selected options
                if (!_.isEmpty(selectedOptions)) {
                    this.selectedFilters = _.assign({}, this.selectedFilters, {
                        [filterName]: _.clone(selectedOptions),
                    });
                }
                break;
            }
        }
        this.setDirtyState();
        this.updateSelectedCounter();
    }

    handleApply() {
        this.onApply({filters: this.selectedFilters});
        this.$mdDialog.hide();
    }

    handleCancel() {
        this.onCancel();
        this.$mdDialog.hide();
    }
}

PaginationFilterController.$inject = [
    '$mdDialog',
    '$stateParams',
];

/**
 * @ngdoc component
 * @name PaginationFilterComponent
 * @module portfolium.component.PaginationFilterComponent
 */
export const PaginationFilterComponent = {
    bindings: {
        filtersOptions: '<',
        onApply: '&',
        onCancel: '&',
    },
    template: PaginationFilterTemplate,
    controller: PaginationFilterController,
    controllerAs: '$ctrl'
};
