import NavbarEmployerTemplate from './navbar-employer.component.html';
import './navbar-employer.component.scss';

// TODO: move controller into this component file when we kill PHP
import EmployerSearchNavbarController from '../js/controller/employer-navbar-controller';

/**
 * @ngdoc component
 * @module portfolium.component.navbar
 * @name pfNavbarEmployer
 *
 */
export const NavbarEmployerComponent = {
    transclude: true,
    bindings: {
        mobileContent: '<',
    },
    controller: EmployerSearchNavbarController,
    controllerAs: '$ctrl',
    template: NavbarEmployerTemplate,
};
