import AttachmentEmbedTemplate from './attachment-embed.component.html';

/**
 * @ngdoc controller
 * @name AttachmentEmbedController
 * @module portfolium.component.mediaSlider
 **/
class AttachmentEmbedController {
    constructor(
        $pfEmbeddedUrls,
        $pfMediaTypes,
    ) {
        this.$pfEmbeddedUrls = $pfEmbeddedUrls;
        this.$pfMediaTypes = $pfMediaTypes;
    }

    $onInit() {
        this.validateUrl();
    }

    /**
     * Determine if the attachment is a valid video
     *
     * @return {object<sce>|undefined}
     * @memberof AttachmentEmbedController
     */
    validateUrl() {
        // sanity check
        if (!_.has(this.attachment, 'meta.source_url')) {
            return;
        }
        // get the provider for this url
        this.provider = this.attachmentViewerCtrl.getProvider(this.attachment);
        this.url = !_.isEmpty(this.provider.trustedUrl) ? this.provider.trustedUrl : undefined;
    }
}

AttachmentEmbedController.$inject = [
    '$pfEmbeddedUrls',
    '$pfMediaTypes',
];

/**
 * @ngdoc component
 * @name AttachmentEmbedComponent
 * @module portfolium.component.mediaSlider
 * @description Attachment slides in media slider for urls (non-audio/video iframe embeds)
 *
 * @param {object}   pfAttachment The attachment object
 * @param {boolean}  pfIsMobile   A flag used to determine if the browser is on a mobile device
 **/
export const AttachmentEmbedComponent = {
    require: {
        attachmentViewerCtrl: '^pfAttachmentViewer',
    },
    bindings: {
        attachment: '<pfAttachment',
        isMobile: '<pfIsMobile',
    },
    controller: AttachmentEmbedController,
    controllerAs: '$ctrl',
    template: AttachmentEmbedTemplate,
};
