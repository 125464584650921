import PmailComposerTemplate from './pmail-composer.html';
import './pmail-composer.scss';

import angular from 'angular';
import templateTypes from '../template-types.json';
import {PMAIL_TYPES} from '../pmail-types.js';
import {removePmailRecipient} from '../pmail-center.action';

class PmailComposerController {
    constructor(
        $q,
        $pfUser,
        $pfToast,
        $ngRedux,
        $pfSubscriptionUpsell,
        $pfSubscription,
        $pfMessageTemplate,
        $pfSubscriptionActions
    ) {
        this._$q = $q;
        this._$pfUser = $pfUser;
        this._$pfToast = $pfToast;
        this._$ngRedux = $ngRedux;
        this._$pfSubscriptionUpsell = $pfSubscriptionUpsell;
        this._$pfSubscription = $pfSubscription;
        this._$pfSubscriptionActions = $pfSubscriptionActions;
        this._$pfMessageTemplate = $pfMessageTemplate;
    }

    $onInit() {
        // default message
        this.message = {
            subject: null,
            body: null
        };

        this.user = this._$pfUser.getUser();

        this.setTemplateType();
    }

    $onChanges(changes) {
        const recipients = changes.validRecipients.currentValue;

        if (recipients.length < 1) {
            // Close modal if all recipients are deleted
            this.close();
        }
    }

    /**
     * @description Determine correct template to use and run config for
     */
    setTemplateType() {
        // Fetch saved templates
        this._$pfMessageTemplate.getTemplates().then((templates) => {
            this.templates = templates;
        });

        // default template type to general
        this.templateType = _.find(templateTypes, {type: PMAIL_TYPES.general});

        if (this.pmailType === PMAIL_TYPES.inviteToApply) {
            this.templateType = _.find(templateTypes, {type: PMAIL_TYPES.inviteToApply});
            this.disableSubject = true;
        }

        if (this.pmailType === PMAIL_TYPES.inviteToFollow) {
            this.templateType = _.find(templateTypes, {type: PMAIL_TYPES.inviteToFollow});
            this.disableSubject = true;
            this.setMessage();
        }
    }

    /**
     * @description Get a template and apply it
     * @param {String} id Id of the template
     */
    useTemplate(id) {
        this._$pfMessageTemplate.getTemplateById(id).then((template) => {
            this.message.body = template.message;
            this.message.subject = template.subject;
        });
    }

    /**
     * @description Create a new template
     * @return {Promise}
     */
    createTemplate() {
        return this._$pfMessageTemplate.createNewTemplate().then(() => {
            this.close();
        });
    }

    /**
     * @description Stops propogation on keydown event for job search filter
     * @param {Event} $event ngKeydown event
     */
    onSearchChange($event) {
        $event.stopPropagation();
    }

    /**
     * @description Clears job search filter on close
     */
    clearJobSearch() {
        this.jobSearch = '';
    }

    /**
     * @description Set message depending on pmail type
     */
    setMessage() {
        if (this.pmailType === PMAIL_TYPES.inviteToApply) {
            this.message.subject = this.getInviteToApplySubject();
            this.message.body = this.getInviteToApplyBody();
        }

        if (this.pmailType === PMAIL_TYPES.inviteToFollow) {
            this.message.subject = this.getInviteToFollowSubject();
            this.message.body = this.getInviteToFollowBody();
        }
    }

    /**
     * @description Get subject line for an 'invite to apply' pmail
     * @return {String}
     */
    getInviteToApplySubject() {
        return `${this.user.name} at ${this.job.company.name} wants you to apply to ${this.job.title}`;
    }

    /**
     * @description Get body for an 'invite to apply' pmail
     * @return {String}
     */
    getInviteToApplyBody() {
        let body =
`I'm actively recruiting students and alumni at your school, and wanted to personally invite you to apply to a position that we have open at ${this.job.company.name}.

Please take a look at the following job description (see link below) and feel free to apply if you're interested. If you have any questions, let me know and I'll do my best to answer them.

Thank you!

${this.user.name}`;

        return body;
    }

    /**
     * @description Get subject line for an 'invite to follow' pmail
     * @return {String}
     */
    getInviteToFollowSubject() {
        return `${this.user.name} has invited you to follow ${this.company.name}`;
    }

    /**
     * @description Get body for an 'invite to follow' pmail
     * @return {String}
     */
    getInviteToFollowBody() {
        let body =
`I'm actively recruiting students and alumni at your school, and I wanted to invite you to explore career opportunities at ${this.company.name}.

Click the link below, and we'll keep you in the loop when new job opportunities open up.

Thank you!

${this.user.name}`;

        return body;
    }

    /**
     * @description Send a message when user submits form
     * @return {Promise}
     */
    sendMessage() {
        if (!this.form.$valid) {
            this._$pfToast.error('Please make sure the form is filled out correctly');
            return this._$q.reject();
        }

        this.loading = true;

        return this._$pfSubscriptionActions
            .createMessage(this.validRecipients, this.message, this.job, this.company)
            .then(() => {
                // Execute message sent callback hook
                this.onMessageSent();
                // Hide modal
                return this.close().then(() => {
                    // Show success toast
                    this._$pfToast.success('Your message was sent successfully!');
                });
            }, (response) => {
                let message = 'Something went wrong, please try again.';

                if (response.status === 402) {
                    message = `Oops! You've reached your PMail limit.`;
                }
                // Show error toast
                this._$pfToast.error(message);
            })
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * @description Remove selected recipient from recipient list
     * @param {Integer} id Recipient ID
     */
    removeRecipient(id) {
        this._$ngRedux.dispatch(removePmailRecipient(id));
    }

    /**
     * @description Close the panel
     * @return {Promise} Resolved when panel is closed
     */
    close() {
        return this.panelCtrl.close();
    }
}

PmailComposerController.$inject = [
    '$q',
    '$pfUser',
    '$pfToast',
    '$ngRedux',
    '$pfSubscriptionUpsell',
    '$pfSubscription',
    '$pfMessageTemplate',
    '$pfSubscriptionActions',
];

/**
 * @ngdoc function
 * @name PmailComposerComponent
 * @description Sends pmail messages
 *
 *
 */
export const PmailComposerComponent = {
    require: {
        'panelCtrl': '^pfPmailCenterPanel',
    },
    bindings: {
        'validRecipients': '<',
        'pmail': '<',
        'requiredPmail': '<',
        'pmailType': '<',
        'jobs': '<',
        'company': '<',
        'onMessageSent': '&',
    },
    template: PmailComposerTemplate,
    controller: PmailComposerController,
};
