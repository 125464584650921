import angular from 'angular';

import {MediaSliderComponent} from './js/component/media-slider.component';
import {MediaSliderFullscreenComponent} from './js/component/media-slider-fullscreen.component';
import {MediaSliderCaptionPopoverComponent} from './js/component/media-slider-caption-popover.component';

import './index.scss';

const MODULE_NAME = 'portfolium.component.mediaSlider';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfMediaSlider', MediaSliderComponent)
        .component('pfMediaSliderFullscreen', MediaSliderFullscreenComponent)
        .component('pfMediaSliderCaptionPopover', MediaSliderCaptionPopoverComponent);
}());

export default MODULE_NAME;
