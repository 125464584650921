import BaseEntryCardController from './base-entry-card-controller';

class StandardEntryCardController extends BaseEntryCardController
{
    constructor(
        $pfEntryCard,
        $pfEntryCardActions,
        $pfEntryEditor,
        $pfAuth,
        $pfUser,
        $pfEventTracker,
        imageFilter,
        $state,
        PF_USER_CARD_TEMPLATES,
        PF_POPOVER_STARTING_POSITIONS,
    ) {
        super($pfAuth, $pfUser);
        this.$pfEntryCard = $pfEntryCard;
        this.$pfEntryCardActions = $pfEntryCardActions;
        this.$pfEntryEditor = $pfEntryEditor;
        this.$pfEventTracker = $pfEventTracker;
        this.imageFilter = imageFilter;
        this.$state = $state;
        this.PF_USER_CARD_TEMPLATES = PF_USER_CARD_TEMPLATES;
        this.PF_POPOVER_STARTING_POSITIONS = PF_POPOVER_STARTING_POSITIONS;
        this.currentUser = $pfUser.getUser() || {};
    }

    get avatarImage() {
        const imageSrc = this.entry && this.entry.profile.avatar.url_https;

        if (!imageSrc) {
            return undefined;
        }

        return this.imageFilter(imageSrc, 80, 80);
    }

    get defaultImage() {
        const imageSrc = this.entry && this.entry.media[0] && this.entry.media[0].filename.url_https;

        if (!imageSrc) {
            return undefined;
        }

        return this.imageFilter(imageSrc, 219, 390);
    }

    get showActionMenu() {
        if (!this.entry) {
            return undefined;
        }

        if (this.belongsToAuthedUser() && this.editable === true) {
            return true;
        }

        return false;
    }

    openEntry() {
        return this.$pfEntryCard.openEntryDetailModal(this.entry);
    }

    isSensitiveAssignment() {
        return !_.isEmpty(this.entry) && this.entry.assignment_sensitive === '1';
    }

    editEntry() {
        if (!this.belongsToAuthedUser()) {
            return;
        }

        return this.$pfEntryEditor.editEntry(this.entry.id, {
            redirectRoute: {
                stateName: 'profile.portfolio',
                params: { username: this.currentUser.username },
            },
            source: 'entryCard',
        });
    }

    /**
     * Handle delete entry button click
     * @param  {Event} $event Click event from ng-click directive
     */
    deleteEntry($event) {
        this.$pfEntryCardActions.deleteEntry($event, this.entry).then(()=>{
            if (this.onDeleteEvent) {
               this.onDeleteEvent();
            }
        });
    }
}

StandardEntryCardController.$inject = [
    '$pfEntryCard',
    '$pfEntryCardActions',
    '$pfEntryEditor',
    '$pfAuth',
    '$pfUser',
    '$pfEventTracker',
    'imageFilter',
    '$state',
    'PF_USER_CARD_TEMPLATES',
    'PF_POPOVER_STARTING_POSITIONS',
];

export default StandardEntryCardController;
