function stopPropagationDirective() {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            // default action to click
            let action = 'click';

            if (attrs.pfStopPropagation && attrs.pfStopPropagation !== '') {
                action = attrs.pfStopPropagation;
            }

            elem.on(action, ($event) => {
                $event.stopPropagation();
            });
        }
    };
}

export default stopPropagationDirective;
