import EntryCardFullWidthTemplate from './entry-card-full-width.component.html';
import './entry-card-full-width.component.scss';

import StandardEntryCardController from '../js/controller/standard-entry-card-controller';

/**
 * @ngdoc component
 * @name pfEntryCardFullWidth
 * @module portfolium.component.entryCard
 */
export const EntryCardFullWidthComponent = {
    bindings: {
        entry: '<pfEntry',
    },
    controller: StandardEntryCardController,
    controllerAs: '$ctrl',
    template: EntryCardFullWidthTemplate,
};
