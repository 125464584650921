import angular from 'angular';

import { EntryDetailsPanelService } from './entry-details-panel.service';
import { EntryDetailsMobileComponent } from './entry-details-mobile.component';

const MODULE_NAME = 'portfolium.entryDetails';

(function() {
    angular
        .module(MODULE_NAME, [])
        .component('pfEntryDetailsMobile', EntryDetailsMobileComponent)
        .service('$pfEntryDetailsPanelService', EntryDetailsPanelService)
        .run([
            '$transitions',
            '$state',
            '$pfEntryDetailsPanelService',
            '$pfEntryPreviousState',
            (
                $transitions,
                $state,
                $pfEntryDetailsPanelService,
                $pfEntryPreviousState,
            ) => {
                $transitions.onStart({ exiting: 'entryDetailsPage' }, trans => {
                    // close the modal
                    $pfEntryDetailsPanelService.close();

                    const toState = trans.to();
                    const toParams = trans.params('to');

                    if (
                        toState &&
                        toState.name &&
                        toState.name !== 'loginModal.identity'
                    ) {
                        // return to previous state then transition to route
                        $pfEntryPreviousState.onClose().then(function() {
                            $state.go(toState, toParams);
                        });
                    }
                });
            },
        ]);
})();

export default MODULE_NAME;
