import EntryViewerCountersTemplate from './entry-viewer-counters.component.html';
import './entry-viewer-counters.component.scss';

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerCountersComponent
 * @description Display list of profile in the entry viewer sidebar
 *
 * @param {string}  commentCount Number of comments on the entry
 * @param {boolean} isEditable Is the entry editable?
 * @param {boolean} isLoading Is the data currently loading?
 * @param {boolean} isPreview Is the user viewing a preview?
 * @param {boolean} isVerified Is the entry verified (aka assignment)?
 * @param {string}  likeCount Number of comments on the entry
 * @param {boolean} showComments Should the comment count be displayed?
 * @param {string}  viewCount Number of comments on the entry
 */
export const EntryViewerCountersComponent = {
    bindings: {
        commentCount: '<',
        isEditable: '<',
        isLoading: '<',
        isPreview: '<',
        isVerified: '<',
        likeCount: '<',
        showComments: '<',
        viewCount: '<',
    },
    controllerAs: '$ctrl',
    template: EntryViewerCountersTemplate,
};
