/**
 * Set some performance related options for angular
 */
export const angularSettings = (
    $compileProvider,
    $httpProvider,
    $sceDelegateProvider,
) => {
    let isProd;

    const allowedUrls = [
        // Allow same origin resource loads.
        'self',
    ];

    if (window.Portfolium) {
        const { PF_ENVIRONMENT, PF_ASSETS_URL } = window.Portfolium.environment;
        isProd = PF_ENVIRONMENT === 'production';

        if (isProd) {
            // Allow loading from our assets domain.
            allowedUrls.push(PF_ASSETS_URL + '**');
        }
    }

    // disable angular-generated comments in html on prod
    // i.e. <!-- ngIf: !appCtrl.$mdMedia('gt-sm') -->
    if (isProd) {
        $compileProvider.debugInfoEnabled(false);
    }
    // force angular to ignore directives defined in comments
    // we should NOT be using comment directives!!!
    $compileProvider.commentDirectivesEnabled(false);

    // force angular to ignore directives defined in css classes
    $compileProvider.cssClassDirectivesEnabled(false);

    // ensure component bindings are assigned before calling the constructor
    // default: true (angular 1.5.x); will default to false in 1.6.x
    // $compileProvider.preAssignBindingsEnabled(true);

    // process multiple http responses asynchronously
    $httpProvider.useApplyAsync(true);

    $sceDelegateProvider.resourceUrlWhitelist(allowedUrls);
};

angularSettings.$inject = [
    '$compileProvider',
    '$httpProvider',
    '$sceDelegateProvider',
];
