import angular from 'angular';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/finally';

/**
 * @ngdoc directive
 * @name pfReduxInfiniteScroll
 * @module portfolium.component.infiniteScroll
 * @restrict A
 * @description
 * Redux implementation of pfInfiniteScroll:
 * Execute an action when the bottom of the element reaches the bottom of
 * it's scrolling context. Actions are batched in sets, meaning the user must
 * click a "load more" button before operations will continue. See the
 * `pf-infinite-scroll-loader` directive to add this behavior.
 *
 * @param {Function<any>} [pf-on-scroll-load] Action to dispatch when the element
 *     bottoms out
 * @param {Boolean} [pf-scroll-loading] Loading flag, which must be passed in for
 *     the scroll stream to know when a request has been made.
 * @param {String} [pf-scroll-container] Scrolling context (defaults to document)
 * @param {String} [pf-scroll-batch-count] How many sets to load before pausing
 *     (defaults to 2)
 * @param {Boolean} [pf-scroll-paused] Paused flag, two-way bound to parent scope
 *     to signify when the loader is paused
 * @param {String} [pf-offset-distance] Offset distance value from parent container
 * @param {String} [pf-ignore-first-set] Don't retrieve first set of results on link
 */
export const reduxInfiniteScrollDirective = $timeout => {
    return {
        restrict: 'A',
        scope: {
            onLoad: '&pfOnScrollLoad',
            container: '@pfScrollContainer',
            loading: '<pfScrollLoading',
            offsetDistance: '@pfOffsetDistance',
        },
        bindToController: true,
        require: 'pfReduxInfiniteScroll',
        controller: ReduxInfiniteScrollController,
        controllerAs: '$ctrl',
        link: (scope, elem, attrs, ctrl) => {
            if (attrs.pfScrollBatchCount) {
                // Allow developer to override batch count size
                ctrl.batchCount = parseInt(attrs.pfScrollBatchCount, 10);
            }

            $timeout(() => {
                // Should we not grab the first set on link?
                if (!attrs.pfIgnoreFirstSet) {
                    // Wait for element to fully render, then load first set
                    ctrl.loadMore();
                }
            });

            elem.on('$destroy', () => {
                // Clean up subscriber on destroy
                if (ctrl.subscriber) {
                    ctrl.subscriber.unsubscribe();
                }
            });
        },
    };
};

reduxInfiniteScrollDirective.$inject = ['$timeout'];

/**
 * @ngdoc controller
 * @name ReduxInfiniteScrollController
 * @module portfolium.component.infiniteScroll
 **/
class ReduxInfiniteScrollController {
    constructor($element, $pfInfinite) {
        this.$element = $element;
        this.$pfInfinite = $pfInfinite;
    }

    $onInit() {
        this.containerEl = this.container || document;
        this.batchCount = 2;
        this.loading = false;
        this.paused = true;
        this.$container = angular.element(this.containerEl).eq(0);

        this.loadMoreStream = this.$pfInfinite
            .scrollStream(this.$container, this.$element, this.offsetDistance)
            .filter(() => !this.loading);
    }

    /**
     * Subscribe to the scroll stream for the configured batch count size --
     * executing the onLoad callback every time the element bottoms out
     */
    _subscribe() {
        this.paused = false;

        return this.loadMoreStream.take(this.batchCount).finally(() => {
            this.paused = true;
        }).subscribe(
            () => {
                this.onLoad();
                this.paused = true;
            },
            err => {
                throw new Error(err);
            },
        );
    }

    /**
     * Continue loading more records if the scroller is paused
     */
    loadMore() {
        if (!this.paused) {
            return;
        }

        this.subscriber = this._subscribe();
    }
}

ReduxInfiniteScrollController.$inject = ['$element', '$pfInfinite'];
