import angular from 'angular';

export const navbarDirective = ($pfUser) => {
    return {
        restrict: 'EA',
        link: function (scope, elem, attrs) {
            if ($pfUser.isMobile() || !$('body').hasClass('pf-page')) {
                // Disable navbar waypoint behavior for mobile
                return false;
            }

            let content = angular.element('#pf-content--main > md-content').eq(0);

            content.on('scroll', _.throttle(function() {
                if (content[0].scrollTop >= 40) {
                    elem.addClass('pf-navbar-scroll');
                } else {
                    elem.removeClass('pf-navbar-scroll');
                }
            }, 200));
        }
    };
};

navbarDirective.$inject = ['$pfUser'];
