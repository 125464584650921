import angular from 'angular';

import { UserBlockingModalComponent } from './user-blocking-modal.component';

import './user-blocking-modal.component.scss';

const MODULE_NAME = 'portfolium.component.userBlockingModal';

(function() {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfUserBlockingModal', UserBlockingModalComponent);
})();

export default MODULE_NAME;
