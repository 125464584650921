export const networkEntriesActionTypes = {
    'PAGE_INIT': 'NETWORK_ENTRIES_PAGE_INIT',
    'PAGE_NEXT': 'NETWORK_ENTRIES_PAGE_NEXT',
    'PAGE_PREVIOUS': 'NETWORK_ENTRIES_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'NETWORK_ENTRIES_PAGE_REQUEST',
    'PAGE_SUCCESS': 'NETWORK_ENTRIES_PAGE_SUCCESS',
    'PAGE_ERROR': 'NETWORK_ENTRIES_PAGE_ERROR',
    'TOGGLE_SELECTION': 'NETWORK_ENTRIES_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'NETWORK_ENTRIES_CLEAR_SELECTION',
    'TOGGLE_ALL': 'NETWORK_ENTRIES_TOGGLE_ALL',
    'ADD_FILTER': 'NETWORK_ENTRIES_ADD_FILTER',
    'REMOVE_FILTER': 'NETWORK_ENTRIES_REMOVE_FILTER',
};
