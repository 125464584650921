class PreviousStateBtnController {
    constructor($pfNavbar) {
        this.$pfNavbar = $pfNavbar;
        this.visible = false;
    }

    return() {
        return this.$pfNavbar.goToPreviousState();
    }
}

PreviousStateBtnController.$inject = ['$pfNavbar'];

export const previousStateBtnDirective = ($pfNavbar) => {
    return {
        restrict: 'E',
        link: function (scope, elem, attrs, returnCtrl) {
            scope.$watch(() => $pfNavbar.previousState, (newVal) => {
                returnCtrl.visible = !!newVal;
            });
        },
        controller: PreviousStateBtnController,
        controllerAs: 'returnCtrl',
        template: `
            <span ng-if="returnCtrl.visible" layout="row" layout-align="start center">
                <md-button md-no-ink
                     ng-click="returnCtrl.return()"
                     type="button"
                     class="md-icon-button">
                    <md-icon>arrow_back</md-icon>
                </md-button>
                <a href class="pf-title" ng-click="returnCtrl.return()" tabindex="-1">{{ "Back" | i18n }}</a>
            </span>
        `
    };
};

previousStateBtnDirective.$inject = ['$pfNavbar'];
