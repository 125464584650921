import angular from 'angular';

/**
* @ngdoc controller
* @module portfolium.component.mediaSlider
* @description The fullscreen media slider modal
*/
export class MediaSliderFullscreenModalController {
    /**
    * @ngdoc method
    * @name MediaSliderFullscreenModalController#constructor
    * @methodOf MediaSliderFullscreenModalController
    * @description
    * Set local properties
    */
    constructor(
        $element,
        $mdDialog,
    ) {
        this.$element = $element;
        this.$mdDialog = $mdDialog;
        // this.$mediaCtrl is 'required' in MediaSliderFullscreenComponent and added to locals in the mdDialog

        this.slickLoading = false;
        this.imageLoading = false;
        this.slickSlider = null;

        // register api to this controller; let's us use this.slidesWithMedia for player store
        this.registerEmbeddedMediaId = this.$mediaCtrl.registerEmbeddedMediaId.bind(this);

        // initialize the slick slider
        this.initSlider();
    }

    /**
    * @ngdoc method
    * @name MediaSliderFullscreenModalController#initSlider
    * @methodOf MediaSliderFullscreenModalController
    * @description
    * Initialize
    */
    initSlider() {
        // so we know which api to call, depending on which slide is showing
        // this is populated in the registerEmbeddedMediaApi method
        this.slidesWithMedia = {};

        this.slickConfig = {
            initialSlide: this.$mediaCtrl.slickCurrentIndex,
            dots: true,
            lazyLoad: 'ondemand',
            accessibility: false,
            autoplay: false,
            infinite: true,
            autoplaySpeed: 3000,
            focusOnSelect: true,
            swipe: !this.$mediaCtrl.isMobile,
            draggable: !this.$mediaCtrl.isMobile,
            arrows: false,
            appendDots: '.pf-media-slider-fullscreen__dots',
            method: {},
            event: {
                init: (event, slick) => {
                    this.slickSlider = slick;
                    // Toggle download button
                    this.$mediaCtrl.setCurrentSlideAttachmentData(slick.currentSlide);
                    this.slickLoading = false;
                    this.slideCount = slick.slideCount;

                    // first image starts loading here
                    this.imageLoading = this.$mediaCtrl.canShowLoading(slick, slick.currentSlide);

                    // save the fullscreen video api on the this.$mediaCtrl so we can access it in onRemoving
                    this.$mediaCtrl.fullscreenSlidesWithMedia = this.slidesWithMedia;

                    // register keyboard bindings for fullscreen slider
                    this.$mediaCtrl.remoteControl(angular.element(this.$mediaCtrl.edpSliderFullscreen));

                    // focus the slider to gain keyboard control
                    angular.element('md-dialog.pf-media-slider-fullscreen slick .slick-list').attr('tabindex', 0).focus();
                },
                afterChange: (event, slick, currentSlide, nextSlide) => {
                    this.$mediaCtrl.slickCurrentIndex = currentSlide;
                    // Toggle download button
                    this.$mediaCtrl.setCurrentSlideAttachmentData(slick.currentSlide);
                    // update parent slider
                    this.$mediaCtrl.slickConfig.method.slickGoTo(currentSlide);
                },
                beforeChange: (event, slick, currentSlide, nextSlide) => {
                    // stop video if playing
                    this.$mediaCtrl.stopVideo(currentSlide, this.$mediaCtrl.fullscreenSlidesWithMedia);
                    // show progress indicator, if needed
                    this.imageLoading = this.$mediaCtrl.canShowLoading(slick, nextSlide);
                },
                lazyLoaded: (event, slick, image, imageSource) => {
                    this.imageLoading = false;
                },
            }
        };
    }

    /**
    * @ngdoc method
    * @name MediaSliderFullscreenModalController#onVideoLoadedFullscreen
    * @methodOf MediaSliderFullscreenModalController
    * @description
    * This is called when the fullscreen modal opens and the video is loaded
    */
    onVideoLoadedFullscreen() {
        // get current video time and set the time on fullscreen video
        this.$mediaCtrl.getTimeFromVideo(this.$mediaCtrl.slickCurrentIndex, this.$mediaCtrl.slidesWithMedia, (seconds) => {
            if (seconds > 0) {
                this.$mediaCtrl.seekVideo(this.$mediaCtrl.slickCurrentIndex, this.slidesWithMedia, seconds, this.$mediaCtrl.resumeVideo);
            }
        });
    }

    /**
    * @ngdoc method
    * @name MediaSliderFullscreenModalController#cancel
    * @methodOf MediaSliderFullscreenModalController
    * @description
    * close modal
    */
    cancel() {
        this.$mdDialog.hide();
    }
}

MediaSliderFullscreenModalController.$inject = [
    '$element',
    '$mdDialog',
];
