export const USER_CONNECTION_ADD_CONNECTED_USER = 'USER_CONNECTION_ADD_CONNECTED_USER';
export const USER_CONNECTION_ADD_PENDING_USER = 'USER_CONNECTION_ADD_PENDING_USER';
export const USER_CONNECTION_ADD_REQUEST = 'USER_CONNECTION_ADD_REQUEST';
export const USER_CONNECTION_REMOVE_CONNECTED_USER = 'USER_CONNECTION_REMOVE_CONNECTED_USER';
export const USER_CONNECTION_REMOVE_PENDING_USER = 'USER_CONNECTION_REMOVE_PENDING_USER';
export const USER_CONNECTION_REMOVE_REQUEST = 'USER_CONNECTION_REMOVE_REQUEST';

/**
 * Add a user id to the connected users state
 * @param {String} userId User id to add to the connected users state
 */
export const addConnectedUser = (userId) => {
    return {
        type: USER_CONNECTION_ADD_CONNECTED_USER,
        payload: {
            userId,
        },
    };
};

/**
 * Add a user id to the pending users state
 * @param {String} userId User id to add to the pending users state
 */
export const addPendingUser = (userId) => {
    return {
        type: USER_CONNECTION_ADD_PENDING_USER,
        payload: {
            userId,
        },
    };
};

/**
 * Remove a user id from the connected users state
 * @param {String} userId User id to remove from the connected users state
 */
export const removeConnectedUser = (userId) => {
    return {
        type: USER_CONNECTION_REMOVE_CONNECTED_USER,
        payload: {
            userId,
        },
    };
};

/**
 * Remove a user id from the pending users state
 * @param {String} userId User id to remove from the pending users state
 */
export const removePendingUser = (userId) => {
    return {
        type: USER_CONNECTION_REMOVE_PENDING_USER,
        payload: {
            userId,
        },
    };
};

/**
 * Add a user id to the requester state
 * @param {String} userId User id to add to the requester state
 */
export const addRequestingUser = (userId) => {
    return {
        type: USER_CONNECTION_ADD_REQUEST,
        payload: {
            userId,
        },
    };
};

/**
 * Remove a user id to the requester state
 * @param {String} userId User id to remove from the requester state
 */
export const removeRequestingUser = (userId) => {
    return {
        type: USER_CONNECTION_REMOVE_REQUEST,
        payload: {
            userId,
        },
    };
};
