export class SettingsService {
    constructor($http, $pfUser) {
        this.$http = $http;
        this.$pfUser = $pfUser;
    }

    /**
     * Make API call to update the user's password
     * @param  {String}    password the users password
     * @param  {String}    confirm  the users password again
     * @param  {String}    existingPassword  the users existing password
     * @returns {Promise}
     */
    updatePassword(password, confirm, existingPassword) {
        /* some forms have no confirm */
        let confirmPassword = confirm ? confirm : password;

        return this.$http({
            method: 'POST',
            url: '/proxy/users/password',
            data: {
                password: password,
                confirm: confirmPassword,
                existing_password: existingPassword,
            }
        })
        .then((data) => {
            // update the session, reset redis, all that
            this.$pfUser.updateSession();

            let user = this.$pfUser.getUser();

            if (user) {
                user.update({
                    temp_pw: true,
                });
            }
            return data;
        });
    }

    /**
     * Make API call to update the current user's fields
     * @param  {Object} fields Fields to update
     * @return {Promise}
     */
    updateFields(fields) {
        return this.$http({
            method: 'POST',
            url: '/proxy/users/profile',
            data: fields
        })
        .then(() => {
            this.$pfUser.updateSession();
        });
    }

    /**
     * Make API call to update the current user's avatar
     * @param  {String} fileUri File URI to new avatar
     * @return {Promise}
     */
    updateAvatar(fileUri) {
        return this.$http({
            method: 'POST',
            url: '/proxy/users/avatar',
            data: {
                'file_uri': fileUri
            }
        })
        .then((response) => {
            let newAvatar = response.data.url_https;
            this.updateHeaderAvatar(newAvatar);
            this.$pfUser.updateSession();
            return newAvatar;
        });
    }

    /**
     * Make API call to update the delete avatar
     * @return {Promise}
     */
     deleteAvatar() {
        return this.$http({
            method: 'DELETE',
            url: '/proxy/users/avatar'
        })
        .then((response) => {
            let newAvatar = response.data.url_https;
            this.updateHeaderAvatar(newAvatar);
            this.$pfUser.updateSession();
            return newAvatar;
        });
    }

    /**
     * Make API call to update the current user's cover
     * @param  {String} fileUri File URI to new avatar
     * @return {Promise}
     */
    updateCover(fileUri) {
        return this.$http({
            method: 'POST',
            url: '/proxy/users/cover',
            data: {
                'file_uri': fileUri
            }
        })
        .then((response) => {
            let newCover = response.data.url_https;
            this.$pfUser.updateSession();
            return response.data;
        });
    }

    /**
     * Make API call to delete cover image
     * @return {Promise}
     */
     deleteCover() {
        return this.$http({
            method: 'DELETE',
            url: '/proxy/users/cover'
        })
        .then((response) => {
            let newCover = response.data.url_https;
            this.$pfUser.updateSession();
            return response.data;
        });
    }

    /*
    * @ngdoc method
    * @name ProfileEditAvatarController#updateHeaderAvatar
    * @methodOf pfProfileEditAvatar
    * @description updates the avatar image in the header
    * TODO: create a service to manage this, use Redux on the profile
    */
    updateHeaderAvatar(url) {
        let headerAvatar = angular.element(document.querySelector( '#pf-navbar-avatar' ));
        headerAvatar.attr('src',url);
    }


    /**
     * Make API call to update language
     */
    updateLanguage(lang) {
        return this.$http({
            method: 'POST',
            url: '/proxy/users/language',
            data: {
                'language': lang
            }
        })
        .then((response) => {
            this.$pfUser.updateSession();
            return lang;
        });
    }
}

SettingsService.$inject = [
    '$http',
    '$pfUser'
];
