import angular from 'angular';

import pmailCardBtnDirective from './js/directive/pmail-card-btn-directive';
import pmailCardDirective from './js/directive/pmail-card-directive';

import FeedTemplate from './template/card/feed.html'
import './index.scss';

(function() {
    let app = angular.module('portfolium.pmailCard', []);

    // Directive
    app.directive('pfPmailCardBtn', pmailCardBtnDirective);
    app.directive('pfPmailCard', pmailCardDirective);

    app.run([
        '$templateCache',
        $templateCache => {
            $templateCache.put(
                'template/components/pmail-card/template/card/feed.html',
                FeedTemplate,
            );
        },
    ]);
})();

export default 'portfolium.pmailCard';
