export class LayoutService {
    constructor($rootScope, $mdLiveAnnouncer) {
        this.$rootScope = $rootScope;
        this.$mdLiveAnnouncer = $mdLiveAnnouncer;
        this.titlePosfix = ' | Portfolium';
    }

    get title() {
        return this.$rootScope.pageTitle;
    }

    set title(value) {
        if (_.isString(value) && value.indexOf(this.titlePrefix) === -1) {
            value = `${value}${this.titlePosfix}`;
        }
        // set a default title if `value` is undefined
        if (_.isNil(value)) {
            value = 'Portfolium';
        }
        this.$rootScope.pageTitle = `${value}`;
        // Announce title change for screenreaders
        this.$mdLiveAnnouncer.announce(value, 'assertive');
    }
}

LayoutService.$inject = ['$rootScope', '$mdLiveAnnouncer'];
