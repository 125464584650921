import angular from 'angular';
import utilities from 'components/utilities';
import tokenfield from 'components/tokenfield';

export default (function () {
    // delegated event listeners
    $(window).on('beforeunload', onWindowBeforeUnload);
    $(document).on('click', 'body.desktop .modal-link, body.ipad .modal-link', openModalClick);
    $(document).on('click', '.modal-close', closeModal);
    $(document).on('click touchend', '.modal-window', onEdgeClose);
    $(document).on('keyup', onEscapeClose);
    $(document).on('ajax-submit-success', '.modal #message-add-form', onMessageAddFormSuccess);

    function openModalClick(jqEvent) {
        // break js chain
        jqEvent.preventDefault();
        // cache some variables
        let element = $(this),
            fromModal = element.data('from_modal'),
            href = element.data('href') || element.data('pf-href'),
            title = element.data('title'),
            enableHistory = element.hasClass('history'),
            iPad = $('body.ipad'),
            doc = $(document);
        // check for iPad - THIS IS A ONE OFF PRODUCT CHOICE WE MADE TO SHOW EDP MODALS FROM LANDSCAPE IPADS
        if (iPad.length) {
            // check for landscape
            if (doc.width() > 768) {
                // handle the open modal code
                openModal(fromModal, href, title, enableHistory);
                // bind to the window, so if a user rotates we can close the modal
                $(window).on('orientationchange', function(event) {
                    // close it
                    closeModal(event);
                });
            } else {
                // reload the page to the EDP
                window.location = href;
            }
        } else {
            // handle the open modal code
            openModal(fromModal, href, title, enableHistory);
        }
    }

    function openModal(fromModal, href, title, enableHistory, makeSmall) {
        // cache some variables
        let modal = $('.modal'),
            modalState = $('.modal-state'),
            modalContent = $('.modal-content'),
            currentTime = new Date().getTime(),
            hrefNoCache = utilities.updateQueryStringParameter(href, 'preventCache', currentTime);

        modal.insertAfter('.main');
        // sanity check that the URL is NOT from a modal
        if (!fromModal) {
            // state has changed, default remove the modal
            modalState.prop('checked', false);
        }
        // sanity check for the makeSmall flag
        if (makeSmall) {
            // append the class "small" to the modal to make the modal a small popup
            modal.addClass('small');
        }
        // make ajax call
        $.get(hrefNoCache).success(function (data, status, xhr) {
            // get content type
            let contentType = xhr.getResponseHeader('content-type') || '';
            // ensure html
            if (contentType.indexOf('html') > -1) {
                // cache the data content
                let newContent = $(data);
                // set the content
                if (fromModal) {
                    // set the content
                    modalContent.hide().html(newContent).fadeIn();
                } else {
                    // open the modal
                    modalState.prop('checked', true);
                    // set the content
                    modalContent.html(newContent);
                    // remove legacy modal opened class
                    modal.addClass('legacy-modal-opened');
                }
                // make sure newContent had content
                if (newContent.length) {
                    // compile the new content using Angular's compile service
                    angular.element(document).injector().invoke(['$compile', '$rootScope', function ($compile, $rootScope) {
                        let $scope = angular.element(newContent).scope();
                        $compile(newContent)($scope || $rootScope);
                    }]);
                }
                // trigger modal event
                modalContent.trigger('modal-loaded');
                // set focus and accessibility
                _.delay(function() {
                    modal.attr('tabindex', '-1');
                    modal.attr('aria-hidden', 'false');
                    modal.focus();
                }, 100);
                // prevent body scrolling
                $('body').addClass('overflow-hidden md-dialog-is-showing');
                // bind connection search
                tokenfield('#messages-to');
                // bind form validation
                utilities.bindFormValidation();
            }
        });
    }

    function closeModal(jqEvent) {
        // sanity check for an event
        if (jqEvent) {
            // break js chain
            jqEvent.preventDefault();
        }
        let modal = $('.modal');
        // move modal
        modal.insertBefore('.main');
        // close the modal
        $('.modal-state, .modal-inline-state').prop('checked', false);
        // re-enable body scrolling
        $('body').removeClass('overflow-hidden md-dialog-is-showing');
        // remove any specific css modal classes
        $('.modal-window').removeClass().addClass('modal-window');
        // remove the small class from the modal
        modal.removeClass('small');
        // remove legacy modal opened class
        modal.removeClass('legacy-modal-opened');
        // remove tab index
        modal.attr('tabindex', '0');
        // remove aria hidden
        modal.attr('aria-hidden', 'true');
    }

    function onWindowBeforeUnload(jqEvent) {
        // sanity check if the modal is open
        if (isModalOpen()) {
            // make sure the modal is closed before the page unload
            closeModal();
        }
    }

    function onEdgeClose(jqEvent) {
        // find outside modal
        if (isModalOpen() && $(jqEvent.target).closest('.modal-inner').length === 0) {
            // break js chain
            jqEvent.preventDefault();
            // close modal
            closeModal(jqEvent);
        }
    }

    function onEscapeClose(jqEvent) {
        // check for Esc
        if (jqEvent.keyCode === 27) {
            // sanity check if the modal is open
            if (isModalOpen()) {
                // close modal
                closeModal(jqEvent);
            }
        }
    }

    function onMessageAddFormSuccess(jqEvent) {
        // get the json data
        let {json} = jqEvent;
        // show success growler
        utilities.showGrowl(json.alert, json.alert_type);
        // close modal
        closeModal();

        // check if on messages page and reload
        if (window.location.href.includes('/messages')) {
            window.location.assign('/messages');
        }
    }

    function isModalOpen() {
        return ($('.modal-state').prop('checked') || $('.modal-inline-state').prop('checked'));
    }

    return {
        openModal: openModal,
        close: closeModal
    };
}());
