import angular from 'angular';

import './index.scss';

import { SubscriberContactMenuComponent } from './subscriber-contact-menu/subscriber-contact-menu.component';

import pmailMessageDirective from './js/directive/pmail-message-directive';
import pmailInviteDirective from './js/directive/pmail-invite-directive';
import applicationActionDirective from './js/directive/application-actions-directive';
import massActionToggleDirective from './js/directive/mass-actions-toggle-directive';
import massActionsAllDirective from './js/directive/mass-actions-all-directive';
import followingChipDirective from './js/directive/following-chip-directive';
import subscriptionTrialBannerDirective from './js/directive/subscription-trial-banner-directive';

import CompanyAdminService from './js/service/company-admin-service';
import SubscriptionService from './js/service/subscription-service';
import EmployerSearchActionsService from './js/service/subscription-actions-service';
import SubscriptionUpsellService from './js/service/subscription-upsell-service';
import MassActionsService from './js/service/mass-actions-service';
import MessageTemplateService from './js/service/message-template-service';

(function() {
    let app = angular.module('portfolium.component.subscription', []);

    // Components
    app.component('pfSubscriberContactMenu', SubscriberContactMenuComponent);

    // Directives
    app.directive('pfPmailMessage', pmailMessageDirective);
    app.directive('pfPmailInvite', pmailInviteDirective);
    app.directive('pfApplicationActions', applicationActionDirective);
    app.directive('pfMassActionsToggle', massActionToggleDirective);
    app.directive('pfMassActionsAll', massActionsAllDirective);
    app.directive('pfChipFollowing', followingChipDirective);
    app.directive('pfSubscriptionTrialBanner', subscriptionTrialBannerDirective);

    // Services
    app.service('$pfCompanyAdmin', CompanyAdminService);
    app.service('$pfSubscription', SubscriptionService);
    app.service('$pfSubscriptionActions', EmployerSearchActionsService);
    app.service('$pfSubscriptionUpsell', SubscriptionUpsellService);
    app.service('$pfMassActions', MassActionsService);
    app.service('$pfMessageTemplate', MessageTemplateService);
})();

export default 'portfolium.component.subscription';
