/**
 * Manage user's Redis session from the client side
 */
class SessionService {
    constructor($window, $http) {
        this.$http = $http;

        this._data = {};

        this.setSession($window.Portfolium.current_user);
    }

    /**
     * Get the user's session data
     * @return {Object|String} Serialized session data
     */
    get data() {
        return _.isEmpty(this._data) ? '' : this._data;
    }

    /**
     * Update the cached user session data
     * @param {Object} New session data
     */
    setSession(sessionData = {}) {
        this._data = _.assign({}, this._data, sessionData);

        return this.data;
    }

    /**
     * Remove the cached user session data
     * @param {Object} New session data
     */
    removeSession() {
        this._data = {};

        return this.data;
    }

    /**
     * Make API call to update the user's session in Redis
     * @param {Object} [params] optional parameters
     * @returns {Promise} Promises resolved with updated session data (?)
     */
    updateSession(params = {}) {
        return this.$http({
            method: 'POST',
            url: '/proxy/session',
            data: params,
        }).then(response => {
            const sessionData = response.data;
            this.setSession(sessionData);

            return sessionData;
        });
    }
}

SessionService.$inject = ['$window', '$http'];

export default SessionService;
