export class AddEntryFromAssignmentsWarningModalController {
    constructor($mdDialog, $pfEntryEditor, PF_ASSIGNMENT_SERVICES) {
        this.$mdDialog = $mdDialog;
        this.$pfEntryEditor = $pfEntryEditor;
        this.PF_ASSIGNMENT_SERVICES = PF_ASSIGNMENT_SERVICES;
    }

    /**
     * Get the title
     */
    getTitle() {
        switch (this.type) {
            case this.PF_ASSIGNMENT_SERVICES.assignment:
                return 'Start assignment instead?';
            case this.PF_ASSIGNMENT_SERVICES.requirement:
                return 'Start requirement instead?';
            default:
                return '';
        }
    }

    /**
     * Get the body
     */
    getBody() {
        switch (this.type) {
            case this.PF_ASSIGNMENT_SERVICES.assignment:
                return `
                    You're currently viewing an assignment.
                    Do you want to start the assignment or create a new
                    project for your personal portfolio?
                `;
            case this.PF_ASSIGNMENT_SERVICES.requirement:
                return `
                    You're currently viewing a pathway requirement.
                    Do you want to start the requirement or create a new
                    project for your personal portfolio?
                `;
            default:
                return '';
        }
    }

    /**
     * Get the decline button text
     */
    getDeclineText() {
        return 'Create a new project';
    }

    /**
     * Get the accept button text
     */
    getAcceptText() {
        switch (this.type) {
            case this.PF_ASSIGNMENT_SERVICES.assignment:
                return 'Start assignment';
            case this.PF_ASSIGNMENT_SERVICES.requirement:
                return 'Start requirement';
            default:
                return '';
        }
    }

    /**
     * Close the modal, rejecting the promise
     */
    close() {
        this.$mdDialog.cancel();
    }

    /**
     * Start assignment
     */
    startAssignment() {
        let entryId = null;

        switch (this.type) {
            case this.PF_ASSIGNMENT_SERVICES.assignment:
                entryId = _.get(this.assignment, 'user_entry_id');
                break;
            case this.PF_ASSIGNMENT_SERVICES.requirement:
                entryId = _.get(this.assignment, 'entry.id');
                break;
        }

        this.$pfEntryEditor.editAssignment(
            entryId,
            { source: this.source }
        ).finally(() => {
            this.$mdDialog.hide()
        });
    }

    /**
     * Create new entry
     */
    createProject() {
        this.$pfEntryEditor.newEntry({
            bucketId: this.bucketId,
            source: this.source,
            targetEvent: this.$event,
        }).finally(() => {
            this.$mdDialog.hide()
        });
    }
}

AddEntryFromAssignmentsWarningModalController.$inject = [
    '$mdDialog',
    '$pfEntryEditor',
    'PF_ASSIGNMENT_SERVICES',
];
