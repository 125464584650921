/**
 * Skip to landmarks
 */

const toggleElement = (elem, showElem) => {
    return elem.css({
        display: showElem ? 'inline-block' : 'none'
    });
}

export const skipToLandmarkDirective = ($timeout) => {
    return {
        restrict: 'EA',
        link: function (scope, elem, attrs) {
            let { pfSkipTo, pfSkipToBackup } = attrs;
            let landmark = pfSkipTo;
            scope.$on('$stateChangeSuccess', () => {
                /**
                 * Find the landmark and enable/disable the button
                 */
                $timeout(()=>{
                    const foundLandmark = document.querySelector(landmark);
                    if (foundLandmark) {
                        return toggleElement(elem, true);
                    }
                    // if theres a backup go search for it
                    if (pfSkipToBackup) {
                        const foundBackup = document.querySelector(pfSkipToBackup);
                        if (foundBackup) {
                            // Set new landmark so we can use it in the element click function below
                            landmark = pfSkipToBackup;
                            return toggleElement(elem, true);
                        }
                    }
                    return toggleElement(elem, false);
                });

                elem.off('click').on('click', ($event) => {
                    $event.preventDefault();
                    // Setting 'tabindex' to -1 takes an element out of normal
                    // tab flow but allows it to be focused via javascript
                    $(landmark).attr('tabindex', -1).on('blur focusout', () => {
                        // when focus leaves this element,
                        // remove the tabindex attribute
                        $(attrs.href).removeAttr('tabindex');
                    }).focus(); // focus on the content container);
                });
            })
        },
    };
};

skipToLandmarkDirective.$inject = ['$timeout'];
