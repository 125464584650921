import angular from 'angular';

import { StatusWidgetComponent } from './status-widget.component';

const MODULE_NAME = 'portfolium.component.status-widget';

(function() {
    angular
        .module(MODULE_NAME, [])
        // Component
        .component('pfStatusWidget', StatusWidgetComponent);
})();

export default MODULE_NAME;
