import TrialBannerTemplate from '../../template/trial-banner.html';

class SubscriptionTrialBannerController
{
    constructor(
        $scope,
        $pfCookies,
        $pfEnv,
        $timeout,
        $pfSubscription,
        $pfSubscriptionUpsell,
        PF_COOKIES,
    ) {
        this.$pfCookies = $pfCookies;
        this.$pfEnv = $pfEnv;
        this.$timeout = $timeout;
        this.$pfSubscription = $pfSubscription;
        this.$pfSubscriptionUpsell = $pfSubscriptionUpsell;
        this.PF_COOKIES = PF_COOKIES;

        this.isBannerVisible = false;

        this.initialize($scope);
    }

    initialize($scope) {
        $scope.$watch(() => this.$pfSubscription._data, () => {
            // Store days remaining
            this.trialDaysRemaining = this.$pfSubscription.getTrialDaysRemaining();

            if (this.$pfSubscription.isTrial()) {
                // Show the banner if user is on trial
                this.showBanner();
            }
        });
    }

    pluralize(singularString, count) {
        if (count === 1) {
            return singularString;
        } else {
            return singularString + 's';
        }
    }

    /**
     * Show the upsell modal
     * @return {Promise}
     */
    openSubscriptionModal() {
        return this.$pfSubscriptionUpsell.openTrialModal();
    }

    /**
     * Show the trial banner
     */
    showBanner() {
        if (this.$pfCookies.get(this.PF_COOKIES.TRIAL_BANNER_CLOSED)) {
            // user already closed the trial banner this session
            return;
        }
        // set view model flag
        this.isBannerVisible = true;

        // add styling classes
        this.addOpenStyles();
    }

    /**
     * Hide the trial banner
     */
    hideBanner() {
        // drop close banner cookie
        this.$pfCookies.put(
            this.PF_COOKIES.TRIAL_BANNER_CLOSED,
            '1',
            {
                path: '/',
                domain: this.$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
            }
        );
        // remove styling classes
        this.removeOpenStyles();
        // set view model flag
        this.isBannerVisible = false;
    }

    /**
     * Clear the classes that position the banner above content
     */
    removeOpenStyles() {
        $('body').removeClass('pf-trial-banner-visible');
        $('.pf-navbar').removeClass('pf-trial-banner-visible');
        $('.stuck-holder').removeClass('pf-trial-banner-visible');
    }

    /**
     * Add clasees that position the banner above content
     */
    addOpenStyles() {
        $('body').addClass('pf-trial-banner-visible');
        $('.pf-navbar').addClass('pf-trial-banner-visible');
        $('.stuck-holder').addClass('pf-trial-banner-visible');
    }
}

SubscriptionTrialBannerController.$inject = [
    '$scope',
    '$pfCookies',
    '$pfEnv',
    '$timeout',
    '$pfSubscription',
    '$pfSubscriptionUpsell',
    'PF_COOKIES',
];

function subscriptionTrialBannerDirective() {
    return {
        controller: SubscriptionTrialBannerController,
        controllerAs: 'trialCtrl',
        template: TrialBannerTemplate
    };
}

export default subscriptionTrialBannerDirective;
