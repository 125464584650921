/**
 * @ngdoc directive
 * @name pfInfiniteScrollLoader
 * @module portfolium.component.infiniteScroll
 * @restrict A
 * @description
 * If operation execution is paused for the parent `pf-infinite-scroll` or
 * `pf-redux-infinite-scroll` directive, clicking this element will start
 * another batch of operations.
 */
export const infiniteScrollLoaderDirective = () => {
    return {
        restrict: 'A',
        require: ['?^pfInfiniteScroll', '?^pfReduxInfiniteScroll'],
        link: (scope, elem, attrs, ctrls) => {
            const ctrl = ctrls[0] || ctrls[1];

            if (!ctrl) {
                throw new Error('[pfInfiniteScrollLoader] No infinite scroll controller found!');
            }

            elem.on('click', () => {
                ctrl.loadMore();
            });
        },
    };
};
