import {CredlyBadgeType} from './credly-badge/credly-badge-type';

/**
 * @implements ImportSource
 * @type CredlySource
 */
export const CredlySource = {
    name: 'credly',
    displayName: 'Credly',
    logoUrl: 'images/partners/credly.png',
    isConnected: false,
    supportedTypes: [
        CredlyBadgeType,
    ],
};
