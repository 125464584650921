import angular from 'angular';
import AttachmentPlayerTemplate from './attachment-player.component.html';

/**
 * @ngdoc controller
 * @name AttachmentPlayerController
 * @module portfolium.component.mediaSlider
 **/
class AttachmentPlayerController {
    constructor(
        $element,
        $timeout,
    ) {
        this.$element = $element;
        this.$timeout = $timeout;
        // some variables to configure the player
        this.provider = null;
        this.url = null;
        this.mediaElem = null;
    }

    $onInit() {
        if (!_.isEmpty(this.attachment.meta.source_url)) {
            this.provider = this.attachmentViewerCtrl.getProvider(this.attachment);
            // set the url to safeUrl, which is allowed by $sce.trustAsResourceUrl
            // TODO - (angular 1.6.x) in ng 1.6.x, we won't need to use $sce.trustAsResourceUrl
            //        since sce restrictions are relaxed in sce for video and audio
            this.url = !_.isEmpty(this.provider.trustedUrl) ? this.provider.trustedUrl : undefined;
            this.mediaElem = this.$element.find('video');
            // set the options
            const options = {
                youtube: {
                    modestbranding: 1,
                    controls: 1,
                    playsinline: 1,
                    enablejsapi: 1,
                    showinfo: 0,
                    rel: 0,
                },
            };
            // register the media element with the parent component
            // currently using $timeout to ensure that the element has
            // a valid url property, so that need to be done on next digest
            // TODO - (angular 1.6.x) $doCheck might be more suitable for this use case
            this.$timeout(() => {
                this.attachmentViewerCtrl.registerMediaElement(this.mediaElem, false, options);
            }, 0);
        }
    }

    $onChanges(changed) {
        // fix for hidden soundcloud iframe
        if (!_.isEmpty(changed.mediaElement.currentValue)) {
            const el = angular.element(this.mediaElement);
            const id = el.attr('id');
            const iframe = el.find(`#${id}_soundcloud_iframe`);
            iframe.css('visibility', 'visible');
        }
    }
}

AttachmentPlayerController.$inject = [
    '$element',
    '$timeout',
];

/**
 * @ngdoc component
 * @name AttachmentPlayerComponent
 * @module portfolium.component.mediaSlider
 * @description Attachment slides in media slider for urls (websites and videos)
 *
 * @param {object}    pfAttachment    The attachment object
 * @param {boolean}   pfIsMobile      A flag used to determine if the browser is on a mobile device
 * @param {object}    pfMediaElement  The media element that is created by the MediaElement framework
 **/
export const AttachmentPlayerComponent = {
    require: {
        attachmentViewerCtrl: '^pfAttachmentViewer',
    },
    bindings: {
        attachment: '<pfAttachment',
        isMobile: '<pfIsMobile',
        mediaElement: '<pfMediaElement',
    },
    controller: AttachmentPlayerController,
    controllerAs: '$ctrl',
    template: AttachmentPlayerTemplate,
};
