import angular from 'angular';

import { BreadcrumbsComponent } from './breadcrumbs.component';

const MODULE_NAME = 'portfolium.component.breadcrumbs';

(function() {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfBreadcrumbs', BreadcrumbsComponent);
})();

export default MODULE_NAME;
