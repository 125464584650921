export const COMPANY_DETAILS_COMPANY_REQUEST = 'COMPANY_DETAILS_COMPANY_REQUEST';
export const COMPANY_DETAILS_UPDATE_COMPANY  = 'COMPANY_DETAILS_UPDATE_COMPANY';
export const RESET_COMPANY                   = 'RESET_COMPANY';

export const companyRequest = () => {
    return {
        type: COMPANY_DETAILS_COMPANY_REQUEST,
    };
};

export const updateCompany = (payload) => {
    return {
        type: COMPANY_DETAILS_UPDATE_COMPANY,
        payload,
    };
};

export const resetCompany = () => {
    return {
        type: RESET_COMPANY,
    };
};
