import angular from 'angular';

import {ResumeUploadModalComponent} from './resume-upload-modal/resume-upload-modal.component';
import {ResumeUploadService} from './resume-upload.service';
import {ResumeUploadComponent} from './resume-upload.component';

(function () {
    let app = angular.module('portfolium.component.resume', []);

    // Components
    app.component('pfResumeUpload', ResumeUploadComponent)
    app.component('pfResumeUploadModal', ResumeUploadModalComponent)
    // Services
    app.service('$pfResumeUpload', ResumeUploadService);

}());

export default 'portfolium.component.resume';
