import {UPDATE_COMPETENCY_OPTIONS} from '../action/job-competency-search';

export const jobCompetencySearch = (state = [], action = {}) => {
    switch (action.type) {
        case UPDATE_COMPETENCY_OPTIONS:
            return action.payload.categories
                .map((category) => {
                    return {
                        id: category.id,
                        label: category.title,
                        color: category.color,
                        competencies: action.payload.competencies
                            .filter((comp) => {
                                return comp.fk_list_competency_id === category.id;
                            })
                            .map((comp) => {
                                return _.assign({}, comp, {
                                    disabled: action.payload.selected.indexOf(comp.id) > -1,
                                });
                            }),
                    };
                })
                .filter(optionGroup => optionGroup.competencies.length > 0);
        default:
            return state;
    }
};
