export const JOB_TRAFFIC_SOURCES =  {
    autoMail: 'automail',
    jobAlerts: 'jobalerts',
    inviteToApply: 'invitetoapply',
    suggestions: 'suggestions',
    companyPage: 'companypage',
    searchJob: 'searchjob',
    searchFeaturedJob: 'searchfeaturedjob',
    jobsPage: 'jobspage',
    jobPageFeatured: 'jobpagefeatured',
    networkJobs: 'networkjobs',
    jobsInterested: 'jobsinterested',
    jobsApplied: 'jobsapplied',
};
