import UserResultTemplate from './user-result.component.html';

/**
 * @ngdoc component
 * @name pfUserResult
 * @module portfolium.component.pfUserResult
 * @description controller for pfUserResult
 * @param {Object} [pf-user] user information to display
 */
class UserResultController {
    /**
    * @ngdoc method
    * @name UserResultController#$constructor
    * @methodOf UserResultController
    * @description get the current status of the avatar
    */
    constructor($pfUser){
        this.$pfUser = $pfUser;
    }

    $onInit() {
        let auxEntries = [];
        // sanity check for top entries
        if (!_.isEmpty(this.user.top_entries)) {
            // loop 3 times
            for (let i = 0; i < 3; i++) {
                // sanity check if the top entries field had entries at the index position, otherwise default a unique object
                let entry = !_.isEmpty(this.user.top_entries[i]) ? this.user.top_entries[i] : {
                    id: (i * -1), // weird trick to keep the object unique in the set so ng-repeat doesn't yell at us
                };
                // push entry
                auxEntries.push(entry);
            }
        } else {
            // push 3 empty entries
            auxEntries.push({});
            auxEntries.push({});
            auxEntries.push({});
        }

        this.entries = auxEntries;
    }

    /**
     * checks if the current user is the logged in user
     * @return {Boolean}
     */
    get itsMe() {
        const currentUser = this.$pfUser.getUser();
        if (!currentUser) {
            return false;
        }

        return currentUser.id === this.user.id;
    }

    /**
    * @ngdoc method
    * @name UserResultController#$isMobile
    * @methodOf UserResultController
    * @description Checks if it is mobile
    * @return {Boolean}
    */
    get isMobile() {
        return this.$pfUser.isMobile();
    }
}

UserResultController.$inject = ['$pfUser'];

export const UserResultComponent = {
    bindings: {
        user: '<',
    },
    template: UserResultTemplate,
    controller: UserResultController,
    controllerAs: '$ctrl'
};
