import BaseModalController from 'components/modal/js/controller/base-modal-controller';

/**
 * @ngdoc service
 * @module portfolium.components.addEntryModal
 * @name $pfAddEntryModal
 */
export class AddEntryModalService {
    constructor($mdDialog, $q) {
        this.$mdDialog = $mdDialog;
        this.$q = $q;
    }

    /**
     * opens add entry modal
     * @param {event|null}  config.targetEvent event that triggered to open the editor
     * @return {Promise}
     */
    open({targetEvent} = {}) {
        return this.$mdDialog.show({
            parent: angular.element(document.body),
            multiple: true,
            template: `
                <md-dialog class="pf-add-entry-modal"
                     aria-label="Add Entry">
                    <pf-add-entry-modal
                         on-close="$ctrl.hide(attachment)">
                    </pf-add-entry-modal>
                </md-dialog>`,
            clickOutsideToClose: false,
            controller: BaseModalController,
            controllerAs: '$ctrl',
            bindToController: true,
            targetEvent,
            focusOnOpen: false,
        }).then(attachment => [attachment]);
    }
}

AddEntryModalService.$inject = [
    '$mdDialog',
    '$q',
];
