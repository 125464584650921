const defaultDistance = 25;
const defaultCount = 0;

export class JobSearchService {
    constructor($q, $http, $state) {
        this.$q = $q;
        this.$http = $http;
        this.$state = $state;
        this.results = [];
        this.spotlightedJobs = [];
        this.count = defaultCount;
        this.defaultDistance = defaultDistance;
    }

    /**
     * Parse state params to build query, then fetch search results
     * @param   {Object}  params State params object to parse
     * @returns {Promise}
     */
    search(params = {}) {

        // default distance
        params.distance = params.distance ? params.distance : this.defaultDistance;

        // if there is no location strip distance out
        if (!params.lat && !params.lon) {
            params.distance = null;
        }

        let queryObj = {
            q: params.q,
            sort: params.s,
            limit: 10,
            offset: params.offset,
            lat: params.lat,
            lon: params.lon,
            distance: params.distance,
            type: params.type,
            industry: params.industry,
            function: params.function,
            company: params.company,
            anywhere: params.anywhere
        };

        return this.getQueryResults(queryObj);
    }

    /**
     * API call for search results query
     * @param  {Object}  query Query parameters
     * @return {Promise}
     */
    getQueryResults(query) {
        return this.$http({
            method: 'GET',
            url: '/proxy/es/jobs',
            params: query,
        })
        .then((response) => {
            this.results = response || {};
            // get the count
            return this.results;
        });
    }

    /**
     * API call for spotlighted jobs
     * @return {Promise}
     */
    getSpotlighted() {
        let query = {
            spotlighted: true,
            sort: 'score',
            limit: 3
        };

        if (this.spotlightedJobs.length) {
            return this.$q.resolve(this.spotlightedJobs);
        }

        return this.$http({
            method: 'GET',
            url: '/proxy/jobs/feed',
            params: query
        })
        .then((response) => {
            let results = response.data || {};

            this.spotlightedJobs = results;

            return results;
        });
    }

    /**
     * Search companies
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    queryCompanies(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/search/companies',
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Search industries
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    queryIndustries(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/search/industries',
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Search functions
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    queryJobFunctions(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/search/functions',
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get functions
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    getJobFunctions(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/system/functions',
            headers: {
                'PF-CACHE': true
            },
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Return if we are on a search result
     * @return Boolean
     */
    isSearch() {
        return _.values(this.$state.params).some(param => !_.isNil(param));
    }

    /**
     * Clear the keyword search
     */
    clear() {
        this.$state.go('.', {
            q: null,
            locale: null,
            offset: null,
            lat: null,
            lon: null,
            s: null,
            type: null,
            industry: null,
            function: null,
            company: null,
            distance: null,
            anywhere: null,
        });
    }
 }

JobSearchService.$inject = ['$q', '$http', '$state'];
