import moment from 'moment-timezone';

/**
 * Create the timezone cookie used by CI
 */
export const timezoneCookie = ($pfCookies, $pfEnv, PF_COOKIES) => {
    const timezone = moment.tz.guess(true);
    $pfCookies.put(
        PF_COOKIES.TIMEZONE,
        timezone,
    {
        path: '/',
        domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
    });
};

timezoneCookie.$inject = ['$pfCookies', '$pfEnv', 'PF_COOKIES'];
