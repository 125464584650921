import { AddEntryFromAssignmentsWarningModalController } from '../add-entry-from-assignments-warning-modal/add-entry-from-assignments-warning-modal.controller';
import AddEntryFromAssignmentsWarningModalTemplate from '../add-entry-from-assignments-warning-modal/add-entry-from-assignments-warning-modal.html';

function addEntryDirective(
    $window,
    $location,
    $mdDialog,
    $pfUser,
    $pfEntryEditor,
    $state,
    $ngRedux,
    $pfBaseAssignmentUtilities,
    PF_ASSIGNMENT_SERVICES,
) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            const user = $pfUser.getUser();
            const bucketId = attrs.pfBucketId;
            const source = attrs.pfSource;

            elem.on('click keydown keypress', ($event) => {
                if (!user) {
                    // Don't allow unauthed users
                    return;
                }

                if ($event.which !== 1 && $event.which !== 13 && !_.isUndefined($event.which)) {
                    // Only listen for clicks and enter key
                    return;
                }

                // Prevent default click event
                $event.preventDefault();

                const state = $ngRedux.getState();
                let assignment, type;

                /**
                 * Assignments page flow
                 * Warn user if attempting to open project editor from assignments/assignments page
                 */
                if ($state.includes('assignment')) {
                    type = PF_ASSIGNMENT_SERVICES.assignment;
                    // get current assignment
                    assignment = _.get(state, 'assignment.meta.assignment');
                } else if ($state.includes('requirement')) {
                    type = PF_ASSIGNMENT_SERVICES.requirement;
                    // get current assignment
                    assignment = _.get(state, 'pathways.currentRequirement.meta.submission');
                }

                // open confirmation modal for assignments
                if (!_.isEmpty(assignment)) {
                    const isNotStarted = $pfBaseAssignmentUtilities.isNotStarted(assignment);

                    // make sure the assignment has not been started
                    if (isNotStarted) {
                        const dialog = {
                            template: AddEntryFromAssignmentsWarningModalTemplate,
                            controller: AddEntryFromAssignmentsWarningModalController,
                            bindToController: true,
                            targetEvent: $event,
                            controllerAs: '$ctrl',
                            clickOutsideToClose: true,
                            locals: {
                                $event,
                                bucketId,
                                source,
                                assignment,
                                type,
                            },
                        };

                        // Show a confirmation dialog
                        return $mdDialog.show(dialog);
                    }
                }

                // create new entry
                $pfEntryEditor.newEntry({
                    bucketId,
                    source,
                    targetEvent: $event,
                });
            });
        }
    };
}

addEntryDirective.$inject = [
    '$window',
    '$location',
    '$mdDialog',
    '$pfUser',
    '$pfEntryEditor',
    '$state',
    '$ngRedux',
    '$pfBaseAssignmentUtilities',
    'PF_ASSIGNMENT_SERVICES',
];

export default addEntryDirective;
