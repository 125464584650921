import angular from 'angular';

import {ProfileRankComponent} from './profile-rank.component';

import './profile-rank.component.scss';

const MODULE_NAME = 'portfolium.components.profileRank';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfProfileRank', ProfileRankComponent);
}());

export default MODULE_NAME;
