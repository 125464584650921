import EntryCardsTemplate from '../../template/entry-cards.html';

function entryCardsDirective() {
    return {
        restrict: 'EA',
        scope: {
            'entries': '=pfEntries',
            'categories': '=pfCategories',
            'showErrors': '=pfShowErrors'
        },
        link: function (scope, elem, attrs) {
        },
        controller: ['$scope', function ($scope) {
            this.removeEntry = function (entry) {
                _.remove($scope.entries, {$$hashKey: entry.$$hashKey});
            };
        }],
        template: EntryCardsTemplate
    };
}

export default entryCardsDirective;
