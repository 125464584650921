import angular from 'angular';

import Routes from './js/routes';
import stateChangeFocusDirective from './js/directive/state-change-focus-directive';
import buttonLoadingDirective from './js/directive/button-loading-directive';
import autofillSubmit from './js/directive/auto-fill-directive';
import domainFilter from './js/filter/domain-filter.js';
import SignInAuthService from './js/service/sign-in-auth-service';
import SignInService from './js/service/sign-in-identity-service';
import SignInHistoryService from './js/service/sign-in-history-service';

import './index.scss';

(function() {
    let app = angular.module('portfolium.component.simpleSignIn', []);

    // Config
    app.config(Routes);

    // Directives
    app.directive('pfStateChangeFocus', stateChangeFocusDirective);
    app.directive('pfButtonLoading', buttonLoadingDirective);
    app.directive('pfAutoFill', autofillSubmit);

    // Filters
    app.filter('domain', domainFilter);

    // Service
    app.service('$pfSignInAuth', SignInAuthService);
    app.service('$pfSignInIdentity', SignInService);
    app.service('$pfSignInHistory', SignInHistoryService);
})();

export default 'portfolium.component.simpleSignIn';
