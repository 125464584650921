import angular from 'angular';

class ApplicationActionBtnController
{
    constructor(
        $pfSubscriptionActions,
        $pfToast,
        $pfUser,
        $window,
        $pfClipboard,
    ) {
        this.$pfSubscriptionActions = $pfSubscriptionActions;
        this.$pfToast = $pfToast;
        this.$pfUser = $pfUser;
        this.$window = $window;
        this.$pfClipboard = $pfClipboard;

        if (!this.offset) {
            this.offset = 0;
        }
    }

    /**
     * @description Copy the application url to the clipboard
     * @param {Event} $event ngClick event
     */
    shareApplication($event) {
        this.$pfClipboard.copyElementText(this.shareSelector, 'Application URL was copied to clipboard!');
    }

    /**
     * @description Show the users resume
     * @param {Event} $event ngClick event
     */
    viewResume($event) {
        if (this.user.resume) {
            this.$pfSubscriptionActions.viewResume(this.user, this.user.resume, $event);
        } else {
            this.$pfToast.error('Something went wrong with finding the resume.');
        }
    }
}

ApplicationActionBtnController.$inject = [
    '$pfSubscriptionActions',
    '$pfToast',
    '$pfUser',
    '$window',
    '$pfClipboard',
];

function applicationActionDirective() {
    return {
        restrict: 'E',
        scope: {
            user: '=pfUser',
            shareSelector: '@pfShareSelector',
            offset: '@pfOffset',
            class: '@pfClass',
        },
        bindToController: true,
        controller: ApplicationActionBtnController,
        controllerAs: 'ctrl',
        template: `
            <md-menu md-offset="0 56">
                <md-button md-no-ink
                     pf-stop-propagation
                     aria-label="Open options menu"
                     class="md-icon-button"
                     ng-class="::ctrl.class"
                     ng-click="$mdMenu.open()">
                    <md-icon>more_vert</md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item>
                        <md-button md-menu-align-target ng-click="ctrl.shareApplication($event)">
                            <div layout="row" flex>
                                <md-icon md-menu-align-target>share</md-icon>
                                <p flex>{{ "Share Application" | i18n }}</p>
                            </div>
                        </md-button>
                    </md-menu-item>
                    <md-menu-item ng-if="ctrl.user.resume">
                        <md-button ng-click="ctrl.viewResume($event)">
                            <div layout="row" flex>
                                <md-icon md-menu-align-target>file_download</md-icon>
                                <p flex>{{ "Download Resume" | i18n }}</p>
                            </div>
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        `
    };
}

export default applicationActionDirective;
