export const TASK_DATA_FETCH = 'TASK_DATA_FETCH';
export const TASK_DATA_FETCH_SUCCESS = 'TASK_DATA_FETCH_SUCCESS';
export const TASK_PATHWAYS_FETCH_SUCCESS = 'TASK_PATHWAYS_FETCH_SUCCESS';
export const TASK_COURSES_FETCH_SUCCESS = 'TASK_COURSES_FETCH_SUCCESS';
export const TASKS_RESET = 'PATHWAY_RESET';

/**
 * Initiates fetch of data, sets fetching to true.
 * */
export const fetchData = () => {
    return {
        type: TASK_DATA_FETCH,
    };
};

/**
 * Fetch data success, sets fetching to true.
 * */
export const onFetchDataSuccess = () => {
    return {
        type: TASK_DATA_FETCH_SUCCESS,
    };
};

/**
 * Assigns and returns fetched pathways
 * */
export const onPathwaysFetchSuccess = (pathways) => {
    return {
        type: TASK_PATHWAYS_FETCH_SUCCESS,
        payload: {
            pathways,
        },
    };
};

/**
 * Assigns and returns fetched courses
 * */
export const onCoursesFetchSuccess = (courses) => {
    return {
        type: TASK_COURSES_FETCH_SUCCESS,
        payload: {
            courses,
        },
    };
};

/**
 * Resets Pathway
 * */
export const resetTasks = () => {
    return {
        type: TASKS_RESET,
    };
};
