import angular from 'angular';

import {JobApplyCenterComponent} from './job-apply-center.component';
import {JobApplyCenterPanelService} from './job-apply-center-panel.service';

const MODULE_NAME = 'portfolium.component.job.jobApplyCenter';

(function() {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfJobApplyCenter', JobApplyCenterComponent)
        // Services
        .service('$pfJobApplyCenterPanel', JobApplyCenterPanelService);
}());

export default MODULE_NAME;
