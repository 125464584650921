import ProgressBarTemplate from './progress-bar.component.html';
import './progress-bar.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component
 * @name ProgressBarController
 */
class ProgressBarController {
    constructor($element) {
        this.$element = $element;
    }

    determineInnerWidth(){
        return { 'width': `${this.percentCompleted}%` }
    }
}

ProgressBarController.$inject = ['$element'];

/**
 * @ngdoc component
 * @module portfolium.component
 * @name ProgressBarComponent
 * @description displays progress bar, with dynamic background color.
 *
 * @param {string} colorClass
 * @param {string} width
 */
export const ProgressBarComponent = {
    bindings: {
        colorClass: '@?',
        percentCompleted: '@',
    },
    template: ProgressBarTemplate,
    controller: ProgressBarController,
    controllerAs: '$ctrl',
};
