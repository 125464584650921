import { combineReducers } from 'redux';

import {
    ASSIGNMENT_FETCH,
    ASSIGNMENT_FETCH_SUCCESS,
    ASSIGNMENT_RESET,
} from './assignment.actions';

const initialState = {
    fetching: false,
    assignment: {},
    network: {},
    feedback: [],
    actions: {},
    skills: [],
}

const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case ASSIGNMENT_FETCH:
            return _.assign({}, state, {
                fetching: true,
            });
        case ASSIGNMENT_FETCH_SUCCESS:
            const { assignment } = action.payload;
            const assignment_skills =  _.get(assignment, 'assignment_skills') || [];
            return _.assign({}, state, {
                assignment,
                actions: assignment.actions,
                network: assignment.network,
                feedback: assignment.feedback,
                skills: _.map(assignment_skills, skill => { return { skill } }),
                fetching: false,
            });
        case ASSIGNMENT_RESET:
            return initialState;
        default:
            return state;
    }
};

export const assignment = combineReducers({
    meta,
});
