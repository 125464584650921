export const NOTIFICATIONS_GROUP_NAMES = {
    '1': 'Courses',
    '2': 'Jobs',
    '3': 'My Network',
    '4': 'My Portfolio',
    '5': 'My Profile',
    '6': 'Pathways',
    '7': 'TalentMatch',
    '8': 'Tasks',
};

export const NOTIFICATIONS_LABELS = {
    'email_application': 'Someone applies to my job',
    'email_assignment_canvas_import': 'I import assignments from Canvas',
    'email_badge_credly': 'I import badge(s) from Credly',
    'email_badge_badgr': 'I import badge(s) from Badgr',
    'email_changed': 'I change the email on my profile',
    'email_comment': 'Someone comments on my project or a project I\'m tagged in',
    'email_completed_pathway': 'I complete a pathway',
    'email_connection_accept_request': 'Someone accepts my connection request',
    'email_connection_send_request': 'Someone sends me a connection request',
    'email_employer': 'Someone follows my company',
    'email_enter_challenge': 'I enter a challenge',
    'email_entry_publish': 'I publish a project',
    'email_entry_likes': 'My project is viewed more than 50 times',
    'email_facebook': 'My facebook friend joins Portfolium',
    'email_job_apply_confirmation': 'I apply for a job',
    'email_like': 'Someone likes my project, or a project I\'m tagged in',
    'email_linkedin': 'I link my LinkedIn account',
    'email_message': 'Someone sends me a message or replies to my message',
    'email_network': 'My request to join a network is approved',
    'email_pathway_teammember': 'I\'m added to a Pathway as a coordinator or reviewer',
    'email_pathway_reviews': 'I have pending reviews on Pathways',
    'email_pending_assignments': 'There are pending assignments to review for courses',
    'email_referral': 'Person I referred joins Portfolium',
    'email_saved_search': 'There are users matching my search criteria',
    'email_secondary_faculty_confirmation': 'I\'m added to a course as a secondary faculty',
    'email_secondary_faculty_request_approve': 'Someone requests to be a secondary faculty on one of my courses',
    'email_secondary_faculty_request_pending': 'My request to be a secondary faculty is pending',
    'email_tag': 'Someone tags me as a teammate on their project',
    'email_tasks_revision': 'Reviewer requests revisions on an assignment',
    'email_tasks_reminder': 'I have pending tasks to complete on Pathways and/or Courses',
    'email_tasks_course_enroll': 'I\'m added to a course',
    'email_job_alerts': 'An employer wants me to apply for a job',
    'email_artifacts_import_completed_email': 'My artifacts have been uploaded successfully',
    'email_tasks_pathway_enroll': 'I\'m added to a pathway',
};
