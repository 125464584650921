import angular from 'angular';

import EntriesModule from './entries/entries.module';
import FilestackModule from './filestack/filestack.module';

const MODULE_NAME = 'portfolium.core.services';

(function () {
    angular.module(MODULE_NAME, [
        EntriesModule,
        FilestackModule,
    ]);
}());

export default MODULE_NAME;
