import BlankEntryCardTemplate from '../../template/blank-entry-card.html';

function blankEntryCardDirective() {
    return {
        restrict: 'E',
        template: BlankEntryCardTemplate
    };
}

export default blankEntryCardDirective;
