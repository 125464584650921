export const ENTRY_VISIBILITY_TYPES_MAPPED = {
    public: '1',
    members: '2',
    hidden: '3',
    connections: '4',
};

export const ENTRY_VISIBILITY_TYPES = [
    {
        id: ENTRY_VISIBILITY_TYPES_MAPPED.public,
        name: 'public',
        displayName: 'Public',
        description: 'Anyone on or off Portfolium',
        descriptionAlt: 'Anyone on or off Portfolium',
        visibleTo: 'everyone on Portfolium',
        visibleToAlt: 'everyone on Portfolium',
        icon: 'public',
    },
    {
        id: ENTRY_VISIBILITY_TYPES_MAPPED.members,
        name: 'members',
        displayName: 'Portfolium Members',
        description: 'Anyone on Portfolium',
        descriptionAlt: 'Anyone on Portfolium',
        visibleTo: 'all members on Portfolium',
        visibleToAlt: 'all members on Portfolium',
        icon: 'people_outline',
    },
    {
        id: ENTRY_VISIBILITY_TYPES_MAPPED.connections,
        name: 'connections',
        displayName: 'Connections',
        description: 'Your connections on Portfolium',
        descriptionAlt: 'Your connections on Portfolium',
        visibleTo: 'your connections on Portfolium',
        visibleToAlt: 'your connections on Portfolium',
        icon: 'group',
    },
    {
        id: ENTRY_VISIBILITY_TYPES_MAPPED.hidden,
        name: 'hidden',
        displayName: 'Private',
        description: 'Only you',
        descriptionAlt: 'Only you and your teammates',
        visibleTo: 'only you',
        visibleToAlt: 'only you and your teammates',
        icon: 'lock_outline',
    },
];
