import angular from 'angular';
import ngRedux from 'ng-redux';
import ngReduxRouter from 'redux-ui-router';
import vcRecaptcha from 'angular-recaptcha';
import $script from 'scriptjs';
// this is so we can load the EDP files within the PHP env, see entry-detail.directive.js
// we can move this to app-spa.js once it's no longer needed for PHP pages

import './js/config/jquery-event-bindings.config';
import components from 'components';
import ocLazyLoad from 'oclazyload';
import CoreModule from '../core/core.module';
import addEntry from '../add-entry';
import entryDetails from '../entry-details/entry-details.module';

import { LayoutService } from './js/service/layout.service';
import { LazyLoadService } from './js/service/lazy-load.service';
import { angularSettings } from './js/config/angular-settings.config';
import { createStore } from './js/config/create-store.config';
import { html5Mode } from './js/config/html5-mode.config';
import { loadingSplash } from './js/config/loading-splash.config';
import { themes } from './js/config/themes.config';
import { timezoneCookie } from './js/config/timezone-cookie.config';

import '../main/index.scss';
import './sass/layout.scss';

import { skipToLandmarkDirective } from './js/directive/skip-to-landmark.directive';
import {FeatureSwitchService} from "core/services/features.service";

const MODULE_NAME = 'portfolium';

(function() {
    // bootstrap environment from global scope
    const { PF_GOOGLE_MAPS_KEY } = window.Portfolium.environment;
    // Load external scripts asynchronously
    $script(
        `//maps.google.com/maps/api/js?libraries=places&key=${PF_GOOGLE_MAPS_KEY}`,
        'googleMaps',
    );
    $script(`https://www.google.com/recaptcha/api.js`, 'googleReCAPTCHA');

    angular
        .module(MODULE_NAME, [
            // Internal dependencies
            CoreModule,
            components,
            addEntry,
            entryDetails,
            // External dependencies
            ngRedux,
            ngReduxRouter,
            ocLazyLoad,
            vcRecaptcha,
        ])
        // Configs
        .config(angularSettings)
        .config(createStore)
        .config(html5Mode)
        .config(themes)
        // Service
        .service('$pfLayout', LayoutService)
        .service('$pfLazyLoad', LazyLoadService)
        .service('$pfFeatures', FeatureSwitchService)
        // Directives
        .directive('pfSkipToLandmark', skipToLandmarkDirective)
        // Runtime
        .run(timezoneCookie)
        .run(loadingSplash);
})();

export default MODULE_NAME;
