import EntryViewerBadgesTemplate from './entry-viewer-badges.component.html';
import './entry-viewer-badges.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerBadgesController
 */
class EntryViewerBadgesController {
    /**
     * Determine in which conditions show the badges
     * @return {boolean}
     */
    showBadges() {
        if (this.isLoading) {
            return false;
        }
        if (this.isPreview) {
            return false;
        }
        if (this.isSpotlighted) {
            return true;
        }
        if (this.isExpertPick) {
            return true;
        }
        if (this.isEntryOfTheWeek) {
            return true;
        }
        return false;
    }

    /**
     * Triggers callback for on view badge and redirects to tags
     * @param  {object} liker
     */
    viewBadge(badge) {
        const event = {
            title: 'Badge Selected',
            properties: {
                sourceName: badge,
                clickSource: 'prEDP Content',
                userAction: 'click',
            },
        };
        this.onViewBadge({ event });
    }
}

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerBadgesComponent
 * @description Display list of likers in the entry viewer sidebar
 *
 * @param {boolean} isLoading Is the data currently loading?
 * @param {boolean} isPreview Is the user viewing a preview?
 * @param {string} isSpotlighted Is the entry spotlighted?
 * @param {string} isExpertPick Is the entry an expert pick?
 * @param {string} isEntryOfTheWeek Is the entry of the week?
 * @param {callback} onViewBadge trigger when a liker is clicked
 */
export const EntryViewerBadgesComponent = {
    bindings: {
        isLoading: '<',
        isPreview: '<',
        isSpotlighted: '<',
        isExpertPick: '<',
        isEntryOfTheWeek: '<',
        onViewBadge : '&',
    },
    controller: EntryViewerBadgesController,
    controllerAs: '$ctrl',
    template: EntryViewerBadgesTemplate,
};
