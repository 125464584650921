import {EntryViewerListController} from '../entry-viewer-list.controller';
import EntryViewerTagsTemplate from './entry-viewer-tags.component.html';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerTagsController
 */
class EntryViewerTagsController extends EntryViewerListController {
    constructor($element, $timeout) {
        super($element, $timeout);
    }

    $onChanges() {
        if (!this.tags || !this.tags.length) {
            return;
        }
        this.listLength = this.tags.length;
    }

    /**
     * Triggers callback for on view tag and redirects to tags
     * @param  {object} tag
     */
    viewTag(tag) {
        const event = {
            title: 'Tag Selected',
            properties: {
                sourceName: tag.tag,
                clickSource: 'prEDP Sidebar',
                userAction: 'click',
            },
        };
        this.onViewTag({ event });
    }
}

EntryViewerTagsController.$inject = [
    '$element',
    '$timeout',
];

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerTagsComponent
 * @description Display list of tags in the entry viewer sidebar
 *
 * @param {boolean} isPreview Is the user viewing a preview?
 * @param {object[]} tags List of tags objects
 * @param {callback} onViewTag trigger when a tag is clicked
 */
export const EntryViewerTagsComponent = {
    bindings: {
        'isPreview': '<',
        'tags': '<',
        'onViewTag' : '&',
    },
    controller: EntryViewerTagsController,
    controllerAs: '$ctrl',
    template: EntryViewerTagsTemplate,
};
