import {PmailCenterPanelComponent} from './pmail-center-panel.component';
import {PmailCenterPanelService} from './pmail-center-panel.service';
import {PmailComposerComponent} from './pmail-composer/pmail-composer.component';
import {PmailValidationComponent} from './pmail-validation/pmail-validation.component';

const MODULE_NAME = 'portfolium.component.pmail-center';

(function () {
    angular.module(MODULE_NAME, [])

    // Components
    .component('pfPmailCenterPanel', PmailCenterPanelComponent)
    .component('pfPmailComposer', PmailComposerComponent)
    .component('pfPmailValidation', PmailValidationComponent)

    // Services
    .service('$pfPmailCenterPanel', PmailCenterPanelService);

}());

export default MODULE_NAME;
