export const PATHWAY_REQUIREMENT_SUBMISSION_SELECT = 'PATHWAY_REQUIREMENT_SUBMISSION_SELECT';
export const PATHWAY_REQUIREMENT_SUBMISSION_RESET = 'PATHWAY_REQUIREMENT_SUBMISSION_RESET';
export const PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_DESCRIPTION_TOGGLE = 'PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_DESCRIPTION_TOGGLE';
export const PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_ADD_TO_LIST = 'PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_ADD_TO_LIST';

export const pathwayRequirementSubmissionPanelActionTypes = {
    'PAGE_INIT': 'PATHWAY_REQUIREMENT_SUBMISSION_PAGE_INIT',
    'PAGE_NEXT': 'PATHWAY_REQUIREMENT_SUBMISSION_PAGE_NEXT',
    'PAGE_PREVIOUS': 'PATHWAY_REQUIREMENT_SUBMISSION_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'PATHWAY_REQUIREMENT_SUBMISSION_PAGE_REQUEST',
    'PAGE_SUCCESS': 'PATHWAY_REQUIREMENT_SUBMISSION_PAGE_SUCCESS',
    'PAGE_ERROR': 'PATHWAY_REQUIREMENT_SUBMISSION_PAGE_ERROR',
    'ADD_SINGLE_RESULT': 'PATHWAY_REQUIREMENT_SUBMISSION_ADD_SINGLE_RESULT',
    'TOGGLE_SELECTION': 'PATHWAY_REQUIREMENT_SUBMISSION_TOGGLE_SELECTION',
    'TOGGLE_ALL': 'PATHWAY_REQUIREMENT_SUBMISSION_TOGGLE_ALL',
    'CLEAR_SELECTION': 'PATHWAY_REQUIREMENT_SUBMISSION_CLEAR_SELECTION',
    'ADD_FILTER': 'PATHWAY_REQUIREMENT_SUBMISSION_ADD_FILTER',
    'REMOVE_FILTER': 'PATHWAY_REQUIREMENT_SUBMISSION_REMOVE_FILTER',
    'RESET_FILTERS': 'PATHWAY_REQUIREMENT_SUBMISSION_RESET_FILTER',
};

export const selectProject = submissionDraft => {
    return {
        type: PATHWAY_REQUIREMENT_SUBMISSION_SELECT,
        payload: {
            submissionDraft,
        },
    };
};

export const resetProject = () => {
    return {
        type: PATHWAY_REQUIREMENT_SUBMISSION_RESET,
    };
};

export const descriptionToggle = () => {
    return {
        type: PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_DESCRIPTION_TOGGLE,
    };
}

export const listItemToggle = (field, keyName, keyValue) => {
    return {
        type: PATHWAY_REQUIREMENT_SUBMISSION_DRAFT_ADD_TO_LIST,
        payload: {
            field,
            keyName,
            keyValue,
        },
    };
}
