// TODO: Fix this with prettier changes
// eslint-disable-next-line import/no-cycle
import { resetEntryEditorAttachments } from './entry-attachments/entry-attachments.actions';

export const ENTRY_EDITOR_AUTOSAVE_DIRTY = 'ENTRY_EDITOR_AUTOSAVE_DIRTY';
export const ENTRY_EDITOR_AUTOSAVE_DISABLE = 'ENTRY_EDITOR_AUTOSAVE_DISABLE';
export const ENTRY_EDITOR_AUTOSAVE_ENABLE = 'ENTRY_EDITOR_AUTOSAVE_ENABLE';
export const ENTRY_EDITOR_AUTOSAVE_FAIL = 'ENTRY_EDITOR_AUTOSAVE_FAIL';
export const ENTRY_EDITOR_AUTOSAVE_RESET = 'ENTRY_EDITOR_AUTOSAVE_RESET';
export const ENTRY_EDITOR_AUTOSAVE_START = 'ENTRY_EDITOR_AUTOSAVE_START';
export const ENTRY_EDITOR_AUTOSAVE_SUCCESS = 'ENTRY_EDITOR_AUTOSAVE_SUCCESS';
export const ENTRY_EDITOR_DID_VIEW_TAG_TEAMMATES_DIALOG =
    'ENTRY_EDITOR_DID_VIEW_TAG_TEAMMATES_DIALOG';
export const ENTRY_EDITOR_HIDE_MOBILE_FIELD = 'ENTRY_EDITOR_HIDE_MOBILE_FIELD';
export const ENTRY_EDITOR_LOADING_TOGGLE = 'ENTRY_EDITOR_LOADING_TOGGLE';
export const ENTRY_EDITOR_RESET_HELP_GUIDE = 'ENTRY_EDITOR_RESET_HELP_GUIDE';
export const ENTRY_EDITOR_ALLOW_STATE_CHANGE =
    'ENTRY_EDITOR_ALLOW_STATE_CHANGE';
export const ENTRY_EDITOR_DISABLE_ACTIONS = 'ENTRY_EDITOR_DISABLE_ACTIONS';
export const ENTRY_EDITOR_DISALLOW_STATE_CHANGE =
    'ENTRY_EDITOR_DISALLOW_STATE_CHANGE';
export const ENTRY_EDITOR_ENABLE_ACTIONS = 'ENTRY_EDITOR_ENABLE_ACTIONS';
export const ENTRY_EDITOR_META_RESET = 'ENTRY_EDITOR_META_RESET';
export const ENTRY_EDITOR_RESET_ASSIGNMENT_DETAILS =
    'ENTRY_EDITOR_RESET_ASSIGNMENT_DETAILS';
export const ENTRY_EDITOR_RESET_ENTRY_DETAILS =
    'ENTRY_EDITOR_RESET_ENTRY_DETAILS';
export const ENTRY_EDITOR_RESET_DRAFT = 'ENTRY_EDITOR_RESET_DRAFT';
export const ENTRY_EDITOR_RESET_DRAFT_STATUS =
    'ENTRY_EDITOR_RESET_DRAFT_STATUS';
export const ENTRY_EDITOR_RESET_SOCIAL_SHARING =
    'ENTRY_EDITOR_RESET_SOCIAL_SHARING';
export const ENTRY_EDITOR_SET_CATEGORY = 'ENTRY_EDITOR_SET_CATEGORY';
export const ENTRY_EDITOR_SET_COVER_PHOTO = 'ENTRY_EDITOR_SET_COVER_PHOTO';
export const ENTRY_EDITOR_SET_DESCRIPTION = 'ENTRY_EDITOR_SET_DESCRIPTION';
export const ENTRY_EDITOR_SET_STATUS_PUBLISHED =
    'ENTRY_EDITOR_SET_STATUS_PUBLISHED';
export const ENTRY_EDITOR_SET_STATUS_IS_ASSIGNMENT =
    'ENTRY_EDITOR_SET_STATUS_IS_ASSIGNMENT';
export const ENTRY_EDITOR_SET_STATUS_NEXT_STATE =
    'ENTRY_EDITOR_SET_STATUS_NEXT_STATE';
export const ENTRY_EDITOR_SET_ID = 'ENTRY_EDITOR_SET_ID';
export const ENTRY_EDITOR_SET_BUCKET_ID = 'ENTRY_EDITOR_SET_BUCKET_ID';
export const ENTRY_EDITOR_SET_CHALLENGE_ID = 'ENTRY_EDITOR_SET_CHALLENGE_ID';
export const ENTRY_EDITOR_SET_TITLE = 'ENTRY_EDITOR_SET_TITLE';
export const ENTRY_EDITOR_SET_ALLOWED_FILE_TYPES =
    'ENTRY_EDITOR_SET_ALLOWED_FILE_TYPES';
export const ENTRY_EDITOR_SET_ATTACHMENTS = 'ENTRY_EDITOR_SET_ATTACHMENTS';
export const ENTRY_EDITOR_SET_ENTRY_CLASSIFICATION =
    'ENTRY_EDITOR_SET_ENTRY_CLASSIFICATION';
export const ENTRY_EDITOR_SET_SEARCH_RESULT_SKILLS =
    'ENTRY_EDITOR_SET_SEARCH_RESULT_SKILLS';
export const ENTRY_EDITOR_SET_SEARCH_RESULT_TEAMMATES =
    'ENTRY_EDITOR_SET_SEARCH_RESULT_TEAMMATES';
export const ENTRY_EDITOR_SET_SKILLS = 'ENTRY_EDITOR_SET_SKILLS';
export const ENTRY_EDITOR_SET_SKILLS_DISPLAY_STRING =
    'ENTRY_EDITOR_SET_SKILLS_DISPLAY_STRING';
export const ENTRY_EDITOR_SET_SEARCH_RESULT_TAGS =
    'ENTRY_EDITOR_SET_SEARCH_RESULT_TAGS';
export const ENTRY_EDITOR_SET_SETTINGS = 'ENTRY_EDITOR_SET_SETTINGS';
export const ENTRY_EDITOR_SET_SOCIAL_SHARING_TWITTER =
    'ENTRY_EDITOR_SET_SOCIAL_SHARING_TWITTER';
export const ENTRY_EDITOR_SET_SUGGESTED_SKILLS =
    'ENTRY_EDITOR_SET_SUGGESTED_SKILLS';
export const ENTRY_EDITOR_SET_SUGGESTED_TAGS =
    'ENTRY_EDITOR_SET_SUGGESTED_TAGS';
export const ENTRY_EDITOR_SET_SUGGESTED_TEAMMATES =
    'ENTRY_EDITOR_SET_SUGGESTED_TEAMMATES';
export const ENTRY_EDITOR_SET_TAGS = 'ENTRY_EDITOR_SET_TAGS';
export const ENTRY_EDITOR_SET_TAGS_DISPLAY_STRING =
    'ENTRY_EDITOR_SET_TAGS_DISPLAY_STRING';
export const ENTRY_EDITOR_SET_TEAMMATES = 'ENTRY_EDITOR_SET_TEAMMATES';
export const ENTRY_EDITOR_SET_TOKEN = 'ENTRY_EDITOR_SET_TOKEN';
export const ENTRY_EDITOR_SET_STRENGTH = 'ENTRY_EDITOR_SET_STRENGTH';
export const ENTRY_EDITOR_SHOW_HELP_GUIDE = 'ENTRY_EDITOR_SHOW_HELP_GUIDE';
export const ENTRY_EDITOR_SHOW_MOBILE_FIELD = 'ENTRY_EDITOR_SHOW_MOBILE_FIELD';
export const ENTRY_EDITOR_UPDATE_ASSIGNMENT_DETAILS =
    'ENTRY_EDITOR_UPDATE_ASSIGNMENT_DETAILS';
export const ENTRY_EDITOR_UPDATE_ENTRY_DETAILS =
    'ENTRY_EDITOR_UPDATE_ENTRY_DETAILS';
export const ENTRY_EDITOR_UPDATE_DRAFT = 'ENTRY_EDITOR_UPDATE_DRAFT';

/**
 * Disable the autosave during save/publish requests
 */
export const autosaveDisable = () => ({
    type: ENTRY_EDITOR_AUTOSAVE_DISABLE,
});

/**
 * Re-enable the autosave after save/publish requests
 */
export const autosaveEnable = () => ({
    type: ENTRY_EDITOR_AUTOSAVE_ENABLE,
});

/**
 * Hide the loading state but retain dirty state on autosave fail
 */
export const autosaveFail = () => ({
    type: ENTRY_EDITOR_AUTOSAVE_FAIL,
});

/**
 * Show the loading state when an autosave starts
 */
export const autosaveStart = () => ({
    type: ENTRY_EDITOR_AUTOSAVE_START,
});

/**
 * Hide the loading state and update the draft with new ID on autosave success
 *
 * @param {string} id Updated draft ID - Important when persisting a draft for the first time
 */
export const autosaveSuccess = id => {
    return dispatch => {
        if (!_.isString(id)) {
            dispatch(autosaveFail());

            return;
        }

        // Unset the loading/disabled flags
        dispatch({
            type: ENTRY_EDITOR_AUTOSAVE_SUCCESS,
        });

        // Make sure a new draft gets the ID set for the next autosave
        dispatch({
            type: ENTRY_EDITOR_SET_ID,
            payload: {
                id,
            },
        });
    };
};

/**
 * Hide the active mobile form field
 */
export const hideMobileField = () => ({
    type: ENTRY_EDITOR_HIDE_MOBILE_FIELD,
});

/**
 * Reset the active desktop form field
 */
export const resetHelpGuide = () => ({
    type: ENTRY_EDITOR_RESET_HELP_GUIDE,
});

/**
 * Reset the entry editor state back to default
 */
export const resetEditor = () => {
    return dispatch => {
        // Clear the current draft
        dispatch(resetDraft());
        // Reset the autosave
        dispatch(resetAutosave());
        // Reset the draft status
        dispatch(resetDraftStatus());
        // Reset the assignment details
        dispatch(resetAssignmentDetails());
        // Reset the entry details
        dispatch(resetEntryDetails());
        // Reset the help editor
        dispatch(resetHelpGuide());
        // Reset the attachments
        dispatch(resetEntryEditorAttachments());
        // Reset the meta
        dispatch(resetMeta());
        // Reset the social sharing
        dispatch(resetSocialSharing());
    };
};

/**
 * Reset the autosave state
 */
export const resetAutosave = () => ({
    type: ENTRY_EDITOR_AUTOSAVE_RESET,
});

/**
 * Reset the current draft back to a new empty draft
 */
export const resetDraft = () => ({
    type: ENTRY_EDITOR_RESET_DRAFT,
});

/**
 * Reset the draft status metadata
 */
export const resetDraftStatus = () => ({
    type: ENTRY_EDITOR_RESET_DRAFT_STATUS,
});

/**
 * Reset the assignment details data
 */
export const resetAssignmentDetails = () => ({
    type: ENTRY_EDITOR_RESET_ASSIGNMENT_DETAILS,
});

/**
 * Reset the entry details data
 */
export const resetEntryDetails = () => ({
    type: ENTRY_EDITOR_RESET_ENTRY_DETAILS,
});

/**
 * Reset the current meta
 */
export const resetMeta = () => ({
    type: ENTRY_EDITOR_META_RESET,
});

/**
 * Set the draft bucket id
 * @param {string} bucketId  New value for bucketId
 */
export const setBucketId = (bucketId = null) => ({
    type: ENTRY_EDITOR_SET_BUCKET_ID,
    payload: {
        bucketId,
    },
});

/**
 * Set the draft challenge id
 * @param {string} challengeId New value for challengeId
 */
export const setChallengeId = (challengeId = null) => ({
    type: ENTRY_EDITOR_SET_CHALLENGE_ID,
    payload: {
        challengeId,
    },
});

/**
 * Set the draft title
 * @param {object} category New value for category
 */
export const setCategory = (category = null) => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_CATEGORY,
            payload: {
                category,
            },
        });

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Set the draft description
 * @param {string} description New value for description
 */
export const setDescription = (description = '') => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_DESCRIPTION,
            payload: {
                description,
            },
        });

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Set the draft as dirty, meaning there are changes that need to be saved
 */
export const setDirty = () => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_AUTOSAVE_DIRTY,
        });

        // disallow state change if dirty
        dispatch(disallowStateChange());
    };
};

/**
 * Tell the editor that the draft is associated with an existing entry
 */
export const setStatusPublished = () => ({
    type: ENTRY_EDITOR_SET_STATUS_PUBLISHED,
});

/**
 * Set the allowed file upload types
 */
export const setAllowedFileUploadTypes = allowedFileTypes => ({
    type: ENTRY_EDITOR_SET_ALLOWED_FILE_TYPES,
    payload: {
        allowedFileTypes,
    },
});

/**
 * Set the entry classification
 */
export const setEntryClassification = entryClassification => ({
    type: ENTRY_EDITOR_SET_ENTRY_CLASSIFICATION,
    payload: {
        entryClassification,
    },
});

/**
 * NOTE: Depreciated in favor of entry classification (default/assignment/requirement)
 *
 * Tell the editor that the draft is associated with an assignment
 */
export const setStatusIsAssignment = () => ({
    type: ENTRY_EDITOR_SET_STATUS_IS_ASSIGNMENT,
});

/**
 * Set the next router state
 * @param {string} state  The next state name to route to
 * @param {object} params The params for the next state
 */
export const setStatusNextState = (state = null, params = null) => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_STATUS_NEXT_STATE,
            payload: {
                state,
                params,
            },
        });
    };
};

/**
 * Allow state transistion from the editor without showing a dialog to the user
 */
export const allowStateChange = () => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_ALLOW_STATE_CHANGE,
        });
    };
};

/**
 * Set the draft strength
 * @param {string} strength New value for strength
 */
export const setStrength = (strength = 0) => ({
    type: ENTRY_EDITOR_SET_STRENGTH,
    payload: {
        strength,
    },
});

/**
 * Set the flag that we showed the tag teammates dialog
 */
export const setDidViewTagTeammatesDialog = () => ({
    type: ENTRY_EDITOR_DID_VIEW_TAG_TEAMMATES_DIALOG,
});

/**
 *  Show a dialog to the user before the state transistion from the editor
 */
export const disallowStateChange = () => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_DISALLOW_STATE_CHANGE,
        });
    };
};

/**
 * Set the draft cover photo
 * @param {object} cover cover photo to update
 */
export const setCoverPhoto = (cover = null) => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_COVER_PHOTO,
            payload: {
                cover,
            },
        });

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Set the draft title
 * @param {string} title New value for title
 */
export const setTitle = (title = '') => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_TITLE,
            payload: {
                title,
            },
        });

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Set the draft teammates
 * @param {object[]} teammates collection of user objects
 */
export const setTeammates = (teammates = []) => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_TEAMMATES,
            payload: {
                teammates,
            },
        });

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Set the draft privacy token
 * @param {string} token token received from the api on saved draft
 */
export const setToken = token => ({
    type: ENTRY_EDITOR_SET_TOKEN,
    payload: {
        token,
    },
});

/**
 * Set the string of selected skills for display
 * @param {object[]} skills collection of skill objects
 */
export const setSkillsDisplayString = (skills = []) => {
    return dispatch => {
        // default to null
        let skillsDisplayString = null;

        if (skills.length > 0) {
            // flatten skills
            const flatSkills = skills.map(skill => skill.skill);
            // join skills into string
            skillsDisplayString = _.join(flatSkills, ', ');
        }

        dispatch({
            type: ENTRY_EDITOR_SET_SKILLS_DISPLAY_STRING,
            payload: {
                skillsDisplayString,
            },
        });
    };
};

/**
 * Set the draft skills
 * @param {object[]} skills collection of skill objects
 */
export const setSkills = (skills = []) => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_SKILLS,
            payload: {
                skills,
            },
        });

        // set skills display string
        dispatch(setSkillsDisplayString(skills));

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Set the string of selected tags for display
 * @param {object[]} tags collection of tag objects
 */
export const setTagsDisplayString = (tags = []) => {
    return dispatch => {
        // default to null
        let tagsString = null;

        if (tags.length > 0) {
            // create the 'hashtags'
            const flatTags = tags.map(tag => tag.tag);

            // join tags into string
            tagsString = _.join(flatTags, ' #');

            dispatch({
                type: ENTRY_EDITOR_SET_TAGS_DISPLAY_STRING,
                payload: {
                    tagsDisplayString: `#${tagsString}`,
                },
            });
        }
    };
};

/**
 * Set the draft tags
 * @param {object[]} tags collection of tag objects
 */
export const setTags = (tags = []) => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_TAGS,
            payload: {
                tags,
            },
        });

        // set tags display string
        dispatch(setTagsDisplayString(tags));

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Set the suggested tags
 * @param {object[]} suggestedTags collection of tag objects
 */
export const setSuggestedTags = (suggestedTags = []) => ({
    type: ENTRY_EDITOR_SET_SUGGESTED_TAGS,
    payload: {
        suggestedTags,
    },
});

/**
 * Set the tags search results
 * @param {object[]} searchResultTags collection of tag objects
 */
export const setSearchResultTags = (searchResultTags = []) => ({
    type: ENTRY_EDITOR_SET_SEARCH_RESULT_TAGS,
    payload: {
        searchResultTags,
    },
});

/**
 * Set the active desktop form field
 * @param {string} fieldName Unique name for the field to show
 */
export const showHelpGuide = (fieldName = '') => ({
    type: ENTRY_EDITOR_SHOW_HELP_GUIDE,
    payload: {
        fieldName,
    },
});

/**
 * Set the active mobile form field
 * @param {string} fieldName Unique name for the field to show
 */
export const showMobileField = (fieldName = '') => ({
    type: ENTRY_EDITOR_SHOW_MOBILE_FIELD,
    payload: {
        fieldName,
    },
});

/**
 * Set the draft settings for visibility and allowing comments
 * @param {object} settings The settings object
 */
export const setSettings = ({ visibility, allow_comments }) => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_SETTINGS,
            payload: {
                visibility,
                allow_comments,
            },
        });

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Toggle the draft loading flag on/off
 */
export const toggleLoading = () => ({
    type: ENTRY_EDITOR_LOADING_TOGGLE,
});

/**
 * Disable form actions
 */
export const disableActions = () => ({
    type: ENTRY_EDITOR_DISABLE_ACTIONS,
});

/**
 * Enable form actions
 */
export const enableActions = () => ({
    type: ENTRY_EDITOR_ENABLE_ACTIONS,
});

/**
 * Update the current draft
 * @param {object} draft New draft
 */
export const updateDraft = (draft = {}) => {
    return dispatch => {
        // clean draft skills, protect ourselves from the evil API
        draft.skill_list = _.map(draft.skill_list, ({ id, skill }) => {
            return { id, skill };
        });

        // Store the new draft model
        dispatch({
            type: ENTRY_EDITOR_UPDATE_DRAFT,
            payload: {
                draft,
            },
        });

        // set skill display string
        dispatch(setSkillsDisplayString(draft.skill_list));
        dispatch(setTagsDisplayString(draft.tags));
    };
};

/**
 * Update the current assignment details
 * @param {object} assignment Current assignment
 */
export const updateAssignmentDetails = (assignment = {}) => ({
    type: ENTRY_EDITOR_UPDATE_ASSIGNMENT_DETAILS,
    payload: {
        assignment,
    },
});

/**
 * Update the entry detail metadata
 * @param {object} entryDetails Entry metadata used for viewing preview
 */
export const updateEntryDetails = (entryDetails = null) => ({
    type: ENTRY_EDITOR_UPDATE_ENTRY_DETAILS,
    payload: {
        entryDetails,
    },
});

export const setAttachments = (attachments = []) => {
    return dispatch => {
        dispatch({
            type: ENTRY_EDITOR_SET_ATTACHMENTS,
            payload: {
                attachments,
            },
        });

        // Flag the draft as dirty
        dispatch(setDirty());
    };
};

/**
 * Set the suggested teammates
 * @param {object[]} suggestedTeammates collection of user objects
 */
export const setSuggestedTeammates = (suggestedTeammates = []) => ({
    type: ENTRY_EDITOR_SET_SUGGESTED_TEAMMATES,
    payload: {
        suggestedTeammates,
    },
});

/**
 * Set the teammate search results
 * @param {object[]} searchResultTeammates collection of user objects
 */
export const setSearchResultTeammates = (searchResultTeammates = []) => ({
    type: ENTRY_EDITOR_SET_SEARCH_RESULT_TEAMMATES,
    payload: {
        searchResultTeammates,
    },
});

/**
 * Set the suggested skills
 * @param {object[]} suggestedSkills collection of skill objects
 */
export const setSuggestedSkills = (suggestedSkills = []) => ({
    type: ENTRY_EDITOR_SET_SUGGESTED_SKILLS,
    payload: {
        suggestedSkills,
    },
});

/**
 * Set the skills search results
 * @param {object[]} searchResultSkills collection of skill objects
 */
export const setSearchResultSkills = (searchResultSkills = []) => ({
    type: ENTRY_EDITOR_SET_SEARCH_RESULT_SKILLS,
    payload: {
        searchResultSkills,
    },
});

/**
 * Set Twitter social sharing flag
 * @param {Boolean} enabled Is this sharing enabled?
 */
export const setSocialSharingTwitter = enabled => ({
    type: ENTRY_EDITOR_SET_SOCIAL_SHARING_TWITTER,
    payload: {
        enabled,
    },
});

/**
 * Reset social sharing flags
 */
export const resetSocialSharing = () => ({
    type: ENTRY_EDITOR_RESET_SOCIAL_SHARING,
});
