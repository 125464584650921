import _ from 'lodash';

import {
    LOAD_USER_BADGES,
    ADD_USER_BADGES,
    RESET_USER_BADGES,
} from '../action/user-badges';

export const initialState = {
    limit: 10,
    offset: 0,
    finished: false,
    loading: false,
    badges: [],
};

export const userBadges = (state = initialState, action = {}) => {
    switch (action.type) {
        case LOAD_USER_BADGES:
            return _.assign({}, state, {loading: true});
        case ADD_USER_BADGES:
            return _.assign({}, state, {
                offset: state.offset + action.payload.length,
                finished: action.payload.length < state.limit,
                loading: false,
                badges: [
                    ...state.badges,
                    ...action.payload,
                ],
            });
        case RESET_USER_BADGES:
            return _.assign({}, initialState);
        default:
            return state;
    }
};
