import angular from 'angular';
import SpotlightPanelTemplate from '../../template/spotlight-panel.html';
import SpotlightPanelController from '../controller/spotlight-panel.controller';

export class SpotlightPanelService {
    constructor($mdPanel, $pfUser) {
        this.$mdPanel = $mdPanel;
        this.$pfUser = $pfUser;
    }

    /**
     * Create a new panel instance
     * @param  {Element}  args.relativeTo   Target element to attach panel to
     * @param  {String}   args.title        The title to display in the panel
     * @param  {String}   args.message      The message to display in the panel
     * @param  {Function} args.onSubmit     The function to call when the 'submit' button is clicked
     * @param  {Function} args.onCancel     The function to call when the 'cancel' button is clicked
     * @param  {Function} args.onDomRemoved The function to call when the panel has been removed from the dom
     * @param  {String}   args.submitText   The text for submit button
     * @param  {String}   args.submitIcon   The icon for submit button
     * @return {MdPanelRef}
     */
    _createPanel({
        title,
        message,
        onSubmit,
        onCancel,
        onDomRemoved,
        submitText,
        submitIcon,
        cancelText,
        relativeTo,
    }) {
        const position = this.$mdPanel.newPanelPosition();

        if (relativeTo) {
            position
                .relativeTo(relativeTo)
                .addPanelPosition(
                    this.$mdPanel.xPosition.CENTER,
                    this.$mdPanel.yPosition.ABOVE,
                );
        } else {
            position.absolute().center();
        }

        const animation = this.$mdPanel
            .newPanelAnimation()
            .openFrom({ top: '50%', left: '50%' })
            .closeTo({ top: '50%', left: '50%' })
            .withAnimation(this.$mdPanel.animation.SCALE);

        let panelClass = 'pf-panel pf-alert-dialog';

        if (this.$pfUser.isMobile()) {
            panelClass = 'pf-panel pf-alert-dialog pf-alert-dialog--stacked';
        }

        const config = {
            controller: SpotlightPanelController,
            controllerAs: '$ctrl',
            template: SpotlightPanelTemplate,
            panelClass,
            attachTo: angular.element(document.body),
            position,
            animation,
            hasBackdrop: true,
            trapFocus: true,
            clickOutsideToClose: false,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 1000,
            locals: {
                title,
                message,
                onSubmit,
                onCancel,
                submitText,
                submitIcon,
                cancelText,
            },
            onDomRemoved: () => {
                if (_.isFunction(onDomRemoved)) {
                    onDomRemoved();
                }
                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
        };
        const panel = this.$mdPanel.create(config);

        return panel;
    }

    /**
     * Open the popover panel
     * @param  {Object}  args  The arguments to be passed to _createPanel
     * @return {Promise<MdPanelRef>}
     */
    open(args) {
        const panel = this._createPanel(args);
        // Open the panel
        return panel.open();
    }
}

SpotlightPanelService.$inject = ['$mdPanel', '$pfUser'];
