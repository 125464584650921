class BaseEntryCardController
{
    constructor($pfAuth, $pfUser) {
        this.$pfAuth = $pfAuth;
        this.$pfUser = $pfUser;
    }

    /**
     * @description Check if the project belongs to the currently authenticated user
     * @return {Boolean}
     */
    belongsToAuthedUser() {
        let userId = this.entry.fk_user_id;

        return this.$pfAuth.isAuthenticatedUser(userId);
    }

    /**
     * @description Check if the project has been submitted for faculty review
     * @return {Boolean}
     */
    hasSubmitted() {
        // if the submitted_at field is NOT empty, then it has been submitted
        return !_.isEmpty(this.entry.submitted_at);
    }

    /**
     * @description Check if the project is private
     * @return {Boolean}
     */
    isPrivate() {
        return this.entry.visibility === '3';
    }

    /**
     * @description Check if the project is being view on a mobile device
     * @return {Boolean}
     */
    isMobile() {
        return this.$pfUser.isMobile();
    }
}

export default BaseEntryCardController;
