/**
 * Attempt to force update for auto complete browser states
 */
function autofillSubmit() {
    return {
        restrict: 'A',
        priority: -10,
        link: function (scope, el) {
            el.on('click', function () {
                $('.pf-auto-complete').trigger('input').trigger('change').trigger('keydown');
            });
        }
    };
}

export default autofillSubmit;
