import angular from 'angular';

import {
    openMobileMenuPanel,
    closeMobileMenuPanel,
} from '../action/mobile-menu-panel';

export class MobileMenuPanelService {
    constructor(
        $mdPanel,
        $ngRedux,
        $timeout,
        $pfUser,
        $mdDialog
    ) {
        this._$mdPanel = $mdPanel;
        this._$ngRedux = $ngRedux;
        this._$timeout = $timeout;
        this._$pfUser = $pfUser;
        this._$mdDialog = $mdDialog;
    }

    /**
     * Create a new panel instance
     * @return {MdPanelRef}
     */
    _createPanel() {
        const width = 264;
        const animationPosition = {
            top: 0,
            left: -width,
        };
        const position = this._$mdPanel.newPanelPosition()
            .absolute()
            .top(0)
            .left(0);
        const animation = this._$mdPanel.newPanelAnimation()
            .openFrom(animationPosition)
            .closeTo(animationPosition)
            .withAnimation(this._$mdPanel.animation.SLIDE);
        // TEMPLATE: layout="column" and layout-fill used to fix firefox scrolling issue
        const config = {
            controller: angular.noop,
            controllerAs: '$ctrl',
            template: `
                <pf-mobile-menu ng-cloak
                     md-panel-ref="$ctrl.mdPanelRef"
                     layout="column"
                     layout-fill
                     flex>
                </pf-mobile-menu>
            `,
            panelClass: 'pf-panel pf-sidenav-panel pf-sidenav-panel--mobile-menu',
            attachTo: angular.element(document.body),
            position: position,
            animation: animation,
            hasBackdrop: true,
            trapFocus: true,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 1000,
            propagateContainerEvents: false,
            onDomRemoved: () => {
                // Update application state
                this._$ngRedux.dispatch(closeMobileMenuPanel());
                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
        };
        const panel = this._$mdPanel.create(config);

        return panel;
    }

    /**
     * Open the Mobile Menu center panel
     * @return {Promise<MdPanelRef>}
     */
    _open() {
        // Initialize state
        this._$ngRedux.dispatch(openMobileMenuPanel());

        const panel = this._createPanel();

        // Hide any dialogs if they are open
        const dialogPromise = this._$mdDialog.hide();
        if (dialogPromise) {
            return dialogPromise.then(() => {
                // Open the panel
                return panel.open();
            });
        } else {
            return panel.open();
        }
    }
}

MobileMenuPanelService.$inject = ['$mdPanel', '$ngRedux', '$timeout', '$pfUser', '$mdDialog'];
