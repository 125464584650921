/**
 * @ngdoc service
 * @name EntriesService
 * @module portfolium.core
 * @description Service for fetching entry feeds
 * @class EntriesService
 */
export class EntriesFeedService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * Get a feed of entries
     * @param {object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getAllEntries(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/entries/feed',
            params,
        }).then(response => response.data);
    }

    /**
     * Get a feed of entries in a category (by id)
     * @param {string} categoryId Category ID
     * @param {object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getEntriesByCategoryId(id, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/entries/category/${id}`,
            params,
        }).then(response => response.data);
    }

    /**
     * Get a feed of entries in a category (by slug)
     * @param {string} slug Category slug
     * @param {object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getEntriesByCategorySlug(slug, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/entries/category/${slug}/slug`,
            params,
        }).then(response => response.data);
    }

    /**
     * Get a feed of entries with a tag
     * @param {string} tag Entry tag
     * @param {object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getEntriesByTag(tag, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/entries/hashtag/${tag}`,
            params,
        }).then(response => response.data);
    }

    /**
     * Get a feed of entries with a skill
     * @param {string} skill Entry skill
     * @param {object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getEntriesBySkill(skill, params = {}) {
        params = _.assign({}, params, {
            q: skill,
        });

        return this.$http({
            method: 'GET',
            url: `/proxy/entries/skill`,
            params,
        }).then(response => response.data);
    }

    /**
     * Get a users entries with their user id
     * @param {Integer} userId Users ID
     * @param {Object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getEntriesByUserId(userId, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/entries/user/${userId}`,
            params,
        }).then(response => response.data);
    }

    /**
     * Get a feed of expert pick entries
     * @param {object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getExpertPickEntries(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/entries/expert',
            params,
        }).then(response => response.data);
    }

    /**
     * Get a feed of pick of the week entries
     * @param {object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getPickOfTheWeekEntries(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/entries/picks',
            params,
        }).then(response => response.data);
    }

    /**
     * Get a feed of staff pick entries
     * @param {object} [params={}] Query params
     * @return {Promise<Array<Object>>}
     *
     * @memberOf EntriesService
     */
    getStaffPickEntries(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/entries/spotlighted',
            params,
        }).then(response => response.data);
    }
}

EntriesFeedService.$inject = [
    '$http',
];
