import BaseModalController from 'components/modal/js/controller/base-modal-controller';

export class UnauthedJobApplyModalController extends BaseModalController {
    constructor($mdDialog, $pfSignUp, $pfToast, $location) {
        super($mdDialog);
        this._$pfSignUp = $pfSignUp;
        this._$pfToast = $pfToast;
        this._$location = $location;
        this.user = {};

        this.srcParam = this._$location.search().src;
    }

    /**
     * Save the visitor's first name, last name, and email address, then redirect to the external URL
     */
    submit() {
        if (!this.isValid()) {
            return;
        }

        this.loading = true;
        this.src = _.isString(this.srcParam) ? this.srcParam : '';

        // Build the post data
        const data = _.extend({}, this.user, {
            // Specify the campaign for tracking purposes
            campaign: 'externalJobApply-' + this.src,
            // Specify if the campaign target goal was met
            campaign_target_met: this.campaignTargetMet,
        });

        // Create a new user account
        return this._$pfSignUp.signUp(data).then((auth) => {
            // Allow user to bypass onboarding
            return this._$pfSignUp.setOnboardingBypass().then(() => {
                // Close the modal and resolve promise
                return this.hide(auth);
            });
        }, () => {
            this._$pfToast.error('Something went wrong, please try again');
        }).finally(() => {
            this.loading = false;
        });
    }

    onResumeUpdate(resume) {
        this.user.resume = resume;
    }

    onResumeDelete() {
        this.user.resume = null;
    }
}

UnauthedJobApplyModalController.$inject = [
    '$mdDialog',
    '$pfSignUp',
    '$pfToast',
    '$location',
];
