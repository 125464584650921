import {toggleVisibleItem} from './about-sections';

export const UPDATE_ABOUTS = 'UPDATE_ABOUTS';
export const RESET_ABOUTS = 'RESET_ABOUTS';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';
export const ADD_ABOUT = 'ADD_ABOUT';
export const ADD_MULTIPLE_ABOUTS = 'ADD_MULTIPLE_ABOUTS';
export const DELETE_ABOUT = 'DELETE_ABOUT';
export const REMOVE_ENTRY_FROM_ABOUTS = 'REMOVE_ENTRY_FROM_ABOUTS';

export const updateAbouts = (abouts) => {
    return {
        type: UPDATE_ABOUTS,
        payload: abouts,
    };
};

export const resetAbouts = () => {
    return {
        type: RESET_ABOUTS,
    };
};

export const addAbout = (sectionName, payload) => {
    return (dispatch) => {
        // Add the new about record
        dispatch({
            type: ADD_ABOUT,
            sectionName,
            payload,
        });

        // Make sure the new about is set as the visible item
        dispatch(toggleVisibleItem(payload.id, sectionName));
    };
};

/**
 * Add one or more about records
 * @param {string} sectionName About type name
 * @param {Array<Object>} abouts New about records to add
 */
export const addMultipleAbouts = (sectionName = '', abouts = []) => {
    return (dispatch) => {
        // Add the new about record
        dispatch({
            type: ADD_MULTIPLE_ABOUTS,
            sectionName,
            payload: {
                abouts,
            },
        });

        // Set the first new about record as the visible item for the section
        dispatch(toggleVisibleItem(abouts[0].id, sectionName));
    };
};

export const updateAbout = (id, sectionName, payload) => {
    return {
        type: UPDATE_ABOUT,
        id,
        sectionName,
        payload,
    };
};

export const deleteAbout = (id, sectionName) => {
    return {
        type: DELETE_ABOUT,
        id,
        sectionName,
    };
};

export const removeEntryFromAbouts = (entryId) => {
    return {
        type: REMOVE_ENTRY_FROM_ABOUTS,
        id: entryId,
    };
};
