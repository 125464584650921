import angular from 'angular';

export class ProfileQuickEditService {
    constructor(
        $mdDialog,
    ) {
        this.$mdDialog = $mdDialog;
    }

    /**
     * Open the quick edit dialog
     * @return {Promise}
     */
    openDialog($event) {
        const dialog = {
            controller: angular.noop,
            controllerAs: 'modalCtrl',
            targetEvent: $event,
            bindToController: true,
            template: `
                <md-dialog class="pf-dialog pf-dialog--no-border pf-dialog--white pf-profile-quick-edit-dialog"
                     aria-label="Edit profile">
                    <pf-profile-quick-edit></pf-profile-quick-edit>
                </md-dialog>
            `,
        };

        return this.$mdDialog.show(dialog);
    }
}

ProfileQuickEditService.$inject = ['$mdDialog'];
