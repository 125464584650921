class MassActionsAllController
{
    constructor($scope, $pfMassActions) {
        this.$pfMassActions = $pfMassActions;

        $scope.$watchCollection(() => {
            return this.$pfMassActions.activeUsers;
        }, (activeUsers) => {
            this.selectable = this.isSelectable();
        });
    }

    isSelectable() {
        if (this.$pfMassActions.toggles.length < 1) {
            return true;
        }

        return this.$pfMassActions.activeUsers.length < this.$pfMassActions.toggles.length;
    }

    toggleSelectAll() {
        this.$pfMassActions.toggleSelectAll();
    }
}

MassActionsAllController.$inject = ['$scope', '$pfMassActions'];

function massActionsAllDirective() {
    return {
        restrict: 'E',
        scope: true,
        controller: MassActionsAllController,
        controllerAs: 'allCtrl',
        template: `
            <md-button md-no-ink
                 aria-label="{{(selectable) ? 'Select All' : 'Select None'}}"
                 ng-click="allCtrl.toggleSelectAll()"
                 class="md-icon-button pf-mass-employer-actions-all">
                <md-icon ng-show="!allCtrl.selectable">check_box</md-icon>
                <md-icon ng-show="allCtrl.selectable">check_box_outline_blank</md-icon>
            </md-button>
        `
    };
}

export default massActionsAllDirective;
