import angular from 'angular';

import { togglePopoverDirective } from './toggle-popover.directive';
import { PopoverPanelService } from './popover-panel.service';

import PopoverEntryStrengthTemplate from './template/popover-entry-strength.html'
import PopoverProfileTemplate from './template/popover-profile.html'
import PopoverTextTemplate from './template/popover-text.html'
import './index.scss';

const MODULE_NAME = 'portfolium.component.popover';

(function () {
    angular.module(MODULE_NAME, [])
        // Directive
        .directive('pfTogglePopover', togglePopoverDirective)
        // Services
        .service('$pfPopoverPanel', PopoverPanelService)
        // Templates
        .run(['$templateCache', ($templateCache) => {
            $templateCache.put('template/components/popover/template/popover-text.html', PopoverTextTemplate);
            $templateCache.put('template/components/popover/template/popover-profile.html', PopoverProfileTemplate);
            $templateCache.put('template/components/popover/template/popover-entry-strength.html', PopoverEntryStrengthTemplate);
        }]);

}());

export default MODULE_NAME;
