function entryCardTeamDirective() {
    return {
        restrict: 'E',
        require: ['ngModel'],
        scope: {},
        bindToController: true,
        link: function (scope, elem, attrs, ctrls) {
            let ngModelCtrl = ctrls[0];

            ngModelCtrl.$render = function () {
                let model = ngModelCtrl.$viewValue;

                scope.teamCtrl.author = {
                    name: `${model.profile.firstname} ${model.profile.lastname}`,
                    username: model.profile.username,
                    avatar: model.profile.avatar.url_https
                };
            };

            elem.attr('tabindex', '-1');
        },
        controller: function () {

        },
        controllerAs: 'teamCtrl',
        template: `
            <a data-heap-redact-text ng-href="/{{teamCtrl.author.username}}" class="pf-author" title="{{teamCtrl.author.username}}s profile">
                <img class="pf-avatar" ng-src="{{teamCtrl.author.avatar|image:80:80}}">
            </a>
            <a data-heap-redact-text ng-href="/{{teamCtrl.author.username}}">{{teamCtrl.author.name}}</a>
            <span flex></span>
            <md-button md-no-ink
                 class="md-icon-button"
                 aria-label="Edit project to tag teammates"
                 ng-click="">
                <md-icon>person_add</md-icon>
            </md-button>
        `
    };
}

export default entryCardTeamDirective;
