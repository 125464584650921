import angular from 'angular';

import { EmptyResultsComponent } from './empty-results.component';

const MODULE_NAME = 'portfolium.component.emptyResults';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfEmptyResults', EmptyResultsComponent);
}());

export default MODULE_NAME;
