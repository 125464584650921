import PaginationControlTemplate from './pagination-control.component.html';

/**
 * @ngdoc controller
 * @name PaginationControlController
 * @module portfolium.component.PaginationControlComponent
 */
class PaginationControlController {

    /**
     * Determines if pagination controls should be shown
     * @return {Boolean}
     */
    get showPagination() {
        // If no results have come back yet, DONT show controls
        if (this.pagination.isFetching && !this.pagination.results.length) {
            return false;
        }

        // If there are results AND there are MORE results, SHOW controls
        if (this.pagination.results.length > 0 && !this.pagination.noMoreResults) {
            return true;
        }

        // If on last page, SHOW controls
        if (this.pagination.noMoreResults && this.pagination.pageNumber > 1) {
            return true;
        }

        // If going to previous page, SHOW controls
        if (this.pagination.isFetching && this.pagination.results.length > 0) {
            return true;
        }

        return false;
    }
}

/**
 * @ngdoc component
 * @name PaginationControlComponent
 * @module portfolium.component.PaginationControlComponent
 */
export const PaginationControlComponent = {
    bindings: {
        pagination: '<',
        previousPage: '&',
        nextPage: '&'
    },
    template: PaginationControlTemplate,
    controller: PaginationControlController,
    controllerAs: '$ctrl'
};
