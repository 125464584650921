function highlightMatchFilter($sce) {
    /**
     * Wrap all occurances of the match text in a highlight span
     * @param  {String} text      Input value
     * @param  {String} matchText Text to match against
     * @return {String}           String with wrapped matches (contains html)
     */
    return function (text, matchText) {
        if (!_.isString(matchText) || !_.isString(text)) {
            return text;
        }

        // This will remove operators from highlights like AND|OR|NOT, etc. from
        // the highlight on the profile data. These are allowed by Elastic Search but
        // we don't want to show them highlighted.
        let excludeRegExp = RegExp(
            '(((\\s+[Tt])|(^[Tt]))[Hh]([Ee]\\s+|[Ee]$))|' +
            '(((\\s+[Aa])|(^[Aa]))[Nn]([Dd]\\s+|[Dd]$))|' +
            '((\\s+[Aa]\\s+)|(\\s+[Aa]$)|(^[Aa]\\s+)|(^[Aa]$))|' +
            '(((\\s+[Oo])|(^[Oo]))([Rr]\\s+|[Rr]$))|' +
            '(((\\s+[Nn])|(^[Nn]))[Oo]([Tt]\\s+|[Tt]$))',
            'gi'
        );

        // this escapes things that will break regex like "C++", so +, * and ?, etc.
        // are escaped before getting highlighted
        matchText = matchText.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '\\$&');

        let quotedTokenRegExp = RegExp('"([^"]*)"', 'gi');
        let quotedTokens = matchText.match(quotedTokenRegExp);
        let nonQuotedTokens = [];

        if (null !== quotedTokens) {
            nonQuotedTokens = quotedTokens
                .reduce(function (carry, exclude) {
                    carry = carry.replace(RegExp(exclude, 'gi'), '');
                    return carry;
                }, matchText)
                .split(/\s+/gi)
                .filter(function (nonQuotedToken) {
                    if ((nonQuotedToken === '') || (null !== nonQuotedToken.match(excludeRegExp))) {
                        return false;
                    } else {
                        return true;
                    }
                });

            quotedTokens = quotedTokens
                .filter(function (tokenWithQuotation) {
                    if (tokenWithQuotation.match(/"\s+"/gi)) {
                        return false;
                    } else {
                        return true;
                    }
                })
                .map(function (tokenWithQuotation) {
                    return tokenWithQuotation.replace(/"/g, '');
                });
        } else {
            nonQuotedTokens = matchText
                .split(/\s+/gi)
                .filter(function (nonQuotedToken) {
                    if ((nonQuotedToken === '') || (null !== nonQuotedToken.match(excludeRegExp))) {
                        return false;
                    } else {
                        return true;
                    }
                });
            quotedTokens = [];
        }

        let highlightedText = text;
        let matchArray = quotedTokens.concat(nonQuotedTokens);
        let matchExp = matchArray.join('|');

        try {
            highlightedText = highlightedText
                .replace(new RegExp('(' + matchExp + ')', 'gi'), '<span class="pf-highlight-match">$1</span>');
        } catch (err) {
            /**
             * Temporarily supress errors thrown due to invalid regular expression
             * caused by the use of special characters.
             *
             * TODO: Use tokens returned by elastic search for highlighting once
             *       the API supports them.
             */
        }

        return $sce.trustAsHtml(highlightedText);
    };
}

highlightMatchFilter.$inject = ['$sce'];

export default highlightMatchFilter;
