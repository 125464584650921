import './loading-shell-container.directive.scss';

/**
 * @ngdoc controller
 * @module portfolium.component
 * @name LoadingShellContainerController
 */
class LoadingShellContainerController {
    constructor($element) {
        this.$element = $element;
        this.loadingClass = 'pf-loading-shell-container--loading';
    }

    $onChanges({ isLoading }) {
        if (!_.isBoolean(isLoading.currentValue)) {
            return;
        }

        if (isLoading.currentValue === true) {
            this.$element.addClass(this.loadingClass);
        } else {
            this.$element.removeClass(this.loadingClass);
        }
    }
}

LoadingShellContainerController.$inject = [
    '$element',
];

/**
 * @ngdoc directive
 * @module portfolium.component
 * @name LoadingShellContainerDirective
 * @description Display list of likers in the entry viewer sidebar
 *
 * @param {boolean} isLoading Is the data currently loading?
 */
export const LoadingShellContainerDirective = () => ({
    scope: {},
    bindToController: {
        isLoading: '<',
    },
    restrict: 'A',
    controller: LoadingShellContainerController,
    controllerAs: '$ctrl',
});
