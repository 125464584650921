import {setUserLocations} from '../reducers/user-about.actions';

export class DashboardOverviewService
{
    constructor(
        $pfUserAbout,
        $pfUser,
        $pfSession,
        $pfAddress,
        $ngRedux,
    ) {
        this.$pfUserAbout = $pfUserAbout;
        this.$pfUser = $pfUser;
        this.$pfSession = $pfSession;
        this.$pfAddress = $pfAddress;
        this.$ngRedux = $ngRedux;
        this.currentUser = $pfUser.getUser();
    }

    /**
     * @description Update the user about data
     * @return {Promise} About user results
     */
    reloadUserData() {
        let userData = {
            currentUser: {},
            userAbout: {},
            addresses: [],
        };
        // update user about data
        return this.$pfUserAbout.updateMyAbout()
            .then((about) => {
                userData.userAbout = about;
                // refresh session data
                this.$pfSession.updateSession().then(() => {
                    this.currentUser = this.$pfUser.getUser();
                    userData.currentUser = this.currentUser;
                });
                return about;
            })
            .then(() => {
                // get addresses
                return this.$pfAddress.getAddresses().then((addresses) => {
                    userData.addresses = addresses;
                    this.$ngRedux.dispatch(setUserLocations(addresses));
                    return userData;
                });
            });
    }
}

DashboardOverviewService.$inject = [
    '$pfUserAbout',
    '$pfUser',
    '$pfSession',
    '$pfAddress',
    '$ngRedux',
];
