import 'bootstrap-tokenfield';
import 'typeahead.js';
import growl from 'components/growl';
import templates from './templates';

/**
 * This module converts a standard text input into an interactive field with a list of editable
 * tokens. It also supports predictive search results using twitter's typeahead library
 *
 * @param  {string} selector jQuery selector for the element to convert
 * @return {object}          jQuery object for selected elements, to allow for chaining after this method
 */
export default function (selector) {

    let engine;
    let tokens;
    let options;
    let elem = $(selector);
    let data = elem.data();

    // If selected element doesn't exist, exit
    if (!elem.length) {
        return;
    }

    // Define Bloodhound engine to fetch typeahead values
    engine = new Bloodhound({
        limit: 12,
        remote: {
            url: data.tokenfieldSearchUrl + '?q=%QUERY',
            filter: function (response) {
                let labelProp = data.tokenfieldResultLabel;
                let valueProp = data.tokenfieldResultValue;

                if (response.length) {
                    // Create label and value properties on result objects for use by tokenfield plugin
                    return response.map(function (result) {
                        result.label = result[labelProp];
                        result.value = result[valueProp];

                        // Special check for "name" as label value, which combines first name and last name
                        if (labelProp === 'name') {
                            result.label = result.firstname + ' ' + result.lastname;
                        }
                        return result;
                    });
                }
                return [];
            }
        },
        datumTokenizer: function(d) {
            return Bloodhound.tokenizers.whitespace(d.value);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace
    });

    engine.initialize();

    // Set options
    options = {
        beautify: false, // Don't add spaces between commas for values, which causes the API to fail
        allowEditing: false, // Dont allow editing of tags inline
        allowPasting: false, // Don't allow pasting of tags
        typeahead: [{
            highlight: true
        }, {
            displayKey: 'label',
            templates: {
                suggestion: function(result) {
                    // If template name was provided, return custom template for typeahead list item
                    if (data.tokenfieldTemplate) {
                        return templates[data.tokenfieldTemplate](result);
                    }
                    // Otherwise, return default template
                    return '<p>' + result.label + '</p>';
                }
            },
            source: engine.ttAdapter()
        }]
    };

    // Set token values if provided
    if (data.tokenfieldTokens) {
        options.tokens = data.tokenfieldTokens;
    }

    // Create tokenfields using selector
    tokens = elem.tokenfield(options);

    // Bind validation to token creation event
    tokens.on('tokenfield:createtoken', function (event) {
        let newToken = event.attrs.value;
        let existingTokens = $(this).tokenfield('getTokens');

        // Limit maximum number of tokens to 10
        if (existingTokens.length > 9) {
            event.preventDefault();
            growl.show('You\'ve reached the maximum!', 'error');
            return;
        }

        // If regex validation attribute exists, validate
        if (data.tokenfieldValidateRegex) {
            let exp = new RegExp(data.tokenfieldValidateRegex);
            let regexError = data.tokenfieldValidateRegexError || 'An error occurred!';

            if (newToken.search(exp) === -1) {
                event.preventDefault();
                growl.show(regexError, 'error');
                return;
            }
        }

        // If duplicate validation attribute exists, check for duplicates
        if (data.tokenfieldValidateDuplicate) {
            $.each(existingTokens, function(index, token) {
                if (token.value.toLowerCase() === newToken.toLowerCase()) {
                    let dupError = data.tokenfieldDuplicateError || 'Duplicate value: "' + token.label + '"';
                    event.preventDefault();
                    growl.show(dupError, 'error');
                    return;
                }
            });
        }
    });

    // Return jQuery object for chaining
    return tokens;
}
