import {updateCategories} from 'core/entities/entities.actions';

/**
 * @ngdoc service
 * @name EntryCategoryService
 * @module portfolium.core
 * @description Service for fetching entry categories
 * @class EntryCategoriesService
 */
export class EntryCategoriesService {
    constructor($http, $ngRedux, $q) {
        this.$http = $http;
        this.$ngRedux = $ngRedux;
        this.$q = $q;
    }

    /**
     * Fetch categories from the database, with current user follow data
     * @return {Promise<Object>} Resolved with category data
     *
     * @memberOf EntryCategoriesService
     */
    fetchCategories() {
        // Set a promise so other calls to `getAllCategories` know that a request is in progress
        this.isFetching = this.$q.defer();

        return this.$http({
            method: 'GET',
            url: `/proxy/system/categories`,
            params: {
                type: 'all',
            },
            // headers: {
            //     'PF-CACHE': true,
            // },
        }).then((response) => {
            const formattedCategories = this._formatCategories(response.data);

            // Cache the categories in redux
            this.$ngRedux.dispatch(updateCategories(formattedCategories));

            // Resolve promise to
            this.isFetching.resolve(formattedCategories);

            return formattedCategories;
        }, () => {
            // Request failed, unset the isFetching promise
            this.isFetching = undefined;

            return this.$q.reject();
        });
    }

    /**
     * Get all the categories
     *
     * @param {object} params Parameters for category fetching
     * @return {Promise<object>} Resolved with category data
     * @memberof EntryCategoriesService
     */
    getAllCategories(params) {

        const { categories } = this.$ngRedux.getState().entities;

        // Categories are already cached in redux -- return immediately
        if (_.isArray(categories.parents)) {
            return this.$q.resolve(categories);
        }

        // Categories are being fetched from another call -- return that promise
        if (this.isFetching) {
            return this.isFetching.promise;
        }

        // Categories haven't been fetched yet -- do that now
        return this.fetchCategories(params);
    }

    /**
     * Get a list of child categories
     *
     * @param {object} categories Raw categories data
     * @return {object[]} Collection of category objects
     * @memberof EntryCategoriesService
     */
    getChildCategories(categories) {
        return categories.children.map((id) => {
            return categories.data[id];
        });
    }

    /**
     * Get a list of child categories given a parent ID and category data
     *
     * @param {string} parentId Parent to find children of
     * @param {object} categories Raw categories data
     * @return {object[]} Collection of category objects
     * @memberof EntryCategoriesService
     */
    getChildCategoriesByParentId(parentId, categories) {
        let childCategories = [];

        _.forIn(categories.data, (category) => {
            if (category.parent_id === parentId) {
                childCategories = [...childCategories, category];
            }
        });

        return childCategories;
    }

    /**
     * Get a list of parent categories
     *
     * @param {object} categories Raw categories data
     * @return {object[]} Collection of category objects
     * @memberof EntryCategoriesService
     */
    getParentCategories(categories) {
        return categories.parents.map((id) => {
            return categories.data[id];
        });
    }

    /**
     * Get a single category (by id)
     * @param {string} categoryId Category to fetch
     * @param {object} [params={}] Optional query params
     * @return {Promise<Object>} Resolved with category
     * @memberOf EntryCategoriesService
     */
    getCategoryById(categoryId, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/system/category/${categoryId}`,
            params,
        }).then(response => response.data);
    }

    /**
     * Follow a category (by id)
     * @param {string} categoryId Category to follow
     * @return {Promise}
     *
     * @memberOf EntryCategoriesService
     */
    followCategoryById(categoryId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/users/interest/${categoryId}`,
        }).then((response) => response.data);
    }

    /**
     * Unfollow a category (by id)
     * @param {string} categoryId Category to unfollow
     * @return {Promise}
     *
     * @memberOf EntryCategoriesService
     */
    unfollowCategoryById(categoryId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/users/interest/${categoryId}`,
        }).then((response) => response.data);
    }

    /**
     * Format category data from API response
     * @param  {Array} categories Collection of all categories
     * @return {Object} Formatted categories object
     *
     * @memberOf EntryCategoriesService
     */
    _formatCategories(categories) {
        const data = categories.reduce((data, current) => {
            return _.assign(data, {[current.id]: current});
        }, {});
        const parents = categories
            .filter(category => category.parent_id === null)
            .map(category => category.id);
        const children = categories
            .filter(category => category.parent_id !== null)
            .map(category => category.id);

        return {
            data,
            parents,
            children,
        };
    }
}

EntryCategoriesService.$inject = [
    '$http',
    '$ngRedux',
    '$q',
];
