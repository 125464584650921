export default function dynamicImage(url, func, size, prepend) {
    // sanity check for a prepend param
    if (prepend) {
        let cloudimage = 'https://portfolium1.cloudimg.io/s/{function}/{size}/';
        // prepend the url with the CloudImage.io prefix
        url = cloudimage + url;
        return url.replace('{function}', func).replace('{size}', size);
    } else {
        return url.replace('{function}', func).replace('{size}', size);
    }
}
