import {combineReducers} from 'redux';

import {getPaginationReducer} from 'components/pagination/pagination.reducer';
import {searchPeopleActionTypes} from './search-people/search-people.actions';
import {searchNetworksActionTypes} from './search-networks/search-networks.actions';
import {searchCompaniesActionTypes} from './search-companies/search-companies.actions';
import {searchEntriesActionTypes} from './search-entries/search-entries.actions';
import {searchJobsActionTypes} from './search-jobs/search-jobs.actions';

export const search = combineReducers({
    people: getPaginationReducer({
        actionTypes: searchPeopleActionTypes,
    }),
    networks: getPaginationReducer({
        actionTypes: searchNetworksActionTypes,
    }),
    companies: getPaginationReducer({
        actionTypes: searchCompaniesActionTypes,
    }),
    entries: getPaginationReducer({
        actionTypes: searchEntriesActionTypes,
        paginationStyle: 'infinite',
        limit: 12
    }),
    jobs: getPaginationReducer({
        actionTypes: searchJobsActionTypes,
    }),
});
