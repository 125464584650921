import angular from 'angular';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/scan';

/**
 * @ngdoc directive
 * @name pfNavbarScrollAndHide
 * @module portfolium.component.navbar
 * @description
 * Add a scroll listener on the given context that shows/hides the subnav after
 * the user scrolls past the scroll distance threshold.
 * @param {string} pfScrollContextId Element ID that is scrolling
 */
export const navbarScrollAndHideDirective = () => {
    return {
        restrict: 'A',
        scope: {
            'scrollContextId': '@pfScrollContextId',
        },
        link(scope, $element, attrs) {
            // Make sure all elements are rendered
            scope.$evalAsync(() => {
                const scrollDistanceThreshold = 80;
                const appBarId = 'pf-app-bar';
                const subnavId = 'pf-subnav';
                const $appBar = angular.element(document.getElementById(appBarId));
                const $subnavWrapper = angular.element(document.getElementById(subnavId));
                const $scrollContent = angular.element(document.getElementById(scope.scrollContextId));

                const initContent = () => {
                    $appBar.addClass('pf-app-bar--with-subnav');
                    $scrollContent.addClass('pf-content-with-subnav');
                }

                const resetContent = () => {
                    $appBar.removeClass('pf-app-bar--with-subnav');
                    $scrollContent.removeClass('pf-content-with-subnav');
                }

                const showMenu = () => {
                    $appBar.removeClass('pf-subnav-hidden');
                    $subnavWrapper.removeClass('pf-subnav-hidden');
                };

                const hideMenu = () => {
                    $appBar.addClass('pf-subnav-hidden');
                    $subnavWrapper.addClass('pf-subnav-hidden');
                };

                // Add required classes to affected elements
                initContent();

                // Setup the scroll event stream
                const scrollStream = Observable.fromEvent($scrollContent[0], 'scroll')
                    // Parse scroll event to get the properties we need
                    .map((e) => ({
                        currentPosition: e.target.scrollTop,
                        totalHeight: e.target.scrollHeight - e.target.offsetHeight,
                    }));

                // Setup the hide menu stream
                const hideMenuStream = scrollStream
                    // Keep track of scroll direction and distance with an accumulator
                    .scan((acc, data) => {
                        if (data.currentPosition >= data.totalHeight) {
                            // Ignore scrolling that extends past the scrollable area,
                            // e.g. elastic bounce effect in osx
                            return acc;
                        }

                        if (!acc.start || (data.currentPosition < acc.current)) {
                            // Reset the accumulator if scroll direction changes
                            return {
                                distance: 0,
                                start: data.currentPosition,
                                current: data.currentPosition,
                            };
                        }

                        // Update accumulator with scroll distance and current position
                        return {
                            distance: data.currentPosition - acc.start,
                            start: acc.start,
                            current: data.currentPosition,
                        };
                    }, {})
                    // Map to a boolean value based on scroll distance
                    .map(acc => acc.distance >= scrollDistanceThreshold)
                    // Only emit value when it changes
                    .distinctUntilChanged();

                // Subscribe to hide menu stream to add/remove classes based on scroll distance
                const hideMenuSubscription = hideMenuStream.subscribe((shouldHideMenu) => {
                    if (shouldHideMenu === true) {
                        hideMenu();
                    } else {
                        showMenu();
                    }
                });

                // Clean up when element is torn down
                scope.$on('$destroy', () => {
                    // Unsubscribe from stream
                    hideMenuSubscription.unsubscribe();
                    // Reset content and menu classes
                    resetContent();
                    showMenu();
                });
            });
        },
    };
};
