import NetworkConnectTemplate from './network-connect.component.html';

/**
 * @ngdoc component
 * @name pfNetworkConnect
 * @module portfolium.component.pfNetworkConnect
 * @description controller for pfNetworkConnect
 * @param {Object} network network information to display
 */
class NetworkConnectController {
    /**
    * @ngdoc method
    * @name NetworkConnectController#$constructor
    * @methodOf NetworkConnectController
    * @description sets the defaults and get the status of the network
    */
    constructor($pfNetworkConnect, $pfToast, $mdDialog, $filter){
        this.$pfNetworkConnect = $pfNetworkConnect;
        this.$pfToast = $pfToast;
        this.$mdDialog = $mdDialog;
        this.$filter = $filter;
    }

    $onInit() {
        this.status = this.getStatus(this.network);
    }

    /**
    * @ngdoc method
    * @name NetworkConnectController#getStatus
    * @methodOf NetworkConnectController
    * @description get the current status of relation between
    *   current user and a network
    */
    getStatus(network = {}) {
        const isMember = network.member === '1';
        const isPending = network.member === '0';

        return {
            isMember,
            isPending,
        };
    }

    /**
    * @ngdoc method
    * @name NetworkConnectController#joinNetwork
    * @methodOf NetworkConnectController
    * @description sents a request to join the network
    */
    joinNetwork() {
        this.$pfNetworkConnect.requestToJoinNetwork(this.network.id).then((data)=>{
            this.status.isPending = true;
            this.$pfToast.success('Request sent');
        });
    }

    /**
    * @ngdoc method
    * @name NetworkConnectController#leaveNetwork
    * @methodOf NetworkConnectController
    * @param ev The button click event
    * @description leaves the network
    */
    leaveNetwork(ev) {
        const title = this.$filter('i18n')('Are you sure?');
        const textContent = `${this.$filter('i18n')('This will remove your membership from')} ${this.network.name}. ${this.$filter('i18n')('Your public work will not show up on their network page anymore, and you will not be able to enroll into courses or pathways for this network.')}`;
        const ariaLabel = this.$filter('i18n')('Leave this network');
        const ok = this.$filter('i18n')('Leave');
        const cancel = this.$filter('i18n')('Cancel');

        const confirm = this.$mdDialog.confirm()
            .title(title)
            .textContent(textContent)
            .ariaLabel(ariaLabel)
            .targetEvent(ev)
            .ok(ok)
            .cancel(cancel);

        this.$mdDialog.show(confirm).then(() => {
            this.$pfNetworkConnect.leaveNetwork(this.network.id).then((data)=>{
                this.status.isMember = false;
                this.status.isPending = false;
            });
        });
    }
}

NetworkConnectController.$inject = ['$pfNetworkConnect', '$pfToast', '$mdDialog', '$filter'];

export const NetworkConnectComponent = {
    bindings: {
        network: '<',
        pfClass: '<',
    },
    template: NetworkConnectTemplate,
    controller: NetworkConnectController,
    controllerAs: '$ctrl'
};
