import EntryViewerConstructionHeaderTemplate from './entry-viewer-construction-header.component.html';
import './entry-viewer-construction-header.component.scss';

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerConstructionHeaderComponent
 * @description Display list of profile in the entry viewer sidebar
 *
 * @param {callback} onClosePreview trigger when user tries to go back to editor
 */
export const EntryViewerConstructionHeaderComponent = {
    bindings: {
        onClosePreview: '&',
    },
    controllerAs: '$ctrl',
    template: EntryViewerConstructionHeaderTemplate,
};
