import {
    updateCurrentUser,
    updateAbouts,
    setSubmitData,
    updateLocations,
    resetCurrentUser,
    resetAbouts,
} from './profile-quick-edit.actions';

import {
    updateAbouts as profileUpdateAbouts,
} from '../../profile/js/action/abouts';

import {updateProfileUser} from '../../profile/js/action/profile-user';
import ProfileQuickEditTemplate from './profile-quick-edit.component.html';

/**
 * @ngdoc controller
 * @name ProfileQuickEditController
 * @module portfolium.components.profileQuickEdit
 */
class ProfileQuickEditController {
    constructor(
        $mdDialog,
        $state,
        $pfUser,
        $pfProfile,
        $pfUserAbout,
        $pfAddress,
        $pfSettings,
        $pfSession,
        $pfDashboardOverview,
        $pfProfileQuickEdit,
        $pfProfileAboutPanel,
        $ngRedux,
        $pfFilestackPreset,
        $pfFilestackSigner,
        $pfToast,
        imageFilter,
        addressFilter,
    ) {
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.$pfUser = $pfUser;
        this.$pfProfile = $pfProfile;
        this.$pfUserAbout = $pfUserAbout;
        this.$pfAddress = $pfAddress;
        this.$pfSettings = $pfSettings;
        this.$pfSession = $pfSession;
        this.$pfDashboardOverview = $pfDashboardOverview;
        this.$pfProfileQuickEdit = $pfProfileQuickEdit;
        this.$pfProfileAboutPanel = $pfProfileAboutPanel;
        this.$ngRedux = $ngRedux;
        this.$pfFilestackPreset = $pfFilestackPreset;
        this.$pfFilestackSigner = $pfFilestackSigner
        this.$pfToast = $pfToast;
        this.imageFilter = imageFilter;
        this.addressFilter = addressFilter;

        // this is the model for form data
        this.userData = {};
        this.saving = false;
    }

    $onInit() {
        // Subscribe to changes
        this._unsubscribe = this.$ngRedux.connect(this._mapStateToThis)(this);

        // get user data from session
        this.getUserData();

        // get user about data
        this.getUserAboutData();

        // get location data
        this.getLocationData();
    }

    $onDestroy() {
        // unsubscribe to state changes
        this._unsubscribe();

        // reset the redux state
        this.$ngRedux.dispatch(resetCurrentUser());
        this.$ngRedux.dispatch(resetAbouts());
    }

    _mapStateToThis(state) {
        return {
            currentUser: state.profileQuickEdit.currentUser,
            abouts: state.profileQuickEdit.abouts,
            locations: state.profileQuickEdit.locations,
        };
    }

    /**
     * Get initial user data from session
     * @return {Object}
     */
    getUserData() {
        const authedUser = this.$pfUser.getUser();

        return this.$pfProfile.getUserByUsername(authedUser.username).then((user) => {
            // update user data state
            this.$ngRedux.dispatch(updateCurrentUser(user));

            // set user data for ng-model
            return this.userData = _.assign(this.userData, {
                // username is required for POST /users/profile
                username: user.username,
                firstname: user.firstname,
                lastname: user.lastname,
                tagline: user.tagline,
            });
        });
    }

    /**
     * Get user about data
     * @return {Promise}
     */
    getUserAboutData() {
        return this.$pfUserAbout.getMyAbout().then((about) => {
            if (about) {
                // update user about data state
                this.$ngRedux.dispatch(updateAbouts(about));

                // get the current about user values
                const {
                    currentExperience,
                    currentEducation,
                } = this.getCurrentAboutData(about);

                // set current values for ng-model
                this.userData.current_experience_id = currentExperience;
                this.userData.current_education_id = currentEducation;
            }
        });
    }

    /**
     * Get user location data
     * @return {Promise}
     */
    getLocationData() {
        return this.$pfAddress.getAddresses().then((locations) => {
            // update location data state
            this.$ngRedux.dispatch(updateLocations(locations));

            // set current location for ng-model
            this.userData.current_location_id = this.getCurrentLocationId(locations);
        });
    }

    /**
     * Save the form data
     * @return {Promise}
     */
    submit() {
        // set the form as submitted
        this.form.$setSubmitted();
        this.saving = true;

        if (this.form.$valid) {
            // we have to update the state in a few different places
            // this updates state for profile quick edit
            this.$ngRedux.dispatch(setSubmitData(this.userData));

            // update application state for profile page
            // this will update 'Education' and 'Work Experience'
            this.$ngRedux.dispatch(profileUpdateAbouts(this.abouts));

            // get current location
            const currentLocation = this.getCurrentLocationData(this.locations);

            // update state for profile page
            // this will update firstname, lastname, tagline, and location
            const updatedUser = {
                firstname: this.currentUser.firstname,
                lastname: this.currentUser.lastname,
                tagline: this.currentUser.tagline,
                avatar: this.currentUser.avatar,
                cover: this.currentUser.cover,
                location: this.addressFilter(currentLocation, 'city_state'),
            };
            this.$ngRedux.dispatch(updateProfileUser(updatedUser));

            // set the avatar data
            if (this.updatedAvatarPhoto) {
                this.userData.avatar = this.updatedAvatarPhoto;
            }

            // set the cover data
            if (this.updatedCoverPhoto) {
                this.userData.cover = this.updatedCoverPhoto;
            }

            // save the data
            return this.$pfSettings.updateFields(this.userData).then((data) => {
                // update user about
                this.$pfDashboardOverview.reloadUserData();
                // set flags
                this.saving = false;
                // close modal
                this.cancel();
                // return
                return data;
            }, (err) => {
                this.saving = false;
            });
        } else {
            this.saving = false;
        }
    }

    /**
     * Close the dialog and reject the promise returned from $mdDialog.show()
     * @param  {*}       reason An argument for the rejected promise.
     * @return {Promise}
     */
    cancel(reason) {
        return this.$mdDialog.cancel(reason);
    }

    /**
     * Update the avatar photo
     */
    updateAvatarPhoto() {
        // need this
        const presetName = 'avatar';
        // set the scope
        let self = this;
        // run the picker
        return this.$pfFilestackPreset.picker(presetName, {
            // on file selected
            onFileUploadStarted: file => {
                // sanity check
                this.$pfFilestackPreset.mimeTypePanic(presetName, file);
                // set the flag
                this.loading = true;
            }
        }, (original, file, security) => {
            // update the avatar here and go
            self.$pfSettings.updateAvatar(file.key).then((newAvatar) => {
                // update the currentUser avatar object
                self.$ngRedux.dispatch(updateCurrentUser({
                    avatar: {
                        source: newAvatar,
                        url_https: self.imageFilter(newAvatar),
                    }
                }));
                // fire up a toast nugget
                self.$pfToast.success('Avatar Updated');
            });
        }, error => {
            // call the error
            self.onError(this.$pfFilestackSigner.errorMessage(error));
        });
    }

    /**
     * Update the cover photo
     */
    updateCoverPhoto() {
        // need this
        const presetName = 'cover';
        // set the scope
        let self = this;
        // run the picker
        return this.$pfFilestackPreset.picker(presetName, {
            // on file selected
            onFileUploadStarted: file => {
                // sanity check
                this.$pfFilestackPreset.mimeTypePanic(presetName, file);
                // set the flag
                this.loading = true;
            }
        }, (original, file, security) => {
            // update the cover here
            self.$pfSettings.updateCover(file.key).then((newCover) => {
                // update the currentUser cover object
                self.$ngRedux.dispatch(updateCurrentUser({
                    newCover,
                }));
                // set the flag
                self.loading = false;
                // pop a toast here
                self.$pfToast.success('Cover Updated');
            });
        }, error => {
            // call the error
            self.onError(this.$pfFilestackSigner.errorMessage(error));
        });
    }

    /**
     * @ngdoc method
     * @name ProfileQuickEditController#onError
     * @methodOf pfProfileEditAvatar
     * @description show an error
     */
    onError(error) {
        // log for now
        // console.log(error);
        // set the flag
        this.loading = false;
        // alert the user
        this.$pfToast.error('There was an error');
    }

    /**
     * Open user about add panel
     * @param {String}   panelName The name of the panel to open
     * @return {Promise}
     */
    openPanel(panelName) {
        const next = (panel, {didCreateEntry}) => {
            if (didCreateEntry) {
                return;
            }
            this.$pfProfileQuickEdit.openDialog();
        };

        return this.$pfProfileAboutPanel.addAbout(panelName, next).then(null, (err) => {
            throw new Error(`[ProfileQuickEditController#openPanel] Error opening panelName -> ${panelName}: ${err}`);
        });
    }

    /**
     * Open add location page in settings
     * @return {Promise}
     */
    addLocation() {
        return this.cancel().then(() => {
            return this.$state.go('settingsLocation');
        });
    }

    /**
     * Get the current about user values
     * @return {Object}
     */
    getCurrentAboutData(about) {
        const currentExperience = _.find(about.experiences, {featured: '1'});
        const currentEducation = _.find(about.educations, {current: '1'});

        return {
            currentExperience: (!_.isEmpty(currentExperience)) ? currentExperience.id : null,
            currentEducation: (!_.isEmpty(currentEducation)) ? currentEducation.id : null,
        };
    }

    /**
     * Get the current location values
     * @return {Object}
     */
    getCurrentLocationData(locations) {
        const currentLocation = _.find(locations, {current: '1'});

        return (!_.isEmpty(currentLocation)) ? currentLocation : null;
    }

    /**
     * Get the current location id
     * @return {Integer}
     */
    getCurrentLocationId(locations) {
        const currentLocation = _.find(locations, {current: '1'});

        return (!_.isEmpty(currentLocation)) ? currentLocation.id : null;
    }
}

ProfileQuickEditController.$inject = [
    '$mdDialog',
    '$state',
    '$pfUser',
    '$pfProfile',
    '$pfUserAbout',
    '$pfAddress',
    '$pfSettings',
    '$pfSession',
    '$pfDashboardOverview',
    '$pfProfileQuickEdit',
    '$pfProfileAboutPanel',
    '$ngRedux',
    '$pfFilestackPreset',
    '$pfFilestackSigner',
    '$pfToast',
    'imageFilter',
    'addressFilter',
];

export const ProfileQuickEditComponent = {
    template: ProfileQuickEditTemplate,
    controller: ProfileQuickEditController,
    controllerAs: 'editCtrl'
};
