import angular from 'angular';
import { MODULE_NAME } from 'components/pf-utils/index.js';
const $pfEnv = angular.injector(['ng', MODULE_NAME]).get('$pfEnv');
const apiKey = $pfEnv.getEnv('PF_FILESTACK_KEY');

export const FILESTACK_CONFIG = {
    apiKey,
    security: {},
    cname: $pfEnv.getEnv('PF_FILESTACK_CNAME'),
    transformUrl: `https://process.filestackapi.com/${apiKey}`,
    storeOptions: {
        location: 's3',
        region: $pfEnv.getEnv('PF_FILESTACK_REGION'),
        container: $pfEnv.getEnv('PF_S3_EXTERNAL_BUCKET'),
        workflows: [$pfEnv.getEnv('PF_STRIP_EXIF_METADATA_WORKFLOW')],
    },
};
