export const SITEWIDE_SEARCH_INIT  = 'SITEWIDE_SEARCH_INIT';
export const SITEWIDE_SEARCH_OPEN_PANEL  = 'SITEWIDE_SEARCH_OPEN_PANEL';
export const SITEWIDE_SEARCH_CLOSE_PANEL  = 'SITEWIDE_SEARCH_CLOSE_PANEL';
export const SITEWIDE_SEARCH_QUERY_UPDATE  = 'SITEWIDE_SEARCH_QUERY_UPDATE';
export const SITEWIDE_SEARCH_MENU_ITEM_INDEX_UPDATE  = 'SITEWIDE_SEARCH_MENU_ITEM_INDEX_UPDATE';
export const SITEWIDE_SEARCH_NEXT_MENU_ITEM  = 'SITEWIDE_SEARCH_NEXT_MENU_ITEM';
export const SITEWIDE_SEARCH_PREVIOUS_MENU_ITEM  = 'SITEWIDE_SEARCH_PREVIOUS_MENU_ITEM';

/**
 * Opens panel
 */
export const initSitewideSearch = (totalMenuItems) => {
    return {
        type: SITEWIDE_SEARCH_INIT,
        payload: {
            totalMenuItems,
        }
    };
};
/**
 * Opens panel
 */
export const openPanel = () => {
    return {
        type: SITEWIDE_SEARCH_OPEN_PANEL,
    };
};

/**
 * Closes panel
 */
export const closePanel = () => {
    return {
        type: SITEWIDE_SEARCH_CLOSE_PANEL,
    };
};

/**
 * Updates query
 * @param {String} query User object
 */
export const queryUpdate = (query) => {
    return {
        type: SITEWIDE_SEARCH_QUERY_UPDATE,
        payload: {
            query,
        },
    };
};

/**
 * Updates query
 * @param {String} query User object
 */
export const menuItemIndexUpdate = (menuItemIndex) => {
    return {
        type: SITEWIDE_SEARCH_MENU_ITEM_INDEX_UPDATE,
        payload: {
            menuItemIndex,
        },
    };
};

/**
 * Updates user
 * @param {Object} user User object
 */
export const nextMenuItem = () => {
    return {
        type: SITEWIDE_SEARCH_NEXT_MENU_ITEM,
    };
};

/**
 * Updates user
 * @param {Object} user User object
 */
export const previousMenuItem = () => {
    return {
        type: SITEWIDE_SEARCH_PREVIOUS_MENU_ITEM,
    };
};

