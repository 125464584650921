import angular from 'angular';
import TagTeammatesService from './js/service/phrase-finder.service';

const MODULE_NAME = 'portfolium.component.phraseFinder';

(function() {
    let app = angular.module(MODULE_NAME, []);

    // Services
    app.service('$pfPhraseFinder', TagTeammatesService);
})();

export default MODULE_NAME;
