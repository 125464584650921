export const SETTINGS_BLOCKED_ACCOUNTS_FETCH = 'SETTINGS_BLOCKED_ACCOUNTS_FETCH';
export const SETTINGS_BLOCKED_ACCOUNTS_UPDATE_USER_LIST = 'SETTINGS_BLOCKED_ACCOUNTS_UPDATE_USER_LIST';
export const SETTINGS_BLOCKED_ACCOUNTS_UNBLOCK_USER = 'SETTINGS_BLOCKED_ACCOUNTS_UNBLOCK_USER';
export const SETTINGS_BLOCKED_ACCOUNTS_SET_PENDING_CONNECTION = 'SETTINGS_BLOCKED_ACCOUNTS_SET_PENDING_CONNECTION';

export const fetchBlockedAccounts = () => {
    return {
        type: SETTINGS_BLOCKED_ACCOUNTS_FETCH,
    };
};

export const updateUserList = (userList) => {
    return {
        type: SETTINGS_BLOCKED_ACCOUNTS_UPDATE_USER_LIST,
        payload: {
            userList
        }
    };
};

export const unblockUser = (userId) => {
    return {
        type: SETTINGS_BLOCKED_ACCOUNTS_UNBLOCK_USER,
        payload: {
            userId
        }
    };
};

export const setPendingConnection = (userId) => {
    return {
        type: SETTINGS_BLOCKED_ACCOUNTS_SET_PENDING_CONNECTION,
        payload: {
            userId
        }
    };
};
