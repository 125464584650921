import {
    mediaPlay,
    mediaPause,
} from './media-player.actions';

/**
 * @ngdoc service
 * @name AttachmentMediaService
 * @module portfolium.component.attachmentViewer
 **/
export class AttachmentMediaService {
    constructor($ngRedux) {
        // Bind services
        this.$ngRedux = $ngRedux;
        // persistent data
        this.players = {};
        this.init();
    }

    init() {
        // Subscribe to state changes
        this.unsubscribe = this.$ngRedux.connect(this.mapStateToThis)(this);
    }

    mapStateToThis(state) {
        return {
            mediaPlayer: state.attachmentViewer.mediaPlayer,
        };
    }

    /**
     * Play the media as defined by the mediaId
     * @param {String}  mediaId   The id of the media element
     * @return {Void}
     */
    play(mediaId) {
        if (!mediaId) {
            return;
        }
        // stop any currently playing media first
        this.stop();
        const api = this.players[mediaId];
        if (api) {
            api.play();
        }
    }

    /**
     * Stops the currently playing media
     * @return {Void}
     */
    stop() {
        if (!!this.mediaPlayer.playing) {
            const api = this.players[this.mediaPlayer.playing];
            if (api) {
                api.pause();
            }
        }
    }

    /**
     * Seek the media to given time
     * @param {String}  mediaId   The element id of the media element
     * @param {Integer} seconds   The time in seconds to seek to
     * @param {Boolean} andPlay   The flag to indicate whether the video should play
     * @return {Void}
     */
    setCurrentTime(mediaId, seconds = 0, andPlay = false) {
        if (!mediaId) {
            return;
        }
        const api = this.players[mediaId];
        if (api) {
            api.setCurrentTime(seconds);
            // play, if requested
            if (andPlay) {
                api.play();
            }
        }
    }

    /**
     * Get the current time on specified video
     * @param {String}  mediaId   The element id of the media element
     * @return {Integer}
     */
    getCurrentTime(mediaId) {
        let currentTime = 0;
        if (mediaId) {
            const api = this.players[mediaId];
            if (api) {
                currentTime = api.currentTime;
            }
        }
        return currentTime;
    }

    /**
     * Is the specified video currently playing
     * @param {String}  mediaId   The element id of the media element
     * @return {Boolean}
     */
    isPlaying(mediaId) {
        if (!mediaId) {
            return false;
        }
        return this.mediaPlayer.playing === mediaId;
    }

    /**
     * Register the media player api and save a reference to it by the mediaId
     * @param {String}  mediaId     The element id of the media element
     * @param {Object}  player      The player api
     * @param {Object}  instance    The new player instance
     * @return {Void}
     */
    registerPlayer(mediaId, player, instance) {
        if (mediaId && !_.isEmpty(player)) {
            // register event handlers
            if (instance) {
                // use the 'playing' event for MediaElementPlayer instances
                player.addEventListener('playing', this.onPlay.bind(this), false);
            } else {
                player.addEventListener('play', this.onPlay.bind(this), false);
            }
            player.addEventListener('pause', this.onStop.bind(this), false);
            player.addEventListener('ended', this.onStop.bind(this), false);
            // save player
            this.players[mediaId] = player;
        }
    }

    /**
     * Unegister the media player api
     * @param {String}  mediaId   The element id of the media element
     * @return {Void}
     */
    unregisterPlayer(mediaId) {
        if (mediaId) {
            const player = this.players[mediaId];
            if (!player.paused) {
                player.pause();
            }
            player.remove();
            delete this.players[mediaId];
        }
    }

    /**
     * The media is playing
     * @param {Object}  ev   The event
     * @return {Void}
     */
    onPlay(ev) {
        // get the id of the media element, so we can send that
        // to the redux state
        const mediaId = _.get(ev, `detail.target.id`);
        // mediaelement api appends a suffix to the element, need to remove it
        if (!_.isEmpty(mediaId)) {
            const mediaIdParts = mediaId.split('_');
            if (mediaIdParts[0]) {
                this.$ngRedux.dispatch(mediaPlay(mediaIdParts[0]));
            }
        }
    }

    /**
     * The media stopped
     * @return {Void}
     */
    onStop() {
        this.$ngRedux.dispatch(mediaPause());
    }
}

AttachmentMediaService.$inject = [
    '$ngRedux',
];
