import JobApplyResumeTemplate from './job-apply-resume.component.html';
import './job-apply-resume.component.scss';

class JobApplyResumeController {
    constructor($pfToast, $pfUser) {
        this.$pfToast = $pfToast;
        // get user object
        this.user = $pfUser.getUser();
    }

    $onInit() {
        // Set resume processing if user is authed
        this.setProcessResume();
    }

    /**
     * Remove the current resume
     */
    removeResume() {
        this.loading = false;
        this.onResumeDeleteEvent();
    }

    /**
     * Displays an error when the upload a resume files
     * @param  {string} err description of the error
     */
    onResumeError(err) {
        this.loading = false;
        this.$pfToast.error(err);
    }

    /**
     * @description This is called when the upload is successful
     * @param {Object} resume The resume
     */
    onResumeUpdate(resume) {
        this.onResumeUpdateEvent({ resume });
        this.loading = false;
    }

    /**
     * Has the user set their resume to private?
     * @return {Boolean}
     */
    getResumePrivacy() {
        if (!this.user) {
            return false;
        }

        return this.user.resume_privacy === '1';
    }

    setProcessResume() {
        this.processResume = !!this.user || false;
    }
}

JobApplyResumeController.$inject = [
    '$pfToast',
    '$pfUser',
];

export const JobApplyResumeComponent = {
    bindings: {
        onResumeUpdateEvent: '&onResumeUpdate',
        onResumeDeleteEvent: '&onResumeDelete',
    },
    controller: JobApplyResumeController,
    controllerAs: '$ctrl',
    template: JobApplyResumeTemplate,
};
