/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerListController
 * @description Manages the show more and show less logic list.
 *              Use: First of all, create a controller and extend it with
 *              EntryViewerListController e.g: class `MyListController extends EntryViewerListController`
 *              In the controller make sure to call `super($element, $timeout);`
 *              Add the function `$onChanges` and include this code replacing `myList` with the list you want
 *              to truncate:
 *                  `$onChanges() {
 *                      if (!this.myList || !this.myList.length) {
 *                          return;
 *                      }
 *                      this.listLength = this.myList.length;
 *                   }`
 *              In the template now there are 3 things that it has to be done:
 *              1 - Add limitTo filter to the ng-repeat `limitTo : $ctrl.getListLimit()`
 *              2 - Add the Show more button, and add this logic
 *                  `ng-if="$ctrl.likers.length && $ctrl.isShowMoreActive()"
 *                   ng-click="::$ctrl.showMore()"`
 *              3 - Add the Show less buton, and add this logic
 *                  `ng-if="$ctrl.teammates.length && $ctrl.isShowLessActive()"
 *                  `ng-click="::$ctrl.showLess()"`
 *
 */
export class EntryViewerListController {
    constructor($element, $timeout) {
        this.$element = $element;
        this.$timeout = $timeout;
        // Default list Limit to 9, it can be
        // overritten on the new controller
        this.listLimit = 9;
        this.showingMore = true;
    }

    /**
     * Calculates how many items to show at a given time
     * @return {boolean}
     */
    getListLimit() {
        return (this.isShowMoreActive()) ? this.listLimit : this.listLength;
    }

    /**
     * Calculates how many items are not being shown a given time
     * @return {boolean}
     */
    getListRest() {
        if (!this.listLength) {
            return 0;
        }
        return (this.isShowMoreActive()) ? this.listLength - this.listLimit : this.listLength;
    }

    /**
     * Show more items action
     */
    showMore() {
        this.showingMore = false;
        this.$timeout(() => {
            this.$element.find('.pf-entry-viewer-sidebar-show-less').focus();
        });
    }

    /**
     * Show less items action
     */
    showLess() {
        this.showingMore = true;
        this.$timeout(() => {
            this.$element.find('.pf-entry-viewer-sidebar-show-more').focus();
        });
    }

    /**
     * Is the show more button active ?
     * @return {boolean}
     */
    isShowMoreActive() {
        if (this.listLength <= this.listLimit) {
            return false;
        }
        return this.showingMore;
    }

    /**
     * Is the show less button active ?
     * @return {boolean}
     */
    isShowLessActive() {
        if (this.listLength <= this.listLimit) {
            return false;
        }
        return !this.showingMore;

    }
}
