import {combineReducers} from 'redux';

import {getPaginationReducer} from 'components/pagination/pagination.reducer';
import {challengesJoin} from './challenges-join/challenges-join.reducer';
import {challengeDetail} from './challenge-detail/challenge-detail.reducer';
import {challengesListActionTypes} from './challenges-list/challenges-list.actions';

export const challenges = combineReducers({
    challengeDetail,
    challengesJoin,
    challengesList: getPaginationReducer({
        actionTypes: challengesListActionTypes,
    }),
});
