import CardCheckboxTemplate from './card-checkbox.component.html';
import './card-checkbox.component.scss';

/**
 * @ngdoc component
 * @name CardCheckboxComponent
 * @module portfolium.components.forms
 **/
export const CardCheckboxComponent = {
    bindings: {
        'title': '<pfTitle',
        'description': '<',
        'image': '<',
        'isChecked': '<',
        'onClick': '&',
    },
    template: CardCheckboxTemplate,
    controllerAs: '$ctrl',
}
