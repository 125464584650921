/**
 * @ngdoc service
 * @module portfolium.core.services.filestack
 * @name $pfFilestackOptions
 * @description Handle complex logic around configuration options for v3 of the
 * Filestack API.
 */
export class FilestackOptionsService {
    constructor(
        PF_FILESTACK_CONFIG,
        PF_MIMETYPES_CONFIG,
        PF_FILESTACK_CONVERT,
    ) {
        this.PF_FILESTACK_CONFIG = PF_FILESTACK_CONFIG;
        this.PF_MIMETYPES_CONFIG = PF_MIMETYPES_CONFIG;
        this.PF_FILESTACK_CONVERT = PF_FILESTACK_CONVERT;
    }

    /**
     * Get a list of accepted mimetypes and/or file extensions
     *
     * @param {string|string[]} [typeName] If a type name or names are provided,
     *     get only those types. Accepted values are "document", "slideshow",
     *     "spreadsheet", "design", "audio", "image", "pdf", and "video"
     * @param {boolean} onlyMimes if true, only return mimetypes
     * @return {string[]} List of mimetypes and extensions
     * @memberof FilestackOptionsService
     */
    getAcceptedTypes(typeName, onlyMimes = false) {
        const hasTypeName = (typeName) => _.has(this.PF_MIMETYPES_CONFIG, typeName);
        const flattenType = (type) => [...type.mimetypes, ...type.extensions];

        // Return a single accepted mimetype/extension list
        if (_.isString(typeName)) {
            if (!hasTypeName(typeName)) {
                return [];
            }

            return flattenType(this.PF_MIMETYPES_CONFIG[typeName]);
        }

        // Return accepted mimetypes/extensions of given types
        if (_.isArray(typeName)) {
            return _.flatten(typeName.map((name) => {
                if (!hasTypeName(name)) {
                    return [];
                }

                return flattenType(this.PF_MIMETYPES_CONFIG[name]);
            }));
        }

        if (onlyMimes) {
            return _.flatMap(this.PF_MIMETYPES_CONFIG, 'mimetypes');
        }

        // Otherwise return all accepted mimetypes and extensions
        return _.flatMap(this.PF_MIMETYPES_CONFIG, flattenType);
    }
}

FilestackOptionsService.$inject = [
    'PF_FILESTACK_CONFIG',
    'PF_MIMETYPES_CONFIG',
    'PF_FILESTACK_CONVERT',
];
