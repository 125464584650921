import ImagePickerTemplate from './image-picker.component.html';
import './image-picker.component.scss';

class ImagePickerController {
    constructor(
        $element,
        $pfDropZone,
        $pfFilestack,
        $pfFilestackPreset,
        $pfFilestackConvert,
        $pfFilestackSigner,
        $timeout,
        isDefaultImageFilter,
    ) {
        this.$element = $element;
        this.$pfDropZone = $pfDropZone;
        this.$pfFilestack = $pfFilestack;
        this.$pfFilestackPreset = $pfFilestackPreset;
        this.$pfFilestackConvert = $pfFilestackConvert;
        this.$pfFilestackSigner = $pfFilestackSigner;
        this.$timeout = $timeout;
        this.isDefaultImageFilter = isDefaultImageFilter;
    }

    at2x(dimension) {
        return parseInt(dimension, 10) * 2;
    }

    $postLink() {
        // get the element
        this.$element.attr('tabindex', -1);
        // init the drop ziz
        this.$pfDropZone.init(
            this.$element,
            {
                onDrop: file => {
                    // set the path
                    const path = this.$pfFilestackPreset.getPresetPath(this.presetName);
                    // set the type
                    const type = this.$pfFilestackPreset.getPresetType(this.presetName);
                    // validate the mimetype
                    const validMimetypeForType = this.$pfFilestackConvert.mimetypeBelongsToType(file.type, type);
                    // set the flag
                    this.loading = true;
                    // sanity check
                    if (validMimetypeForType) {
                        // get the security policy for an upload
                        this.$pfFilestackSigner.getUploadPolicy(this.presetName, file.name).then(security => {
                            // upload the file
                            this.$pfFilestack.upload(file, { path:security.path }, {}, security).then((meta) => {
                                // set the clean url
                                meta.clean_url = meta.url;
                                // set the security
                                meta.security = security;
                                // call the upload finished
                                this.onFileUploadFinished(meta);
                                // set the flag
                                this.loading = false;
                            }).catch(err => {
                                // set the on error
                                this.onError({err: 'Something went wrong, please try again.'});
                            });
                            // return
                            return;
                        }).catch(error => {
                            // set the on error
                            this.onError({err: this.$pfFilestackSigner.errorMessage(error)});
                        });
                    } else {
                        // set the on error
                        this.onError({err: `Sorry, that file type isn't supported.`});
                    }
                },
                multipleFiles: false,
                fullScreenMode: false,
            },
        ).then(dropZoneRef => {
            // get the drop zone ref
            this.dropZoneRef = dropZoneRef;
        });
    }

    $onDestroy() {
        // destroy the drop zone ref
        this.dropZoneRef.destroy();
        // delete the drop zone ref
        delete this.dropZoneRef;
    }

    /**
     * Opens the file stack picker, and manages the loading
     *     events
     * @return {Promise}
     */
    pick() {
        // set the scope
        let self = this;
        // run the picker
        return this.$pfFilestackPreset.picker(this.presetName, {
            // on file selected
            onFileUploadStarted: file => {
                // sanity check
                this.$pfFilestackPreset.mimeTypePanic(this.presetName, file);
                // set the flag
                this.loading = true;
            }
        }, (original, file, security) => {
            // set the handle
            original.handle = file.handle;
            // set the url
            original.url = file.url;
            // set the clean url
            original.clean_url = file.url;
            // set the key
            original.key = file.key;
            // set the security
            original.security = security;
            // rock the house
            self.onFileUploadFinished(original);
        }, error => {
            // call the error
            this.onError({err: this.$pfFilestackSigner.errorMessage(error)});
        });
    }

    /**
     * @ngdoc method
     * @name ImagePickerController#onError
     * @methodOf pfProfileEditAvatar
     * @description show an error
     */
    onError(error) {
        // log for now
        // console.log(error);
        // set the flag
        this.loading = false;
        // alert the user
        this.$pfToast.error('Something went wrong, please try again');
    }

    /**
     * Manage the image upload finish
     * @param  {object} meta image data
     */
    onFileUploadFinished(meta) {
        // set timeout
        this.$timeout(() => {
            // set loading
            this.loading = false;
        });
        // get the signed url
        const signedUrl = this.$pfFilestackSigner.signPreviewUrl(meta, meta.clean_url);
        // set the meta url
        meta.url = signedUrl;
        // set src
        this.src = signedUrl;
        // call onUpdate here
        this.onUpdate({ meta });
    }

    /**
     * Determine when to show upload view
     */
    showUploader() {
        if (_.isNil(this.src)) {
            return true;
        }

        if (this.isDefaultImageFilter(this.src)) {
            return true;
        }

        return false;
    }

    /**
     * Delete the image
     */
    deleteImage($event) {
        $event.preventDefault();
        $event.stopImmediatePropagation();

        this.src = null;
        this.onUpdate({ meta: null });
    }
}

ImagePickerController.$inject = [
    '$element',
    '$pfDropZone',
    '$pfFilestack',
    '$pfFilestackPreset',
    '$pfFilestackConvert',
    '$pfFilestackSigner',
    '$timeout',
    'isDefaultImageFilter',
];

export const ImagePickerComponent = {
    bindings: {
        src: '<',
        presetName: '<pfPresetName',
        height: '<pfHeight',
        width: '<pfWidth',
        onUpdate: '&',
        onError: '&',
    },
    controllerAs: '$ctrl',
    template: ImagePickerTemplate,
    controller: ImagePickerController,
};
