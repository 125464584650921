import _ from 'lodash';

import {
    USER_CONNECTION_ADD_CONNECTED_USER,
    USER_CONNECTION_ADD_PENDING_USER,
    USER_CONNECTION_ADD_REQUEST,
    USER_CONNECTION_REMOVE_CONNECTED_USER,
    USER_CONNECTION_REMOVE_PENDING_USER,
    USER_CONNECTION_REMOVE_REQUEST,
} from './user-connect.actions';

export const userConnectionInitialState = {
    connected: false,
    pending: false,
    isRequester: false,
};

/**
 * The key for this state will be the userId of the connected
 * user and the value will be an object like userConnectionInitialState
 *
 * Example:
 * {
 *   '101010': {
 *     connected: true,
 *     pending: false,
 *     isRequester: false,
 *   },
 *   ...
 * }
 */
export const userConnections = (state = {}, action = {}) => {
    switch (action.type) {
        case USER_CONNECTION_ADD_CONNECTED_USER:
        case USER_CONNECTION_REMOVE_CONNECTED_USER:
        case USER_CONNECTION_ADD_PENDING_USER:
        case USER_CONNECTION_REMOVE_PENDING_USER:
        case USER_CONNECTION_ADD_REQUEST:
        case USER_CONNECTION_REMOVE_REQUEST:
            return _.assign({}, state, {
                [action.payload.userId]: userConnection(state[action.payload.userId], action),
            });
        default:
            return state;
    }
};

export const userConnection = (state = userConnectionInitialState, action = {}) => {
    switch (action.type) {
        case USER_CONNECTION_ADD_CONNECTED_USER:
            return _.assign({}, state, {
                connected: true,
                pending: false,
            });
        case USER_CONNECTION_REMOVE_CONNECTED_USER:
            return _.assign({}, state, {
                connected: false,
                pending: false,
            });
        case USER_CONNECTION_ADD_PENDING_USER:
            return _.assign({}, state, {
                connected: false,
                pending: true,
            });
        case USER_CONNECTION_REMOVE_PENDING_USER:
            return _.assign({}, state, {
                connected: false,
                pending: false,
            });
        case USER_CONNECTION_ADD_REQUEST:
            return _.assign({}, state, {
                isRequester: true,
            });
        case USER_CONNECTION_REMOVE_REQUEST:
            return _.assign({}, state, {
                isRequester: false,
            });
        default:
            return state;
    }
};
