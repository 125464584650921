import uuid from 'node-uuid';

/**
 * @ngdoc service
 * @module portfolium.entryEditor.entryAttachments
 * @name $pfEntryAttachment
 * @description Handles logic for uploads of attachments
 */
export class EntryAddAttachmentService {
    constructor(
        $http,
        $pfEntryAttachment,
        $pfFilestackConvert,
        $pfFilestackOptions,
        $pfFilestackPreset,
        $pfFilestackSigner,
        $pfToast,
        $q,
        PF_ENTRY_ATTACHMENTS_TYPES
    ) {
        this.$http = $http;
        this.$pfEntryAttachment = $pfEntryAttachment;
        this.$pfFilestackConvert = $pfFilestackConvert;
        this.$pfFilestackOptions = $pfFilestackOptions;
        this.$pfFilestackPreset = $pfFilestackPreset;
        this.$pfFilestackSigner = $pfFilestackSigner;
        this.$pfToast = $pfToast;
        this.$q = $q;
        this.PF_ENTRY_ATTACHMENTS_TYPES = PF_ENTRY_ATTACHMENTS_TYPES;

        this.presetName = 'attachment'
    }

    /**
     * Upload an attachment file from a file and try to get its preview image
     * @param {object} file Raw file data from file input
     * @param {object} options Configuration options
     * @param {function} options.onStart Callback for file upload start
     * @param {function} options.onProgress Callback for file upload progress
     * @param {function} options.onSuccess Callback for file upload success
     * @param {function} options.onFail Callback for file upload failure
     * @param {string|array} allowedFileTypes Single type or array if types to allow uploaded, defined by us
     * @return {Promise<object>} Resolved with a new attachment object
     */

    addAttachmentFromFile(
        file,
        {
            onStart,
            onProgress,
            onSuccess,
            onFail,
        },
        allowedFileTypes = null,
    ) {
        // sanity check the the file type is allowed
        if (!this.validFileType(file, allowedFileTypes)) {
            // set the toast here
            this.$pfToast.error(`Sorry, that file type isn't supported.`);
            // reject the nugget
            return this.$q.reject();
        }
        // create a temp id
        const _id = uuid.v4();
        // Trigger start callback
        onStart({ _id, title: file.name });
        // get the security policy for an upload
        return this.$pfFilestackSigner.getUploadPolicy('project_attachment', file.name).then(security => {
            // Try to upload the file
            return this.$pfFilestackPreset.upload(
                this.presetName,
                file,
                {
                    onProgress: ({ totalPercent }) => {
                        // call onProgress here and get going ...
                        onProgress({ _id, percentage: totalPercent });
                    }
                },
                {
                    // set the path in the store
                    path: security.path,
                },
                security
            ).then(filestackMeta => {
                // set the security from the call above
                filestackMeta.security = security;
                // retur the attachnent from filestack
                return this.getAttachmentFromFilestackMeta(filestackMeta).then((attachment) => {
                    // call onSuccess
                    onSuccess({ _id, meta: attachment });
                    // resolve the attachment
                    return this.$q.resolve(attachment);
                });
            }).catch(() => {
                // call onFail
                onFail({ _id });
                // reject here
                return this.$q.reject();
            });
        }).catch(error => {
            // call onFail
            onFail({ _id });
            // reject here
            return this.$q.reject();
        });
    }

    /**
     * Convert a document that has already been uploaded via Filestack into an
     * image (PNG format).
     *
     * @param {object} filestackMeta Metadata returned by the Filestack API/SDK
     * @returns {Promise<object>} Resolved with Filestack meta for newly uploaded image
     * @memberof AddAttachmentService
     */
    convertDocumentToImage(filestackMeta) {
        // sanity check that we can convert this file
        if (!this.$pfFilestackConvert.canConvertToImage(filestackMeta)) {
            // File conversion is not supported for this file
            return this.$q.reject();
        }
        // create the store path here
        const path = this.$pfFilestackPreset.convertPath(filestackMeta.key, 'png');
        // Attempt to convert document to PNG
        return this.$pfFilestackPreset.convert(
            this.presetName,
            filestackMeta.handle,
            'png',
            path,
            filestackMeta.security
        );
    }

    /**
     * Process an uploaded file and resolve with a valid attachment object.
     * Supported file types will be converted into an image that is embedded as
     * a "preview" image on the attachment.
     *
     * @param {object} filestackMeta Metadata returned by the Filestack API/SDK
     * @returns {Promise<object>} Resolved with an EntryAttachment object of type
     *     "image", or "document".
     * @memberof AddAttachmentService
     */
    getAttachmentFromFilestackMeta(filestackMeta) {
        // get the deferrment
        const deferred = this.$q.defer();
        // get the image type name
        const imageTypeName = this.PF_ENTRY_ATTACHMENTS_TYPES.IMAGE.name;
        // get the bools
        const isImageType = this.$pfFilestackConvert
            .mimetypeBelongsToType(filestackMeta.mimetype, imageTypeName)
        // Determine whether file is an image
        if (isImageType) {
            // Create an image attachment object
            const imageAttachment = this.$pfEntryAttachment
                .createImageAttachmentFromMeta(filestackMeta);
            // Resolve promise with image attachment
            deferred.resolve(imageAttachment);
            // No need to try and convert image, resolve immediately
            return deferred.promise;
        }
        // Try to convert the document into an image
        this.convertDocumentToImage(filestackMeta)
            .then((imageMeta) => {
                // Image conversion succeeded, create document attachment with preview
                const documentAttachment = this.$pfEntryAttachment
                    .createDocumentAttachmentFromMeta(filestackMeta, imageMeta);
                // get the security policy for an upload
                return this.$pfFilestackSigner.getReadPolicy(
                    documentAttachment.meta.preview.file.url
                )
                .then(security => {
                    // set the security
                    documentAttachment.meta.preview.file.security = security;
                    // resolve the nugg
                    deferred.resolve(documentAttachment);
                })
                .catch(error => {
                    // log for now
                    // console.log(error);
                });
            })
            .catch(() => {
                // Image conversion failed, create document attachment without preview
                const documentAttachment = this.$pfEntryAttachment
                    .createDocumentAttachmentFromMeta(filestackMeta);
                // resolve the nugg
                deferred.resolve(documentAttachment);
            });

        // return the promise
        return deferred.promise;
    }

    /**
     * Upload a file via the Filestack picker UI.
     *
     * @param {object} options Configuration options
     * @param {function} [options.onOpen] Callback for file picker open
     * @param {function} [options.onClose] Callback for file picker close
     * @param {function} options.onStart Callback for file upload start
     * @param {function} options.onProgress Callback for file upload progress
     * @param {function} options.onSuccess Callback for file upload success
     * @param {function} options.onFail Callback for file upload failure
     *
     * @returns {Promise<object>} Resolved with new attachment object
     * @memberof AddAttachmentService
     */
    addAttachmentFromFilestack({
        onOpen = () => {},
        onClose = () => {},
        onStart = () => {},
        onProgress = () => {},
        onSuccess = () => {},
        onFail = () => {},
        acceptedTypes = null
    }) {
        // create a temp id
        const uniqueId = uuid.v4();
        // create a promise to return the attachment
        const deferred = this.$q.defer();
        // get the security policy for an upload
        return this.$pfFilestackSigner.getPickerPolicy(this.presetName).then(security => {
            // Trigger the Filestack picker UI
            this.$pfFilestackPreset.pick(this.presetName, {
                onOpen,
                onClose,
                // Configure picker callbacks to execute our callbacks
                onFileUploadStarted: (file) => {
                    // create the const
                    const _id = uniqueId + file.filename;
                    // call onStart
                    onStart({ _id, title: file.filename });
                },
                onFileUploadProgress: (file, progressEvent) => {
                    // create the const
                    const _id = uniqueId + file.filename;
                    // call onProgress
                    onProgress({ _id, percentage: progressEvent.totalPercent });
                },
                onFileUploadFinished: (original) => {
                    // go through all the crazy goodness to store and delete
                    this.$pfFilestackPreset.storeAndDelete(this.presetName, original, security, file => {
                        // create the constant id here
                        const _id = uniqueId + original.filename;
                        // get the security policy for an upload
                        return this.$pfFilestackSigner.getReadPolicy(file.url).then(security => {
                            // set the security from the call above
                            file.security = security;
                            // get the attachment from filestack
                            this.getAttachmentFromFilestackMeta(file)
                                // do the then
                                .then((attachment) => {
                                    // on success called here
                                    onSuccess({ _id, meta: attachment });
                                    // defer the resolve, jim
                                    deferred.resolve(attachment);
                                });
                        }).catch(error => {
                            // call the on error
                            this.onError(error, uniqueId, file, onFail, deferred);
                        });
                    }, error => {
                        // call the on error
                        this.onError(error, uniqueId, file, onFail, deferred);
                    });
                },
                onFileUploadFailed: (file, error) => {
                    // call the on error
                    this.onError(error, uniqueId, file, onFail, deferred);
                },
            },
            acceptedTypes,
            security,
        );
        // return the promise
        return deferred.promise;
        }).catch(error => {
            // reject
            deferred.reject();
        });
    }

    /**
     * @ngdoc method
     * @name EntryAddAttachmentService#onError
     * @methodOf pfProfileEditAvatar
     * @description show an error
     */
    onError(error, uniqueId, file, { onFail = () => {} }, deferred)
    {
        // need this
        const _id = uniqueId + file.filename;
        // onFail
        onFail({ _id });
        // reject
        deferred.reject();
    }

    /**
     * Attempt to create an attachment from an url
     * @param  {string} url                url to process
     * @param  {Function} options.onStart    callback function to execute on Start
     * @param  {Function} options.onSuccess  callback function to execute on Success
     * @param  {Function} options.onFail     callback function to execute on Fail
     * @return {Promise} Resolved after the url is proccessed or it fails, error can container size,
     *                            which means the size is not allowed
     * @return {Promise<object>} Resolved with new attachment object
     */
    getAttachmentFromUrl(
        url,
        {
            onStart,
            onSuccess,
            onFail,
        }) {
        // create a temp id
        const _id = uuid.v4();
        // get the deferred, jack.
        const deferred = this.$q.defer();
        // Trigger start callback
        onStart({
            _id,
            title: url,
        });
        this.getAttachmentByUrl(url).then(attachment => {
                // return
                return attachment;
            }).then(meta => {
                // call success callback
                onSuccess({
                    _id,
                    meta,
                });
                // resolve
                deferred.resolve(meta);
            }, (err) => {
                // reject
                deferred.reject(err);
            }).catch(err => {
                // onfail
                onFail({ _id });
                // reject
                deferred.reject(err);
            });
        // return the promise
        return deferred.promise;
    }

    /**
     * check that the file is an accepted type to upload
     * @param  {object} file
     * @param  {string|array} allowedFileTypes Single type or array if types to allow uploaded, defined by us
     * @return {boolean}
     */
    validFileType(file, allowedFileTypes) {
        const mimetype = file.type;
        const extension = this.$pfFilestackConvert.getExtension(file.name);
        const acceptedTypes = this.$pfFilestackOptions.getAcceptedTypes(allowedFileTypes);

        if (acceptedTypes.indexOf(extension) >= 0) {
            return true;
        }
        if (acceptedTypes.indexOf(mimetype) >= 0) {
            return true;
        }
        if (this.$pfFilestackConvert.mimetypeBelongsToType(mimetype, this.PF_ENTRY_ATTACHMENTS_TYPES.IMAGE.name)) {
            return true;
        }
        return false;
    }

    /**
     * Checks that a numbers of bytes are valid to upload into the draft
     * @param  {Number}  sizeInBytes
     * @return {Boolean}
     */
    isValidFilesize(sizeInBytes = 0) {
        const sizeInMb = _.parseInt(sizeInBytes) / 1024 / 1024;

        return sizeInMb <= 100;
    }

    /**
     * get the preview photo for current attachment
     * @param {object} attachment
     * @return {object|null}
     */
    getAttachmentPreview(attachment = {}) {
        switch (attachment.type) {
            case this.PF_ENTRY_ATTACHMENTS_TYPES.IMAGE.name:
                // should always be null or an image object
                return attachment.meta.image;
            case this.PF_ENTRY_ATTACHMENTS_TYPES.DOCUMENT.name:
            case this.PF_ENTRY_ATTACHMENTS_TYPES.WEBSITE.name:
                // should always be null or an image object
                return attachment.meta.preview;
            default:
                return null;
        }
    }

    /**
     * Check if current attachment preview image set as cover photo
     *     for project draft
     * @param {object} attachment
     * @param {object} cover project cover photo
     * @return {boolean}
     */
    isPreviewPhotoCurrentCover(attachment, cover) {
        if (!cover) {
            return false;
        }
        if (!cover.file) {
            return false;
        }
        const preview = this.getAttachmentPreview(attachment);
        if (!preview) {
            return false;
        }
        if (!preview.file) {
            return false;
        }
        return (cover.file.filestack.key === preview.file.filestack.key);
    }

    /**
     * Make API call to fetch and parse meta tags for supplied URL
     * @param  {String}  url Source URL
     * @return {Promise}
     */
    getAttachmentByUrl(url) {
        // call the chester nugget here
        return this.$http.get('/proxy/tools/chester', {
            params: {
                url,
            },
        })
        .then(response => response.data);
    }
}

EntryAddAttachmentService.$inject = [
    '$http',
    '$pfEntryAttachment',
    '$pfFilestackConvert',
    '$pfFilestackOptions',
    '$pfFilestackPreset',
    '$pfFilestackSigner',
    '$pfToast',
    '$q',
    'PF_ENTRY_ATTACHMENTS_TYPES',
];
