import angular from 'angular';

import {DropZoneComponent} from './drop-zone.component';

const MODULE_NAME = 'portfolium.components.dropZone';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfDropZone', DropZoneComponent)
}());

export default MODULE_NAME;
