import angular from 'angular';

/**
 * @ngdoc service
 * @name SitewideSearchMobilePanelService
 * @module portfolium.component.pfSitewideSearch
 * @description Provides a mobile panel for sitewide search
 */
export class SitewideSearchMobilePanelService {
    constructor($mdPanel, $mdDialog, $timeout, $q) {
        this.$mdPanel = $mdPanel;
        this.$mdDialog = $mdDialog;
        this.$timeout = $timeout;
        this.$q = $q;
    }

    /**
     * Create a new panel instance
     * @return {MdPanelRef}
     */
    _createPanel() {
        const windowHeight = angular.element(window).height();
        const windowWidth = angular.element(window).width();
        const animationPosition = {
            top: windowHeight,
            left: 0,
        };
        const position = this.$mdPanel.newPanelPosition()
            .absolute()
            .bottom(0)
            .right(0);
        const animation = this.$mdPanel.newPanelAnimation()
            .openFrom(animationPosition)
            .closeTo(animationPosition)
            .withAnimation(this.$mdPanel.animation.SLIDE);
        // TEMPLATE: layout="row" and layout-fill used to fix firefox scrolling issue
        const config = {
            controller: angular.noop,
            controllerAs: '$ctrl',
            template: `
                <pf-sitewide-search-mobile
                     md-panel-ref="$ctrl.mdPanelRef"
                     layout="row"
                     layout-fill
                     flex>
                </pf-sitewide-search-mobile>
            `,
            panelClass: 'pf-sitewide-search-mobile-panel',
            attachTo: angular.element(document.body),
            position: position,
            animation: animation,
            trapFocus: true,
            escapeToClose: true,
            focusOnOpen: false,
            onDomAdded: (response) => {
                this.$timeout(() => {
                    // find the search input
                    const input = response[1].panelEl.find('input');
                    // focus the search input
                    input.focus();
                    // keyboard will push content up so scroll to the top of the panel
                    document.body.scrollTop = 0;
                    // delay smooths transition between keyboard and panel appearing together
                }, 300);
            },
            onDomRemoved: () => {
                // Reset panel reference
                this.panel = undefined;
                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
        };
        const panel = this.$mdPanel.create(config);

        return panel;
    }

    /**
     * Open the notification center panel
     * @return {Promise<MdPanelRef>}
     */
    open() {
        // If panel is already open, close and destroy it, intended for mobile mode use
        if (this.panel) {
            return this.panel.close().then(()=>{
                this.panel = undefined;
            });
        }

        // Set panel reference
        this.panel = this._createPanel();

        // Hide any dialogs if they are open
        return this.$mdDialog.hide().then(() => {
            // Open the panel
            return this.panel.open();
        });
    }

    /**
     * close panel
     * @return {Promise}
     */
    close() {
        if (!this.panel) {
            return this.$q.reject();
        }
        return this.panel.close();
    }
}

SitewideSearchMobilePanelService.$inject = [
    '$mdPanel',
    '$mdDialog',
    '$timeout',
    '$q',
];
