/**
 * @ngdoc directive
 * @name urlValidationDirective
 * @module portfolium.component.formValidation
 * @restrict A
 * @description
 * Validates a URL input field
 */

// slightly different than api; added '.*' to the end to match params to the end of url
const URL_REGEX = /^(http|https|ftp):\/\/([A-Z0-9][A-Z0-9_-]*(?:\.[A-Z0-9][A-Z0-9_-]*)+):?(\d+)?\/?.*$/i;
const PROTO_REGEX = /^(http|https|ftp):\/\//i;

export const urlValidationDirective = () => {
    return {
        restrict: 'A',
        scope: {
            strictValidation: '<pfUrlValidate',
        },
        require: '?ngModel',
        link: function (scope, elem, attrs, ngModel) {
            if (ngModel && ngModel.$validators.url) {

                // overwrite the built-in angular url validator
                ngModel.$validators.url = (modelVal, viewVal) => {

                    if (scope.strictValidation && !PROTO_REGEX.test(viewVal)) {
                        // if strictValidation, only validate urls if they begin with PROTO_REGEX
                        return true;
                    }

                    if (ngModel.$isEmpty(modelVal)) {
                        // consider empty models to be valid
                        return true;
                    }

                    if (URL_REGEX.test(viewVal)) {
                        // valid!
                        return true;
                    }

                    // invalid
                    return false;
                };
            }
        },
    };
};
