import angular from 'angular';

import { EntryViewerBadgesComponent } from './entry-viewer-badges/entry-viewer-badges.component';
import { EntryViewerComponent } from './entry-viewer.component';
import { EntryViewerCommentComponent } from './entry-viewer-comment/entry-viewer-comment.component';
import { EntryViewerCommentListComponent } from './entry-viewer-comment-list/entry-viewer-comment-list.component';
import { EntryViewerCommentsToolbarComponent } from './entry-viewer-comments-toolbar/entry-viewer-comments-toolbar.component';
import { EntryViewerConstructionHeaderComponent } from './entry-viewer-construction-header/entry-viewer-construction-header.component';
import { EntryViewerContestsComponent } from './entry-viewer-contests/entry-viewer-contests.component';
import { EntryViewerCountersComponent } from './entry-viewer-counters/entry-viewer-counters.component';
import { EntryViewerDescriptionComponent } from './entry-viewer-description/entry-viewer-description.component';
import { EntryViewerFooterComponent } from './entry-viewer-footer/entry-viewer-footer.component';
import { EntryViewerHeaderComponent } from './entry-viewer-header/entry-viewer-header.component';
import { EntryViewerHeaderMobileComponent } from './entry-viewer-header-mobile/entry-viewer-header-mobile.component';
import { EntryViewerLikeButtonComponent } from './entry-viewer-like-button/entry-viewer-like-button.component';
import { EntryViewerLikersComponent } from './entry-viewer-likers/entry-viewer-likers.component';
import { EntryViewerPanelService } from './entry-viewer-panel.service';
import { EntryViewerProfileComponent } from './entry-viewer-profile/entry-viewer-profile.component';
import { EntryViewerSkillsComponent } from './entry-viewer-skills/entry-viewer-skills.component';
import { EntryViewerTagsComponent } from './entry-viewer-tags/entry-viewer-tags.component';
import { EntryViewerTeammatesComponent } from './entry-viewer-teammates/entry-viewer-teammates.component';

const MODULE_NAME = 'portfolium.component.entryViewer';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfEntryViewer', EntryViewerComponent)
        .component('pfEntryViewerBadges', EntryViewerBadgesComponent)
        .component('pfEntryViewerComment', EntryViewerCommentComponent)
        .component('pfEntryViewerCommentList', EntryViewerCommentListComponent)
        .component('pfEntryViewerCommentsToolbar', EntryViewerCommentsToolbarComponent)
        .component('pfEntryViewerConstructionHeader', EntryViewerConstructionHeaderComponent)
        .component('pfEntryViewerContests', EntryViewerContestsComponent)
        .component('pfEntryViewerCounters', EntryViewerCountersComponent)
        .component('pfEntryViewerDescription', EntryViewerDescriptionComponent)
        .component('pfEntryViewerFooter', EntryViewerFooterComponent)
        .component('pfEntryViewerHeader', EntryViewerHeaderComponent)
        .component('pfEntryViewerHeaderMobile', EntryViewerHeaderMobileComponent)
        .component('pfEntryViewerLikeButton', EntryViewerLikeButtonComponent)
        .component('pfEntryViewerLikers', EntryViewerLikersComponent)
        .component('pfEntryViewerProfile', EntryViewerProfileComponent)
        .component('pfEntryViewerSkills', EntryViewerSkillsComponent)
        .component('pfEntryViewerTags', EntryViewerTagsComponent)
        .component('pfEntryViewerTeammates', EntryViewerTeammatesComponent)
        // Services
        .service('$pfEntryViewerPanel', EntryViewerPanelService);
}());

export default MODULE_NAME;
