import EmptyResultsTemplate from './empty-results.component.html';
import './empty-results.component.scss';

 /**
  * @ngdoc component
  * @name pfEmptyResults
  * @module portfolium.component.emptyResults
  * @description
  * Displays a generic empty result
  * @param {String} icon
  * @param {String} pfTitle
  * @param {String} subTitle
  * @param {String} actionTitle
  * @param {String} onActionTitle
  * @param {String} actionSubTitle
  * @param {String} onActionSubTitle
  *
  */
export const EmptyResultsComponent = {
    bindings: {
        icon: '<',
        title: '<pfTitle',
        subTitle: '<',
        actionTitle: '<',
        onActionTitle: '&',
        actionSubTitle: '<',
        onActionSubTitle: '&',
    },
    template: EmptyResultsTemplate,
    controllerAs: '$ctrl',
    bindToController: true,
};
