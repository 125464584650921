import angular from 'angular';

/**
 * @module portfolium.core.services.filestack
 * @name PfDropzoneRef
 * @description Class to keep an instance of the dropzone created
 *              so it can be removed at any time.
 *              Note: Make sure to always call the destroy method and
 *                  delete the instance to aoid memory leaks.
 */
class PfDropzoneRef {
    constructor(scope, targetElement, dropZoneTemplate) {
        this.scope = scope;
        this.dropZoneTemplate = dropZoneTemplate;
        this.targetElement = targetElement;
    }

    /**
     * Removes the drag and drop zone from the past element
     * @param  {element} $element angular $element which drop zone is
     *                        attached
     */
    destroy() {
        if (this.scope) {
            this.scope.$destroy();
        }

        this.dropZoneTemplate.remove();
    }

    /**
     * Enable the drop zone for current reference
     */
    enable() {
        this.scope.$apply(() => {
            // remove this class to allow user upload a file
            this.targetElement.removeClass('disable-drag');
        });
    }

    /**
     * Disable the drop zone for current reference
     */
    disable() {
        this.scope.$apply(() => {
            // remove this class to allow user upload a file
            this.targetElement.addClass('disable-drag');
        });
    }
}

/**
 * @ngdoc service
 * @module portfolium.core.services.filestack
 * @name $pfDropZone
 * @description Service to create and handle drag and drop zone
 * Filestack API.
 */
export class DropZoneService {
    constructor($q, $compile, $rootScope) {
        this.$q = $q;
        this.$compile = $compile;
        this.$rootScope = $rootScope;
    }

    /**
     * Creates and handle drag and drop zone onto a pass element
     * @param  {element}  $element                angular $element which drop zone is going
     *                                            go be attached
     * @param  {Boolean}  options.multipleFiles   allow multiple files?
     * @param  {Boolean}  options.fullScreenMode  allow fullscreen?
     * @param  {Function} options.preFlight       callback before files are processed
     * @param  {Function} options.onDrop          callback on file/s drop
     */
    init(
        $element,
        {
            multipleFiles = true,
            fullScreenMode = true,
            preFlight = angular.noop,
            onDrop = angular.noop,
        } = {},
    ) {
        const scope = this.$rootScope.$new(false);

        scope.preFlight = (files) => {
            return preFlight(files);
        };

        scope.onDrop = (file) => {
            onDrop(file);
        };

        scope.multipleFiles = multipleFiles;
        scope.fullScreenMode = fullScreenMode;
        scope.targetElement = $element;

        const dropZoneTemplate = angular.element(`
            <pf-drop-zone
                 pre-flight="preFlight(files)"
                 on-drop="onDrop(file)"
                 target-element="targetElement"
                 multiple-files="multipleFiles"
                 full-screen-mode="fullScreenMode">
            </pf-drop-zone>
        `);

        $element.append(this.$compile(dropZoneTemplate)(scope));

        const dropZoneRef = new PfDropzoneRef(scope, $element, dropZoneTemplate);

        return this.$q.resolve(dropZoneRef);
    }
}

DropZoneService.$inject = [
    '$q',
    '$compile',
    '$rootScope',
];
