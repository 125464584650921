import { EntryDetailsController } from './entry-details.controller';
import { resetEntry } from './entry-details.actions';

/**
 * @ngdoc service
 * @module portfolium.entryDetails
 * @name EntryDetailsPanelService
 * @description Service for the Entry panel, it is a
 *              wrapper for $pfEntryViewer
 **/
export class EntryDetailsPanelService {
    constructor($pfEntryViewerPanel, $pfEntryPreviousState, $ngRedux) {
        // Bind services
        this.$pfEntryViewerPanel = $pfEntryViewerPanel;
        this.$pfEntryPreviousState = $pfEntryPreviousState;
        this.$ngRedux = $ngRedux;
    }

    /**
     * is the panel open?
     * @return {Boolean}
     */
    get isOpen() {
        return !!this.panel;
    }

    /**
     * [createPanel description]
     * @param  {string} slug    Current entry slug
     * @param  {String} ad      Entry privacy bypass token
     * @param  {String} version Entry version
     * @return {Promise}
     */
    createPanel(slug, ad, version) {
        // Set the body class for the EDP when on top of PHP pages
        const hasClass = angular.element('body').hasClass('pf-app--edp');
        if (!hasClass) {
            angular.element('body').addClass('pf-app--edp');
        }

        return this.$pfEntryViewerPanel.openPreview(
            {
                controller: EntryDetailsController,
                locals: {
                    slug,
                    ad,
                    version,
                },
            },
            {
                onPanelOpen: () => {
                    this.$pfEntryPreviousState.onOpen();
                },
                onPanelClose: reason => {
                    // set initial redux data
                    this.$ngRedux.dispatch(resetEntry());
                    this.panel = undefined;
                    this.$pfEntryPreviousState.onClose(reason);
                },
            },
        );
    }

    /**
     * open panel
     * @param  {String} entry   Entry slug
     * @param  {String} ad      Entry privacy bypass token
     * @param  {String} version Entry version
     * @return {Promise}
     */
    open(slug, ad, version) {
        this.createPanel(slug, ad, version).then(previewPanel => {
            this.panel = previewPanel;
        });
    }

    /**
     * close panel
     * @return {Promise}
     */
    close() {
        if (_.isEmpty(this.panel)) {
            return;
        }
        this.panel.close('forceClose');
    }
}

EntryDetailsPanelService.$inject = [
    '$pfEntryViewerPanel',
    '$pfEntryPreviousState',
    '$ngRedux',
];
