import ErrorCenteredTemplate from '../template/error-centered.html';
import ErrorFullWidthTemplate from '../template/error-full-width.html';

function Routes($stateProvider) {
    // Define error states
    $stateProvider
        .state('403', {
            parent: 'layout.standard',
            url: '/access-denied',
            views: {
                'app@layout.standard': {
                    template: ErrorCenteredTemplate,
                    controller: ['$scope', function ($scope) {
                        $scope.headline = 'Access denied, no humans allowed!';
                        $scope.message = `The page you were looking for is private, and you don't have access to view it.`;
                        $scope.image = 'ufo.svg';
                        $scope.private = false;
                    }]
                }
            },
            onEnter: ['$pfLayout', ($pfLayout) => {
                    // Set page title
                    $pfLayout.title = 'Access denied';
            }],
        })
        .state('404', {
            parent: 'layout.standard',
            views: {
                'app@layout.standard': {
                    template: ErrorCenteredTemplate,
                    controller: ['$scope', function ($scope) {
                        $scope.headline = 'Houston, we have a 404.';
                        $scope.message = `The page you were looking for doesn't exist. You may have mistyped or it may have been moved.`;
                        $scope.image = 'spaceship.svg';
                        $scope.private = false;
                    }]
                }
            },
            onEnter: ['$pfLayout', ($pfLayout) => {
                    // Set page title
                    $pfLayout.title = 'Page not found';
            }],
        })
        .state('coppaError', {
            parent: 'layout.standard',
            url: '/age-deny',
            views: {
                'app@layout.standard': {
                    template: ErrorFullWidthTemplate,
                    controller: ['$scope', function ($scope) {
                        $scope.headline = 'Uh-Oh!';
                        $scope.subHeadline = `You can't create a Portfolium account`;
                        $scope.message = `You must meet the minimum age requirement to create a Portfolium account.`;
                        $scope.ctaText = 'Learn more';
                        $scope.ctaLink = 'https://community.canvaslms.com/docs/DOC-17859';
                    }],
                }
            },
            onEnter: ['$pfLayout', ($pfLayout) => {
                    // Set page title
                    $pfLayout.title = 'Age restriction';
            }],
        })
        .state('private', {
            parent: 'layout.standard',
            params: {
                username: ''
            },
            views: {
                'app@layout.standard': {
                    template: ErrorCenteredTemplate,
                    controller: ['$scope', '$window', 'profile', '$pfUser', function ($scope, $window, profile, $pfUser) {
                        $scope.profile = profile;
                        $scope.authed = $pfUser.getUser() ? true : false;
                        $scope.headline = 'Access denied, no humans allowed!';
                        $scope.private = true;
                        $scope.message = 'This profile is private. You must be connected with them to view.';
                        $scope.image = 'ufo.svg';

                        $scope.reload = function () {
                            $window.location.reload();
                        };
                    }]
                },
            },
            resolve: {
                profile: ['$pfUserConnect', '$stateParams', '$pfUser',function ($pfUserConnect, $stateParams, $pfUser) {
                    let {username} = $stateParams;
                    return $pfUserConnect.getProfileByUsername(username);
                }],
            },
        })
        .state('blocked', {
            parent: 'layout.standard',
            params: {
                username: '',
                blockedUser: null,
                blockedDate: null,
            },
            views: {
                'app@layout.standard': {
                    template: `
                        <pf-blocked-user
                             pf-blocked-user="::$resolve.blockedUser"
                             pf-blocked-date="{{ ::$resolve.blockedDate }}">
                        </pf-blocked-user>
                    `,
                },
            },
            onEnter: ['$pfLayout', ($pfLayout) => {
                // Set page title
                $pfLayout.title = 'Profile Blocked';
            }],
            resolve: {
                name: ['$stateParams', function ($stateParams) {
                    const { userId } = $stateParams;
                    if (_.isNil(userId)) {
                        return null;
                    }
                    return userId;
                }],
                blockedUser: ['$stateParams', function ($stateParams) {
                    const { blockedUser } = $stateParams;
                    if (_.isNil(blockedUser)) {
                        return null;
                    }
                    return blockedUser;
                }],
                blockedDate: ['$stateParams', function ($stateParams) {
                    const { blockedDate } = $stateParams;
                    if (_.isNil(blockedDate)) {
                        return null;
                    }
                    return blockedDate;
                }],
            },
        });
}

Routes.$inject = ['$stateProvider'];

export default Routes;
