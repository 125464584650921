import ClipboardTemplate from './clipboard.html';

/**
 * @ngdoc component
 * @name pfClipboard
 * @module portfolium.component.clipboard
 * @description
 * On click copy content to the clipboard.
 * @param {String} [pf-copy-text] text to copy on click
 *
 */
class ClipboardController {
    constructor($element, $pfToast, $window, $document) {
        this.$element = $element;
        this.$pfToast = $pfToast;
        this.$window = $window;
        this.$document = $document;
    }

    // iOS Safari detection
    // https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari/29696509#29696509
    isIOS() {
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        return iOS && webkit && !ua.match(/CriOS/i);
    }

    createNode(text, context) {
        let node = this.$document[0].createElement('textarea');
        node.style.position = 'absolute';
        node.contentEditable = true;
        node.textContent = text;
        node.readOnly = false;
        node.style.left = '-10000px';
        node.style.top =
            (this.$window.pageYOffset ||
                this.$document[0].documentElement.scrollTop) + 'px';
        return node;
    }

    copyNode(node) {
        // Set inline style to override css styles
        this.$document[0].body.style.webkitUserSelect = 'initial';
        let selection = null;
        if (this.isIOS()) {
            const range = document.createRange();
            range.selectNodeContents(node);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            node.setSelectionRange(0, 99999);
        } else {
            selection = this.$document[0].getSelection();
            selection.removeAllRanges();
            node.select();
        }

        if (this.$document[0].execCommand('copy')) {
            selection.removeAllRanges();
            this.$pfToast.success('Copied to Clipboard!');
        }
        // Reset inline style
        this.$document[0].body.style.webkitUserSelect = '';
    }

    copyText(text, context) {
        let node = this.createNode(text, context);
        this.$document[0].body.appendChild(node);
        this.copyNode(node);
        this.$document[0].body.removeChild(node);
    }

    copyToClipboard(element) {
        this.copyText(this.pfTextToCopy, element[0]);
    }
}

ClipboardController.$inject = ['$element', '$pfToast', '$window', '$document'];

export const ClipboardComponent = {
    bindings: {
        pfTextToCopy: '<'
    },
    template: ClipboardTemplate,
    controller: ClipboardController,
    controllerAs: '$ctrl',
    bindToController: true
};
