export const themes = ($mdThemingProvider) => {
    /**
     * From the Angular Material docs (https://material.angularjs.org/latest/#/Theming/03_configuring_a_theme):
     *
     * "By default, shades 500, 300 800 and A100 are used for primary and warn intentions,
     * while 400, 300, 800 and A100 are used for accent."
     *
     * See /client/main/sass/base/_variables.scss for the rest of the palette values
     *
     * @todo: update these palettes will all values, whenever needed. May need to tweak some
     * of the lighter/darker values
     */
    let pfBlueMap = $mdThemingProvider.extendPalette('blue', {
        '300': '33b3ff', // $color-electric-300
        '500': '008ee2' // $color-electric-500
    });

    // Define custom palettes
    $mdThemingProvider.definePalette('pfBlue', pfBlueMap);

    // Define custom portfolium theme
    $mdThemingProvider.theme('portfolium')
        .primaryPalette('pfBlue');

    // Define toast themes
    $mdThemingProvider.theme('success-toast');
    $mdThemingProvider.theme('error-toast');

    // Set portfolium theme as default
    $mdThemingProvider.setDefaultTheme('portfolium');
};

themes.$inject = ['$mdThemingProvider'];
