export const UPDATE_DRAFT_COUNT = 'UPDATE_DRAFT_COUNT';
export const DECREMENT_DRAFT_COUNT  = 'DECREMENT_DRAFT_COUNT';

export const updateDraftCount = (draftCount) => {
    return {
        type: UPDATE_DRAFT_COUNT,
        payload: {
            draftCount
        },
    };
};

export const decrementDraftCount = () => {
    return {
        type: DECREMENT_DRAFT_COUNT,
    };
};

export const profileDraftsPaginationActions = {
    'PAGE_INIT': 'PROFILE_DRAFTS_PAGE_INIT',
    'PAGE_NEXT': 'PROFILE_DRAFTS_PAGE_NEXT',
    'PAGE_PREVIOUS': 'PROFILE_DRAFTS_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'PROFILE_DRAFTS_PAGE_REQUEST',
    'PAGE_SUCCESS': 'PROFILE_DRAFTS_PAGE_SUCCESS',
    'PAGE_ERROR': 'PROFILE_DRAFTS_PAGE_ERROR',
    'TOGGLE_SELECTION': 'PROFILE_DRAFTS_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'PROFILE_DRAFTS_CLEAR_SELECTION',
    'TOGGLE_ALL': 'PROFILE_DRAFTS_TOGGLE_ALL',
    'ADD_FILTER': 'PROFILE_DRAFTS_ADD_FILTER',
    'REMOVE_FILTER': 'PROFILE_DRAFTS_REMOVE_FILTER',
    'UPDATE_SINGLE_RESULT': 'PROFILE_DRAFTS_UPDATE_SINGLE_RESULT',
    'REMOVE_SINGLE_RESULT': 'PROFILE_DRAFTS_REMOVE_SINGLE_RESULT',
};
