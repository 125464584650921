export const jobMatchesTypes = {
    'PAGE_INIT': 'JOB_MATCHES_PAGE_INIT',
    'PAGE_NEXT': 'JOB_MATCHES_PAGE_NEXT',
    'PAGE_PREVIOUS': 'JOB_MATCHES_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'JOB_MATCHES_PAGE_REQUEST',
    'PAGE_SUCCESS': 'JOB_MATCHES_PAGE_SUCCESS',
    'PAGE_ERROR': 'JOB_MATCHES_PAGE_ERROR',
    'TOGGLE_SELECTION': 'JOB_MATCHES_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'JOB_MATCHES_CLEAR_SELECTION',
    'TOGGLE_ALL': 'JOB_MATCHES_TOGGLE_ALL',
};
