const pinUrl = 'https://www.pinterest.com/pin/create/button';
const portfoliumUrl = 'https://portfolium.com';

class SharePinterest
{
    constructor($window) {
        this.$window = $window;
    }

    shareData(data, type) {
        let description = `${data.title} - By ${data.author}`;
        let popupUrl = `${pinUrl}?url=${data.url}&media=${data.image}&description=${description}`;

        if (type === 'company') {
            description = `${data.name}`;
            popupUrl = `${pinUrl}?url=${portfoliumUrl}/company/${data.slug}&media=${data.logo.url_https}&description=${description}`;
        }

        if (type === 'job') {
            description = `${data.title} - ${data.company.name}`;
            popupUrl = `${pinUrl}?url=${portfoliumUrl}/job/${data.slug}&media=${data.company.logo.url_https}&description=${description}`;
        }

        if (type === 'network') {
            description = `${data.name} - ${data.network.name}`;
            popupUrl = `${pinUrl}?url=${portfoliumUrl}/network/${data.slug}&media=${data.logo.url_https}&description=${description}`;
        }

        return this.$window.open(popupUrl, '', 'resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0,width=750,height=320');
    }
}

export default SharePinterest;
