class SignInAuthService
{
    constructor(
        $http,
        $window,
        $pfAuth,
        $pfSignInIdentity,
        $pfSignUp,
        $pfToast,
        $pfEventTracker,
        PF_ACCOUNT_TYPES
    ) {
        this.$http = $http;
        this.$window = $window;
        this.$pfAuth = $pfAuth;
        this.$pfSignInIdentity = $pfSignInIdentity;
        this.$pfSignUp = $pfSignUp;
        this.$pfToast = $pfToast;
        this.$pfEventTracker = $pfEventTracker;
        this.PF_ACCOUNT_TYPES = PF_ACCOUNT_TYPES;
    }

    /**
     * Create a new user account with social data
     * @param  {Boolean} [possibleDuplicate] Is this a possible duplicate account?
     * @return {Promise}
     */
    _createAccountSocial(possibleDuplicate = false) {
        const socialData = this.$pfSignInIdentity.social;
        const networkId = this.$pfSignInIdentity.network.id;
        const schoolName = this.$pfSignInIdentity.school;

        return this.$pfAuth.socialLogin(socialData.client, socialData.token, {
            // Make sure flag is set to create new account
            create: true,
            // Auto request to join the network the user selected
            id: this.$pfSignInIdentity.network.id,
            type: 'network',
            school: schoolName,
            network_id: networkId,
            id_token: socialData.id_token
        }).then((user) => {
            if (possibleDuplicate) {
                // Flag as possible dupe account
                this.createDupeUser(user.id, networkId);
            }
            // Redirect to the set URL
            return this.$pfAuth.redirect();
        }, () => {
            this.$pfToast.error('Something went wrong, please try again.');
        });
    }

    /**
     * Create a new user account with an email address
     * @param  {String}  email               New user's email address
     * @param  {String=null}  recaptchaToken  Recaptcha token required if 4+ failed login attempts
     * @param  {Boolean} [possibleDuplicate] Is this a possible duplicate account?
     * @param  {String}  userType            User type
     * @return {Promise}
     */
    _createAccountEmail(email, possibleDuplicate = false, userType = this.PF_ACCOUNT_TYPES.unknown.name, recaptchaToken) {
        const networkId = this.$pfSignInIdentity.network.id;
        const schoolName = this.$pfSignInIdentity.school;

        return this.$pfSignUp.signUp({
            email: email,
            // Auto request to join the network the user selected
            network_id: networkId,
            school: schoolName,
            type: userType,
            recaptcha: recaptchaToken,
        }).then((user) => {
            if (possibleDuplicate) {
                // Flag as possible dupe account
                this.createDupeUser(user.id, networkId);
            }
            // Redirect to the set URL
            return this.$pfAuth.redirect();
        }, (response) => {
            if (response.status === 400) {
                this.$pfToast.error(response.data.error);
            } else {
                this.$pfToast.error('Something went wrong, please try again.');
            }
        });

    }

    /**
     * Create a new user account
     * @param {String} email                 New user's email address
     * @param {Boolean} [possibleDuplicate]  Is this a possible duplicate account?
     * @param {String} userType              The type of account
     * @param {String=null} recaptchaToken   Recaptcha token required if 4+ failed login attempts
     * @return {Promise}
     */
    createAccount(email, possibleDuplicate = false, userType = this.PF_ACCOUNT_TYPES.unknown.name, recaptchaToken) {
        if (this.$pfSignInIdentity.hasSocialData) {
            // User authenticated with a social service, register via social auth
            return this._createAccountSocial(possibleDuplicate, userType);
        }

        // Register via email
        return this._createAccountEmail(email, possibleDuplicate, userType, recaptchaToken);
    }

    /**
     * Flag a users account as a possible dupe account
     * @param  {int} userId The user id
     * @param  {int} networkId The network id
     * @return {Promise}
     */
    createDupeUser(userId, networkId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/users/merge_queue/${userId}`,
            data: {
                network_id: networkId
            }
        });
    }
}

SignInAuthService.$inject = [
    '$http',
    '$window',
    '$pfAuth',
    '$pfSignInIdentity',
    '$pfSignUp',
    '$pfToast',
    '$pfEventTracker',
    'PF_ACCOUNT_TYPES',
];

export default SignInAuthService;
