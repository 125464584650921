function i18nDirective($log) {
    let $zab = window.ZAB;

    return {
        restrict: 'A',
        link(scope, element, attrs) {},

        compile(element, attr) {
            return {
                pre($scope, element, attr) {
                    if ($zab.debug.enabled) {
                        element.attr('style', $zab.debug.styles.FOUND);
                    }
                },

                post($scope, element, attr) {
                    let translated;
                    let transText = element.text();

                    try {
                        translated = $zab.get(element.text());
                        transText = translated.text;
                        if ($zab.debug.enabled) {
                            element.attr('style', $zab.debug.styles[translated.code]);
                        }
                    } catch (e) {
                        $log.error(e);
                        $log.error('Could not translate: ' + element.text());
                    }

                    element.text(transText);
                }

            };
        }
    };
}
i18nDirective.$inject = ['$log'];

export { i18nDirective };
