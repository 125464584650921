function envUrlFilter($pfEnv) {
    /**
     * Prepend a relative URI with the Portfolium environment URL
     * @param  {String} uri Relative URI path
     * @return {String}     Full URL for current environment
     */
    return function (uri) {
        return $pfEnv.url + uri;
    };
}

envUrlFilter.$inject = ['$pfEnv'];

export default envUrlFilter;
