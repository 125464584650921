import angular from 'angular';
import {EntryAuditService} from './entry-audit.service';

const MODULE_NAME = 'portfolium.component.entry-audit';

(function () {
    angular.module(MODULE_NAME, [])
        // Services
        .service('$pfEntryAudit', EntryAuditService);
}());

export default MODULE_NAME;
