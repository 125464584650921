import angular from 'angular';
import boolFilter from './js/filter/bool-filter';
import { emptyToNullDirective } from './js/directive/empty-to-null.directive';
import { inputFocusDirective } from './js/directive/input-focus.directive';
import {
    momentDiffFilter,
    momentDateFilter,
    momentDatetimeFilter,
    momentDateNewFilter,
    momentTimeAgoFilter,
    momentUnixTimeFilter,
    momentUtcDateLocalTimeFilter,
} from './js/filter/moment-filters';
import { fullNameFilter, possessiveNameFilter } from './js/filter/name-filters';
import {
    megaNumberFilter,
    ordinalNumberFilter,
} from './js/filter/number-filters';
import {
    descriptionToHtmlFilter,
    truncateStringFilter,
} from './js/filter/string-format-filters';
import { ScrollService } from './js/service/scroll.service';
import { i18nDirective } from './js/directive/i18n-directive';
import { i18nFilter } from './js/filter/i18n-filter';

import inlineTemplateDirective from './js/directive/inline-template-directive';
import stopPropagationDirective from './js/directive/stop-propagation-directive';

import envUrlFilter from './js/filter/env-url-filter';
import highlightMatchFilter from './js/filter/highlight-match-filter';
import filesizeFilter from './js/filter/filesize-filter';
import socialLinkFilter from './js/filter/social-link-filter';
import UrlUtilsService from './js/service/url-utils.service';
import EnvironmentService from './js/service/environment-service';
import StringToLinkService from './js/service/string-to-link.service';
import ClassYearService from './js/service/class-year.service';

export const MODULE_NAME = 'portfolium.component.pfUtils';

(function() {
    angular
        .module(MODULE_NAME, [])
        // Directives
        .directive('pfEmptyToNull', emptyToNullDirective)
        .directive('pfInputFocus', inputFocusDirective)
        .directive('pfInlineTemplate', inlineTemplateDirective)
        .directive('pfStopPropagation', stopPropagationDirective)
        .directive('i18n', i18nDirective)
        // Filters
        .filter('bool', boolFilter)
        .filter('momentDiff', momentDiffFilter)
        .filter('momentDate', momentDateFilter)
        .filter('momentDatetime', momentDatetimeFilter)
        .filter('momentDateNew', momentDateNewFilter)
        .filter('momentTimeAgo', momentTimeAgoFilter)
        .filter('momentUnixTime', momentUnixTimeFilter)
        .filter('momentUtcDateLocalTime', momentUtcDateLocalTimeFilter)
        .filter('fullName', fullNameFilter)
        .filter('possessiveName', possessiveNameFilter)
        .filter('megaNumber', megaNumberFilter)
        .filter('ordinalNumber', ordinalNumberFilter)
        .filter('descriptionToHtml', descriptionToHtmlFilter)
        .filter('truncateString', truncateStringFilter)
        .filter('envUrl', envUrlFilter)
        .filter('highlightMatch', highlightMatchFilter)
        .filter('filesize', filesizeFilter)
        .filter('socialLink', socialLinkFilter)
        .filter('i18n', i18nFilter)
        // Service
        .service('$pfUrlUtils', UrlUtilsService)
        .service('$pfEnv', EnvironmentService)
        .service('$pfStringToLink', StringToLinkService)
        .service('$pfClassYear', ClassYearService)
        .service('$pfScroll', ScrollService);
})();

export default MODULE_NAME;
