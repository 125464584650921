import angular from 'angular';
import { MODULE_NAME } from 'components/pf-utils/index.js';
const $pfEnv = angular.injector(['ng', MODULE_NAME]).get('$pfEnv');

const projectEditorSources = [
    'imagesearch',
    'dropbox',
    'box',
    'onedrive',
];

const fromSources = [
    'local_file_system',
    'imagesearch',
    'dropbox',
    'box',
    'onedrive',
];

const resumeSources = [
    'local_file_system',
    'dropbox',
    'box',
    'onedrive',
];

const ugcS3PathPrefix = 'ugcs3/v3/';

const avatarDimensions = {
    height: 400,
    width: 400,
};

const coverDimensions = {
    height: 400,
    width: 1600,
};

const profileAttributeDimensions = {
    height: 320,
    width: 320,
};

const jobDimensions = {
    height: 320,
    width: 320,
};

const attachment = {
    storeOptions: {
        path: ugcS3PathPrefix + 'project_attachments/',
    },
    acceptedTypes: null,
    filestackOptions: {
        disableTransformer: true,
        fromSources: projectEditorSources,
        hideModalWhenUploading: true,
        imageMin: [400, 400],
        maxFiles: 1,
        startUploadingWhenMaxFilesReached: false,
        uploadInBackground: false,
    },
};

const attachmentCropImage = {
    storeOptions: {
        path: ugcS3PathPrefix + 'project_attachments/',
    },
    filestackOptions: {
        hideModalWhenUploading: false,
        transformations: {
            crop: true,
            circle: false,
            rotate: true,
        },
        uploadInBackground: true,
    },
}

const resume = {
    storeOptions: {
        path: 'resumes/',
        container: $pfEnv.getEnv('PF_S3_INTERNAL_BUCKET'),
    },
    acceptedTypes: 'resume',
    filestackOptions: {
        disableTransformer: true,
        fromSources: resumeSources,
        hideModalWhenUploading: true,
        imageMin: [400, 400],
        maxFiles: 1,
        startUploadingWhenMaxFilesReached: true,
        uploadInBackground: false,
        exposeOriginalFile: true,
    },
};

const imagePreset = (name, {width, height}) => {
    return {
        storeOptions: {
            path: ugcS3PathPrefix + name + '/',
        },
        acceptedTypes: 'image',
        filestackOptions: {
            disableTransformer: false,
            fromSources,
            hideModalWhenUploading: false,
            imageMin: [width, height],
            startUploadingWhenMaxFilesReached: false,
            uploadInBackground: false,
            transformations: {
                crop: {
                    aspectRatio: width / height,
                    force: true,
                },
            },
        },
    };
}

export const FILESTACK_PRESETS = {
    attachment,
    attachmentCropImage,
    avatar: imagePreset('avatar', avatarDimensions),
    companyLogo: imagePreset('company_logo', avatarDimensions),
    companyCover: imagePreset('company_cover', coverDimensions),
    cover: imagePreset('cover', coverDimensions),
    profileAttribute: imagePreset('profile_attributes', profileAttributeDimensions),
    job: imagePreset('job', jobDimensions),
    resume,
};
