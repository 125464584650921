
import angular from 'angular';

import { DialogHeaderComponent } from './dialog-header/dialog-header.component';

const MODULE_NAME = 'portfolium.component.dialogModule';

(function() {
    angular.module(MODULE_NAME, [])
        .component('pfDialogHeader', DialogHeaderComponent);
})();

export default MODULE_NAME;
