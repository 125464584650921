import EntryViewerProfileTemplate from './entry-viewer-profile.component.html';
import './entry-viewer-profile.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerProfileController
 **/
 class EntryViewerProfileController {
    constructor(
        $pfAuth,
        $pfSubscription,
        $pfUser,
        $pfEntryEditor,
        PF_USER_CARD_TEMPLATES,
        PF_POPOVER_STARTING_POSITIONS,
    ) {
        this.$pfAuth = $pfAuth;
        this.$pfSubscription = $pfSubscription;
        this.$pfUser = $pfUser;
        this.$pfEntryEditor = $pfEntryEditor;
        this.PF_USER_CARD_TEMPLATES = PF_USER_CARD_TEMPLATES;
        this.PF_POPOVER_STARTING_POSITIONS = PF_POPOVER_STARTING_POSITIONS;
    }

    /**
     * Is the viewer connected to the entry owner ?
     * @return {Boolean|undefined}
     */
    isUserConnected() {
        // sanity check for profile
        if (_.isEmpty(this.profile)) {
            return;
        }
        // get connected flag
        const {connected} = this.profile;
        // sanity check for connected flag
        if (_.isEmpty(connected)) {
            return;
        }
        // is user connected?
        return connected === '1';
    }

    /**
     * Is the viewer an employer from search
     * @return {Boolean}
     */
    isSubscriber() {
        return this.$pfSubscription.isSubscriber();
    }

    /**
     * Is the user on a mobile device?
     * @return {Boolean}
     */
    isMobile() {
        return this.$pfUser.isMobile();
    }

    /**
     * Is the user looking at their own profile?
     * @return {Boolean}
     */
    isOwner() {
        return _.isObject(this.profile) && this.$pfAuth.isAuthenticatedUser(this.profile.id);
    }

    /**
     * Get size of the icon depending if it is mobile or desktop
     * @return {string}
     */
    getIconSize() {
        if (this.isMobile()) {
            return '40';
        }

        return '56';
    }

    /**
     * Redirect to the entry wizard
     * @return {Promise}
     */
    editEntry() {
        const {username} = this.$pfUser.getUser();

        return this.$pfEntryEditor.editEntry(this.entryId, {
            redirectRoute: {
                stateName: 'profile.portfolio',
                params: { username },
            },
            source: 'entryDetailsPanel',
        });
    }
}

EntryViewerProfileController.$inject = [
    '$pfAuth',
    '$pfSubscription',
    '$pfUser',
    '$pfEntryEditor',
    'PF_USER_CARD_TEMPLATES',
    'PF_POPOVER_STARTING_POSITIONS',
];

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerProfileComponent
 * @description Display list of profile in the entry viewer sidebar
 *
 * @param {boolean} isPreview Is the user viewing a preview?
 * @param {number} entryId Id of current entry
 * @param {object[]} profile entry profile
 * @param {boolean} isEditable can be edited
 */
export const EntryViewerProfileComponent = {
    bindings: {
        'isLoading': '<',
        'isPreview': '<',
        'profile': '<',
        'entryId': '<',
        'isEditable': '<',
    },
    controller: EntryViewerProfileController,
    controllerAs: '$ctrl',
    template: EntryViewerProfileTemplate,
};
