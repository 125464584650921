class AddEntryService
{
    constructor($http, $q, $state) {
        this.$http = $http;
        this.$q = $q;
        this.$state = $state;
    }

    /**
     * Add a new entry
     * @return {Promise}
     */
    addEntry() {
        if (this.$state.get('entryEditor')) {
            // Already in single page app -- go to entry wizard state
            return this.$q.resolve().then(() => {
                /**
                 * Resolve promise immediately, then transition. This is because
                 * the initial $state.go transition is blocked by the lazy load
                 * hook, rejecting the promise. In order for the add entry modal
                 * to close properly, this promise must be resolved.
                 *
                 * TODO: Use the $transition$ service in UI-Router 1.0 to handle
                 * the lazy load hook without rejecting the initial $state.go
                 */
                this.$state.go('entryEditor', {mode: 'add', newEntry: true});
            });
        }

        // On php page -- do page redirect
        return this.$q.resolve().then(() => {
            this.$window.location.assign('/project/draft');
        });
    }
}

AddEntryService.$inject = [
    '$http',
    '$q',
    '$state',
];

export default AddEntryService;
