class AddCompaniesAction
{
    constructor($state, $pfGameAction) {
        this.$state = $state;
        this.$pfGameAction = $pfGameAction;
        this.name = 'add_more_companies';
    }

    get label() {
        let label = 'Follow companies';
        let currentLevel = this.$pfGameAction.getCurrentLevel() || {};

        if (this.type === 'Must_have' && currentLevel.name === 'pro') {
            label = 'Follow 2+ companies';
        }

        if (this.type === 'Has_many') {
            label = 'Follow more companies';
        }

        return label;
    }

    execute() {
        return this.$state.go('jobs.companies');
    }
}

AddCompaniesAction.$inject = ['$state', '$pfGameAction'];

export default AddCompaniesAction;
