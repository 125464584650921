import angular from 'angular';
import BaseModalController from 'components/modal/js/controller/base-modal-controller';

/**
 * @ngdoc controller
 * @name PaginationFilterBtnController
 * @module portfolium.components.paginationFilter
 */
class PaginationFilterBtnController {
    constructor($mdMedia, $mdDialog, $stateParams) {
        this.$mdMedia = $mdMedia;
        this.$mdDialog = $mdDialog;
        this.$stateParams = $stateParams;
        this.selectedTotal = 0;
    }

    $onInit() {
        this.updateSelectedCount();
    }

    $onChanges({ currentFilters }) {
        if (currentFilters) {
            this.updateSelectedCount();
        }
    }

    isMobile() {
        return !this.$mdMedia('gt-sm');
    }

    onUpdateSelectedCount(count = 0) {
        this.selectedTotal = count;
    }

    updateSelectedCount() {
        let count = 0;
        _.each(this.filtersOptions, (filter, filterName) => {
            if (_.has(this.$stateParams, filterName)) {
                const param = this.$stateParams[filterName];
                if (_.isString(param)) {
                    count++;
                } else if (_.isArray(param)) {
                    count += param.length;
                }
            }
        });
        this.selectedTotal = count;
    }

    /**
     * Open the modal
     * @param  {Event}     $event     Event
     * @return {Promise}
     */
    openModal($event) {
        return this.$mdDialog.show({
            locals: {
                filtersOptions: this.filtersOptions,
                handleOnApply: this.onApply,
                handleOnCancel: this.onCancel,
            },
            template: `
                <md-dialog
                     class="pf-dialog pf-dialog--white pf-dialog--v2 pf-pagination-filter-modal"
                     aria-label="Pagination Filter">
                    <pf-pagination-filter
                         filters-options="::$ctrl.filtersOptions"
                         on-apply="::$ctrl.handleOnApply({filters})"
                         on-cancel="::$ctrl.handleOnCancel">
                    </pf-pagination-filter>
                </md-dialog>
            `,
            parent: angular.element(document.body),
            multiple: true,
            focusOnOpen: false,
            hasBackdrop: true,
            targetEvent: $event,
            clickOutsideToClose: true,
            controller: BaseModalController,
            bindToController: true,
            controllerAs: '$ctrl',
            onComplete: () => {
                // since we have no button to focus on, set focusOnOpen to false and manually do it to the window
                // see: https://github.com/angular/material/issues/1190
                angular.element('md-dialog').focus();
            },
        })
        .then(() => {
            this.updateSelectedCount();
        });
    }
}

PaginationFilterBtnController.$inject = [
    '$mdMedia',
    '$mdDialog',
    '$stateParams',
];

export const PaginationFilterBtnComponent = {
    controller: PaginationFilterBtnController,
    bindings: {
        currentFilters: '<',
        filtersOptions: '<',
        onCancel: '&',
        onApply: '&',
    },
    template: `
        <md-button md-no-ink class="md-button"
             ng-class="{
                 'pf-outline': $ctrl.isMobile(),
                 'pf-icon-text-button': !$ctrl.isMobile()
             }"
             ng-click="$ctrl.openModal($event)">
            <div layout="row" layout-align="center center">
                <md-icon ng-if="!$ctrl.isMobile()">filter_list</md-icon>
                Filters ({{ $ctrl.selectedTotal }})
            </div>
        </md-button>
    `,
};
