/**
 * @ngdoc directive
 * @name toggleSitewideSearchMobilePanelDirective
 * @module portfolium.component.sitewideSearch
 * @param {Service} $pfSitewideSearchMobilePanel search panel service
 * @description Toggles the site wide search panel, for mobile use
 */
export const toggleSitewideSearchMobilePanelDirective = ($pfSitewideSearchMobilePanel) => {
    return {
        restrict: 'A',
        link: (scope, elem) => {
            elem.on('click', ($event) => {
                $pfSitewideSearchMobilePanel.open();
            });
        },
    };
};

toggleSitewideSearchMobilePanelDirective.$inject = ['$pfSitewideSearchMobilePanel'];
