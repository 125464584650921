import '../../auth-modal.scss'

export class RedirectAuthModalController {
    constructor(
        $pfToast,
        $pfEnv,
        $mdDialog,
        $pfAuthProvider,
        provider,
    ) {
        this.isDev = $pfEnv.isDev;
        this.selectedRegion = '';
        this.BadgRURLs = {
            "test":"test.badgr.com",
            "us":"badgr.com",
            "eu":"eu.badgr.com",
            "ca":"ca.badgr.com",
            "au":"au.badgr.com",
        };
        this.clientIds = {
            "test":"B9aH5rawaMnC2Tg95OBvRBYmtAyo185d8iozF6md",
            "us":"DkTfLmRXjSmDRCwq6zZeZajzcQ6UOfPTNnSkEMLf",
            "eu":"hc2HujvVJwzuC2yGSB2GpMhc7fSTzrzgu8KIAjV6",
            "ca":"RED7IbSA9m5jgkIRPlWvcBzjmuxULghTrvkGmnHE",
            "au":"tBoPELOQtFcqYJzSFpkSQfYk5pb4LsIlIsckWIQ3",
        }

        this.$pfToast = $pfToast;
        this.$mdDialog = $mdDialog;
        this.$pfAuthProvider = $pfAuthProvider;

        // set up the provider
        this.providerName = provider.displayName;
        this.providerLogo = provider.logoUrl;
        this.provider = $pfAuthProvider.get(provider.name);
    }

    /**
     * @description Close the modal, rejecting the promise
     */
    close() {
        this.$mdDialog.hide();
    }

    redirect() {

        let currentUrl = this.BadgRURLs[this.selectedRegion];
        let currentClientId = this.clientIds[this.selectedRegion];
        window.location.href = `https://${currentUrl}/auth/oauth2/authorize?client_id=${currentClientId}&redirect_uri=https://${window.location.host}/badge/redirect&scope=r:backpack+rw:backpack+r:assertions+r:profile`;
    }
}

RedirectAuthModalController.$inject = [
    '$pfToast',
    '$pfEnv',
    '$mdDialog',
    '$pfAuthProvider',
    'provider',
];
