import NavbarEmptyTemplate from './navbar-empty.component.html';
import './navbar-empty.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.navbar
 * @name NavbarEmptyController
 */
class NavbarEmptyController {
    constructor($pfUser) {
        this.$pfUser = $pfUser;
    }

    $onInit() {
        this.currentUser = this.$pfUser.getUser();
    }
}

NavbarEmptyController.$inject = [
    '$pfUser',
];

/**
 * @ngdoc component
 * @module portfolium.component.navbar
 * @name pfNavbarEmpty
 */
export const NavbarEmptyComponent = {
    controller: NavbarEmptyController,
    controllerAs: '$ctrl',
    template: NavbarEmptyTemplate,
};
