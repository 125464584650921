export class BadgrService {
    constructor(
        $http,
        $pfUrlUtils,
    ) {
        this.$http = $http;
        this.$pfUrlUtils = $pfUrlUtils;
    }

    /**
     * Get the badge data from the id
     * @param {String} id The id of the badge
     * @return {Promise}
     */
    getBadgeData(id) {
        return this.$http({
            method: 'GET',
            url: `/proxy/badgr/badge_info`,
            params: {
                u: id,
            },
        }).then(response => response.data);
    }

    /**
     * Add a badge to the authed users profile
     * @param  {Object} badge={} badge data
     * @return {promise}
     */
    addBadge(badge = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/badgr/add_badge`,
            data: {
                id: badge.id,
                title: badge.title,
                description: badge.description,
                url: badge.url,
                image: badge.image,
                issue_date: badge.issued_on,
                issuer: (_.get(badge.issuer, 'name')) ? badge.issuer.name : null,
            },
        }).then(response => response.data);
    }

    /**
     * Get a badge that is associated with a user by badge source id
     * @param  {String} id badge source id
     * @return {promise}
     */
    getExistingBadgeById(id) {
        return this.$http({
            method: 'GET',
            url: `/proxy/badgr/user_badge/${id}`,
        }).then(response => response.data);
    }

    /**
     * Get the badge id from the share url
     * @param {String} url The url
     * @return {String}
     */
    getBadgeIdFromUrl(url) {
        return this.$http({
            method: 'GET',
            url: `/proxy/badgr/public_badge_info`,
            params: {
                u: url,
            },
        }).then((response) => {
            return response.data
        });
    }
}

BadgrService.$inject = [
    '$http',
    '$pfUrlUtils',
];
