class GameEngineService
{
    constructor($http, $pfUser, $rootScope) {
        this.$http = $http;
        this.$rootScope = $rootScope;
        this.user = $pfUser.getUser();
    }

    /**
     * @description Update the game engine results
     * @return {Object|Promise} Game engine results
     */
    updateResults() {
        return this.getResults();
    }

    /**
     * @description Fetch game engine results
     * @return {Object|Promise} Game engine results
     */
    getResults() {
        if (!this.user) {
            return {};
        }

        return this.getGameEngineResults().then(() => {
            return this.results;
        });
    }

    /**
     * @description Get the authed user's current level
     * @return {Boolean|Object} Level object
     */
    getCurrentLevel() {
        if (!this.results) {
            return false;
        }

        return this.results.current_level;
    }

    /**
     * @description Make API call to retrieve game engine results
     * @return {Promise}
     */
    getGameEngineResults() {
        return this.$http({
            method: 'GET',
            url: `/proxy/suggestions/me`
        })
        .then((response) => {
            let results = response.data;

            this.results = results;

            return results;
        });
    }
}

GameEngineService.$inject = ['$http', '$pfUser', '$rootScope'];

export default GameEngineService;
