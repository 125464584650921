import ProfileAboutPanelWorkSamplesTemplate from '../../template/profile-about-panel-work-samples.html';
import {
    toggleAboutPanelTab,
    updateSearchResults,
    updateEntryList,
    clearSearchResults,
    toggleOrdering,
} from '../action/profile-about-panel';

class ProfileAboutPanelWorkSamplesController {
    constructor($timeout, $ngRedux, $pfEntrySearch, $pfEntryEditor, $pfUser, $pfUserEntries) {
        this._$timeout = $timeout;
        this._$ngRedux = $ngRedux;
        this._$pfEntrySearch = $pfEntrySearch;
        this._$pfEntryEditor = $pfEntryEditor;
        this._$pfUser = $pfUser;
        this._$pfUserEntries = $pfUserEntries;
    }

    $onInit() {
        this.currentUser = this._$pfUser.getUser();
    }

    $onChanges(changes) {
        if (changes.userEntries) {
            // Update the non-attached entries list
            this._$ngRedux.dispatch(updateEntryList(this.userEntries.entries));
        }
    }

    /**
     * Close the panel without saving any changes
     * @param {boolean} [didCreateEntry] whether to close or not the overview edit dialog
     * @return {Promise}
     */
    close({ didCreateEntry = false } = {}) {
        return this.panelCtrl.close({didCreateEntry});
    }

    /**
     * Switch panel  tabs
     * @param  {String} tabName Tab to switch to
     */
    activateTab(tabName) {
        this._$ngRedux.dispatch(toggleAboutPanelTab(tabName));
    }

    /**
     * Search the user's entries based on keyword matching
     * @param  {String}              searchText Search query
     * @return {void|Promise<Array>}
     */
    searchEntries(searchText) {
        // Pause 150ms before showing loading spinner
        const delay = this._$timeout(() => this.searchLoading = true, 150);
        const cancelLoading = () => {
            this._$timeout.cancel(delay);
            this.searchLoading = false;
        };

        if (!searchText.length) {
            // No search text -- reset search state
            this._$ngRedux.dispatch(clearSearchResults());
            cancelLoading();

            return;
        }

        return this._$pfEntrySearch.searchEntriesByUserId(this.currentUser.id, { q: searchText })
            .then((entries) => {
                // Update application state
                this._$ngRedux.dispatch(updateSearchResults(entries));
            })
            .finally(cancelLoading);
    }

    /**
     * Clear the entry search query (show all entries)
     */
    clearEntrySearch() {
        this._$ngRedux.dispatch(clearSearchResults());
    }

    /**
     * Add a new project
     * @param {jqEvent} $event on click event
     * @return {Promise}
     */
    addEntry($event) {
        return this._$pfEntryEditor.newEntry({
            bucketId: this.panel.data.fk_bucket_id,
            source: 'profileAboutPanel',
            targetEvent: $event,
        }).then(() => {
            // Finished creating entry and transitioning to entry wizard,
            // close the panel
            return this.close({ didCreateEntry: true });
        });
    }

    /**
     * Show/hide the ordering options for attached entries
     * @param {Event} $event ngClick event
     */
    toggleOrdering($event) {
        if (_.isEmpty(this.panel.data.entries)) {
            // Toggle is disabled
            return;
        }

        // Block normal behavior of md-switch component
        $event.stopImmediatePropagation();
        // Update application state
        this._$ngRedux.dispatch(toggleOrdering());
    }

    /**
     * Load more user entries for the infinite scroller
     * @return {Promise<Array<Object>>}
     */
    loadEntries() {
        return this._$pfUserEntries.loadEntries();
    }
}

ProfileAboutPanelWorkSamplesController.$inject = [
    '$timeout',
    '$ngRedux',
    '$pfEntrySearch',
    '$pfEntryEditor',
    '$pfUser',
    '$pfUserEntries',
];

export const ProfileAboutPanelWorkSamplesComponent = {
    require: {
        'panelCtrl': '^pfProfileAboutPanel',
    },
    bindings: {
        'panel': '<pfAboutPanel',
        'userEntries': '<pfUserEntries',
    },
    template: ProfileAboutPanelWorkSamplesTemplate,
    controller: ProfileAboutPanelWorkSamplesController,
};
