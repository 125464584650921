const tweetUrl = 'https://twitter.com/intent/tweet';
const portfoliumHandle = 'PortfoliumHQ';
const portfoliumUrl = 'https://portfolium.com';

class ShareTwitter
{
    constructor($window) {
        this.$window = $window;
    }

    shareData(data, type) {
        let popupUrl = `${tweetUrl}?url=${data.url}&via=${portfoliumHandle}&text=${data.title}&hashtags=portfolium`;

        if (type === 'company') {
            popupUrl = `${tweetUrl}?url=${portfoliumUrl}/company/${data.slug}&via=${portfoliumHandle}&text=${data.name}&hashtags=portfolium`;
        }

        if (type === 'job') {
            popupUrl = `${tweetUrl}?url=${portfoliumUrl}/job/${data.slug}&via=${portfoliumHandle}&text=${data.title}&hashtags=portfolium`;
        }

        if (type === 'network') {
            popupUrl = `${tweetUrl}?url=${portfoliumUrl}/network/${data.slug}&via=${portfoliumHandle}&text=${data.name}&hashtags=portfolium`;
        }

        return this.$window.open(popupUrl, '', 'resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0,width=650,height=360');
    }
}

export default ShareTwitter;
