import angular from 'angular';

import {ClipboardComponent} from './clipboard.component';
import {ClipboardService} from './clipboard.service';

import './index.scss';

const MODULE_NAME = 'portfolium.component.clipboard';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfClipboard', ClipboardComponent)
        // Service
        .service('$pfClipboard', ClipboardService);
}());

export default MODULE_NAME;
