import angular from 'angular';

import {UserResultComponent} from './user-result.component';

import './user-result.component.scss';

const MODULE_NAME = 'portfolium.component.userResult';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfUserResult', UserResultComponent);
}());

export default MODULE_NAME;
