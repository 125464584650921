import {
    SETTINGS_PRIVACIES_REQUEST,
    SETTINGS_TOGGLE_PRIVACY,
    SETTINGS_UPDATE_PRIVACIES,
    SETTINGS_GDPR_SUBMIT_REQUEST,
    SETTINGS_UPDATE_GDPR_PORTABILITY,
} from './settings-privacy.actions';

const initialState = {
    bypassUrl: null,
    loading: false,
    privacies: {},
    gdprPortability: {},
    gdprSubmitLoading: false,
};

export const settingsPrivacy = (state = initialState, action = {}) => {
    switch (action.type) {
        case SETTINGS_PRIVACIES_REQUEST:
            return _.assign({}, state, {
                loading: true,
            });
        case SETTINGS_TOGGLE_PRIVACY:
            const { settingKey } = action.payload;
            return _.assign({}, state, {
                privacies: _.assign({}, state.privacies, {
                    [settingKey]: !state.privacies[settingKey]
                })
            });
        case SETTINGS_UPDATE_PRIVACIES:
            const { privacies, bypassUrl } = action.payload;
            return _.assign({}, state, {
                loading: false,
                privacies,
                bypassUrl,
            });
        case SETTINGS_GDPR_SUBMIT_REQUEST:
            return _.assign({}, state, {
                gdprSubmitLoading: true,
            });
        case SETTINGS_UPDATE_GDPR_PORTABILITY:
            const { gdprPortability } = action.payload;
            return _.assign({}, state, {
                gdprSubmitLoading: false,
                gdprPortability,
            });
        default:
            return state;
    }
};
