class PMailService
{
    constructor($http) {
        this.$http = $http;
    }

    /**
     * @description Send a message to one or more users, deducting a pmail credit
     * for each
     * @param  {Array}   userIds Collection of user IDs to invite
     * @param  {Object}  message Message object with subject and body
     * @return {Promise}
     */
    sendMessage(userIds = [], message = {}) {
        return this.$http({
            method: 'POST',
            url: `/proxy/pmail/create`,
            data: {
                'to_user_ids': userIds.join(','),
                'subject': message.subject,
                'message': message.body
            }
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Invite one or more users to apply to one of the current
     * user's jobs
     * @param  {Array}   userIds Collection of user IDs to invite
     * @param  {Object}  message Message object with subject and body
     * @param  {String}  jobId   Job ID to invite
     * @return {Promise}
     */
    inviteToApply(userIds = [], message = {}, jobId = null) {
        return this.$http({
            method: 'POST',
            url: `/proxy/pmail/invite_job/${jobId}`,
            data: {
                'to_user_ids': userIds.join(','),
                'subject': message.subject,
                'message': message.body
            }
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Invite one or more users to follow one of the current
     * user's companies
     * @param  {Array}   userIds   Collection of user IDs to invite
     * @param  {Object}  message   Message object with subject and body
     * @param  {String}  copmanyId Company ID to follow
     * @return {Promise}
     */
    inviteToFollow(userIds = [], message = {}, companyId = null) {
        return this.$http({
            method: 'POST',
            url: `/proxy/pmail/invite_company/${companyId}`,
            data: {
                'to_user_ids': userIds.join(','),
                'subject': message.subject,
                'message': message.body
            }
        })
        .then((response) => {
            return response.data;
        });
    }
}

PMailService.$inject = ['$http'];

export default PMailService;
