import InterestedModalTemplate from '../../template/interested-modal.html';

export class InterestedController {
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    /**
     * @description Close the modal, rejecting the promise
     */
    close() {
        this.$mdDialog.hide();
    }
}

InterestedController.$inject = [
    '$mdDialog',
];

export const interestedJobDirective = (
    $mdDialog,
    $mdMedia
) => {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.on('click', ($event) => {
                let url = attrs.pfJobUrl;
                let href = attrs.pfHref;

                $mdDialog.show({
                    targetEvent: $event,
                    controller: InterestedController,
                    controllerAs: 'modalCtrl',
                    bindToController: true,
                    template: InterestedModalTemplate,
                    fullscreen: !$mdMedia('gt-xs'),
                    clickOutsideToClose: true,
                    locals: {
                        jobUrl: url,
                        jobHref: href
                    }
                });
            });
        }
    };
}

interestedJobDirective.$inject = ['$mdDialog', '$mdMedia'];
