import angular from 'angular';

import {AccordionComponent} from './accordion.component';

const MODULE_NAME = 'portfolium.component.accordion';

(function () {
    angular.module(MODULE_NAME, [])
    .component('pfAccordion', AccordionComponent)
}());

export default MODULE_NAME;
