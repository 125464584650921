import angular from 'angular';
import {jobApplyPreAuthDirective} from './js/directive/job-apply-pre-auth.directive';
import {interestedJobDirective} from './js/directive/job-interested.directive';
import {JobApplyBtnComponent} from './js/component/job-apply-button/job-apply-btn.component';
import {JobApplyResumeComponent} from './js/component/job-apply-resume/job-apply-resume.component';
import {JobApplyService} from './js/service/job-apply.service';
import {JOB_APPLY_ACTIONS} from './job-apply.constant';

const MODULE_NAME = 'portfolium.component.job.jobApply';

import './job-apply.scss';

(function() {
    angular.module(MODULE_NAME, [])
        // Constants
        .constant('PF_JOB_APPLY_ACTIONS', JOB_APPLY_ACTIONS)
        .component('pfJobApplyBtn', JobApplyBtnComponent)
        .component('pfJobApplyResume', JobApplyResumeComponent)
        .directive('pfJobApplyPreAuth', jobApplyPreAuthDirective)
        .directive('pfInterested', interestedJobDirective)
        .service('$pfJobApply', JobApplyService);
}());

export default MODULE_NAME;
