function imageWrapDirective() {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        link: function (scope, elem, attrs) {
            let img = elem.find('img');

            scope.loading = true;
            scope.hideProgress = attrs.hasOwnProperty('pfHideProgress');

            img.on('load', () => {
                scope.$apply(() => {
                    scope.loading = false;
                });
                img.off('load');
            });
        },
        template: `
            <div class="pf-image-wrap" ng-class="{'pf-image-loading': loading}">
                <div class="pf-image-wrap-loading" ng-if="!hideProgress && loading">
                    <md-progress-circular
                         class="md-hue-3"
                         md-mode="indeterminate"
                         md-diameter="24">
                    </md-progress-circular>
                </div>
                <div class="pf-image-wrap-content" ng-hide="loading" ng-transclude></div>
            </div>
        `
    };
}

export default imageWrapDirective;
