function i18nFilter($log) {
    let $zab = window.ZAB;

    return function (text, params) {
        if (!$zab.hasInitied) {
            $zab.init();
        }

        let translated;
        let transText = text;

        try {
            translated = $zab.get(text);
            transText = translated.text;

            if (params) {
                let replaceTokens = Object.keys(params);
                let re;
                for (let x = 0; x < replaceTokens.length; x++) {
                    re = new RegExp('\{' + replaceTokens[x]+ '\}', 'g');
                    transText = transText.replace(re, params[replaceTokens[x]]);
                }
            }

        } catch (e) {
            $log.error(e);
            $log.error('ZAB- Could not translate: ' + text);
        }

        return transText;
    };
}

i18nFilter.$inject = ['$log'];

export { i18nFilter };
