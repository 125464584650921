import angular from 'angular';

import FormComponentsModule from './form-components/form-components.module';

const MODULE_NAME = 'portfolium.component.forms';

(function () {
    angular.module(MODULE_NAME, [
        FormComponentsModule,
    ]);
}());

export default MODULE_NAME;
