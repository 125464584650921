import moment from 'moment';

class DatePickerController
{
    constructor($scope) {
        this.yearList = this.getYears();
        this.monthList = this.getMonths();

        $scope.$watch('pickerCtrl.year', (newVal, oldVal) => {
            if (parseInt(newVal) === parseInt(oldVal)) {
                // Exit on init
                return;
            }

            // Reset month when year changes
            this.month = null;
        });

        $scope.$watch('pickerCtrl.month', (newVal, oldVal) => {
            // Update option list for days field (do this even on init to make sure day options are there)
            this.dayList = this.getDays();

            if (parseInt(newVal) === parseInt(oldVal)) {
                // Exit on init
                return;
            }

            // Reset day when month changes
            this.day = null;
        });
    }

    /**
     * Get the year field options
     * @return {Array} Years array
     */
    getYears() {
        let years = [];
        let startDate = parseInt(moment().add(10, 'years').format('YYYY'), 10);
        let endDate = parseInt(moment().subtract(80, 'years').format('YYYY'), 10);

        for (let i = startDate; i >= endDate; i--) {
            years.push(i);
        }

        return years;
    }

    /**
     * Get the month field options collection
     * @return {Array} Month collection
     */
    getMonths() {
        return [
            {
                label: 'January',
                value: '01'
            },
            {
                label: 'February',
                value: '02'
            },
            {
                label: 'March',
                value: '03'
            },
            {
                label: 'April',
                value: '04'
            },
            {
                label: 'May',
                value: '05'
            },
            {
                label: 'June',
                value: '06'
            },
            {
                label: 'July',
                value: '07'
            },
            {
                label: 'August',
                value: '08'
            },
            {
                label: 'September',
                value: '09'
            },
            {
                label: 'October',
                value: '10'
            },
            {
                label: 'November',
                value: '11'
            },
            {
                label: 'December',
                value: '12'
            }
        ];
    }

    /**
     * Get the day field options collection
     * @return {Array} Days collection
     */
    getDays() {
        let days = [];
        let date = moment(`${this.year}-${this.month}`, 'YYYY-MM', true);
        let daysInMonth = date.isValid() && date.daysInMonth();

        if (!daysInMonth) {
            return [];
        }

        for (let i = 1; i <= daysInMonth; i++) {
            let day = i.toString();

            if (day.length === 1) {
                // Prepend single digit values with 0
                day = '0' + i;
            }

            days.push({
                label: i.toString(),
                value: day
            });
        }

        return days;
    }

    /**
     * Open the select to the current year if no value was set
     */
    openToCurrentYearOrSelected() {
        // check if the year wasn't set
        if (!this.year) {
            // set the current year
            this.year = new Date().getFullYear();
        }
    }
}

DatePickerController.$inject = ['$scope'];

function datePickerDirective() {
    return {
        restrict: 'E',
        require: ['ngModel', 'pfDatePicker'],
        scope: {},
        bindToController: true,
        link: function (scope, elem, attrs, ctrls) {
            let ngModelCtrl = ctrls[0];
            let pickerCtrl = ctrls[1];

            ngModelCtrl.$formatters.push((modelValue) => {
                let date = (modelValue) ? modelValue.split('-') : [];
                let year = date[0] || null;
                let month = date[1] || null;
                let day = date[2] || null;

                if (year === '0000') {
                    year = null;
                }

                if (month === '00') {
                    month = null;
                }

                if (day === '00') {
                    day = null;
                }

                return {
                    year: year,
                    month: month,
                    day: day
                };
            });

            ngModelCtrl.$parsers.push(() => {
                let {year} = pickerCtrl;
                let {month} = pickerCtrl;
                let {day} = pickerCtrl;

                if (!year) {
                    return null;
                }

                if (!month) {
                    month = '00';
                }

                if (!day) {
                    day = '00';
                }

                return `${year}-${month}-${day}`;
            });

            ngModelCtrl.$render = function () {
                pickerCtrl.year = ngModelCtrl.$viewValue.year;
                pickerCtrl.month = ngModelCtrl.$viewValue.month;
                pickerCtrl.day = ngModelCtrl.$viewValue.day;
            };

            scope.$watch('pickerCtrl.year + pickerCtrl.month + pickerCtrl.day', () => {
                ngModelCtrl.$setViewValue({
                    year: scope.year,
                    month: scope.month,
                    day: scope.day
                });
            });

            elem.attr('tabindex', -1);

            elem.on('focus', () => {
                elem.find('md-select')[0].focus();
            });
        },
        controller: DatePickerController,
        controllerAs: 'pickerCtrl',
        template: `
            <div class="pf-date-picker" layout="column" layout-gt-xs="row">
                <div class="pf-field" flex-gt-xs="33">
                    <md-select class="pf-form-control"
                         ng-model="pickerCtrl.year"
                         md-on-open="pickerCtrl.openToCurrentYearOrSelected()"
                         aria-label="Select a year"
                         placeholder='{{ "Add year" | i18n }}'>
                        <md-option ng-value="null">--</md-option>
                        <md-option ng-repeat="year in ::pickerCtrl.yearList" ng-value="::year">{{::year}}</md-option>
                    </md-select>
                </div>
                <div class="pf-field" flex-gt-xs="33">
                    <md-select class="pf-form-control"
                         ng-disabled="!pickerCtrl.year"
                         ng-model="pickerCtrl.month"
                         aria-label="Select a month"
                         placeholder='{{ "Add month" | i18n }}'>
                        <md-option ng-value="null">--</md-option>
                        <md-option ng-repeat="month in ::pickerCtrl.monthList" ng-value="::month.value">{{::month.label | i18n}}</md-option>
                    </md-select>
                </div>
                <div class="pf-field" flex-gt-xs="33">
                    <md-select class="pf-form-control"
                         ng-disabled="!pickerCtrl.month"
                         ng-model="pickerCtrl.day"
                         aria-label="Select a day"
                         placeholder='{{ "Add day" | i18n }}'>
                        <md-option ng-value="null">--</md-option>
                        <md-option ng-repeat="day in pickerCtrl.dayList" ng-value="::day.value">{{::day.label}}</md-option>
                    </md-select>
                </div>
            </div>
        `
    };
}

export default datePickerDirective;
