import {SitewideSearchController} from './sitewide-search.controller';
import SitewideSearchTemplate from './sitewide-search.component.html';

/**
 * @ngdoc component
 * @name pfSitewideSearch
 * @module portfolium.component.pfSitewideSearch
 * @description controller for pfSitewideSearch
 */
export const SitewideSearchComponent = {
    template: SitewideSearchTemplate,
    controller: SitewideSearchController,
};
