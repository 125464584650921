import angular from 'angular';

import {FollowCategoryButtonComponent} from './follow-category-button/follow-category-button.component';

const MODULE_NAME = 'portfolium.component.categoryInterests';

(function () {
    angular.module(MODULE_NAME, [])
        .component('pfFollowCategoryButton', FollowCategoryButtonComponent);
}());

export default MODULE_NAME;
