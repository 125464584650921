import _ from 'lodash';

import {INITIAL_PROFILE_SECTIONS} from '../model/initial-profile-sections';
import {
    TOGGLE_ABOUT_SECTION_VISIBILITY,
    TOGGLE_ABOUT_SECTION_VISIBLE_ITEM,
    UPDATE_ABOUT_SECTION_ORDER,
} from '../action/about-sections';

export const aboutSections = (state = INITIAL_PROFILE_SECTIONS, action = {}) => {
    switch (action.type) {
        case TOGGLE_ABOUT_SECTION_VISIBILITY:
        case TOGGLE_ABOUT_SECTION_VISIBLE_ITEM:
            return state.map((section) => {
                if (action.sectionName !== section.name) {
                    return section;
                }

                return aboutSection(section, action);
            });
        case UPDATE_ABOUT_SECTION_ORDER:
            return _.orderBy(state.map((section) => {
                let order = action.payload[section.name];

                if (section.name === 'resume') {
                    // Make sure add resume cta is always first
                    order = -1;
                }

                if (section.name === 'introduction') {
                    // Make sure introduction is always between add resume and the rest
                    order = 0;
                }

                return aboutSection(section, {
                    type: action.type,
                    payload: order,
                });
            }), ['order'], ['asc']);
        default:
            return state;
    }
};

const aboutSection = (state = {}, action = {}) => {
    switch (action.type) {
        case TOGGLE_ABOUT_SECTION_VISIBILITY:
            return _.assign({}, state, {
                collapsed: !!action.payload,
            });
        case TOGGLE_ABOUT_SECTION_VISIBLE_ITEM:
            return _.assign({}, state, {
                visibleItem: action.payload,
            });
        case UPDATE_ABOUT_SECTION_ORDER:
            return _.assign({}, state, {
                order: parseInt(action.payload, 10),
            });
        default:
            return state;
    }
};
