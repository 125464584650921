import {combineReducers} from 'redux';
import {getPaginationReducer} from 'components/pagination/pagination.reducer';

import {
    CHALLENGES_JOIN_OPEN,
    CHALLENGES_JOIN_CLOSE,
    CHALLENGES_JOIN_SEARCH_RESULTS_UPDATE,
    CHALLENGES_JOIN_SEARCH_RESULTS_RESET,
    paginationActionTypes,
} from './challenges-join.actions';

const initialState = {
    open: false,
    searchResults: [],
    searchResultsVisible: false,
}

const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case CHALLENGES_JOIN_OPEN:
            return _.assign({}, state, {
                open: true
            });
        case CHALLENGES_JOIN_CLOSE:
            return _.assign({}, state, {
                open: false,
                searchResults: [],
                searchResultsVisible: false,
            });
        case CHALLENGES_JOIN_SEARCH_RESULTS_UPDATE:
            return _.assign({}, state, {
                searchResults: action.payload.results,
                searchResultsVisible: true,
            });
        case CHALLENGES_JOIN_SEARCH_RESULTS_RESET:
            return _.assign({}, state, {
                searchResults: [],
                searchResultsVisible: false
            });
        default:
            return state;
    }
};

export const challengesJoin = combineReducers({
    meta,
    entries: getPaginationReducer({
        limit: 15,
        actionTypes: paginationActionTypes,
        paginationStyle: 'infinite',
    }),
});
