import angular from 'angular';

import {ProfileQuickEditComponent} from './profile-quick-edit.component';
import {ProfileQuickEditBtnComponent} from './profile-quick-edit-btn.component';
import {ProfileQuickEditService} from './profile-quick-edit.service';

import './profile-quick-edit.component.scss';

const MODULE_NAME = 'portfolium.components.profileQuickEdit';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfProfileQuickEdit', ProfileQuickEditComponent)
        .component('pfProfileQuickEditBtn', ProfileQuickEditBtnComponent)
        // Services
        .service('$pfProfileQuickEdit', ProfileQuickEditService);
}());

export default MODULE_NAME;
