export class ProfileService {
    constructor(
        $http,
        $pfSession,
        $pfUser,
        $window,
        $pfSubscription,
        $pfUserAbout,
        $pfAuth,
        $timeout,
    ) {
        this.$http = $http;
        this.$pfSession = $pfSession;
        this.$pfUser = $pfUser;
        this.$window = $window;
        this.$pfUserAbout = $pfUserAbout;
        this.$pfAuth = $pfAuth;
        this.$timeout = $timeout;
        this.isSubscriber = $pfSubscription.isSubscriber();
    }

    /**
     * @description Fetch user my profile data
     * @returns {Promise}
     */
    getUserMe() {
        return this.$http({
            method: 'GET',
            url: `/proxy/users/me`
        }).then(response => response.data);
    }

    /**
     * @description Fetch user profile data by username
     * @param   {String}  username Username
     * @returns {Promise}
     */
    getUserByUsername(username) {
        let params = {
            subscriber: this.isSubscriber ? true : false
        };

        return this.$http({
            method: 'GET',
            url: `/proxy/users/user/${username}/slug`,
            params: params
        }).then(response => response.data);
    }

    /**
     * @description Fetch user's about me data by username
     * @param   {String}  username Username
     * @returns {Promise}
     */
    getAboutByUsername(username) {
        const currentUser = this.$pfUser.getUser();
        // TODO - move this to this.$pfUserAbout.getAboutByUsername(username)
        return this.$http({
            method: 'GET',
            url: `/proxy/users/about/${username}/slug`
        })
        .then(response => {
            // update the userAbout service if username is authed user
            if (username === currentUser.username) {
                this.$pfUserAbout.updateMyAbout(response.data);
            }

            return response.data;
        });
    }

    /**
     * @description Fetch a user's connections by user ID
     * @param  {Strgin}  userId User ID
     * @param  {Object}  params Request parameters
     * @returns {Promise}
     */
    getConnectionsByUserId(userId, params) {
        return this.$http({
            method: 'GET',
            url: `/proxy/connections/user/${userId}`,
            params: params
        }).then(response => response.data);
    }

    /**
     * @description get the current users settings
     * @return {promise}
     */
    getUserNotificationSettings() {
        return this.$http({
            method: 'GET',
            url: `proxy/settings/notifications`,
        }).then(response => response.data);
    }

    /**
     * @description get the current users privacy settings
     * @return {promise}
     */
    getUserPrivacySettings() {
        return this.$http({
            method: 'GET',
            url: `proxy/settings/privacy`,
        }).then(response => response.data);
    }

    /**
     * @description Fetch user my Email Notifications data
     * @returns {Promise}
     */
    getEmailSettings(user) {
        return this._notificationsToBool(user);
    }

    /**
     * @description Get User Cleaances for current user
     * @return {Promise}
     */
    getUserClearances() {
        return this.$http({
            method: 'GET',
            url: `/proxy/system/clearances`,
        }).then(response => this._clearanceToBool(response.data));
    }

    /**
     * @description Add a Clearance for an user
     * @param  {String}  clearanceId Id of the clearance
     * @return {Promise}
     */
    setUserClearance(clearanceId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/users/clearance/${clearanceId}`,
        }).then(response => response.data);
    }

    /**
     * @description update a users currently selected privacy settings
     * @param  {object} data key value object
     * @return {promise}
     */
    updatePrivacy(data) {
        return this.$http({
            method: 'POST',
            url: `/proxy/settings/privacy`,
            data
        })
        .then(response => {
            this.$pfSession.updateSession();
            return response.data;
        });
    }

    /**
     * @description Update a users resume
     * @param   {object} data Form data
     * @returns {promise}
     */
    updateResume(data) {
        return this.$http({
            method: 'POST',
            url: '/proxy/users/resume',
            data: data,
        })
        .then(response => response.data.resume);
    }

    /**
     * @description update a users currently selected settings
     * @param  {object} data key value object
     * @return {Promise}
     */
    updateBatchSetting(settings) {
        return this.$http({
            method: 'POST',
            url: `/proxy/settings/setting_batch`,
            data: {
                settings: JSON.stringify(settings)
            }
        })
        .then(response => {
            this.$pfSession.updateSession();
            return response.data;
        });
    }

    /**
     * @description Delete a clearance for an user
     * @param  {String}  clearanceId Id of the clearance
     * @return {Promise}
     */
    deleteUserClearance(clearanceId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/users/clearance/${clearanceId}`,
        }).then(response => response.data);
    }

    /**
     * @description Delete all connections for current user
     * @returns {Promise}
     */
    deleteAllConnections() {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/connections/me`,
        }).then(response => response.data);
    }

    /**
     * Disable Current User
     * @param  {String}  reason reason for disabling account
     * @returns {Promise}
     */
    disableUser(reason) {
        let params = {
            reason: reason
        };

        return this.$http({
            method: 'POST',
            data: params,
            url: `/proxy/users/me`
        }).then(() => {
            // delete local storage
            this.$pfAuth.logoutTasks();
            this.$window.location.assign('/logout');
        });
    }

    /**
     * Permanently delete current user account
     * @param  {String}  reason reason for deleting account
     * @param  {String}  password users password
     * @returns {Promise}
     */
    permanentlyDeleteUser(reason, password) {
        let params = {
            reason,
            password,
        };

        return this.$http({
            method: 'POST',
            data: params,
            url: `/proxy/gdpr/withdraw_consent`
        }).then(response => response.data);
    }

    /**
     * Cancel delete user account request
     * @param  {String}  token token for delete account request
     * @returns {Promise}
     */
    cancelDeleteUser(token) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/gdpr/withdraw_consent/${token}`
        }).then(response => response.data);
    }

    /**
     * Make API call to request GDPR download file
     * @return {Promise}
     */
     createGdprDownloadRequest() {
        return this.$http({
            method: 'POST',
            url: '/proxy/gdpr/portability'
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Transform the value of the clearances to boolean
     * @param  {Array<Object>}  clearances
     * @return {Object}
     */
    _clearanceToBool(clearances) {
        _.forEach(clearances, (value, key) => {
            clearances[key].granted = (clearances[key].granted === '1');
        });
        return clearances;
    }

    /**
     * @description Transform the value of the notoficiatiosn to boolean
     * @param  {Array<Object>}  notifications
     * @return {Object}
     */
    _notificationsToBool(userInfo) {
        let notifications = {};
        _.forEach(userInfo, (value, key) => {
            if (_.startsWith(key, 'email_')) {
                notifications[key] = (value === '1');
            }
        });
        return notifications;
    }
}

ProfileService.$inject = [
    '$http',
    '$pfSession',
    '$pfUser',
    '$window',
    '$pfSubscription',
    '$pfUserAbout',
    '$pfAuth',
    '$timeout',
];
