export class SectionNameService {
    setName(sectionName) {
        if (!_.isString(sectionName)) {
            this._name = '';
        } else {
            this._name = sectionName;
        }

        return this._name;
    }

    getName() {
        return this._name;
    }
}

/**
 * Watch for changes to the section name, then update the directive template
 */
export const sectionNameDirective = ($pfSectionName) => {
    return {
        restrict: 'E',
        link: function (scope, elem, attrs) {
            // Watch for changes to the name value, and update element
            scope.$watch(() => $pfSectionName.getName(), (name) => {
                scope.sectionName = name;
            });
        },
        template: `
            <span ng-bind="sectionName"></span>
        `
    };
};

sectionNameDirective.$inject = ['$pfSectionName'];
