import {attachEntry, detachEntry} from '../action/profile-about-panel';

class ProfileAboutPanelEntryController {
    constructor($ngRedux, $mdDialog, $pfProfileBucket, $filter) {
        this._$ngRedux = $ngRedux;
        this._$mdDialog = $mdDialog;
        this._$pfProfileBucket = $pfProfileBucket;
        this.$filter = $filter;
    }

    $onChanges(changes) {
        if (changes.attachedEntries) {
            // Set toggled flag when binding data changes
            this.toggled = !!_.find(this.attachedEntries, {id: this.entry.id});
        }
    }

    /**
     * Show a confirmation dialog to confirm detachment of an entry
     * @param  {event}   $event ngClick event
     * @return {Promise}
     */
    _confirmDelete($event) {
        const title = this.$filter('i18n')('Remove project from this section?');
        const ok = this.$filter('i18n')('Yes, remove');
        const cancel = this.$filter('i18n')('Cancel');

        const confirm = this._$mdDialog.confirm()
            .title(title)
            .targetEvent($event)
            .ok(ok)
            .cancel(cancel);

        return this._$mdDialog.show(confirm);
    }

    /**
     * Handle click on the list item element
     * @param  {Event} $event ngClick event
     */
    toggle($event) {
        this.toggled = !this.toggled;

        this.onToggle($event);
    }

    /**
     * Attach/detatch an entry from an about record
     * @param  {Event}         $event ngClick event
     * @return {Promise<void>}
     */
    onToggle($event) {
        if (this.toggled === false) {
            // Show a confirmation dialog
            return this._confirmDelete($event).then(() => {
                // Persist change to db
                return this._$pfProfileBucket.removeEntryFromBucket(this.bucketId, this.entry.id)
                    // Update application state
                    .then((data) => this._$ngRedux.dispatch(detachEntry({
                        skills: data.skills,
                        entry: this.entry,
                    })));
            }, () => {
                // User aborted the confirmation dialog or API request failed -- flip toggle back
                this.toggled = true;
            });
        }

        // Persist change to db
        return this._$pfProfileBucket.addEntryToBucket(this.bucketId, this.entry.id)
            // Update application state
            .then((data) => this._$ngRedux.dispatch(attachEntry({
                skills: data.skills,
                entry: this.entry,
            })), () => {
                // API request failed -- flip toggle back
                this.toggled = false;
            });
    }
}

ProfileAboutPanelEntryController.$inject = [
    '$ngRedux',
    '$mdDialog',
    '$pfProfileBucket',
    '$filter',
];

export const ProfileAboutPanelEntryComponent = {
    bindings: {
        'attachedEntries': '<pfAttachedEntries',
        'entry': '<pfEntry',
        'bucketId': '@pfBucketId',
    },
    controller: ProfileAboutPanelEntryController,
    template: `
        <md-list-item
             class="md-3-line"
             ng-class="{'pf-selected': $ctrl.toggled}">
            <md-checkbox
                 class="md-primary"
                 ng-model="$ctrl.toggled"
                 ng-change="$ctrl.onToggle($event)"
                 aria-label="Toggle {{::$ctrl.entry.title}}">
            </md-checkbox>
            <img ng-src="{{::$ctrl.entry.media[0].filename.url_https|image:108:192}}"
                 class="md-avatar"
                 alt=""
                 aria-hidden="true">
            <div class="md-list-item-text">
                <h3>{{::$ctrl.entry.title}}</h3>
                <p>{{::$ctrl.entry.category}}</p>
            </div>
        </md-list-item>
    `,
};
