export const ENTRY_EDITOR_GUIDE_TYPES = {
    attachments: 'attachments',
    category: 'category',
    description: 'description',
    skills: 'skills',
    tags: 'tags',
    teammates: 'teamates',
    title: 'title',
}

export const ENTRY_EDITOR_GUIDE_TEMPLATES = [
    {
        guideName: ENTRY_EDITOR_GUIDE_TYPES.attachments,
        template: '<pf-entry-editor-guide-attachments></pf-entry-editor-guide-attachments>',
    },
    {
        guideName: ENTRY_EDITOR_GUIDE_TYPES.category,
        template: '<pf-entry-editor-guide-category></pf-entry-editor-guide-category>',
    },
    {
        guideName: ENTRY_EDITOR_GUIDE_TYPES.description,
        template: '<pf-entry-editor-guide-description></pf-entry-editor-guide-description>',
    },
    {
        guideName: ENTRY_EDITOR_GUIDE_TYPES.skills,
        template: '<pf-entry-editor-guide-skills></pf-entry-editor-guide-skills>',
    },
    {
        guideName: ENTRY_EDITOR_GUIDE_TYPES.tags,
        template: '<pf-entry-editor-guide-tags></pf-entry-editor-guide-tags>',
    },
    {
        guideName: ENTRY_EDITOR_GUIDE_TYPES.teammates,
        template: '<pf-entry-editor-guide-teammates></pf-entry-editor-guide-teammates>',
    },
    {
        guideName: ENTRY_EDITOR_GUIDE_TYPES.title,
        template: '<pf-entry-editor-guide-title></pf-entry-editor-guide-title>',
    },
]
