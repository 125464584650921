import angular from 'angular';
import 'rxjs/add/operator/take';

/**
 * @ngdoc directive
 * @name pfInfiniteScroll
 * @module portfolium.component.infiniteScroll
 * @restrict A
 * @description
 * Execute an operation when the bottom of the element reaches the bottom of
 * it's scrolling context. Operations are batched in sets, meaning the user must
 * click a "load more" button before operations will continue. See the
 * `pf-infinite-scroll-loader` directive to add this behavior.
 *
 * @param {Promise<Any>} [pf-on-scroll-load] Operation to exectute when the
 *     element bottoms out
 * @param {String} [pf-scroll-container] Scrolling context (defaults to document)
 * @param {String} [pf-scroll-batch-count] How many sets to load before pausing
 * @param {Boolean} [pf-scroll-loading] Loading flag
 * @param {Boolean} [pf-scroll-paused] Paused flag
 * @param {String} [pf-offset-distance] Offset distance value from parent container
 */
export const infiniteScrollDirective = $timeout => {
    return {
        restrict: 'A',
        scope: {
            onLoad: '&pfOnScrollLoad',
            container: '@pfScrollContainer',
            loading: '=pfScrollLoading',
            paused: '=pfScrollPaused',
            offsetDistance: '@pfOffsetDistance',
        },
        bindToController: true,
        require: 'pfInfiniteScroll',
        controller: InfiniteScrollController,
        controllerAs: '$ctrl',
        link: (scope, elem, attrs, ctrl) => {
            if (attrs.pfScrollBatchCount) {
                // Allow developer to override batch count size
                ctrl.batchCount = parseInt(attrs.pfScrollBatchCount, 10);
            }

            $timeout(() => {
                // Wait for element to fully render, then load first set
                ctrl.loadMore();
            });

            elem.on('$destroy', () => {
                // Clean up subscriber on destroy
                if (ctrl.subscriber) {
                    ctrl.subscriber.unsubscribe();
                }
            });
        },
    };
};

infiniteScrollDirective.$inject = ['$timeout'];

class InfiniteScrollController {
    constructor($scope, $element, $q, $pfInfinite) {
        this._$scope = $scope;
        this._$element = $element;
        this._$q = $q;
        this._$pfInfinite = $pfInfinite;
    }

    $onInit() {
        this.containerEl = this.container || document;
        this.batchCount = 2;
        this.loading = false;
        this.paused = true;
        this.$container = angular.element(this.containerEl).eq(0);

        this.loadMoreStream = this._$pfInfinite
            .scrollStream(this.$container, this._$element, this.offsetDistance)
            .filter(() => !this.loading);
    }

    /**
     * Subscribe to the scroll stream for the configured batch count size --
     * executing the onLoad callback every time the element bottoms out
     */
    _subscribe() {
        this.paused = false;

        return this.loadMoreStream.take(this.batchCount).subscribe(
            val => {
                this._$scope.$apply(() => {
                    this.loading = true;
                });

                this._$q.when(this.onLoad()).finally(() => {
                    this.loading = false;
                });
            },
            err => {
                throw new Error(err);
            },
            () => {
                this.paused = true;
            },
        );
    }

    /**
     * Continue loading more records if the scroller is paused
     */
    loadMore() {
        if (!this.paused) {
            return;
        }

        this.subscriber = this._subscribe();
    }
}

InfiniteScrollController.$inject = ['$scope', '$element', '$q', '$pfInfinite'];
