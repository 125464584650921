import angular from 'angular';

import { PathwayRequirementSubmissionPanelService } from './pathway-requirement-submission-panel.service';
import { PathwayRequirementSubmissionPanelComponent } from './pathway-requirement-submission-panel.component';
import { PathwayRequirementSubmissionPanelEditorComponent } from './pathway-requirement-submission-panel-editor/pathway-requirement-submission-panel-editor.component';
import { PathwayRequirementSubmissionPanelListComponent } from './pathway-requirement-submission-panel-list/pathway-requirement-submission-panel-list.component';
import { PathwayRequirementSubmissionPanelEditorFieldListComponent } from './pathway-requirement-submission-panel-editor/pathway-requirement-submission-panel-editor-field-list/pathway-requirement-submission-panel-editor-field-list.component';
import { PathwayRequirementSubmissionPanelEditorFieldListCheckboxComponent } from './pathway-requirement-submission-panel-editor/pathway-requirement-submission-panel-editor-field-list/pathway-requirement-submission-panel-editor-field-list-checkbox.component';

const MODULE_NAME = 'portfolium.pathways.requirement.submissionPanel';

(function () {
    angular.module(MODULE_NAME, [])
        // Services
        .service('$pfPathwayRequirementSubmissionPanel', PathwayRequirementSubmissionPanelService)
        // Components
        .component('pfPathwayRequirementSubmissionPanel', PathwayRequirementSubmissionPanelComponent)
        .component('pfPathwayRequirementSubmissionPanelEditor', PathwayRequirementSubmissionPanelEditorComponent)
        .component('pfPathwayRequirementSubmissionPanelList', PathwayRequirementSubmissionPanelListComponent)
        .component('pfPathwayRequirementSubmissionPanelEditorFieldList', PathwayRequirementSubmissionPanelEditorFieldListComponent)
        .component('pfPathwayRequirementSubmissionPanelEditorFieldListCheckbox', PathwayRequirementSubmissionPanelEditorFieldListCheckboxComponent);
})();

export default MODULE_NAME;
