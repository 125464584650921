import angular from 'angular';

import './filestack-viewer-mobile.component.scss';

import {filestackViewerDirective} from './filestack-viewer.directive';
import {FilestackViewerService} from './filestack-viewer.service';
import {FilestackViewerMobileComponent} from './filestack-viewer-mobile.component';

const MODULE_NAME = 'portfolium.component.filestackViewer';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfFilestackViewerMobile', FilestackViewerMobileComponent)
        // Directive
        .directive('pfFilestackViewer', filestackViewerDirective)
        // Services
        .service('$pfFilestackViewer', FilestackViewerService);
}());

export default MODULE_NAME;
