export const NOTIFICATION_TYPE = {
    join: '1',
    like: '2',
    comment: '3',
    collaborator: '4',
    commentback: '5',
    connectionRequest: '6',
    connectionAccept: '7',
    facebook: '8',
    referral: '9',
    announcement: '10',
    networkRequest: '11',
    networkAccept: '12',
    job: '13',
    follows: '14',
    applies: '15',
    views: '16',
    knockKnockEntry: '17',
    enrollment: '18',
    assignment_submit: '19',
    assignment: '20',
    assignment_graded: '21',
    assignment_revision_request: '22',
    requirement_graded: '23',
    requirement_revision_request: '24',
};

export const NOTIFICATION_GROUP = {
    connect: 'connect',
    confirm: 'confirm',
    general: 'general',
};

export const DYNAMIC_TYPE = {
    entry: 'entry',
    user: 'user',
    network: 'network',
    job: 'job',
    application: 'application',
    enrollment: 'enrollment',
    assignment_submit: 'assignment_submit',
    assignment: 'assignment',
    requirement: 'requirement',
};
