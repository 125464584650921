import SitewideSearchMenuTemplate from './sitewide-search-menu.component.html';
import './sitewide-search-menu.component.scss';

/**
 * @ngdoc controller
 * @name SitewideSearchMenuController
 * @module portfolium.component.pfSitewideSearch
 * @description controller for pfSitewideSearchMenu
 */
class SitewideSearchMenuController {
    constructor(
        $state,
        $pfEventTracker,
        $mdMedia,
        $window,
        PF_SITEWIDE_SEARCH_MENU,
        PF_SITE_WIDE_SEARCH_MENU_ID,
        PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX
    ) {
        this.$state = $state;
        this.$pfEventTracker = $pfEventTracker;
        this.$mdMedia = $mdMedia;
        this.$window = $window;
        this.PF_SITEWIDE_SEARCH_MENU = PF_SITEWIDE_SEARCH_MENU;
        this.PF_SITE_WIDE_SEARCH_MENU_ID = PF_SITE_WIDE_SEARCH_MENU_ID;
        this.PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX = PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX;
    }

    /**
     * Get the constant file for the menu
     * @return {Array}
     */
    get sitewideMenu() {
        return this.PF_SITEWIDE_SEARCH_MENU;
    }

    get isMobile() {
        return !this.$mdMedia('gt-sm');
    }

    /**
     * Goes to the search state
     * @param  {Object} menuItem configurations
     * @param  {String} q     what to look for
     * @return {Promise|undefined}
     */
    searchFor(menuItem, q) {
        const {state} = menuItem;
        const {name} = menuItem;
        // track category
        this.trackCategory(name);
        // do the click
        if (this.pfItemClicked) {
            this.pfItemClicked();
        }
        // do we have a current state to transition from?
        if (this.$state.current.status === 'active') {
            /**
             * TODO After upgrading UI-Router, put this panel close back into the state.go resolve
             *
             * State transition to search gets cut off and resumed once assets are downloaded, killing the current promise so nothing is resloved
             */
            if (!_.isEmpty(this.mdPanelRef)) {
                this.mdPanelRef.close();
            }
            return this.$state.go(state, {q});
        } else {
            // get item state
            const {route} = menuItem;
            // the current page does not have a state (php page, for example)
            this.$window.location.assign(`/search/${route}?q=${q}&page=1`);
            return;
        }
    }

    /**
     * tracks the event of click on the search items
     * @param  {String} category name of the search state
     * @param  {String} where it was clicked
     */
    trackCategory(categoryName) {
        // set click source
        const clickSource = this.isMobile ? 'mobileNavbar' : 'desktopNavbar';

        // send track
        this.$pfEventTracker.trackServer('Categorical Search Performed',{
            clickSource,
            categoryName,
            userAction: 'search',
        });
    }
}

SitewideSearchMenuController.$inject = [
    '$state',
    '$pfEventTracker',
    '$mdMedia',
    '$window',
    'PF_SITEWIDE_SEARCH_MENU',
    'PF_SITE_WIDE_SEARCH_MENU_ID',
    'PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX',
];

/**
 * @ngdoc component
 * @name pfSitewideSearchMenu
 * @module portfolium.component.sitewideSearch
 * @param {String} query
 * @description controller for pfSitewideSearchMenu
 */
export const SitewideSearchMenuComponent = {
    template: SitewideSearchMenuTemplate,
    controller: SitewideSearchMenuController,
    bindings: {
        'query': '<',
        'role': '@pfRole',
        'ariaLabel': '@pfAriaLabel',
        'focusedElement': '<',
        'mdPanelRef': '<mdPanelRef',
        'pfItemClicked': '&',
    },
};
