class PmailCardController
{
    constructor() {
        this.initialize();
    }

    initialize() {
        // Set header/sub length
        switch (this.pmailType) {
            case 'GENERAL':
                this.headerLength = this.pmail.thread.subject.length;
                break;
            case 'KNOCK_KNOCK':
                this.headerLength = this.pmail.company.name.length;
                break;
        }
    }
}

function pmailCardDirective($templateCache, imageFilter) {
    let templateBase = 'template/components/pmail-card/template/card';

    return {
        restrict: 'E',
        scope: {
            'pmail': '=pfPmail',
            'pmailType': '=pfPmailType',
        },
        bindToController: true,
        link: function (scope, elem, attrs, pmailCtrl) {
            if (!_.isObject(pmailCtrl.pmail)) {
                throw new Error('[pfPmailCard] You must provide a pmail!');
            }

            pmailCtrl.logoImage = imageFilter(pmailCtrl.pmail.company.logo.url_https, 192, 192);

            elem.attr('tabindex', -1);
        },
        controller: PmailCardController,
        controllerAs: 'pmailCtrl',
        template: function (elem, attrs) {
            let template = $templateCache.get(`${templateBase}/feed.html`);

            if (_.isString(attrs.pfCardType)) {
                template = $templateCache.get(`${templateBase}/${attrs.pfCardType}.html`);
            }

            if (!template) {
                throw new Error('[pfPmailCard] Template not found!');
            }

            return template;
        }
    };
}

pmailCardDirective.$inject = ['$templateCache', 'imageFilter'];

export default pmailCardDirective;
