import angular from 'angular';
import raven from 'raven-js';
import ngRavenExceptionHandler from './ng-raven-exception-handler';

(function () {
    let app = angular.module('portfolium.errorTracker', []);
    if (!window.Portfolium) {
        return;
    }

    const userContext = window.Portfolium.current_user;
    const { PF_ENVIRONMENT, AWS_REGION_CODE } = window.Portfolium.environment;
    const isProd = PF_ENVIRONMENT === 'production';

    if (!isProd) {
        // Disable error logging everywhere but production
        return;
    }

    let ravenDsn = `https://d2cb144b3dac44769fe2700c1c95edd0@relay-${AWS_REGION_CODE}.sentry.insops.net/323`;
    let ravenConfig = {
        logger: 'javascript',
        whitelistUrls: [
            /portfolium\.com/,
            /assets\.portfolium\.com/,
        ],
    };

    // Initialize Sentry client (aka Raven)
    raven.config(ravenDsn, ravenConfig).install();

    if (userContext) {
        raven.setUserContext({
            id: userContext.id,
            authSource: userContext.auth_source,
        });
    }

    // Decorate Angular $exceptionHandler
    app.config(['$provide', function ($provide) {
        $provide.decorator('$exceptionHandler', ['$delegate', ngRavenExceptionHandler]);
    }]);
}());

export default 'portfolium.errorTracker';
