/**
 * DEPRECATED: Use new RxJS Observable scrolling service
 */
class InfiniteScroll
{
    constructor($q, config) {
        this.$q = $q;
        this.loading = false;
        this.requestCounter = 0;
        this.pauseRequests = false;
        this.batchCount = config.batchCount || 0;
    }

    /**
     * @description
     * Make a request, then increment the request counter. If the batch count
     * has been reached, pause further requests
     * @param  {Function} request Function that returns a promise to be execute
     * @return {Promise}
     */
    load(request) {
        let def = this.$q.defer();

        if (this.loading || this.pauseRequests) {
            def.reject();

            return def.promise;
        }

        this.loading = true;

        request().then((response) => {
            this.loading = false;
            this.requestCounter++;

            if (this.requestCounter > (this.batchCount - 1)) {
                this.pauseRequests = true;
                this.requestCounter = 0;
            }

            def.resolve(response);
        }, (response) => {
            def.reject(response);
        });

        return def.promise;
    }

    /**
     * @description Reset paused flag to allow requests
     * @returns {InfiniteScroll}
     */
    continue(request) {
        this.pauseRequests = false;

        return this;
    }

    /**
     * @description Reset the counter and paused flag completely
     * @returns {InfiniteScroll}
     */
    reset() {
        this.continue();
        this.requestCounter = 0;

        return this;
    }

    /**
     * @description Are batch requests currently paused?
     * @returns {Boolean}
     */
    isPaused() {
        return this.pauseRequests;
    }
}

class InfiniteScrollService
{
    constructor($q) {
        this.$q = $q;
    }

    /**
     * @description Initialize a new infinite scroll container
     * @param   {Object}         config A dictionary of configuration values
     * @returns {InfiniteScroll}
     */
    init(config) {
        return new InfiniteScroll(this.$q, config);
    }
}

InfiniteScrollService.$inject = ['$q'];

export default InfiniteScrollService;
