import MobileMenuItemsTemplate from '../../template/mobile-menu-items.html';

/**
 * @ngdoc component
 * @name mobileMenuItemsComponent
 * @module portfolium.component.mobileMenu
 **/
 export const MobileMenuItemsComponent = {
    bindings: {
        'closePanel': '&',
        'menuItems': '<',
        'jobsEnabled': '&',
    },
    template: MobileMenuItemsTemplate,
};
