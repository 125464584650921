import PathwayRequirementSubmissionPanelEditorTemplate from './pathway-requirement-submission-panel-editor.component.html';
import './pathway-requirement-submission-panel-editor.component.scss';

import {
    descriptionToggle,
    listItemToggle,
    resetProject,
} from '../pathway-requirement-submission-panel.actions';

/**
 * @ngdoc controller
 * @name PathwayRequirementSubmissionPanelEditorController
 * @module portfolium.component.pathways.requirement.submissionPanel
 **/
class PathwayRequirementSubmissionPanelEditorController {
    constructor(
        $ngRedux,
        $pfEntryDrafts,
        imageFilter,
    ) {
        this.$ngRedux = $ngRedux;
        this.$pfEntryDrafts = $pfEntryDrafts;
        this.imageFilter = imageFilter;
    }

    _mapStateToThis({
        pathways: {
            currentRequirement: {
                pathwayRequirementSubmissionPanel: {
                    currentSubmissionDraft: draft,
                }
            }
        }
    }) {
        return { draft };
    }

    $onInit() {
         // Subscribe to state updates and map actions to the controller
        this._unsubscribe = this.$ngRedux.connect(
            this._mapStateToThis,
        )(this);
    }

    $onDestroy() {
        // Unsubscribe from state updates
        this._unsubscribe();
    }

    isProjectCopiable(isValid = false) {
        // default item counter
        let itemsCounter = 0;
        // default valids counter
        let validsCounter = 0
        // sanity check for description
        if (!_.isEmpty(this.draft.description)) {
            // increment counter
            itemsCounter++;
            // sanity check for valid description
            if (isValid && this.draft.includeDescription) {
                // increment counter
                validsCounter++;
            }
        }
        // sanity check for attachments
        if (!_.isEmpty(this.draft.attachments)) {
            // increment counter
            itemsCounter++;
            // sanity check for valid attachments
            if (isValid && this.isListValid(this.draft.attachments)) {
                // increment counter
                validsCounter++;
            }
        }
        // sanity check for tags
        if (!_.isEmpty(this.draft.tags)) {
            // increment counter
            itemsCounter++;
            // sanity check for valid tags
            if (isValid && this.isListValid(this.draft.tags)) {
                // increment counter
                validsCounter++;
            }
        }
        // sanity check for skills
        if (!_.isEmpty(this.draft.skill_list)) {
            // increment counter
            itemsCounter++;
            // sanity check for valid skills
            if (isValid && this.isListValid(this.draft.skill_list)) {
                // increment counter
                validsCounter++;
            }
        }
        // sanity check for collaborators
        if (!_.isEmpty(this.draft.collaborators)) {
            // increment counter
            itemsCounter++;
            // sanity check for valid collaborators
            if (isValid && this.isListValid(this.draft.collaborators)) {
                // increment counter
                validsCounter++;
            }
        }
        // sanity check for items
        if (itemsCounter === 0) {
            return false;
        }

        if (isValid) {
            return validsCounter > 0;
        }

        return true;
    }

    isListValid(list) {
        // get how many items it is including
        const includeItems = _.filter(list, { include: true });
        // return valid when there is one or more included items
        return (includeItems.length > 0);
    }

    toggleDescription() {
        this.$ngRedux.dispatch(descriptionToggle());
    }

    toggleListItem(field, keyName, keyValue) {
        this.$ngRedux.dispatch(listItemToggle(field, keyName, keyValue));
    }

    finalizeSubmission() {
        this.$pfEntryDrafts
            .publishSubmissionDraft(this.projectId, this.draft)
            .then(() => {
                this.onDone();
            });
    }

    handleOnBack() {
        // reset the draft
        this.$ngRedux.dispatch(resetProject());
        // on back event
        this.onBack();
    }
}

PathwayRequirementSubmissionPanelEditorController.$inject = [
    '$ngRedux',
    '$pfEntryDrafts',
    'imageFilter',
];

/**
 * @ngdoc component
 * @name PathwayRequirementSubmissionPanelEditorComponent
 * @module portfolium.component.pathways
 **/
export const PathwayRequirementSubmissionPanelEditorComponent = {
    bindings: {
        'projectId': '<',
        'onDone': '&',
        'onBack': '&',
        'onClose': '&',
    },
    controller: PathwayRequirementSubmissionPanelEditorController,
    template: PathwayRequirementSubmissionPanelEditorTemplate,
}
