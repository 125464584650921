import {getPaginationReducer} from 'components/pagination/pagination.reducer';
import {dataImporterSelectionTypes} from './data-importer-selection.actions';

export const dataImporterSelection = getPaginationReducer({
    actionTypes: dataImporterSelectionTypes,
    /**
     * Because we're currently fetching all the results at once, set limit to
     * match the API's cap on total number of assignments fetched
     * TODO: Remove/change this when we implement paginated results list
     */
    limit: 10000,
    paginationStyle: 'infinite',
});
