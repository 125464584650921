import BadgeCardTemplate from './badge-card.component.html';

/**
 * @ngdoc component
 * @name pfBadgeCard
 * @module portfolium.component.pfBadgeCard
 * @description controller for pfBadgeCard
 * @param {Object} badge object containing the badge
 */
class BadgeCardController {
    constructor($state){
        this.$state = $state;
    }

    goToBadge() {
        // sanity check for badge
        if (_.isEmpty(this.badge)) {
            return;
        }
        // sanity check for badge slug
        if (_.isEmpty(this.badge.slug)) {
            return;
        }
        // sanity check for username
        if (!_.isEmpty(this.username) && this.isBadgeEarned) {
            // go to transactional page
            return this.$state.go('pathways.badgeDetailsUser', {
                badgeSlug: this.badge.slug,
                username: this.username,
            });
        }
        // go to non transactional page
        return this.$state.go('pathways.badgeDetails', {
            badgeSlug: this.badge.slug,
        });
    }

    getBadgeImage() {
        // sanity check for badge
        if (_.isEmpty(this.badge)) {
            return;
        }
        // sanity check
        const { image } = this.badge;
        // sanity check for image
        if (_.isNil(image)) {
            return;
        }
        // check if data comes differently
        const { url_https: imageURL } = image;
        // sanity check for that image url
        if (!_.isNil(imageURL)) {
            // is inside of the image object, return it
            return imageURL;
        }
        // is just a string return it
        return image;
    }
}

BadgeCardController.$inject = [
    '$state',
];

export const BadgeCardComponent = {
    bindings: {
        badge: '<',
        username: '<',
        awardedAt: '<',
        isBadgeEarned: '<',
    },
    template: BadgeCardTemplate,
    controller: BadgeCardController,
};
