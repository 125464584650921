import CompanyResultCardTemplate from './company-result-card.component.html';
import './company-result-card.component.scss';

/**
 * @ngdoc component
 * @name companyResultCardComponent
 * @module portfolium.component.company
 * @param {Object} company information of company
 **/
export const CompanyResultCardComponent = {
    bindings: {
        'company': '<',
    },
    bindToController: true,
    template: CompanyResultCardTemplate,
    controllerAs: '$ctrl',
};
