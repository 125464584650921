export const MESSAGES_UPDATE_CURRENT_THREAD = 'MESSAGES_UPDATE_CURRENT_THREAD';
export const MESSAGES_ADD_MESSAGE_TO_CURRENT_THREAD = 'MESSAGES_ADD_MESSAGE_TO_CURRENT_THREAD';
export const MESSAGES_RESET_CURRENT_THREAD = 'MESSAGES_RESET_CURRENT_THREAD';

export const updateCurrentThread = (thread) => {
    return {
        type: MESSAGES_UPDATE_CURRENT_THREAD,
        payload: {
            thread,
        },
    };
};

export const addMessageToCurrentThread = (message) => {
    return {
        type: MESSAGES_ADD_MESSAGE_TO_CURRENT_THREAD,
        payload: {
            message,
        },
    };
};

export const resetCurrentThread = () => {
    return {
        type: MESSAGES_RESET_CURRENT_THREAD,
    };
};
