export const discoverEntriesFeedActionTypes = {
    'PAGE_INIT': 'DISCOVER_ENTRIES_FEED_PAGE_INIT',
    'PAGE_NEXT': 'DISCOVER_ENTRIES_FEED_PAGE_NEXT',
    'PAGE_PREVIOUS': 'DISCOVER_ENTRIES_FEED_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'DISCOVER_ENTRIES_FEED_PAGE_REQUEST',
    'PAGE_SUCCESS': 'DISCOVER_ENTRIES_FEED_PAGE_SUCCESS',
    'PAGE_ERROR': 'DISCOVER_ENTRIES_FEED_PAGE_ERROR',
    'TOGGLE_SELECTION': 'DISCOVER_ENTRIES_FEED_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'DISCOVER_ENTRIES_FEED_CLEAR_SELECTION',
    'TOGGLE_ALL': 'DISCOVER_ENTRIES_FEED_TOGGLE_ALL',
    'ADD_FILTER': 'DISCOVER_ENTRIES_FEED_ADD_FILTER',
    'REMOVE_FILTER': 'DISCOVER_ENTRIES_FEED_REMOVE_FILTER',
    'RESET_FILTERS': 'DISCOVER_ENTRIES_FEED_RESET_FILTERS',
};
