import {ProfileAboutPanelService} from './profile-about-panel.service';

export class ProfileAboutPanelProvider {
    constructor() {
        this.panels = {};
    }

    /**
     * Register a new panel
     * @param  {String} name   Unique name for the panel
     * @param  {Object} config Panel configuration
     */
    panel(name, config = {}) {
        if (!_.isString(name)) {
            throw new Error('Panel name must be a string.');
        }

        if (this.panels[name]) {
            throw new Error(`Panel "${name}" already exists!`);
        }

        this.panels[name] = config;

        return this;
    }

    /**
     * Factory for $pfAboutPanel service
     */
    get $get() {
        return [
            '$q',
            '$timeout',
            '$mdPanel',
            '$mdDialog',
            '$mdMedia',
            '$ngRedux',
            ($q, $timeout, $mdPanel, $mdDialog, $mdMedia, $ngRedux) => {
                return new ProfileAboutPanelService($q, $timeout, $mdPanel, $mdDialog, $mdMedia, $ngRedux, this.panels);
            }
        ];
    }
}
