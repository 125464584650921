import {BadgrBadgeType} from './badgr-badge/badgr-badge-type';

/**
 * @implements ImportSource
 * @type BadgrSource
 */
export const BadgrSource = {
    name: 'badgr',
    displayName: 'Canvas Credentials',
    logoUrl: 'images/auth/logo_auth-provider_canvas.svg',
    isConnected: false,
    supportedTypes: [
        BadgrBadgeType,
    ],
};
