export class MainNavService {
    constructor($mdSidenav) {
        this.$mdSidenav = $mdSidenav;
    }

    get mainNav() {
        return this.$mdSidenav('mainNav');
    }

    /**
     * Open the main navigation sidenav
     * @return {Promise} Resolved when sidenav is finished opening
     */
    open() {
        return this.mainNav.open();
    }

    /**
     * Close the main navigation sidenav
     * @return {Promise} Resolved when sidenav is finished closing
     */
    close() {
        return this.mainNav.close();
    }

    /**
     * Toggle the main navigation sidenav
     * @return {Promise} Resolved when sidenav is finished opening/closing
     */
    toggle() {
        return this.mainNav.toggle();
    }
}

MainNavService.$inject = ['$mdSidenav'];
