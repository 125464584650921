import {CONNECTION_STATUS} from '../model/connection-status';

import {
    NOTIFICATION_TYPE,
    DYNAMIC_TYPE
} from '../../../notification-center/js/model/notification-type';

import {UserConnectFormController} from '../controller/user-connect-modal-controller';
import {updateNotificationStatus} from '../../../notification-center/js/action/notification-center-notifications';

import UserConnectModalTemplate from '../../template/user-connect-modal.html';

export class UserConnectService
{
    constructor($http, $ngRedux, $mdDialog) {
        this.$http = $http;
        this.$ngRedux = $ngRedux;
        this.$mdDialog = $mdDialog;
    }

    /**
     * @description Get a user's public profile by username
     * @param  {String}  username Username
     * @return {Promise}
     */
    getProfileByUsername(username) {
        return this.$http({
            'method': 'GET',
            'url': `/proxy/users/profile/${username}/slug`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Open the user connection request modal
     * @param  {Object}    user The user that the request will be sent to
     * @param  {Element}   parent The parent element to append the dialog to
     * @param  {Event}     $event Target event
     * @return {Promise}
     */
    addConnectionDialog(user, parent, event) {
        return this.$mdDialog.show({
            controller: UserConnectFormController,
            controllerAs: 'connectCtrl',
            locals: {
                user: user
            },
            bindToController: true,
            template: UserConnectModalTemplate,
            parent: parent,
            multiple: true,
            hasBackdrop: true,
            targetEvent: event,
            clickOutsideToClose: true,
            onComplete: onDialogOpen,
        });

        function onDialogOpen(scope, element, options) {
            if (scope.connectCtrl) {
                scope.connectCtrl.setDefaultMessage();
            }
        }
    }

    /**
     * @description Request a connection with a user by ID
     * @param  {String}  userId User ID
     * @param  {Object}  data   Form data to submit
     * @return {Promise}
     */
    requestConnection(userId, data) {
        return this.$http({
            method: 'POST',
            url: `/proxy/connections/request/${userId}`,
            data: data
        })
        .then((response) => {
            // Check if there is a notification involving the two users (entry like/comment) and update notification status
            this.$ngRedux.dispatch(updateNotificationStatus({
                userId: userId,
                type: DYNAMIC_TYPE.entry,
                status: CONNECTION_STATUS.requested
            }));

            return response.data;
        });
    }

    /**
     * @description Accept a connection request
     * @param  {String}  token Request token
     * @return {Promise}
     */
    acceptConnection(token, userId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/connections/accept/${token}`
        })
        .then((response) => {
            // mark connection request notification as accepted in the store
            // update connection request notifications
            this.$ngRedux.dispatch(updateNotificationStatus({
                userId: userId,
                type: NOTIFICATION_TYPE.connectionRequest,
                status: CONNECTION_STATUS.accepted
            }));

            // update entry group notifications
            this.$ngRedux.dispatch(updateNotificationStatus({
                userId: userId,
                type: DYNAMIC_TYPE.entry,
                status: CONNECTION_STATUS.accepted
            }));

            return response.data;
        });
    }

    /**
     * @description Remove a connection with a user by ID
     * @param  {String}  userId User ID
     * @return {Promise}
     */
    removeConnection(userId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/connections/user/${userId}`
        })
        .then((response) => {
            // Check if there is a notification involving the two users (entry like/comment) and update notification status
            // update entry group notifications
            this.$ngRedux.dispatch(updateNotificationStatus({
                userId: userId,
                type: DYNAMIC_TYPE.entry,
                status: CONNECTION_STATUS.deleted
            }));

            // update connection request notifications
            this.$ngRedux.dispatch(updateNotificationStatus({
                userId: userId,
                type: NOTIFICATION_TYPE.connectionRequest,
                status: CONNECTION_STATUS.rejected
            }));

            return response.data;
        });
    }
}

UserConnectService.$inject = ['$http', '$ngRedux', '$mdDialog'];
