export class NetworkService
{
    constructor($http) {
        this.$http = $http;
    }

    /**
     * Fetch a user's networks by user ID
     * @param  {String}  userId User ID to fetch
     * @return {Promise}
     */
    getNetworksByUserId(userId) {
        return this.$http({
            method: 'GET',
            url: `/proxy/networks/user/${userId}`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Fetch an individual network by ID
     * @param  {String}  networkId Network ID to fetch
     * @return {Promise}
     */
    getNetworkById(networkId) {
        return this.$http({
            method: 'GET',
            url: `/proxy/networks/network/${networkId}`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Fetch an individual network's details by Slug
     * @param  {String}  slug Network Slug to fetch
     * @return {Promise}
     */
    getNetworkDetailsBySlug(slug) {
        return this.$http({
            method: 'GET',
            url: `/proxy/networks/details/${slug}/slug`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Search networks
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    queryNetworks(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/search/networks',
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Search schools (including synonyms)
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    querySchools(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/search/schools',
            params: params
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Search entries
     * @param  {String}  slug of the network
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    queryEntriesBySlug(slug, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/entries/network/${slug}/slug`,
            params: params,
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Search Members
     * @param  {String}  slug of the network
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    queryMembersBySlug(slug, params = {}) {
        params = _.assign(params,{'top_entries': 'true'});
        return this.$http({
            method: 'GET',
            url: `/proxy/networks/users/${slug}/slug`,
            params: params,
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Search Jobs
     * @param  {String}  slug of the network
     * @param  {Object}  params Search params for query
     * @return {Promise}
     */
    queryJobsBySlug(slug, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/jobs/network/${slug}/slug`,
            params: params,
        }).then((response) => {
            return response.data;
        });
    }
}

NetworkService.$inject = ['$http'];
