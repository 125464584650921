import moment from 'moment';
import JobListTemplate from './job-list.component.html';
import './job-list.component.scss';

/**
 * @ngdoc controller
 * @name JobListController
 * @param {Object} JobList job to show
 * @param {Boolean} [show-description] whether to show or not the
 *  description
 * @param {String} [pf-source] source of the data, if 'es', will parse for
 *  Elastic Search data.
 * @module portfolium.component.JobList
 **/
class JobListController {
    constructor(
        $state,
        $window,
        $pfJobDetails,
        $pfEventTracker
    ) {
        this.$state = $state;
        this.$window = $window;
        this.$pfJobDetails = $pfJobDetails;
        this.$pfEventTracker = $pfEventTracker;
    }

    /**
    * @ngdoc method
    * @name isJobExpired
    * @description determine if passed in job is expired
    * @param job Job object
    * @return {Boolean}
    */
    isJobExpired(job) {
        return this.$pfJobDetails.isExpired(job, this.pfSource);
    }

    /*
    * @name getJobLocation
    * @description Get Location of the Job.
    * @param {Object} job
    * @return {String} Returns location of a job as {City, State}
    **/
    getJobLocation(job) {
        return this.$pfJobDetails.getJobLocation(job);
    }

    rowClicked({ slug }) {
        this.$state.go('job', {
            slug,
            traffic_source: this.trafficSource
        });
        if (this.pfClick) {
            this.pfClick();
        }
    }
}

JobListController.$inject = [
    '$state',
    '$window',
    '$pfJobDetails',
    '$pfEventTracker',
];

/**
 * @ngdoc component
 * @name jobListComponent
 * @module portfolium.component.jobList
 **/
export const JobListComponent = {
    bindings: {
        'jobs': '<',
        'showDescription': '<',
        'pfSource': '@',
        'limit': '<',
        'trafficSource': '<',
        'pfClick': '&',
    },
    template: JobListTemplate,
    controller: JobListController,
    controllerAs: '$ctrl'
};
