const UNSUPPORTED_CONVERT_MIMETYPES = [
    'application/x-iwork-keynote-sffkey',
    'application/x-iwork-keynote-sffnumbers',
    'application/x-iwork-pages-sffpages',
    'image/svg+xml',
];

const SUPPORTED_CONVERT_EXTENSIONS = [
    '.ai',
    '.bmp',
    '.doc',
    '.docx',
    '.html',
    '.odp',
    '.ods',
    '.odt',
    '.pdf',
    '.ppt',
    '.pptx',
    '.psd',
    '.tif',
    '.tiff',
    '.txt',
    '.webp',
    '.xls',
    '.xlsx',
];

export const FILESTACK_CONVERT = {
    unsupportedMimetypes: UNSUPPORTED_CONVERT_MIMETYPES,
    supportedExtensions: SUPPORTED_CONVERT_EXTENSIONS,
};
