export const UPDATE_PROFILE_HIGHLIGHTS = 'UPDATE_PROFILE_HIGHLIGHTS';
export const RESET_PROFILE_HIGHLIGHTS = 'RESET_PROFILE_HIGHLIGHTS';

export const updateProfileHighlights = (payload) => {
    return {
        type: UPDATE_PROFILE_HIGHLIGHTS,
        payload,
    };
};

export const resetProfileHighlights = () => {
    return {
        type: RESET_PROFILE_HIGHLIGHTS,
    };
};
