import angular from 'angular';

import JobCardModule from './job-card/job-card.module';
import JobApplyModule from './job-apply/job-apply.module';
import JobApplyCenterModule from './job-apply-center/job-apply-center.module';

import {JobSearchFiltersComponent} from './job-search-filters/job-search-filters.component';
import {JobResultComponent} from './job-result/job-result.component';
import {JobResultCardComponent} from './job-result-card/job-result-card.component';
import {JobRegisterComponent} from './job-register/job-register.component.js';
import {JobListComponent} from './job-list/job-list.component';
import {JobListCardComponent} from './job-list-card/job-list-card.component';
import {JobAvatarComponent} from './job-avatar/job-avatar.component';

const MODULE_NAME = 'portfolium.component.jobs';

(function () {
    angular.module(MODULE_NAME, [
            JobCardModule,
            JobApplyModule,
            JobApplyCenterModule
        ])
        // Components
        .component('pfJobSearchFilters', JobSearchFiltersComponent)
        .component('pfJobResult', JobResultComponent)
        .component('pfJobResultCard', JobResultCardComponent)
        .component('pfJobRegister', JobRegisterComponent)
        .component('pfJobList', JobListComponent)
        .component('pfJobListCard', JobListCardComponent)
        .component('pfJobAvatar', JobAvatarComponent)
}());

export default MODULE_NAME;
