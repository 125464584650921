import FieldHelpTextTemplate from './field-help-text.component.html';
import './field-help-text.component.scss';

/**
 * @ngdoc component
 * @name FieldErrorComponent
 * @module portfolium.components.forms
 **/
export const FieldHelpTextComponent = {
    transclude: true,
    bindings: {
        'class': '@pfClass',
    },
    template: FieldHelpTextTemplate,
}
