import angular from 'angular';

import {HeroImageComponent} from './hero-image.component';

const MODULE_NAME = 'portfolium.component.heroImage';

(function () {
    angular.module(MODULE_NAME,[])
    // Components
    .component('pfHeroImage', HeroImageComponent);

}());

export default MODULE_NAME;
