import EntryCardActionMenuTemplate from './entry-card-action-menu.component.html';

/**
 * @ngdoc component
 * @name pfEntryCardActionMenu
 * @module portfolium.component.entryCard
 */
export const EntryCardActionMenuComponent = {
    bindings: {
        onEdit: '&pfOnEdit',
        onDelete: '&pfOnDelete',
    },
    controller: () => {},
    controllerAs: '$ctrl',
    template: EntryCardActionMenuTemplate,
};
