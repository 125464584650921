import FieldErrorTemplate from './field-error.component.html';
import './field-error.component.scss';

/**
 * @ngdoc component
 * @name FieldErrorComponent
 * @module portfolium.components.forms
 **/
export const FieldErrorComponent = {
    transclude: true,
    bindings: {
        'class': '@pfClass',
    },
    template: FieldErrorTemplate,
}
