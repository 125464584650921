import AccordionTemplate from './accordion.component.html';
import './accordion.component.scss';

/**
 * @ngdoc component
 * @name AccordionController
 * @module portfolium.components.accordion
 */
class AccordionController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        // require unique id
        if (_.isNil(this.uniqueId)) {
            throw new Error('The unique-id attribute is required');
        }
        this.buttonId = `${this.uniqueId}_button`;
        // add disabled attr
        if (this.isDisabled) {
            this.setAttr('disabled', true, true);
        } else {
            this.triggerStateCallbacks();
        }
    }

    /**
     * Handle adding/removing an attribute based on a value
     * @param {String}  attr The attribute on the element
     * @param {Mixed}   value The value of the attribute
     * @param {Boolean} enabled If true, add the attribute; if false, remove it
     */
    setAttr(attr, value, enabled) {
        if (enabled) {
            this.$element.attr(attr, value);
        } else {
            this.$element.removeAttr(attr);
        }
    }

    /**
     * Handle the open/close toggle
     * @param {Object} $event The click/keypress event
     */
    toggle($event) {
        $event.stopPropagation();

        if (this.isDisabled) {
            return;
        }

        // the keyboard keypress event also registers a click event
        // so get rid of one of them
        if ($event.type === 'keypress') {
            return;
        }

        this.isOpen = !this.isOpen;

        // fire callbacks
        this.triggerStateCallbacks();
    }

    triggerStateCallbacks() {
        if (this.isOpen) {
            this.onOpen();
        } else {
            this.onClose();
        }
    }
}

AccordionController.$inject = [
    '$element',
];

/**
 * @ngdoc component
 * @name AccordionComponent
 * @module portfolium.components.accordion
 **/
export const AccordionComponent = {
    bindings: {
        uniqueId: '@',
        pfTitle: '@',
        onOpen: '&',
        onClose: '&',
        isOpen: '<',
        isMuted: '<',
        isDisabled: '<',
    },
    controller: AccordionController,
    controllerAs: '$ctrl',
    template: AccordionTemplate,
    transclude: true,
};
