import angular from 'angular';
import $script from 'scriptjs';

export const googlePlacesAutocompleteDirective = ($window) => {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            onPlaceChange: '&?pfOnGooglePlacesPlaceChange',
        },
        link: function postLink(scope, element, attrs, model) {
            $script.ready('googleMaps', () => {
                let options = {
                    types: ['(cities)'],
                    componentRestrictions: {country:'us'}
                };

                function removeElementsByClass(className) {
                    let elements = document.getElementsByClassName(className);
                    while (elements.length > 0) {
                        elements[0].parentNode.removeChild(elements[0]);
                    }
                }

                let autocomplete = new google.maps.places.Autocomplete(element[0], options);
                let input = angular.element('#location')[0];

                google.maps.event.addDomListener(input, 'keydown', e => {
                    if (e.keyCode === 13) {
                        const googleDOMNodes = document.getElementsByClassName('pac-container');

                        if (googleDOMNodes.length > 0) {
                            e.preventDefault();
                        }
                        removeElementsByClass('pac-container');
                    }
                });

                google.maps.event.addListener(autocomplete, 'place_changed', () => {
                    let place = autocomplete.getPlace();
                    let location = place.geometry && place.geometry.location ? {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    } : {};

                    if (scope.onPlaceChange) {
                        scope.onPlaceChange({place: location});
                    }

                    model.$setViewValue(place.formatted_address);

                    let lat_input = angular.element(':hidden#place_lat');
                    let lon_input = angular.element(':hidden#place_lon');

                    if (lat_input) {
                        angular.element(':hidden#place_lat').val(location.lat);
                    }
                    if (lon_input) {
                        angular.element(':hidden#place_lon').val(location.lng);
                    }
                });

                scope.$on('$destroy', function() {
                    google.maps.event.clearInstanceListeners(element[0]);
                });
            });
        }
    };
};

googlePlacesAutocompleteDirective.$inject = ['$window'];
