import angular from 'angular';
// TODO: Move this service to the core module since it isn't tied specifically to the entry card component
/**
 * @ngdoc service
 * @name EntryCardActionsService
 * @module portfolium.component.EntryCardActionsService
 * @description Service for Entry Cards.
 *
 */
class EntryCardActionsService
{
   constructor(
       $pfToast,
       $mdDialog,
       $http,
       $q,
       $pfEntrySettings,
       $pfUser,
       $state,
       $filter,
       PF_ENTRY_VISIBILITY_TYPES,
   ) {
      this.$pfToast = $pfToast;
      this.$mdDialog = $mdDialog;
      this.$http = $http;
      this.$q = $q;
      this.$pfEntrySettings = $pfEntrySettings;
      this.$pfUser = $pfUser;
      this.$state = $state;
      this.$filter = $filter;
      this.PF_ENTRY_VISIBILITY_TYPES = PF_ENTRY_VISIBILITY_TYPES;
   }

    /**
    * @ngdoc method
    * @name EntryCardActionsService#deleteEntry
    * @methodOf EntryCardActionsService
    * @description calls the confirm modal if it is allow to delete,
    * calls the alert modal if it is not allow to delete.
    * @param  {Object} $event
    * @param  {Object} entry entry to be deleted
    * @return {Promise}
    **/
    deleteEntry($event, entry) {
        return this.showDeleteWarning($event, entry.id);
    }

    /**
    * @ngdoc method
    * @name EntryCardActionsService#showDeleteWarning
    * @methodOf EntryCardActionsService
    * @description opens the confirm modal
    * @param  {Object} $event
    * @return {Promise}
    **/
    showDeleteWarning($event, entryId) {
        const title = this.$filter('i18n')('Delete Project');
        const textContent = this.$filter('i18n')('Are you sure you want to delete your project?');
        const ariaLabel = this.$filter('i18n')('Delete Project');
        const ok = this.$filter('i18n')('Yes, Delete');
        const cancel = this.$filter('i18n')('Cancel');

        // Configure confirm modal
        let confirm = this.$mdDialog.confirm()
           .parent(angular.element(document.body))
           .title(title)
           .cancel(cancel)
           .textContent(textContent)
           .ariaLabel(ariaLabel)
           .targetEvent($event)
           .ok(ok);

        // Show confirm modal
        return this.$mdDialog.show(confirm).then(() => {
            // User confirmed, delete entry
            return this.deleteEntryRest(entryId).then(() => {
                this.$pfToast.success('Project deleted.');
                // on mobile we need to redirect
                if (this.$pfUser.isMobile()) {
                    return this.redirectToProfile();
                }
            }, () => {
               this.$pfToast.error('Something went wrong, please try again.');
            });
        });
    }

    /**
     * Redirect to current user's profile page
     * @return {Promise}
     */
    redirectToProfile() {
        let {username} = this.$pfUser.getUser();

        return this.$state.go('profile.portfolio', {
            username: username,
        });
    }

    /**
    * @ngdoc method
    * @name EntryCardActionsService#deleteEntryRest
    * @methodOf EntryCardActionsService
    * @description calls the API to delete the entry
    * @param  {Int} entryId id of the entry
    * @return {Promise}
    **/
    deleteEntryRest(entryId) {
        return this.$http({
            method: 'DELETE',
            url: '/proxy/entries/entry/' + entryId
        });
    }
}

EntryCardActionsService.$inject = [
    '$pfToast',
    '$mdDialog',
    '$http',
    '$q',
    '$pfEntrySettings',
    '$pfUser',
    '$state',
    '$filter',
    'PF_ENTRY_VISIBILITY_TYPES',
];

export default EntryCardActionsService;
