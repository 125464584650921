function requireAuthDirective(
    $location,
    $state,
    $pfUser,
    $window,
    PF_COOKIES,
    $pfEnv,
) {
    return {
        restrict: 'A',
        priority: -100,
        link: function(scope, elem, attrs) {
            let user = $pfUser.getUser();

            if (user) {
                // Do nothing if user is already logged in
                return;
            }

            elem.on('click', $event => {
                // Prevent default click event
                $event.preventDefault();
                $event.stopImmediatePropagation();

                // redirect IE users to static login page
                if (_.includes($window.navigator.userAgent, 'Trident')) {
                    $window.location.href = '/login';

                    return;
                }

                // sanity check if the onboarding bypass flag was set
                if (_.has(attrs, 'pfObBypass')) {
                    // create the onboarding bypass session cookie
                    Cookies.set(PF_COOKIES.ONBOARDING_BYPASS, true, {
                        expires: 7,
                        path: '/',
                        domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                        secure: true,
                        samesite: 'None',
                    });
                }
                // sanity check if the keep action flag was set
                if (_.has(attrs, 'pfKeepAction')) {
                    // create the run action session cookie
                    Cookies.set(PF_COOKIES.RUN_ACTION, attrs.href, {
                        path: '/',
                        domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                        secure: true,
                        samesite: 'None',
                    });
                }
                // sanity check if the next action flag was set
                if (_.has(attrs, 'pfNextAction')) {
                    // create the run action session cookie
                    Cookies.set(PF_COOKIES.NEXT_ACTION, attrs.pfNextAction, {
                        path: '/',
                        domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                        secure: true,
                        samesite: 'None',
                    });
                }
                // sanity check if the post onboarding destination was set
                if (_.has(attrs, 'pfOnboardDestination')) {
                    // create the run action session cookie
                    Cookies.set(
                        PF_COOKIES.ONBOARDING_DESTINATION,
                        $pfEnv.url + $location.path(),
                        {
                            path: '/',
                            domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                            secure: true,
                            samesite: 'None',
                        },
                    );
                }
                // sanity check if the keep action flag was set
                if (_.has(attrs, 'pfOnboardSkip')) {
                    // create the run action session cookie
                    Cookies.set(PF_COOKIES.ONBOARDING_SKIP_FULL, true, {
                        expires: 7,
                        path: '/',
                        domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                        secure: true,
                        samesite: 'None',
                    });
                }

                /** @todo: implement return url (with cookie or just set on service)
                    // create the return URL session cookie if someone uses FB or LI to login
                    $.cookie('return-url', $location.path(), {path: '/', domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN')});
                */

                const stateName = 'loginModal.identity';
                const modalAttrs = {
                    title: attrs.pfAuthTitle,
                    verb: attrs.pfAuthVerb,
                };

                // Launch simple sign in modal
                $state.go(stateName, {
                    identity: null,
                    modalAttrs: modalAttrs,
                });
            });
        },
    };
}

requireAuthDirective.$inject = [
    '$location',
    '$state',
    '$pfUser',
    '$window',
    'PF_COOKIES',
    '$pfEnv',
];

export default requireAuthDirective;
