import angular from 'angular';
import './job-register.component.scss';
import JobRegisterTemplate from './job-register.html';

/**
 * @ngdoc component
 * @name pfClipboard
 * @module portfolium.component.clipboard
 * @description
 * On click copy content to the clipboard.
 * @param {String} [pf-copy-text] text to copy on click
 *
 */
class JobRegisterController {
    constructor(
        $pfToast,
        $pfAuth,
        $pfSignUp,
        $location,
        $window,
        $pfEventTracker,
        $filter,
        $pfCookies,
        $pfEnv,
        PF_COOKIES
    ) {
        this.$pfToast = $pfToast;
        this.$pfAuth = $pfAuth;
        this.$pfSignUp = $pfSignUp;
        this.$location = $location;
        this.$window = $window;
        this.$pfEventTracker = $pfEventTracker;
        this.$pfFilter = $filter;
        this.$pfCookies = $pfCookies;
        this.$pfEnv = $pfEnv;
        this.PF_COOKIES = PF_COOKIES;
        this.data = {};
    }

    $onInit(){
        let newUser = this.$location.search();
        newUser = this._decodeURIObject(newUser);
        if (newUser.campaign_id) {
            this.$pfEventTracker.track('Creating Job Post', newUser);
        }
        this.data = newUser;
    }

   /**
    * Submit the form data, then close the modal
    * @return {Promise}
    */
    submit() {
        // verify form data
        if (!this.isValid()) {
            return;
        }

        this.loading = true;

        return this.$pfSignUp.employersJoinSimple(this.data).then((user) => {
            return this.$pfAuth.createSession(user.data).then(() => {
                this.$window.location.assign('/job/add/first');
            });
        }, () => {
            // show error toast message
            this.$pfToast.error('Something went wrong, please try again.');
        }).finally(() => {
            this.loading = false;
        });
    }

    /**
    * Checks if the form is valid
    * @return {Boolean}
    */
    isValid() {
        if (!this.form) {
            return true;
        }

        return this.form.$valid === true;
    }

    /**
    * Decodes an Object inputs URIs
    * @param {Object} object The Object to be decoded
    * @return {Object}
    */
    _decodeURIObject(object) {
        let res = {};
        angular.forEach(object, (value, key)=>{
            res[key] = decodeURIComponent(value);
        });
        return res;
    }

    /**
     * Set a session-based cookie to watch for on successful account authentication
     */
    putCookie() {
        this.$pfCookies.put(
            this.PF_COOKIES.EMPLOYER_LOGIN_REDIRECT,
            '/job/add/first',
            {
                path: '/',
                domain: this.$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
            }
        );
    }
}


JobRegisterController.$inject = [
    '$pfToast',
    '$pfAuth',
    '$pfSignUp',
    '$location',
    '$window',
    '$pfEventTracker',
    '$filter',
    '$pfCookies',
    '$pfEnv',
    'PF_COOKIES',
];

export const JobRegisterComponent = {
    template: JobRegisterTemplate,
    controller: JobRegisterController,
    controllerAs: 'jobCtrl',
    bindToController: true
};
