import EntryOptionsTemplate from './entry-options.component.html';

 /**
  * @ngdoc component
  * @name EntryOptions
  * @description
  */
class EntryOptionsController {
    constructor(
        $pfAuth,
        $pfEntryCardActions,
        $state,
        $pfUser,
        $pfEntryEditor,
        $pfEntryDrafts,
        $pfAssignmentsUtilities,
    ) {
        this.$pfAuth = $pfAuth;
        this.$pfEntryCardActions = $pfEntryCardActions;
        this.$state = $state;
        this.$pfEntryEditor = $pfEntryEditor;
        this.$pfEntryDrafts = $pfEntryDrafts;
        this.$pfAssignmentsUtilities = $pfAssignmentsUtilities;
        this.currentUser = $pfUser.getUser() || {};
    }

    get isOwner() {
        // drafts are always pulled using current user
        if (this.isDraft) {
            return true;
        }

        // check for needed data
        if (this.entry && this.entry.profile) {
            // check if the authed user is the owner of the entry
            return this.$pfAuth.isAuthenticatedUser(this.entry.profile.id);
        }

        // default the return value
        return false;
    }

    isAssignment() {
        // TODO figure out how assignment data is returned on drafts
        if (this.isDraft) {
            return false;
        }

        return this.entry.faculty_assigned === '1';
    }

    /**
     * Looks at assignment status to determine if assignment has been graded
     * @return {boolean}
     */
    isGradedAssignment() {
        // we just need the status of the assignment
        const isCompleted = this.$pfAssignmentsUtilities
            .isCompleted({status: this.entry.assignment_status});
        return this.isAssignment() && isCompleted;
    }

    /**
     * Should we show the delete button
     * @return {boolean}
     */
    showDeleteButton() {
        // NOT an assignment
        if (!this.isAssignment()) {
            return true;
        }

        if (this.isGradedAssignment()) {
            return true;
        }

        return false;
    }

     /**
      * Handle delete entry button click
      * @param  {Event} $event Click event from ng-click directive
      */
    deleteEntry($event) {
        // check for draft
        if (this.isDraft) {
            return this.$pfEntryDrafts
                .deleteDraftConfirm($event, this.entry.id)
                .then(() => {
                    if (this.onDeleteEvent) {
                        this.onDeleteEvent();
                    }
                });
        }

        return this.$pfEntryCardActions
            .deleteEntry($event, this.entry)
            .then(() => {
                if (this.onDeleteEvent) {
                    this.onDeleteEvent();
                }
            });
     }

    /**
     * Redirect to the entry editor
     * @return {Promise}
     */
    editEntry() {
        // Check for draft
        if (this.isDraft) {
            return this.$pfEntryEditor.editDraft(this.entry.id, {
                source: 'entryOptionsMenu',
            });
        }

        return this.$pfEntryEditor.editEntry(this.entry.id, {
            redirectRoute: {
                stateName: 'profile.portfolio',
                params: { username: this.currentUser.username },
            },
            source: 'entryOptionsMenu',
        });
    }
}

EntryOptionsController.$inject = [
    '$pfAuth',
    '$pfEntryCardActions',
    '$state',
    '$pfUser',
    '$pfEntryEditor',
    '$pfEntryDrafts',
    '$pfAssignmentsUtilities',
];

export const EntryOptionsComponent = {
    bindings: {
        entry: '<',
        isDraft: '<',
        onDeleteEvent: '&pfOnDelete'
    },
    template: EntryOptionsTemplate,
    controller: EntryOptionsController,
    controllerAs: '$ctrl',
};
