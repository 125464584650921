import { UAParser } from 'ua-parser-js';
import { AnalyticsTracker } from './analytics-tracker';

export class EventTrackerService {
    constructor(
        $window,
        $location,
        $http,
        $pfUser,
        $pfAnonymous,
        $document,
        $pfCookies,
    ) {
        this.$location = $location;
        this.$pfAnonymous = $pfAnonymous;
        this.$pfUser = $pfUser;
        this.$http = $http;
        this.$window = $window;
        this.$document = $document;
        this.UAParser = new UAParser();
        this.tracker = null;
        this.isAnalyticalCookiesEnable = $pfCookies.isAnalyticalCookiesEnable();
        // sanity check for analytical cookies enable
        if (this.isAnalyticalCookiesEnable) {
            // get the analytics tracker
            this.tracker = new AnalyticsTracker();
        }
    }

    /**
     * The track method lets you record any actions your users perform.
     * @param  {String}   event      The name of the event you're tracking.
     * @param  {Object}   properties A dictionary of properties for the event.
     * @param  {Object}   options    A dictionary options
     * @param  {Function} callback   A callback function that gets called after a short timeout
     */
    track(event, properties, options, callback) {
        // sanity check for analytical cookies disable
        if (!this.isAnalyticalCookiesEnable) {
            return;
        }
        this.tracker.track(
            event,
            properties,
            _.assign({}, options, {
                anonymousId: this.$pfAnonymous.getAnonymousId(),
            }),
            callback
        );
    }

    /**
     * Track an event on the server side to ensure accuracy.
     * @param  {String}   event      The name of the event you're tracking.
     * @param  {Object}   properties A dictionary of properties for the event.
     * @return {Promise}             Resolved after event has been fired
     */
    trackServer(event = '', properties = {}) {
        // sanity check for analytical cookies disable
        if (!this.isAnalyticalCookiesEnable) {
            return;
        }
        const {
            browser: {
                name: browserName,
                version: browserVersion,
            },
            device: {
                model,
                type,
                vendor,
            },
            os: {
                name: osName,
                version: osVersion,
            },
            ua: userAgent,
        } = this.UAParser.getResult();

        const {
            location: {
                search,
                pathname: path,
                href: url,
            },
            innerWidth: width,
            innerHeight: height,
            devicePixelRatio: density,
        } = this.$window;

        const {
            referrer,
            title,
        } = this.$document[0];

        const context = {
            browser: {
                name: browserName,
                version: browserVersion,
            },
            device: {
                model,
                type,
                vendor,
            },
            os: {
                name: osName,
                version: osVersion,
            },
            page: {
                path,
                referrer,
                search,
                title,
                url,
            },
            screen: {
                width,
                height,
                density,
            },
            userAgent,
        };

        const updatedProperties = _.assign({},
            properties,
            {
                referrer,
            },
        );
        return this.$http({
            method: 'POST',
            url: '/proxy/events/track',
            data: {
                event,
                properties: updatedProperties,
                context,
            },
        });
    }

    /**
     * The page method lets you record page views on your website, along with optional extra
     * information about the page being viewed.
     * @param  {String}   category   The category of the page
     * @param  {String}   name       The name of the of the page
     * @param  {Object}   properties A dictionary of properties of the page
     * @param  {Object}   options    A dictionary options
     * @param  {Function} callback   A callback function that gets called after a short timeout
     */
    page(category, name, properties, options, callback) {
        // sanity check for analytical cookies disable
        if (!this.isAnalyticalCookiesEnable) {
            return;
        }
        this.tracker.page(
            category,
            name,
            properties,
            _.assign({}, options, {
                anonymousId: this.$pfAnonymous.getAnonymousId(),
            }),
            callback
        );
    }

    /**
     * The identify method is how you tie one of your users and their actions to a
     * recognizable userId and traits.
     * @param  {String}   userId   The database ID for the user
     * @param  {Object}   traits   A dictionary of traits you know about the user
     * @param  {Object}   options  A dictionary options,
     * @param  {Function} callback A callback function that gets called after a short timeout
     */
    identify(userId, traits, options, callback) {
        // sanity check for analytical cookies disable
        if (!this.isAnalyticalCookiesEnable) {
            return;
        }
        this.tracker.identify(
            userId,
            traits,
            _.assign({}, options, {
                anonymousId: this.$pfAnonymous.getAnonymousId(),
            }),
            callback
        );
    }

    /**
     * Reset the ID, anonymous ID, and clear all user traits stored in
     * cookies/localStorage for the tracker
     */
    reset() {
        // sanity check for analytical cookies disable
        if (!this.isAnalyticalCookiesEnable) {
            return;
        }
        // Call reset method on client library
        this.tracker.reset();

        // Manually reset integrations if needed
        this.tracker.ready(() => {
            // non-op
        });
    }

    /**
     * Store a new anonymous ID for use by the tracking library. If an anonymous
     * ID already exists, a new ID will not be created.
     */
    updateAnonymousId() {
        this.$pfAnonymous.updateAnonymousId();
    }
}

EventTrackerService.$inject = [
    '$window',
    '$location',
    '$http',
    '$pfUser',
    '$pfAnonymous',
    '$document',
    '$pfCookies',
];
