export class TasksService {
    constructor($http, $state) {
        this.$http = $http;
        this.$state = $state;
    }

    /**
     * Fetch user assigned pathways
     * @return {Promise}
     */
    getUserPathways() {
        return this.$http({
            method: 'GET',
            url: `/proxy/pathways/summary`,
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
                switch (err.status) {
                    case 403:
                        this.$state.go('403');
                        break;
                    case 404:
                        this.$state.go('404');
                        break;
                    default:
                        this.$state.go('404');
                        break;
                }
        });
    }

    /**
     * Fetch user assigned courses
     * @return {Promise}
     */
    getUserCourses() {
        return this.$http({
            method: 'GET',
            url: `/proxy/courses/me`,
        })
        .then((response) => {
            let courses = response.data;
            courses.forEach((course) => {
                let count = 0;
                course.assignments.forEach((assignment) => {
                    if (parseInt(assignment.status) > 3) {
                        count += 1;
                    }
                });
                course.completed = (count / course.assignments.length) === 1;
            });
            return courses;
        });
    }
}

TasksService.$inject = ['$http', '$state'];
