export const CHALLENGE_DETAIL_SET_CHALLENGE  = 'CHALLENGE_DETAIL_SET_CHALLENGE';
export const CHALLENGE_DETAIL_SET_WINNERS  = 'CHALLENGE_DETAIL_SET_WINNERS';
export const CHALLENGE_DETAIL_UPDATE_SUBMISSION_COUNT  = 'CHALLENGE_DETAIL_UPDATE_SUBMISSION_COUNT';
export const CHALLENGE_DETAIL_SET_USER_JOINED  = 'CHALLENGE_DETAIL_SET_USER_JOINED';
export const CHALLENGE_DETAIL_SET_USER_SUBMISSION  = 'CHALLENGE_DETAIL_SET_USER_SUBMISSION';
export const CHALLENGE_RESET  = 'CHALLENGE_RESET';

export const challengeDetailActionTypes = {
    'PAGE_INIT': 'CHALLENGE_DETAIL_PAGE_INIT',
    'PAGE_NEXT': 'CHALLENGE_DETAIL_PAGE_NEXT',
    'PAGE_REQUEST': 'CHALLENGE_DETAIL_PAGE_REQUEST',
    'PAGE_SUCCESS': 'CHALLENGE_DETAIL_PAGE_SUCCESS',
    'PAGE_ERROR': 'CHALLENGE_DETAIL_PAGE_ERROR',
    'TOGGLE_SELECTION': 'CHALLENGE_DETAIL_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'CHALLENGE_DETAIL_CLEAR_SELECTION',
    'TOGGLE_ALL': 'CHALLENGE_DETAIL_TOGGLE_ALL',
    'ADD_FILTER': 'CHALLENGE_DETAIL_ADD_FILTER',
    'REMOVE_FILTER': 'CHALLENGE_DETAIL_REMOVE_FILTER',
};

/**
 * Set the current challenge
 * @param {Object} challenge
 */
export const setChallenge = (challenge) => {
    return {
        type: CHALLENGE_DETAIL_SET_CHALLENGE,
        payload: {
            challenge,
        }
    };
};

/**
 * Set the challenge winners
 * @param {Array} winners Winners of the challenge
 */
export const setWinners = (winners) => {
    return {
        type: CHALLENGE_DETAIL_SET_WINNERS,
        payload: {
            winners,
        }
    };
};

/**
 * Updates submission count for the current challenge
 * @param {Integer} submissionCount number of submissions to the current challenge
 */
export const updateSubmissionCount = (submissionCount) => {
    return {
        type: CHALLENGE_DETAIL_UPDATE_SUBMISSION_COUNT,
        payload: {
            submissionCount,
        }
    };
};

/**
 * Update users submission for the current challenge
 * @param {Object} userSubmission authed users submission to the current challenge
 */
export const setUserSubmission = (userSubmission) => {
    return {
        type: CHALLENGE_DETAIL_SET_USER_SUBMISSION,
        payload: {
            userSubmission,
        }
    };
};

/**
 * Updates current users status to "Joined Challenge"
 */
export const setUserJoined = () => {
    return {
        type: CHALLENGE_DETAIL_SET_USER_JOINED,
    };
};

/**
 * Reset challenge details
 */
export const resetChallenge = () => {
    return {
        type: CHALLENGE_RESET,
    };
};
