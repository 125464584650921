import angular from 'angular';

import {initializeNotificationCenter} from './js/config/notification-center.config';
import {templateCache} from './js/config/template-cache.config';

import {NotificationCenterPanelMessagesComponent} from './js/component/notification-center-panel-messages.component';
import {NotificationCenterPanelNotificationsComponent} from './js/component/notification-center-panel-notifications.component';
import {NotificationCenterPanelComponent} from './js/component/notification-center-panel.component';
import {NotificationListComponent} from './js/component/notification-list.component';
import {toggleNotificationCenterDirective} from './js/directive/toggle-notification-center.directive';
import {notificationCenterJewelDirective} from './js/directive/notification-center-jewel.directive';
import {NotificationCenterService} from './js/service/notification-center.service';
import {NotificationCenterPanelService} from './js/service/notification-center-panel.service';

import './index.scss';

const MODULE_NAME = 'portfolium.component.notificationCenter';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfNotificationCenterPanelMessages', NotificationCenterPanelMessagesComponent)
        .component('pfNotificationCenterPanelNotifications', NotificationCenterPanelNotificationsComponent)
        .component('pfNotificationCenterPanel', NotificationCenterPanelComponent)
        .component('pfNotificationList', NotificationListComponent)
        // Directives
        .directive('pfToggleNotificationCenter', toggleNotificationCenterDirective)
        .directive('pfNotificationCenterJewel', notificationCenterJewelDirective)
        // Services
        .service('$pfNotificationCenter', NotificationCenterService)
        .service('$pfNotificationCenterPanel', NotificationCenterPanelService)
        // Run
        .run(templateCache)
        .run(initializeNotificationCenter);
}());

export default MODULE_NAME;
