import LanguagePickerTemplate from './language-picker.component.html';

class LanguagePickerController {
    constructor($pfToast, $pfSettings){
        this.$pfToast = $pfToast;
        this.$pfSettings = $pfSettings;
    }

    $onInit() {
        this.allLangs = window.ZAB.settings.availableTranslations;
        this.showLangs = this.allLangs.slice(0, 3);
    }

    pickLanguage(language) {
        this.$pfSettings.updateLanguage(language.code).then(function(data) {
            if (window.ZAB && window.ZAB.setLanguage(language.code)) {
                setTimeout(function() {
                    window.location.reload();
                }, 100);
            } else {
                this.$pfToast.error('Something went wrong, please try again.');
            }
        });
    }
}

LanguagePickerController.$inject = ['$pfToast', '$pfSettings'];

export const LanguagePickerComponent = {
    template: LanguagePickerTemplate,
    controller: LanguagePickerController,
    controllerAs: '$ctrl'
};
