class GameEngineActionService
{
    constructor($injector, $q, actions) {
        this.$q = $q;
        this.actions = _.map(actions, (action) => {
            return $injector.instantiate(action);
        });
    }

    /**
     * @description Retrieve up an action by name
     * @param  {String} actionName Action name value
     * @return {Object}            Action object
     */
    getActionByName(actionName) {
        let action = _.find(this.actions, {name: actionName});
        // return a clone so the requester can manage the actions state
        return _.clone(action);
    }
}

GameEngineActionService.$inject = ['$injector', '$q'];

class GameEngineActionProvider
{
    constructor($injector) {
        this.$injector = $injector;
        this.actions = [];
    }

    /**
     * @description Register an action in the actions repository
     * @param  {Object}                   action Action object
     * @return {GameEngineActionProvider}
     */
    registerAction(action) {
        this.actions.push(action);

        return this;
    }

    get $get() {
        return ['$injector', '$q', function($injector, $q) {
           return new GameEngineActionService($injector, $q, this.actions);
        }];
    }
}

GameEngineActionProvider.$inject = ['$injector'];

export default GameEngineActionProvider;
