import angular from 'angular';

import {PaginationControlComponent} from './pagination-control.component';

import './pagination-control.component.scss';

const MODULE_NAME = 'portfolium.component.paginationControl';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfPaginationControl', PaginationControlComponent);
}());

export default MODULE_NAME;
