export class SecurityClearanceService
{
    constructor($http) {
        this.$http = $http;
    }

    /**
     * @description Get User Clearances for current user
     * @return {Promise}
     */
    getUserClearances() {
        return this.$http({
            method: 'GET',
            url: `/proxy/system/clearances`,
        })
        .then((response) => {
            return this._clearanceToBool(response.data);
        });
    }

    /**
     * @description Add a Clearance for an user
     * @param  {String}  clearanceId Id of the clearance
     * @return {Promise}
     */
    setUserClearance(clearanceId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/users/clearance/${clearanceId}`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Delete a clearance for an user
     * @param  {String}  clearanceId Id of the clearance
     * @return {Promise}
     */
    deleteUserClearance(clearanceId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/users/clearance/${clearanceId}`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Transform the value of the clearances to boolean
     * @param  {Array<Object>}  clearances
     * @return {Object}
     */
    _clearanceToBool(clearances) {
        _.forEach(clearances, (value, key) => {
            clearances[key].granted = (clearances[key].granted === '1');
        });
        return clearances;
    }
}

SecurityClearanceService.$inject = ['$http'];
