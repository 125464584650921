import StatusWidgetTemplate from './status-widget.component.html';
import './status-widget.component.scss';

export class StatusWidgetController {
    constructor($timeout) {
        this.$timeout = $timeout;
        this.refreshingValues = false;
    }

    $onChanges({ pfLoading }) {
        // sanity check
        if (this.pfDynamicValues) {
            // check if pfLoading is changing, we dont care here
            if (!_.isNil(pfLoading)) {
                // stop it
                return;
            }

            /** THIS IS A FIX TO ALLOW USE OF ONE TIME
                BINDINGS AND UPDATE THE VALUE  **/

            // make refreshingValues true to remove
            this.refreshingValues = true;
            // need this
            this.$timeout(() => {
                // put it back, no one will notice
                this.refreshingValues = false;
            }, 0)
        }
    }
}

StatusWidgetController.$inject = [
    '$timeout',
];

/**
 * @ngdoc component
 * @name StatusWidgetComponent
 * @module portfolium.status-widget
 **/
export const StatusWidgetComponent = {
    controller: StatusWidgetController,
    template: StatusWidgetTemplate,
    bindings: {
        pfClass: '<',
        pfIcon: '<',
        pfLabel: '<',
        pfLabelLink: '<',
        pfLabelLinkText: '<',
        pfValue: '<',
        pfLoading: '<',
        pfDynamicValues: '<',
    },
};
