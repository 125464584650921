import {combineReducers} from 'redux';
import {abouts} from './abouts';
import {aboutSections} from './about-sections';
import {profileUser} from './profile-user';
import {userBadges} from './user-badges';
import {profileHighlights} from './profile-highlights';
import {profileDrafts} from '../../profile-drafts/profile-drafts.component.reducer';

export const profile = combineReducers({
    abouts,
    aboutSections,
    profileUser,
    userBadges,
    profileHighlights,
    profileDrafts,
});
