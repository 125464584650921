import DropZoneTemplate from './drop-zone.component.html';
import './drop-zone.component.scss';

/**
 * @ngdoc controller
 * @name DropZoneController
 * @module portfolium.components.dropZone
 */
class DropZoneController {
    constructor($scope, $pfToast, $q) {
        this.$scope = $scope;
        this.$pfToast = $pfToast;
        this.$q = $q;
        this.dropZoneActive = false;
    }

    $postLink() {
        this.targetElement
            // add dragover and drageenter to body and reference
            // into the pfDropZone namespace
            .on('dragover.pfDropZone dragenter.pfDropZone', () => {
                if (this.targetElement.hasClass('disable-drag')) {
                    return;
                }
                if (this.dropZoneActive === false) {
                    this.$scope.$apply(() => {
                        this.dropZoneActive = true;
                    });
                }
            })
            // add dragleave, dragend and drop to body and reference
            // into the pfDropZone namespace
            .on('dragleave.pfDropZone dragend.pfDropZone drop.pfDropZone', () => {
                if (this.targetElement.hasClass('disable-drag')) {
                    return;
                }
                this.$scope.$apply(() => {
                    this.dropZoneActive = false;
                });
            })
            // add  drop to body and reference
            // into the pfDropZone namespace
            .on('drop.pfDropZone', (e) => {
                if (this.targetElement.hasClass('disable-drag')) {
                    return;
                }
                // get the files uploaded list
                const droppedFiles = e.originalEvent.dataTransfer.files;

                // call the before upload callback
                const promise = this.preFlight({files: droppedFiles});

                // resolve the value or promise from the preFlight callback
                this.$q.resolve(promise).then(() => {
                    if (!this.multipleFiles) {
                        if (droppedFiles.length > 1) {
                            this.$pfToast.error('You can only drop one file');
                            return;
                        }

                        const file = droppedFiles[0];
                        this.onDrop({file});
                        return;
                    }

                    // iterate over files
                    _.forEach(droppedFiles, file => {
                        // send the file in the callback
                        this.onDrop({file});
                    });
                }, (err) => {
                    // TODO - handle rejected upload
                });
            });
    }

    $onDestroy() {
        // remove the bindings for the namespace pfDropZone only
        this.targetElement.off(`
            dragover.pfDropZone
            dragenter.pfDropZone
            dragleave.pfDropZone
            dragend.pfDropZone
            drop.pfDropZone`);
    }
}

DropZoneController.$inject = [
    '$scope',
    '$pfToast',
    '$q',
];

/**
 * @ngdoc component
 * @name DropZoneComponent
 * @module portfolium.components.dropZone
 * @description Creates bindings to the body to handle the onDrop
 *              functionality and styling.
 * @callback {function} preFlight Handle to check the files before
 *           doing anything else with them, if this is a promise, a
 *           rejection will prevent any further actions
 * @callback {function} onDrop Handle for the
 *           succeded file drop
 * @param {Object}  preFlight.files ALL uploaded files
 * @param {Object}  onDrop.file uploaded file information
 * @param {Boolean} multipleFiles files flag
 * @param {Object}  targetElement the element we targeted
 * @param {Boolean} fullScreenMode do we enable fullscreen
 *
 **/
export const DropZoneComponent = {
    bindings: {
        preFlight: '&',
        onDrop: '&',
        multipleFiles: '<',
        targetElement: '<',
        fullScreenMode: '<',
    },
    template: DropZoneTemplate,
    controller: DropZoneController,
    controllerAs: '$ctrl'
};
