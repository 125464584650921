class AddEducationAction
{
    constructor($pfGameAction) {
        this.$pfGameAction = $pfGameAction;
        this.name = 'add_an_education';
    }

    get label() {
        let label = 'Add another school';
        let currentLevel = this.$pfGameAction.getCurrentLevel() || {};

        if (currentLevel.name === 'beginner') {
            label = 'Add your school';
        }

        // always show this label if the type is Has_many
        if (this.type === 'Has_many') {
            return 'Add another school';
        }

        return label;
    }


    execute(next) {
        return this.$pfGameAction.openPanel('education', next);
    }
}

AddEducationAction.$inject = ['$pfGameAction'];

export default AddEducationAction;
