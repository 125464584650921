function socialLinkFilter() {
    let urls = {
        'facebook': 'https://www.facebook.com/',
        'twitter': 'https://twitter.com/',
        'instagram': 'http://instagram.com/',
        'linkedin': 'https://www.linkedin.com/',
        'pinterest': 'https://www.pinterest.com/',
        'github': 'https://github.com/'
    };

    return function (val, type) {
        return urls[type] + val;
    };
}

export default socialLinkFilter;
