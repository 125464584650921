export const PATHWAY_BADGE_FETCH = 'PATHWAY_BADGE_FETCH';
export const PATHWAY_BADGE_FETCH_SUCCESS = 'PATHWAY_BADGE_FETCH_SUCCESS';
export const PATHWAY_BADGE_RESET = 'PATHWAY_BADGE_RESET';

/**
 * Initiates fetch badge sets fetching to true
 * */
export const fetchBadge = () => {
    return {
        type: PATHWAY_BADGE_FETCH,
    };
};

/**
 * Assigns and returns fetched badge
 * */
export const onBadgeFetchSuccess = (badge) => {
    return {
        type: PATHWAY_BADGE_FETCH_SUCCESS,
        payload: {
            badge,
        },
    };
};

/**
 * Resets Badge
 * */
export const resetBadge = () => {
    return {
        type: PATHWAY_BADGE_RESET,
    };
};
