import angular from 'angular';

import { TasksService } from 'core/services/tasks.service';
import CoreConfigModule from './config/core-config.module';
import CoreServicesModule from './services/core-services.module';
import CoreFiltersModule from './filters/core-filters.module';

import { textStyleDirective } from './directives/text-style.directive';

import { AddressService } from './services/address.service';
import { AppSwitcherAuthService } from './services/app-switcher.service';
import { AssignmentsService } from './services/assignments.service';
import { AssignmentsUtilitiesService } from './services/assignments-utilities.service';
import { AttachmentViewerService } from './services/attachment-viewer.service';
import { BaseAssignmentUtilitiesService } from './services/base-assignment-utilities.service';
import { ChallengesService } from './services/challenges.service';
import { ChallengesUtilitiesService } from './services/challenges-utilities.service';
import { CookiesService } from './services/cookies.service';
import { ContentReportingService } from './services/content-reporting.service';
import { CourseService } from './services/course.service';
import { DashboardOverviewService } from './services/dashboard-overview.service';
import { DateService } from './services/date.service';
import { DropZoneService } from './services/drop-zone.service';
import { EmailService } from './services/email.service';
import { JobDetailsService } from './services/job-details.service';
import { JobSearchService } from './services/job-search.service';
import { JobsService } from './services/jobs.service';
import { MediaTypesService } from './services/media-types.service';
import { NetworkService } from './services/network.service';
import { ProfileAboutService } from './services/profile-about.service';
import { ProfileBucketService } from './services/profile-bucket.service';
import { ProfileService } from './services/profile.service';
import { RequirementUtilitiesService } from './services/requirement-utilities.service';
import { SecurityClearanceService } from './services/security-clearance.service';
import { SettingsService } from './services/settings.service';
import { SitewideSearchService } from './services/sitewide-search.service';
import { SkillsService } from './services/skills.service';
import { TagTeammateReminderService } from './services/tag-teammate-reminder.service';
import { UserAboutService } from './services/user-about.service';
import { UserBlockingService } from './services/user-blocking.service';
import { UserEntriesService } from './services/user-entries-service';
import { BadgrService } from './services/badgr.service';
import { PathwaysService } from './services/pathways.service';
import { FeatureSwitchService } from './services/features.service';

import { ACCOUNT_TYPES } from './constants/account-types.constant';
import { API_ERRORS } from './constants/api-errors.constant';
import {
    ASSIGNMENT_ACTION_INITIATOR_TYPES,
    ASSIGNMENT_ACTION_TYPE_CODES,
    ASSIGNMENT_ACTIONS_MAPPED,
} from './constants/assignments-action-types.constant';
import {
    ASSIGNMENTS_STATUS,
    ASSIGNMENTS_STATUS_MAPPED,
    ASSIGNMENTS_STATUS_SIMPLE,
} from './constants/assignments-status.constant';
import { ASSIGNMENTS_TYPES }  from './constants/assignments-types.constant';
import { ATTACHMENT_VIEWER_TYPES }  from './constants/attachment-viewer-types.constant';
import { BREAKPOINTS }  from './constants/breakpoints.constant';
import { COMPANY_SIZES }  from './constants/company-sizes.constant';
import {
    CONTENT_REPORTING_TYPES,
    CONTENT_REPORTING_REASONS,
} from './constants/content-reporting.constant';
import {
    COOKIES,
    COOKIES_OPTIONS
} from './constants/cookies.constant';
import { ENTRY_ATTACHMENTS_TYPES }  from './constants/attachment-types.constant';
import {
    ENTRY_CLASSIFICATIONS,
    ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES,
}  from './constants/entry-classifications.constant';
import {
    ENTRY_EDITOR_GUIDE_TYPES,
    ENTRY_EDITOR_GUIDE_TEMPLATES,
} from './constants/entry-editor-guides.constant';
import { ENTRY_VERSION_STATES }  from './constants/entry-version-states.constant';
import {
    ENTRY_VISIBILITY_TYPES,
    ENTRY_VISIBILITY_TYPES_MAPPED,
} from './constants/entry-visibility-types.constant';
import { JOB_STATES }  from './constants/job-states.constant';
import { JOB_TRAFFIC_SOURCES }  from './constants/job-traffic-sources.constant';
import { JOB_TYPES }  from './constants/job-types.constant';
import { MEDIA_TYPES }  from './constants/media-types.constant';
import { NETWORK_TYPES }  from './constants/network-types.constant';
import {
    PATHWAY_REQUIREMENT_STATUS_MAPPED,
    PATHWAY_REQUIREMENT_STATUS
} from './constants/pathway-requirement-status.constant';
import { PATHWAY_REQUIREMENT_TYPES }  from './constants/pathway-requirement-types.constant';
import { PATHWAY_TYPES }  from './constants/pathway-types.constant';
import { PROJECT_SHARING_SOCIAL_NETWORKS }  from './constants/project-sharing-social-networks.constant';
import {
    POPOVER_TYPES,
    POPOVER_STARTING_POSITIONS
} from './constants/popover.constant';
import {
    REQUIREMENT_ACTION_INITIATOR_TYPES,
    REQUIREMENT_ACTION_TYPE_CODES,
    REQUIREMENT_ACTIONS_MAPPED,
} from './constants/requirement-action-types.constant';
import {
    TEXT_STYLES_MAP,
    TEXT_STYLES_TYPES
} from './constants/text-styles.constant';
import { USER_CARD_TEMPLATES }  from './constants/user-card-templates.constant';
import { ASSIGNMENT_SERVICES } from './constants/assignment-services.constant';
import { NETWORK_START_PAGES } from './constants/network-start-pages.constant';
import { FEATURE_SWITCHES } from './constants/features.constant';

const MODULE_NAME = 'portfolium.core';

(function () {
    angular.module(MODULE_NAME, [
        CoreConfigModule,
        CoreServicesModule,
        CoreFiltersModule,
    ])
        // Constants
        .constant('PF_API_ERRORS', API_ERRORS)
        .constant('PF_ACCOUNT_TYPES', ACCOUNT_TYPES)
        .constant('PF_ASSIGNMENTS_STATUS', ASSIGNMENTS_STATUS)
        .constant('PF_ASSIGNMENTS_STATUS_MAPPED', ASSIGNMENTS_STATUS_MAPPED)
        .constant('PF_ASSIGNMENTS_STATUS_SIMPLE', ASSIGNMENTS_STATUS_SIMPLE)
        .constant('PF_ASSIGNMENTS_TYPES', ASSIGNMENTS_TYPES)
        .constant('PF_ASSIGNMENT_ACTIONS_MAPPED', ASSIGNMENT_ACTIONS_MAPPED)
        .constant('PF_ASSIGNMENT_ACTION_TYPE_CODES', ASSIGNMENT_ACTION_TYPE_CODES)
        .constant('PF_ASSIGNMENT_ACTION_INITIATOR_TYPES', ASSIGNMENT_ACTION_INITIATOR_TYPES)
        .constant('PF_ASSIGNMENT_SERVICES', ASSIGNMENT_SERVICES)
        .constant('PF_ATTACHMENT_VIEWER_TYPES', ATTACHMENT_VIEWER_TYPES)
        .constant('PF_BREAKPOINTS', BREAKPOINTS)
        .constant('PF_COMPANY_SIZES', COMPANY_SIZES)
        .constant('PF_CONTENT_REPORTING_TYPES', CONTENT_REPORTING_TYPES)
        .constant('PF_CONTENT_REPORTING_REASONS', CONTENT_REPORTING_REASONS)
        .constant('PF_COOKIES', COOKIES)
        .constant('PF_COOKIES_OPTIONS', COOKIES_OPTIONS)
        .constant('PF_ENTRY_ATTACHMENTS_TYPES', ENTRY_ATTACHMENTS_TYPES)
        .constant('PF_ENTRY_CLASSIFICATIONS', ENTRY_CLASSIFICATIONS)
        .constant('PF_ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES', ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES)
        .constant('PF_ENTRY_EDITOR_GUIDE_TYPES', ENTRY_EDITOR_GUIDE_TYPES)
        .constant('PF_ENTRY_EDITOR_GUIDE_TEMPLATES', ENTRY_EDITOR_GUIDE_TEMPLATES)
        .constant('PF_ENTRY_VISIBILITY_TYPES', ENTRY_VISIBILITY_TYPES)
        .constant('PF_ENTRY_VISIBILITY_TYPES_MAPPED', ENTRY_VISIBILITY_TYPES_MAPPED)
        .constant('PF_JOB_STATES', JOB_STATES)
        .constant('PF_JOB_TRAFFIC_SOURCES', JOB_TRAFFIC_SOURCES)
        .constant('PF_JOB_TYPES', JOB_TYPES)
        .constant('PF_MEDIA_TYPES', MEDIA_TYPES)
        .constant('PF_NETWORK_TYPES', NETWORK_TYPES)
        .constant('PF_NETWORK_START_PAGES', NETWORK_START_PAGES)
        .constant('PF_PATHWAY_REQUIREMENT_STATUS_MAPPED', PATHWAY_REQUIREMENT_STATUS_MAPPED)
        .constant('PF_PATHWAY_REQUIREMENT_STATUS', PATHWAY_REQUIREMENT_STATUS)
        .constant('PF_PATHWAY_REQUIREMENT_TYPES', PATHWAY_REQUIREMENT_TYPES)
        .constant('PF_POPOVER_STARTING_POSITIONS', POPOVER_STARTING_POSITIONS)
        .constant('PF_POPOVER_TYPES', POPOVER_TYPES)
        .constant('PF_REQUIREMENT_ACTION_INITIATOR_TYPES', REQUIREMENT_ACTION_INITIATOR_TYPES)
        .constant('PF_REQUIREMENT_ACTION_TYPE_CODES', REQUIREMENT_ACTION_TYPE_CODES)
        .constant('PF_REQUIREMENT_ACTIONS_MAPPED', REQUIREMENT_ACTIONS_MAPPED)
        .constant('PF_USER_CARD_TEMPLATES', USER_CARD_TEMPLATES)
        .constant('PF_PROJECT_SHARING_SOCIAL_NETWORKS', PROJECT_SHARING_SOCIAL_NETWORKS)
        .constant('PF_PATHWAY_TYPES', PATHWAY_TYPES)
        .constant('PF_ENTRY_VERSION_STATES', ENTRY_VERSION_STATES)
        .constant('PF_TEXT_STYLES_MAP', TEXT_STYLES_MAP)
        .constant('PF_TEXT_STYLES_TYPES', TEXT_STYLES_TYPES)
        .constant('PF_FEATURE_SWITCHES', FEATURE_SWITCHES)
        // Services
        .service('$pfAddress', AddressService)
        .service('$pfAppSwitcherAuth', AppSwitcherAuthService)
        .service('$pfAssignments', AssignmentsService)
        .service('$pfAssignmentsUtilities', AssignmentsUtilitiesService)
        .service('$pfAttachmentViewer', AttachmentViewerService)
        .service('$pfBaseAssignmentUtilities', BaseAssignmentUtilitiesService)
        .service('$pfChallenges', ChallengesService)
        .service('$pfChallengesUtilities', ChallengesUtilitiesService)
        .service('$pfContentReporting', ContentReportingService)
        .service('$pfCookies', CookiesService)
        .service('$pfCourse', CourseService)
        .service('$pfDashboardOverview', DashboardOverviewService)
        .service('$pfDate', DateService)
        .service('$pfDropZone', DropZoneService)
        .service('$pfEmail', EmailService)
        .service('$pfJobDetails', JobDetailsService)
        .service('$pfJobs', JobsService)
        .service('$pfJobSearch', JobSearchService)
        .service('$pfMediaTypes', MediaTypesService)
        .service('$pfNetwork', NetworkService)
        .service('$pfProfile', ProfileService)
        .service('$pfProfileAbout', ProfileAboutService)
        .service('$pfProfileBucket', ProfileBucketService)
        .service('$pfRequirementUtilities', RequirementUtilitiesService)
        .service('$pfSecurityClearance', SecurityClearanceService)
        .service('$pfSettings', SettingsService)
        .service('$pfSiteWideSearch', SitewideSearchService)
        .service('$pfSkills', SkillsService)
        .service('$pfTagTeammateReminder', TagTeammateReminderService)
        .service('$pfUserAbout', UserAboutService)
        .service('$pfUserBlocking', UserBlockingService)
        .service('$pfUserEntries', UserEntriesService)
        .service('$pfBadgr', BadgrService)
        .service('$pfPathways', PathwaysService)
        .service('$pfTasks', TasksService)
        .service('$pfFeatures', FeatureSwitchService)
        // Directives
        .directive('pfTextStyle', textStyleDirective);
}());

export default MODULE_NAME;
