import CompanyConnectSheetTemplate from '../../template/company-connect-sheet.html';
import CompanyConnectSheetController from './company-connect-sheet-controller';

class CompanyConnectBtnController {
    constructor(
        $mdDialog,
        $mdBottomSheet,
        $pfUser,
        $pfCompanyConnect,
        $filter,
    ) {
        this.$mdDialog = $mdDialog;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$pfUser = $pfUser;
        this.$pfCompanyConnect = $pfCompanyConnect;
        this.$filter = $filter;
    }

    /**
     * Is the user currently on a mobile device?
     * @return {Boolean}
     */
    get isMobile() {
        return this.$pfUser.isMobile();
    }

    /**
     * Handle the click event to take the appropriate action based on current status
     * @param  {Event}    $event      Click event from ng-click
     * @return {Promise}
     */
    onClick($event) {
        $event.stopPropagation();
        $event.preventDefault();

        if (this.following) {
            if (this.isMobile) {
                return this.removeConnectionSheet($event);
            }

            return this.removeConnectionDialog($event);
        }

        if (this.messageDialog) {
            return this.addConnectionDialog($event);
        }

        return this.addConnection($event);
    }

    /**
     * Submitting a connection request immediately
     * @return {Promise}
     */
    addConnection() {
        this.following = true;
        this.setViewValue();

        return this.$pfCompanyConnect
            .requestConnection(this.company.id)
            .then(null, () => {
                this.following = false;
            });
    }

    /**
     * Show bottom sheet to remove a connection
     * @param  {Event}   $event Target event
     * @return {Promise}
     */
    removeConnectionSheet($event) {
        return this.$mdBottomSheet
            .show({
                template: CompanyConnectSheetTemplate,
                controller: CompanyConnectSheetController,
                controllerAs: 'sheetCtrl',
                targetEvent: $event,
            })
            .then(() => {
                this.following = false;
                this.setViewValue();

                return this.$pfCompanyConnect
                    .removeConnection(this.company.id)
                    .then(null, () => {
                        this.following = true;
                        this.setViewValue();
                    });
            });
    }

    /**
     * Show dialog to remove a connection
     * @param  {Event}   $event Target event
     * @return {Promise}
     */
    removeConnectionDialog($event) {
        const title = this.$filter('i18n')(
            'Are you sure you want to unfollow this company?',
        );
        const ariaLabel = this.$filter('i18n')('Remove');
        const ok = this.$filter('i18n')('Yes, unfollow');
        const cancel = this.$filter('i18n')('Cancel');

        let confirm = this.$mdDialog
            .confirm()
            .title(title)
            .ariaLabel(ariaLabel)
            .targetEvent($event)
            .ok(ok)
            .cancel(cancel);

        return this.$mdDialog.show(confirm).then(() => {
            this.following = false;
            this.setViewValue();

            return this.$pfCompanyConnect
                .removeConnection(this.company.id)
                .then(null, () => {
                    this.following = true;
                    this.setViewValue();
                });
        });
    }

    /**
     * Update the view value with ngModelController
     */
    setViewValue() {
        this._ngModelCtrl.$setViewValue({
            following: this.following,
        });
    }
}

CompanyConnectBtnController.$inject = [
    '$mdDialog',
    '$mdBottomSheet',
    '$pfUser',
    '$pfCompanyConnect',
    '$filter',
];

export default CompanyConnectBtnController;
