export const courseAssignmentsActionTypes = {
    'PAGE_INIT': 'COURSE_PAGE_INIT',
    'PAGE_NEXT': 'COURSE_PAGE_NEXT',
    'PAGE_REQUEST': 'COURSE_PAGE_REQUEST',
    'PAGE_SUCCESS': 'COURSE_PAGE_SUCCESS',
    'PAGE_ERROR': 'COURSE_PAGE_ERROR',
    'TOGGLE_SELECTION': 'COURSE_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'COURSE_CLEAR_SELECTION',
    'TOGGLE_ALL': 'COURSE_TOGGLE_ALL',
    'ADD_FILTER': 'COURSE_ADD_FILTER',
    'REMOVE_FILTER': 'COURSE_REMOVE_FILTER',
};
