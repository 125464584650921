import BaseModalController from 'components/modal/js/controller/base-modal-controller';

export class TrialModalController extends BaseModalController {
    constructor($mdDialog, $pfSubscription) {
        super($mdDialog);
        this.$pfSubscription = $pfSubscription;

        this.initialize();
    }

    /**
     * Initialize values
     */
    initialize() {
        // Set trial days remaining
        this.trialDaysRemaining = this.$pfSubscription.getTrialDaysRemaining();
        // Set subscribers sponsored network
        this.sponsoredNetwork = this.$pfSubscription.sponsoredNetwork;
    }

    /**
    * Is the subscriber's trial expired?
    * @return {Boolean}
    */
    isExpiredTrial() {
        return (this.trialDaysRemaining < 0) ? true : false;
    }

    /**
     * Did a network sponsor a trial for the subscriber?
     * @return {Boolean}
     */
    isSponsoredTrial() {
        return !!this.sponsoredNetwork;
    }

}

TrialModalController.$inject = [
    '$mdDialog',
    '$pfSubscription',
];
