export const challengesListActionTypes = {
    'PAGE_INIT': 'CHALLENGES_PAGE_INIT',
    'PAGE_NEXT': 'CHALLENGES_PAGE_NEXT',
    'PAGE_PREVIOUS': 'CHALLENGES_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'CHALLENGES_PAGE_REQUEST',
    'PAGE_SUCCESS': 'CHALLENGES_PAGE_SUCCESS',
    'PAGE_ERROR': 'CHALLENGES_PAGE_ERROR',
    'TOGGLE_SELECTION': 'CHALLENGES_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'CHALLENGES_CLEAR_SELECTION',
    'TOGGLE_ALL': 'CHALLENGES_TOGGLE_ALL',
    'ADD_FILTER': 'CHALLENGES_ADD_FILTER',
    'REMOVE_FILTER': 'CHALLENGES_REMOVE_FILTER',
};
