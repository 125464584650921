class SignInHistoryService
{
    constructor($q, $state) {
        this.$q = $q;
        this.$state = $state;
        this.history = [];
        this.returnUrl = null;
    }

    _addState(stateName) {
        this.history.push({
            stateName: stateName
        });

        return this.history;
    }

    _removeState() {
    }

    _removeLastState() {
        this.history.splice(-1, 1);

        return this.hisory;
    }

    _getLastState() {
        return this.history.slice(-1)[0];
    }

    isModal() {
        return this.$state.current.$$state().includes.simpleSignInModal === true;
    }

    next(toStateName, toStateParams, options = {}) {
        let currentState = this.$state.current;
        let toParams = this.$state.params;

        _.forEach(toStateParams, (value, key) => {
            toParams[key] = value;
        });

        if (this.isModal()) {
            // If inside modal, update toStateName with correct parent state
            toStateName = toStateName
                .replace('login.', 'loginModal.')
                .replace('register.', 'registerModal.');
        }

        return this.$state.go(toStateName, toParams).then(() => {
            if (options.bypassHistory === true) {
                return;
            }

            this._addState(currentState.name);
        });
    }

    back() {
        let lastState = this._getLastState();

        if (!lastState) {
            return this.$q.reject();
        }

        return this.$state.go(lastState.stateName, this.$state.params).then(() => {
            this._removeLastState();
        });
    }

    getHistory() {
        return this.history;
    }

    clearHistory() {
        this.history = [];
        this.returnUrl = null;
    }
}

SignInHistoryService.$inject = ['$q', '$state'];

export default SignInHistoryService;
