import { combineReducers } from 'redux';
import { entryEditorAttachments } from './entry-attachments/entry-attachments.reducer';
import {
    ENTRY_CLASSIFICATIONS,
    ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES,
} from '../core/constants/entry-classifications.constant.js';
import {
    ENTRY_EDITOR_ALLOW_STATE_CHANGE,
    ENTRY_EDITOR_AUTOSAVE_DIRTY,
    ENTRY_EDITOR_AUTOSAVE_DISABLE,
    ENTRY_EDITOR_AUTOSAVE_ENABLE,
    ENTRY_EDITOR_AUTOSAVE_FAIL,
    ENTRY_EDITOR_AUTOSAVE_RESET,
    ENTRY_EDITOR_AUTOSAVE_START,
    ENTRY_EDITOR_AUTOSAVE_SUCCESS,
    ENTRY_EDITOR_DISABLE_ACTIONS,
    ENTRY_EDITOR_DISALLOW_STATE_CHANGE,
    ENTRY_EDITOR_ENABLE_ACTIONS,
    ENTRY_EDITOR_HIDE_MOBILE_FIELD,
    ENTRY_EDITOR_LOADING_TOGGLE,
    ENTRY_EDITOR_META_RESET,
    ENTRY_EDITOR_RESET_ASSIGNMENT_DETAILS,
    ENTRY_EDITOR_RESET_HELP_GUIDE,
    ENTRY_EDITOR_RESET_DRAFT,
    ENTRY_EDITOR_RESET_DRAFT_STATUS,
    ENTRY_EDITOR_RESET_ENTRY_DETAILS,
    ENTRY_EDITOR_RESET_SOCIAL_SHARING,
    ENTRY_EDITOR_SET_ALLOWED_FILE_TYPES,
    ENTRY_EDITOR_SET_ATTACHMENTS,
    ENTRY_EDITOR_SET_BUCKET_ID,
    ENTRY_EDITOR_SET_CATEGORY,
    ENTRY_EDITOR_SET_CHALLENGE_ID,
    ENTRY_EDITOR_SET_COVER_PHOTO,
    ENTRY_EDITOR_SET_DESCRIPTION,
    ENTRY_EDITOR_SET_ENTRY_CLASSIFICATION,
    ENTRY_EDITOR_SET_ID,
    ENTRY_EDITOR_SET_SETTINGS,
    ENTRY_EDITOR_SET_SKILLS,
    ENTRY_EDITOR_SET_SOCIAL_SHARING_TWITTER,
    ENTRY_EDITOR_SET_STATUS_PUBLISHED,
    ENTRY_EDITOR_SET_STATUS_IS_ASSIGNMENT,
    ENTRY_EDITOR_SET_STATUS_NEXT_STATE,
    ENTRY_EDITOR_SET_TAGS,
    ENTRY_EDITOR_SET_TEAMMATES,
    ENTRY_EDITOR_SET_TITLE,
    ENTRY_EDITOR_SET_TOKEN,
    ENTRY_EDITOR_SHOW_HELP_GUIDE,
    ENTRY_EDITOR_SHOW_MOBILE_FIELD,
    ENTRY_EDITOR_UPDATE_DRAFT,
    ENTRY_EDITOR_UPDATE_ASSIGNMENT_DETAILS,
    ENTRY_EDITOR_UPDATE_ENTRY_DETAILS,
    ENTRY_EDITOR_SET_SKILLS_DISPLAY_STRING,
    ENTRY_EDITOR_SET_SUGGESTED_TEAMMATES,
    ENTRY_EDITOR_SET_SEARCH_RESULT_TEAMMATES,
    ENTRY_EDITOR_SET_SUGGESTED_SKILLS,
    ENTRY_EDITOR_SET_SEARCH_RESULT_SKILLS,
    ENTRY_EDITOR_SET_SEARCH_RESULT_TAGS,
    ENTRY_EDITOR_SET_SUGGESTED_TAGS,
    ENTRY_EDITOR_SET_TAGS_DISPLAY_STRING,
    ENTRY_EDITOR_SET_STRENGTH,
    ENTRY_EDITOR_DID_VIEW_TAG_TEAMMATES_DIALOG,
} from './entry-editor.actions';

const initialAutosaveState = {
    hasSaveSuccess: false,
    isDirty: false,
    isSaveDisabled: false,
    isSaving: false,
};

const initialDraftStatusState = {
    loading: false,
    isAssignment: false,
    isPublished: false,
    entryClassification: ENTRY_CLASSIFICATIONS.default,
    disableActions: false,
    allowStateChange: true,
    nextState: null,
    nextStateParams: null,
};

const initialMetaState = {
    allowedFileTypes: Object.keys(ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES.default),
    suggestedSkills: [],
    suggestedTags: [],
    suggestedTeammates: [],
    searchResultSkills: [],
    searchResultTags: [],
    searchResultTeammates: [],
    skillsDisplayString: null,
    tagsDisplayString: null,
    strength: 0,
    didViewTagTeammatesDialog: false,
};

const initialProjectSharing = {
    twitter: false,
};

const cleanSkills = (skills) => {
    return _.map(skills, (skill) => {
        return { id: skill.id, skill: skill.skill };
    })
}

const meta = (state = initialMetaState, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_SET_ALLOWED_FILE_TYPES:
            return _.assign({}, state, {
                allowedFileTypes: action.payload.allowedFileTypes,
            });
        case ENTRY_EDITOR_SET_SKILLS_DISPLAY_STRING:
            return _.assign({}, state, {
                skillsDisplayString: action.payload.skillsDisplayString,
            });
        case ENTRY_EDITOR_SET_TAGS_DISPLAY_STRING:
            return _.assign({}, state, {
                tagsDisplayString: action.payload.tagsDisplayString,
            });
        case ENTRY_EDITOR_SET_SUGGESTED_TEAMMATES:
            return _.assign({}, state, {
                suggestedTeammates: action.payload.suggestedTeammates,
            });
        case ENTRY_EDITOR_SET_SEARCH_RESULT_TEAMMATES:
            return _.assign({}, state, {
                searchResultTeammates: action.payload.searchResultTeammates,
            });
        case ENTRY_EDITOR_SET_SUGGESTED_SKILLS:
            return _.assign({}, state, {
                suggestedSkills: cleanSkills(action.payload.suggestedSkills),
            });
        case ENTRY_EDITOR_SET_SEARCH_RESULT_SKILLS:
            return _.assign({}, state, {
                searchResultSkills: cleanSkills(action.payload.searchResultSkills),
            });
        case ENTRY_EDITOR_SET_SUGGESTED_TAGS:
            return _.assign({}, state, {
                suggestedTags: action.payload.suggestedTags,
            });
        case ENTRY_EDITOR_SET_SEARCH_RESULT_TAGS:
            return _.assign({}, state, {
                searchResultTags: action.payload.searchResultTags,
            });
        case ENTRY_EDITOR_SET_STRENGTH:
            return _.assign({}, state, {
                strength: action.payload.strength,
            });
        case ENTRY_EDITOR_DID_VIEW_TAG_TEAMMATES_DIALOG:
            return _.assign({}, state, {
                didViewTagTeammatesDialog: true,
            });
        case ENTRY_EDITOR_META_RESET:
            return initialMetaState;
        default:
            return state;
    }
};

const autosave = (state = initialAutosaveState, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_AUTOSAVE_DIRTY:
            return _.assign({}, state, {
                isDirty: true,
            });
        case ENTRY_EDITOR_AUTOSAVE_DISABLE:
            return _.assign({}, state, {
                isSaveDisabled: true,
            });
        case ENTRY_EDITOR_AUTOSAVE_ENABLE:
            return _.assign({}, state, {
                isSaveDisabled: false,
            });
        case ENTRY_EDITOR_AUTOSAVE_FAIL:
            return _.assign({}, state, {
                isSaveDisabled: false,
                isSaving: false,
            });
        case ENTRY_EDITOR_AUTOSAVE_START:
            return _.assign({}, state, {
                isSaveDisabled: true,
                isSaving: true,
            });
        case ENTRY_EDITOR_AUTOSAVE_RESET:
            return initialAutosaveState;
        case ENTRY_EDITOR_AUTOSAVE_SUCCESS:
            return _.assign({}, state, {
                hasSaveSuccess: true,
                isDirty: false,
                isSaveDisabled: false,
                isSaving: false,
            });
        default:
            return state;
    }
};

const draftStatus = (state = initialDraftStatusState, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_LOADING_TOGGLE:
            return _.assign({}, state, {
                loading: !state.loading,
            });
        case ENTRY_EDITOR_ALLOW_STATE_CHANGE:
            return _.assign({}, state, {
                allowStateChange: true,
            });
        case ENTRY_EDITOR_DISALLOW_STATE_CHANGE:
            return _.assign({}, state, {
                allowStateChange: false,
            });
        case ENTRY_EDITOR_RESET_DRAFT_STATUS:
            return initialDraftStatusState;
        case ENTRY_EDITOR_SET_STATUS_PUBLISHED:
            return _.assign({}, state, {
                isPublished: true,
            });
        case ENTRY_EDITOR_SET_STATUS_NEXT_STATE:
            return _.assign({}, state, {
                nextState: action.payload.state,
                nextStateParams: action.payload.params,
            });
        case ENTRY_EDITOR_SET_STATUS_IS_ASSIGNMENT:
            return _.assign({}, state, {
                isAssignment: true,
            });
        case ENTRY_EDITOR_SET_ENTRY_CLASSIFICATION:
            return _.assign({}, state, {
                entryClassification: action.payload.entryClassification,
            });
        case ENTRY_EDITOR_DISABLE_ACTIONS:
            return _.assign({}, state, {
                disableActions: true,
            });
        case ENTRY_EDITOR_ENABLE_ACTIONS:
            return _.assign({}, state, {
                disableActions: false,
            });
        default:
            return state;
    }
};

const currentDraft = (state = {}, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_RESET_DRAFT:
            return {};
        case ENTRY_EDITOR_SET_BUCKET_ID:
            return _.assign({}, state, {
                bucket_id: action.payload.bucketId,
            });
        case ENTRY_EDITOR_SET_CATEGORY:
            return _.assign({}, state, {
                category: action.payload.category,
            });
        case ENTRY_EDITOR_SET_CHALLENGE_ID:
            return _.assign({}, state, {
                challenge_id: action.payload.challengeId,
            });
        case ENTRY_EDITOR_SET_DESCRIPTION:
            return _.assign({}, state, {
                description: action.payload.description,
            });
        case ENTRY_EDITOR_SET_COVER_PHOTO:
            return _.assign({}, state, {
                cover: action.payload.cover,
            });
        case ENTRY_EDITOR_SET_ID:
            return _.assign({}, state, {
                id: action.payload.id,
            });
        case ENTRY_EDITOR_SET_TITLE:
            return _.assign({}, state, {
                title: action.payload.title,
            });
        case ENTRY_EDITOR_SET_TOKEN:
            return _.assign({}, state, {
                token: action.payload.token,
            });
        case ENTRY_EDITOR_SET_ATTACHMENTS:
            return _.assign({}, state, {
                attachments: action.payload.attachments,
            });
        case ENTRY_EDITOR_SET_TEAMMATES:
            return _.assign({}, state, {
                collaborators: action.payload.teammates,
            });
        case ENTRY_EDITOR_SET_SKILLS:
            return _.assign({}, state, {
                skill_list: action.payload.skills,
            });
        case ENTRY_EDITOR_SET_TAGS:
            return _.assign({}, state, {
                tags: action.payload.tags,
            });
        case ENTRY_EDITOR_SET_SETTINGS:
            return _.assign({}, state, {
                visibility: action.payload.visibility,
                allow_comments: action.payload.allow_comments,
            });
        case ENTRY_EDITOR_UPDATE_DRAFT:
            return action.payload.draft;
        default:
            return state;
    }
};

const projectSharing = (state = initialProjectSharing, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_SET_SOCIAL_SHARING_TWITTER:
            return _.assign({}, state, {
                twitter: !!action.payload.enabled,
            });
        case ENTRY_EDITOR_RESET_SOCIAL_SHARING:
            return initialProjectSharing;
        default:
            return state;
    }
};

const activeMobileField = (state = null, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_HIDE_MOBILE_FIELD:
            return null;
        case ENTRY_EDITOR_SHOW_MOBILE_FIELD:
            return action.payload.fieldName;
        default:
            return state;
    }
};

const helpGuide = (state = null, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_RESET_HELP_GUIDE:
            return null;
        case ENTRY_EDITOR_SHOW_HELP_GUIDE:
            return action.payload.fieldName;
        default:
            return state;
    }
};

const entryDetails = (state = null, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_RESET_ENTRY_DETAILS:
            return null;
        case ENTRY_EDITOR_UPDATE_ENTRY_DETAILS:
            return action.payload.entryDetails;
        default:
            return state;
    }
};

const assignmentDetails = (state = null, action = {}) => {
    switch (action.type) {
        case ENTRY_EDITOR_RESET_ASSIGNMENT_DETAILS:
            return null;
        case ENTRY_EDITOR_UPDATE_ASSIGNMENT_DETAILS:
            return action.payload.assignment;
        default:
            return state;
    }
};

const draftLimit = (state = { state: 'idle' }, event = {}) => {
    switch (event.type) {
        // In lack of a better transition marker...
        case '@@reduxUiRouter/onSuccess':
            return { state: 'idle' }
        case 'ENTRY_EDITOR_DRAFT_LIMIT_REQUESTED':
            return { state: 'pending' }
        case 'ENTRY_EDITOR_DRAFT_LIMIT_RESPONSE':
            return { state: 'resolved', data: event.data }
        case 'ENTRY_EDITOR_DRAFT_LIMIT_ERROR':
            return { state: 'error' }
        default:
            return state;
    }
}

export const entryEditor = combineReducers({
    activeMobileField,
    assignmentDetails,
    autosave,
    currentDraft,
    draftStatus,
    draftLimit,
    entryDetails,
    entryEditorAttachments,
    helpGuide,
    meta,
    projectSharing,
});
