class SystemService
{
    constructor($http) {
        this.$http = $http;
    }

    /**
     * Get industries
     * @return {promise}
     */
    getIndustries() {
        return this.$http({
            method: 'GET',
            url: '/proxy/system/industries',
            headers: {
                'PF-CACHE': true
            },
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get functions
     * @return {promise}
     */
    getFunctions() {
        return this.$http({
            method: 'GET',
            url: '/proxy/system/functions',
            headers: {
                'PF-CACHE': true
            },
        })
        .then((response) => {
            return response.data;
        });
    }
}

SystemService.$inject = [
    '$http',
];

export default SystemService;
