export const COURSE_SET = 'COURSE_SET';
export const COURSE_RESET = 'COURSE_RESET';

export const setCourse = course => {
    return {
        type: COURSE_SET,
        payload: {
            course,
        },
    };
};

/**
 * Reset the current assignment to empty
 */
export const resetCourse = () => ({
    type: COURSE_RESET,
});
