import hello from 'hellojs';
import utilities from 'components/utilities';
import { RedirectAuthModalController } from "../redirect-oauth/canvas/redirect-auth-modal.controller";
import { CanvasSource } from '../../../../settings/settings-import-data/data-importer/canvas/canvas-source';
import RedirectAuthModal from '../redirect-oauth/canvas/redirect-auth-modal.html';

/**
 * @implements AuthProvider
 * @name CanvasAuthProvider
 * @description
 * Authenticate with Canvas
 */
export class CanvasAuthProvider {
    constructor(
        $http,
        $pfUser,
        $q,
        $window,
        $mdDialog,
    ) {
        this.$http = $http;
        this.$pfUser = $pfUser;
        this.$q = $q;
        this.$window = $window;
        this.$mdDialog = $mdDialog;
        // needed for auth provider identification
        this.name = 'canvas';

        /**
         * TODO: Handle multiple networks. Instead of pulling the first lms record
         * off the session, we'll need to create a separate hellojs client for
         * each network.
         */
        this.createClient(this.lms);
    }

    get lms() {
        const user = this.$pfUser.getUser();

        if (!user) {
            return {};
        }

        return user.lms[0];
    }

    /**
     * Authenticate the user via Canvas
     * @param {object} [lms] Future param required for multiple networks
     * @return {Promise} Resolved after user has authenticated and spoke has been created
     */
    login(lms = {}) {
        if (this.hasSpoke()) {
            return this.$q.resolve();
        }

        let self = this

        $.ajax({
            url: '/canvas/authorization',
            success: function (data) {
                if (data.length > 1) {
                    self.$mdDialog.show({
                        template: RedirectAuthModal,
                        controller: RedirectAuthModalController,
                        controllerAs: '$ctrl',
                        locals: {
                            provider: CanvasSource,
                            data
                        },
                        clickOutsideToClose: true,
                    })
                } else {
                    window.location = data[0].authorization_url;
                }
            },
            error: function (xhr) {
                utilities.showGrowl(xhr.responseJSON, 'error');
            }
        });

        return this.$q.reject();
    }

    /**
     * Log out
     * @return {Promise} Resolved after user has logged out of the service
     */
    logout() {
        /**
         * Nothing to log out of so resolve the promise to continue the unlink flow
         * Deleting the spoke is the next step in the flow
         */
        return this.$q.resolve();
    }

    /**
     * Create a new hellojs client for Canvas authentication
     * @param {object} [lms] Future param required for multiple networks
     */
    createClient(lms = {}) {
        const baseUrl = lms.client_url;

        if (!baseUrl) {
            return;
        }

        hello.init({
            canvas: {
                name: 'canvas',
                oauth: {
                    version: 2,
                    auth: `${baseUrl}/login/oauth2/auth`,
                    grant: `${baseUrl}/login/oauth2/token`,
                },
                refresh: true,
                base: baseUrl,
            }
        });
    }

    /**
     * Get the hellojs client for Canvas
     * @param {object} [lms] Future param required for multiple networks
     */
    getClient(lms = {}) {
        hello.init({
            canvas: lms.client_id,
        }, {
            response_type: 'code',
            popup: {
                width: 800,
            },
        });

        return hello('canvas');
    }

    /**
     * Get the hellojs client for Canvas
     * @param {object} [lms] Future param required for multiple networks
     * @return {boolean} Does the current user have access to this provider?
     */
    hasAccess(lms = {}) {
        return this.lms && this.lms.client_url;
    }

    /**
     * Has the user already authenticated with Canvas?
     * @param {object} [lms] Future param required for multiple networks
     * @return {boolean}
     */
    hasSpoke(lms = {}) {
        const { spokes } = this.$pfUser.getUser();
        return spokes.canvas === true;
    }

    /**
     * Parse the response from hellojs and format it before passing to the API.
     * @param {object} authResponse Auth response object from hellojs
     * @param {object} [lms] Future param required for multiple networks
     * @return {object} Formatted data object
     */
    parseAuthResponse(authResponse = {}, lms = {}) {
        return {
            'access_token': authResponse.access_token,
            /**
            * Hard code user ID here because Heroku OAuth app sucks at parsing
            * the user data response from Canvas. This can be fixed using
            * Julio's custom hellojs module if we host the auth server ourselves
            */
            'canvas_user_id': 1,
            'refresh_token': authResponse.refresh_token,
            'expires_at': authResponse.expires_in,
            'network_id': lms.fk_network_id,
        };
    }

    /**
     * Store the new auth token and update the user session
     * @param {object} data Formatted data object
     * @return {Promise} Resolved after spoke is created and session is updated
     */
    storeToken(data = {}) {
        // Store auth token in the spokes table
        return this.$http({
            method: 'POST',
            url: '/proxy/canvas/tokens',
            data,
        }).then(() => {
            // Make sure session is updated with new spoke
            return this.$pfUser.updateSession();
        });
    }
}

CanvasAuthProvider.$inject = [
    '$http',
    '$pfUser',
    '$q',
    '$window',
    '$mdDialog',
];
