export const COMPANY_SIZES = [
    {id: '1-10', name: '1-10'},
    {id: '11-50', name: '11-50'},
    {id: '51-200', name: '51-200'},
    {id: '201-500', name: '201-500'},
    {id: '501-1000', name: '501-1000'},
    {id: '1001-5000', name: '1001-5000'},
    {id: '5001-10000', name: '5001-10000'},
    {id: '10000+', name: '10000+'},
];
