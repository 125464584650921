import _ from 'lodash';

import {AuthProviderService} from './auth-provider.service';

/**
 * @ngdoc provider
 * @module portfolium.auth.authProviders
 * @name $pfAuthProviderProvider
 * @description
 * Register all supported auth providers when the application boots
 */
export class AuthProviderProvider {
    constructor() {
        this.providers = [];
        // keep track of registered provider ids
        this.registeredIds = [];

        this.$get = [
            '$injector',
            ($injector) => new AuthProviderService($injector, this.providers)
        ];
    }

    /**
     * Register a new authentication provider
     * @param {AuthProvider} provider New provider
     * @param {String} id Unique id for provider; since `provider` is an uninstantiated Class, we don't have access to properties yet
     */
    register(provider, id) {
        if (!id) {
            throw new Error(`Provider id not defined`);
        }

        if (_.includes(this.registeredIds, id)) {
            throw new Error(`Provider already defined for "${id}"`);
        }

        this.registeredIds = [
            ...this.registeredIds,
            id,
        ];

        this.providers = [
            ...this.providers,
            provider,
        ];
    }
}
