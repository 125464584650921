import angular from 'angular';

import {NetworkResultComponent} from './network-result.component';

import './network-result.component.scss';

const MODULE_NAME = 'portfolium.component.networkResult';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfNetworkResult', NetworkResultComponent);
}());

export default MODULE_NAME;
