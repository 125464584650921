import * as filestack from 'filestack-js';

/**
 * @ngdoc service
 * @module portfolium.core.services.filestack
 * @name $pfFilestack
 * @description Filestack client wrapper to handle file uploads and conversion via
 * the v3 Filestack API.
 */
export class FilestackService {
    constructor($q, $http, $pfFilestackOptions, $pfFilestackSigner, PF_FILESTACK_CONFIG) {
        this.$q = $q;
        this.$http = $http;
        this.$pfFilestackOptions = $pfFilestackOptions;
        this.$pfFilestackSigner = $pfFilestackSigner;
        this.client = filestack.init(PF_FILESTACK_CONFIG.apiKey, {
            cname: PF_FILESTACK_CONFIG.cname,
        });
        this.config = PF_FILESTACK_CONFIG;
    }

    /**
     * Convert an existing file into another format and upload it to Amazon S3.
     *
     * @param {string} fileHandle UUID from Filestack pointing to the existing file
     * @param {string} convertTo File type to convert the file into. For a list of supported conversions, see the [Filestack docs](https://www.filestack.com/docs/document-transformations)
     * @param {object} [storeOptions] Config for storing of file
     * @param {string} [storeOptions.path] Path to store the file in, with trailing slash. Defaults to the root
     * @param {string} [storeOptions.container] the S3 container to store to
     * @param {Object} [security] Filestack security
     *
     * @return {Promise<object>} Resolved with file metadata when upload is complete
     * @memberof FilestackService
     */
    convert(
        fileHandle,
        convertTo,
        { container = this.config.storeOptions.container, path } = {},
        { policy, signature } = {},
    ) {
        // make the convert url here
        const url = this.$pfFilestackSigner.buildConvertUrl(
            this.config.transformUrl,
            convertTo,
            path,
            container,
            { policy, signature },
            fileHandle
        );
        // make the API call
        return this.$http({
            method: 'POST',
            url,
            headers: {
                'X-Requested-With': undefined,
            },
        })
        .then(response => response.data)
        .then(convertedData => {
            // get the handle here and go
            const getHandle = (url) => {
                // Split the URL (https://cdn.filestackcontent.com/b9zJgRruQZK7fHspWe6r)
                const urlParts = convertedData.url.split('/');
                // Get the handle from the split URL
                const handle = urlParts[urlParts.length - 1];
                // return here
                return handle;
            }
            // return
            return {
                container: convertedData.container,
                filename: convertedData.filename,
                handle: getHandle(convertedData.url),
                key: convertedData.key,
                mimetype: convertedData.type,
                size: convertedData.size,
                url: convertedData.url,
            };
        });
    }

    /**
     * Returns the raw, initiated filestack client object containing the
     * supported client methods for the Filestack API. See reference [here](https://github.com/filestack/filestack-js)
     *
     * @return {object}
     * @memberof FilestackService
     */
    getClient() {
        return this.client;
    }

    /**
     * Launch the Filestack modal UI to pick one or more files, then store to
     * Amazon S3.
     *
     * @param {object} [storeOptions] Config for storing of file
     * @param {string} [storeOptions.access] "public" or "private". Defaults to public
     * @param {string} [storeOptions.container] the S3 container to store to
     * @param {string} [storeOptions.filename] Optional custom filename
     * @param {string} [storeOptions.path] Path to store the file in, with trailing slash. Defaults to the root
     * @param {object} [customOptions] Custom, Portfolium-defined config values
     * @param {string|string[]} [customOptions.acceptedTypes] If a type name or names are provided, get only those types. Accepted values are "document", "slideshow", "spreadsheet", "design", "audio", "image", and "pdf"
     * @param {object} [filestackOptions] Filestack config values. Sensible defaults are provided, but you can override with your own. For a list of supported options, see the [Filestack docs](https://github.com/filestack/filestack-js#module_filestack..init.pick)
     * @param {Object} [security] Filestack security
     *
     * @return {Promise<object>} Resolved with bbject containing the keys `filesUploaded` and `filesFailed` which are both arrays of file metadata objects.
     * @memberof FilestackService
     */
    pick(
        { access = 'public', container = this.config.storeOptions.container, path } = {},
        { acceptedTypes } = {},
        filestackOptions = {},
        { policy, signature } = {},
    ) {
        // Set default options
        const defaultOptions = {
            fromSources: [
                'dropbox',
                'box',
                'onedrive',
                'local_file_system',
            ],
            disableTransformer: true,
            hideModalWhenUploading: true,
            startUploadingWhenMaxFilesReached: true,
            uploadInBackground: false,
            exposeOriginalFile: true,
        };
        // Build store options to point to our S3 bucket
        const storeOptions = _.assign({}, this.config.storeOptions, {
            access,
            container,
            path,
        });
        // Create base config object, then merge with override options passed in, if any
        const compiledOptions = _.assign(defaultOptions, {
            accept: this.$pfFilestackOptions.getAcceptedTypes(acceptedTypes),
            storeTo: storeOptions,
        }, filestackOptions);
        // sanity check
        if (policy && signature) {
            // set the policy here
            this.client.setSecurity({ policy, signature });
        }
        // pick here with the policy ...
        return this.client.picker(compiledOptions).open();
    }

    /**
     * Upload a new file to Amazon S3 using multi-part chunking. The uploaded
     * content is served immediately via [Filestack's CIN](https://www.filestack.com/docs/advanced-uploads)
     *
     * @param {File|Blob|string} file Valid file, blob, or base64 encoded string to upload
     * @param {object} [storeOptions] Config for storing of file
     * @param {string} [storeOptions.access] "public" or "private". Defaults to public
     * @param {string} [storeOptions.container] the S3 container to store to
     * @param {string} [storeOptions.filename] Optional custom filename
     * @param {string} [storeOptions.path] Path to store the file in, with trailing slash. Defaults to the root
     * @param {object} [uploadOptions] Config for uploading of file
     * @param {onProgress} [uploadOptions.onProgress] Progress callback executed every 1000ms
     * @param {Object} [security] Filestack security
     *
     * @callback {onProgress}
     * @param {object} progress Progress event
     * @param {number} progress.totalPercent Percentage of total file upload progress
     * @param {number} progress.totalBytes Total bytes uploaded across all chunks
     *
     * @return {Promise<object>} Resolved with file metadata when upload is complete
     * @memberof FilestackService
     */
    upload(
        file,
        { access = 'public', container = this.config.storeOptions.container, filename, path } = {},
        { onProgress } = {},
        { policy, signature } = {},
    ) {
        // Build store options to point to our S3 bucket
        const storeOptions = _.assign({}, this.config.storeOptions, {
            access,
            container,
            filename,
            path,
        });
        // sanity check
        if (policy && signature) {
            // set the policy here
            this.client.setSecurity({ policy, signature });
        }
        // upload here with the policy ...
        return this.client.upload(file, { progressInterval: 100, onProgress }, storeOptions);
    }

    /**
     * Stores a file to Amazon S3 using store method in the Javascript API V3 of Filestack.
     * See more in: https://www.filestack.com/docs/javascript-api/store-url-v3
     *
     * @param {string} url              file url to store
     * @param {object} [storeOptions] Config for storing of file
     * @param {string} [storeOptions.access] "public" or "private". Defaults to public
     * @param {string} [storeOptions.container] the S3 container to store to
     * @param {string} [storeOptions.filename] Optional custom filename
     * @param {string} [storeOptions.path] Path to store the file in, with trailing slash. Defaults to the root
     *
     * @return {Promise<object>} Resolved with file metadata when store is complete
     * @memberof FilestackService
     */
    storeUrl(
        url,
        { access = 'public', container = this.config.storeOptions.container, filename, path } = {},
        { policy, signature } = {},
    ) {
        // Build store options to point to our S3 bucket
        const storeOptions = _.assign({}, this.config.storeOptions, {
            access,
            container,
            filename,
            path,
        });
        // sanity check
        if (policy && signature) {
            // set the policy here
            this.client.setSecurity({ policy, signature });
        }
        // store the URL here and go ...
        return this.client.storeURL(url, storeOptions)
            .then(storedData => {
                // return here
                return {
                    container: storedData.container,
                    filename: storedData.filename,
                    handle: storedData.handle,
                    key: storedData.key,
                    size: storedData.size,
                    mimetype: storedData.type,
                    url: storedData.url,
                };
            }).catch(error => {
                // reject this for now
                return this.$q.reject(error);
            });
    }

    /**
     * Launch the Filestack modal UI to crop one or more files, then store to
     * Amazon S3.
     *
     * @param {array<string|object>} filesOrUrls An array of URL or File/Blob values to transform.
     * @param {object} [storeOptions] Config for storing of file
     * @param {string} [storeOptions.access] "public" or "private". Defaults to public
     * @param {string} [storeOptions.container] the S3 container to store to
     * @param {string} [storeOptions.filename] Optional custom filename
     * @param {string} [storeOptions.path] Path to store the file in, with trailing slash. Defaults to the root
     * @param {object} [customOptions] Custom, Portfolium-defined config values
     * @param {string|string[]} [customOptions.acceptedTypes] If a type name or names are provided, get only those types. Accepted values are "document", "slideshow", "spreadsheet", "design", "audio", "image", and "pdf"
     * @param {object} [filestackOptions] Filestack config values. Sensible defaults are provided, but you can override with your own. For a list of supported options, see the [Filestack docs](https://github.com/filestack/filestack-js#module_filestack..init.pick)
     * @param {Object} [security] Filestack security
     *
     * @return {Promise<object>} Resolved with bbject containing the keys `filesUploaded` and `filesFailed` which are both arrays of file metadata objects.
     * @memberof FilestackService
     */
    cropFiles(filesOrUrls,
        { access = 'public', container = this.config.storeOptions.container, path } = {},
        { acceptedTypes } = {},
        filestackOptions = {},
        { policy, signature } = {},
    ) {
        // Set default options
        const defaultOptions = {
            disableTransformer: true,
            hideModalWhenUploading: true,
            startUploadingWhenMaxFilesReached: true,
            uploadInBackground: false,
        };
        // Build store options to point to our S3 bucket
        const storeOptions = _.assign({}, this.config.storeOptions, {
            access,
            container,
            path,
        });
        // Create base config object, then merge with override options passed in, if any
        const compiledOptions = _.assign(defaultOptions, {
            storeTo: storeOptions,
        }, filestackOptions);
        // sanity check
        if (policy && signature) {
            // set the policy here
            this.client.setSecurity({ policy, signature });
        }
        // crop the files and get going ...
        return this.client.picker(compiledOptions).crop(filesOrUrls);
    }

    /**
     * Remove a file by handle
     *
     * @param {String} [handle] Handle of file to delete
     * @param {Object} [security] Filestack security
     *
     * @return {Promise<object>}
     * @memberof FilestackService
     */
    remove(handle, { policy, signature } = {}) {
        // sanity check
        if (policy && signature) {
            // set the policy here
            this.client.setSecurity({ policy, signature });
        }
        // upload here with the policy ...
        return this.client.remove(handle);
    }
}

FilestackService.$inject = [
    '$q',
    '$http',
    '$pfFilestackOptions',
    '$pfFilestackSigner',
    'PF_FILESTACK_CONFIG',
];
