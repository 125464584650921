import angular from 'angular';

import {httpConfig} from './http.config';

const MODULE_NAME = 'portfolium.core.config.http';

(function () {
    angular.module(MODULE_NAME, [])
        .config(httpConfig);
}());

export default MODULE_NAME;
