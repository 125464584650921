import angular from 'angular';

import {AssignmentSubmitComponent} from './assignment-submit/assignment-submit.component';

const MODULE_NAME = 'portfolium.component.assignments';

(function () {
    angular.module(MODULE_NAME, [])
    // Components
    .component('pfAssignmentSubmit', AssignmentSubmitComponent);
}());

export default MODULE_NAME;
