class ShareEmbed
{
    constructor($window) {
        this.$window = $window;
    }

    shareData(data) {
        let popupUrl = data.embedUrl;

        return this.$window.open(popupUrl, '', 'resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0,width=640,height=850');
    }
}

export default ShareEmbed;
