export class HelpChatService {
    constructor($window, $document, $pfCookies, $pfUser) {
        this.$window = $window;
        this.$document = $document;
        this.$pfUser = $pfUser;
        this.isAnalyticalCookiesEnable = $pfCookies.isAnalyticalCookiesEnable();
    }

    hideButton() {
        if (!this.isAnalyticalCookiesEnable) {
            return;
        }
        const authedUser = this.$pfUser.getUser();

        if (authedUser) {
            this.hideSalesForceButton()
        }
    }

    showButton() {
        if (!this.isAnalyticalCookiesEnable) {
            return;
        }
        const authedUser = this.$pfUser.getUser();

        if (authedUser) {
            this.showSalesForceButton()
        }
    }

    hideSalesForceButton() {
        this.$document.find('.embeddedServiceHelpButton').css('display', 'none');
    }

    showSalesForceButton() {
        this.$document.find('.embeddedServiceHelpButton').css('display', 'block');
    }

    logout() {
        // non-op
    }
}

HelpChatService.$inject = [
    '$window',
    '$document',
    '$pfCookies',
    '$pfUser',
];
