import {combineReducers} from 'redux';
import {getPaginationReducer} from 'components/pagination/pagination.reducer';

import {
    EDP_COMMENTS_DECREMENT,
    EDP_COMMENTS_INCREMENT,
    EDP_INIT,
    EDP_ADD_LIKER,
    EDP_LIKE_ENTRY,
    EDP_UNLIKE_ENTRY,
    EDP_RESET_ENTRY,
    EDP_REMOVE_TEAMMATE_FROM_ENTRY,
    EDP_UPDATE_ENTRY,
    edpCommentsActionTypes,
} from './entry-details.actions';

const entryInitialState = {
    entry: {},
    isLoading: false,
};

export const entryDetails = (state = entryInitialState, action = {}) => {
    switch (action.type) {
        case EDP_COMMENTS_DECREMENT: {
            let {comments} = state.entry;
            comments--;
            const entry = _.assign({}, state.entry, {
                comments,
            });
            return _.assign({}, state, {
                entry,
            });
        }
        case EDP_COMMENTS_INCREMENT: {
            let {comments} = state.entry;
            comments++;
            const entry = _.assign({}, state.entry, {
                comments,
            });
            return _.assign({}, state, {
                entry,
            });
        }
        case EDP_INIT: {
            return _.assign({}, state, {
                isLoading: true,
            });
        }
        case EDP_ADD_LIKER: {
            const {liker} = action.payload;
            const {likers} = state.entry;

            const entry = _.assign({}, state.entry, {
                likers: [...state.entry.likers, liker],
            });

            return _.assign({}, state, {
                entry,
            });
        }
        case EDP_LIKE_ENTRY: {
            let {likes} = state.entry;
            likes = parseInt(likes, 10) + 1;

            const entry = _.assign({}, state.entry, {
                liked: '1',
                likes,
            });
            return _.assign({}, state, {
                entry,
            });
        }
        case EDP_UNLIKE_ENTRY: {
            let {likes} = state.entry;
            likes = parseInt(likes, 10) - 1;

            const entry = _.assign({}, state.entry, {
                liked: '0',
                likes,
            });
            return _.assign({}, state, {
                entry,
            });
        }
        case EDP_RESET_ENTRY: {
            return entryInitialState;
        }
        case EDP_REMOVE_TEAMMATE_FROM_ENTRY: {
            const {teammateId} = action.payload;
            const {collaborators} = state.entry;

            const index = _.findIndex(collaborators, {
                id: teammateId,
            });

            // return state if not found
            if (_.isUndefined(index)) {
                return state;
            }

            // update and return new result
            const entry =  _.assign({}, state.entry, {
                    collaborators: [
                        ...collaborators.slice(0, index),
                        ...collaborators.slice(index + 1, collaborators.length)
                    ]
                });
            return _.assign({}, state, {
                entry,
            });
        }
        case EDP_UPDATE_ENTRY: {
            const {entry} = action.payload;
            return _.assign({}, state,
                {
                    entry: entry,
                    isLoading: false,
                }
            );
        }
        default:
            return state;
    }
};

export const entryPanel = combineReducers({
    entryDetails,
    entryComments: getPaginationReducer({
        actionTypes: edpCommentsActionTypes,
        paginationStyle: 'infinite',
        limit: 5,
    }),
});
