class EmployerSearchNavbarController
{
    constructor(
        $scope,
        $pfSubscriptionActions,
        $pfSubscription,
        $pfMassActions,
        $pfNavbar,
        $pfMainNav,
        $pfAppSwitcherAuth,
    ) {
        this.$pfSubscriptionActions = $pfSubscriptionActions;
        this.$pfMassActions = $pfMassActions;
        this.$pfNavbar = $pfNavbar;
        this.$pfMainNav = $pfMainNav;
        this.$pfAppSwitcherAuth = $pfAppSwitcherAuth;

        this.isCompanyAdmin = !!$pfSubscription.getCompanyId();

        // Watch for mass actions mode changes
        $scope.$watch(() => {
            return $pfMassActions.isOpened();
        }, (isOpen) => {
            this.mode = (isOpen) ? 'massActions' : 'default';
        });

        // Watch for mass acounts user count updates
        $scope.$watchCollection(() => {
            return $pfMassActions.activeUsers;
        }, (activeUsers) => {
            this.selected = activeUsers.length;
        });
    }

    /**
     * Is there a previous state to navigate back to?
     * @return {Boolean}
     */
    get hasPreviousState() {
        return !!this.$pfNavbar.previousState;
    }

    get shouldShowNavAppSwitcher() {
        return this.$pfAppSwitcherAuth.hasAccessToManyApps;
    }

    /**
     * Show the main navigation menu
     * @return {Promise}
     */
    openMainNav() {
        return this.$pfMainNav.open();
    }

    back() {
        this.$pfMassActions.close();
    }

    selectAll() {
        this.$pfMassActions.toggleSelectAll();
    }

    sendMessage($event) {
        let users = this.$pfMassActions.getActiveUsers();

        this.$pfSubscriptionActions.sendMessage(users, $event);
    }

    getMassUsers() {
        return this.$pfMassActions.getActiveUsers();
    }

    inviteToApply($event) {
        let users = this.$pfMassActions.getActiveUsers();

        this.$pfSubscriptionActions.inviteToApply(users, $event);
    }
}

EmployerSearchNavbarController.$inject = [
    '$scope',
    '$pfSubscriptionActions',
    '$pfSubscription',
    '$pfMassActions',
    '$pfNavbar',
    '$pfMainNav',
    '$pfAppSwitcherAuth',
];

export default EmployerSearchNavbarController;
