import MobileMenuAuthHeaderTemplate from '../../template/mobile-menu-auth-header.html';

/**
 * @ngdoc controller
 * @name mobileMenuAuthHeaderController
 * @param {Function} closePanel function to close mobile menu
 * @param {Object} user Current user
 * @module portfolium.component.mobileMenu
 **/
 class MobileMenuAuthHeaderController {
    constructor($state, $window) {
        this.$state = $state;
        this.$window = $window;
    }

    /**
     * Routes user to profile according to app state
     * @return {Promise} Resolved when user is routed
     */
    routeToProfile() {
        // Close panel
        this.closePanel();

        // Determine state
        if (!!this.$state.current.name) {
            return this.$state.go('profile.portfolio', {
                username: this.user.username,
            });
        }

        // If not on angular page use window location replace
        return this.$window.location.assign(`/${this.user.username}`);
    }
}

MobileMenuAuthHeaderController.$inject = [
    '$state',
    '$window',
];

/**
 * @ngdoc component
 * @name mobileMenuAuthHeaderComponent
 * @module portfolium.component.mobileMenu
 **/
export const MobileMenuAuthHeaderComponent = {
    bindings: {
        'closePanel': '&',
        'user': '<',
    },
    controller: MobileMenuAuthHeaderController,
    template: MobileMenuAuthHeaderTemplate,
};
