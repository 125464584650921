import { BaseAssignmentUtilitiesService } from './base-assignment-utilities.service';

/**
 * @ngdoc service
 * @name RequirementUtilitiesService
 * @module portfolium.pathway
 **/
 export class RequirementUtilitiesService extends BaseAssignmentUtilitiesService {
     constructor(
        $filter,
        $pfEntryDetails,
        isDefaultImageFilter,
        PF_ASSIGNMENTS_TYPES,
        PF_ASSIGNMENTS_STATUS,
        PF_ASSIGNMENTS_STATUS_MAPPED,
        PF_ASSIGNMENT_ACTION_TYPE_CODES,
        PF_REQUIREMENT_ACTION_TYPE_CODES,
        PF_ENTRY_CLASSIFICATIONS,
        PF_PATHWAY_REQUIREMENT_TYPES,
     ) {
        super(
            $filter,
            $pfEntryDetails,
            PF_ASSIGNMENTS_STATUS,
            PF_ASSIGNMENTS_STATUS_MAPPED,
            PF_ASSIGNMENT_ACTION_TYPE_CODES,
            PF_REQUIREMENT_ACTION_TYPE_CODES,
            PF_ENTRY_CLASSIFICATIONS,
            PF_PATHWAY_REQUIREMENT_TYPES,
        );
        this.isDefaultImageFilter = isDefaultImageFilter;
        this.PF_ASSIGNMENTS_TYPES = PF_ASSIGNMENTS_TYPES;
        this.PF_ASSIGNMENT_ACTION_TYPE_CODES = PF_ASSIGNMENT_ACTION_TYPE_CODES;
        this.PF_REQUIREMENT_ACTION_TYPE_CODES = PF_REQUIREMENT_ACTION_TYPE_CODES;
    }

    /**
     * Generate a title for the unstarted button
     * @param  {Object}  assignment
     * @return {String}
     */
    getUnstartedButtonText(assignment) {
        // sanity check that the assignment data exists
        if (_.isEmpty(assignment)) {
            return 'Start Project';
        }
        return 'Start Requirement';
    }

    /**
     * Generate a title for the submit button
     * @param  {Object}  assignment
     * @return {String}
     */
    getSubmitButtonText(assignment) {
        if (this.needsRevision(assignment) || this.isUnsubmitted(assignment)) {
            return 'Resubmit Requirement';
        }
        return 'Submit Requirement';
    }

    /**
     * Generate a title for the unsubmit button
     * @param  {Object}  assignment
     * @return {String}
     */
    getUnsubmitButtonText(assignment) {
        // sanity check that the assignment data exists
        if (_.isEmpty(assignment)) {
            return 'Unsubmit Project';
        }
        return 'Unsubmit Requirement';
    }

    /**
     * Generate a title for the edit button
     * @param  {Object}  assignment
     * @return {String}
     */
    getEditButtonText(assignment) {
        // sanity check that the assignment data exists
        if (_.isEmpty(assignment)) {
            return 'Edit Project';
        }
        return 'Edit Requirement';
    }

    isUnsubmittable(submission) {
        if (!this.isSubmitted(submission)) {
            return false;
        }
        if (this.isLate(submission)) {
            return false;
        }
        return true;
    }

    /**
     * Checks if an assignment has been previously submitted
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isUnsubmitted(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        const actions = _.get(assignment, 'entry.assignment.actions');
        if (_.isEmpty(actions)) {
            return;
        }
        const { ASSIGNMENT_UNSUBMITTED } = this.PF_REQUIREMENT_ACTION_TYPE_CODES;
        const foundUnsubmitted = _.find(actions, { 'type': ASSIGNMENT_UNSUBMITTED });

        return !this.isSubmitted(assignment)
            && !this.isFinished()
            && !_.isEmpty(foundUnsubmitted);
    }

    /**
     * Checks if an submission type is pending review
     * @param  {Object}  submission
     * @return {Boolean|undefined}
     */
    isPendingReview(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        return this.isSubmitted(submission) && this.isLate(submission);
    }

    /**
     * Checks if an submission is late
     * @param  {Object}  submission
     * @return {Boolean|undefined}
     */
    isLate(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        return this.isLateBase(submission.due_date);
    }

    /**
     * Checks if an submission type is rubric
     * @param  {Object}  submission
     * @return {Boolean|undefined}
     */
    isRubric(submission) {
        const type = this.getType(submission);
        if (!type) {
            return;
        }
        return type.type === 'RUBRIC';
    }

    /**
     * Checks if an submission type is 3 stars
     * @param  {Object}  submission
     * @return {Boolean|undefined}
     */
    is3Star(submission) {
        const type = this.getType(submission);
        if (!type) {
            return;
        }
        return type.type === '3_STAR';
    }

    /**
     * Checks if an submission type is 5 stars
     * @param  {Object}  submission
     * @return {Boolean|undefined}
     */
    is5Star(submission) {
        const type = this.getType(submission);
        if (!type) {
            return;
        }
        return type.type === '5_STAR';
    }

    /**
     * Checks if an submission type is ABCDEF
     * @param  {Object}  submission
     * @return {Boolean|undefined}
     */
    isAbcdf(submission) {
        const type = this.getType(submission);
        if (!type) {
            return;
        }
        return type.type === 'ABCDF';
    }

    /**
     * Checks if an submission type is Pass/Fail
     * @param  {Object}  submission
     * @return {Boolean|undefined}
     */
    isPassFail(submission) {
        const type = this.getType(submission);
        if (!type) {
            return;
        }
        return type.type === 'PASS_FAIL';
    }

    /**
     * Checks if an submission type is Numeric Scoring
     * @param  {Object}  submission
     * @return {Boolean|undefined}
     */
    isNumeric(submission) {
        const type = this.getType(submission);
        if (!type) {
            return;
        }
        return type.type === 'NUMERIC';
    }

    isPassScore(submission) {
        if (!this.isPassFail(submission)) {
            return false;
        }
        return this.getCurrentScore(submission) === 1;
    }

    isFailScore(submission) {
        if (!this.isPassFail(submission)) {
            return false;
        }
        return this.getCurrentScore(submission) === 2;
    }

    /**
     * Checks if an submissions rubric is visible
     * @param  {Object} submission
     * @return {Boolean|undefined}
     */
    isRubricVisible(submission) {
        if (!this.isRubric(submission)) {
            return false;
        }
        return submission.transparency === '1';
    }

    /**
     * Gets grades hidden status
     * @param  {Object}  submission
     * @return {boolean}
     */
    isGradeHidden(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        return submission.grades_hidden === "1";
    }

    hasEarnedBadge(submission) {
        return this.isCompleted(submission);
    }

    hasDueDate(submission) {
        return !_.isNil(this.getDueDate(submission));
    }

    getAttachedEntry(submission) {
        return _.get(submission, 'entry') || {};
    }

    getAssignmentTitle(submission) {
        return _.get(submission, 'title');
    }

    getDescription(submission) {
        return _.get(submission, 'description');
    }

    getGradedAt(submission) {
        return _.get(submission, 'graded_at');
    }

    getDueDate(submission) {
        return _.get(submission, 'due_date');
    }

    getAssignmentImage(submission) {
        const imageSrc = _.get(submission, `entry.cover.file.url`);

        if (!imageSrc) {
            return false;
        }

        if (this.isDefaultImageFilter(imageSrc)) {
            return false;
        }

        return imageSrc;
    }

    /**
     * Finds the type name of an submission
     * @param  {Object} submission
     * @return {String|undefined} submission type name
     */
    getTypeName(submission) {
        const type = this.getType(submission);
        if (!type) {
            return;
        }
        return type.name;
    }

    /**
     * TODO Whenever we update assignment.assignment_type and submission.type properties to be the same, this function can be moved to $pfBaseAssignmentUtilities
     * Also, anything using this function can most likely be shared as well, this is one of the only things holding us back
     *
     * Finds the type of a requirement (assignment) using the requirement submission object
     * @param  {Object} submission
     * @return {Object|undefined} submission type
     */
    getType(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        if (!submission.type) {
            return;
        }
        const currentTypeCode = parseInt(submission.type, 10);
        const type = _.find(this.PF_ASSIGNMENTS_TYPES, ({ id }) => {
            return id === currentTypeCode;
        });
        return type;
    }

    /**
     * get the max possible value of score
     * @param  {Object} submission
     * @return {Number}
     */
    getTotalScore(submission) {
        const type = this.getType(submission);
        if (!type) {
            return;
        }

        if (this.isRubric(submission)) {
            const pointsAvailable = submission.rubric.points_available;
            return parseFloat(pointsAvailable)
        }

        return parseInt(type.total);
    }

    getCurrentScore(submission) {
        if (_.isEmpty(submission)) {
            return;
        }

        const { avg_score } = submission;

        if (_.isEmpty(avg_score)) {
            return;
        }
        if (this.isRubric(submission) || this.isAbcdf(submission)) {
            return parseFloat(avg_score);
        }
        return parseInt(avg_score);
    }

    /**
     * get the max possible value of score
     * @param  {Object} submission
     * @return {Number}
     */
    getScorePercentage(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        const totalScore = this.getTotalScore(submission);
        if (totalScore === 0) {
            return 0;
        }
        let currentScore = this.getCurrentScore(submission);
        if (this.isPassFail(submission)) {
            currentScore = this.calculatePassFailScore(currentScore);
        }
        if (this.isAbcdf(submission)) {
            currentScore = this.calculateLetterGradeScore(currentScore);
        }
        const finalScore = (currentScore * 100) / totalScore;
        return finalScore.toLocaleString('en', {
            style: 'decimal',
            maximumFractionDigits: 2,
        });
    }

    /**
     * Finds the status of an submission
     * @param  {Object} submission
     * @return {Object|undefined} submission status
     */
    getStatus(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        /**
         * check for pending review, we can't have this in the shared service because
         * the due date property is different on assignments and submissions
         */
        if (this.isPendingReview(submission)) {
            submission.status = this.PF_ASSIGNMENTS_STATUS_MAPPED.pendingReview;
        }
        // call the shared getStatus()
        return this.getBaseStatus(submission);
    }

    getBreadCrumbData({ slug, name: label }, { username }) {
        const route = `pathways.pathwayUser({ slug: '${slug}', username: '${username}' })`;
        return {
            route,
            label,
        };
    }

    getBadge(requirement) {
        return _.get(requirement, 'results[0].meta');
    }

    getRequestedRevisionsForFeedbackComponent(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        const actions = _.get(submission, 'entry.assignment.actions', []);
        return this.getBaseRequestedRevisionsForFeedbackComponent(actions);
    }

    getSubmissionSubTitle(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        return this.getSubmissionSubTitleBase({
            isSubmitted: this.isSubmitted(submission),
            submittedAt: submission.submitted_at,
            updatedAt: _.get(submission, 'entry.updated_at', null),
            attachmentCount: this.getAttachmentCount(submission),
        });
    }

    getAttachmentCount(submission) {
        if (_.isEmpty(submission)) {
            return;
        }
        const attachments = _.get(submission, 'entry.attachments');
        if (_.isEmpty(attachments)) {
            return 0;
        }
        return attachments.length;
    }

    getAllLearningOutcomes(submission) {
        if (!this.isRubric(submission)) {
            return;
        }
        return this.getAllLearningOutcomesBase(submission);
    }

    getRubricUrl(submission) {
        if (!this.isRubric(submission)) {
            return;
        }
        const rubricUrl = _.get(submission, 'rubric.rubric_url');
        if (_.isEmpty(rubricUrl)) {
            return;
        }
        return rubricUrl;
    }

    showGradeSection(submission) {
        return this.isInAFinishedLikeState(submission) && !this.isGradeHidden(submission)
    }

    showFeedbackSection(submission, feedback) {
        // we currently don't show feedback for rubric scoring types
        if (this.isRubric(submission)) {
            return;
        }

        return this.isInAFinishedLikeState(submission) && !_.isNil(feedback) && !_.isEmpty(feedback);
    }

    showFeedbackAsNetwork() {
        // for pathways, show the network as the grader
        return true;
    }

    showBadgesSection(requirement, submission) {
        if (_.isEmpty(requirement) || _.isEmpty(submission)) {
            return;
        }
        if (_.isEmpty(this.getBadge(requirement))) {
            return false;
        }
        if (this.hasEarnedBadge(submission)) {
            return true;
        }
        if (this.isNotSatisfied(submission)) {
            return false;
        }
        if (this.isIncomplete(submission)) {
            return false;
        }
        // if we got here. the requirement, has a badge and it has potencial to earn it
        return true;
    }

    showCriteria(submission) {
        if (!this.isInAFinishedLikeState(submission)) {
            return false;
        }
        if (this.isGradeHidden(submission)) {
            return false;
        }
        if (!this.isRubricVisible(submission)) {
            return false;
        }
        return true;
    }

    showRubricGradedUrl(submission) {
        return this.isRubricVisible(submission);
    }

    showRubricPreviewUrl(submission) {
        if (
            this.isInAFinishedLikeState(submission) &&
            !this.isGradeHidden(submission)
        ) {
            return false;
        }
        return this.isRubricVisible(submission);
    }

    isCriteriaNaScored(criteria) {
        // get score and loa id
        const { score, fk_loa_id: loaId } = criteria;
        // sanity check for scored
        if (!_.isNull(score)) {
            // criteria doesnt have score
            return false;
        }
        // sanity check for loa id
        if (_.isNull(loaId)) {
            // criteria has score but it is N/A
            return true;
        }
        // criteria has score and a loa
        return false;
    }
}

RequirementUtilitiesService.$inject = [
    '$filter',
    '$pfEntryDetails',
    'isDefaultImageFilter',
    'PF_ASSIGNMENTS_TYPES',
    'PF_ASSIGNMENTS_STATUS',
    'PF_ASSIGNMENTS_STATUS_MAPPED',
    'PF_ASSIGNMENT_ACTION_TYPE_CODES',
    'PF_REQUIREMENT_ACTION_TYPE_CODES',
    'PF_ENTRY_CLASSIFICATIONS',
    'PF_PATHWAY_REQUIREMENT_TYPES',
];
