import angular from 'angular';

class LoginController
{
    constructor(
        $scope,
        $state,
        $stateParams,
        $location,
        $pfSignInIdentity,
        $pfSignInHistory,
        $pfToast,
        $pfAuth,
        $pfSignInAuth,
        $pfCookies,
        $pfEnv,
        $pfAppSwitcherAuth,
        PF_COOKIES,
        subdomainNetwork,
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$location = $location;
        this.$pfSignInIdentity = $pfSignInIdentity;
        this.$pfSignInHistory = $pfSignInHistory;
        this.$pfToast = $pfToast;
        this.$pfAuth = $pfAuth;
        this.$pfSignInAuth = $pfSignInAuth;
        this.$pfCookies = $pfCookies;
        this.$pfEnv = $pfEnv;
        this.$pfAppSwitcherAuth = $pfAppSwitcherAuth;
        this.PF_COOKIES = PF_COOKIES;
        this.sso = false;
        this.subdomainNetwork = subdomainNetwork;
        // Preset model values
        this.identity = angular.copy($stateParams.identity);
        this.password = null;
        // Check state params to see if identity should be cleared out
        if ($stateParams.clear === true) {
            this.$pfSignInIdentity.clearIdentity();
            this.identity = null;
        }
        // define login paths
        this.loginPaths = {
            edu: 'edu',
            portfolium: 'portfolium',
            tm: 'tm',
        };
        // default login path to EDU, only educators will have access to this
        this.loginPath = this.loginPaths.edu;
        // default showCaptcha
        this.showCaptcha = false;
        // default recaptcha token
        this.recaptchaToken = null;
        // default resetCaptcha
        this.resetCaptcha = false;
        // Setup identity listener
        $scope.$watch('loginCtrl.identity', this.handleIdentityChange.bind(this));
    }

    get profile() {
        return this.$pfSignInIdentity.profile;
    }

    get isValid() {
        return this.$scope.signInCtrl.isValid();
    }

    /**
     * Clear out the network and profile data when user changes identity field
     * @param  {String} newVal New identity value
     * @param  {String} oldVal Old identity value
     */
    handleIdentityChange(newVal, oldVal) {
        if (newVal !== oldVal) {
            this.$pfSignInIdentity.clearIdentity();
            // reset login path to EDU
            this.loginPath = this.loginPaths.edu;
        }
    }

    /**
     * Handle updates from recaptcha and set the token
     */
    setRecaptchaToken({ token }) {
        this.recaptchaToken = token;
    }

    /**
     * Transition to the forgot password page, without validating the form
     * @return {Promise}
     */
    showForgotPassword() {
        return this.$scope.signInCtrl.next('login.forgotPassword', null, false);
    }

    /**
     * Send a password reset request
     * @return {Promise}
     */
    forgotPassword() {
        // return captcha flag to default
        this.resetCaptcha = false;

        // validate form
        if (!this.isValid) {
            return;
        }

        this.loading = true;

        return this.$pfAuth.forgotPassword(this.confirmEmail, this.recaptchaToken).then(() => {
                // Route to success step
                this.$scope.signInCtrl.next('login.forgotSuccess');
            }, (response) => {
                // reset captch
                this.resetCaptcha = true;

                if (response.status === 404) {
                    this.$pfToast.error('We could not find your account with that email');
                    return;
                }

                this.$pfToast.error('Something went wrong, please try again.');
            }).finally(() => {
                this.loading = false;
            });
    }

    /**
     * Determine if user should redirect to EDU
     * @return {boolean}
     */
    shouldRedirectToEdu() {
        return this.$pfSignInIdentity.hasGrowthAccess() && this.loginPath === this.loginPaths.edu;
    }

    /**
     * Determine if user should redirect to TalentMatch
     * @return {boolean}
     */
    shouldRedirectToTalentMatch() {
        return this.$pfSignInIdentity.hasTalentMatchAccess() && this.loginPath === this.loginPaths.tm;
    }
    /**
     * Log the user in with given credentials
     */
    login() {
        this.loading = true;
        this.$pfAuth.login(
            this.identity,
            this.password,
            this.recaptchaToken
        ).then(async () => {

            await this.$scope.signInCtrl.checkIdentity(this.identity)
                .then(async () => {
                    // return captcha flag to default
                    this.resetCaptcha = false;

                    // validate form
                    if (!this.isValid) {
                        return;
                    }

                    this.loading = true;
                });

            // check for TDOE redirect
            if (this.shouldRedirectToEdu()) {
                return await this.$pfAppSwitcherAuth.goToEduFromWeb();
            }

            // reload the page and redirect but first check for flow-based session redirect
            if (this.$pfSignInIdentity.isEmployer) {
                const flowPath = this.$pfCookies.get(this.PF_COOKIES.EMPLOYER_LOGIN_REDIRECT);

                this.$pfAuth.redirect(flowPath);
            } else if (this.$pfSignInIdentity.isNetworkLoginPage) {
                this.$pfAuth.redirect(
                    `${this.$location.$$protocol}://${this.$location.$$host}/network/${this.$pfSignInIdentity.network.slug}`,
                    false
                );
            } else {
                this.$pfAuth.redirect();
            }
            // remove the employer login redirect cookie
            this.$pfCookies.remove(this.PF_COOKIES.EMPLOYER_LOGIN_REDIRECT, {
                domain: this.$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                path: '/',
            });
        }, (response) => {
            // reset the captcha
            this.resetCaptcha = true;
            // set message
            let message = response.data.error;
            // check for deleted account - status 423
            if (response.status === 423) {
                const {
                    token,
                    requested_at,
                    will_delete_at,
                } = response.data;

                return this.$pfAuth
                    .triggerAccountLockedError(token, requested_at, will_delete_at);
            }

            if (response.status === 429) {
                this.showCaptcha = !this.showCaptcha;
                message = "Login information is invalid";

            }

            if (response.status > 499) {
                message = "Something went wrong, please try again.";
            }

            this.$pfToast.error(message);
            this.loading = false;
        });

    }
}

LoginController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$location',
    '$pfSignInIdentity',
    '$pfSignInHistory',
    '$pfToast',
    '$pfAuth',
    '$pfSignInAuth',
    '$pfCookies',
    '$pfEnv',
    '$pfAppSwitcherAuth',
    'PF_COOKIES',
    'subdomainNetwork',
];

export default LoginController;
