export const PATHWAY_REQUIREMENT_STATUS_MAPPED = {
    notStarted: 0,
    inProgress: 1,
    needsRevision: 2,
    submitted: 3,
    completed: 4,
    incomplete: 5,
    pendingReview: 6,
    notSatisfied: 7,
};

export const PATHWAY_REQUIREMENT_STATUS = [
    {
        id: PATHWAY_REQUIREMENT_STATUS_MAPPED.notStarted,
        name: 'Not Started',
        code: 'NOT_STARTED',
        icon: 'warning',
        class: 'pf-status-widget__icon--pending',
    },
    {
        id: PATHWAY_REQUIREMENT_STATUS_MAPPED.inProgress,
        name: 'In Progress',
        code: 'IN_PROGRESS',
        icon: 'refresh',
        class: 'pf-status-widget__icon--in-progress',
    },
    {
        id: PATHWAY_REQUIREMENT_STATUS_MAPPED.needsRevision,
        name: 'Revisions Requested',
        code: 'NEEDS_REVISION',
        icon: 'autorenew',
        class: 'pf-status-widget__icon--pending',
    },
    {
        id: PATHWAY_REQUIREMENT_STATUS_MAPPED.submitted,
        name: 'Submitted',
        code: 'SUBMITTED',
        icon: 'assignment_turned_in',
        class: 'pf-status-widget__icon--in-progress',
    },
    {
        id: PATHWAY_REQUIREMENT_STATUS_MAPPED.completed,
        name: 'Scored',
        code: 'COMPLETED',
        icon: 'done',
        class: 'pf-status-widget__icon--complete',
    },
    {
        id: PATHWAY_REQUIREMENT_STATUS_MAPPED.incomplete,
        name: 'Incomplete',
        code: 'INCOMPLETE',
        icon: 'close',
        class: 'pf-status-widget__icon--incomplete',
    },
    {
        id: PATHWAY_REQUIREMENT_STATUS_MAPPED.pendingReview,
        name: 'Pending Review',
        code: 'PENDING_REVIEW',
        icon: 'assignment_turned_in',
        class: 'pf-status-widget__icon--in-progress',
    },
    {
        id: PATHWAY_REQUIREMENT_STATUS_MAPPED.notSatisfied,
        name: 'Scored',
        code: 'NOT_SATISFIED',
        icon: 'close',
        class: 'pf-status-widget__icon--incomplete',
    },
];
