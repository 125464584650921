export const PROFILE_QUICK_EDIT_UPDATE_CURRENT_USER     = 'PROFILE_QUICK_EDIT_UPDATE_CURRENT_USER';
export const PROFILE_QUICK_EDIT_UPDATE_ABOUTS           = 'PROFILE_QUICK_EDIT_UPDATE_ABOUTS';
export const PROFILE_QUICK_EDIT_ON_SUBMIT               = 'PROFILE_QUICK_EDIT_ON_SUBMIT';
export const PROFILE_QUICK_EDIT_UPDATE_EDUCATIONS       = 'PROFILE_QUICK_EDIT_UPDATE_EDUCATIONS';
export const PROFILE_QUICK_EDIT_UPDATE_EXPERIENCES      = 'PROFILE_QUICK_EDIT_UPDATE_EXPERIENCES';
export const PROFILE_QUICK_EDIT_UPDATE_LOCATIONS        = 'PROFILE_QUICK_EDIT_UPDATE_LOCATIONS';
export const PROFILE_QUICK_EDIT_RESET_CURRENT_USER      = 'PROFILE_QUICK_EDIT_RESET_CURRENT_USER';
export const PROFILE_QUICK_EDIT_RESET_ABOUTS            = 'PROFILE_QUICK_EDIT_RESET_ABOUTS';

/**
 * @description Update the current user state
 * @param   {Object}   currentUser  The current user data
 * @returns {Object}
 */
export const updateCurrentUser = (currentUser) => {
    return {
        type: PROFILE_QUICK_EDIT_UPDATE_CURRENT_USER,
        payload: {
            currentUser,
        },
    };
};

/**
 * @description Reset the current user state
 * @returns {Object}
 */
export const resetCurrentUser = () => {
    return {
        type: PROFILE_QUICK_EDIT_RESET_CURRENT_USER,
    };
};

/**
 * @description Update the abouts state
 * @param   {Object}   abouts  The about data
 * @returns {Object}
 */
export const updateAbouts = (abouts) => {
    return {
        type: PROFILE_QUICK_EDIT_UPDATE_ABOUTS,
        payload: {
            abouts,
        },
    };
};

/**
 * @description Set the data from form submit
 * @param   {Object}   payload  The form data
 * @returns {Object}
 */
export const setSubmitData = (payload) => {
    return (dispatch, getState) => {
        // set current education
        dispatch(setCurrentEducation(payload.current_education_id));
        // set current experience
        dispatch(setCurrentExperience(payload.current_experience_id));
        // set current location
        dispatch(setCurrentLocation(payload.current_location_id));
        // update user data
        dispatch(updateCurrentUser(payload));
    };
};

/**
 * @description Update the educations state
 * @param   {Object}   educations  The education data
 * @returns {Object}
 */
export const updateEducations = (educations) => {
    return {
        type: PROFILE_QUICK_EDIT_UPDATE_EDUCATIONS,
        payload: {
            educations,
        },
    };
};

/**
 * @description Update the experiences state
 * @param   {Object}   experiences  The education data
 * @returns {Object}
 */
export const updateExperiences = (experiences) => {
    return {
        type: PROFILE_QUICK_EDIT_UPDATE_EXPERIENCES,
        payload: {
            experiences,
        },
    };
};

/**
 * @description Update the locations state
 * @param   {Object}   locations  The location data
 * @returns {Object}
 */
export const updateLocations = (locations) => {
    return {
        type: PROFILE_QUICK_EDIT_UPDATE_LOCATIONS,
        payload: {
            locations,
        },
    };
};

/**
 * @description Reset the abouts state
 * @returns {Object}
 */
export const resetAbouts = () => {
    return {
        type: PROFILE_QUICK_EDIT_RESET_ABOUTS,
    };
};

/**
 * @description Set the education as defined by `id` to 'current'
 * @returns {Function}
 */
export const setCurrentEducation = (id) => {
    return (dispatch, getState) => {
        if (id) {
            const {profileQuickEdit} = getState();
            const educations = _.map(profileQuickEdit.abouts.educations, (education) => {
                education.current = (id === education.id) ? '1' : '0';
                return education;
            });
            dispatch(updateEducations(educations));
        }
    };
};

/**
 * @description Set the experience as defined by `id` to 'current'
 * @returns {Function}
 */
export const setCurrentExperience = (id) => {
    return (dispatch, getState) => {
        if (id) {
            const {profileQuickEdit} = getState();
            const experiences = _.map(profileQuickEdit.abouts.experiences, (experience) => {
                experience.current = (id === experience.id) ? '1' : '0';
                return experience;
            });
            dispatch(updateExperiences(experiences));
        }
    };
};

/**
 * @description Set the location as defined by `id` to 'current'
 * @returns {Function}
 */
export const setCurrentLocation = (id) => {
    return (dispatch, getState) => {
        if (id) {
            const {profileQuickEdit} = getState();
            const locations = _.map(profileQuickEdit.locations, (location) => {
                location.current = (id === location.id) ? '1' : '0';
                return location;
            });
            dispatch(updateLocations(locations));
        }
    };
};
