import moment from 'moment';
import JobResultTemplate from './job-result.component.html';
import './job-result.component.scss';
/**
 * @ngdoc controller
 * @name jobResultController
 * @param {Object} jobResult job to show
 * @param {Boolean} [show-description] whether to show or not the
 *  description
 * @param {String} [pf-source] source of the data, if 'es', will parse for
 *  Elastic Search data.
 * @module portfolium.component.jobResult
 **/
class JobResultController {
    constructor($pfJobDetails, $window) {
        this.$pfJobDetails = $pfJobDetails;
        this.$window = $window;
    }

    $onInit() {
        this.job = this._normalizeJob(this.jobResult);
    }

    /**
    * @ngdoc method
    * @name jobPostedAgo
    * @description Get length of time ago the job was posted
    * @param {Object} job
    * @return {String} Returns time ago job was posted
    **/
    get jobPostedAgo() {
        return this.$pfJobDetails.getJobPostedAgo(this.job);
    }

    /**
    * @ngdoc method
    * @name jobLocation
    * @description Get Location of the Job.
    * @param {Object} job
    * @return {String} Returns location of a job as {City, State}
    **/
    get jobLocation() {
        return this.$pfJobDetails.getJobLocation(this.job);
    }

    /**
    * @ngdoc method
    * @name _normalizeJob
    * @description Normalize job to match the UI specs
    * @param {Object} job
    * @return {Object}
    **/
    _normalizeJob(job) {
        if (this.pfSource === 'es') {
            return this.$pfJobDetails.jobNormalizerES(job);
        }
        return this.$pfJobDetails.jobNormalizerSearch(job);
    }
}

JobResultController.$inject = [
    '$pfJobDetails',
    '$window'
];

/**
 * @ngdoc component
 * @name jobResultComponent
 * @module portfolium.component.jobResult
 **/
export const JobResultComponent = {
    bindings: {
        'jobResult': '<',
        'showDescription': '<',
        'pfSource': '@',
    },
    bindToController: true,
    template: JobResultTemplate,
    controller: JobResultController,
    controllerAs: '$ctrl'
};
