import angular from 'angular';

class NetworkController
{
    constructor(
        $scope,
        $state,
        $pfNetwork,
        $pfSignInAuth,
        $pfSignInIdentity,
        $pfToast,
        PF_ACCOUNT_TYPES
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$pfNetwork = $pfNetwork;
        this.$pfSignInAuth = $pfSignInAuth;
        this.$pfSignInIdentity = $pfSignInIdentity;
        this.$pfToast = $pfToast;
        this.PF_ACCOUNT_TYPES = PF_ACCOUNT_TYPES;
        // Preset model values
        this.network = (!!this.$pfSignInIdentity.network.id) ? angular.copy(this.$pfSignInIdentity.network) : null;
    }

    get isValid() {
        return this.$scope.signInCtrl.isValid();
    }

    /**
     * Validate the selected network and determine which action to take
     * @return {undefined|Proimise}
     */
    submitNetwork() {
        if (!this.isValid) {
            return;
        }
        // Update the new values
        this.$pfSignInIdentity.network = this.schoolNetwork.network;
        this.$pfSignInIdentity.school = this.schoolNetwork.school;

        if (this.$pfSignInIdentity.isPrepoppedNetwork) {
            // Route to existing network step for prepopped networks
            return this.$scope.signInCtrl.next('register.existing');
        }

        // Otherwise, create a new user
        return this.$scope.registerCtrl.showCaptcha(this.createUser.bind(this));
    }

    /**
     * Check if the users identity is an email address
     * @return {Boolean}
     */
    isEmail() {
        return this.$pfSignInIdentity.isEmail(this.$scope.registerCtrl.email);
    }

    /**
     * Create a new user account
     * @param {String} userType Type of user to start onboarding
     * @return {Promise}
     */
    createUser(userType = this.PF_ACCOUNT_TYPES.unknown.name) {
        const { email, recaptchaToken } = this.$scope.registerCtrl;

        return this.$pfSignInAuth.createAccount(email, false, userType, recaptchaToken)
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * Update the value of pfSignInIdentity so it updates the image
     * and logo of the school.
     */
    updateSchool() {
        this.$pfSignInIdentity.school = this.schoolNetwork.school;
        this.$pfSignInIdentity.network = this.schoolNetwork.network;
    }
}

NetworkController.$inject = [
    '$scope',
    '$state',
    '$pfNetwork',
    '$pfSignInAuth',
    '$pfSignInIdentity',
    '$pfToast',
    'PF_ACCOUNT_TYPES',
];

export default NetworkController;
