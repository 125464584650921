function stateChangeFocusDirective($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            let listener = scope.$on('$stateChangeSuccess', () => {
                elem.focus();
            });

            elem.on('$destroy', listener);
        }
    };
}

stateChangeFocusDirective.$inject = ['$timeout'];

export default stateChangeFocusDirective;
