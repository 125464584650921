/**
 * Notifcation Center Hook
 */
export const initializeNotificationCenter = (
    $pfUser,
    $pfNotificationCenter,
    $pfNotificationCenterPanel,
    $location
) => {
    /**
     * Check if authed, then get unread count, determine unread notification visibility and set the store
     */
    if ($pfUser.getUser()) {
        $pfNotificationCenter.getUnreadCount().then((unreadCount) => {
            $pfNotificationCenter.storeNotificationCenterCount(unreadCount);
        });
    }

    /**
     * Look for the notification_panel param that tells us if we should open the notification center
     */
    const locationParams = $location.search();
    const notificationPanelParam = locationParams.notification_panel || null;
    const openNotificationPanel = _.isNil(notificationPanelParam) ? false : notificationPanelParam === '1';

    if (openNotificationPanel) {
        $pfNotificationCenterPanel.open();
    }
};

initializeNotificationCenter.$inject = [
    '$pfUser',
    '$pfNotificationCenter',
    '$pfNotificationCenterPanel',
    '$location',
];
