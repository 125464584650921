/**
 * These templates are used with toggle-popover.directive
 * and popover-panel.service which injects 'content' into its controller '$ctrl'
 */
export const USER_CARD_TEMPLATES = {
    extraDense: {
        standard: `
            <pf-user-card-extra-dense
                user="$ctrl.content"
                md-panel-ref="$ctrl.mdPanelRef"
                link-target="{{ $ctrl.linkTarget }}">
            <pf-user-card-extra-dense>
        `,
        withStats: `
            <pf-user-card-extra-dense
                user="$ctrl.content"
                show-stats="true"
                md-panel-ref="$ctrl.mdPanelRef"
                link-target="{{ $ctrl.linkTarget }}">
            <pf-user-card-extra-dense>
        `,
    },
}
