import angular from 'angular';

import {SchoolAutocompleteComponent} from './school-autocomplete.component';

import './school-autocomplete.component.scss';

const MODULE_NAME = 'portfolium.component.schoolAutocomplete';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfSchoolAutocomplete', SchoolAutocompleteComponent);
}());

export default MODULE_NAME;
