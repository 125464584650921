import angular from 'angular';

import { LanguagePickerComponent } from './language-picker.component';

import './language-picker.component.scss';

const MODULE_NAME = 'portfolium.component.LanguagePickerModule';

(function() {
    angular
        .module(MODULE_NAME, [])
        .component('pfLanguagePicker', LanguagePickerComponent);
})();

export default MODULE_NAME;
