import {setUserAbout} from '../reducers/user-about.actions';

export class UserAboutService
{
    constructor($q, $http, $ngRedux, $pfUser) {
        this.$q = $q;
        this.$http = $http;
        this.$ngRedux = $ngRedux;
        this.$pfUser = $pfUser;
    }

    /**
     * @description Fetch current user about data
     * @returns {Promise}
     */
    getMyAbout() {
        const authedUser = this.$pfUser.getUser();
        return this.$q((resolve, reject) => {
            if (!authedUser) {
                return reject(new Error('authedUser is not set'));
            }

            return resolve(this.getAboutByUsername(authedUser.username));
        });
    }

    /**
     * @description Fetch current user's primary education; the criteria for "primary" education is defined in the reducer
     * @returns {Promise}
     */
    getMyPrimaryEducation() {
        return this.$q((resolve, reject) => {
            this._getCurrentUserState().then((currentUser) => {
                return resolve(currentUser.primaryEducation);
            }, reject);
        });
    }

    /**
     * @description Fetch current user's primary experience; the criteria for "primary" experience is defined in the reducer
     * @returns {Promise}
     */
    getMyPrimaryExperience() {
        return this.$q((resolve, reject) => {
            this._getCurrentUserState().then((currentUser) => {
                return resolve(currentUser.primaryExperience);
            }, reject);
        });
    }

    /**
     * @description Update current users about data
     * @param   {Object}   data (optional) The about user data object
     * @returns {Void}
     */
    updateMyAbout(data) {
        const authedUser = this.$pfUser.getUser();

        return this.$q((resolve, reject) => {
            if (!authedUser) {
                return reject(new Error('authedUser is not set'));
            }

            if (!_.isEmpty(data)) {
                // update currentUser userAbout state
                return resolve(this.$ngRedux.dispatch(setUserAbout(data)));
            }

            // or update from the api
            return resolve(this.getAboutByUsername(authedUser.username, true));
        });

    }

    /**
     * @description Fetch user's about me data by username
     * @param   {String}   username Username
     * @param   {Boolean}  force    If true, bypass redux cache and fetch from api
     * @returns {Promise}
     */
    getAboutByUsername(username, force) {
        const authedUser = this.$pfUser.getUser();
        let isCurrentUser = false;

        if (authedUser && (username === authedUser.username)) {
            isCurrentUser = true;
        }

        // save an api call if we already have user about data
        if (!force && isCurrentUser) {
            const { currentUser } = this.$ngRedux.getState();
            if (!_.isEmpty(currentUser.userAbout)) {
                return this.$q((resolve, reject) => {
                    return resolve(currentUser.userAbout);
                });
            }
        }

        return this.$http({
            method: 'GET',
            url: `/proxy/users/about/${username}/slug`
        })
        .then((response) => {
            // if username is authed user, save state data
            if (isCurrentUser) {
                // update currentUser userAbout state
                this.$ngRedux.dispatch(setUserAbout(response.data));
            }

            return response.data;
        });
    }

    /**
     * @description Get currentUser from state or load it from api
     * @returns {Promise}
     */
    _getCurrentUserState() {
        const authedUser = this.$pfUser.getUser();
        const { currentUser } = this.$ngRedux.getState();
        return this.$q((resolve, reject) => {
            if (!authedUser) {
                return reject(new Error('authedUser is not set'));
            }
            // if userAbout is set, return the state here
            if (!_.isEmpty(currentUser.userAbout)) {
                return resolve(currentUser);
            }
            // otherwise update from the api
            return this.getAboutByUsername(authedUser.username).then((about) => {
                // get the current state data
                const { currentUser } = this.$ngRedux.getState();
                return resolve(currentUser);
            }, reject);
        });
    }
}

UserAboutService.$inject = [
    '$q',
    '$http',
    '$ngRedux',
    '$pfUser',
];
