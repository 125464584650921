import EntryViewerDescriptionTemplate from './entry-viewer-description.component.html';
import './entry-viewer-description.component.scss';

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerDescriptionComponent
 * @description Display description in the entry viewer
 *
 * @param {string}  description Entry description
 * @param {boolean} isPreview   Is the user viewing a preview?
 * @param {boolean} isLoading   Is the entry loading?
 */
export const EntryViewerDescriptionComponent = {
    bindings: {
        description: '<',
        isPreview: '<',
        isLoading: '<',
    },
    controller: () => {},
    controllerAs: '$ctrl',
    template: EntryViewerDescriptionTemplate,
};
