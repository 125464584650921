import moment from 'moment';

export class JobDetailsService {
    constructor($filter, $pfDate, PF_JOB_STATES, PF_JOB_TYPES) {
        this.$filter = $filter;
        this.$pfDate = $pfDate;
        this.PF_JOB_STATES = PF_JOB_STATES;
        this.PF_JOB_TYPES = PF_JOB_TYPES;
    }

    /**
    * @ngdoc method
    * @name JobDetailsService#getJobState
    * @methodOf JobDetailsService
    * @description
    * Return current job state
    * @param {Object} job
    * @param {String} source Source of the job. If from elastic search, pass in 'es'
    * @return {String} Current job state
    **/
    getJobState(job, source = null) {
        // default to new
        let jobState = this.PF_JOB_STATES.newJob;
        // check if the job was hydrated (editing a job vs. new job)
        if (_.has(job, 'expires_on')) {
            // Set todays date and job.expires_on date for comparison
            let jobExpiresOn = this.$pfDate.utcToLocal(job.expires_on).toDate();

            // check for elastic search as source, es source uses unix timestamp for expires_on
            if (source === 'es') {
                jobExpiresOn = moment.unix(job.expires_on).toDate();
            }

            let todaysDate = moment().toDate();
            let jobPublished = job.published === '0' ? false : true;

            // Determine job state
            if (jobPublished && (jobExpiresOn > todaysDate)) {
                jobState = this.PF_JOB_STATES.activeJob;
            } else if (jobPublished && (jobExpiresOn <= todaysDate)) {
                jobState = this.PF_JOB_STATES.expiredJob;
            } else if (!jobPublished && (job.expires_on === null)) {
                jobState = this.PF_JOB_STATES.draftJob;
            }
        }
        // return the state here
        return jobState;
    }

    /**
    * @ngdoc method
    * @name JobDetailsService#getJobLocation
    * @methodOf JobDetailsService
    * @description
    * Get Location of the Job.
    * @param {Object} job
    * @return {String} Returns location of a job as {City, State}
    **/
    getJobLocation(job) {
        if (!job) {
            return;
        }
        // this is here to protect the first load of the job when the object isn't there
        if (!job.slug) {
            return null;
        }

        if (job.anywhere) {
            return 'Anywhere';
        }

        // check if the job was already normalized
        if (!job.address) {
            // normalize the job
            job = this.jobNormalizerSearch(job);
        }

        // this catches the case if a job was imported for "anywhere"
        // but doesn't have the flag set and is missing locations
        if (job.address === 'null, null'
            || job.address === ', '
            || job.address === 'undefined, undefined') {
            return 'Anywhere';
        }

        return job.address;
    }

    /**
    * @ngdoc method
    * @name jobAvatar#getAvatar
    * @methodOf jobAvatar
    * @description
    * Return job image if there is one set. Will show company image as default.
    * @param  {Object} job Job Object containing image information.
    * @return {String} Url of the image to show.
    **/
    getAvatar(job = {}) {
        // If it is already normalized just return it
        if (_.isString(job.image)) {
            return job.image;
        }

        // if the source of the image is set, then it means
        // it has an image set
        if (job.image && job.image.source) {
            return job.image.url_https;
        }

        // if the job does not have an image set, dont show the default empty,
        // show the company one
        if (job.company && job.company.logo && job.company.logo.url_https) {
            return job.company.logo.url_https;
        }

        return null;
    }

    /**
    * @ngdoc method
    * @name JobDetailsService#getEmploymentType
    * @methodOf JobDetailsService
    * @description
    * Get mapped employment type
    * @param {Object} job
    * @return {String}
    **/
    getEmploymentType(job) {
        // sanity check for job
        if (_.isEmpty(job)) {
            return;
        }
        // find the object containing the job type sent
        // from the API
        let key = _.find(this.PF_JOB_TYPES, (type) => {
            return type.name === job.type;
        });
        // sanity check to see if found the type
        if (key && key.displayName) {
            // return the readable type
            return key.displayName;
        }
        return;
    }

    /**
    * @ngdoc method
    * @name JobDetailsService#getJobPostedAgo
    * @methodOf JobDetailsService
    * @description
    * Get length of time ago the job was posted.
    * @param {Object} job
    * @return {String} Returns time ago job was posted
    **/
    getJobPostedAgo(job) {
        // If job has a posted data in the future, default to today
        if (this.$pfDate.utcToLocal(job.posted_at) > moment()) {
            return 'today';
        }

        return this.$pfDate.utcToLocal(job.posted_at).from(moment());
    }

    /**
     * Check if job is expired
     * @param {Object} job
     * @param {String} source
     * @return {Boolean}
     */
    isExpired(job, source = null) {
        return this.PF_JOB_STATES.expiredJob === this.getJobState(job, source);
    }

    /**
     * Check if the job is a Network Only Job (NOJ)
     * These are only for Prepop Employers
     * @param {Array} networks The jobs networks
     * @return {Boolean} the NOJ flag
     */
    isNetworkOnlyJob(networks) {
        return !_.isEmpty(networks);
    }

     /**
     * @ngdoc method
     * @name JobDetailsService#isActiveNetworkOnlyJob
     * @methodOf JobDetailsService
     * @description Check if the job is an Active Network Only Job (NOJ)
     * @param {Object} job The job
     * @return {Boolean} the NOJ flag
     **/
    isActiveNetworkOnlyJob(job) {
        return this.getJobState(job) === this.PF_JOB_STATES.activeJob && this.isNetworkOnlyJob(job.networks);
    }

    /**
    * @ngdoc method
    * @name JobDetailsService#jobNormalizerSearch
    * @methodOf JobDetailsService
    * @description Normalize the data sent from the Search
    * endpoint
    * @param {Object} job
    * @return {Object}
    **/
    jobNormalizerSearch(job) {
        // sanity check for job slug
        // TM jobs will return with only an ID until they resolve
        if (!job.slug) {
            return;
        }

        if (!job.company) {
            job.company = {
                name: '',
            }
        }

        return {
            id: job.id,
            slug: job.slug,
            description: job.description,
            image: this.getAvatar(job),
            title: job.title,
            companyName: job.company.name,
            address: job.city + ', ' + job.state,
            anywhere: job.anywhere === '1',
        }
    }

    /**
    * @ngdoc method
    * @name JobDetailsService#jobNormalizerES
    * @methodOf JobDetailsService
    * @description Normalize the data sent from the Elastic Search
    * endpoint
    * @param {Object} job
    * @return {Object}
    **/
    jobNormalizerES(job) {
        return {
            id: job.id,
            slug: job.slug,
            description: job.description,
            image: this.getAvatar(job),
            title: job.title,
            companyName: job.company,
            address: job.address,
            anywhere: job.anywhere,
        }
    }
}

JobDetailsService.$inject = ['$filter', '$pfDate', 'PF_JOB_STATES', 'PF_JOB_TYPES'];
