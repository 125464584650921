export const JOB_SUMMARY_APPLICATION_REQUEST    = 'JOB_SUMMARY_APPLICATION_REQUEST';
export const JOB_SUMMARY_APPLICATION_SUCCESS    = 'JOB_SUMMARY_APPLICATION_SUCCESS';
export const JOB_SUMMARY_APPLICATION_RESET    = 'JOB_SUMMARY_APPLICATION_RESET';

export const jobApplicationRequest = (applicationId) => {
    return {
        type: JOB_SUMMARY_APPLICATION_REQUEST,
        payload: applicationId,
    };
};

export const jobApplicationSuccess = (application) => {
    return {
        type: JOB_SUMMARY_APPLICATION_SUCCESS,
        payload: application,
    };
};

export const jobApplicationReset = () => {
    return {
        type: JOB_SUMMARY_APPLICATION_RESET,
    };
};
