/**
 * Clears all local storage data when the user logs out.
 */
function logoutTasksDirective($pfAuth) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.on('click', () => {
                $pfAuth.logoutTasks();
            });
        }
    };
}

logoutTasksDirective.$inject = ['$pfAuth'];

export default logoutTasksDirective;
