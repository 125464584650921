import angular from 'angular';
import { CONNECTION_STATUS } from '../js/model/connection-status';

import {
    addConnectedUser,
    addPendingUser,
    addRequestingUser,
    removeConnectedUser,
    removePendingUser,
    removeRequestingUser,
} from './user-connect.actions';

import UserConnectSheetController from '../js/controller/user-connect-sheet-controller';

import UserConnectSheetTemplate from '../template/user-connect-sheet.html'
import UserConnectBtnTemplate from './user-connect-btn.html'
import NotificationConnectBtnTemplate from './notification-connect-btn.html'

/**
 * @ngdoc controller
 * @name UserConnectBtnController
 * @module portfolium.component.userConnect
 */
class UserConnectBtnController {
    constructor(
        $mdDialog,
        $mdBottomSheet,
        $pfUser,
        $pfUserConnect,
        $ngRedux,
        $pfToast,
        $pfNotificationCenter,
        $filter,
    ) {
        this.$mdDialog = $mdDialog;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$pfUser = $pfUser;
        this.$pfUserConnect = $pfUserConnect;
        this.$ngRedux = $ngRedux;
        this.$pfToast = $pfToast;
        this.$pfNotificationCenter = $pfNotificationCenter;
        this.$filter = $filter;
        this.loaded = false;
        this.init();
    }

    $onChanges(changed) {
        if (
            !_.isEmpty(changed.user) &&
            !_.isEmpty(changed.user.currentValue) &&
            !this.loaded
        ) {
            this.loaded = true;
            this.setInitialState();
        }
    }

    $onDestroy() {
        // Unsubscribe to state changes
        this.unsubscribe();
    }

    init() {
        // Subscribe to state changes
        this.unsubscribe = this.$ngRedux.connect(this.mapStateToThis, {
            addConnectedUser,
            addPendingUser,
            addRequestingUser,
            removeConnectedUser,
            removePendingUser,
            removeRequestingUser,
        })(this);
    }

    mapStateToThis(state) {
        return {
            userConnections: state.userConnections,
        };
    }

    /**
     * Evaluate initial data and set the state
     * @return {Void}
     */
    setInitialState() {
        if (_.isEmpty(this.user)) {
            return;
        }

        // set initial status
        this.status = this.getStatus();

        // set connected state
        if (this._isConnected()) {
            this.addConnectedUser(this.user.id);
        }

        // set pending state
        if (this._isPending()) {
            this.addPendingUser(this.user.id);
        }

        // set requester state
        if (this.status && this._isPending() && _.isNull(this.status.token)) {
            this.addRequestingUser(this.user.id);
        }
    }

    /**
     * Get the connection object for this user
     * @return {Object}
     */
    getConnection() {
        return this.userConnections[this.user.id] || {};
    }

    /**
     * Check the redux state to see if if the user is connected
     * @return {Boolean}
     */
    isConnected() {
        const connection = this.getConnection();
        return connection.connected;
    }

    /**
     * Check the redux state to see if if the user is pending
     * @return {Boolean}
     */
    isPending() {
        const connection = this.getConnection();
        return connection.pending;
    }

    /**
     * Check the redux state to see if if the user is the requester
     * @return {Boolean}
     */
    isRequester() {
        const connection = this.getConnection();
        return connection.isRequester;
    }

    /**
     * Get the user connection status
     * @return {Object}
     */
    getStatus() {
        return this.user.status;
    }

    /**
     * Is the user currently on a mobile device?
     * @return {Boolean}
     */
    get isMobile() {
        return this.$pfUser.isMobile();
    }

    /**
     * Is the button a pending menu type?
     * @return {Boolean|undefined}
     */
    isPendingMenu() {
        if (!this.status) {
            return;
        }
        return this.isPending() && !this.isRequester();
    }

    /**
     * Check the user object to see if the user is connected?
     * @return {Boolean}
     */
    _isConnected() {
        return this.user.connected === '1';
    }

    /**
     * Check the user object to see if the user is pending?
     * @return {Boolean}
     */
    _isPending() {
        if (
            this.status &&
            this.status.status === 'pending' &&
            this.notificationStatus !== CONNECTION_STATUS.deleted
        ) {
            return true;
        }

        if (this.notificationStatus === CONNECTION_STATUS.requested) {
            return true;
        }

        return false;
    }

    /**
     * Is the connect button disabled?
     * @return {Boolean}
     */
    get isDisabled() {
        if (this.isPending() && (this.isRequester() || this.isIconButton)) {
            return true;
        }

        if (this.isConnected() && this.isNotificationButton) {
            return true;
        }

        if (this.isPending() && this.isNotificationButton) {
            return true;
        }

        return false;
    }

    /**
     * Get the object to be used in the ngClass
     * @return {Object}
     */
    getNgClass() {
        const ngClass = {
            'pf-connected': this.isConnected(),
            'pf-mobile': this.isMobile,
            'pf-pending': this.isPending(),
            'pf-pending-menu': this.isPendingMenu(),
            'md-portfolium-theme pf-notification-button': this
                .isNotificationButton,
            'pf-icon-text-button': !this.isIconButton,
        };
        return ngClass;
    }

    /**
     * Handle the click event to take the appropriate action based on current status
     * @param  {Event}    $event      Click event from ng-click
     * @param  {Function} $mdMenuOpen Menu open function from mdMenu scope
     * @return {Promise}
     */
    onClick($event, $mdMenuOpen) {
        if (this.isConnected()) {
            if (this.isMobile) {
                return this.removeConnectionSheet($event);
            }

            return this.removeConnectionDialog($event);
        }

        if (this.isPending() && !this.isRequester()) {
            $event.preventDefault();

            return $mdMenuOpen($event);
        }

        if (this.messageDialog) {
            return this.addConnectionDialog($event);
        }

        return this.addConnection($event);
    }

    /**
     * Submitting a connection request immediately
     * @param  {Event}   $event Target event
     * @return {Promise}
     */
    addConnection($event) {
        // set pending state
        this.addPendingUser(this.user.id);

        return this.$pfUserConnect.requestConnection(this.user.id).then(
            () => {
                // set the requester state
                this.addRequestingUser(this.user.id);
            },
            (err = {}) => {
                const { status } = err;
                if (403 === status) {
                    this.$pfToast.error(
                        'You cannot connect with a blocked user',
                    );
                    // set connected state
                    this.removePendingUser(this.user.id);
                }
            },
        );
    }

    /**
     * Show the dialog for submitting a connection request
     * @param  {Event}   $event Target event
     * @return {Promise}
     */
    addConnectionDialog($event) {
        // if this is on top of a modal (like EDP), set the parent to the
        // md-dialog element, so the backdrop overlays properly
        const parent = $event.currentTarget.offsetParent;
        const isInModal =
            parent && parent.nodeName.toLowerCase() === 'md-dialog';
        const parentEl = isInModal ? parent : undefined;

        return this.$pfUserConnect
            .addConnectionDialog(this.user, parentEl, $event)
            .then(() => {
                // set pending state
                this.addPendingUser(this.user.id);
                // set the requester state
                this.addRequestingUser(this.user.id);
            });
    }

    /**
     * Accept a pending connection request
     * @return {Promise}
     */
    acceptConnection() {
        // set connected state
        this.addConnectedUser(this.user.id);

        return this.$pfUserConnect
            .acceptConnection(this.user.status.token, this.user.id)
            .then(
                () => {
                    this.onConnected();

                    // Confirm acceptance
                    this.$pfToast.success(
                        `You are now connected to {firstname}.`,
                        {},
                        { firstname: this.user.firstname },
                    );
                },
                () => {
                    // Show error message
                    this.$pfToast.error(
                        `Something went wrong, please try again later.`,
                    );

                    // set connected state
                    this.removeConnectedUser(this.user.id);
                },
            );
    }

    /**
     * Deny a pending connection request
     * @return {Promise}
     */
    denyConnection() {
        // set connected state
        this.removePendingUser(this.user.id);

        return this.$pfUserConnect.removeConnection(this.user.id).then(
            () => {
                this.onDenied();
                // set requester state
                this.removeRequestingUser(this.user.id);
            },
            () => {
                // Show error message
                this.$pfToast.error(
                    `Something went wrong, please try again later.`,
                );

                // set connected state
                this.addPendingUser(this.user.id);
            },
        );
    }

    /**
     * Show a toast informing the user their connection request is pending
     */
    iconShowPendingConnectionToast() {
        this.$pfToast.success(
            `Your connection request to {firstname} is now pending.`,
            {},
            { firstname: this.user.firstname },
        );
    }

    /**
     * Show bottom sheet to remove a connection
     * @param  {Event}   $event Target event
     * @return {Promise}
     */
    removeConnectionSheet($event) {
        return this.$mdBottomSheet
            .show({
                template: UserConnectSheetTemplate,
                controller: UserConnectSheetController,
                controllerAs: 'sheetCtrl',
                targetEvent: $event,
            })
            .then(() => {
                // set connected state
                this.removeConnectedUser(this.user.id);

                return this.$pfUserConnect
                    .removeConnection(this.user.id)
                    .then(null, () => {
                        // set connected state
                        this.addConnectedUser(this.user.id);
                    });
            });
    }

    /**
     * Show dialog to remove a connection
     * @param  {Event}   $event Target event
     * @return {Promise}
     */
    removeConnectionDialog($event) {
        // if this is on top of a modal (like EDP), set the parent to the
        // md-dialog element, so the backdrop overlays properly
        const parent = $event.currentTarget.offsetParent;
        const isInModal =
            parent && parent.nodeName.toLowerCase() === 'md-dialog';

        const title = this.$filter('i18n')(
            'Are you sure you want to remove this connection?',
        );
        const ariaLabel = this.$filter('i18n')('Remove');
        const ok = this.$filter('i18n')('Yes, remove');
        const cancel = this.$filter('i18n')('Cancel');

        let confirm = this.$mdDialog
            .confirm({
                hasBackdrop: true,
                multiple: true,
                parent: isInModal ? parent : undefined,
            })
            .title(title)
            .ariaLabel(ariaLabel)
            .targetEvent($event)
            .ok(ok)
            .cancel(cancel);

        return this.$mdDialog.show(confirm).then(() => {
            // set connected state
            this.removeConnectedUser(this.user.id);

            return this.$pfUserConnect
                .removeConnection(this.user.id)
                .then(null, () => {
                    // set connected state
                    this.addConnectedUser(this.user.id);
                });
        });
    }
}

UserConnectBtnController.$inject = [
    '$mdDialog',
    '$mdBottomSheet',
    '$pfUser',
    '$pfUserConnect',
    '$ngRedux',
    '$pfToast',
    '$pfNotificationCenter',
    '$filter',
];

/**
 * @ngdoc component
 * @name pfUserConnectBtn
 * @module portfolium.component.userConnect
 * @description
 * Show a user connect button
 *
 * @param {Object}   pfUser                 The user record
 * @param {String}   pfClass                Classes to be added to the component
 * @param {Function} pfOnConnected          Method to be run when connection is accepted
 * @param {Function} pfOnDenied             Method to be run when connection is denied
 * @param {Boolean}  pfMessageDialog        Should the message dialog be shown?
 * @param {String}   pfConnectText          Text to be shown on the connect button
 * @param {Boolean}  pfIsIconButton         Display connection button as icon button, only allowing connection requests to be sent, not accepted or deleted
 * @param {Boolean}  pfIsSimpleApproveDeny  Use simple approve(check) and deny(x) buttons, only allowing those actions
 * @param {Boolean}  pfIsNotificationButton Is the button being used on the notification center?
 * @param {Boolean}  pfNoButtonIcons        Remove the icons from the buttons
 * @param {String}   pfNotificationStatus   Conneciton status of the notification, when being used on the notification center
 * @param {String}   pfNotificationId       ID of the notification, when being used on the notification center
 */
export const UserConnectBtnComponent = {
    bindings: {
        user: '<pfUser',
        class: '@pfClass',
        onConnected: '&pfOnConnected',
        onDenied: '&pfOnDenied',
        messageDialog: '<pfMessageDialog',
        connectText: '@pfConnectText',
        isIconButton: '@pfIsIconButton',
        isSimpleApproveDeny: '@pfIsSimpleApproveDeny',
        isNotificationButton: '@pfIsNotificationButton',
        noButtonIcons: '<pfNoButtonIcons',
        notificationStatus: '<pfNotificationStatus',
        notificationId: '<pfNotificationId',
    },
    controller: UserConnectBtnController,
    controllerAs: '$ctrl',
    template: [
        '$element',
        '$attrs',
        ($element, $attrs) => {
            const buttonTemplates = {
                'user-connect-btn': UserConnectBtnTemplate,
                'notification-connect-btn': NotificationConnectBtnTemplate
            }

            let template = UserConnectBtnTemplate;

            if (_.isString($attrs.pfBtnType)) {
                try {
                    template = buttonTemplates[$attrs.pfBtnType];
                } catch (e) {
                    throw new Error('[pfUserConnectBtn] Template not found!');
                }
            }

            if (!template) {
                throw new Error('[pfUserConnectBtn] Template not found!');
            }

            return template;
        },
    ],
};
