import _ from 'lodash';
import {
    PATHWAY_DETAILS_FETCH,
    PATHWAY_DETAILS_FETCH_SUCCESS,
    PATHWAY_RESET,
} from './pathway-details.actions';

const initialState = {
    pathway: {},
    pathwayUser: {},
    network: {},
    fetching: false,
};

export const currentPathway = (state = initialState, action = {}) => {
    switch (action.type) {
        case PATHWAY_DETAILS_FETCH:
            return _.assign({}, state, {
                fetching: true,
            });
        case PATHWAY_DETAILS_FETCH_SUCCESS:
            const { pathway, pathwayUser, network } = action.payload;
            return _.assign({}, state, {
                pathway,
                pathwayUser,
                network,
                fetching: false,
            });
        case PATHWAY_RESET:
            return initialState;
        default:
            return state;
    }
};
