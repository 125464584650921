import AttachmentAudioTemplate from './attachment-audio.component.html';

/**
 * @ngdoc controller
 * @name AttachmentAudioController
 * @module portfolium.component.mediaSlider
 **/
class AttachmentAudioController {
    constructor(
        $element,
        $pfAttachmentViewer,
        $timeout,
        $sce,
    ) {
        this.$element = $element;
        this.$pfAttachmentViewer = $pfAttachmentViewer;
        this.$timeout = $timeout;
        this.$sce = $sce;
        // some variables to configure the player
        this.provider = null;
        this.mediaElem = null;
        this.url = undefined;
        this.mimetype = undefined;
    }

    $onInit() {
        this.mimetype = this.$pfAttachmentViewer.getMimeType(this.attachment);
        const url = this.$pfAttachmentViewer.getDocumentUrl(this.attachment);
        // TODO - (angular 1.6.x) in ng 1.6.x, we won't need to use $sce.trustAsResourceUrl
        //        since sce restrictions are relaxed in sce for video and audio
        this.url = this.$sce.trustAsResourceUrl(url);
        this.mediaElem = this.$element.find('audio');
        // register the media element with the parent component
        // currently using $timeout to ensure that the element has
        // a valid url property, so that need to be done on next digest
        // TODO - (angular 1.6.x) $doCheck might be more suitable for this use case
        this.$timeout(() => {
            this.attachmentViewerCtrl.registerMediaElement(this.mediaElem);
        }, 0);
    }
}

AttachmentAudioController.$inject = [
    '$element',
    '$pfAttachmentViewer',
    '$timeout',
    '$sce',
];

/**
 * @ngdoc component
 * @name AttachmentAudioComponent
 * @module portfolium.component.mediaSlider
 * @description Attachment slides in media slider for audio files
 *
 * @param {object}    pfAttachment The attachment object
 * @param {boolean}   pfIsMobile   A flag used to determine if the browser is on a mobile device
 **/
export const AttachmentAudioComponent = {
    require: {
        attachmentViewerCtrl: '^pfAttachmentViewer',
    },
    bindings: {
        attachment: '<pfAttachment',
        isMobile: '<pfIsMobile',
    },
    controller: AttachmentAudioController,
    controllerAs: '$ctrl',
    template: AttachmentAudioTemplate,
};
