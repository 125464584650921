export const SETTINGS_LOCATIONS_REQUEST                  = 'SETTINGS_LOCATIONS_REQUEST';
export const SETTINGS_LOCATIONS_ADD                      = 'SETTINGS_LOCATIONS_ADD';
export const SETTINGS_UPDATE_LOCATION                    = 'SETTINGS_UPDATE_LOCATION';
export const SETTINGS_UPDATE_LOCATIONS                   = 'SETTINGS_UPDATE_LOCATIONS';
export const SETTINGS_LOCATIONS_MAKE_LOCATION_CURRENT    = 'SETTINGS_LOCATIONS_MAKE_LOCATION_CURRENT';
export const SETTINGS_ORDER_LOCATIONS                    = 'SETTINGS_ORDER_LOCATIONS';
export const SETTINGS_REMOVE_LOCATION                    = 'SETTINGS_REMOVE_LOCATION';

export const settingsLocationsRequest = () => {
    return {
        type: SETTINGS_LOCATIONS_REQUEST,
    };
};

export const settingsAddLocation = (location) => {
    return {
        type: SETTINGS_LOCATIONS_ADD,
        payload: {
            location: location
        }
    };
};

export const settingsUpdateLocations = (locations) => {
    return {
        type: SETTINGS_UPDATE_LOCATIONS,
        payload: {
            locations: locations
        }
    };
};

export const settingsUpdateLocation = (location) => {
    return {
        type: SETTINGS_UPDATE_LOCATION,
        payload: {
            location: location
        }
    };
};

export const settingsOrderLocations = () => {
    return {
        type: SETTINGS_ORDER_LOCATIONS
    };
};

export const settingsMakeLocationCurrent = (locationId) => {
    return {
        type: SETTINGS_LOCATIONS_MAKE_LOCATION_CURRENT,
        payload: {
            locationId: locationId,
        }
    }
}

export const settingsRemoveLocation = (locationId) => {
    return {
        type: SETTINGS_REMOVE_LOCATION,
        payload: {
            locationId: locationId,
        }
    };
};
