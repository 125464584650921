/**
 * @ngdoc directive
 * @name usernameUniqueDirective
 * @module portfolium.component.formValidation
 * @restrict A
 * @description
 * Validates a username input field
 *
 * currently this doesn't do anything but clear the validation error
 * when the value is changed
 *
 * eventually we may want to handle username validation asynchronously
 * right now we are simply parsing an error returned by the api
 *  - see ProfileEditFieldsController#updateFields() for an example
 */

export const usernameValidationDirective = () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            ctrl.$validators.unique = (modelValue, viewValue) => {
                return true;
            };

            ctrl.$validators.forbidden = (modelValue, viewValue) => {
                return true;
            };
        },
    };
}
