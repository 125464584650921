import {getPaginationActions} from 'components/pagination/pagination.actions';

import {
    openPanel,
    closePanel,
    userUpdate,
    searchResultsUpdate,
    searchResultsReset,
    entryActionTypes,
} from './job-apply-center.actions';

import JobApplyCenterTemplate from './job-apply-center.component.html';
import './job-apply-center.component.scss';

/**
 * @ngdoc controller
 * @name JobApplyCenterController
 * @module portfolium.component.jobApplyCenter
 **/
class JobApplyCenterController {
    constructor(
        $pfUser,
        $ngRedux,
        $pfEntriesFeed,
        $pfEntrySearch,
        $timeout,
        $state,
        $pfJobs,
        $pfToast,
        $pfEntryEditor,
    ) {
        this.$pfUser = $pfUser;
        this.$ngRedux = $ngRedux;
        this.$pfEntriesFeed = $pfEntriesFeed;
        this.$pfEntrySearch = $pfEntrySearch;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$pfJobs = $pfJobs;
        this.$pfToast = $pfToast;
        this.$pfEntryEditor = $pfEntryEditor;
    }

    /**
    * @ngdoc method
    * @name $onInit
    * @description
    * Initialize user and entry pagination
    **/
    $onInit() {
        // Update state with panel open
        this.$ngRedux.dispatch(openPanel());

        // get user object
        let user = this.$pfUser.getUser();

        // update state with user
        this.$ngRedux.dispatch(userUpdate(user));

        // Configure pagination actions
        const paginationActions = getPaginationActions({
            actionTypes: entryActionTypes,
            getResults: this.getEntries.bind(this),
            paginationStyle: 'infinite'
        });

        // Subscribe to application state changes and map actions to this
        this._unsubscribe = this.$ngRedux.connect(
            this._mapStateToThis,
            paginationActions
        )(this);

        // Init page
        this.initPage();
    }

    /**
     * Has the user set their resume to private?
     * @return {Boolean}
     */
    getResumePrivacy() {
        if (!this.user) {
            return false;
        }

        return this.user.resume_privacy === '1';
    }

    /**
    * @ngdoc method
    * @name $onDestroy
    * @description
    * Update panel state and UnSubscribe from ngRedux
    **/
    $onDestroy() {
        // Update state with panel close
        this.$ngRedux.dispatch(closePanel());

        this._unsubscribe();
    }

    /**
    * @ngdoc method
    * @name _mapStateToThis
    * @description
    * Maps entries to the controller
    * @param  {Object} state current state.
    * @return {Object} New state of entries.
    **/
    _mapStateToThis(state) {
        return {
            user: state.jobApplyCenter.meta.user,
            searchResults: state.jobApplyCenter.meta.searchResults,
            searchResultsVisible: state.jobApplyCenter.meta.searchResultsVisible,
            pagination: state.jobApplyCenter.entries,
        };
    }

    /**
    * @ngdoc method
    * @name goToProfile
    * @description
    * Close panel and redirect to profile about
    * @return {Void}
    **/
    goToProfile() {
        // close panel
        this.close();

        return this.$state.go('profile.about', {username: this.user.username});
    }

    /**
     * @ngdoc method
     * @name searchEntries
     * @description
     * Search the user's entries based on keyword matching
     * @param  {String}              searchText Search query
     * @return {void|Promise<Array>}
     **/
    searchEntries(searchText) {
        this.searchLoading = true;

        if (!searchText.length) {
            // No search text -- reset search state
            this.$ngRedux.dispatch(searchResultsReset());
            this.searchLoading = false;

            return;
        }

        return this.$pfEntrySearch.searchEntriesByUserId(this.user.id, { q: searchText })
            .then((entries) => {
                // Update application state
                this.$ngRedux.dispatch(searchResultsUpdate(entries));
            })
            .finally(() => {
                this.searchLoading = false;
            });
    }

     /**
      * @ngdoc method
      * @name clearEntrySearch
      * @description
      * Clear the entry search query (show all entries)
      **/
    clearEntrySearch() {
        // clear search text
        this.searchText = '';
        // clear search results
        this.$ngRedux.dispatch(searchResultsReset());
    }

    /**
      * @ngdoc method
      * @name getEntries
      * @description
      * Gets users entries
      * @return {Array<Object>} List of user entries
      */
    getEntries() {
        // Get entries
        return this.$pfEntriesFeed.getEntriesByUserId(this.user.id, this.pagination.query);
    }

    /**
      * @ngdoc method
      * @name hasEntries
      * @description
      * Checks for results
      * @return {Boolean} Does the user have entries
      */
    hasEntries() {
        return this.pagination.results.length > 0;
    }

    /**
     * Is the entry selected?
     * @param {String} id entry ID
     * @return {Boolean}
     */
    isSelected(id) {
        return this.pagination.selected.indexOf(id) > -1;
    }

    /**
     * Add a project
     * @param  {Event}  $event ngClick event
     * @return {Promise}
     */
    addEntry($event) {
        return this.$pfEntryEditor.newEntry({
            source: 'jobApplyPanel',
            targetEvent: $event,
        })
        .then(() => {
            // Finished creating entry and transitioning to entry wizard,
            // close the panel
            return this.close();
        });
    }

    /**
      * @ngdoc method
      * @name toggleEntry
      * @description
      * Add or Remove an entry from application
      * @param {Object} entry Entry to add
      */
    toggleEntry(entry) {
        let hasMaxEntries = (this.pagination.selected.length === 4);
        let isSelected = this.isSelected(entry.id);

        if (!isSelected && hasMaxEntries) {
            return this.$pfToast.error('Only 4 projects allowed in application, please remove one before adding another.');
        }

        this.toggleSelection(entry.id);
        this.addSingleResult(entry);
    }

    /**
      * @ngdoc method
      * @name submitApplication
      * @description
      * Submit application
      * @return {Void}
      */
    submitApplication() {
        // check for validation
        if (this.application.$valid) {
            // set application data
            let applicationData = {
                message: this.coverLetter ? this.coverLetter : undefined,
                entry_ids: _.toString(this.pagination.selected),
            };

            // submit application then close panel
            return this.$pfJobs.applyByJobId(this.job.id, applicationData).then((response) => {
                // Set job as applied
                this.onJobApplied();

                // close panel
                this.close();

                this.$pfToast.success('Your application has been sent!');
            }, () => {
                this.$pfToast.error('Something went wrong, please try again.');
            });
        }
    }

    /**
     * Displays an error when the upload a resume files
     * @param  {string} err description of the error
     */
    onResumeError(err) {
        this.$pfToast.error(err);
    }

    /**
     * @description This is called when the upload is successful
     */
    onResumeUpdate() {
        // show success message
        this.$pfToast.success('Resume was uploaded!');
    }

    /**
      * @ngdoc method
      * @name close
      * @description
      * Close panel
      */
    close() {
        this.mdPanelRef.close();
    }
}

JobApplyCenterController.$inject = [
    '$pfUser',
    '$ngRedux',
    '$pfEntriesFeed',
    '$pfEntrySearch',
    '$timeout',
    '$state',
    '$pfJobs',
    '$pfToast',
    '$pfEntryEditor',
];

/**
 * @ngdoc component
 * @name JobApplyCenterComponent
 * @module portfolium.component.jobApplyCenter
 **/
export const JobApplyCenterComponent = {
    bindings: {
        'job': '<',
        'mdPanelRef': '<',
        'onJobApplied': '&',
    },
    controller: JobApplyCenterController,
    controllerAs: '$ctrl',
    template: JobApplyCenterTemplate,
}
