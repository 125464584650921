/**
 * Factory function for selection actions
 * @param {Object} config Configuration options
 * @param {Object} config.actionTypes Filter action types
 * @param {String} config.actionTypes.TOGGLE_SELECTION Individual selection toggle action
 * @param {String} config.actionTypes.CLEAR_SELECTION Clear selection action
 * @param {String} config.actionTypes.TOGGLE_ALL Toggle all selection action
 * @return {Object} Object containing all the public actions for selection
 */
export const getSelectionActions = ({actionTypes}) => {
    const {
        TOGGLE_SELECTION,
        CLEAR_SELECTION,
        TOGGLE_ALL,
    } = actionTypes;

    /**
     * Toggle an item in the list of selected items
     * @param {String} id ID of the item to toggle
     */
    const toggleSelection = (id) => {
        return {
            type: TOGGLE_SELECTION,
            payload: id,
        };
    };

    /**
     * Clear the list of selected items
     */
    const clearSelection = () => {
        return {
            type: CLEAR_SELECTION,
        };
    };

    /**
     * Select or deselect all items in the result set
     */
    const toggleAll = () => {
        return {
            type: TOGGLE_ALL,
        };
    };

    return {
        toggleSelection,
        clearSelection,
        toggleAll,
    };
};
