class SignInService
{
    constructor(PF_ACCOUNT_TYPES, PF_NETWORK_START_PAGES) {
        this.PF_ACCOUNT_TYPES = PF_ACCOUNT_TYPES;
        this.PF_NETWORK_START_PAGES = PF_NETWORK_START_PAGES;
        this.profile = {};
        this.school = '';
        this.network = {};
        this.social = {};
    }

    get isExistingUser() {
        return !!this.profile.id;
    }

    get isUserOnboarded() {
        return this.isExistingUser && this.profile.onboarded === '1';
    }

    get isPrepoppedNetwork() {
        return !!this.network.id && this.network.launched === '1';
    }

    get hasSocialData() {
        return !!this.social.token;
    }

    get isSingleSignOnNetwork() {
        return !!this.network.id && this.network.sso === '1' && this.network.sso_details && !!this.network.sso_details.url;
    }

    get isNetworkLoginPage() {
        return this.network.start_page === this.PF_NETWORK_START_PAGES.NETWORK_PAGE;
    }

    get isEmployer() {
        return !!this.profile.id && this.profile.type === this.PF_ACCOUNT_TYPES.employer.id;
    }

    hasEduAccess() {
        const { id, acls } = this.profile;
        const eduAccess = _.get(acls, 'edu.access');

        if (_.isNil(id) || _.isNil(eduAccess)) {
            return false;
        }

        return eduAccess;
    }

    hasGrowthAccess() {
        const { id, acls } = this.profile;
        const growthAccess = _.get(acls, 'growth');

        if (_.isNil(id) || _.isNil(growthAccess)) {
            return false;
        }

        return growthAccess;
    }

    hasTalentMatchAccess() {
        const { id, acls } = this.profile;
        const talentMatchAccess = _.get(acls, 'tm.access');

        if (_.isNil(id) || _.isNil(talentMatchAccess)) {
            return false;
        }

        return talentMatchAccess;
    }

    hasAccessToManyApps() {
        return this.hasEduAccess() || this.hasTalentMatchAccess();
    }

    /**
     * Check if the identity is an email address
     * @param  {String}  identity Value to check for a valid identity
     * @return {Boolean}
     */
    isEmail(identity) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(identity);
    }

    /**
     * Clear out the profile and network data
     */
    clearIdentity() {
        this.profile = {};
        this.school = '';
        this.network = {};
        this.social = {};
    }
}

SignInService.$inject = [
    'PF_ACCOUNT_TYPES',
    'PF_NETWORK_START_PAGES',
];

export default SignInService;
