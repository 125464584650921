import {combineReducers} from 'redux';
import {getPaginationReducer} from 'components/pagination/pagination.reducer';

import {
    CHALLENGE_DETAIL_SET_CHALLENGE,
    CHALLENGE_DETAIL_SET_WINNERS,
    CHALLENGE_DETAIL_UPDATE_SUBMISSION_COUNT,
    CHALLENGE_DETAIL_SET_USER_JOINED,
    CHALLENGE_DETAIL_SET_USER_SUBMISSION,
    CHALLENGE_RESET,
    challengeDetailActionTypes,
} from './challenge-detail.actions';

const initialState = {
    challenge: {},
    winners: [],
    submissionCount: 0,
    userSubmission: {},
    hasJoined: false,
}

export const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case CHALLENGE_DETAIL_SET_CHALLENGE:
            return _.assign({}, state, {
                challenge: action.payload.challenge
            });
        case CHALLENGE_DETAIL_SET_WINNERS:
            return _.assign({}, state, {
                winners: action.payload.winners
            });
        case CHALLENGE_DETAIL_UPDATE_SUBMISSION_COUNT:
            return _.assign({}, state, {
                submissionCount: action.payload.submissionCount
            });
        case CHALLENGE_DETAIL_SET_USER_JOINED:
            return _.assign({}, state, {
                hasJoined: true
            });
        case CHALLENGE_DETAIL_SET_USER_SUBMISSION:
            return _.assign({}, state, {
                userSubmission: action.payload.userSubmission
            });
        case CHALLENGE_RESET:
            return initialState;
        default:
            return state;
    }
};

export const challengeDetail = combineReducers({
    meta,
    pagination: getPaginationReducer({
        actionTypes: challengeDetailActionTypes,
        limit: 16,
        paginationStyle: 'infinite',
    }),
});
