import moment from 'moment';

class SignUpService {
    constructor($q, $pfCookies, $pfEnv, $http, $pfAuth, PF_COOKIES) {
        this._$pfCookies = $pfCookies;
        this._$pfEnv = $pfEnv;
        this._$http = $http;
        this._$pfAuth = $pfAuth;
        this._$q = $q;
        this.PF_COOKIES = PF_COOKIES;
    }

    /**
     * Register a new user account
     * @param  {Object}  data          Data to pass to API
     * @param  {Boolean} createSession Should a session be created for the new user?
     * @return {Promise}
     */
    signUp(data, createSession = true) {
        let url = '/proxy/auth/join_simple';

        if (data.username && data.password) {
            // Username and password fields were provided, change API endpoint
            url = '/proxy/auth/join';
        }
        // get referral cookie
        const referral = this._$pfCookies.get(this.PF_COOKIES.REFERRAL);
        // sanity check for referral
        if (!_.isEmpty(referral)) {
            // add the referral cookie value to the POST data
            data.referral = referral;
        }

        // Make API call to create user record
        return this._$http({
            method: 'POST',
            url: url,
            data,
        })
        .then((response) => {
            const auth = response.data;

            // Delete the external job seeker cookie
            this._$pfCookies.remove(this.PF_COOKIES.EXTERNAL_JOB_SEEKER, {
                domain: this._$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                path: '/',
            });

            if (!createSession) {
                // Resolve promise without creating session
                return this._$q.resolve(auth);
            }

            // Chain API call to create session
            return this._$pfAuth.createSession(auth);
        });
    }

    /**
     * Set a cookie that allows the user to bypass the required onboarding flow.
     * This cookie lives for 1 year.
     * @return {Promise<Date>} Resolved after cookie is set
     */
    setOnboardingBypass() {
        const expiresOn = moment().add(1, 'years').toDate();

        // Set the onboarding bypass cookie to skip onboarding
        this._$pfCookies.put(
            this.PF_COOKIES.ONBOARDING_BYPASS,
            '1',
            {
                expires: expiresOn,
                path: '/',
                domain: this._$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
            }
        );

        return this._$q.resolve(expiresOn);
    }

    /**
     * Register a new employer account with the minimum required fields
     * @param {Object} employer Employer object with required fields for API
     */
    employersJoinSimple(employer) {
        return this._$http({
            method: 'POST',
            url: '/proxy/auth/employers_join_simple',
            data: employer,
        });
    }

    /**
     * Check if user account exists with username
     * @param  {String} username Username value to check
     * @return {Promise}
     */
    checkUsernameAvailability(username) {
        return this._$http({
            method: 'GET',
            url: '/proxy/system/username',
            params: {
                username: username
            }
        })
        .then(function (response) {
            return response.data.available;
        });
    }

    /**
     * Check if company name already exists
     * @param  {String} companyName Company name value to check
     * @return {Promise}
     */
    checkCompanyAvailability(company) {
        return this._$http({
            method: 'GET',
            url: 'proxy/system/company',
            params: {
                name: company
            }
        })
        .then(response => response.data);
    }
}

SignUpService.$inject = [
    '$q',
    '$pfCookies',
    '$pfEnv',
    '$http',
    '$pfAuth',
    'PF_COOKIES',
];

export default SignUpService;
