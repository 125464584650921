function setClassWhenAtTop() {
    let $win = $(window);

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            let topClass = attrs.setClassWhenAtTop,
            offsetTop = element.offset().top - 70; // compensate for header bar

            function makeSticky() {
                if ($win.scrollTop() >= offsetTop) {
                    element.addClass(topClass);
                } else {
                    if ($.isEmptyObject($.find('md-backdrop'))) {
                        element.removeClass(topClass);
                    }
                }
            }

            $win.on('scroll', _.throttle(makeSticky, 100));
        }
    };
}

export default setClassWhenAtTop;
