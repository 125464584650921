export const LOAD_USER_BADGES = 'LOAD_USER_BADGES';
export const ADD_USER_BADGES = 'ADD_USER_BADGES';
export const RESET_USER_BADGES = 'RESET_USER_BADGES';

export const loadUserBadges = () => {
    return {
        type: LOAD_USER_BADGES,
    };
};

export const addUserBadges = (badges) => {
    return {
        type: ADD_USER_BADGES,
        payload: badges,
    };
};
