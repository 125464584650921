/**
 * Enable HTML5 mode for UI Router
 */
export const html5Mode = ($locationProvider) => {
    $locationProvider.html5Mode({
        enabled: true,
        rewriteLinks: false,
    });
};

html5Mode.$inject = ['$locationProvider'];
