import EntryViewerContestsTemplate from './entry-viewer-contests.component.html';
import './entry-viewer-contests.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerContestsController
 */
class EntryViewerContestsController {
    /**
     * Triggers callback for on view contest and redirects to tags
     * @param  {object} liker
     */
    viewContest(contest) {
        const event = {
            title: 'Contest Selected',
            properties: {
                sourceName: contest.name,
                clickSource: 'prEDP Sidebar',
                userAction: 'click',
            },
        };
        this.onViewContest({ event });
    }

    /**
     * Triggers callback for on vote for an entry in a contest
     * @param  {object} contest
     */
    onVoteEntry(contest) {
        const event = {
            title: 'Vote Entry for Contest',
            properties: {
                sourceName: contest.name,
                sourceId: contest.id,
                targetId: this.entryId,
                clickSource: 'prEDP Sidebar',
                userAction: 'click',
            },
        };
        this.onViewContest({ event });
    }
}

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerContestsComponent
 * @description Display list of contests in the project viewer sidebar
 *
 * @param {object[]} contests List of contests objects
 * @param {number} entryId id of the current project, this is use to vote
 * @param {boolean} isPreview Is the user viewing a preview?
 * @param {callback} onViewContest trigger when a contest is clicked or voted
 */
export const EntryViewerContestsComponent = {
    bindings: {
        'contests': '<',
        'entryId': '<',
        'isPreview': '<',
        'onViewContest': '&',
    },
    controller: EntryViewerContestsController,
    controllerAs: '$ctrl',
    template: EntryViewerContestsTemplate,
};
