/**
 * @ngdoc service
 * @name EntryPreviousStateService
 * @module portfolium.core
 * @description Service managing EDP Panel Open and Close
 * @class EntryPreviousStateService
 */
export class EntryPreviousStateService {
    constructor($pfLayout, $pfPreviousState, $state) {
        this.$pfLayout = $pfLayout;
        this.$pfPreviousState = $pfPreviousState;
        this.$state = $state;
        this.username = '';
    }

    /**
     * Sets current username so it is available if the
     * entry has not previous state and has to re direct to
     * the entry's user profile
     * @param {string} username Username of the profile to go back to.
     */
    setUsername(username) {
        this.username = username;
    }

    /**
     * When EDP opens, page title has to be stored
     *     for when the re direct back happens
     */
    onOpen() {
        this.cachedPageTitle = angular.copy(this.$pfLayout.title);
        const prevState = this.$pfPreviousState.get();

        if (
            prevState &&
            prevState.state &&
            prevState.state.name &&
            prevState.state.name !== 'loginModal.identity'
        ) {
            // remember the previous state with memoized name
            this.$pfPreviousState.memo('entryDetailsModalInvoker');
        }
    }

    /**
     * When EDP closes, it will redirect back to the previous state
     *     if closes without any interaction.
     *     Will go to entry's user profile if no previous state
     * @param  {string} reason Why the modal was closed?
     * @return {Promise|undefined}
     */
    onClose(reason) {
        if (reason === 'forceClose') {
            return;
        }

        const entryDetailsPrevState = this.$pfPreviousState.get(
            'entryDetailsModalInvoker',
        );

        // return to previous state
        if (entryDetailsPrevState) {
            this.$pfLayout.title = this.cachedPageTitle;
            return this.$state.go(
                entryDetailsPrevState.state,
                entryDetailsPrevState.params,
            );
        }

        if (!_.isEmpty(this.username)) {
            return this.$state.go('profile.about', {
                username: this.username,
            });
        }
    }
}

EntryPreviousStateService.$inject = ['$pfLayout', '$pfPreviousState', '$state'];
