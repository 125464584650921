class ToggleMobileMenuController
{
    constructor ($pfMobileMenuPanel) {
        this._$mobileMenuPanel = $pfMobileMenuPanel;
    }

    /**
     * Open the Mobile Menu center panel
     */
    openMobileMenu() {
        this._$mobileMenuPanel._open();
    }
}

ToggleMobileMenuController.$inject = ['$pfMobileMenuPanel'];

export const toggleMobileMenuDirective = () => {
    return {
        restrict: 'A',
        link: (scope, elem, attrs, ctrl) => {
            elem.on('click', ($event) => {
                // Open mobile menu
                ctrl.openMobileMenu();
            });
        },
        controller: ToggleMobileMenuController,
    };
};
