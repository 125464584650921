const MODULE_NAME = 'portfolium.core.services.entries';

import {EdpService} from './edp.service';
import {EntriesFeedService} from './entries-feed.service';
import {EntryAddAttachmentService} from './entry-add-attachment.service';
import {EntryAttachmentService} from './entry-attachment.service';
import {EntryCategoriesService} from './entry-categories.service';
import {EntryDetailsService} from './entry-details.service';
import {EntryDraftsService} from './entry-drafts.service';
import {EntryEditorService} from './entry-editor.service';
import {EntryPreviousStateService} from './entry-previous-state.service';
import {EntrySearchService} from './entry-search.service';
import {EntrySettingsService} from './entry-settings.service';
import {EntryStrengthService} from './entry-strength.service';

(function () {
    angular.module(MODULE_NAME, [])
        // Services
        .service('$pfEdpService', EdpService)
        .service('$pfEntryAddAttachment', EntryAddAttachmentService)
        .service('$pfEntryAttachment', EntryAttachmentService)
        .service('$pfEntriesFeed', EntriesFeedService)
        .service('$pfEntryCategories', EntryCategoriesService)
        .service('$pfEntryDetails', EntryDetailsService)
        .service('$pfEntryDrafts', EntryDraftsService)
        .service('$pfEntryEditor', EntryEditorService)
        .service('$pfEntryPreviousState', EntryPreviousStateService)
        .service('$pfEntrySearch', EntrySearchService)
        .service('$pfEntrySettings', EntrySettingsService)
        .service('$pfEntryStrength', EntryStrengthService);
}());

export default MODULE_NAME;
