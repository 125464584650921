import {combineReducers} from 'redux';

import {
    SITEWIDE_SEARCH_INIT,
    SITEWIDE_SEARCH_OPEN_PANEL,
    SITEWIDE_SEARCH_CLOSE_PANEL,
    SITEWIDE_SEARCH_QUERY_UPDATE,
    SITEWIDE_SEARCH_MENU_ITEM_INDEX_UPDATE,
    SITEWIDE_SEARCH_NEXT_MENU_ITEM,
    SITEWIDE_SEARCH_PREVIOUS_MENU_ITEM,
} from './sitewide-search.actions';

const initialState = {
    open: false,
    query: '',
    menuItemIndex: 0,
    totalMenuItems: 0,
}

export const sitewideSearch = (state = initialState, action = {}) => {
    switch (action.type) {
        case SITEWIDE_SEARCH_INIT:
            return _.assign({}, state, {
                totalMenuItems: action.payload.totalMenuItems
            });
        case SITEWIDE_SEARCH_OPEN_PANEL:
            return _.assign({}, state, {
                open: true
            });
        case SITEWIDE_SEARCH_CLOSE_PANEL:
            return _.assign({}, state, {
                open: false,
            });
        case SITEWIDE_SEARCH_QUERY_UPDATE:
            return _.assign({}, state, {
                query: action.payload.query
            });
        case SITEWIDE_SEARCH_MENU_ITEM_INDEX_UPDATE:
            return _.assign({}, state, {
                menuItemIndex: action.payload.menuItemIndex
            });
        case SITEWIDE_SEARCH_NEXT_MENU_ITEM:
            const menuItemIndex = (state.menuItemIndex + 1) % state.totalMenuItems;
            return _.assign({}, state, {
                menuItemIndex
            });
        case SITEWIDE_SEARCH_PREVIOUS_MENU_ITEM:
            // since this could negative, calculate the reminder of
            // next item and get it into a variable
            const tempIndex = (state.menuItemIndex - 1) % state.totalMenuItems;
            // assign the value eto the selected item
            let newMenuItemIndex = tempIndex;
            // if negative (-1 only)
            if (tempIndex < 0) {
                // select last item and this will reset the count
                newMenuItemIndex = state.totalMenuItems - 1;
            }
            return _.assign({}, state, {
                menuItemIndex: newMenuItemIndex,
            });
        default:
            return state;
    }
};
