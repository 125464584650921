export const UPDATE_COMPETENCIES = 'UPDATE_COMPETENCIES';
export const ADD_COMPETENCY = 'ADD_COMPETENCY';
export const EDIT_COMPETENCY = 'EDIT_COMPETENCY';
export const REMOVE_COMPETENCY = 'REMOVE_COMPETENCY';

export const updateCompetencies = (payload) => {
    return {
        type: UPDATE_COMPETENCIES,
        payload,
    };
};

export const addCompetency = (payload) => {
    return {
        type: ADD_COMPETENCY,
        payload,
    };
};

export const editCompetency = (competencyId, payload) => {
    return {
        type: EDIT_COMPETENCY,
        id: competencyId,
        payload,
    };
};

export const removeCompetency = (competencyId) => {
    return {
        type: REMOVE_COMPETENCY,
        id: competencyId,
    };
};
