/**
 * @ngdoc filter
 * @name shortCourseTitleFilter
 * @description
 * Takes in an assignment object and returns either the course code
 * or course title if code is unavailable.
 * @param {Object} assignment Assignment object containing course code and title
 * @return {String} Code or course title
 */
export const shortCourseTitleFilter = () => {
    return (assignment) => {
        // return empty if no assignment object passed in
        if (_.isEmpty(assignment)) {
            return '';
        }

        // sanity check for course code and return
        if (!_.isEmpty(assignment.course_code)) {
            return assignment.course_code;
        }

        // sanity check for course title and return
        if (!_.isEmpty(assignment.course_title)) {
            return assignment.course_title;
        }

        // default return
        return '';
    };
}

export default shortCourseTitleFilter;
