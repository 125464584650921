/**
 * @ngdoc controller
 * @description controller for upload file directive
 * @name addAttachmentUploadFileDirective
 * @module portfolium.entryEditor.entryAttachments
 **/
class AddAttachmentUploadFileController {
    constructor(
        $element,
        $q,
        $pfEntryAddAttachment,
        $pfEventTracker,
        $pfFilestack,
        $pfFilestackOptions,
        $pfFilestackConvert,
        $pfUser,
    ) {
        this.$element = $element;
        this.$q = $q;
        this.$pfEntryAddAttachment = $pfEntryAddAttachment;
        this.$pfEventTracker = $pfEventTracker;
        this.$pfFilestack = $pfFilestack;
        this.$pfFilestackOptions = $pfFilestackOptions;
        this.$pfFilestackConvert = $pfFilestackConvert;
        this.$pfUser = $pfUser;
    }

    $postLink() {
        // create the file input
        this.createFileInput();
    }

    /**
     * Creates a file input element, adds it to the
     * DOM and bind funcionallity
     */
    createFileInput() {
        // get all accepted types
        const acceptedTypes = this.$pfFilestackOptions.getAcceptedTypes(this.allowedFileTypes, true);

        // create the input for files
        const fileInput = angular.element('<input type="file" multiple hide style="display: none;">');
        // only limit mimetypes on desktops as support on mobile is poor
        // and will end up just blocking everything
        if (!this.$pfUser.isMobile()) {
            // restrict the input to the accepted types gotten
            fileInput.attr('accept', acceptedTypes.toString());
        }
        // get changes ( file is trying to be uploaded)
        fileInput.on('change', (event) => {
            // handle the change
            this.onFileInputChange(event)
                .then(() => {
                    // clean the input file
                    fileInput.val('');
                });
        });
        // stop the click event from bubbling up to element
        fileInput.on('click', (event) => {
            event.stopImmediatePropagation();
        });

        // make sure user can tab to the element
        this.$element.attr('tabindex', 0);
        // bind a click to open the file dialog
        this.$element.on('click', ($event) => {
            // Stop the click from bubbling up to the element
            $event.stopImmediatePropagation();
            // open the native file dialog
            fileInput.click();
        });
        // ADA bindings
        this.$element.on('keydown', (e) => {
            // fire when is enter or space
            if ((e.keyCode === 13)  || (e.keyCode === 32)) {
                // open the native file dialog
                fileInput.click();
            }
        });

        // append the created element to the current element
        this.$element.append(fileInput);
    }

    /**
     * Handles the change event for files upload input
     * @param  {Event} event
     * @return {Promise}
     */
    onFileInputChange(event) {
        // make sure there is a file
        if (event.target.files.length === 0) {
            // dont do anything if the re is no file
            return this.$q.resolve();
        }

        // get the files uploaded list
        const pickedFiles = event.target.files;

        // call the before upload callback
        const promise = this.onBeforeUpload({files: event.target.files});

        // resolve the value or promise from the before upload callback
        return this.$q.resolve(promise).then(() => {
            // iterate over files
            let promises = [];
            _.forEach(pickedFiles, file => {
                // get the temp attachment object
                promises.push(
                    this.$pfEntryAddAttachment.addAttachmentFromFile(
                        file,
                        {
                            onStart: this.onUploadFileStart,
                            onProgress: this.onUploadFileProgress,
                            onSuccess: this.onUploadFileSuccess,
                            onFail: this.onUploadFileFail,
                        },
                        this.allowedFileTypes,
                    ).then(attachment => {
                        // Track event
                        this.$pfEventTracker.trackServer('Attachment Added', {
                            attachmentType: attachment.type,
                            interactionType: 'nativeFilePicker',
                        });
                        return attachment;
                    })
                );
            });
            return this.$q.all(promises);
        }, (err) => {
            // TODO - handle rejected upload
        });
    }
}

AddAttachmentUploadFileController.$inject = [
    '$element',
    '$q',
    '$pfEntryAddAttachment',
    '$pfEventTracker',
    '$pfFilestack',
    '$pfFilestackOptions',
    '$pfFilestackConvert',
    '$pfUser',
];

/**
 * @ngdoc directive
 * @description allows an html input type file element to
 *        upload the selecte file using filestack
 * @name addAttachmentUploadFileDirective
 * @module portfolium.entryEditor.entryAttachments
 * @param {Function} onBeforeUpload        callback for before upload, can return
 *                                         a value or a promise that, if rejected it will
 *                                         cancel the upload
 * @param {Function} onUploadFileStart     callback for the upload start
 * @param {Function} onUploadFileProgress  callback for the upload progress
 * @param {Function} onUploadFileSuccess   callback for the upload success
 * @param {Function} onUploadFileFail      callback for the upload fail
 **/
export const addAttachmentUploadFileDirective = () => {
    return {
        restrict: 'A',
        bindToController: true,
        controllerAs: '$ctrl',
        scope: {
            onBeforeUpload: '&',
            onUploadFileStart: '&',
            onUploadFileProgress: '&',
            onUploadFileSuccess: '&',
            onUploadFileFail: '&',
            allowedFileTypes: '<',
        },
        controller: AddAttachmentUploadFileController,
    };
};
