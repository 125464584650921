import legacyModal from 'components/modal';

import {
    addConnectedUser,
    addPendingUser,
    addRequestingUser,
} from '../user-connect-btn/user-connect.actions';

import UserMessageBtnTemplate from './user-message-btn.html';

/**
 * @ngdoc controller
 * @name UserMessageBtnController
 * @module portfolium.component.userConnect
 */
class UserMessageBtnController {
    constructor(
        $window,
        $mdDialog,
        $mdBottomSheet,
        $pfUser,
        $pfSubscription,
        $pfToast,
        $pfUserConnect,
        $ngRedux,
        $filter,
    ) {
        this.$window = $window;
        this.$mdDialog = $mdDialog;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$pfUser = $pfUser;
        this.$pfSubscription = $pfSubscription;
        this.$pfToast = $pfToast;
        this.$pfUserConnect = $pfUserConnect;
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
        this.init();
    }

    $onChanges(changed) {
        if (!_.isEmpty(changed.user.currentValue) && !this.loaded) {
            this.loaded = true;
            this.setInitialState();
        }
    }

    $onDestroy() {
        // Unsubscribe to state changes
        this.unsubscribe();
    }

    init() {
        // Subscribe to state changes
        this.unsubscribe = this.$ngRedux.connect(this.mapStateToThis, {
            addConnectedUser,
            addPendingUser,
            addRequestingUser,
        })(this);
    }

    mapStateToThis(state) {
        return {
            userConnections: state.userConnections,
        };
    }

    /**
     * Evaluate initial data and set the state
     * @return {Void}
     */
    setInitialState() {
        if (_.isEmpty(this.user)) {
            return;
        }

        // set initial status
        this.status = this.getStatus();

        // set connected state
        if (this._isConnected()) {
            this.addConnectedUser(this.user.id);
        }

        // set pending state
        if (this._isPending()) {
            this.addPendingUser(this.user.id);
        }

        // set requester state
        if (this.status && this._isPending() && _.isNull(this.status.token)) {
            this.addRequestingUser(this.user.id);
        }
    }

    /**
     * Get the connection object for this user
     * @return {Object}
     */
    getConnection() {
        return this.userConnections[this.user.id] || {};
    }

    /**
     * Check the redux state to see if if the user is connected
     * @return {Boolean}
     */
    isConnected() {
        const connection = this.getConnection();
        return connection.connected;
    }

    /**
     * Check the redux state to see if if the user is pending
     * @return {Boolean}
     */
    isPending() {
        const connection = this.getConnection();
        return connection.pending;
    }

    /**
     * Check the redux state to see if if the user is the requester
     * @return {Boolean}
     */
    isRequester() {
        const connection = this.getConnection();
        return connection.isRequester;
    }

    /**
     * Get the user connection status
     * @return {Object}
     */
    getStatus() {
        return this.user.status;
    }

    /**
     * Is the user currently on a mobile device?
     * @return {Boolean}
     */
    get isMobile() {
        return this.$pfUser.isMobile();
    }

    /**
     * Is the button disabled?
     * @return {Boolean}
     */
    get isDisabled() {
        // honor the parents disabled expression first
        if (_.isBoolean(this.disabled)) {
            return this.disabled;
        }

        if (this.isPending() && this.isRequester()) {
            return true;
        }

        return false;
    }

    /**
     * Check the user object to see if the user is connected?
     * @return {Boolean}
     */
    _isConnected() {
        return this.user.connected === '1';
    }

    /**
     * Check the user object to see if the user is pending?
     * @return {Boolean}
     */
    _isPending() {
        if (this.status && this.status.status === 'pending') {
            return true;
        }

        return false;
    }

    /**
     * Get the object to be used in the ngClass
     * @return {Object}
     */
    getNgClass() {
        const ngClass = {
            'not-yet': this.isPending(),
            'unconnected': !this.isConnected(),
            'pf-mobile': this.isMobile,
            'pf-icon-text-button': !!this.icon && !this.isListItem,
        };
        return ngClass;
    }

    /**
     * Is the user a subscriber?
     * @return {Boolean}
     */
    get isSubscriber() {
        return this.$pfSubscription.isSubscriber();
    }

    /**
     * Handle the message button click event
     * @param  {Event}       $event ngClick event
     * @return {Promise|void}
     */
    onClick($event) {
        if (this.isSubscriber) {
            return this.showPmailDialog($event);
        }

        if (this.isPending()) {
            return this.showPendingConnectionDialog($event, this.isRequester());
        }

        if (!this.isConnected()) {
            return this.showNotConnectedDialog($event);
        }

        if (this.isMobile) {
            return this.redirectToMessagePage();
        }

        return this.showMessageDialog($event);

    }

    /**
     * Show a dialog informing the user they must be connected to send a message
     * @param  {Event}   $event Target event
     * @return {Promise}
     */
    showNotConnectedDialog($event) {
        // if this is on top of a modal (like EDP), set the parent to the
        // md-dialog element, so the backdrop overlays properly
        const parent = $event.currentTarget.offsetParent;
        const isInModal = ($event.currentTarget.offsetParent.nodeName.toLowerCase() === 'md-dialog');

        const dialogConf = {
            multiple: true,
            hasBackdrop: true,
            parent: (isInModal) ? parent : undefined
        };

        const title = this.$filter('i18n')('Oops!');
        const textContent = `${this.$filter('i18n')('You must connect with')} ${this.user.firstname} ${this.$filter('i18n')('before sending a message. Request to connect now?')}`;
        const ok = this.$filter('i18n')('Connect');
        const cancel = this.$filter('i18n')('Close');

        const alert = this.$mdDialog
            .confirm(dialogConf)
            .title(title)
            .ok(ok)
            .cancel(cancel)
            .textContent(textContent)
            .targetEvent($event);

        return this.$mdDialog
            .show(alert)
            .then(() => {
                // Confirm connection request
                this.addConnection($event)
                    .then((response) => {
                        // set the requester state
                        this.addRequestingUser(this.user.id);
                        // set pending status
                        this.addPendingUser(this.user.id);
                        this.$pfToast.success(`Your connection request to {firstname} has been sent.`, {}, { firsname: this.user.firstname });
                    });
            });
    }

    /**
     * Show a dialog informing the user their connection request is pending
     * @param  {Event}    $event   Target event
     * @param  {Boolean}  isRequester Is this user the requester?
     * @return {Promise}
     */
    showPendingConnectionDialog($event, isRequester) {
        // if this is on top of a modal (like EDP), set the parent to the
        // md-dialog element, so the backdrop overlays properly
        const parent = $event.currentTarget.offsetParent;
        const isInModal = ($event.currentTarget.offsetParent.nodeName.toLowerCase() === 'md-dialog');

        let message = `${this.$filter('i18n')('You must accept')} ${this.user.firstname}${this.$filter('i18n')('\'s connection request before you can send a message.')}`;

        if (isRequester) {
            message = `${this.user.firstname} ${this.$filter('i18n')('must accept your connection request before you can send a message.')}`;
        }

        let alert = this.$mdDialog
            .alert({
                multiple: true,
                hasBackdrop: true,
                parent: (isInModal) ? parent : undefined,
            })
            .title(this.$filter('i18n')('Oops!'))
            .textContent(message)
            .ok(this.$filter('i18n')('Close'))
            .targetEvent($event);

        return this.$mdDialog.show(alert);
    }

    /**
     * Submitting a connection request immediately
     * @param  {Event}   $event Target event
     * @return {Promise}
     */
    addConnection($event) {
        return this.$pfUserConnect.addConnectionDialog(this.user, null, $event);
    }

    /**
     * Redirect to the send message page
     */
    redirectToMessagePage() {
        this.$window.location.assign(`/message/add/${this.user.id}`);
    }

    /**
     * Show the send message dialog
     */
    showMessageDialog() {
        legacyModal.openModal(null, `/message/add/${this.user.id}`);
    }

    /**
     * Show the send pMail dialog
     */
    showPmailDialog() {
        legacyModal.openModal(null, `/message/add/${this.user.id}`);
    }
}

UserMessageBtnController.$inject = [
    '$window',
    '$mdDialog',
    '$mdBottomSheet',
    '$pfUser',
    '$pfSubscription',
    '$pfToast',
    '$pfUserConnect',
    '$ngRedux',
    '$filter',
];

/**
 * @ngdoc component
 * @name pfUserConnectBtn
 * @module portfolium.component.userConnect
 * @description
 * Show a user connect button
 *
 * @param {Object}  pfUser       The user record
 * @param {Boolean} pfDisabled   Should the button be disabled
 * @param {String}  pfClass      Classes to be added to the component
 * @param {String}  pfButtonText Text to be shown on the button
 * @param {String}  pfIcon       Icon to be shown on button
 */
export const UserMessageBtnComponent = {
    bindings: {
        'user': '<pfUser',
        'disabled': '<pfDisabled',
        'class': '@pfClass',
        'buttonText': '@pfButtonText',
        'icon': '@pfIcon',
        'isListItem': '@pfIsListItem',
    },
    controller: UserMessageBtnController,
    controllerAs: '$ctrl',
    template: UserMessageBtnTemplate,
};
