import angular from 'angular';
import {AssignmentSubmitModalController} from './assignment-submit-modal.controller';

import AssignmentSubmitConfirmationNoAttachmentsModalTemplate from './assignment-submit-confirmation-no-attachments.modal.html'
import AssignmentSubmitConfirmationModalTemplate from './assignment-submit-confirmation.modal.html'
import AssignmentSubmitLtiModalTemplate from './assignment-submit-lti.modal.html'
import AssignmentSubmitMobileTemplate from './assignment-submit-mobile.component.html'
import AssignmentSubmitTemplate from './assignment-submit.component.html'
import AssignmentUnsubmitConfirmationModalTemplate from './assignment-unsubmit-confirmation.modal.html'
import './assignment-submit.component.scss';

/**
 * @ngdoc component
 * @name AssignmentSubmitController
 * @module portfolium.assignments
*/
class AssignmentSubmitController {
    constructor(
        $q,
        $mdPanel,
        $pfUser,
        $pfAssignmentsUtilities,
        $pfTagTeammateReminder,
        $timeout,
        $pfEventTracker,
    ) {
        this.$q = $q;
        this.$mdPanel = $mdPanel;
        this.isMobile = $pfUser.isMobile();
        this.$pfAssignmentsUtilities = $pfAssignmentsUtilities;
        this.$pfTagTeammateReminder = $pfTagTeammateReminder;
        this.$timeout = $timeout;
        this.$pfEventTracker = $pfEventTracker;
        this.didViewTagTeammatesDialog = false;
    }

    $onInit() {
        // default label name
        this.labelName = 'assignment';
        // default secondary label name
        this.secondaryLabel = 'assignment';
        // check for edu course id
        if (_.isEmpty(this.assignment.course_id)) {
            // set label name for component
            this.labelName = 'requirement';
            // default secondary label name
            this.secondaryLabel = 'Requirement';
        }
    }

    /**
    * Checks if the assignment has been submitted
    * @return {Boolean}
    */
    get isSubmitted() {
        return this.$pfAssignmentsUtilities.isSubmitted(this.assignment);
    }

    /**
     * Checks if the status of the assignment is not started
     * @return {Boolean}
     */
    get isNotStarted() {
        return !this.isEditing && this.$pfAssignmentsUtilities.isNotStarted(this.assignment);
    }

    /**
    * Checks if the assignment is LTI completed
    * @return {Boolean}
    */
    get isLTICompleted() {
        return this.$pfAssignmentsUtilities.isLTICompleted(this.assignment);
    }

    /**
    * Checks if the assignment needs revision
    * @return {Boolean}
    */
    get needsRevision() {
        return this.$pfAssignmentsUtilities.needsRevision(this.assignment);
    }

    /**
    * Get the submit button class
    * @return {String}
    */
    get submitBtnClass() {
        let btnClass = [];
        if (this.pfClass) {
            btnClass.push(this.pfClass);
        }
        if (this.isAdp) {
            if (!this.isNotStarted) {
                btnClass.push('md-raised');
            } else {
                btnClass.push('pf-outline');
            }
        }
        return btnClass.join(' ');
    }

    /**
    * Get the unsubmit button class
    * @return {String}
    */
    get unsubmitBtnClass() {
        let btnClass = [];
        if (this.pfClass) {
            btnClass.push(this.pfClass);
        }
        if (this.isAdp) {
            if (this.isSubmitted) {
                btnClass.push('pf-outline');
            }
        }
        return btnClass.join(' ');
    }

    /**
     * Opens a confirmation modal for the submit action
     *     If there is no attachments, it will show a different template
     * @param  {[type]} ev Click event
     */
    confirmSubmit(ev) {
        // Check for tracking source and track submit(publish) click from project editor
        if (!_.isEmpty(this.pfSource)) {
            // Track event
            this.$pfEventTracker.trackServer(
                'Project Builder Publish Button Clicked',
                {
                    source: this.pfSource,
                    projectType: this.labelName
                }
            );

        }
        // check for an indication that teammates were involved here
        this.shouldTagTeammates()
            .then(() => {
                // load default template
                let template = AssignmentSubmitConfirmationModalTemplate;
                // assignment has been imported from an LTI and flow hasn't been finished
                if (!this.isLTICompleted) {
                    template = AssignmentSubmitLtiModalTemplate;
                // check if assignment has been edited, if not, show the "un-edited entry" modal
                } else if (!this.hasAttachments) {
                    template = AssignmentSubmitConfirmationNoAttachmentsModalTemplate;
                }
                // set the template and configuration
                const config = this._buildConfirmDialogConfig(template);
                // create panel
                const panelRef = this.$mdPanel.create(config);
                // Open confirm panel
                this.$mdPanel.open(panelRef);
            });
    }

    /**
     * Open modal to confirm UN-submition of assignment
     * @param  {Object} ev Click event
     */
    confirmUnsubmit(ev) {
        // load default template
        const template = AssignmentUnsubmitConfirmationModalTemplate;
        // set the template and configuration
        const config = this._buildConfirmDialogConfig(template);
        // create panel
        const panelRef = this.$mdPanel.create(config);

        // Open confirm panel
        this.$mdPanel.open(panelRef);
    }

    /**
     * @description Determine if we should ask the user to tag teammates
     * @return {Promise}
     */
    shouldTagTeammates() {
        // show dialog if we haven't shown it, are in the project editor, and if we have a draft
        if (!this.didViewTagTeammatesDialog && this.isEditing && !_.isEmpty(this.draft)) {
            return this.$pfTagTeammateReminder
                .shouldTagTeammates(this.draft, {
                    message: `It looks like this might be a group or team ${this.labelName}. We suggest tagging your teammates to boost your submission strength and provide credit to your teammates.`,
                    cancelText: `No, Submit ${this.labelName}`,
                })
                .then((found) => {
                    if (found) {
                        this.didViewTagTeammatesDialog = true;
                    }
                }, () => {
                    this.didViewTagTeammatesDialog = true;
                    return this.$timeout(() => {
                        // focus collaborator input...
                        angular.element('#pf-entry-field-teammates-section input').focus();
                        return this.$q.reject();
                    });
                });
        }
        return this.$q.resolve();
    }

    /**
     * Build the confirmation dialog config object for the panel/modal
     * @param {String} template Template to use in the confirmation dialog
     */
    _buildConfirmDialogConfig(template) {
        if (!this.assignment) {
            return;
        }

        const assignmentId = this.assignment.assignment_id;

        const position = this.$mdPanel.newPanelPosition()
            .absolute()
            .center();
        const assignmentModal = angular.element('md-dialog.pf-assignment-modal');
        const config = {
            id: assignmentId, // this is so we don't open a bunch of panels at the same time
            attachTo: (assignmentModal.length > 0) ? assignmentModal : angular.element(document.body),
            controller: AssignmentSubmitModalController,
            locals: {
                assignment: this.assignment,
                isEditing: this.isEditing,
                onSubmit: this.onSubmit,
                onSubmitSuccess: this.onSubmitSuccess,
                onUnsubmitSuccess: this.onUnsubmitSuccess,
            },
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: template,
            hasBackdrop: true,
            panelClass: 'pf-assignment-panel',
            position: position,
            trapFocus: true,
            zIndex: 150,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true
        };

        return config;
    }
}

AssignmentSubmitController.$inject = [
    '$q',
    '$mdPanel',
    '$pfUser',
    '$pfAssignmentsUtilities',
    '$pfTagTeammateReminder',
    '$timeout',
    '$pfEventTracker',
];

/**
 * @ngdoc component
 * @name AssignmentSubmitComponent
 * @module portfolium.assignments
 * @description The submit assignment button with contextual modal
 *
 * @param {object} assignment The assignment data
 * @param {object} draft Current draft, useful on the project builder, currently
 *                       using this to check for attachments that are added from
 *                       the project builder
 * @param {boolean} isEditing Is this currently in the project builder
 * @param {boolean} isAdp     Is this currently on the ADP
 * @param {boolean} isDisabled Should the button be disabled (in a loading state)?
 * @param {string}   pfClass  The class to apply to the buttons
 * @param {string}   pfSource button click source, used for tracking clicks from project editor
 * @param {function} onSubmit Callback for submit assignment action, called
 *                            before the submit api call
 * @param {function} onSubmitSuccess Callback for submit success action
 * @param {function} onUnsubmitSuccess Callback for unsubmit success action
*/
export const AssignmentSubmitComponent = {
    controller: AssignmentSubmitController,
    bindings: {
        assignment: '<',
        draft: '<',
        hasAttachments: '<',
        isEditing: '<',
        isAdp: '<',
        isDisabled: '<',
        pfClass: '@',
        onSubmit: '&',
        pfSource: '@',
        onSubmitSuccess: '&',
        onUnsubmitSuccess: '&',
    },
    template: ['$pfUser', ($pfUser) => {
        const isMobile = $pfUser.isMobile();

        if (isMobile) {
            return AssignmentSubmitMobileTemplate;
        }

        return AssignmentSubmitTemplate;
    }],
};
