import MobileMenuAuthItemsTemplate from '../../template/mobile-menu-auth-items.html';

/**
 * @ngdoc controller
 * @name mobileMenuAuthItemsController
 * @param {Function} closePanel function to close mobile menu
 * @param {Object} user Current user
 * @module portfolium.component.mobileMenu
 **/
 class MobileMenuAuthItemsController {
    constructor($pfAppSwitcherAuth, $pfEnv) {
        this.$pfAppSwitcherAuth = $pfAppSwitcherAuth;
        this.$pfEnv = $pfEnv;
    }

    get hasEduAccess() {
        return this.$pfAppSwitcherAuth.hasEduAccess;
    }

    get hasTalentMatchAccess() {
        return this.$pfAppSwitcherAuth.hasTalentMatchAccess;
    }

    get hasAccessToManyApps() {
        return this.$pfAppSwitcherAuth.hasAccessToManyApps;
    }

    /**
     * Redirects to Canvas
     */
    goToEduSso() {
        this.$pfAppSwitcherAuth.goToEduFromWeb();
    }

    /**
     * Redirects to TalentMatch
     */
    goToTalentMatchSso() {
        this.$pfAppSwitcherAuth.goToTalentMatchFromWeb();
    }
}

MobileMenuAuthItemsController.$inject = [
    '$pfAppSwitcherAuth',
    '$pfEnv',
];

/**
 * @ngdoc component
 * @name mobileMenuAuthItemsComponent
 * @module portfolium.component.mobileMenu
 **/
export const MobileMenuAuthItemsComponent = {
    bindings: {
        'closePanel': '&',
        'user': '<',
    },
    controller: MobileMenuAuthItemsController,
    template: MobileMenuAuthItemsTemplate,
};
