import ReadMoreTemplate from './read-more.component.html';
import './read-more.component.scss';

/**
 * @ngdoc controller
 * @name ReadMoreController
 * @module portfolium.ReadMoreController
 **/
class ReadMoreController {

    constructor($scope, $window, $element) {
        this._$scope = $scope;
        this._$window = $window;
        this._$element = $element;
    }

    $onChanges() {
        // wait for content to be passed in
        if (!!this.content) {
            this._init();
        }
    }

    $postLink() {
        // watch for window resize and recalculate
        angular.element(this._$window).on('resize', _.debounce(() => {
            this._init();
            this._$scope.$digest();
        }, 250));
    }

    /**
    *  @description shows the correct text for the
    *               button depending on its state
    *  @return {String}
    **/
    get textBtn() {
        if (this.isContentHidden) {
            return (_.isEmpty(this.pfText)) ? 'Read more' : this.pfText;
        }
        return 'See Less';
    }

    /**
     * @description Shows or hides content depending on current state
     */
    toggleContent() {
        // set enabled class
        const enabledClass = 'pf-read-more-enabled';

        if (this.isContentHidden) {
            // turn off obscuration
            this._$element.removeClass('pf-read-more-content--hidden');
            this._$element.css('max-height', 'none');
            this.isContentHidden = false;
        } else {
            // turn on obscuration
            this._$element.addClass('pf-read-more-content--hidden');
            this._$element.css('max-height', '');
            this.isContentHidden = true;
        }
    }

    /**
     * @description Determine if read more should be enabled and set classes/variables
     * @return {Boolean}
     */
    _init() {
        // set parent height
        let parentMaxHeight = parseInt(this._$element.css('max-height'));
        let padding = parseInt(this._$element.css('padding-top')) + parseInt(this._$element.css('padding-bottom'));

        // set content height
        let contentHeight = this._$element.children().height() + padding;

        // evaluate
        if (contentHeight > parentMaxHeight) {
            this._$element.addClass('pf-read-more-enabled pf-read-more-content--hidden');
            this.isContentHidden = true;
            return this.isReadMoreEnabled = true;
        }

        this._$element.removeClass('pf-read-more-enabled pf-read-more-content--hidden');
        this.isContentHidden = false;
        return this.isReadMoreEnabled = false;
    }
}

ReadMoreController.$inject = ['$scope', '$window', '$element'];

/**
 * @ngdoc component
 * @name ReadMoreComponent
 * @module portfolium.ReadMoreComponent
 * @description Give pf-read-more a max-height in your template and the component will calculate when the read more button needs to be shown
 **/
export const ReadMoreComponent = {
    bindings: {
        'content': '<',
        'pfText': '<',
        'pfPrimary': '<'
    },
    template: ReadMoreTemplate,
    controller: ReadMoreController,
    controllerAs: '$ctrl'
};
