import {
    SETTINGS_TM_NETWORK_REQUEST,
    SETTINGS_TM_NETWORK_UPDATE,
    SETTINGS_TM_SUBSCRIPTION_REQUEST,
    SETTINGS_TM_SUBSCRIPTION_UPDATE,
    SETTINGS_SET_TRIAL_STATUS,
    SETTINGS_SET_TRIAL_UNTIL
} from './settings-talent-match.actions';

export const settingsTalentMatch = (state = {}, action = {}) => {
    switch (action.type) {
        case SETTINGS_TM_NETWORK_REQUEST:
            return _.assign({}, state, {
                networkLoading: true
            });
        case SETTINGS_TM_NETWORK_UPDATE:
            return _.assign({}, state, {
                networks: action.payload.networks ,
                networkLoading: false
            });
        case SETTINGS_TM_SUBSCRIPTION_REQUEST:
            return _.assign({}, state, {
                subscriptionLoading: true
            });
        case SETTINGS_TM_SUBSCRIPTION_UPDATE:
            return _.assign({}, state, {
                subscription: action.payload.subscription,
                subscriptionLoading: false
            });
        case SETTINGS_SET_TRIAL_STATUS:
            return _.assign({}, state, {
                trialStatus: action.payload.trialStatus
            });
        case SETTINGS_SET_TRIAL_UNTIL:
            return _.assign({}, state, {
                trialUntil: action.payload.trialUntil
            });
        default:
            return state;
    }
};
