export const notificationCenterJewelDirective = ($pfCookies, $ngRedux, PF_COOKIES) => {
    return {
        restrict: 'A',
        link: (scope, elem, attrs, ctrl) => {
            // Get the initial message/notification counts from cookie
            const jewelCountCookie = $pfCookies.get(PF_COOKIES.JEWEL_COUNT);
            let totalCount = 0;

            if (jewelCountCookie) {
                // Parse the cookie value
                const jewelCount = JSON.parse(jewelCountCookie);

                totalCount = jewelCount.messages + jewelCount.notifications;
            }

            // Show or hide for initial state
            showNotificationCenterJewel(totalCount);

            // Subscriber to store updates
            const unsubscribe = $ngRedux.subscribe(() => {
                const state = $ngRedux.getState();

                // Calculate unread count
                const notificationCount = state.notificationCenter.notificationCenterNotifications.unread;
                const messageCount = state.notificationCenter.notificationCenterMessages.unread;
                const count = notificationCount + messageCount;

                showNotificationCenterJewel(count);
            });

            /**
             * Determines notifcation jewel status, run everytime store is updated
             * @param {Number} count Total count of unread messages/notifications
             */
            function showNotificationCenterJewel(count) {
                if (count > 0) {
                    elem.removeClass('pf-hide');
                } else {
                    elem.addClass('pf-hide');
                }
            }

            elem.on('$destroy', () => {
                // Cancel state change subscription
                unsubscribe();
            });
        },
    };
};

notificationCenterJewelDirective.$inject = [
    '$pfCookies',
    '$ngRedux',
    'PF_COOKIES',
];
