import angular from 'angular';

import { BlockedUserComponent } from './blocked-user.component';

const MODULE_NAME = 'portfolium.blockedUser';

(function () {
    angular.module(MODULE_NAME, [])
    // Components
    .component('pfBlockedUser', BlockedUserComponent);
}());

export default MODULE_NAME;
