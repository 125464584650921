class CompanyConnectSheetController
{
    constructor($mdBottomSheet) {
        this.$mdBottomSheet = $mdBottomSheet;
    }

    cancel() {
        return this.$mdBottomSheet.cancel();
    }

    confirm() {
        return this.$mdBottomSheet.hide();
    }
}

CompanyConnectSheetController.$inject = ['$mdBottomSheet'];

export default CompanyConnectSheetController;
