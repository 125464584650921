import angular from 'angular';

import {eventTrackerDirective} from './event-tracker.directive';
import {AnonymousService} from './anonymous.service';
import {EventTrackerService} from './event-tracker.service';

const MODULE_NAME = 'portfolium.component.eventTracker';

(function () {
    angular.module(MODULE_NAME, [])
        // Directive
        .directive('pfEventTracker', eventTrackerDirective)
        // Service
        .service('$pfAnonymous', AnonymousService)
        .service('$pfEventTracker', EventTrackerService);
}());

export default MODULE_NAME;
