class UserConnectSheetController
{
    constructor($mdBottomSheet) {
        this.$mdBottomSheet = $mdBottomSheet;
    }

    cancel() {
        return this.$mdBottomSheet.cancel();
    }

    confirm() {
        return this.$mdBottomSheet.hide();
    }
}

UserConnectSheetController.$inject = ['$mdBottomSheet'];

export default UserConnectSheetController;
