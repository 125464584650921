function companyNameDirective($q, $pfSignUp) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elem, attrs, ctrl) {
            ctrl.$asyncValidators.company = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    // Consider empty model valid
                    return $q.when();
                }

                let def = $q.defer();

                // Check username availability with API call
                $pfSignUp.checkCompanyAvailability(modelValue)
                    .then(function (response) {
                        if (response.available) {
                            // Company name is available
                            def.resolve();
                            return;
                        }

                        // Company name is unavailable
                        def.reject();
                    }, function () {
                        // API call failed
                        def.reject();
                    });

                return def.promise;
            };
        }
    };
}

companyNameDirective.$inject = ['$q', '$pfSignUp'];

export default companyNameDirective;
