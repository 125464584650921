/**
 * By default, format all POST requests as form data. If `config.isJSONRequest`
 * is `true`, format as form data but serialize the data as a JSON string.
 */
export const postInterceptor = ($pfEnv, $pfCookies) => {
    let csrfCookieName;
    if ($pfEnv.isEnvSet('PF_CSRF_WEB_COOKIE_NAME')) {
        csrfCookieName = $pfEnv.getEnv('PF_CSRF_WEB_COOKIE_NAME');
    } else {
        csrfCookieName = $pfEnv.isProduction ? 'pf-web-csrf' : `pf-web-csrf-${$pfEnv.getEnv('PF_ENVIRONMENT')}`;
    }
    const csrfToken = $pfCookies.get(csrfCookieName);

    return {
        'request': (config) => {
            if (config.method !== 'POST') {
                // Only modify POST requests
                return config;
            }

            // Add Content-Type header
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

            // Make sure POST payload exists
            if (!config.data) {
                config.data = {};
            }

            // Check for special requests that accept serialized JSON
            if (config.isJSONRequest === true) {
                // Modify payload with serialized JSON on a `data` key
                config.data = {
                    data: JSON.stringify(config.data),
                };
            }

            // Add CSRF token to payload
            config.data.csrf_token = csrfToken;

            // Transform payload into form data
            config.data = $.param(config.data);

            return config;
        }
    };
}

postInterceptor.$inject = ['$pfEnv', '$pfCookies'];
