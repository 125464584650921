import angular from 'angular';

import { BlankFieldComponent } from './blank-field/blank-field.component';
import { BlankAvatarComponent } from './blank-avatar/blank-avatar.component';
import { LoadingShellContainerDirective } from './loading-shell-container/loading-shell-container.directive';

const MODULE_NAME = 'portfolium.component.loadingShell';

(function () {
    angular.module(MODULE_NAME, [])
        .component('pfBlankField', BlankFieldComponent)
        .component('pfBlankAvatar', BlankAvatarComponent)
        .directive('pfLoadingShellContainer', LoadingShellContainerDirective);
}());

export default MODULE_NAME;
