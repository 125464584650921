export const TOGGLE_COMPETENCY_LIST_ITEM = 'TOGGLE_COMPETENCY_LIST_ITEM';

export const toggleListItem = (categoryId, competencyId) => {
    return {
        type: TOGGLE_COMPETENCY_LIST_ITEM,
        payload: {
            categoryId,
            competencyId,
        },
    };
};
