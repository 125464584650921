/**
 * Manage state for a collection of items that can be toggled on and off. For now,
 * this is bound to managing a collection of user profiles for TalentMatch
 * TODO: Refactor to allow mass actions of any kind
 */
class MassActionsService
{
    constructor($rootScope) {
        this.toggles = [];
        this.activeUsers = [];
    }

    /**
     * Reset the mass actions collection
     */
    reset() {
        this.close();
        this.toggles.length = 0;
    }

    /**
     * Toggle mass actions mode on or off
     */
    toggle() {
        if (this.isOpen) {
            return this.close();
        }

        return this.open();
    }

    /**
     * Toggle mass actions mode on
     */
    open() {
        this.isOpen = true;

        _.forEach(this.toggles, (toggle) => {
            // Call the 'onOpen' method on each registered toggle option
            toggle.onOpen();
        });
    }

    /**
     * Toggle mass actions mode off
     */
    close() {
        this.isOpen = false;

        // Reset collection of selected user profiles
        this.activeUsers.length = 0;

        _.forEach(this.toggles, (toggle) => {
            // Call the 'onClose' method on each registered toggle option
            toggle.onClose();
        });
    }

    /**
     * Is mass action mode currently enabled?
     * @return {Boolean}
     */
    isOpened() {
        return this.isOpen;
    }

    /**
     * Register a new toggle option as available in the options collection
     * @param  {ToggleController} toggleCtrl Instance of the mass action option's controller
     */
    registerToggle(toggleCtrl) {
        this.toggles.push(toggleCtrl);
    }

    /**
     * Add a profile to the collection of selected user profiles
     * @param  {String} profileId ID of the profile to add
     * @return {Array}            Updated selected users collection
     */
    add(profileId) {
        let toggle = _.find(this.toggles, (toggleCtrl) => {
            return toggleCtrl.profile.id === profileId;
        });

        // Make sure a toggle is registered with the given profile ID
        if (!toggle) {
            return;
        }

        // Check to make sure profile isn't in list already
        let profile = _.find(this.activeUsers, {id: toggle.profile.id});

        if (profile) {
            return;
        }

        // Make sure mass actions mode is toggled on
        this.open();

        // Add the profile to the collection of selected user profiles
        this.activeUsers.push(toggle.profile);

        return this.activeUsers;
    }

    /**
     * Remove a profile from the colletion of selected user profiles
     * @param  {String} profileId ID of the profile to remove
     * @return {Array}            Updated selected users collection
     */
    remove(profileId) {
        let index = _.findIndex(this.activeUsers, {id: profileId});

        if (index < 0) {
            // Given profile doesn't exist in the list
            return this.activeUsers;
        }

        // Remove profile from the collection of selected user profiles
        this.activeUsers.splice(index, 1);

        if (this.activeUsers.length < 1) {
            // If selected count is 0, exit mass actions mode
            this.close();
        }

        return this.activeUsers;
    }

    /**
     * Get the current collection of selected user profiles
     * @return {Array} Selected users collection
     */
    getActiveUsers() {
        return this.activeUsers;
    }

    /**
     * Add all of the registered toggles to the collection of selected users
     */
    selectAll() {
        _.forEach(this.toggles, (toggleCtrl) => {
            toggleCtrl.on();
        });
    }

    /**
     * Select all or none of the registered toggles
     */
    toggleSelectAll() {
        if (this.activeUsers.length < this.toggles.length) {
            this.selectAll();
        } else {
            this.close();
        }
    }
}

MassActionsService.$inject = ['$rootScope'];

export default MassActionsService;
