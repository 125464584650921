import _ from 'lodash';

/**
 * Creates a reducer for managing filtering in a search query
 * @param {Object} config Configuration options for the reducer
 * @param {Object} config.actionTypes Filter action types
 * @return {Function<Function>} Function that returns the filter reducer
 */
export const getFilterReducer = ({actionTypes}) => {
    const {
        ADD_FILTER,
        REMOVE_FILTER,
        RESET_FILTERS,
    } = actionTypes;

    const filter = (state = {}, action) => {
        switch (action.type) {
            case ADD_FILTER: {
                const {name, type, value} = action.payload;

                if (type === 'keyValue') {
                    return _.assign({}, state, {
                        [name]: value,
                    });
                }

                if (type === 'collection') {
                    let collection;

                    if (_.isArray(state[name])) {
                        collection = _.uniq([
                            ...state[name],
                            ...[value],
                        ]);
                    } else {
                        collection = [value];
                    }

                    return _.assign({}, state, {
                        [name]: collection,
                    });
                }

                return state;
            }
            case REMOVE_FILTER: {
                const {name, type, value} = action.payload;

                if (type === 'keyValue') {
                    return _.assign({}, state, {
                        [name]: null,
                    });
                }

                if (type === 'collection') {
                    let collection;

                    if (_.isArray(state[name])) {
                        collection = _.without(state[name], value);
                    } else {
                        collection = [];
                    }

                    return _.assign({}, state, {
                        [name]: collection,
                    });
                }

                return state;
            }
            case RESET_FILTERS:
                return _.mapValues(state, () => null);
            default:
                return state;
        }
    };

    return filter;
};
