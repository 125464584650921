export default class UrlUtilsService {
    constructor() {
    }

    /**
     * Convert an object to URL params, with optional preceding '?'
     * @param [Object]    obj                       An object with key/val to be converted to URL params
     * @param [Boolean]   prependQuestionMark       If true, prepend the URL params with '?'
     * @return [String]                             The URL params as a string
     */
    objToUrlParams(obj, prependQuestionMark) {
        let params = '';
        if (!(obj instanceof Object)) {
            return params;
        }

        const paramArr = _.map(obj, function (val, key) {
            val = encodeURIComponent(val);
            return `${key}=${val}`;
        });
        params = (prependQuestionMark) ? '?' + paramArr.join('&') : paramArr.join('&');

        return params;
    }

    /**
     * Convert URL params to an object
     * @param [String]    params        A string with key/val to be converted to an object
     * @return [Object]                 The URL params as an object
     */
    urlParamsToObj(params) {
        let obj = {};
        if (params instanceof Object) {
            return params;
        }
        if (typeof params !== 'string') {
            return obj;
        }
        // remove preceding ? or & from params
        const qIdx = params.indexOf('?');
        if (qIdx === 0) {
            params = params.substring(1, params.length-1);
        }
        // separate the key/value pairs
        const paramsParts = params.split('&');
        _.each(paramsParts, (param) => {
            // separate the key and value
            let paramPart = param.split('=');
            obj[paramPart[0]] = paramPart[1];
        });
        return obj;
    }

    /**
     * Combine multiple URL param objects or strings to an object
     * @param [String/Object]    params        Two or more URL params string/objects to combine
     * @return [Object]                        The URL params, combined, as an object
     */
    combineParams() {
        let obj = {};
        const params = Array.prototype.slice.call(arguments);
        if (params.length === 0) {
            return obj;
        }
        _.each(params, (param) => {
            // convert to object before merging
            obj = _.assign(obj, this.urlParamsToObj(param));
        });
        return obj;
    }

    /**
     * Add URL params to a URL
     * @param [String]           url       The URL
     * @param [String/Object]    params    The URL params
     * @return [String]                    The URL with params as a string
     */
    addParamsToUrl(url, params) {
        if (!url) {
            return new Error('url is required');
        }
        if (!params) {
            return url;
        }
        // convert params to an object, if not done already
        // this ensures that we have a clean key/val structure
        if (typeof params === 'string') {
            params = this.urlParamsToObj(params);
        }
        // remove preceding ? or & from params

        // separate params from url, if any
        const urlParts = url.split('?');
        url = urlParts[0];
        params = (urlParts.length === 2) ? this.combineParams(urlParts[1], params) : params;
        const paramStr = this.objToUrlParams(params, true);
        return `${url}${paramStr}`;
    }

    /**
     * Convert http url to https
     * @param [String]           url       The URL
     * @return [String]                    The URL as https
     */
    enforceHttps(url) {
        if (!url) {
            return '';
        }
        url = url.replace(/^http:/, 'https:');
        return url;
    }
}
