import angular from 'angular';

import {SuggestionsService} from './suggestions.service';

(function () {
    let app = angular.module('portfolium.component.suggestions', []);

    // Services
    app.service('$pfSuggestions', SuggestionsService);

}());

export default 'portfolium.component.suggestions';
