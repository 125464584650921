import EntryViewerLikeButtonTemplate from './entry-viewer-like-button.component.html';
import './entry-viewer-like-button.component.scss';

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerLikeButtonComponent
 * @description Display like button
 *
 * @param {boolean} liked Is the current entry liked by the current user?
 * @param {callback} onLike Trigger when user likes an entry
 */
export const EntryViewerLikeButtonComponent = {
    bindings: {
        liked: '<',
        onLike: '&',
    },
    controller: () => {},
    controllerAs: '$ctrl',
    template: EntryViewerLikeButtonTemplate,
};

