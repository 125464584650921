import {UPDATE_CATEGORIES} from './entities.actions';

const initialState = {
    categories: {},
};

export const entities = (state = initialState, action = {}) => {
    switch (action.type) {
        case UPDATE_CATEGORIES:
            return _.assign({}, state, {
                categories: action.payload.categories,
            });
        default:
            return state;
    }
};
