import EntryViewerFooterTemplate from './entry-viewer-footer.component.html';
import './entry-viewer-footer.component.scss';

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerFooterController
 */
class EntryViewerFooterController {
    $onInit() {
        this.currentYear = new Date().getFullYear();
    }

    /**
     * Report the entry as abusive, offensive, etc.
     *
     * @param {Event} $event ngClick event
     * @memberof EntryViewerFooterController
     */
    handleReportContent($event) {
        $event.preventDefault();
        this.onReportContent({ $event });
    }
}

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerFooterComponent
 * @description Display footer with copyright and report abuse button in project viewer
 *
 * @param {object} profile Profile object of the project creator
 * @param {boolean} isPreview Is current user previewing their own project
 * @param {callback} onContentReport handle content report click
 */
export const EntryViewerFooterComponent = {
    bindings: {
        profile: '<',
        isPreview: '<',
        onReportContent: '&',
    },
    controller: EntryViewerFooterController,
    controllerAs: '$ctrl',
    template: EntryViewerFooterTemplate,
};
