function assetFilter($pfEnv) {
    return function (url) {
        const { assetsUrl } = $pfEnv;
        // assetsUrl will/should have a trailing slash
        return `${assetsUrl}${url}`;
    };
}

assetFilter.$inject = ['$pfEnv'];

export default assetFilter;
