import {combineReducers} from 'redux';
import {getPaginationReducer} from 'components/pagination/pagination.reducer';
import {myJobsInterestedActionTypes} from './my-jobs-interested/my-jobs-interested.actions';
import {myJobsAppliedActionTypes} from './my-jobs-applied/my-jobs-applied.actions';

export const myJobs = combineReducers({
    jobsInterested: getPaginationReducer({
        limit: 5,
        actionTypes: myJobsInterestedActionTypes,
    }),
    jobsApplied: getPaginationReducer({
        limit: 5,
        actionTypes: myJobsAppliedActionTypes,
    }),
});
