class AddStartDateAction
{
    constructor($pfGameAction, $pfUserAbout) {
        this.$pfGameAction = $pfGameAction;
        this.$pfUserAbout = $pfUserAbout;
        this.name = 'add_your_last_grad_start_date';
        this.label = 'Add your start date';
    }

    execute(next) {
        // we should always have an education record
        return this.$pfUserAbout.getMyPrimaryEducation().then((education) => {
            if (!education) {
                // if the user hasn't added an education, show add education panel
                return this.$pfGameAction.openPanel('education', next);
            }
            // open the edit user about panel
            return this.$pfGameAction.openPanelEdit('education', education, '#pf-about-panel-form--education__start-date', false, next);
        });
    }
}

AddStartDateAction.$inject = ['$pfGameAction', '$pfUserAbout'];

export default AddStartDateAction;
