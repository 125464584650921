import {EntryViewerListController} from '../entry-viewer-list.controller';
import EntryViewerSkillsTemplate from './entry-viewer-skills.component.html';

/**
 * @ngdoc controller
 * @module portfolium.component.entryViewer
 * @name EntryViewerSkillsController
 */
class EntryViewerSkillsController extends EntryViewerListController {
    constructor($element, $timeout) {
        super($element, $timeout);
    }

    $onChanges() {
        if (!this.skills || !this.skills.length) {
            return;
        }
        this.listLength = this.skills.length;
    }

    /**
     * Triggers callback for on view skill and redirects to skills
     * @param  {object} skill
     */
    viewSkill(skill) {
        const event = {
            title: 'Skill Selected',
            properties: {
                sourceName: skill.skill,
                clickSource: 'prEDP Sidebar',
                userAction: 'click',
            },
        };
        this.onViewSkill({ event });
    }
}

EntryViewerSkillsController.$inject = [
    '$element',
    '$timeout',
];

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerSkillsComponent
 * @description Display list of skills in the entry viewer sidebar
 *
 * @param {boolean} isPreview Is the user viewing a preview?
 * @param {object[]} skills List of skill objects
 * @param {object} profile profile of entry owner
 * @param {callback} onViewSkill trigger when a skill is clicked
 */
export const EntryViewerSkillsComponent = {
    bindings: {
        'isPreview': '<',
        'skills': '<',
        'profile': '<',
        'onViewSkill' : '&',
    },
    controller: EntryViewerSkillsController,
    controllerAs: '$ctrl',
    template: EntryViewerSkillsTemplate,
};
