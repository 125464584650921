import moment from 'moment';
import JobListCardTemplate from './job-list-card.component.html';
import './job-list-card.component.scss';

/**
 * @ngdoc controller
 * @name JobListCardController
 * @param {Object} JobListCard job to show
 * @param {String} [pf-source] source of the data, if 'es', will parse for
 *  Elastic Search data.
 * @module portfolium.component.job
 **/
class JobListCardController {
    constructor($window, $pfJobDetails) {
        this.$window = $window;
        this.$pfJobDetails = $pfJobDetails;
    }

    /**
    * @ngdoc method
    * @name isJobExpired
    * @description determine if passed in job is expired
    * @param job Job object
    * @return {Boolean}
    */
    isJobExpired(job) {
        return this.$pfJobDetails.isExpired(job, this.pfSource);
    }

    /*
    * @name getJobLocation
    * @description Get Location of the Job.
    * @param {Object} job
    * @return {String} Returns location of a job as {City, State}
    **/
    getJobLocation(job) {
        return this.$pfJobDetails.getJobLocation(job);
    }
}

JobListCardController.$inject = [
    '$window',
    '$pfJobDetails',
];

/**
 * @ngdoc component
 * @name jobListCardComponent
 * @module portfolium.component.job
 **/
export const JobListCardComponent = {
    bindings: {
        'jobs': '<',
        'pfSource': '@',
        'fetching': '<',
        'limit': '<',
        'trafficSource': '<',
    },
    template: JobListCardTemplate,
    controller: JobListCardController,
    controllerAs: '$ctrl'
};
