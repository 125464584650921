import {postInterceptor} from './post-interceptor';
import {authInterceptor} from './auth-interceptor';

/**
 * Configure Angular's $http service to communicate with the Portfolium API
 */
export const httpConfig = ($httpProvider) => {
    // Set X-Requested-With header by default
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    // Add post interceptor to add content-type header to POST requests
    $httpProvider.interceptors.push(postInterceptor);

    // Add auth interceptor handle 401 errors when the users token is not valid
    $httpProvider.interceptors.push(authInterceptor);

    // initialize get if not there
    if (!$httpProvider.defaults.headers.get) {
        $httpProvider.defaults.headers.get = {};
    }

    // disable IE ajax request caching
    $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get.Pragma = 'no-cache';
}

httpConfig.$inject = ['$httpProvider'];
