import hello from 'hellojs';
import config from './config';
import SocialAuthClient from './social-auth-client';

import {
    setTwitterUserData,
} from './social-auth.actions';

class SocialAuthService {
    constructor(
        $http,
        $pfUser,
        $ngRedux,
        $pfEventTracker,
    ) {
        this.$http = $http;
        this.$pfUser = $pfUser;
        this.$ngRedux = $ngRedux;
        this.$pfEventTracker = $pfEventTracker;

        const { spokes } = this.$pfUser.getUser() || {};

        this._init();

        this.twitter = new SocialAuthClient(
            'twitter',
            this._onLogin.bind(this),
            this._onLogout.bind(this),
            this.addSpoke.bind(this),
            spokes
        );
        this.google = new SocialAuthClient(
            'google',
            null,
            null,
            this.addSpoke.bind(this),
            spokes
        );
        this.socialUserData = {};

        // get the userdata
        this.refreshUserData();
    }

    /**
     * Initialize hello.js
     */
    _init() {
        hello.init({
            twitter: config.clients.twitter.id,
            google: config.clients.google.id,
        }, {
            oauth_proxy: config.proxy,
            redirect_uri: config.redirectUri
        });
    }

    /**
     * callback after login to social network
     * @param  {String}  provider Auth service being used
     * @param  {Object}  userData The user data from the social network
     */
    _onLogin(provider, userData) {
        this._setUserData(provider, userData);
    }

    /**
     * callback after logout to social network
     * @param  {String}  provider Auth service being used
     */
    _onLogout(provider) {
        this._setUserData(provider, null);
    }

    /**
     * Set userdata for a social network
     * @param  {String}  provider Auth service being used
     * @param  {Object}  userData The user data from the social network
     */
    _setUserData(provider, userData) {
        switch (provider) {
            case 'twitter':
                this.$ngRedux.dispatch(setTwitterUserData(userData));
                break;
        }
    }

    /**
     * Refresh user data from each network
     */
    refreshUserData() {
        const state = this.$ngRedux.getState();
        this.socialUserData = state.socialUserData;

        _.each(this.socialUserData, (data, provider) => {
            const authed = this[provider].isAuthorized();
            if (authed && !data) {
                this[provider].api('/me')
                    .then((response) => {
                        this._setUserData(provider, response);
                    });
            }
        });
    }

    /**
     * Clear cached login information from localStorage
     */
    clearLocalData() {
        if (!window.localStorage) {
            return;
        }

        window.localStorage.removeItem('hello');
    }

    /**
     * Add a spoke record
     * @param  {String}   client          Auth service being used
     * @param  {String}   token           OAuth token
     * @param  {Boolean}  importData      Determine if social data should be imported
     * @param  {String}   trackingSource  The name of the analytics tracking source
     * @return {Promise}
     */
    addSpoke(client, token, importData = false, source = null, data = {}) {
        data.token = token;
        data.import = importData;

        return this.$http({
            method: 'POST',
            url: `/proxy/social/${client}`,
            data
        })
        .then(() => {
            if (source) {
                return this.$pfEventTracker.trackServer('Social Network Connected', {
                    source,
                    socialNetworkName: client,
                })
                .then(() => this.$pfUser.updateSession());
            }

            // Update the user's CI session
            return this.$pfUser.updateSession();
        });
    }

    /**
     * Get Spokes records
     * @return {Promise}
     */
    getSpokes() {
        return this.$http({
            method: 'GET',
            url: '/proxy/social/spokes/'
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Remove a spoke record
     * @param  {String}  client Auth service being used
     * @return {Promise}
     */
    removeSpoke(client) {
        return this.$http({
            method: 'DELETE',
            url: '/proxy/social/unlink/' + client
        })
        .then(() => {
            // Update the user's CI session
            return this.$pfUser.updateSession();
        });
    }
}

SocialAuthService.$inject = [
    '$http',
    '$pfUser',
    '$ngRedux',
    '$pfEventTracker',
];

export default SocialAuthService;
