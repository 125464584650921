function usernameDirective($q, $pfUser, $pfSignUp) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elem, attrs, ctrl) {
            let user = $pfUser.getUser();

            ctrl.$asyncValidators.username = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue) || modelValue === user.username) {
                    // Consider empty model valid
                    return $q.when();
                }

                let def = $q.defer();

                // Check username availability with API call
                $pfSignUp.checkUsernameAvailability(modelValue)
                    .then(function (available) {
                        if (available) {
                            // Username is available
                            def.resolve();
                            return;
                        }
                        // Username unavailable
                        def.reject();
                    }, function () {
                        // API call failed
                        def.reject();
                    });

                return def.promise;
            };
        }
    };
}

usernameDirective.$inject = ['$q', '$pfUser', '$pfSignUp'];

export default usernameDirective;
