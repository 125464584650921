/**
 * @ngdoc directive
 * @name pfFilestackViewerDirective
 * @module portfolium.component.attachmentViewer
 * @restrict E
 * @description
 * Builds and inserts iframe for filestack preview
 * NOTE Find a jQuery-less way to do this, interpolation is not working on the iframe when done in HTML
 */

export const filestackViewerDirective = ($pfEnv) => {
    return {
        restrict: 'E',
        scope: {
            fileHandle: '@',
            width: '@',
            height: '@',
            policy: '@',
            signature: '@'
        },
        link: function (scope, elem) {
            const cdnUrl = $pfEnv.getEnv('PF_CDN_URL');
            // set the cssUrl here
            let cssUrl = `%22${cdnUrl}/filestack/pf-viewer.css%22`;
            // create the constant url here and go ...
            let url = `https://cdn.filestackcontent.com/preview=css:${cssUrl}`;
            // add the security parameters here
            if (scope.policy && scope.signature) {
                // set the policy here
                url += `/security=policy:${scope.policy},signature:${scope.signature}`;
            }
            // add the handle
            url += `/${scope.fileHandle}`;
            // set the preview iframe here
            let preview = `<iframe src="${url}" width="${scope.width}" height="${scope.height}" frameBorder="0">`;
            // append
            elem.append(preview);
        },
    };
};

filestackViewerDirective.$inject = ['$pfEnv'];
