import angular from 'angular';
import './social-share-panel.scss';

/**
 * @ngdoc service
 * @name SocialSharePanelService
 * @module portfolium.component.socialShare
 * @description Provides a panel for sharing options
 */
export class SocialSharePanelService {
    constructor($q, $mdPanel, $mdMedia, $pfUser) {
        this.$q = $q;
        this.$mdPanel = $mdPanel;
        this.$mdMedia = $mdMedia;
        this.isMobile = $pfUser.isMobile();
    }

    /**
     * Create a new panel instance
     * @param  {object} config            Panel config settings
     * @param  {string} config.type       Type of thing being shared
     * @param  {object} config.data       Data for panel to use / share
     * @param  {string} config.source     Data for panel to use / share
     * @param  {element} config.element   Element to attach panel
     * @return {MdPanelRef}
     */
    _createPanel({
        type,
        data,
        source,
        element
    }) {
        const offset = this.isMobile ? 8 : 10;

        const position = this.$mdPanel
            .newPanelPosition()
            .relativeTo(element)
            .withOffsetY(panelRef => {
                if (panelRef._actualPosition.y === this.$mdPanel.yPosition.BELOW) {
                    return `${offset}px`;
                }
                if (panelRef._actualPosition.y === this.$mdPanel.yPosition.ABOVE) {
                    return `-${offset}px`;
                }
            });

        if (this.isMobile) {
            position.addPanelPosition(this.$mdPanel.xPosition.ALIGN_END, this.$mdPanel.yPosition.ABOVE)
                    .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.ABOVE)
                    .addPanelPosition(this.$mdPanel.xPosition.ALIGN_END, this.$mdPanel.yPosition.BELOW)
                    .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW);
        } else {
            position.addPanelPosition(this.$mdPanel.xPosition.ALIGN_END, this.$mdPanel.yPosition.ABOVE)
                    .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.ABOVE)
                    .addPanelPosition(this.$mdPanel.xPosition.ALIGN_START, this.$mdPanel.yPosition.ABOVE)
                    .addPanelPosition(this.$mdPanel.xPosition.ALIGN_START, this.$mdPanel.yPosition.BELOW)
                    .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW)
                    .addPanelPosition(this.$mdPanel.xPosition.ALIGN_END, this.$mdPanel.yPosition.BELOW);
        }

        const animation = this.$mdPanel.newPanelAnimation()
            .openFrom(element)
            .closeTo(element)
            .withAnimation(this.$mdPanel.animation.FADE);

        const config = {
            animation,
            attachTo: angular.element(document.body),
            clickOutsideToClose: true,
            controller: angular.noop,
            controllerAs: '$ctrl',
            hasBackdrop: false,
            locals: {
                data,
                source,
            },
            panelClass: 'pf-panel pf-social-share-panel',
            position,
            propagateContainerEvents: false,
            template: `
                <pf-social-share-menu-project
                    project="::$ctrl.data"
                    source="::$ctrl.source"
                    md-panel-ref="::$ctrl.mdPanelRef">
                </pf-social-share-menu-project>
            `,
            trapFocus: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 1000,
            onDomRemoved: () => {
                // Reset panel reference
                this.panel = undefined;
                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
        };
        const panel = this.$mdPanel.create(config);
        return panel;
    }

    /**
     * Open the panel
     * @param  {object} config Panel config settings
     * @return {Promise<MdPanelRef>}
     */
    open(config) {
        if (this.panel) {
            return this.$q.reject();
        }
        // create panel
        this.panel = this._createPanel(config);
        // Open the panel
        return this.panel.open();
    }

    /**
     * close panel
     * @return {Promise}
     */
    close() {
        if (!this.panel) {
            return this.$q.reject();
        }
        return this.panel.close();
    }
}

SocialSharePanelService.$inject = [
    '$q',
    '$mdPanel',
    '$mdMedia',
    '$pfUser',
];
