import angular from 'angular';

function show(message, type) {
    // get the toast service instance
    let $pfToast = angular.element('body').injector().get('$pfToast');
    // check for a type
    type = type ? type : 'notice';
    // show the toast bar
    $pfToast.showSimple(type, message);
}

function hide() {
    // get the toast service instance
    let $pfToast = angular.element('body').injector().get('$pfToast');
    // hide the toast bar
    $pfToast.hide();
}

export default {
    show: show,
    hide: hide
};
