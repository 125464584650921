import SitewideSearchMobileTemplate from './sitewide-search-mobile.component.html';
import './sitewide-search-mobile.component.scss';

import {SitewideSearchController} from '../sitewide-search.controller';

/**
 * @ngdoc component
 * @name SitewideSearchMobileComponent
 * @module portfolium.component.sitewideSearch
 * @param {String} query
 * @description Component for mobile search
 */
export const SitewideSearchMobileComponent = {
    bindings: {
        'mdPanelRef': '<',
    },
    template: SitewideSearchMobileTemplate,
    controller: SitewideSearchController,
};
