import {jobSearchDistanceOptions} from './job-search-distance-options.constant';
import JobSearchFiltersTemplate from './job-search-filters.component.html';
import './job-search-filters.component.scss';

export class JobSearchFiltersController {
    constructor($pfJobSearch, PF_JOB_TYPES) {
        this.$pfJobSearch = $pfJobSearch;
        this.jobOptions = PF_JOB_TYPES;
        this.distanceOptions = jobSearchDistanceOptions;
        this.selectedDistance =  _.find(this.distanceOptions, {name: this.distance });
        this.initFilters(this.initialFilters);
    }

    /**
     * send the filters data
     */
    filter() {
        if (!this.filters) {
            return false;
        }

        if (this.type) {
            this.filters.type = this.type.name;
        }


        this.onFormSubmit({
            data: _.assign(
                {},
                this.filters,
                this.location
            )
        });
    }

    /**
     * Reset the filters and sends the data again
     */
    reset() {
        this.filters = {
            company: null,
            industry: null,
            function: null,
            type: null,
            anywhere: null,
            distance: null,
            locale: null
        };

        this.location = {
            lat: null,
            lon: null
        };

        this.type =  _.find(this.jobOptions, {name: '' });

        this.searchText = {
            company: '',
            industry: '',
            function: '',
        };

        this.filter();
    }

    /**
     * Will set the filters for the initial state
     * @param  {Object} data [description]
     * @return {[type]}      [description]
     */
    initFilters(data = {}) {
        const filters = _.assign({
            company: null,
            industry: null,
            function: null,
            type: null,
            anywhere: null,
            distance: null,
            lat: null,
            lon: null,
            locale: null
        }, data);

        this.filters = filters;

        this.location = {
            lat: filters.lat || null,
            lon: filters.lon || null
        }
    }

    /**
     * Search companies by query string
     * @param  {String}  query Query string value
     * @return {Array}
     */
    getCompanies(query) {
        return this.$pfJobSearch.queryCompanies({
            q: query,
            with_jobs: true
        }).then((results) => {
            let companies = [];

            _.forEach(results, (company) => {
                companies.push(company.name);
            });

            return companies;
        });
    }

    /**
     * Search industries by query string
     * @param  {String}  query Query string value
     * @return {Array}
     */
    getIndustries(query) {
        return this.$pfJobSearch.queryIndustries({
            q: query
        }).then((results) => {
            let industries = [];

            _.forEach(results, (industry) => {
                industries.push(industry.industry);
            });
            return industries;
        });
    }

    /**
     * Search functions by query string
     * @param  {String}  query Query string value
     * @return {Array}
     */
    getJobFunctions(query) {
        return this.$pfJobSearch.queryJobFunctions({
            q: query
        }).then((results) => {
            let jobFunctions = [];

            _.forEach(results, (jobFunction) => {
                jobFunctions.push(jobFunction.function);
            });

            return jobFunctions;
        });
    }

    /**
     * On address update, make sure that there the values
     *  distance and anywhere and set properly.
     * @param  {String}  query Query string value
     * @return {Array}
     */
    addressUpdate() {

        if (!this.isLocationEmpty) {

            this.location = {
                'lat': this.location.lat,
                'lon': this.location.lon
            }

            if (_.isEmpty(this.filters.distance)) {
                // set the distance default to 10
                this.filters.distance = '10';
            }

            if (_.isEmpty(this.filters.anywhere)) {
                this.filters.anywhere = 'false';
            }
        }
    }

    get isLocationEmpty() {
        return _.isNull(this.location.lat) && _.isNull(this.location.lon);
    }
}

JobSearchFiltersController.$inject = [
    '$pfJobSearch',
    'PF_JOB_TYPES'
];

/**
 * @ngdoc component
 * @name pfJobSearchFilters
 * @module portfolium.component.pfJobSearchFilters
 * @description controller for pfJobSearchFilters
 * @param {Object} [pf-user] user information to display
 */
export const JobSearchFiltersComponent = {
    bindings: {
        onFormSubmit: "&",
        initialFilters: '<'
    },
    template: JobSearchFiltersTemplate,
    controller: JobSearchFiltersController,
    controllerAs: '$ctrl'
};
