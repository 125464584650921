class InviteBtnController
{
    constructor($pfSubscriptionActions) {
        this.$pfSubscriptionActions = $pfSubscriptionActions;

        if (!this.offset) {
            this.offset = 0;
        }
    }

    /**
     * @description Show the invite to apply to job dialog
     * @param {Event} $event ngClick event
     */
    inviteToApply($event) {
        const recipients = this.recipients() || this.user;

        // callback for click
        if (_.isFunction(this.onClick)) {
            this.onClick($event);
        }

        this.$pfSubscriptionActions.inviteToApply(recipients, $event)
            .then(() => {
                this.onMessageSent();
            });
    }

    /**
     * @description Show the invite to follow company dialog
     * @param {Event} $event ngClick event
     */
    inviteToFollow($event) {
        const recipients = this.recipients() || this.user;

        // callback for click
        if (_.isFunction(this.onClick)) {
            this.onClick($event);
        }

        this.$pfSubscriptionActions.inviteToFollow(recipients, $event)
            .then(() => {
                this.onMessageSent();
            });
    }
}

InviteBtnController.$inject = ['$pfSubscriptionActions'];

/**
 * @ngdoc directive
 * @name pfPmailInvite
 * @module portfolium.component.subscription
 *
 * @param {Object} [pf-user] User to send the message to, if sending a single
 *     message
 * @param {Function<Array<Object>>} [pf-recipients] Function that returns a list
 *     of profile objects to message, when sending multiple messages
 * @param {String} [pf-offset] Offset value for displaying the popup options menu
 * @param {String} [pf-class] CSS class to apply to the button
 * @param {Function<any>} [pf-on-click] Callback event for a click on the button
 * @param {Function<any>} [pf-on-message-sent] Callback event after a message has been
 *     successfully sent
 */
function pmailInviteDirective() {
    return {
        restrict: 'E',
        scope: {
            user: '=pfUser',
            recipients: '&pfRecipients',
            offset: '@pfOffset',
            class: '@pfClass',
            onClick: '&pfOnClick',
            onMessageSent: '&pfOnMessageSent',
        },
        bindToController: true,
        controller: InviteBtnController,
        controllerAs: 'inviteCtrl',
        template: `
            <md-menu md-offset="0 56">
                <md-button md-no-ink
                     aria-label="Open options menu"
                     ng-class="::inviteCtrl.class"
                     ng-click="$mdMenu.open($event)">
                    <span md-menu-origin>Invite</span>
                    <md-icon>arrow_drop_down</md-icon>
                </md-button>
                <md-menu-content width="3">
                    <md-menu-item>
                        <md-button md-menu-align-target ng-click="inviteCtrl.inviteToApply($event)">
                            <div layout="row" flex>
                                <p flex>{{ "Invite to Apply to Job" | i18n }}</p>
                            </div>
                        </md-button>
                    </md-menu-item>
                    <md-menu-item>
                        <md-button ng-click="inviteCtrl.inviteToFollow($event)">
                            <div layout="row" flex>
                                <p flex>{{ "Invite to Follow Company" | i18n }}</p>
                                <md-icon md-menu-align-target></md-icon>
                            </div>
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        `
    };
}

export default pmailInviteDirective;
