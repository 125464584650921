export const CHALLENGES_JOIN_OPEN  = 'CHALLENGES_JOIN_OPEN';
export const CHALLENGES_JOIN_CLOSE = 'CHALLENGES_JOIN_CLOSE';
export const CHALLENGES_JOIN_SEARCH_RESULTS_UPDATE = 'CHALLENGES_JOIN_SEARCH_RESULTS_UPDATE';
export const CHALLENGES_JOIN_SEARCH_RESULTS_RESET = 'CHALLENGES_JOIN_SEARCH_RESULTS_RESET';

export const paginationActionTypes = {
    'PAGE_INIT': 'CHALLENGES_JOIN_PAGE_INIT',
    'PAGE_NEXT': 'CHALLENGES_JOIN_PAGE_NEXT',
    'PAGE_PREVIOUS': 'CHALLENGES_JOIN_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'CHALLENGES_JOIN_PAGE_REQUEST',
    'PAGE_SUCCESS': 'CHALLENGES_JOIN_PAGE_SUCCESS',
    'PAGE_ERROR': 'CHALLENGES_JOIN_PAGE_ERROR',
    'ADD_SINGLE_RESULT': 'CHALLENGES_JOIN_ADD_SINGLE_RESULT',
    'TOGGLE_SELECTION': 'CHALLENGES_JOIN_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'CHALLENGES_JOIN_CLEAR_SELECTION',
};

/**
 * Opens panel
 */
export const openPanel = () => {
    return {
        type: CHALLENGES_JOIN_OPEN,
    };
};

/**
 * Closes panel
 */
export const closePanel = () => {
    return {
        type: CHALLENGES_JOIN_CLOSE,
    };
};

/**
 * Updates search results
 * @param {Array<Objects>} results Search results returned
 */
export const searchResultsUpdate = (results) => {
    return {
        type: CHALLENGES_JOIN_SEARCH_RESULTS_UPDATE,
        payload: {
            results,
        },
    };
};

/**
 * Resets search results list and properties
 */
export const searchResultsReset = () => {
    return {
        type: CHALLENGES_JOIN_SEARCH_RESULTS_RESET,
    };
};
