import {UPDATE_COMPETENCY_CATEGORIES} from '../action/competency-categories';

export const competencyCategories = (state = [], action = {}) => {
    switch (action.type) {
        case UPDATE_COMPETENCY_CATEGORIES:
            return [
                ...action.payload,
            ];
        default:
            return state;
    }
};
