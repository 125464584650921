/**
 * @ngdoc controller
 * @name nl2brFilter
 * @module portfolium.core.filters
 * @description
 * converts newlines to <br>
 * @param {String} text The text to search/replace newlines
 **/
 export const nl2brFilter = ($sce) => {
    return function(text){
        // replace line breaks
        text = (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/gi, `$1 <br> $2`);

        return $sce.trustAsHtml(text);
    };
}

nl2brFilter.$inject = ['$sce'];

export default nl2brFilter;
