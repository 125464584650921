export const jobInterestedTypes = {
    'PAGE_INIT': 'JOB_INTERESTED_PAGE_INIT',
    'PAGE_NEXT': 'JOB_INTERESTED_PAGE_NEXT',
    'PAGE_PREVIOUS': 'JOB_INTERESTED_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'JOB_INTERESTED_PAGE_REQUEST',
    'PAGE_SUCCESS': 'JOB_INTERESTED_PAGE_SUCCESS',
    'PAGE_ERROR': 'JOB_INTERESTED_PAGE_ERROR',
    'TOGGLE_SELECTION': 'JOB_INTERESTED_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'JOB_INTERESTED_CLEAR_SELECTION',
    'TOGGLE_ALL': 'JOB_INTERESTED_TOGGLE_ALL',
};
