import angular from 'angular';
import {MediaSliderFullscreenModalController} from '../controller/media-slider-fullscreen-modal.controller';
import MediaSliderFullscreenTemplate from '../../template/media-slider-fullscreen.html';

/**
* @ngdoc controller
* @name pfMediaSliderFullscreen
* @module portfolium.component.mediaSlider
* @description Opens the fullscreen media slider
*/
class MediaSliderFullscreenController {
    /**
    * @ngdoc method
    * @name MediaSliderFullscreenController#constructor
    * @methodOf MediaSliderFullscreenController
    * @description
    * Set local properties
    */
    constructor(
        $mdDialog,
        $pfUser,
        $pfEventTracker,
        $pfHelpChat,
    ) {
        this.$mdDialog = $mdDialog;
        this.$pfUser = $pfUser;
        this.$pfEventTracker = $pfEventTracker;
        this.$pfHelpChat = $pfHelpChat;
    }

    /**
    * @ngdoc method
    * @name MediaSliderFullscreenController#openFullScreenView
    * @methodOf MediaSliderFullscreenController
    * @description Open fullscreen media slider
    * @return {Promise}
    */
    openFullScreenView() {
        // set main slider inactive
        this.$mediaCtrl.edpSliderActive = false;
        // stop video, if needed
        this.$mediaCtrl.isPlayingVideo(this.$mediaCtrl.slickCurrentIndex, this.$mediaCtrl.slidesWithMedia, (playing) => {
            if (playing) {
                this.$mediaCtrl.stopVideo(this.$mediaCtrl.slickCurrentIndex, this.$mediaCtrl.slidesWithMedia);
            }
            this.$mediaCtrl.resumeVideo = !!playing;
        });

        // stop autoplay
        this.$mediaCtrl.isSlickEnabled = false;
        this.$mediaCtrl.slickConfig.method.slickPause();
        // disable keyboard bindings on EDP slider
        this.$mediaCtrl.remoteControl(angular.element(this.$mediaCtrl.edpSlider), true);

        return this.$mdDialog.show({
            locals: {
                '$mediaCtrl': this.$mediaCtrl,
                'entry': this.entry,
                'isMobile': this.isMobile,
            },
            controller: MediaSliderFullscreenModalController,
            bindToController: true,
            multiple: true,
            controllerAs: 'fullscreenCtrl',
            template: MediaSliderFullscreenTemplate,
            parent: angular.element(document.body),
            clickOutsideToClose:true,
            focusOnOpen: false,
            fullscreen: true, // Only for -xs, -sm breakpoints.
            onComplete: ($scope, element) => {
                // mdDialog looks for a button when setting focus, since the EDP doesn't have one, let's set it manually
                angular.element('md-dialog.pf-media-slider-fullscreen').focus();
                // hide help chat button
                this.$pfHelpChat.hideButton();
            },
            onRemoving: (element, removePromise) => {
                // get the current play time, if applicable, from the fullscreen media, then resume on EDP
                this.$mediaCtrl.getTimeFromVideo(this.$mediaCtrl.slickCurrentIndex, this.$mediaCtrl.fullscreenSlidesWithMedia, (seconds) => {
                    this.$mediaCtrl.isPlayingVideo(this.$mediaCtrl.slickCurrentIndex, this.$mediaCtrl.fullscreenSlidesWithMedia, (playing) => {

                        removePromise.then(() => {
                            if (seconds > 0) {
                                this.$mediaCtrl.seekVideo(this.$mediaCtrl.slickCurrentIndex, this.$mediaCtrl.slidesWithMedia, seconds, playing);
                            }
                            this.$mediaCtrl.resumeVideo = playing;
                        });
                    });
                });
            },
        }).finally((message) => {
            // show help chat button
            this.$pfHelpChat.showButton();
            // enable slick slider
            this.$mediaCtrl.isSlickEnabled = true;
            // restart autoplay
            if (!this.$mediaCtrl.resumeVideo && this.$mediaCtrl.slickConfig.autoplay) {
                // video is not playing so we can start slider
                this.$mediaCtrl.slickConfig.method.slickPlay();
            }
            // unregister fullscreen keyboard bindings
            this.$mediaCtrl.remoteControl(angular.element(this.$mediaCtrl.edpSliderFullscreen), true);
            // re-register keyboard bindings
            this.$mediaCtrl.remoteControl(angular.element(this.$mediaCtrl.edpSlider));
            // reactivate main slider
            this.$mediaCtrl.edpSliderActive = true;
        });
    }
}

MediaSliderFullscreenController.$inject = [
    '$mdDialog',
    '$pfUser',
    '$pfEventTracker',
    '$pfHelpChat',
];

export const MediaSliderFullscreenComponent = {
    bindings: {
        'entry': '<pfEntry',
        'isMobile': '<pfIsMobile'
    },
    require: {
        '$mediaCtrl': '^pfMediaSlider',
    },
    controller: MediaSliderFullscreenController,
    controllerAs: '$mediaFullCtrl',
    bindToController: true,
    template: `
        <md-button
             md-no-ink class="pf-media-slider__button md-icon-button"
             aria-label="Full screen"
             ng-click="$mediaFullCtrl.openFullScreenView()">
            <md-icon class="material-icons">fullscreen</md-icon>
            <md-tooltip md-direction="bottom" md-delay="500" md-z-index="5002">{{ "Fullscreen slide" | i18n }}</md-tooltip>
        </md-button>
    `,
};
