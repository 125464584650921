export class PreviousStateService {
    constructor($state, $q, $transitions) {
        this.$state = $state;
        this.previous = null;
        this.lastPrevious = null;
        this.memos = {};
        this.$q = $q;

        $transitions.onStart({}, trans => {
            const fromState = trans.from().$$state();

            if (fromState) {
                this.setPreviousState({
                    state: trans.from(),
                    params: trans.params('from'),
                });
            }
        });
    }

    setPreviousState(prevState) {
        this.lastPrevious = this.previous;
        this.previous = prevState;
    }

    get(memoName) {
        return memoName ? this.memos[memoName] : this.previous;
    }

    set(memoName, previousState, previousParams) {
        this.memos[memoName] = {
            state: this.$state.get(previousState),
            params: previousParams,
        };
    }

    go(memoName, options) {
        const to = this.get(memoName);
        if (!to) {
            return this.$q.reject(
                new Error(
                    'no previous state ' +
                        (memoName ? 'for memo: ' + memoName : ''),
                ),
            );
        }
        return this.$state.go(to.state, to.params, options);
    }

    memo(memoName, defaultStateName, defaultStateParams) {
        this.memos[memoName] = this.previous || {
            state: $state.get(defaultStateName),
            params: defaultStateParams,
        };
    }

    forget(memoName) {
        if (memoName) {
            delete this.memos[memoName];
        } else {
            this.previous = null;
        }
    }
}

PreviousStateService.$inject = ['$state', '$q', '$transitions'];
