import _ from 'lodash';

import {
    MESSAGES_UPDATE_CURRENT_THREAD,
    MESSAGES_ADD_MESSAGE_TO_CURRENT_THREAD,
    MESSAGES_RESET_CURRENT_THREAD,
} from './thread.actions';

const initialState = {};

export const currentThread = (state = initialState, action = {}) => {
    switch (action.type) {
        case MESSAGES_UPDATE_CURRENT_THREAD:
            const {thread} = action.payload;
            return thread;
        case MESSAGES_ADD_MESSAGE_TO_CURRENT_THREAD:
            const {message} = action.payload;
            let updatedMessages = [
                ...state.messages,
                message,
            ];
            updatedMessages = _.uniqBy(updatedMessages ,'id');
            return _.assign({}, state, {
                messages: updatedMessages
            });
        case MESSAGES_RESET_CURRENT_THREAD:
            return {};
        default:
            return state;
    }
};
