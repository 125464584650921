import { NOTIFICATION_TYPE } from '../model/notification-type';
import { toggleNotificationCenterPanelTab } from '../action/notification-center-panel';
import NotificationCenterPanelNotificationsTemplate from '../../template/notification-center-panel-notifications.html';
import {
    addNotifications,
    markNotificationAsRead,
    markAllNotificationsAsRead,
} from '../action/notification-center-notifications.js';

class NotificationCenterPanelNotificationsController {
    constructor(
        $q,
        $scope,
        $state,
        $pfToast,
        $ngRedux,
        $window,
        $pfEntryEditor,
        $pfSubscription,
        $pfNotificationCenter,
        $pfUser,
        $pfEnv,
        $mdMedia,
    ) {
        this._$q = $q;
        this._$state = $state;
        this._$pfToast = $pfToast;
        this._$ngRedux = $ngRedux;
        this._$window = $window;
        this._$pfEntryEditor = $pfEntryEditor;
        this._$pfSubscription = $pfSubscription;
        this._$pfNotificationCenter = $pfNotificationCenter;
        this._$pfUser = $pfUser;
        this._$pfEnv = $pfEnv;
        this._$mdMedia = $mdMedia;

        // Subscribe to state updates
        const unsubscribe = $ngRedux.connect(this._mapStateToThis)(this);

        // Unsubscribe when panel is closed
        $scope.$on('$destroy', unsubscribe);
    }

    $onInit() {
        // Prevents infinite scroll from making multiple API calls, when set to true
        this.isLoading = false;
    }

    _mapStateToThis(state) {
        return {
            panel: state.notificationCenter.notificationCenterPanel,
            notificationStore:
                state.notificationCenter.notificationCenterNotifications,
        };
    }

    /**
     * @description Checks notification object and type to determine status
     * @param  {Object}  notification Notification
     * @return {Boolean}              is the notification diabled
     */
    isDisabled(notification) {
        return (
            notification.object === null &&
            notification.type !== NOTIFICATION_TYPE.join
        );
    }

    /**
     * @description Load a new set of notifications
     * @returns {Promise<Array>}
     */
    loadNotifications() {
        if (this.notificationStore.finished) {
            return this._$q.resolve();
        }

        // Set loading flag
        this.isLoading = true;

        return this._$pfNotificationCenter.getNotifications().then(
            notifications => {
                // Set messages
                return this.appendNotifications(notifications).then(() => {
                    // Reset loading flag
                    this.isLoading = false;
                });
            },
            () => {
                // API call failed
                this._$pfToast.error(
                    'Unable to retrieve notifications, please try again later',
                );
            },
        );
    }

    /**
     * @description Add notifications to the collection
     * @param   {Array}   messages Messages collection
     * @returns {Promise}          Promise resolved with updated Messages collection
     */
    appendNotifications(notifications) {
        this._$ngRedux.dispatch(addNotifications(notifications));

        return this._$q.resolve(notifications);
    }

    /**
     * Mark the selected notification as being read
     * @param  {String} notificationId ID of the notification
     */
    markNotificationAsRead(notificationId) {
        this._$pfNotificationCenter
            .markNotificationAsRead(notificationId)
            .then(response => {
                if (response.status === 200) {
                    // Send dispatch to change state of notifications
                    this._$ngRedux.dispatch(
                        markNotificationAsRead(notificationId),
                    );
                }
            });
    }

    /**
     * Mark all notifications as read
     * @return {Object} response     status of the network call
     */
    markAllNotificationsAsRead() {
        this._$pfNotificationCenter
            .markAllNotificationsAsRead()
            .then(response => {
                if (response.status === 200) {
                    // Send dispatch to change state of notifications
                    this._$ngRedux.dispatch(markAllNotificationsAsRead());
                    // Show success toast
                    this._$pfToast.success('Notifications marked as read');
                }
            });
    }

    /**
     * Handle the click event to take the appropriate action based on current status
     * @param {jqEvent} $event on click event
     * @param  {Object} notification    The notification in question
     */
    onClick($event, notification) {
        if (notification.seen === '0') {
            // Mark the clicked notification as seen
            this.markNotificationAsRead(notification.id);
        }

        // Close the notification panel
        this.close();

        // Determine click action based on the notifications dynamic type
        switch (notification.dynamic_type) {
            case 'join':
                return this._$pfEntryEditor.newEntry({
                    source: 'joinNotification',
                    targetEvent: $event,
                });

            case 'user':
                if (!!this._$state.current.name) {
                    return this._$state.go('profile.portfolio', {
                        username: notification.object.username,
                    });
                }
                // If not on angular page use window location replace
                return this._$window.location.assign(
                    `/${notification.object.username}`,
                );

            case 'application':
                // Make sure user is a subscriber before routing to applicant page
                if (this._$pfSubscription.isSubscriber()) {
                    return this._$window.location.assign(
                        `/job/${notification.object.fk_job_id}/applications/${notification.object.id}`,
                    );
                }

                // If not a subscriber, route to user profile
                if (!!this._$state.current.name) {
                    return this._$state.go('profile.portfolio', {
                        username: notification.object.profile.username,
                    });
                }

                // If not on angular page use window location replace
                return this._$window.location.assign(
                    `/${notification.object.profile.username}`,
                );

            case 'job':
                return this._$window.location.assign(
                    `/job/${notification.object.slug}`,
                );

            case 'entry':
                return this._$window.location.assign(
                    `/entry/${notification.object.slug}`,
                );

            case 'network':
                if (!!this._$state.current.name) {
                    return this._$state.go('networkEntries', {
                        network: notification.object.slug,
                    });
                }
                return this._$window.location.assign(
                    `/network/${notification.object.slug}`,
                );

            case 'enrollment':
                //  get course slug
                const { slug: enrollmentCourseSlug } = notification.object;
                // check if you're on an angular app
                if (!!this._$state.current.name) {
                    return this._$state.go('course', {
                        slug: enrollmentCourseSlug,
                        sort: 'duedate',
                    });
                }

                // if not on angular page use window location replace
                return this._$window.location.assign(
                    `/course/${enrollmentCourseSlug}`,
                );

            case 'assignment_submit':
                // get the Canvas URL
                const baseUrl = this._$pfEnv.eduUrl.replace(/\/+$/, '');

                const {
                    fk_edu_course_id: assignmentSubmitCourseId,
                    fk_assignment_id: assignmentSubmitAssignmentId,
                    id: assignmentSubmitUserAssignmentId,
                } = notification.object;

                const url = `${baseUrl}/assessment/course/${assignmentSubmitCourseId}/assignment/${assignmentSubmitAssignmentId}?user_assignment_id=${assignmentSubmitUserAssignmentId}`;

                // open the link a new tab
                this._$window.open(url);
                break;

            case 'assignment':
                // set assignmentId
                const {
                    id: assignmentId,
                    course_slug: courseSlug,
                } = notification.object;

                // check if you're on an angular app
                if (!!this._$state.current.name) {
                    return this._$state.go('assignment', {
                        assignmentId,
                        courseSlug,
                    });
                }

                // if not on angular page use window location replace
                return this._$window.location.assign(
                    `/course/${courseSlug}/assignment/${assignmentId}`,
                );

            case 'requirement':
                const {
                    object: { slug: pathwaySlug },
                    object_id: requirementId,
                } = notification;

                // get current user
                const user = this._$pfUser.getUser();

                // check if you're on an angular app
                if (!!this._$state.current.name) {
                    return this._$state.go(
                        'requirement',
                        {
                            username: user.username,
                            slug: pathwaySlug,
                            requirementId,
                        },
                        {
                            reload: false,
                        },
                    );
                }
                // if not on angular page use window location replace
                return this._$window.location.assign(
                    `/${user.username}/pathway/${pathwaySlug}/requirement/${requirementId}`,
                );
        }
    }

    /**
     * Close the panel without saving any changes
     * @return {Promise}
     */
    close() {
        return this.panelCtrl.close();
    }

    /**
     * Checks it the current window is mobile
     * @return {Boolean}
     */
    get isMobile() {
        return !this._$mdMedia('gt-sm');
    }

    /**
     * Change panel tabs
     * @param  {String}             tabName Tab to transition to
     * @return {void|Promise<void>}
     */
    activateTab(tabName) {
        if (tabName === this.panel.activeTab) {
            // Already on the tab
            return;
        }
        // check if you're on an angular app
        if (!!this._$state.current.name) {
            // if it is already showing a thread, do not move
            if (this.isMobile || !this._$state.is('thread')) {
                // go to messages tab
                this._$state.go('messages');
            }
            // close panel
            this.close();
            return;
        }
        // close panel
        this.close();
        // if not on angular page use window location replace
        return this._$window.location.assign(`/messages`);
    }
}

NotificationCenterPanelNotificationsController.$inject = [
    '$q',
    '$scope',
    '$state',
    '$pfToast',
    '$ngRedux',
    '$window',
    '$pfEntryEditor',
    '$pfSubscription',
    '$pfNotificationCenter',
    '$pfUser',
    '$pfEnv',
    '$mdMedia',
];

export const NotificationCenterPanelNotificationsComponent = {
    require: {
        panelCtrl: '^pfNotificationCenterPanel',
    },
    bindings: {
        panel: '<pfNotificationCenterPanel',
    },
    template: NotificationCenterPanelNotificationsTemplate,
    controller: NotificationCenterPanelNotificationsController,
};
