import angular from 'angular';

import { GdprSnackbarComponent } from './gdpr-snackbar/gdpr-snackbar.component';
import { GdprService } from './gdpr.service';
 import {
     GDPR_MESSAGES,
     GDPR_COOKIE_DEFAULT_CHOICES
} from './gdpr-messages.constant';

const MODULE_NAME = 'portfolium.component.gdpr';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfGdprSnackbar', GdprSnackbarComponent)
        // Services
        .service('$pfGdprService', GdprService)
        // Constants
        .constant('PF_GDPR_MESSAGES', GDPR_MESSAGES)
        .constant('PF_GDPR_COOKIE_DEFAULT_CHOICES', GDPR_COOKIE_DEFAULT_CHOICES);
}());

export default MODULE_NAME;
