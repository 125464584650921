class CompanyCardController
{
    constructor($pfUser) {
        this.currentUser = $pfUser.getUser();
    }

    /**
      * @ngdoc method
      * @name isDefaultCoverImage
      * @description checks for default cover image
      * @return {Void||Boolean}
      */
    isDefaultCoverImage() {
        if (!this.company.id) {
            return undefined;
        }

        if (this.company.cover.source === null) {
            return true
        }

        return false;
    }

    /**
      * @ngdoc method
      * @name getCoverImage
      * @description checks for and returns a background-image object
      * @return {Void||Object} Default image returns empty object
      */
    getCoverImage() {
        if (!this.company.id) {
            return undefined;
        }

        if (this.isDefaultCoverImage()) {
            return {};
        }

        return {
            'background-image': `url(${this.company.cover.url_https})`,
        };
    }
}

CompanyCardController.$inject = ['$pfUser'];

export const companyCardDirective = ($templateCache, imageFilter) => {
    let templateBase = 'template/components/company/company-connect/template/card';

    return {
        restrict: 'E',
        scope: {
            'company': '=pfCompany'
        },
        bindToController: true,
        link: function (scope, elem, attrs, companyCtrl) {
            if (!_.isObject(companyCtrl.company)) {
                throw new Error('[pfCompanyCard] You must provide a company!');
            }

            companyCtrl.coverImage = imageFilter(companyCtrl.company.cover.url_https, 144, 256);
            companyCtrl.logoImage = imageFilter(companyCtrl.company.logo.url_https, 128, 128);

            elem.attr('tabindex', -1);
        },
        controller: CompanyCardController,
        controllerAs: 'companyCtrl',
        template: function (elem, attrs) {
            let template = $templateCache.get(`${templateBase}/standard.html`);

            if (_.isString(attrs.pfCardType)) {
                template = $templateCache.get(`${templateBase}/${attrs.pfCardType}.html`);
            }

            if (!template) {
                throw new Error('[pfCompanyCard] Template not found!');
            }

            return template;
        }
    };
}

companyCardDirective.$inject = ['$templateCache', 'imageFilter'];
