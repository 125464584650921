import angular from 'angular';

import AuthProvidersModule from './auth-providers/auth-providers.module';

import requireAuthDirective from './js/require-auth-directive';
import usernameDirective from './js/username-directive';
import companyNameDirective from './js/company-name.directive';
import logoutTasksDirective from './js/logout-tasks.directive';

import UserService from './js/user-service';
import AuthService from './js/auth-service';
import SignUpService from './js/sign-up-service';
import SessionService from './js/session-service';

import './auth.module.scss';

const MODULE_NAME = 'portfolium.component.auth';

(function() {
    angular
        .module(MODULE_NAME, [AuthProvidersModule])
        // Directives
        .directive('pfRequireAuth', requireAuthDirective)
        .directive('pfUsername', usernameDirective)
        .directive('pfCompanyName', companyNameDirective)
        .directive('pfLogoutTasks', logoutTasksDirective)
        // Services
        .service('$pfUser', UserService)
        .service('$pfAuth', AuthService)
        .service('$pfSignUp', SignUpService)
        .service('$pfSession', SessionService);
})();

export default MODULE_NAME;
