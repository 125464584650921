export const userProfileMenuDirective = () => {
    return {
        link: function (scope, elem, attrs, ctrl) {
            ctrl.isActingAs = scope.$eval(attrs.pfIsActingAs);
        },
        controller: ['$pfUser', function ($pfUser) {
            this.currentUser = $pfUser.getUser();
        }],
        controllerAs: 'userMenuCtrl',
        template: `
            <md-menu-item class="pf-profile-link">
                <md-button
                     ui-sref="profile.about({username: userMenuCtrl.currentUser.username})"
                     pf-sref="profile.about"
                     href="/{{::userMenuCtrl.currentUser.username}}">
                    <p flex><strong>{{::userMenuCtrl.currentUser.name}}</strong><br><small>{{ "View Profile" | i18n }}</small></p>
                </md-button>
            </md-menu-item>
            <md-menu-divider></md-menu-divider>
            <md-menu-item ng-if="::!userMenuCtrl.currentUser.isCompanyAdmin()">
                <md-button
                     ui-sref="myJobs(::{username: userMenuCtrl.currentUser.username})"
                     pf-sref="myJobs"
                     href="/{{::userMenuCtrl.currentUser.username}}/jobs">
                    <p flex>{{ "My Jobs" | i18n }}</p>
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <md-button ui-sref="settings"
                     pf-sref="settings"
                     href="/settings">
                    <p flex>{{ "Settings" | i18n }}</p>
                </md-button>
            </md-menu-item>
            <md-menu-item ng-if="::!userMenuCtrl.currentUser.isCompanyAdmin()">
                <md-button ui-sref="invite"
                     pf-sref="invite"
                     href="/invite">
                    <p flex>{{ "Invite" | i18n }}</p>
                </md-button>
            </md-menu-item>
            <md-menu-item>
                <md-button
                     href="{{::(userMenuCtrl.currentUser.isCompanyAdmin()) ? 'https://community.canvaslms.com/docs/DOC-18216' : 'https://community.canvaslms.com/community/answers/guides/portfolio'}}"
                     target="_blank">
                    <p flex>{{ "User Guide" | i18n }}</p>
                </md-button>
            </md-menu-item>
            <md-menu-divider></md-menu-divider>
            <md-menu-item ng-if="::userMenuCtrl.isActingAs">
                <md-button href="/switch-back">
                    <p flex>{[ "Switch Back" | i18n }}</p>
                </md-button>
            </md-menu-item>
            <md-menu-item ng-if="::!userMenuCtrl.isActingAs">
                <md-button href="/logout"
                     pf-logout-tasks
                     pf-social-auth-clear>
                    <p flex>{{ "Logout" | i18n }}</p>
                </md-button>
            </md-menu-item>
        `
    };
};
