import PathwayRequirementSubmissionPanelTemplate from './pathway-requirement-submission-panel.component.html';
import './pathway-requirement-submission-panel.component.scss';

/**
 * @ngdoc controller
 * @name PathwayRequirementSubmissionPanelController
 * @module portfolium.component.pathways.requirement.submissionPanel
 **/
class PathwayRequirementSubmissionPanelController {
    constructor() {
        this.showList = true;
    }

    showEditor() {
        this.showList = false;
    }

    hideEditor() {
        this.showList = true;
    }

    projectSelected() {
        this.onProjectSelected().then(() => {
            this.close('copy');
        });
    }

    /**
      * @ngdoc method
      * @name close
      * @param reason The reason close was called
      * @description
      * Close panel
      */
    close(reason) {
        this.mdPanelRef.close(reason);
    }
}

/**
 * @ngdoc component
 * @name PathwayRequirementSubmissionPanelComponent
 * @module portfolium.component.pathways.requirement.submissionPanel
 **/
export const PathwayRequirementSubmissionPanelComponent = {
    bindings: {
        'mdPanelRef': '<',
        'projectId': '<',
        'onProjectSelected': '&',
        'currentStep': '<',
    },
    controller: PathwayRequirementSubmissionPanelController,
    template: PathwayRequirementSubmissionPanelTemplate,
};
