export const PHRASES_TAG_TEAMMATES = [
    'worked in a group',
    'worked in team',
    'worked on team',
    'worked on a team',
    'group project',
    'teammate',
    'in a group of',
    'team member',
    'team project',
    'part of a team',
    'collaborate',
    'classmates',
    'colleagues',
    'we worked',
];

export const SKILLS_TAG_TEAMMATES = [
    'teamwork',
    'collaboration',
];
