export class SkillsService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * Add a new skill for the authed User
     * @param  {Object}  data Form data
     * @return {Promise}
     */
    addSkill(data) {
        return this.$http({
            method: 'POST',
            url: `/proxy/skills/create`,
            data: data
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Edit the user's existing skill
     * @param  {String}  skillId ID of the skill to edit
     * @param  {Object}  data    Properties to update on skill
     * @return {Promise}
     */
    editSkill(skillId, data) {
        return this.$http({
            method: 'POST',
            url: `/proxy/skills/skill/${skillId}`,
            data: data
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Remove a user's skill
     * @param  {String}  skillId ID of skill to delete
     * @return {Promise}
     */
    removeSkill(skillId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/skills/skill/${skillId}`
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Search the skills list
     * @param  {String}  query Search query
     * @return {Promise}
     */
    searchSkills(query) {
        return this.$http({
            method: 'GET',
            url: `/proxy/search/skills`,
            params: {
                q: query
            }
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Get a user's skills
     * @param  {String}  userId User ID
     * @return {Promise}
     */
    getUserSkills(userId) {
        return this.$http({
            method: 'GET',
            url: `/proxy/users/about/${userId}`
        }).then((response) => {
            let {data} = response;

            return data.skills;
        });
    }
}

SkillsService.$inject = ['$http'];
