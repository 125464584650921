class StandardContestCardController
{
    constructor() {

    }
}

function contestCardDirective($templateCache, imageFilter) {
    let templateBase = 'template/components/contest-card/template/card';

    return {
        restrict: 'E',
        scope: {
            'contest': '=pfContest',
        },
        bindToController: true,
        link: function (scope, elem, attrs, contestCardCtrl) {
            if (!_.isObject(contestCardCtrl.contest)) {
                throw new Error('[pfContestCard] You must provide a contest!');
            }

            contestCardCtrl.defaultImage = contestCardCtrl.contest.cover && imageFilter(contestCardCtrl.contest.cover.url_https, 144, 256);

            elem.attr('tabindex', -1);
        },
        controller: StandardContestCardController,
        controllerAs: 'contestCardCtrl',
        template: function (elem, attrs) {
            let template = $templateCache.get(`${templateBase}/standard.html`);

            if (_.isString(attrs.pfCardType)) {
                template = $templateCache.get(`${templateBase}/${attrs.pfCardType}.html`);
            }

            if (!template) {
                throw new Error('[pfContestCard] Template not found!');
            }

            return template;
        }
    };
}

contestCardDirective.$inject = ['$templateCache', 'imageFilter'];

export default contestCardDirective;
