import EntryVoteButtonTemplate from './entry-vote-button.component.html';
import './entry-vote-button.component.scss';

/**
 * @ngdoc controller
 * @name EntryVoteButtonController
 * @module portfolium.component.entryCard
 **/
class EntryVoteButtonController {
    constructor($pfChallenges, $element) {
        this.$pfChallenges = $pfChallenges;
        this.$element = $element;
    }

    $onInit() {
        // Skip custom element when tabbing -- go straight to the button inside
        this.$element.attr('tabindex', '-1');
    }

    /**
     * Get voted status from either project or contest model
     * @param {Boolean}
     */
    get voted() {
        if (this.isEntryCard) {
            // Parse voted model value from project object
            return this.entry.voted === '1';
        }

        // Parse voted model value from contest object
        return this.contest.voted === '1';
    }

    /**
     * Update model to reflect user vote
     */
    set voted(voted = '0') {
        if (this.isEntryCard) {
            // update project model
            this.entry = _.assign({}, this.entry, {
                voted: voted,
            });
        }

        // update contest model
        this.contest = _.assign({}, this.contest, {
            voted: voted,
        });
    }

    /**
     * Cast a vote for this project on the given contest
     * @return {Promise}
     */
    vote() {
        // Update model immediately
        this.voted = '1';

        return this.$pfChallenges.voteForEntry(this.entry.id, this.contest.id)
            .then(() => {
                this.onVote({contest: this.contest});
                // Do nothing on success
                return;
            }, () => {
                // Revert model value on failure
                return this.voted = '0';
            });
    }
}

EntryVoteButtonController.$inject = [
    '$pfChallenges',
    '$element',
];

/**
 * @ngdoc component
 * @name EntryVoteButtonComponent
 * @module portfolium.component.entryCard
 **/
export const EntryVoteButtonComponent = {
    bindings: {
        'contest': '<',
        'entry': '<',
        'isEntryCard': '<',
        'challengeComplete': '<',
        'onVote': '&',
    },
    controller: EntryVoteButtonController,
    template: EntryVoteButtonTemplate,
};
