import {addAbout, updateAbout} from '../../../../profile/js/action/abouts';

export const OPEN_ABOUT_PANEL = 'OPEN_ABOUT_PANEL';
export const CLOSE_ABOUT_PANEL = 'CLOSE_ABOUT_PANEL';
export const TOGGLE_ABOUT_PANEL_TAB = 'TOGGLE_ABOUT_PANEL_TAB';
export const ABOUT_PANEL_SAVE_FORM = 'ABOUT_PANEL_SAVE_FORM';
export const ABOUT_PANEL_ATTACH_ENTRY = 'ABOUT_PANEL_ATTACH_ENTRY';
export const ABOUT_PANEL_DETACH_ENTRY = 'ABOUT_PANEL_DEATACH_ENTRY';
export const ABOUT_PANEL_UPDATE_ENTRIES = 'ABOUT_PANEL_UPDATE_ENTRIES';
export const ABOUT_PANEL_UPDATE_ENTRIES_ORDER = 'ABOUT_PANEL_UPDATE_ENTRIES_ORDER';
export const ABOUT_PANEL_UPDATE_ENTRY_LIST = 'ABOUT_PANEL_UPDATE_ENTRY_LIST';
export const ABOUT_PANEL_REORDER_ENTRY_LIST = 'ABOUT_PANEL_REORDER_ENTRY_LIST';
export const ABOUT_PANEL_UPDATE_SEARCH_RESULTS = 'ABOUT_PANEL_UPDATE_SEARCH_RESULTS';
export const ABOUT_PANEL_CLEAR_SEARCH_RESULTS = 'ABOUT_PANEL_CLEAR_SEARCH_RESULTS';
export const ABOUT_PANEL_TOGGLE_ORDERING = 'ABOUT_PANEL_TOGGLE_ORDERING';

export const ABOUT_PANEL_TABS = {
    EDIT_DETAILS: 'EDIT_DETAILS',
    WORK_SAMPLES: 'WORK_SAMPLES',
};

export const openAboutPanel = (payload) => {
    let sectionName = payload.aboutType + 's';

    if (payload.aboutType === 'introduction') {
        sectionName = 'introduction';
    }

    return {
        type: OPEN_ABOUT_PANEL,
        payload: _.assign({}, payload, {
            sectionName,
        }),
    };
};

export const closeAboutPanel = (payload) => {
    return (dispatch, getState) => {
        const {profile, profileAboutPanel} = getState();

        dispatch({
            type: CLOSE_ABOUT_PANEL,
            payload,
        });

        if (!profileAboutPanel.data.id) {
            // Exited without saving, don't sync about data
            return;
        }

        if (_.find(profile.abouts[profileAboutPanel.sectionName], {id: profileAboutPanel.data.id})) {
            // About already exists, update it
            dispatch(
                updateAbout(
                    profileAboutPanel.data.id,
                    profileAboutPanel.sectionName,
                    profileAboutPanel.data
                )
            );

            return;
        }

        // Add a new about
        dispatch(
            addAbout(
                profileAboutPanel.sectionName,
                profileAboutPanel.data
            )
        );
    };
};

export const toggleAboutPanelTab = (tabName) => {
    return {
        type: TOGGLE_ABOUT_PANEL_TAB,
        tabName,
    };
};

export const saveForm = (data) => {
    return {
        type: ABOUT_PANEL_SAVE_FORM,
        payload: data,
    };
};

export const attachEntry = (data) => {
    return {
        type: ABOUT_PANEL_ATTACH_ENTRY,
        payload: data,
    };
};

export const detachEntry = (data) => {
    return {
        type: ABOUT_PANEL_DETACH_ENTRY,
        payload: data,
    };
};

export const updateEntries = (entries) => {
    return {
        type: ABOUT_PANEL_UPDATE_ENTRIES,
        payload: entries,
    };
};

export const updateEntriesOrder = (order) => {
    return {
        type: ABOUT_PANEL_UPDATE_ENTRIES_ORDER,
        payload: order,
    };
};

export const updateSearchResults = (entries) => {
    return {
        type: ABOUT_PANEL_UPDATE_SEARCH_RESULTS,
        payload: entries,
    };
};

export const clearSearchResults = () => {
    return (dispatch) => {
        dispatch({
            type: ABOUT_PANEL_CLEAR_SEARCH_RESULTS,
        });

        dispatch(reorderEntryList());
    };
};

export const updateEntryList = (entries) => {
    return {
        type: ABOUT_PANEL_UPDATE_ENTRY_LIST,
        payload: entries,
    };
};

export const reorderEntryList = () => {
    return {
        type: ABOUT_PANEL_REORDER_ENTRY_LIST,
    };
};

export const toggleOrdering = () => {
    return (dispatch) => {
        dispatch({
            type: ABOUT_PANEL_TOGGLE_ORDERING,
        });

        dispatch(reorderEntryList());
    };
};
