import { BaseAssignmentUtilitiesService } from './base-assignment-utilities.service';

/**
 * @ngdoc service
 * @name AssignmentsUtilitiesService
 * @module portfolium.assignments
 **/
 export class AssignmentsUtilitiesService extends BaseAssignmentUtilitiesService {
     constructor(
        $filter,
        $pfEntryDetails,
        isDefaultImageFilter,
        PF_ASSIGNMENTS_TYPES,
        PF_ASSIGNMENTS_STATUS,
        PF_ASSIGNMENTS_STATUS_MAPPED,
        PF_ASSIGNMENT_ACTION_TYPE_CODES,
        PF_REQUIREMENT_ACTION_TYPE_CODES,
        PF_ENTRY_CLASSIFICATIONS,
        PF_PATHWAY_REQUIREMENT_TYPES,
     ) {
        super(
            $filter,
            $pfEntryDetails,
            PF_ASSIGNMENTS_STATUS,
            PF_ASSIGNMENTS_STATUS_MAPPED,
            PF_ASSIGNMENT_ACTION_TYPE_CODES,
            PF_REQUIREMENT_ACTION_TYPE_CODES,
            PF_ENTRY_CLASSIFICATIONS,
            PF_PATHWAY_REQUIREMENT_TYPES,
        );
        this.isDefaultImageFilter = isDefaultImageFilter;
        this.PF_ASSIGNMENTS_TYPES = PF_ASSIGNMENTS_TYPES;
        this.PF_ASSIGNMENT_ACTION_TYPE_CODES = PF_ASSIGNMENT_ACTION_TYPE_CODES;
    }

    /**
     * Generate a title for the unstarted button
     * @param  {Object}  assignment
     * @return {String}
     */
    getUnstartedButtonText(assignment) {
        // sanity check that the assignment data exists
        if (_.isEmpty(assignment)) {
            return 'Start Project';
        }
        return 'Start Assignment';
    }

    /**
     * Generate a title for the submit button
     * @param  {Object}  assignment
     * @return {String}
     */
    getSubmitButtonText(assignment) {
        if (this.needsRevision(assignment) || this.isUnsubmitted(assignment)) {
            return 'Resubmit Assignment';
        }
        return 'Submit Assignment';
    }

    /**
     * Generate a title for the unsubmit button
     * @param  {Object}  assignment
     * @return {String}
     */
    getUnsubmitButtonText(assignment) {
        // sanity check that the assignment data exists
        if (_.isEmpty(assignment)) {
            return 'Unsubmit Project';
        }
        return 'Unsubmit Assignment';
    }

    /**
     * Generate a title for the edit button
     * @param  {Object}  assignment
     * @return {String}
     */
    getEditButtonText(assignment) {
        // sanity check that the assignment data exists
        if (_.isEmpty(assignment)) {
            return 'Edit Project';
        }
        return 'Edit Assignment';
    }

    /**
     * Check if the user has a LTI context for the assignment
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    userHasLTIAssignmentContext(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return assignment.user_assignment_has_lti_context === '1';
    }

    /**
     * Check if the assignment's course has a LTI context
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isLTICourse(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return assignment.course_has_lti_context === '1';
    }

    /**
     * Check if the assignment has a LTI context
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isLTIAssignment(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return assignment.assignment_has_lti_context === '1';
    }

    /**
     * Check if an assignment LTI flow is completed. If the course has
     * a context, the assignment should have one in order for the flow to
     * be completed. If the assignment doesnt have a context, who cares...?
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isLTICompleted(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }

        // when the course does not have a context, everything is good
        if (!this.isLTICourse(assignment)) {
            return true;
        }

        // when the assignment does not have a context, everything is good
        if (!this.isLTIAssignment(assignment)) {
            return true;
        }

        // if the course has a context
        // if the assignment has a context
        // then see if the user has a context for the assignment as well
        return this.userHasLTIAssignmentContext(assignment);
    }

    isUnsubmittable(assignment) {
        if (!this.isSubmitted(assignment)) {
            return false;
        }
        if (this.isLate(assignment)) {
            return false;
        }
        return true;
    }

    /**
     * Checks if an assignment has been previously submitted
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isUnsubmitted(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        const actions = _.get(assignment, 'actions');
        if (_.isEmpty(actions)) {
            return;
        }
        const { ASSIGNMENT_UNSUBMITTED } = this.PF_ASSIGNMENT_ACTION_TYPE_CODES;
        const foundUnsubmitted = _.find(actions, { 'type': ASSIGNMENT_UNSUBMITTED });

        return !this.isSubmitted(assignment)
            && !this.isFinished()
            && !_.isEmpty(foundUnsubmitted);
    }

    /**
     * Checks if an assignment type is pending review
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isPendingReview(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.isSubmitted(assignment) && this.isLate(assignment);
    }

    /**
     * Checks if an assignment is late
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isLate(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.isLateBase(assignment.assignment_due_date);
    }

    /**
     * Checks if an assignment type is rubric
     * @param  {Object} assignment
     * @return {Boolean|undefined}
     */
    isRubric(assignment) {
        const type = this.getType(assignment);
        if (!type) {
            return;
        }
        return type.type === 'RUBRIC';
    }

    /**
     * Checks if an assignment type is 3 stars
     * @param  {Object} assignment
     * @return {Boolean|undefined}
     */
    is3Star(assignment) {
        const type = this.getType(assignment);
        if (!type) {
            return;
        }
        return type.type === '3_STAR';
    }

    /**
     * Checks if an assignment type is 5 stars
     * @param  {Object} assignment
     * @return {Boolean|undefined}
     */
    is5Star(assignment) {
        const type = this.getType(assignment);
        if (!type) {
            return;
        }
        return type.type === '5_STAR';
    }

    /**
     * Checks if an assignment type is ABCDEF
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isAbcdf(assignment) {
        const type = this.getType(assignment);
        if (!type) {
            return;
        }
        return type.type === 'ABCDF';
    }

    /**
     * Checks if an assignment type is Pass/Fail
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isPassFail(assignment) {
        const type = this.getType(assignment);
        if (!type) {
            return;
        }
        return type.type === 'PASS_FAIL';
    }

    /**
     * Checks if an assignment type is Numeric Scoring
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isNumeric(assignment) {
        const type = this.getType(assignment);
        if (!type) {
            return;
        }
        return type.type === 'NUMERIC';
    }

    isPassScore(assignment) {
        if (!this.isPassFail(assignment)) {
            return false;
        }
        return this.getCurrentScore(assignment) === 1;
    }

    isFailScore(assignment) {
        if (!this.isPassFail(assignment)) {
            return false;
        }
        return this.getCurrentScore(assignment) === 2;
    }

    /**
     * Checks if an assignment rubric is visible
     * @param  {Object} requirement
     * @return {Boolean|undefined}
     */
    isRubricVisible(assignment) {
        if (!this.isRubric(assignment)) {
            return false;
        }
        return assignment.assignment_transparency === '1';
    }

    /**
     * Is this an assignment that has been set as sensitive?
     * @param  {Object}  assignment
     * @return {boolean}
     */
    isSensitive(assignment) {
        return !_.isEmpty(assignment) && assignment.assignment_sensitive === '1';
    }

    /**
     * Checks if an assignment has grades hidden flag
     * @param  {Object}  assignment
     * @return {Boolean|undefined}
     */
    isGradeHidden(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return assignment.assignment_grades_hidden === '1';
    }

    hasBadge(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return assignment.assignment_has_badge === '1';
    }

    hasEarnedBadge(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return assignment.assignment_earned_badge === '1';
    }

    hasDueDate(assignment) {
        return !_.isNil(this.getDueDate(assignment));
    }

    getAttachedEntry(assignment) {
        return _.get(assignment, 'user_entry') || {};
    }

    getAssignmentTitle(assignment) {
        return _.get(assignment, 'assignment_title');
    }

    getDescription(assignment) {
        return _.get(assignment, 'assignment_description');
    }

    getGradedAt(assignment) {
        return _.get(assignment, 'user_assignment_graded_at');
    }

    getDueDate(assignment) {
        return _.get(assignment, 'assignment_due_date');
    }

    getAssignmentImage(assignment) {
        const imageSrc = _.get(assignment, `user_entry.cover.file.url`)

        if (!imageSrc) {
            return false;
        }

        if (this.isDefaultImageFilter(imageSrc)) {
            return false;
        }

        return imageSrc;
    }

    /**
     * Finds the type name of an assignment
     * @param  {Object} assignment
     * @return {String|undefined} assignment type name
     */
    getTypeName(assignment) {
        const type = this.getType(assignment);
        if (!type) {
            return;
        }
        return type.name;
    }

    /**
     * TODO Whenever we update assignment.assignment_type and submission.type properties to be the same, this function can be moved to $pfBaseAssignmentUtilities
     * Also, anything using this function can most likely be shared as well, this is one of the only things holding us back
     *
     * Finds the type of an Assignment
     * @param  {Object} assignment
     * @return {Object|undefined} assignment type
     */
    getType(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        if (!assignment.assignment_type) {
            return;
        }
        const currentTypeCode = parseInt(assignment.assignment_type, 10);
        const type = _.find(this.PF_ASSIGNMENTS_TYPES, ({ id }) => {
            return id === currentTypeCode;
        });
        return type;
    }

    /**
     * get the max possible value of score
     * @param  {Object} assignment
     * @return {Number}
     */
    getTotalScore(assignment) {
        const type = this.getType(assignment);
        if (!type) {
            return;
        }

        if (this.isRubric(assignment)) {
            const pointsAvailable = assignment.rubric.points_available;
            return parseFloat(pointsAvailable)
        }
        return parseInt(type.total);
    }

    getCurrentScore(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }

        const { user_avg_score } = assignment;

        if (_.isEmpty(user_avg_score)) {
            return;
        }
        if (this.isRubric(assignment) || this.isAbcdf(assignment)) {
            return parseFloat(user_avg_score);
        }
        return parseInt(user_avg_score);
    }

    /**
     * get the max possible value of score
     * @param  {Object} assignment
     * @return {Number}
     */
    getScorePercentage(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        const totalScore = this.getTotalScore(assignment);
        if (totalScore === 0) {
            return 0;
        }
        let currentScore = this.getCurrentScore(assignment);
        if (this.isPassFail(assignment)) {
            currentScore = this.calculatePassFailScore(currentScore);
        }
        if (this.isAbcdf(assignment)) {
            currentScore = this.calculateLetterGradeScore(currentScore);
        }
        const finalScore = (currentScore * 100) / totalScore;
        return finalScore.toLocaleString('en', {
            style: 'decimal',
            maximumFractionDigits: 2,
        });
    }

    /**
     * Finds the status of an assignment
     * @param  {Object} assignment
     * @return {Object|undefined} assignment status
     */
    getStatus(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.getBaseStatus(assignment);
    }

    getBreadCrumbData({ course_slug, course_title: label }) {
        const route = `course({ slug: '${course_slug}' })`;
        return {
            route,
            label,
        };
    }

    getBadge(assignment) {
        return _.get(assignment, 'badge');
    }

    getRequestedRevisionsForFeedbackComponent(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        const actions = _.get(assignment, 'actions', []);
        return this.getBaseRequestedRevisionsForFeedbackComponent(actions);
    }

    getSubmissionSubTitle(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        return this.getSubmissionSubTitleBase({
            isSubmitted: this.isSubmitted(assignment),
            submittedAt: assignment.user_submitted_at,
            updatedAt: _.get(assignment, 'user_entry.updated_at', null),
            attachmentCount: this.getAttachmentCount(assignment),
        });
    }

    getAttachmentCount(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        const attachments = _.get(assignment, 'user_entry.attachments');
        if (_.isEmpty(attachments)) {
            return 0;
        }
        return attachments.length;
    }

    getAllLearningOutcomes(assignment) {
        if (!this.isRubric(assignment)) {
            return;
        }
        return this.getAllLearningOutcomesBase(assignment);
    }

    getRubricUrl(assignment) {
        if (!this.isRubric(assignment)) {
            return;
        }
        const rubricUrl = _.get(assignment, 'rubric.rubric_url');
        if (_.isEmpty(rubricUrl)) {
            return;
        }
        return rubricUrl;
    }

    showGradeSection(assignment) {
        return this.isInAFinishedLikeState(assignment) && !this.isGradeHidden(assignment)
    }

    showFeedbackSection(assignment, feedback) {
        // we currently don't show feedback for rubric scoring types
        if (this.isRubric(assignment)) {
            return;
        }

        return this.isInAFinishedLikeState(assignment) && !_.isNil(feedback) && !_.isEmpty(feedback);
    }

    showFeedbackAsNetwork() {
        // for assignments, show the teacher who graded - not the network
        return false;
    }

    showBadgesSection(assignment) {
        if (_.isEmpty(assignment)) {
            return;
        }
        if (!this.hasBadge(assignment)) {
            return false;
        }
        if (this.hasEarnedBadge(assignment)) {
            return true;
        }
        if (this.isIncomplete(assignment)) {
            return false;
        }
        // if is complete, and it was not earned, it means
        // student didnt meet the threshold
        if (this.isCompleted(assignment)) {
            return false;
        }
        // if we got here. the assignment, has a badge and it has potencial to earn it
        return true;
    }

    showCriteria(assignment) {
        if (!this.isInAFinishedLikeState(assignment)) {
            return false;
        }
        if (this.isGradeHidden(assignment)) {
            return false;
        }
        if (!this.isRubricVisible(assignment)) {
            return false;
        }
        return true;
    }

    showRubricPreviewUrl(assignment) {
        if (
            this.isInAFinishedLikeState(assignment) &&
            !this.isGradeHidden(assignment)
        ) {
            return false;
        }
        return this.isRubricVisible(assignment);
    }

    isCriteriaNaScored(criteria) {
        // get score and loa id
        const { score, fk_loa_id: loaId } = criteria;
        // sanity check for scored
        if (!_.isNull(score)) {
            // criteria doesnt have score
            return false;
        }
        // sanity check for loa id
        if (_.isNull(loaId)) {
            // criteria has score but it is N/A
            return true;
        }
        // criteria has score and a loa
        return false;
    }
}

AssignmentsUtilitiesService.$inject = [
    '$filter',
    '$pfEntryDetails',
    'isDefaultImageFilter',
    'PF_ASSIGNMENTS_TYPES',
    'PF_ASSIGNMENTS_STATUS',
    'PF_ASSIGNMENTS_STATUS_MAPPED',
    'PF_ASSIGNMENT_ACTION_TYPE_CODES',
    'PF_REQUIREMENT_ACTION_TYPE_CODES',
    'PF_ENTRY_CLASSIFICATIONS',
    'PF_PATHWAY_REQUIREMENT_TYPES',
];
