export const jobSearchDistanceOptions = [
    {
        title: '10 Miles',
        value: 10,
    },
    {
        title: '25 Miles',
        value: 25,
    },
    {
        title: '35 Miles',
        value: 35,
    },
    {
        title: '50 Miles',
        value: 50,
    },
    {
        title: '100 Miles',
        value: 100,
    }
];
