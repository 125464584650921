import _ from 'lodash';

import {
    UPDATE_COMPETENCIES,
    ADD_COMPETENCY,
    EDIT_COMPETENCY,
    REMOVE_COMPETENCY,
} from '../action/competencies';

export const competencies = (state = [], action = {}) => {
    switch (action.type) {
        case UPDATE_COMPETENCIES:
            return [
                ...action.payload,
            ];
        case ADD_COMPETENCY:
            return [
                ...state,
                action.payload,
            ];
        case EDIT_COMPETENCY:
            return state.map((competency) => {
                if (competency.id === action.id) {
                    return _.assign({}, competency, action.payload);
                }

                return competency;
            });
        case REMOVE_COMPETENCY:
            return state.filter(competency => competency.id !== action.id);
        default:
            return state;
    }
};
