import angular from 'angular';

import {LiveAddressComponent} from './live-address.component';
import {LiveAddressService} from './live-address.service';

const MODULE_NAME = 'portfolium.components.LiveAddress';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfLiveAddress', LiveAddressComponent)
        .service('$pfLiveAddress', LiveAddressService)
}());

export default MODULE_NAME;
