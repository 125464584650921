// url to crop on cloudimg
const cropUrl = 'https://portfolium1.cloudimg.io/fit';

/**
 * @ngdoc filter
 * @name imageFitFilter
 * @module portfolium.core.filters
 * @description creats a new image that fits in the width and height
 * @params {string} url url of the image to fit
 * @params {string} width height of the container to fit image
 * @params {string} height height of the container to fit image
 * @params {string} [color] background color
 **/
export const imageFitFilter = () => {
    return function(url, height, width, color = '000000') {
        // sanity check for url
        if (!url) {
            return;
        }
        // sanity check for width
        if (!width) {
            // return url without changes
            return url;
        }
        // sanity check for height
        if (!height) {
            // return url without changes
            return url;
        }
        // build and return the fitted url
        return `${cropUrl}/${width}x${height}/c${color}/${url}`
    };
}
