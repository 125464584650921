import './blank-avatar.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component
 * @name BlankAvatarController
 */
class BlankAvatarController {
    constructor($element) {
        this.$element = $element;
    }

    $postLink() {
        const backgroundColor = '0, 64, 102'; // $color-electric-dark-24 in RGB
        const opacity = (this.opacity) ? this.opacity : '.12';
        const borderRadius = (this.borderRadius) ? this.borderRadius : '50%';
        const size = (this.size) ? this.size : '40px';

        const styles = {
            'background-color': `rgba(${backgroundColor}, ${opacity})`,
            'border-radius': borderRadius,
            'height': size,
            'width': size,
        };

        this.$element.css(styles);
    }
}

BlankAvatarController.$inject = ['$element'];

/**
 * @ngdoc component
 * @module portfolium.component
 * @name BlankAvatarComponent
 * @description Display list of likers in the entry viewer sidebar
 *
 * @param {string} borderRadius
 * @param {string} size
 * @param {string} opacity
 */
export const BlankAvatarComponent = {
    bindings: {
        borderRadius: '@?',
        size: '@?',
        opacity: '@?',
    },
    controller: BlankAvatarController,
    controllerAs: '$ctrl',
};
