export const SETTINGS_EMAILS_REQUEST        = 'SETTINGS_EMAILS_REQUEST';
export const SETTINGS_EMAILS_ADD            = 'SETTINGS_EMAILS_ADD';
export const SETTINGS_UPDATE_EMAIL          = 'SETTINGS_UPDATE_EMAIL';
export const SETTINGS_UPDATE_EMAILS         = 'SETTINGS_UPDATE_EMAILS';
export const SETTINGS_REMOVE_EMAIL          = 'SETTINGS_REMOVE_EMAIL';
export const SETTINGS_ORDER_EMAILS          = 'SETTINGS_ORDER_EMAILS';
export const SETTINGS_MAKE_EMAIL_PRIMARY    = 'SETTINGS_MAKE_EMAIL_PRIMARY';

export const settingsEmailsRequest = () => {
    return {
        type: SETTINGS_EMAILS_REQUEST,
    };
};

export const settingsAddEmail = (email) => {
    return {
        type: SETTINGS_EMAILS_ADD,
        payload: {
            email: email
        }
    };
};

export const settingsUpdateEmails = (emails) => {
    return {
        type: SETTINGS_UPDATE_EMAILS,
        payload: {
            emails: emails
        }
    };
};

export const settingsUpdateEmail = (email) => {
    return {
        type: SETTINGS_UPDATE_EMAIL,
        payload: {
            email: email
        }
    };
};

export const settingsRemoveEmail = (emailId) => {
    return {
        type: SETTINGS_REMOVE_EMAIL,
        payload: {
            emailId: emailId,
        }
    };
};

export const settingsOrderEmails = () => {
    return {
        type: SETTINGS_ORDER_EMAILS
    };
};

export const settingsMakeEmailPrimary = (emailId) => {
    return {
        type: SETTINGS_MAKE_EMAIL_PRIMARY,
        payload: {
            emailId: emailId,
        }
    }
}
