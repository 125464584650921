import NavbarMobileTemplate from './navbar-mobile.component.html';
import './navbar-mobile.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.navbar
 * @name NavbarMobileController
 */
class NavbarMobileController {
    constructor($mdMedia, $pfUser) {
        this.$mdMedia = $mdMedia;
        this.$pfUser = $pfUser;
        this.showSearch = false;
    }

    $onInit() {
        // need to update currentUser on every digest to
        // see if user is logged out via $pfAuth.logout()
        this.currentUser = this.$pfUser.getUser();
    }
}

NavbarMobileController.$inject = [
    '$mdMedia',
    '$pfUser',
];

/**
 * @ngdoc component
 * @module portfolium.component.navbar
 * @name NavbarMobileComponent
 */
export const NavbarMobileComponent = {
    transclude: {
        'title': '?navbarTitle',
        'auxButton': '?navbarAuxButton'
    },
    controller: NavbarMobileController,
    controllerAs: '$ctrl',
    template: NavbarMobileTemplate,
};
