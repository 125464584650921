import LiveAddressTemplate from './live-address.component.html';

/**
* @ngdoc controller
* @name pfLiveAddress
* @module portfolium.component.LiveAddressController
* @description
* Creates Address form pulling information from GoogleAPI
* @param {Object} [pf-current-address] address to resolve
* @param {Booolean} [hide-card] show or not the card
* @param {Boolean} [get-coordinates] return coordinates insted of
*  address object
* @param {String} [pf-placeholder] text to show as a placeholder
* @param {Boolean} [autofocus] autofocus on init, Defaulted to true
*
*/
class LiveAddressController {
    /**
    * @ngdoc method
    * @name LiveAddressController#constructor
    * @methodOf LiveAddressController
    * @description
    * Set pfLiveAddress and scope and sets the current View so it can be
    */
    constructor($pfLiveAddress, $scope, $element, $state) {
        this.$pfLiveAddress = $pfLiveAddress;
        this.addressChosen = false;
        this.$scope = $scope;
        this.$element = $element;
        this.lat = angular.copy($state.params.lat);
        this.lon = angular.copy($state.params.lon);
        this.addressChosen = false;
        if (this.lat && this.lon) {
            this.addressChosen = true;
        }
    }

    /**
    * @ngdoc method
    * @name LiveAddressController#$onInit
    * @methodOf LiveAddressController
    * @description set the current address to the view
    */
    $onInit() {
        this.$element.attr('tabindex', -1);

        this.placeholder = this.placeholder || 'Start typing an address';
        this.autofocus = this.autofocus || true;
    }

    /**
    * @ngdoc method
    * @name LiveAddressController#$onInit
    * @methodOf LiveAddressController
    * @description set the current address to the view
    * @param {String} street get Suggestions from address service
    */
    getSuggestions(street) {
        return this.$pfLiveAddress.getGoogleSuggestions(street, this.onlyCities);
    }

    /**
    * @ngdoc method
    * @name LiveAddressController#$updateStreet
    * @methodOf LiveAddressController
    * @description update an address for the current user
    * @param {Object} address address to be edited
    */
    updateStreet(address) {
        if (_.isEmpty(address)) {
            this.ngModel.$setViewValue({});
            this.addressChosen = false;
            return;
        }
        this.$pfLiveAddress.updateStreet(address).then((data)=> {
            this._formatAddress(data);
            this.addressChosen = true;
        });
    }

    handleBlur() {
        if (!this.addressChosen) {
            return this.resetLocationSearch();
        }
    }

    handleKeypress(event) {
        /**
         * Catch the form submission and check for an address
         */
        if (event.keyCode === 13 && !this.addressChosen) {
            return this.resetLocationSearch();
        }
    }

    handleChange(event) {
        // just reset if they change the search query, make them re-choose from dropdown
        this.addressChosen = false;
    }

    resetLocationSearch() {
        this.searchText = '';
        this.ngModel.$setViewValue({});
        this.onUpdate();
    }

    /**
    * @ngdoc method
    * @name LiveAddressController#$_formatAddress
    * @methodOf LiveAddressController
    * @description formats the address to make sense.
    * @param {Object} address address recevied from the Address service
    * @return {Void}
    */
    _formatAddress(address) {
        let street_number = '';
        let street = '';
        let city = '';
        let state = '';
        let zipcode = '';
        let country = '';
        let lat = address.geometry.location.lat();
        let lon = address.geometry.location.lng();

        if (_.isEmpty(address) || _.isEmpty(address.address_components)) {
            this.addressChosen = false;
            return null;
        }

        _.forEach(address.address_components,(component)=>{
            _.forEach(component.types,(type, key)=>{
                if (type === 'street_number') {
                    street_number = component.short_name;
                }
                if (type === 'route') {
                    street = component.short_name;
                }

                if (type === 'locality') {
                    city = component.short_name;
                }

                if (type === 'administrative_area_level_1') {
                    state = component.short_name;
                }

                if (type === 'postal_code') {
                    zipcode = component.short_name;
                }

                if (type === 'country') {
                    country = component.short_name;
                }
            });
        });

        this.pfCurrentAddress = {
            street_number,
            street,
            city,
            state,
            zipcode,
            lat,
            lon,
            country,
        }
        this.ngModel.$setViewValue(this.pfCurrentAddress);
        this.onUpdate();
    }
}

LiveAddressController.$inject = [
    '$pfLiveAddress',
    '$scope',
    '$element',
    '$state',
];

export const LiveAddressComponent = {
    bindings: {
        hideCard: '<',
        searchText: '=',
        onUpdate: '&',
        pfClass: '<',
        onlyCities: '<',
        id: '@pfId',
        placeholder: '<pfPlaceholder',
        autofocus: '<pfAutofocus',
    },
    require: {
        ngModel: '^ngModel'
    },
    template: LiveAddressTemplate,
    controller: LiveAddressController,
    controllerAs: '$ctrl'
};
