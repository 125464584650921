import moment from 'moment';
import {updateProfileUser} from '../../profile/js/action/profile-user';

export class ProfileAboutService {
    constructor($http, $q, $pfUser, $pfGameEngine, $pfEventTracker, $pfToast, $pfUserAbout, $ngRedux) {
        this.$http = $http;
        this.$q = $q;
        this.$pfUser = $pfUser;
        this.$pfGameEngine = $pfGameEngine;
        this.$pfEventTracker = $pfEventTracker;
        this.$pfToast = $pfToast;
        this.$pfUserAbout = $pfUserAbout;
        this.$ngRedux = $ngRedux;
    }

    /**
     * @description Create or edit an about record
     * @param   {String}  objectType Type of object to create/edit
     * @param   {String}  objectId   ID of object if editing
     * @param   {Object}  data       Form data
     * @returns {Promise}
     */
    _updateAboutRecord(objectType, objectId, data) {
        let url = `/proxy/users/about/${objectType}`;

        if (objectId) {
            url += `/${objectId}`;
        }

        /**
         * To reduce API response time, we upload images to the CDN on the client side.
         * The API expects a '/ugcs3/path/filename.ext' to cut out the upload step, so we must strip
         * out the CDN path here.
         */
        if (data.file_uri && objectType !== 'badge') {
            data.file_uri = data.file_uri.split('.com/').pop();
        }

        return this.$http({
            method: 'POST',
            url: url,
            data: data,
        })
        .then((response) => {
            // Update game engine
            this.$pfGameEngine.updateResults();

            // update userAbout data
            this.$pfUserAbout.updateMyAbout();

            return response.data;
        });
    }

    /**
     * @description Update the order of a user about record
     * @param  {String}  objectType Type of object
     * @param  {String}  order      New order of object
     * @return {Promise}
     */
    updateOrder(objectType, order) {
        return this.$http({
            method: 'POST',
            url: `/proxy/users/order`,
            data: {
                type: objectType,
                order: order,
            }
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Update user introduction
     * @param  {String}  id           ID of record if editing
     * @param  {String}  introduction Introduction entity
     * @return {Promise}
     */
    updateIntro(id, introduction) {
        return this._updateAboutRecord('introduction', id, {
            intro: introduction.intro,
        });
    }

    /**
     * @description Update or create an experience record
     * @param   {String}  id         ID of record if editing
     * @param   {Object}  experience Experience entity
     * @returns {Promise}
     */
    updateExperience(id, experience) {
        // user selected the current tab
        if (experience.current === '1') {
            experience.end_date = '';
        }

        // user havent selected the current tab
        if (!experience.current) {
            experience.current = false;
        }

        return this._updateAboutRecord('experience', id, {
            company: experience.company,
            position: experience.position,
            description: experience.description,
            start_date: experience.start_date,
            end_date: experience.end_date,
            current: experience.current,
            file_uri: _.get(experience, 'image.source', null),
        });
    }

    /**
     * @description Update or create an education record
     * @param   {String}  id        ID of record if editing
     * @param   {Object}  education Education entity
     * @returns {Promise}
     */
    updateEducation(id, education) {
        return this._updateAboutRecord('education', id, {
            file_uri: _.get(education, 'image.source', null),
            school: education.school,
            major: education.major,
            dual_major: education.dual_major,
            minor: education.minor,
            dual_minor: education.dual_minor,
            concentration: education.concentration,
            start_date: education.start_date,
            degree: education.degree,
            grad_year: education.grad_year,
            gpa: education.gpa,
            description: education.description,
        }).then((education) => {
            // Update the session to make sure primary school stays in sync
            this.$pfUser.updateSession().then((session) => {
                const user = this.$pfUser.getUser();

                user.update(session);
            });

            return education;
        });
    }

    /**
     * @description Update or create a course record
     * @param   {String}  id     ID of record if editing
     * @param   {Object}  course Course entity
     * @returns {Promise}
     */
    updateCourse(id, course) {
        return this._updateAboutRecord('course', id, {
            title: course.title,
            description: course.description,
            date: course.date,
            file_uri: _.get(course, 'image.source', null),
        });
    }

    /**
     * @description Update or create an accomplishment record
     * @param   {String}  id             ID of record if editing
     * @param   {Object}  accomplishment Accomplishment entity
     * @returns {Promise}
     */
    updateAccomplishment(id, accomplishment) {
        return this._updateAboutRecord('accomplishment', id, {
            title: accomplishment.title,
            description: accomplishment.description,
            date: accomplishment.date,
            file_uri: _.get(accomplishment, 'image.source', null),
        });
    }

    /**
     * @description Update or create a club record
     * @param   {String}  id   ID of record if editing
     * @param   {Object}  club Club entity
     * @returns {Promise}
     */
    updateClub(id, club) {
        return this._updateAboutRecord('club', id, {
            title: club.title,
            description: club.description,
            start_date: club.start_date,
            end_date: club.end_date,
            file_uri: _.get(club, 'image.source', null),
        });
    }

    /**
     * @description Update or create a badge record
     * @param   {String}  id    ID of record if editing
     * @param   {Object}  badge Badge entity
     * @returns {Promise}
     */
    updateBadge(id, badge) {
        return this._updateAboutRecord('badge', id, {
            title: badge.title,
            description: badge.description,
            issue_date: badge.issue_date,
            file_uri: _.get(badge, 'image.source', null),
        });
    }

    /**
     * @description Update or create an athletic record
     * @param   {String}  id       ID of record if editing
     * @param   {Object}  athletic Athletic entity
     * @returns {Promise}
     */
    updateAthletic(id, athletic) {
        return this._updateAboutRecord('athletic', id, {
            title: athletic.title,
            description: athletic.description,
            start_date: athletic.start_date,
            end_date: athletic.end_date,
            file_uri: _.get(athletic, 'image.source', null),
        });
    }

    /**
     * @description Update or create a certification record
     * @param   {String}  id            ID of record if editing
     * @param   {Object}  certification Certification entity
     * @returns {Promise}
     */
    updateCertification(id, certification) {
        return this._updateAboutRecord('certification', id, {
            title: certification.title,
            description: certification.description,
            date: certification.date,
            file_uri: _.get(certification, 'image.source', null),
        });
    }

    /**
     * @description Update or create a publication record
     * @param   {String}  id          ID of record if editing
     * @param   {Object}  publication Publication entity
     * @returns {Promise}
     */
    updatePublication(id, publication) {
        return this._updateAboutRecord('publication', id, {
            title: publication.title,
            description: publication.description,
            date: publication.date,
            file_uri: _.get(publication, 'image.source', null),
        });
    }

    /**
     * @description Update or create a volunteer record
     * @param   {String}  id        ID of record if editing
     * @param   {Object}  volunteer Volunteer entity
     * @returns {Promise}
     */
    updateVolunteer(id, volunteer) {
        return this._updateAboutRecord('volunteer', id, {
            title: volunteer.title,
            description: volunteer.description,
            start_date: volunteer.start_date,
            end_date: volunteer.end_date,
            file_uri: _.get(volunteer, 'image.source', null),
        });
    }

    /**
     * @description Update skills count in the user about (currently just redux state)
     * @param   {Number}  skills Number of skills
     * @returns {Void}
     */
    updateSkillCount(skills) {
        // update state
        this.$ngRedux.dispatch(updateProfileUser({
            skills,
        }));
    }

    /**
     * @description Import badge records
     * @param   {Array}   badgeIds    Array of Badge IDs
     * @param   {String}  badgeSource Badge issuer source
     * @returns {Promise}
     */
    importBadges(badgeIds, badgeSource = 'credly') {
        return this.$http({
            method: 'POST',
            url: '/proxy/credly/badges',
            data: {
                badge_ids: badgeIds,
            },
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Remove a profile about section
     * @param  {String}  recordType About object type value
     * @param  {String}  recordId   About object ID
     * @return {Promise}
     */
    removeAboutRecord(recordType, recordId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/users/about/${recordType}/${recordId}`,
        })
        .then((response) => {
            // Update game engine
            this.$pfGameEngine.updateResults();

            // update userAbout data
            this.$pfUserAbout.updateMyAbout();

            return response.data;
        }, (response) => {
            this.$pfToast.error('Something went wrong, please try again.');

            return this.$q.reject(response);
        });
    }

   /**
    * @description Update a users resume
    * @param   {Object} data Form data
    * @returns {Promise}
    */
    updateResume(data) {
        return this.$http({
            method: 'POST',
            url: '/proxy/users/resume',
            data: data,
        })
        .then((response) => {
            // update state
            this.$ngRedux.dispatch(updateProfileUser({
                resume: response.data.resume,
            }));

            // Update game engine
            this.$pfGameEngine.updateResults();

            // Update the user's CI session
            this.$pfUser.updateSession();

            return response.data.resume;
        });
    }

    /**
     * @description Update a users resume privacy
     * @param   {Object} data Form data
     * @returns {Promise}
     */
    updateResumePrivacy(data) {
        return this.$http({
            method: 'POST',
            url: '/proxy/users/resume_privacy',
            data: data,
        })
        .then((response) => {
            return response.data.resume;
        });
    }

    /**
     * @description Delete a users resume
     * @returns {Promise}
     */
    deleteResume() {
        return this.$http({
            method: 'DELETE',
            url: '/proxy/users/resume',
        })
        .then((response) => {
            // update state
            this.$ngRedux.dispatch(updateProfileUser({
                resume: null,
            }));

            // Update game engine
            this.$pfGameEngine.updateResults();

            // Update the user's CI session
            this.$pfUser.updateSession();

            return response.data;
        });
    }
}

ProfileAboutService.$inject = [
    '$http',
    '$q',
    '$pfUser',
    '$pfGameEngine',
    '$pfEventTracker',
    '$pfToast',
    '$pfUserAbout',
    '$ngRedux',
];
