import JobAvatarTemplate from './job-avatar.html';

/**
 * @ngdoc controller
 * @name pfJobAvatar
 * @module portfolium.talentMatch.components.utilities.jobAvatar
 * @description
 * Shows job image if there is one set. Will show company image as default.
 * @param {Object} pf-job Job Object
 * @param {Int} height Image Height so cloudimage can dynamically crop the original source
 * @param {Int} width Image Width so cloudimage can dynamically crop the original source
 *
 **/
class JobAvatarController {

    constructor($pfJobDetails) {
        this.$pfJobDetails = $pfJobDetails;
        this.height = this.height || 80;
        this.height = this.width || 80;
    }

    /**
    * @ngdoc method
    * @name jobAvatar#getAvatar
    * @methodOf jobAvatar
    * @description
    * Return job image if there is one set. Will show company image as default.
    * @return {String} Url of the image to show.
    **/
    get avatar() {
        return this.$pfJobDetails.getAvatar(this.job);
    }
}

JobAvatarController.$inject = [
    '$pfJobDetails'
];

export const JobAvatarComponent = {
    bindings: {
        job: '<pfJob',
        height: '<',
        width: '<',
    },
    template: JobAvatarTemplate,
    controller: JobAvatarController,
    controllerAs: '$ctrl'
};
