import _ from 'lodash';
import {combineReducers} from 'redux';

import {
    NETWORK_DETAILS_REQUEST,
    NETWORK_DETAILS_UPDATE,
    RESET_NETWORK,
} from './network.actions';

import {getPaginationReducer} from '../components/pagination/pagination.reducer';
import {networkMembersActionTypes} from './network-members/network-members.actions';
import {networkEntriesActionTypes} from './network-entries/network-entries.actions';
import {networkJobsActionTypes} from './network-jobs/network-jobs.actions';

const initialState = {
    loading: false,
    network: {},
};

export const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case NETWORK_DETAILS_REQUEST:
            return _.assign({}, state, {
                loading: true
            });
        case NETWORK_DETAILS_UPDATE:
            return _.assign({}, state, {
                loading: false,
                network: action.payload
            });
        case RESET_NETWORK:
            return {
                network: {},
            };
        default:
            return state;
    }
};

export const network = combineReducers({
    meta,
    members: getPaginationReducer({
        actionTypes: networkMembersActionTypes,
        paginationStyle: 'infinite',
        limit: 6
    }),
    entries: getPaginationReducer({
        actionTypes: networkEntriesActionTypes,
        paginationStyle: 'infinite',
        limit: 12
    }),
    jobs: getPaginationReducer({
        actionTypes: networkJobsActionTypes,
        paginationStyle: 'infinite',
        limit: 6
    }),
});
