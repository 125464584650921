import NavbarStandardTemplate from './navbar-standard.component.html';
import './navbar-standard.component.scss';

/**
 * @ngdoc controller
 * @module portfolium.component.navbar
 * @name NavbarUserDropdownController
 */
class NavbarStandardController {
    constructor($mdMedia, $pfUser, $pfEnv, $pfAppSwitcherAuth) {
        this.$mdMedia = $mdMedia;
        this.$pfUser = $pfUser;
        this.$pfEnv = $pfEnv;
        this.$pfAppSwitcherAuth = $pfAppSwitcherAuth;
        this.showSearch = false;
    }

    $onInit() {
        // need to update currentUser on every digest to
        // see if user is logged out via $pfAuth.logout()
        this.currentUser = this.$pfUser.getUser();
    }

    get shouldShowNavAppSwitcher() {
        return this.$pfAppSwitcherAuth.hasAccessToManyApps;
    }
}

NavbarStandardController.$inject = [
    '$mdMedia',
    '$pfUser',
    '$pfEnv',
    '$pfAppSwitcherAuth',
];

/**
 * @ngdoc component
 * @module portfolium.component.navbar
 * @name pfNavbarStandard
 * @param {Boolean} [mobile-content] True if special mobile content will be
 *     included via transclusion. Include mobile content like so:
 *
 *     ```
 *     <pf-navbar-standard mobile-content="true">
 *         <h1>This content is included on mobile instead of the logo!</h1>
 *     </pf-navbar-standard>
 *     ```
 */
export const NavbarStandardComponent = {
    transclude: true,
    bindings: {
        mobileContent: '<',
    },
    controller: NavbarStandardController,
    controllerAs: '$ctrl',
    template: ['$pfSubscription', ($pfSubscription) => {
        if ($pfSubscription.isSubscriber()) {
            // Render employer navbar for subscriber accounts -- mobile content
            // not supported
            return '<pf-navbar-employer></pf-navbar-employer>';
        }

        // Render standard navbar for all other accounts
        return NavbarStandardTemplate;
    }],
};
