import {combineReducers} from 'redux';
import {getPaginationReducer} from 'components/pagination/pagination.reducer';

import {
    JOB_APPLY_CENTER_OPEN,
    JOB_APPLY_CENTER_CLOSE,
    JOB_APPLY_CENTER_USER_UPDATE,
    JOB_APPLY_CENTER_SEARCH_RESULTS_UPDATE,
    JOB_APPLY_CENTER_SEARCH_RESULTS_RESET,
    JOB_APPLY_CENTER_RESET,
    entryActionTypes,
} from './job-apply-center.actions';

const initialState = {
    open: false,
    user: {},
    searchResults: [],
    searchResultsVisible: false,
}

export const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case JOB_APPLY_CENTER_OPEN:
            return _.assign({}, state, {
                open: true
            });
        case JOB_APPLY_CENTER_CLOSE:
            return _.assign({}, state, {
                open: false,
                user: {},
                searchResults: [],
                searchResultsVisible: false,
            });
        case JOB_APPLY_CENTER_USER_UPDATE:
            return _.assign({}, state, {
                user: action.payload.user
            });
        case JOB_APPLY_CENTER_SEARCH_RESULTS_UPDATE:
            return _.assign({}, state, {
                searchResults: action.payload.results,
                searchResultsVisible: true,
            });
        case JOB_APPLY_CENTER_SEARCH_RESULTS_RESET:
            return _.assign({}, state, {
                searchResults: [],
                searchResultsVisible: false
            });
        default:
            return state;
    }
};

export const jobApplyCenter = combineReducers({
    meta,
    entries: getPaginationReducer({
        limit: 15,
        actionTypes: entryActionTypes,
        paginationStyle: 'infinite',
    }),
});
