import ShareTwitter from './share-twitter';
import SharePinterest from './share-pinterest';
import ShareEmbed from './share-embed';

export class SocialShareService {
    constructor(
        $window,
        $pfUser,
        envUrlFilter,
        $pfSocialAuth,
        $pfEventTracker,
    ) {
        this._$pfUser = $pfUser;
        this._$pfSocialAuth = $pfSocialAuth;
        this._$pfEventTracker = $pfEventTracker;
        this._envUrlFilter = envUrlFilter;
        this._twitter = new ShareTwitter($window);
        this._pinterest = new SharePinterest($window);
        this._embed = new ShareEmbed($window);
        this.currentUser = $pfUser.getUser();
    }

    /**
     * Get the entry url based on its slug
     * @param  {String} slug Slug property on entry object
     * @return {String}      Entry URL
     */
    _getUrl(slug) {
        let user = this._$pfUser.getUser();
        let uri = `/entry/${slug}`;

        if (user) {
            uri += `?ref=${user.username}`;
        }

        return this._envUrlFilter(uri);
    }

    /**
     * Get the Widget Embed url based on its ID
     * @param  {String} id   ID property on entry object
     * @return {String}      Entry URL
     */
    _getEmbedUrl(id) {
        return this._envUrlFilter(`/entry/embed/${id}`);
    }

    /**
     * Parse the entry profile object to get a full author name
     * @param  {Object} profile Profile object on entry
     * @return {String}         Author's full name
     */
    _getAuthor(profile = {}) {
        return `${profile.firstname} ${profile.lastname}`;
    }

    /**
     * Parse the entry media collection to get an image url
     * @param  {Array} media Media collection on entry
     * @return {String}      Image url
     */
    _getImage(media) {
        if (!media || !media.length) {
            return null;
        }

        return media[0].filename.url_https;
    }

    /**
     * Determine if the current user is the project owner
     * @return {Boolean}
     */
    _isProjectOwner() {
        if (!_.get(this.data, 'profile')) {
            return false;
        }

        if (this.currentUser && (this.data.profile.id === this.currentUser.id)) {
            return true;
        }

        return false;
    }

    /**
     * Format a raw entry object into a meta object with data necessary for
     * sharing
     * @param  {Object} entry Raw entry object from the API
     @ @return {Object}       Formatted entry object for sharing
     */
    _formatEntry(entry) {
        return _.assign({}, {
            id: entry.id,
            title: entry.title,
            url: this._getUrl(entry.slug),
            embedUrl: this._getEmbedUrl(entry.id),
            author: this._getAuthor(entry.profile),
            image: this._getImage(entry.media)
        });
    }

    /**
     * Open a popup window to share an entry on a social network
     * @param  {String}  provider         Social network to share to
     * @param  {String}  type             What type of share object
     * @param  {Object}  data             Raw data object from the API to share
     * @param  {Boolean} [formatEntry]    Should the entry be formatted by the service?
     * @param  {String}  [trackingSource] The source for the analytics tracking
     * @return {Void}
     */
    shareData(provider = '', data = {}, type = null, formatEntry = true, trackingSource = null) {

        const socialProvider = this['_' + provider];

        if (!socialProvider) {
            return;
        }

        // track the project share event
        if (!_.isNull(trackingSource) && type === 'entry') {
            this._$pfEventTracker.trackServer('Project Shared', {
                source: trackingSource,
                socialNetworkName: provider,
                projectID: data.id,
                isProjectOwner: this._isProjectOwner(),
            });
        }

        if (formatEntry) {
            return socialProvider.shareData(this._formatEntry(data), type);
        }

        return socialProvider.shareData(data, type);
    }
}

SocialShareService.$inject = [
    '$window',
    '$pfUser',
    'envUrlFilter',
    '$pfSocialAuth',
    '$pfEventTracker',
];
