import angular from 'angular';

import StandardTemplate from './template/card/standard.html'
import FeedTemplate from './template/card/feed.html'
import './index.scss';

import {EntryCardActionMenuComponent} from './entry-card-action-menu/entry-card-action-menu.component';
import {EntryCardFullWidthComponent} from './entry-card-full-width/entry-card-full-width.component';
import {EntryCardMobileDiscoverComponent} from './entry-card-mobile-discover/entry-card-mobile-discover.component';
import {EntryCardRibbonComponent} from './entry-card-ribbon/entry-card-ribbon.component';
import {EntryCardStandardComponent} from './entry-card-standard/entry-card-standard.component';
import {EntryCardBlankComponent} from './entry-card-blank/entry-card-blank.component';
import {EntryCardPreviewComponent} from './entry-card-preview/entry-card-preview.component';
import {EntryOptionsComponent} from './entry-options/entry-options.component';
import {EntryVoteButtonComponent} from './entry-vote-button/entry-vote-button.component';
import {ListDraftCardComponent} from './list-draft-card/list-draft-card.component';

import EntryCardService from './js/service/entry-card-service'
import EntryCardActionsService from './js/service/entry-card-actions.service'
import entryCardsDirective from './js/directive/entry-cards-directive'
import entryCardDirective from './js/directive/entry-card-directive'
import blankEntryCardDirective from './js/directive/blank-entry-card-directive'
import inlineEditDirective from './js/directive/inline-edit-directive'
import httpPrefixDirective from './js/directive/http-prefix-directive'
import listEntryCardDirective from './js/directive/list-entry-card-directive'
import entryCardLikeDirective from './js/directive/entry-card-like-directive'
import entryCardTeamDirective from './js/directive/entry-card-team-directive'

const MODULE_NAME = 'portfolium.component.entryCard';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfEntryCardActionMenu', EntryCardActionMenuComponent)
        .component('pfEntryCardFullWidth', EntryCardFullWidthComponent)
        .component('pfEntryCardMobileDiscover', EntryCardMobileDiscoverComponent)
        .component('pfEntryCardRibbon', EntryCardRibbonComponent)
        .component('pfEntryCardStandard', EntryCardStandardComponent)
        .component('pfEntryCardBlank', EntryCardBlankComponent)
        .component('pfEntryCardPreview', EntryCardPreviewComponent)
        .component('pfEntryOptions', EntryOptionsComponent)
        .component('pfEntryVoteButton', EntryVoteButtonComponent)
        .component('pfListDraftCard', ListDraftCardComponent)
        // Services
        .service('$pfEntryCard', EntryCardService)
        .service('$pfEntryCardActions', EntryCardActionsService)
        // Directives
        .directive('pfEntryCards', entryCardsDirective)
        .directive('pfEntryCard', entryCardDirective)
        .directive('pfBlankEntryCard', blankEntryCardDirective)
        .directive('pfInlineEdit', inlineEditDirective)
        .directive('pfHttpPrefix', httpPrefixDirective)
        .directive('pfListEntryCard', listEntryCardDirective)
        // Components
        .directive('pfEntryCardLike', entryCardLikeDirective)
        .directive('pfEntryCardTeam', entryCardTeamDirective)
        // Templates
        .run(['$templateCache', ($templateCache) => {
            $templateCache.put('template/components/entry-card/template/card/feed.html', FeedTemplate);
            $templateCache.put('template/components/entry-card/template/card/standard.html', StandardTemplate);
        }]);
}());

export default MODULE_NAME;
