import NetworkResultTemplate from './network-result.component.html';

/**
 * @ngdoc component
 * @name pfNetworkResult
 * @module portfolium.component.pfNetworkResult
 * @description controller for pfNetworkResult
 * @param {Object} network  information of the network to display
 */
class NetworkResultController {
    /**
     * @ngdoc method
     * @name NetworkResultController#$constructor
     * @methodOf NetworkResultController
     * @description get the current status of the avatar
     */
    constructor(PF_NETWORK_TYPES) {
        this.PF_NETWORK_TYPES = PF_NETWORK_TYPES;
    }

    /**
     * @ngdoc method
     * @name NetworkResultController#networkType
     * @methodOf NetworkResultController
     * @description maps the Network type sent from the API
     *   to the corresponding string
     * @return {String}
     */
    get networkType() {
        return _.find(this.PF_NETWORK_TYPES, ['id', this.network.type]).title;
    }
}

NetworkResultController.$inject = ['PF_NETWORK_TYPES'];

export const NetworkResultComponent = {
    bindings: {
        network: '<',
    },
    template: NetworkResultTemplate,
    controller: NetworkResultController,
    controllerAs: '$ctrl',
};
