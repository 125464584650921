import angular from 'angular';

import {SitewideSearchComponent} from './sitewide-search.component';
import {SitewideSearchMenuComponent} from './sitewide-search-menu/sitewide-search-menu.component';
import {
    SITE_WIDE_SEARCH_MENU,
    SITE_WIDE_SEARCH_MENU_ID,
    SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX,
} from './sitewide-search-menu/sitewide-search-menu.constant';
import {SitewideSearchMobileComponent} from './sitewide-search-mobile/sitewide-search-mobile.component';
import {SiteWideSearchPanelService} from './sitewide-search-panel.service';
import {toggleSitewideSearchMobilePanelDirective} from './sitewide-search-mobile/toggle-sitewide-search-mobile-panel.directive';
import {SitewideSearchMobilePanelService} from './sitewide-search-mobile/sitewide-search-mobile-panel.service';

const MODULE_NAME = 'portfolium.component.sitewideSearch';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfSitewideSearch', SitewideSearchComponent)
        .component('pfSitewideSearchMenu', SitewideSearchMenuComponent)
        .component('pfSitewideSearchMobile', SitewideSearchMobileComponent)
        // Directives
        .directive('pfToggleSitewideSearchMobilePanel', toggleSitewideSearchMobilePanelDirective)
        // Services
        .service('$pfSitewideSearchPanel', SiteWideSearchPanelService)
        .service('$pfSitewideSearchMobilePanel', SitewideSearchMobilePanelService)
        // Constants
        .constant('PF_SITEWIDE_SEARCH_MENU', SITE_WIDE_SEARCH_MENU)
        .constant('PF_SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX', SITE_WIDE_SEARCH_SELECTED_ITEM_PREFIX)
        .constant('PF_SITE_WIDE_SEARCH_MENU_ID', SITE_WIDE_SEARCH_MENU_ID);
}());

export default MODULE_NAME;
