import ContentReportFormTemplate from './content-report-form.component.html';
import './content-report-form.component.scss';

export const ContentReportFormComponent = {
    bindings: {
        loading: '<',
        header: '@',
        selectedReason: '<',
        reasons: '<',
        user: '<',
        onCancel: '&',
        onContentReport: '&',
    },
    template: ContentReportFormTemplate,
};
