class EmbeddedUrlsService
{
    constructor($sce) {
        this.$sce = $sce;
    }

    /**
     * validate the known embedded services in an entry
     * @param  {String}  url The url to validate
     * @param  {String}  provider The provider name that will be mapped to a validation function
     * @return {Object<sce>}
     */
    validate(url, provider) {
        let validUrl = null;
        const func = `_${provider}`;
        if (!!url && typeof this[func] === 'function') {
            validUrl = this[func](url);
        }
        return validUrl;
    }

    /**
     * validate Prezi URL
     * @param  {String}  url The URL
     * @return {Object}
     */
    _prezi(url) {
        if (!url) { return null; }
        const options = '?bgcolor=ffffff&amp;lock_to_path=1&amp;autoplay=0&amp;autohide_ctrls=0';
        const validRe = /^https?:\/\/prezi.com\//i;
        if (!validRe.test(url)) { return null; }
        if (url.indexOf('/embed/') === -1) {
            url = url.replace(/^https?:\/\/prezi.com\/([a-z0-9\-_]*)\/(.*)$/i, 'https://prezi.com/embed/$1/');
        }
        url += options;
        return this.$sce.trustAsResourceUrl(url);
    }

    /**
     * validate Sway URL
     * @param  {String}  url The URL
     * @return {Object}
     */
    _sway(url) {
        if (!url) { return null; }
        const validRe = /^https?:\/\/sway.com\//i;
        if (!validRe.test(url)) { return null; }
        if (url.indexOf('/embed/') === -1) {
            url = url.replace(/^https?:\/\/sway.com\/([a-z0-9\-_]*)$/i, 'https://sway.com/s/$1/embed');
        }
        return this.$sce.trustAsResourceUrl(url);
    }

    /**
     * validate Sketchfab URL
     * @param  {String}  url The URL
     * @return {Object}
     */
    _sketchfab(url) {
        if (!url) { return null; }
        const validRe = /^https?:\/\/sketchfab.com\//i;
        if (!validRe.test(url)) { return null; }
        if (url.indexOf('/embed') === -1) {
            url = url.replace(/^https?:\/\/sketchfab.com\/models\/([a-z0-9\-_]*)$/i, 'https://sketchfab.com/models/$1/embed');
        }
        return this.$sce.trustAsResourceUrl(url);
    }

}

EmbeddedUrlsService.$inject = ['$sce'];

export default EmbeddedUrlsService;
