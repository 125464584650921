import angular from 'angular';

import { RecaptchaComponent } from './recaptcha.component.js';

const MODULE_NAME = 'portfolium.component.recaptchaModule';

(function() {
    angular.module(MODULE_NAME, [])
        .component('pfRecaptcha', RecaptchaComponent);
})();

export default MODULE_NAME;
