import PmailCenterPanelTemplate from './pmail-center-panel.html';
import './pmail-center-panel.scss';

import {
    PMAIL_CENTER_PANEL_STEPS,
    openPmailCenterPanel,
    setPmailRecipientsAndRequiredPmail,
    setPmailStats,
} from './pmail-center.action';

class PmailCenterPanelController {
    constructor(
        $ngRedux,
        $pfSubscriptionActions,
        $pfHelpChat,
    ) {
        this._$ngRedux = $ngRedux;
        this._$pfSubscriptionActions = $pfSubscriptionActions;
        this._$pfHelpChat = $pfHelpChat;

        // Subscribe to state updates
        this._unsubscribe = this._$ngRedux.connect(this._mapStateToThis)(this);
    }

    $onInit() {
        this.steps = PMAIL_CENTER_PANEL_STEPS;

        // get valid recipients
        let validRecipients = this._$pfSubscriptionActions.getValidRecipients(this.recipients);
        // get blocked recipients
        let blockedRecipients = this._$pfSubscriptionActions.getBlockedRecipients(this.recipients);
        // get required pmail
        let requiredPmail = this._$pfSubscriptionActions.getRequiredPmail(validRecipients);
        // get the active step
        let activeStep = this.getActiveStep(validRecipients, blockedRecipients, requiredPmail);

        // Dispatch subscribers current pmail stats
        this._$ngRedux.dispatch(setPmailStats({
            pmail: this.initialPmail,
        }));

        // Dispatch all recipients and required pmail to message
        this._$ngRedux.dispatch(setPmailRecipientsAndRequiredPmail({
            validRecipients: validRecipients,
            blockedRecipients: blockedRecipients,
            requiredPmail: requiredPmail,
        }));

        // hide help chat button when pmail panel opens
        this._$pfHelpChat.hideButton();

        // Dispatch active step
        this._$ngRedux.dispatch(openPmailCenterPanel(activeStep));
    }

    $onDestroy() {
        // show help chat button when pmail panel closes
        this._$pfHelpChat.showButton();

        // Unsubscribe when panel is closed
        this._unsubscribe();
    }

    _mapStateToThis(state) {
        return {
            panel: state.pmailCenter,
            pmail: state.pmailCenter.pmail,
            requiredPmail: state.pmailCenter.requiredPmail,
            validRecipients: state.pmailCenter.validRecipients,
            blockedRecipients: state.pmailCenter.blockedRecipients,
        };
    }

    /**
     * @description Set current step based off of pmail remaining and blocked recipients
     * @param {Array<Object>} validRecipients Collection of profile objects who the subscriber can message
     * @param {Array<Object>} blockedRecipients Collection of profile objects who the subscriber is blocked from messaging
     * @param {Integer} requiredPmail Number of pmail required to send the message
     * @return {String} The active step that should be shown
     */
    getActiveStep(validRecipients, blockedRecipients, requiredPmail) {
        let remainingPmail = parseInt(this.initialPmail.remaining, 10);
        let activeStep = null;

        // Check for pmail limit or blocked recipients
        if (
            requiredPmail > remainingPmail ||
            !!blockedRecipients.length ||
            (remainingPmail < 50 && validRecipients.length > 1)
        ) {
            activeStep = this.steps.VALIDATION;
        } else {
            activeStep = this.steps.COMPOSER;
        }

        return activeStep;
    }

    /**
     * @description Close the panel
     * @return {Promise} Resolved when panel is closed
     */
    close() {
        return this.mdPanelRef.close();
    }
}

PmailCenterPanelController.$inject = [
    '$ngRedux',
    '$pfSubscriptionActions',
    '$pfHelpChat',
];

/**
 * @ngdoc PmailCenterPanel
 * @name Pmail Center Panel
 * @description Displays pmail validation and composer views
 */
export const PmailCenterPanelComponent = {
    bindings: {
        'mdPanelRef': '<mdPanelRef',
        'recipients': '<',
        'initialPmail': '<',
        'pmailType': '<',
        'jobs': '<',
        'company': '<',
        'onMessageSent': '&',
    },
    template: PmailCenterPanelTemplate,
    controller: PmailCenterPanelController,
};
