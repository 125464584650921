import _ from 'lodash';

import {TOGGLE_COMPETENCY_LIST_ITEM} from '../action/competency-list';

export const competencyList = (state = {}, action = {}) => {
    switch (action.type) {
        case TOGGLE_COMPETENCY_LIST_ITEM:
            const isToggled = state[action.payload.categoryId] === action.payload.competencyId;

            return _.assign({}, state, {
                [action.payload.categoryId]: (isToggled) ? null : action.payload.competencyId,
            });
        default:
            return state;
    }
};
