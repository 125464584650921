import angular from 'angular';
import contestCardDirective from './js/contest-card-directive';

import FeedTemplate from './template/card/feed.html'
import StandardTemplate from './template/card/standard.html'
import './index.scss';

(function() {
    let app = angular.module('portfolium.contestCard', []);

    // Directives
    app.directive('pfContestCard', contestCardDirective);

    // Templates
    app.run([
        '$templateCache',
        $templateCache => {
            $templateCache.put(
                'template/components/contest-card/template/card/standard.html',
                StandardTemplate,
            );
            $templateCache.put(
                'template/components/contest-card/template/card/feed.html',
                FeedTemplate,
            );
        },
    ]);
})();

export default 'portfolium.contestCard';
