class SpotlightPanelController {
    constructor(
        mdPanelRef,
    ) {
        this.mdPanelRef = mdPanelRef;
        // defaults; from the config locals in the $pfSpotlightPanel service
        this.submitText = this.submitText || 'Do it!';
        this.cancelText = this.cancelText || 'Cancel';
        this.onSubmit = this.onSubmit || function () {};
        this.onCancel = this.onCancel || function () {};
    }

    /**
     * Open the main navigation menu
     * @return {Promise}
     */
    submit() {
        this.mdPanelRef.hide()
            .then(() => {
                this.mdPanelRef.destroy();
                this.onSubmit();
            });
    }

    /**
     * Close the main navigation menu
     * @return {Promise}
     */
    cancel() {
        this.mdPanelRef.hide()
            .then(() => {
                this.mdPanelRef.destroy();
                this.onCancel();
            });
    }
}

SpotlightPanelController.$inject = [
    'mdPanelRef',
];

export default SpotlightPanelController;
