export const registerPanels = ($pfProfileAboutPanelProvider) => {
    $pfProfileAboutPanelProvider
        .panel('accomplishment', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateAccomplishment(data.id, data);
            }],
            locals: {
                title: 'Accomplishment',
                labelField: 'title',
            },
        })
        .panel('athletic', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateAthletic(data.id, data);
            }],
            locals: {
                title: 'Athletic',
                labelField: 'title',
            },
        })
        .panel('certification', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateCertification(data.id, data);
            }],
            locals: {
                title: 'Certification',
                labelField: 'title',
            },
        })
        .panel('club', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateClub(data.id, data);
            }],
            locals: {
                title: 'Club',
                labelField: 'title',
            },
        })
        .panel('course', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateCourse(data.id, data);
            }],
            locals: {
                title: 'Course',
                labelField: 'title',
            },
        })
        .panel('education', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateEducation(data.id, data);
            }],
            locals: {
                title: 'Education',
                labelField: 'school',
            },
        })
        .panel('experience', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateExperience(data.id, data);
            }],
            locals: {
                title: 'Work Experience',
                labelField: 'company',
            },
        })
        .panel('introduction', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateIntro(data.id, data);
            }],
            locals: {
                title: 'Introduction',
            },
        })
        .panel('publication', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updatePublication(data.id, data);
            }],
            locals: {
                title: 'Publication',
                labelField: 'title',
            },
        })
        .panel('volunteer', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateVolunteer(data.id, data);
            }],
            locals: {
                title: 'Volunteer Work',
                labelField: 'title',
            },
        })
        .panel('badge', {
            onSubmit: ['$pfProfileAbout', 'data', ($pfProfileAbout, data) => {
                return $pfProfileAbout.updateBadge(data.id, data);
            }],
            locals: {
                title: 'Badge',
                labelField: 'title',
            },
        });
};

registerPanels.$inject = ['$pfProfileAboutPanelProvider'];
