import angular from 'angular';

import {urlValidationDirective} from './url-validation.directive';
import {usernameValidationDirective} from './username-validation.directive';

const MODULE_NAME = 'portfolium.component.formValidation';

(function () {
    angular.module(MODULE_NAME, [])
        // Directive
        .directive('pfUrlValidate', urlValidationDirective)
        .directive('pfUsernameValidate', usernameValidationDirective);
}());

export default MODULE_NAME;
