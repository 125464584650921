import angular from 'angular';

import { HelpChatService } from './help-chat.service';
import helpChatLauncherDirective from './help-chat-launcher.directive';

import './help-chat.scss';

const moduleName = 'portfolium.component.help-chat';

(function() {
    angular
        .module(moduleName, [])
        .directive('pfHelpChatLauncher', helpChatLauncherDirective)
        .service('$pfHelpChat', HelpChatService);
})();

export default moduleName;
