export const GDPR_COOKIE_DEFAULT_CHOICES = {
    functional_cookies: true,
    analytical_cookies: true,
};

export const GDPR_MESSAGES = {
    snackbar: "Our websites use cookies. By continuing, you agree to their use.",
    modal: [
        {
            title: "How we use cookies:",
            body: "While we use cookies to deliver, measure, and improve the Platform in various ways, the cookies we use generally fall into one of the following categories:",
        },
        {
            title: "Required Cookies",
            body: "Required cookies enable core functionality. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.",
        },
        {
            title: "Functional Cookies",
            body: "These cookies are used to provide you with a more personalized experience on our website and to remember choices you make when you use our website.\n\nFor example, we may use functionality cookies to remember your language preferences or remember your login details.",
        },
        {
            title: "Analytical Cookies",
            body: "Analytical cookies help us to improve our website by collecting and reporting information on its usage.\n\nFor example, these cookies may track things such as how long you spend on the website or the pages you visit which helps us to understand how we can improve our website site for you.",
        },
        {
            title: "More Information",
            body: "For more information on how to enable or disable cookie preferences, refer to our <a href='https://community.canvaslms.com/docs/DOC-17831' target='_blank'>help guide.</a>",
        },
    ],
};
