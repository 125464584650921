import {AVATAR_STATUSES} from './talent-match-avatar.status';
import TalentMatchAvatarTemplate from './talent-match-avatar.component.html';

/**
 * @ngdoc component
 * @name pfTalentMatchAvatar
 * @module portfolium.component.pfTalentMatchAvatar
 * @description controller for pfTalentMatchAvatar
 * @param {String} pf-avatar profile to show the avatar
 * @param {Int} pf-width width of the avatar
 * @param {Object} pf-profile profile to show the avatar
 * @param {String} pf-profile.firstname profile first name
 * @param {String} pf-profile.lastname profile last name
 * @param {Object} pf-profile.subscriber subscriber properties
 * @param {Boolean} pf-profile.subscriber.following is the subscriber a
 *     follower
 * @param {Boolean} pf-profile.subscriber.network_access subscriber
 *     network access
 */
class TalentMatchAvatarController {
    /**
    * @ngdoc method
    * @name TalentMatchAvatarController#$constructor
    * @methodOf TalentMatchAvatarController
    * @description get the current status of the avatar
    */
    constructor(){
        this._status = this.avatarStatus();

        // set checkbox style
        this.checkboxStyle = {
            width: `${this.pfWidth/3.6}px`,
            height: `${this.pfWidth/3.6}px`,
        };
    }

    /**
    * @ngdoc method
    * @name TalentMatchAvatarController#$avatarStatus
    * @methodOf TalentMatchAvatarController
    * @description gets the status of the current TM User avatar
    * @return {AVATAR_STATUSES}
    */
    avatarStatus() {
        // sanity check that confirm that the profile is sent and it has
        // a subscriber property
        if (this.pfProfile && this.pfProfile.subscriber) {
            // Profiles that follows the current user are allowed to
            // receive messages ** GREEN DOT **
            if (this.pfProfile.subscriber.following === true) {
                return AVATAR_STATUSES.free;
            }
            // Profiles that follows have any restricted
            // network access can not be messaged ** RED CROSS **
            if (this.pfProfile.subscriber.network_access === false) {
                return AVATAR_STATUSES.denied;
            }
        }
        // users who are not followers or dont have network restrictions are
        // not allowed to received messages for free. ** DEFAULT AVATAR **
        return AVATAR_STATUSES.default;
    }

    /**
    * @ngdoc method
    * @name TalentMatchAvatarController#$messageForFree
    * @methodOf TalentMatchAvatarController
    * @description checks if the current TalentMatch user is allow to received
    *   messages for free
    * @return {Boolean}
    */
    get messageForFree() {
        return this._status === AVATAR_STATUSES.free;
    }

    /**
    * @ngdoc method
    * @name TalentMatchAvatarController#$messageForFree
    * @methodOf TalentMatchAvatarController
    * @description checks if the current TalentMatch user is allow to received
    *   messages
    * @return {Boolean}
    */
    get messageDenied() {
        return this._status === AVATAR_STATUSES.denied;
    }

    /**
    * @ngdoc method
    * @name TalentMatchAvatarController#$isDefault
    * @methodOf TalentMatchAvatarController
    * @description check if the status is the default to send messages
    * @return {Boolean}
    */
    get isDefault() {
        return this._status === AVATAR_STATUSES.default;
    }
}

export const TalentMatchAvatarComponent = {
    bindings: {
        pfProfile: '<',
        pfAvatar: '<',
        pfWidth: '<'
    },
    template: TalentMatchAvatarTemplate,
    controller: TalentMatchAvatarController,
    controllerAs: '$ctrl'
};
