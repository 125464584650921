import angular from 'angular';

class EntryCardService {
    constructor($http, $q, $window, $state, $mdDialog, $pfMediaTypes) {
        this.$http = $http;
        this.$q = $q;
        this.$window = $window;
        this.$state = $state;
        this.$mdDialog = $mdDialog;
        this.$pfMediaTypes = $pfMediaTypes;
    }

    /**
     * checks if the mimetype belongs to an image
     * @param  {String}  mimetype mimetype name
     * @return {Boolean}
     */
    isImage(mimetype) {
        return this.$pfMediaTypes.isMimetypeImage(mimetype);
    }

    /**
     * Like an entry
     * @param  {String}  entryId Entry ID
     * @return {Promise}
     */
    likeEntry(entryId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/entries/like/${entryId}`,
        }).then(response => {
            return response.data;
        });
    }

    /**
     * Open the entry detail dialog without transitioning to parallel state
     * TODO: Refactor when template is no longer generated by PHP
     * @param  {String}  slug  Entry slug
     * @param  {String}  token Auth token to view private entry
     * @return {Promise}
     */
    openEntryDialog(slug = '', token = null) {
        // Add custom class to dialog backdrop for styling
        angular.element('body').addClass('pf-darkened-backdrop');

        // First fetch the PHP template via ajax
        return this.$http({
            method: 'GET',
            url: `/entry/${slug}`,
            params: {
                preventCache: new Date().getTime(),
                angular_modal: true,
                ad: token,
            },
        }).then(response => {
            const template = response.data;

            // Open the ngMaterial dialog
            return this.$mdDialog.show({
                parent: angular.element(document.body),
                controller: [
                    '$transitions',
                    '$mdDialog',
                    function($transitions, $mdDialog) {
                        $transitions.onStart({}, trans => {
                            const toState = trans.to();
                            if (!toState.$$state().includes.entryModal) {
                                $mdDialog.hide();
                            }
                        });

                        this.close = function() {
                            $mdDialog.cancel();
                        };
                    },
                ],
                controllerAs: 'modalCtrl',
                template: `
                    <md-dialog class="pf-dialog--entry-details">
                        <md-dialog-content>
                            ${template}
                        </md-dialog-content>
                    </md-dialog>
                `,
                clickOutsideToClose: true,
                onRemoving: function() {
                    // Remove custom backdrop class
                    angular.element('body').removeClass('pf-darkened-backdrop');
                },
            });
        });
    }

    /**
     * Open the entry detail modal. This transitions to a parallel state and
     * pushes a record onto the window history stack
     * @param  {Object}  entry Entry object
     * @return {Promise}
     */
    openEntryDetailModal(entry) {
        return this.$state
            .go('entryDetailsPage', {
                slug: entry.slug,
                token: entry.token,
            })
            .catch(() => {
                // catch "Transition superseded" error
            });
    }

    /**
     * Redirect to the entry detail page
     * @param  {String} entryId Entry ID
     */
    redirectToEntryDetailPage(entryId) {
        if (!entryId) {
            return;
        }

        this.$window.location.assign(`/e/${entryId}`);
    }

    /**
     * Delete an entry
     * @param  {String}  entryId Entry ID
     * @return {Promise}
     */
    deleteEntry(entryId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/entries/entry/${entryId}`,
        }).then(response => {
            return response.data;
        });
    }
}

EntryCardService.$inject = [
    '$http',
    '$q',
    '$window',
    '$state',
    '$mdDialog',
    '$pfMediaTypes',
];

export default EntryCardService;
