import angular from 'angular';

import MessagingService from './js/service/messaging-service';
import PMailService from './js/service/pmail-service';

(function() {
    let app = angular.module('portfolium.component.messaging', []);

    // Service
    app.service('$pfMessaging', MessagingService);
    app.service('$pfMail', PMailService);
})();

export default 'portfolium.component.messaging';
