import moment from 'moment';

const momentDiffFilter = () => {
    /**
     * Takes two dates and returns the difference in the passed in unit of measurment
     * @param {String} firstDate Datetime value
     * @param {String} secondDate Datetime value
     * @param {String|null} unit Unit of measurement (years, months, weeks, days, hours, minutes, and seconds)
     * @return {String}     Date difference
     */
    return (firstDate, secondDate, unit = null) => {
        const firstMomentDate = moment(firstDate);
        const secondMomentDate = moment(secondDate);
        return firstMomentDate.diff(secondMomentDate, unit);
    };
}

const momentDateFilter = () => {
    /**
     * Format a date string
     * @param  {String} val    Date value in format YYYY-MM-DD
     * @param  {String} format Moment format tokens
     * @return {String}        Formatted date
     */
    return (val, format) => {
        return moment(val, ['YYYY-MM-DD', 'YYYY-MM', 'YYYY', 'MMMM D, YYYY']).format(format);
    };
}

const momentDatetimeFilter = ($pfDate) => {
    /**
     * Format a datetime string (converts UTC to local time)
     * @param  {String} val    Datetime value
     * @param  {String} format Moment format tokens
     * @return {String}        Formatted date and time
     */
    return (val, format) => {
        // sanity check if the val is NOT string
        if (!_.isString(val)) {
            // short circuit out
            return undefined;
        }

        // return local time in set format
        return $pfDate.utcToLocal(val, format);
    };
}

momentDatetimeFilter.$inject = ['$pfDate'];

const momentUtcDateLocalTimeFilter = ($pfDate) => {
    /**
     * Get a moment date time object in your local timezone
     * @param {string} dateTime utc formated date
     * @return {moment} moment time object
     */
    return (dateTime) => {
        // set the time to the local time zone
        return $pfDate.utcToLocal(dateTime);
    };
}

momentUtcDateLocalTimeFilter.$inject = ['$pfDate'];

const momentDateNewFilter = () => {
    /**
     * Format a datetime string (converts UTC to local time) from a js Date Obj
     * @param  {String} val    Datetime value
     * @param  {String} format Moment format tokens
     * @return {String}        Formatted date and time
     */
    return (val, format) => {
        let newDate = new Date(val);

        return $pfDate.utcToLocal(newDate, format);
    };
}

const momentTimeAgoFilter = ($pfDate) => {
    /**
     * Format a datetime string in human readable "time ago" format
     * @param  {String} val Datetime value
     * @return {String}     Formatted time ago
     */
    return (val) => {
        // sanity check if the val is NOT string
        if (!_.isString(val)) {
            // short circuit out
            return undefined;
        }

        // set the time to the local time zone
        const localTime = $pfDate.utcToLocal(val);
        // return local time in set format
        return localTime.fromNow();
    };
}

momentTimeAgoFilter.$inject = ['$pfDate'];

const momentUnixTimeFilter = () => {
    /**
    * Format a unix timestamp from seconds (converts unix time to human readable time)
    * @param  {Integer} val   Unix timestamp
    * @param  {String} format Moment format tokens
    * @return {String}        Formatted date and time
    */
    return (val, format) => {
        return moment.unix(val).format(format);
    };
}

export {
    momentDiffFilter,
    momentDateFilter,
    momentDatetimeFilter,
    momentDateNewFilter,
    momentTimeAgoFilter,
    momentUnixTimeFilter,
    momentUtcDateLocalTimeFilter
};
