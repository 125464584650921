function FormWizardNextBtnDirective() {
    return {
        require: '^pfFormWizardGroup',
        restrict: 'EA',
        scope: {},
        replace: true,
        transclude: true,
        link: function (scope, elem, attrs, formWizardGroupCtrl) {
            // Disable continue button by default
            scope.disabled = function () {
                return !formWizardGroupCtrl.isValid;
            };

            scope.nextGroup = function () {
                formWizardGroupCtrl.nextGroup()
                    .then(function () {
                        // Hide button if next group is shown
                        scope.hide = true;
                    });
            };
        },
        template: '<button ng-click="nextGroup()" ng-hide="hide" ng-disabled="disabled()"><div ng-transclude></div></button>'
    };
}

export default FormWizardNextBtnDirective;