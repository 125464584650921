import legacyModal from 'components/modal';
import {toggleNotificationCenterPanelTab} from '../action/notification-center-panel';
import NotificationCenterPanelMessagesTemplate from '../../template/notification-center-panel-messages.html';
import {
    addMessages,
} from '../action/notification-center-messages';

class NotificationCenterPanelMessagesController {
    constructor(
        $q,
        $scope,
        $ngRedux,
        $window,
        $pfNotificationCenter
    ) {
        this._$q = $q;
        this._$ngRedux = $ngRedux;
        this._$window = $window;
        this._$pfNotificationCenter = $pfNotificationCenter;

        // Subscribe to state updates
        const unsubscribe = $ngRedux.connect(this._mapStateToThis)(this);

        // Unsubscribe when panel is closed
        $scope.$on('$destroy', unsubscribe);
    }

    $onInit() {
        // Prevents infinite scroll from making multiple API calls, when set to true
        this.isLoading = false;

        // Set message length limits for filter
        this.lengthLimits = {
            subject: this.panelCtrl.isMobile ? 45 : 95,
            body: this.panelCtrl.isMobile ? 70 : 90,
        };
    }

    _mapStateToThis(state) {
        return {
            panel: state.notificationCenter.notificationCenterPanel,
            messageStore: state.notificationCenter.notificationCenterMessages,
        };
    }

    /**
     * @description Load a new set of messages
     * @returns {Promise<Array>}
     */
    loadMessages() {
        if (this.messageStore.finished) {
            return this._$q.reject();
        }

        // Set loading flag
        this.isLoading = true;

        return this._$pfNotificationCenter.getMessages().then((messages)=>{
            // Set messages
            return this.appendMessages(messages).then(()=>{
                // Reset loading flag
                this.isLoading = false;
            });
        }, () => {
            // API call failed
            this._$pfToast.error('Unable to retrieve messages, please try again later');
        });
    }

    /**
     * @description Add messages to the collection
     * @param   {Array}   messages Messages collection
     * @returns {Promise}          Promise resolved with updated Messages collection
     */
    appendMessages(messages) {
        this._$ngRedux.dispatch(addMessages(messages));

        return this._$q.resolve(messages);
    }

    /**
     * View the selected message
     * @param  {String}  threadId   Thread ID
     */
    viewMessage(threadId) {
        const currentMessageCount = this.messageStore.unread;
        const newMessageCount = currentMessageCount > 0 ? currentMessageCount - 1 : 0;

        // Set new unread values and update message count
        const unreadCount = {
            notifications: this.panelCtrl.notificationStore.unread,
            messages: newMessageCount
        };

        // Update counts
        this._$pfNotificationCenter.storeNotificationCenterCount(unreadCount);

        // Route to message
        if (!!this._$state.current.name) {
            return this._.$state.go('thread', {id: threadId});
        }
        return this._$window.location.assign(`/messages/${threadId}`);
    }

    /**
     * Send user to messages page
     */
    viewAllMessages() {
        // Route to message
        if (!!this._$state.current.name) {
            return this._.$state.go('messages');
        }
        return this._$window.location.assign(`/messages`);
    }

    /**
     * Show the send message dialog or if on mobile, sends user to new message page
     */
    showMessageDialog() {
        if (this.panelCtrl.isMobile) {
            this._$window.location.assign(`/message/add`);
        } else {
            legacyModal.openModal(null, `/message/add`);
        }

        this.close();
    }

    /**
     * Close the panel without saving any changes
     * @return {Promise}
     */
    close() {
        return this.panelCtrl.close();
    }

    /**
     * Change panel tabs
     * @param  {String}             tabName Tab to transition to
     * @return {void|Promise<void>}
     */
    activateTab(tabName) {
        if (tabName === this.panel.activeTab) {
            // Already on the tab
            return;
        }
        // check if you're on an angular app
        if (!!this._$state.current.name) {
            // if it is already showing a thread, do not move
            if (!this._$state.is('thread')) {
                // go to messages tab
                this._$state.go('messages');
            }
            // close panel
            this.close();
            return;
        }
        // close panel
        this.close();
        // if not on angular page use window location replace
        return this._$window.location.assign(`/messages`);
    }
}

NotificationCenterPanelMessagesController.$inject = [
    '$q',
    '$scope',
    '$ngRedux',
    '$window',
    '$pfNotificationCenter',
];

export const NotificationCenterPanelMessagesComponent = {
    require: {
        'panelCtrl': '^pfNotificationCenterPanel',
    },
    bindings: {
        'panel': '<pfNotificationCenterPanel',
    },
    template: NotificationCenterPanelMessagesTemplate,
    controller: NotificationCenterPanelMessagesController,
};
