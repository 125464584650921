/**
* Constants are determined by computing the ratio between the number of
* characters in any given width at a defined font-size in pixels.
*
* This is expressed by the function:
*   R( [f], c, p ) = c / p : [f];
*   where :
*       R is the ratio constant,
*       f  = the specified font-size (px),
*       c  = the num of chars
*       p  = the num of px
*
* e.g. R( [10], 10, 10) => 1 : [10].
* **/
export const CHAR_PER_PIXEL_CONSTANTS = {
    16: 0.14,
    14: 0.16,
};
