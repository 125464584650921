import moment from 'moment';
import uuid from 'node-uuid';

export class AnonymousService {
    constructor($pfCookies, $pfEnv, PF_COOKIES) {
        this._$pfCookies = $pfCookies;
        this._$pfEnv = $pfEnv;
        this.PF_COOKIES = PF_COOKIES;
    }

    /**
     * Generate a new UUID to identify the visitor
     * @return {String} New anonymous ID
     */
    _generateAnonymousId() {
        return uuid.v4();
    }

    /**
     * Store a new anonymous ID for the visitor
     * @return {String} Newly generated anonymous ID
     */
    _setAnonymousId() {
        const newAnonymousId = this._generateAnonymousId();
        // Store the anonymous ID in a cookie. Cookie must match the cookie
        // generated automatically by the client-side javascript tracking library
        this._$pfCookies.put(
            this.PF_COOKIES.ANONYMOUS_ID,
            JSON.stringify(newAnonymousId),
            {
                expires: moment().add(1, 'years').toDate(),
                path: '/',
                domain: this._$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
            }
        );

        return newAnonymousId;
    }

    /**
     * Get the current anonymous ID.
     * @return {Null|String} Anonymous ID (UUID) or false if none exists
     */
    getAnonymousId() {
        const existingAnonymousId = this._$pfCookies.get(this.PF_COOKIES.ANONYMOUS_ID);

        if (!existingAnonymousId) {
            return null;
        }

        return JSON.parse(existingAnonymousId);
    }

    /**
     * Make sure an anonymous ID is set. If not, generate a new one and store it
     * @return {String} Anonymous ID value
     */
    updateAnonymousId() {
        const currentId = this.getAnonymousId();

        if (currentId) {
            return currentId;
        }

        return this._setAnonymousId();
    }
}

AnonymousService.$inject = [
    '$pfCookies',
    '$pfEnv',
    'PF_COOKIES',
];
