import _ from 'lodash';

import {
    SET_CURRENT_USER_ABOUT,
    SET_CURRENT_USER_LOCATION,
    SET_CURRENT_USER_PRIMARY_EDUCATION,
    SET_CURRENT_USER_PRIMARY_EXPERIENCE,
} from './user-about.actions';

export const initialState = {
    userAbout: {},
    primaryEducation: {},
    primaryExperience: {},
    primaryLocation: {},
};

// this is currently used in components/auth/current-user.reducer.js
export const userAbout = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_CURRENT_USER_PRIMARY_EDUCATION:
        case SET_CURRENT_USER_PRIMARY_EXPERIENCE:
        case SET_CURRENT_USER_ABOUT:
            return _.assign({}, state, action.payload);
        case SET_CURRENT_USER_LOCATION:
            const location = _.find(action.payload.locations, {current: '1'});
            return _.assign({}, state.primaryLocation, {
                primaryLocation: location,
            });
        default:
            return state;
    }
};
