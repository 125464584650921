import BaseModalController from 'components/modal/js/controller/base-modal-controller';

export class IntrestedModalController extends BaseModalController {
    constructor($mdDialog, $pfJobs, $window, $pfJobApply) {
        super($mdDialog);
        this.$pfJobs = $pfJobs;
        this.$window = $window;
        this.$pfJobApply = $pfJobApply;
    }

    /**
     * @ngdoc function
     * @description Apply to an external job
     * @param  {Event}  $event  Click event from ng-click
     * @param {Object}  job     The job record
     * @param {Boolean} saveJob Save the job?
     */
    applyOutside($event, job, saveJob) {
        $event.preventDefault();

        this.$pfJobApply.applyOutsideLink(job, saveJob);
        this.$mdDialog.cancel();
    }
}

IntrestedModalController.$inject = [
    '$mdDialog',
    '$pfJobs',
    '$window',
    '$pfJobApply',
];
