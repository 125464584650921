class MessageTemplateService
{
    constructor($http, $timeout, $state, $window) {
        this.$http = $http;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$window = $window;
    }

    /**
     * Get the messaging templates by company
     * @param   {Object}  params
     * @return {promise}
     */
    createNewTemplate() {
        // fixes https://github.com/angular/material/issues/7079
        return this.$timeout(() => {
            if (this.$state.includes('talentMatch')) {
                return this.$state.go('talentMatch.tmSettings.templates');
            }

            return this.$window.location.replace('/templates');
        }, 500);
    }

    /**
     * Get the messaging templates by company
     * @param   {Object}  params
     * @return {promise}
     */
    getTemplates(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/templates/me',
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Get a template by id
     * @param   {String}  id Id of the template to get
     * @param   {Object}  params Params
     * @return {promise}
     */
    getTemplateById(id, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/templates/template/${id}`,
            params: params
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Cteate a new template
     * @param   {Object}  template A template to save
     * @param   {Object}  params Params
     * @return {promise}
     */
     createTemplate(template, params = {}) {
         // Map scope fields for API call
         let data = {
             'type': template.type,
             'title': template.title,
             'subject': template.subject,
             'message': template.message,
         };

         return this.$http({
             method: 'POST',
             url: '/proxy/templates/create',
             data: data
         })
         .then((response) => {
             return response.data;
         });
     }

     /**
      * Edit an exisiting template
      * @param   {String}  id  Id of the template to edit
      * @param   {Object}  template A template to edit
      * @param   {Object}  params Params
      * @return {promise}
      */
      editTemplate(template, params = {}) {
          // Map scope fields for API call
          let data = {
              'title': template.title,
              'subject': template.subject,
              'message': template.message,
          };

          return this.$http({
              method: 'POST',
              url: `/proxy/templates/template/${template.id}`,
              data: data
          })
          .then((response) => {
              return response.data;
          });
      }

      /**
       * Delete a template
       * @param   {String}  id A template to delete
       * @return {promise}
       */
       deleteTemplate(id) {
           return this.$http({
               method: 'DELETE',
               url: `/proxy/templates/template/${id}`
           })
           .then((response) => {
               return response.data;
           });
       }
}

MessageTemplateService.$inject = [
    '$http',
    '$timeout',
    '$state',
    '$window',
];

export default MessageTemplateService;
