import PathwayRequirementSubmissionPanelEditorFieldListCheckboxTemplate from './pathway-requirement-submission-panel-editor-field-list-checkbox.component.html'
import './pathway-requirement-submission-panel-editor-field-list.component.scss';
import { PathwayRequirementSubmissionPanelEditorFieldListController }
    from './pathway-requirement-submission-panel-editor-field-list.controller';

/**
 * @ngdoc component
 * @name PathwayRequirementSubmissionPanelEditorFieldListCheckboxComponent
 * @module portfolium.component.pathways.requirement.submissionPanel
 **/
export const PathwayRequirementSubmissionPanelEditorFieldListCheckboxComponent = {
    bindings: {
        'pfTitle': '<',
        'listName': '<',
        'list': '<',
        'image': '<',
        'idKey': '<',
        'nameKey': '<',
        'onItemClick': '&',
    },
    controller: PathwayRequirementSubmissionPanelEditorFieldListController,
    template: PathwayRequirementSubmissionPanelEditorFieldListCheckboxTemplate,
}
