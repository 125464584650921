import angular from 'angular';

import ListTemplate from './template/card/list.html'
import StandardTemplate from './template/card/standard.html'
import './index.scss';

import { UserConnectService } from './js/service/user-connect-service';
import { UserConnectBtnComponent } from './user-connect-btn/user-connect-btn.component';
import { UserMessageBtnComponent } from './user-message-btn/user-message-btn.component';

import userConnectCardDirective from './js/directive/user-connect-card-directive';

(function() {
    let app = angular.module('portfolium.component.userConnect', []);

    // Components
    app.component('pfUserConnectBtn', UserConnectBtnComponent);
    app.component('pfUserMessageBtn', UserMessageBtnComponent);

    // Directive
    app.directive('pfUserConnectCard', userConnectCardDirective);

    // Service
    app.service('$pfUserConnect', UserConnectService);

    app.run([
        '$templateCache',
        $templateCache => {
            $templateCache.put(
                'template/components/user-connect/template/card/list.html',
                ListTemplate,
            );
            $templateCache.put(
                'template/components/user-connect/template/card/standard.html',
                StandardTemplate,
            );
        },
    ]);
})();

export default 'portfolium.component.userConnect';
