export const REQUIREMENT_ACTION_INITIATOR_TYPES = {
    portfolium: 'portfolium',
    student: 'student',
    network: 'network',
};

export const REQUIREMENT_ACTION_TYPE_CODES = {
    'ASSIGNMENT_CREATED': '1',
    'ASSIGNMENT_SUBMITTED': '2',
    'ASSIGNMENT_UNSUBMITTED': '3',
    'ASSIGNMENT_SCORED': '4',
    'MARKED_INCOMPLETE': '5',
    'SCORE_REVERSED': '6',
    'ASSIGNMENT_EDITED': '7',
    'REVISION_REQUESTED': '8',
    'BADGE_AWARDED': '9',
    'ASSIGNMENT_RESCORED': '10',
    'COURSE_ENDED_INCOMPLETE': '11',
};

export const REQUIREMENT_ACTIONS_MAPPED = [
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.ASSIGNMENT_CREATED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.network,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.ASSIGNMENT_SUBMITTED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.student,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.ASSIGNMENT_UNSUBMITTED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.student,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.ASSIGNMENT_SCORED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.network,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.ASSIGNMENT_RESCORED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.network,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.MARKED_INCOMPLETE,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.network,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.COURSE_ENDED_INCOMPLETE,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.network,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.SCORE_REVERSED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.portfolium,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.ASSIGNMENT_EDITED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.student,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.REVISION_REQUESTED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.network,
    },
    {
        actionType: REQUIREMENT_ACTION_TYPE_CODES.BADGE_AWARDED,
        initiatorType: REQUIREMENT_ACTION_INITIATOR_TYPES.network,
    },
];
