class PmailCardBtnController
{
    constructor($window, $state, $pfUser) {
        this.$window = $window;
        this.$state = $state;
        this.$pfUser = $pfUser;
    }

    /**
     * Is the user currently on a mobile device?
     * @return {Boolean}
     */
    get isMobile() {
        return this.$pfUser.isMobile();
    }

    /**
     * Handle the click event to take the appropriate action based on current status
     * @param  {Event}    $event      Click event from ng-click
     * @return {Promise}
     */
    onClick($event) {
        switch (this.pmailType) {
            case 'GENERAL':
                this.$state.go('thread', {id: this.pmail.thread.id});
                break;
            case 'INVITE_TO_APPLY':
                this.$window.location.href = `/job/${this.pmail.job.id}`;
                break;
        }
    }
}

PmailCardBtnController.$inject = [
    '$window',
    '$state',
    '$pfUser',
];

export default PmailCardBtnController;
