import { combineReducers } from 'redux';
import { settingsBlockedAccounts } from './settings-blocked-accounts/settings-blocked-accounts.reducer';
import { settingsEmails } from './settings-email/settings-email.reducer';
import { settingsImportData } from './settings-import-data/settings-import-data.reducer';
import { settingsLocations } from './settings-location/settings-location.reducer';
import { settingsNotifications } from './settings-notifications/settings-notifications.reducer';
import { settingsClearances } from './settings-clearances/settings-clearances.reducer';
import { settingsPrivacy } from './settings-privacy/settings-privacy.reducer';
import { settingsTalentMatch } from './settings-talent-match/settings-talent-match.reducer';
import { settingsAccountDisable } from './settings-account-disable/settings-account-disable.reducer';

export const settings = combineReducers({
    settingsBlockedAccounts,
    settingsEmails,
    settingsImportData,
    settingsLocations,
    settingsNotifications,
    settingsClearances,
    settingsPrivacy,
    settingsTalentMatch,
    settingsAccountDisable,
});
