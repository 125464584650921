/**
 * @ngdoc controller
 * @name sitewideSearchController
 * @module portfolium.component.pfSitewideSearch
 * @description controller for pfSitewideSearch
 */
export class SitewideSearchPanelController {
    constructor(
        PF_SITEWIDE_SEARCH_MENU,
        $timeout,
        $pfSitewideSearchPanel,
        $ngRedux
    ) {
        this.PF_SITEWIDE_SEARCH_MENU = PF_SITEWIDE_SEARCH_MENU;
        this.$timeout = $timeout;
        this.$pfSitewideSearchPanel = $pfSitewideSearchPanel;
        this.$ngRedux = $ngRedux;
        // default selected item to the first one in the list
        this.init();
    }

    init() {
        this._unsubscribe = this.$ngRedux.connect(
            this._mapStateToThis,
        )(this);
    }

    /**
     * closes the panel and focus the input
     * whenever an item was clicked (state changed)
     * @return {[type]} [description]
     */
    itemClicked() {
        // todo: close the panel here
        this.$pfSitewideSearchPanel.close();
        // focus input
        this.focusInput();
    }

    /**
     * focus the input elemenet at the
     * end of the stack
     * @param  {Number} [delay]  time that should be waited before focus
     */
    focusInput(delay = 0) {
        // execute in the end of the stack
        this.$timeout(() => {
            // focus the search input
            this.input.focus();
        }, delay);
    }

    _mapStateToThis(state) {
        return {
            selectedItem: state.sitewideSearch.menuItemIndex,
            searchValue: state.sitewideSearch.query,
        };
    }

    $onDestroy() {
        this._unsubscribe();
    }
}

SitewideSearchPanelController.$inject = [
    'PF_SITEWIDE_SEARCH_MENU',
    '$timeout',
    '$pfSitewideSearchPanel',
    '$ngRedux',
];
