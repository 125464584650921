export const jobApplicationsTypes = {
    'PAGE_INIT': 'JOB_APPLICATIONS_PAGE_INIT',
    'PAGE_NEXT': 'JOB_APPLICATIONS_PAGE_NEXT',
    'PAGE_PREVIOUS': 'JOB_APPLICATIONS_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'JOB_APPLICATIONS_PAGE_REQUEST',
    'PAGE_SUCCESS': 'JOB_APPLICATIONS_PAGE_SUCCESS',
    'PAGE_ERROR': 'JOB_APPLICATIONS_PAGE_ERROR',
    'TOGGLE_SELECTION': 'JOB_APPLICATIONS_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'JOB_APPLICATIONS_CLEAR_SELECTION',
    'TOGGLE_ALL': 'JOB_APPLICATIONS_TOGGLE_ALL',
};
