import angular from 'angular';
import { SocialOnboardComponent } from './social-onboard.component';

import socialAuthDirective from './social-auth-directive';
import socialAuthClearDirective from './social-auth-clear-directive';
import socialLinkDirective from './social-link-directive';
import SocialAuthService from './social-auth-service';

(function() {
    let app = angular.module('portfolium.component.socialAuth', []);

    // Directives
    app.directive('pfSocialAuth', socialAuthDirective);
    app.directive('pfSocialAuthClear', socialAuthClearDirective);
    app.directive('pfSocialLink', socialLinkDirective);

    // Component
    app.component('pfSocialOnboard', SocialOnboardComponent);

    // Services
    app.service('$pfSocialAuth', SocialAuthService);
})();

export default 'portfolium.component.socialAuth';
