const DOCUMENT_TYPES = {
    mimetypes: [
        'application/msword',
        'application/vnd.ms-word.document.macroenabled.12',
        'application/vnd.ms-word.template.macroenabled.12',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.text-template',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/x-iwork-pages-sffpages',
        'text/plain',
    ],
    extensions: [
        '.doc',
        '.docm',
        '.docx',
        '.dotm',
        '.dotx',
        '.odt',
        '.ott',
        '.pages',
        '.txt',
    ],
    signatures: [
        'D0CF11E0', // doc
        '504B0304', // docx, pages
        '55726E61', // txt
    ],
};

const SLIDESHOW_TYPES = {
    mimetypes: [
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-powerpoint.addin.macroenabled.12',
        'application/vnd.ms-powerpoint.presentation.macroenabled.12',
        'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
        'application/vnd.ms-powerpoint.template.macroenabled.12',
        'application/vnd.oasis.opendocument.presentation',
        'application/vnd.oasis.opendocument.presentation-template',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/x-iwork-keynote-sffkey',
    ],
    extensions: [
        '.key',
        '.odp',
        '.otp',
        '.potm',
        '.potx',
        '.ppt',
        '.pptm',
        '.pptx',
    ],
};

const SPREADSHEET_TYPES = {
    mimetypes: [
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.addin.macroenabled.12',
        'application/vnd.ms-excel.sheet.binary.macroenabled.12',
        'application/vnd.ms-excel.sheet.macroenabled.12',
        'application/vnd.ms-excel.template.macroenabled.12',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.spreadsheet-template',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/x-iwork-keynote-sffnumbers',
    ],
    extensions: [
        '.csv',
        '.numbers',
        '.ods',
        '.ots',
        '.xlam',
        '.xls',
        '.xlsb',
        '.xlsm',
        '.xlsx',
        '.xltm',
        '.xltx',
    ],
};

const DESIGN_TYPES = {
    mimetypes: [
        'application/illustrator',
        'application/photoshop',
        'application/postscript',
        'application/psd',
        'application/tif',
        'application/tiff',
        'application/x-photoshop',
        'application/x-tif',
        'application/x-tiff',
        'image/bmp',
        'image/photoshop',
        'image/psd',
        //'image/svg+xml',
        'image/tif',
        'image/tiff',
        'image/vnd.adobe.photoshop',
        'image/x-photoshop',
        'image/x-psd',
        'image/x-tif',
        'image/x-tiff',
        'image/x-windows-bmp',
        'zz-application/zz-winassoc-psd',
    ],
    extensions: [
        '.ai',
        '.bmp',
        '.psd',
        //'.svg',
        '.tif',
        '.tiff',
    ],
};

const AUDIO_TYPES = {
    mimetypes: [
        'audio/aac',
        'audio/aiff',
        'audio/basic',
        'audio/flac',
        'audio/l24',
        'audio/m4a',
        'audio/mid',
        'audio/mp3',
        'audio/mp4',
        'audio/mpeg',
        'audio/mpeg3',
        'audio/mpegurl',
        'audio/mpg',
        'audio/ogg',
        'audio/s-wav',
        'audio/vnd.wav',
        'audio/wav',
        'audio/wave',
        'audio/x-aac',
        'audio/x-aiff',
        'audio/x-flac',
        'audio/x-m4a',
        'audio/x-mp3',
        'audio/x-mpeg',
        'audio/x-mpeg3',
        'audio/x-mpg',
        'audio/x-ms-wma',
        'audio/x-wav',
        'audio/x-pn-wav',
    ],
    extensions: [
        '.aac',
        '.aiff',
        '.flac',
        '.m4a',
        '.mp3',
        '.wav',
        '.wma',
    ],
};

const VIDEO_TYPES = {
    mimetypes: [
        'application/x-troff-msvideo',
        'video/3gp',
        'video/3gpp',
        'video/webm',
        'video/avi',
        'video/mp4',
        'video/mpeg',
        'video/mpeg4',
        'video/msvideo',
        'video/quicktime',
        'video/x-m4v',
        'video/x-ms-asf',
        'video/x-ms-wma',
        'video/x-ms-wmv',
        'video/x-msvideo',
    ],
    extensions: [
        '.3gp',
        '.webm',
        '.asf',
        '.avi',
        '.m4v',
        '.mov',
        '.mp4',
        '.mpeg',
        '.mpg',
        '.qt',
        '.wmv',
    ],
};

const IMAGE_TYPES = {
    mimetypes: [
        'image/gif',
        'image/jpe',
        'image/jpeg',
        'image/jpg',
        'image/pjpeg',
        'image/png',
        'image/x-png',
    ],
    extensions: [
        '.gif',
        '.jpe',
        '.jpeg',
        '.jpg',
        '.png',
    ],
};

const PDF_TYPES = {
    mimetypes: [
        'application/pdf',
    ],
    extensions: [
        '.pdf',
    ],
    signatures: [
        '25504446', // pdf
    ],
};

const DB_TYPES = {
    mimetypes: [
        'application/mdb',
        'application/msaccess',
        'application/vnd.ms-access',
        'application/vnd.msaccess',
        'application/x-mdb',
        'application/x-msaccess',
    ],
    extensions: [
        '.accdb',
        '.accde',
        '.mdb',
    ],
};

const RESUME_TYPES = {
    mimetypes: _.union(
        DOCUMENT_TYPES.mimetypes,
        PDF_TYPES.mimetypes
    ),
    extensions: _.union(
        DOCUMENT_TYPES.extensions,
        PDF_TYPES.extensions
    ),
    signatures: _.union(
        DOCUMENT_TYPES.signatures,
        PDF_TYPES.signatures
    ),
}

export const MIMETYPES_CONFIG = {
    audio: AUDIO_TYPES,
    db: DB_TYPES,
    design: DESIGN_TYPES,
    document: DOCUMENT_TYPES,
    image: IMAGE_TYPES,
    pdf: PDF_TYPES,
    resume: RESUME_TYPES,
    slideshow: SLIDESHOW_TYPES,
    spreadsheet: SPREADSHEET_TYPES,
    video: VIDEO_TYPES,
};
