export const ADD_MESSAGES = 'ADD_MESSAGES';
export const UPDATE_UNREAD_MESSAGES = 'UPDATE_UNREAD_MESSAGES';

export const addMessages = (payload) => {
    return {
        type: ADD_MESSAGES,
        payload,
    };
};

export const updateUnreadMessages = (payload) => {
    return {
        type: UPDATE_UNREAD_MESSAGES,
        payload,
    };
};
