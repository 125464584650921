import angular from 'angular';
import CompanyConnectBtnTemplate from '../../template/company-connect-btn.html';
import CompanyConnectBtnController from '../controller/company-connect-btn-controller'

export const companyConnectBtnDirective = () => {
    return {
        restrict: 'E',
        require: ['ngModel'],
        scope: {
            'class': '@pfClass',
            'onConnected': '&pfOnConnected',
            'onDenied': '&pfOnDenied',
            'messageDialog': '=pfMessageDialog',
            'connectText': '@pfConnectText',
            'hideIcon': '<pfHideIcon',
        },
        bindToController: true,
        link: function (scope, elem, attr, ctrls) {
            const ngModelCtrl = ctrls[0];

            scope.connectCtrl._ngModelCtrl = ngModelCtrl;

            ngModelCtrl.$formatters.push((modelValue) => {
                return {
                    company: modelValue,
                    following: modelValue.following === '1',
                    status: modelValue.status
                };
            });

            ngModelCtrl.$parsers.push((viewValue) => {
                let updatedCompany = angular.extend(ngModelCtrl.$modelValue, {
                    following: (viewValue.connected) ? '1' : '0',
                    status: viewValue.status
                });

                return angular.copy(updatedCompany);
            });

            ngModelCtrl.$render = function () {
                scope.connectCtrl.company = ngModelCtrl.$viewValue.company;
                scope.connectCtrl.following = ngModelCtrl.$viewValue.following;
                scope.connectCtrl.status = ngModelCtrl.$viewValue.status;
            };

            elem.attr('tabindex', '-1');
        },
        controller: CompanyConnectBtnController,
        controllerAs: 'connectCtrl',
        template: CompanyConnectBtnTemplate
    };
}
