export const FOLLOW_CATEGORIES = 'FOLLOW_CATEGORIES';
export const FOLLOW_CATEGORY = 'FOLLOW_CATEGORY';
export const UNFOLLOW_CATEGORY = 'UNFOLLOW_CATEGORY';

/**
 * Follow multiple project categories
 * @param {Array<string>} categories Categories to follow
 */
export const followCategories = (categories) => {
    return {
        type: FOLLOW_CATEGORIES,
        payload: {
            categories,
        },
    };
};

/**
 * Follow an entries category
 * @param {string} categoryId Category to follow
 */
export const followCategory = (categoryId) => {
    return {
        type: FOLLOW_CATEGORY,
        payload: {
            categoryId,
        },
    };
};

/**
 * Unfollow an entries category
 * @param {string} categoryId Category to unfollow
 */
export const unfollowCategory = (categoryId) => {
    return {
        type: UNFOLLOW_CATEGORY,
        payload: {
            categoryId,
        },
    };
}
