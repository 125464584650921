import {
    loadUserEntries,
    addUserEntries,
} from '../reducers/user-entries.action';

export class UserEntriesService {
    constructor($http, $q, $ngRedux, $pfUser) {
        this._$http = $http;
        this._$q = $q;
        this._$ngRedux = $ngRedux;
        this._currentUser = $pfUser.getUser();

        this._$ngRedux.connect(this._mapStateToThis)(this);
    }

    _mapStateToThis(state) {
        return {
            userEntries: state.userEntries,
        };
    }

    /**
     * Load more user entries, based on the current application state
     * @return {Promise<Array<Object>>}
     */
    loadEntries() {
        if (this.userEntries.loading || this.userEntries.finished) {
            return this._$q.resolve([]);
        }

        this._$ngRedux.dispatch(loadUserEntries());

        return this._$http({
            method: 'GET',
            url: `/proxy/entries/user/${this._currentUser.id}`,
            params: {
                limit: this.userEntries.limit,
                offset: this.userEntries.offset,
            },
        }).then((response) => {
            const entries = response.data;

            this._$ngRedux.dispatch(addUserEntries(entries));

            return entries;
        });
    }
}

UserEntriesService.$inject = [
    '$http',
    '$q',
    '$ngRedux',
    '$pfUser',
];
