import AddEntryModalTemplate from './add-entry-modal.component.html';
import './add-entry-modal.component.scss';

/**
 * @ngdoc component
 * @name AddEntryModalController
 * @module portfolium.components.addEntryModal
 */
class AddEntryModalController {
    constructor($element, $pfDropZone, $pfEntryAddAttachment, $timeout) {
        this.$element = $element;
        this.$pfDropZone = $pfDropZone;
        this.$pfEntryAddAttachment = $pfEntryAddAttachment;
        this.$timeout = $timeout;
        this.attachment = {};
        this.percentage = 0;
        this.error = false;
        this.loading = false;
        this.urlLoading = false;
        this.dropZoneRef = null;

        this.attachmentsCallbacks =  {
            onStart: () => {
                this.onAttachmentUploadStart();
            },
            onProgress: ({percentage}) => {
                this.onAttachmentUploadProgress(null, percentage);
            },
            onSuccess: ({meta}) => {
                this.onAttachmentUploadSuccess(null, meta);
            },
            onFail: () => {
                this.onAttachmentUploadFail();
            },
        };
    }

    $onInit() {
        this.$pfDropZone.init(
            this.$element,
            {
                onDrop: file => {
                    // get the temp attachment object
                    this.$pfEntryAddAttachment.addAttachmentFromFile(
                        file,
                        this.attachmentsCallbacks,
                        this.allowedFileTypes,
                    );
                },
                multipleFiles: false,
            }
        ).then(dropZoneRef => {
            this.dropZoneRef = dropZoneRef;
        });
    }

    $onDestroy() {
        this.dropZoneRef.destroy();
        delete this.dropZoneRef;
    }

    /**
     * Handle the change event for the source URL field
     * @param  {String} url Url value from source URL field
     */
    onUrlChange(url) {
        if (!_.isString(url) || !url.length) {
            return;
        }

        this.error = false;
        this.urlLoading = true;

        // get the temp attachment object
        this.$pfEntryAddAttachment.getAttachmentFromUrl(
            url,
            this.attachmentsCallbacks
        )
        .then(() => {
            this.urlLoading = false;
        })
        .catch(() => {
            this.loading = false;
            this.urlLoading = false;
            this.error = true;
        });
    }

    /**
     * Callback for when the upload or url starts processing
     * @param  {string|null} _id attachment id, it is not used
     *                           but it has to be there because the callback sends it
     */
    onAttachmentUploadStart(_id) {
        this.$timeout(() => {
            // dont let the user drop any file while proccessing
            this.$element.addClass('disable-drag');
            // set loading flag
            this.loading = true;
        });
    }

    /**
     * Callback for when the upload or url progress of processing
     * @param  {string|null} _id attachment id, it is not used
     *                           but it has to be there because the callback sends it
     * @param  {number} percentage processing percentage
     */
    onAttachmentUploadProgress(_id, percentage) {
        this.$timeout(() => {
            this.percentage = percentage;
        });
    }

    /**
     * Callback for when the upload or url processing had succeed
     * @param  {string|null} _id attachment id, it is not used
     *                           but it has to be there because the callback sends it
     * @param  {object} meta attachment data ready to add to the draft
     */
    onAttachmentUploadSuccess(_id, meta) {
        this.$timeout(() => {
            // remove this class just to have things clear
            this.$element.removeClass('disable-drag');
            // unset loading flags
            this.loading = false;
            this.urlLoading = false;
        });
        // close and send the attachment to add into the draft
        this.onClose({attachment: meta});
    }

    /**
     * Callback for when the upload or url processing had fail
     */
    onAttachmentUploadFail() {
        this.$timeout(() => {
            // remove this class to allow user upload a file
            this.$element.removeClass('disable-drag');
            // unset loading flags
            this.loading = false;
            this.urlLoading = false;
        });
    }

    /**
     * close the modal and goes to the editor with no attachment
     */
    skipToEditor() {
        this.onClose({attachment: null});
    }
}

AddEntryModalController.$inject = [
    '$element',
    '$pfDropZone',
    '$pfEntryAddAttachment',
    '$timeout',
];

/**
 * @ngdoc component
 * @name AddEntryModalComponent
 * @module portfolium.components.addEntryModal
 **/
export const AddEntryModalComponent = {
    bindings: {
        onClose: '&',
        allowedFileTypes: '<',
    },
    controller: AddEntryModalController,
    controllerAs: '$ctrl',
    template: AddEntryModalTemplate,
};
