import angular from 'angular';
import resizeSliderDirective from './js/comparison-slider-directive'
import './sass/comparison-slider.scss';

(function () {
    // Define module
    let app = angular.module('portfolium.component.comparison.slider', []);

    // Directives
    app.directive('pfComparisonSlider', resizeSliderDirective);
}());

export default 'portfolium.component.comparison.slider';
