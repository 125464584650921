export const ASSIGNMENT_ACTION_INITIATOR_TYPES = {
    portfolium: 'portfolium',
    student: 'student',
    faculty: 'faculty',
};

export const ASSIGNMENT_ACTION_TYPE_CODES = {
    'ASSIGNMENT_CREATED': '1',
    'ASSIGNMENT_SUBMITTED': '2',
    'ASSIGNMENT_UNSUBMITTED': '3',
    'ASSIGNMENT_SCORED': '4',
    'MARKED_INCOMPLETE': '5',
    'SCORE_REVERSED': '6',
    'ASSIGNMENT_EDITED': '7',
    'REVISION_REQUESTED': '8',
    'BADGE_AWARDED': '9',
    'ASSIGNMENT_RESCORED': '10',
    'COURSE_ENDED_INCOMPLETE': '11',
};

export const ASSIGNMENT_ACTIONS_MAPPED = [
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.ASSIGNMENT_CREATED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.faculty,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.ASSIGNMENT_SUBMITTED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.student,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.ASSIGNMENT_UNSUBMITTED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.student,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.ASSIGNMENT_SCORED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.faculty,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.ASSIGNMENT_RESCORED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.faculty,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.MARKED_INCOMPLETE,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.faculty,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.COURSE_ENDED_INCOMPLETE,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.faculty,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.SCORE_REVERSED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.portfolium,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.ASSIGNMENT_EDITED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.student,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.REVISION_REQUESTED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.faculty,
    },
    {
        actionType: ASSIGNMENT_ACTION_TYPE_CODES.BADGE_AWARDED,
        initiatorType: ASSIGNMENT_ACTION_INITIATOR_TYPES.badge,
    },
];
