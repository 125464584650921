import {combineReducers} from 'redux';
import {notificationCenterPanel} from './notification-center-panel';
import {notificationCenterNotifications} from './notification-center-notifications';
import {notificationCenterMessages} from './notification-center-messages';

export const notificationCenter = combineReducers({
    notificationCenterPanel,
    notificationCenterNotifications,
    notificationCenterMessages,
});
