import BaseModalController from 'components/modal/js/controller/base-modal-controller';

export class PmailModalController extends BaseModalController {
    constructor($mdDialog) {
        super($mdDialog);
    }
}

PmailModalController.$inject = [
    '$mdDialog',
];
