export class ChallengesService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * Get all the challenges
     * @param {Object} [params={}] Query params
     * @return {Promise}
     */
    getChallenges(params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/contests/feed`,
            params,
        }).then(response => response.data);
    }

    /**
     * get information of a challenge by its slug
     * @param  {String} slug challenge slug
     * @return {promise}
     */
    getChallengeBySlug(slug) {
        return this.$http({
            method: 'GET',
            url: `/proxy/contests/contest/${slug}/slug`,
        }).then(response => response.data);
    }

    /**
     * get the entries count for slug challenge
     * @param  {String} slug challenge slug
     * @return {promise}
     */
    getChallengeCountBySlug(slug) {
        return this.$http({
            method: 'GET',
            url: `/proxy/contests/counts/${slug}/slug`,
        }).then(response => response.data);
    }

    /**
     * get the currently authed users entry into the passed in contest
     * @param  {Integer} contest_id contest Id
     * @return {promise}
     */
    getUserEntryForContest(contest_id) {
        return this.$http({
            method: 'GET',
            url: `/proxy/contests/me/${contest_id}`,
        }).then(response => response.data);
    }

    getChallengeSubmissions(challengeId, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/entries/contest/${challengeId}`,
            params,
        }).then(response => response.data);
    }

    /**
     * Get winners for challenge
     * @param  {String}  challengeId   Which challenge?
     * @return {Promise}
     */
    getWinners(challengeId) {
        return this.$http({
            method: 'GET',
            url: `proxy/contests/winners/${challengeId}`,
        }).then(response => response.data);
    }

    /**
     * Join a challenge with an entry
     * @param {Object} params join challenge params
     * @param {Integer} params.challengeId Id of the challenge being joined
     * @param {Integer} params.entryId Id of the entry being used to join
     */
    joinChallenge(params) {
        return this.$http({
            method: 'POST',
            url: `/proxy/contests/enter/${params.challengeId}/${params.entryId}`
        }).then(response => response.data);
    }

    /**
     * Cast a vote for an entry in an active contest
     * @param  {String}  entryId   Which contest?
     * @param  {String}  contestId Which contest?
     * @return {Promise}
     */
    voteForEntry(entryId, contestId) {
        return this.$http({
            method: 'POST',
            url: `proxy/contests/vote/${contestId}/${entryId}`,
        });
    }
}

ChallengesService.$inject = ['$http'];
