export const SETTINGS_NOTIFICATIONS_REQUEST = 'SETTINGS_NOTIFICATIONS_REQUEST';
export const SETTINGS_NOTIFICATIONS_TOGGLE = 'SETTINGS_NOTIFICATIONS_TOGGLE';
export const SETTINGS_NOTIFICATIONS_UPDATE = 'SETTINGS_NOTIFICATIONS_UPDATE';
export const SETTINGS_NOTIFICATIONS_USER_TYPE = 'SETTINGS_NOTIFICATIONS_USER_TYPE';
export const SETTINGS_NOTIFICATIONS_TOGGLE_ALL = 'SETTINGS_NOTIFICATIONS_TOGGLE_ALL';

export const settingsNotificationsRequest = () => {
    return {
        type: SETTINGS_NOTIFICATIONS_REQUEST,
    };
};

export const settingsNotificationsToggle = settingKey => {
    return {
        type: SETTINGS_NOTIFICATIONS_TOGGLE,
        payload: {
            settingKey
        },
    };
};

export const settingsNotificationsToggleAll = groupId => {
    return {
        type: SETTINGS_NOTIFICATIONS_TOGGLE_ALL,
        payload: {
            groupId
        },
    };
};

export const settingsNotificationsUpdate = groupList => {
    return {
        type: SETTINGS_NOTIFICATIONS_UPDATE,
        payload: {
            groupList
        },
    };
};

export const settingsNotificationsUserType = userType => {
    return {
        type: SETTINGS_NOTIFICATIONS_USER_TYPE,
        payload: {
            userType,
        },
    }
}
