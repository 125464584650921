class CompanyConnectService
{
    constructor($http) {
        this.$http = $http;
    }

    /**
     * @description Request a connection with a company by ID
     * @param  {String}  companyId Company ID
     * @param  {Object}  data   Form data to submit
     * @return {Promise}
     */
    requestConnection(companyId, data) {
        return this.$http({
            method: 'POST',
            url: `/proxy/companies/follow/${companyId}`,
            data: data
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Remove a connection with a company by ID
     * @param  {String}  companyId Company ID
     * @return {Promise}
     */
    removeConnection(companyId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/companies/unfollow/${companyId}`
        })
        .then((response) => {
            return response.data;
        });
    }
}

CompanyConnectService.$inject = ['$http'];

export default CompanyConnectService;
