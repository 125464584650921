import {combineReducers} from 'redux';
import {getPaginationReducer} from 'components/pagination/pagination.reducer';
import {job} from './job';
import {jobApplication} from './job-application';
import {jobApplicationsTypes} from '../constant/job-applications';
import {jobInterestedTypes} from '../constant/job-interested';
import {jobMatchesTypes} from '../constant/job-matches';

export const jobSummary = combineReducers({
    job,
    jobApplication,
    jobApplications: getPaginationReducer({
        actionTypes: jobApplicationsTypes,
    }),
    jobInterested: getPaginationReducer({
        actionTypes: jobInterestedTypes,
    }),
    jobMatches: getPaginationReducer({
        actionTypes: jobMatchesTypes,
    }),
});
