class AddClubAction
{
    constructor($pfGameAction) {
        this.$pfGameAction = $pfGameAction;
        this.name = 'add_more_clubs';
    }

    get label() {
        let label = 'Add a club, affiliation, or program';
        let currentLevel = this.$pfGameAction.getCurrentLevel() || {};

        if (currentLevel.name === 'pro') {
            label = 'Add 3+ clubs, affiliations, or programs';
        }

        if (currentLevel.name === 'champ' || this.type === 'Has_many') {
            label = 'Add more clubs, affiliations, or programs';
        }

        return label;
    }

    execute(next) {
        return this.$pfGameAction.openPanel('club', next);
    }
}

AddClubAction.$inject = ['$pfGameAction'];

export default AddClubAction;
