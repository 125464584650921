import _ from 'lodash';

import {
    OPEN_NOTIFICATION_CENTER_PANEL,
    CLOSE_NOTIFICATION_CENTER_PANEL,
    TOGGLE_NOTIFICATION_CENTER_PANEL_TAB,
} from '../action/notification-center-panel';

const initialState = {
    open: false,
    activeTab: 'NOTIFICATIONS',
    tabs: [
        {
            name: 'NOTIFICATIONS',
            displayName: 'Notifications',
            active: true,
        },
        {
            name: 'MESSAGES',
            displayName: 'Messages',
            active: false,
        },
    ],
};

export const notificationCenterPanel = (state = initialState, action = {}) => {
    switch (action.type) {
        case OPEN_NOTIFICATION_CENTER_PANEL:
            return _.assign({}, state, {
                open: true,
                activeTab: action.payload.activeTab,
                tabs: state.tabs.map((tab) => {
                    return _.assign({}, tab, {
                        active: tab.name === action.payload.activeTab,
                    });
                }),
            });
        case CLOSE_NOTIFICATION_CENTER_PANEL:
            return _.assign({}, initialState);
        case TOGGLE_NOTIFICATION_CENTER_PANEL_TAB:
            return _.assign({}, state, {
                activeTab: action.tabName,
                tabs: state.tabs.map((tab) => {
                    return _.assign({}, tab, {active: tab.name === action.tabName});
                }),
            });
        default:
            return state;
    }
};
