class BaseModalController
{
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    /**
     * Close the dialog and reject the promise returned from $mdDialog.show()
     * @param  {*}       reason An argument for the rejected promise.
     * @return {Promise}
     */
    cancel(reason) {
        return this.$mdDialog.cancel(reason);
    }

    /**
     * Hide the dialog and resolve the promise returned from $mdDialog.show()
     * @param  {*}       value An argument for the resolved promise.
     * @return {Promise}
     */
    hide(value) {
        return this.$mdDialog.hide(value);
    }

    /**
     * Validate the dialog form
     * @return {Boolean} Is the form valid?
     */
    isValid() {
        if (!this.form) {
            return true;
        }

        return this.form.$valid === true;
    }
}

BaseModalController.$inject = ['$mdDialog'];

export default BaseModalController;
