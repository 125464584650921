import moment from 'moment';
import JobResultCardTemplate from './job-result-card.component.html';
import './job-result-card.component.scss';
/**
 * @ngdoc controller
 * @name jobResultCardController
 * @param {Object} jobResultCard job to show
 * @param {Boolean} [show-description] whether to show or not the
 *  description
 * @param {String} [pf-source] source of the data, if 'es', will parse for
 *  Elastic Search data.
 * @module portfolium.component.jobResultCard
 **/
class JobResultCardController {
    constructor($pfJobDetails, $window) {
        this.$pfJobDetails = $pfJobDetails;
        this.$window = $window;
    }

    $onInit() {
        this.job = this._normalizeJob(this.jobResult);
    }

    /**
     * @ngdoc method
     * @name jobPostedAgo
     * @description Get length of time ago the job was posted
     * @param {Object} job
     * @return {String} Returns time ago job was posted
     **/
    get jobPostedAgo() {
        return this.$pfJobDetails.getJobPostedAgo(this.job);
    }

    /**
     * @ngdoc method
     * @name jobLocation
     * @description Get Location of the Job.
     * @param {Object} job
     * @return {String} Returns location of a job as {City, State}
     **/
    get jobLocation() {
        return this.$pfJobDetails.getJobLocation(this.job);
    }

    /**
     * @ngdoc method
     * @name _normalizeJob
     * @description Normalize job to match the UI specs
     * @param {Object} job
     * @return {Object}
     **/
    _normalizeJob(job) {
        if (this.pfSource === 'es') {
            return this.$pfJobDetails.jobNormalizerES(job);
        }
        return this.$pfJobDetails.jobNormalizerSearch(job);
    }
}

JobResultCardController.$inject = ['$pfJobDetails', '$window'];

/**
 * @ngdoc component
 * @name jobResultCardComponent
 * @module portfolium.component.jobResultCard
 **/
export const JobResultCardComponent = {
    bindings: {
        jobResult: '<',
        pfSource: '@',
        trafficSource: '<',
    },
    bindToController: true,
    template: JobResultCardTemplate,
    controller: JobResultCardController,
    controllerAs: '$ctrl',
};
