export const UPDATE_PROFILE_USER = 'UPDATE_PROFILE_USER';
export const RESET_PROFILE_USER = 'RESET_PROFILE_USER';

export const updateProfileUser = (payload) => {
    return {
        type: UPDATE_PROFILE_USER,
        payload,
    };
};

export const resetProfileUser = () => {
    return {
        type: RESET_PROFILE_USER,
    };
};
