import angular from 'angular';

import { resetProject } from './pathway-requirement-submission-panel.actions';

/**
 * @ngdoc service
 * @name PathwayRequirementSubmissionPanelService
 * @module portfolium.component.pathways.requirement.submissionPanel
 **/
export class PathwayRequirementSubmissionPanelService {
    constructor(
        $mdPanel,
        $ngRedux,
        $mdDialog,
        $mdMedia,
        $q,
        $pfHelpChat,
        $pfEventTracker,
    ) {
        this.$mdPanel = $mdPanel;
        this.$ngRedux = $ngRedux;
        this.$mdDialog = $mdDialog;
        this.$mdMedia = $mdMedia;
        this.$q = $q;
        this.$pfHelpChat = $pfHelpChat;
        this.$pfEventTracker = $pfEventTracker;
    }

    /**
     * Create a new panel instance
     * @param {Number} requirementId
     * @return {MdPanelRef}
     */
    _createPanel({ projectId, onProjectSelected }) {
        const isMobile = !this.$mdMedia('gt-xs');
        const position = this.$mdPanel.newPanelPosition()
            .absolute()
            .center();
        const config = {
            controller: angular.noop,
            controllerAs: '$ctrl',
            panelClass: 'pf-panel pf-pathway-requirement-submission-panel-wrapper',
            template: `
                <pf-pathway-requirement-submission-panel
                     on-project-selected="::$ctrl.onProjectSelected(projectId)"
                     project-id="::$ctrl.projectId"
                     md-panel-ref="::$ctrl.mdPanelRef"
                     layout="column"
                     layout-fill
                     flex>
                </pf-pathway-requirement-submission-panel>
            `,
            locals: {
                projectId,
                onProjectSelected,
            },
            attachTo: angular.element(document.body),
            focusOnOpen: true,
            trapFocus: true,
            fullscreen: isMobile,
            position: isMobile ? null : position,
            clickOutsideToClose: true,
            escapeToClose: true,
            hasBackdrop: true,
            zIndex: 70,
            onCloseSuccess: (panelRef, closeReason) => {
                const {
                    pathways: {
                        currentRequirement: {
                            pathwayRequirementSubmissionPanel: {
                                currentSubmissionDraft
                            }
                        }
                    }
                } = this.$ngRedux.getState();
                // if currentSubmissionDraft is null then the user is on the selection step
                // otherwise they're on the copy step
                const stepName = _.isNil(currentSubmissionDraft) ? 'selectionStep' : 'copyStep';
                // Track event
                return this.$pfEventTracker.trackServer(
                    'Submit Existing Project Closed',
                    {
                        clickSource: closeReason,
                        stepName,
                    }
                )
                .then(() => {
                    // reset the draft
                    this.$ngRedux.dispatch(resetProject());
                });
            },
            onDomRemoved: () => {
                // Reset panel
                this.panel = undefined;
                // Make sure the panel is destroyed when closed
                panel.destroy();

                if (isMobile) {
                    // show help chat button
                    this.$pfHelpChat.showButton();
                }
            },
        };
        const panel = this.$mdPanel.create(config);

        if (isMobile) {
            // hide help chat button
            this.$pfHelpChat.hideButton();
        }

        return panel;
    }

    /**
     * Open the panel
     * @param {Nuber} requirementId
     * @return {Promise<MdPanelRef>}
     */
    open({ projectId, onProjectSelected }) {
        const defer = this.$q.defer();

        // If panel is already open, don't do anything
        if (this.panel) {
            return defer.reject();
        }

        // Set panel reference
        this.panel = this._createPanel({ projectId, onProjectSelected });

        // Hide any dialogs if they are open
        return this.$mdDialog.hide().then(() => {
            // Open the panel
            return this.panel.open().then(() => {
                // Track event
                return this.$pfEventTracker.trackServer(
                    'Submit Existing Project Opened'
                )
                .then(() => {
                    // return deferred promise
                    return defer.promise;
                });
            });
        });
    }
}

PathwayRequirementSubmissionPanelService.$inject = [
    '$mdPanel',
    '$ngRedux',
    '$mdDialog',
    '$mdMedia',
    '$q',
    '$pfHelpChat',
    '$pfEventTracker',
];
