import angular from 'angular';
import hello from 'hellojs';
import config from './config';

class SocialAuthClient {
    constructor(
        client,
        onLogin,
        onLogout,
        addSpoke,
        socialSpokes
    ) {
        this.client = client;
        this.onLogin = onLogin;
        this.onLogout = onLogout;
        this.addSpoke = addSpoke;
        this.socialSpokes = socialSpokes;
        this.authProvider = hello(client);
        // set the flag here
        this.inFlight = false;
    }

    /**
     * Get the current auth status from hellojs
     * @private
     * @return {Object} Auth object from hellojs
     */
    _getStatus() {
        return this.authProvider.getAuthResponse();
    }

    /**
     * Has the user already authenticated with the client?
     * @return {boolean}
     */
    hasSpoke() {
        return this.socialSpokes[this.client] === true;
    }

    /**
     * Is the client service currently logged in?
     * @return {Boolean}
     */
    isAuthorized() {
        let session = this._getStatus();
        let currentTime = (new Date()).getTime() / 1000;

        return session && session.access_token && session.expires > currentTime;
    }

    /**
     * Login to the client service
     * @param  {Object}  options Configuration options
     * @return {Promise}
     */
    login(options, addSpoke = false) {
        let defaultOptions = {
            scope: config.clients[this.client].scope,
            force: false,
            response_type: "token id_token"
        };

        const loginOptions = angular.extend({}, defaultOptions, options);

        return this.authProvider.login(loginOptions)
            .then(({ authResponse: { access_token: token, id_token } }) => {
                if (_.isFunction(this.onLogin)) {
                    return this.api('/me')
                        .then(userData => {
                            this.onLogin(this.client, userData);
                            if (addSpoke) {
                                this.addSpoke(
                                    this.client,
                                    token,
                                    false,
                                    null,
                                    {id_token}
                                )
                            }
                            return { token, id_token };
                        });
                }

                if (addSpoke) {
                    this.addSpoke(
                        this.client,
                        token,
                        false,
                        null,
                        {id_token}
                    )
                }
                return { token, id_token };
            });
    }

    /**
     * Logout of the client service
     * @param  {Object}  options Configuration options
     * @return {Promise}
     */
    logout(options = {}) {
        if (_.isFunction(this.onLogout)) {
            this.onLogout(this.client);
        }

        return this.authProvider.logout(options);
    }

    /**
     * Call a method on the client service API
     * @param  {String}  path   API path
     * @param  {String}  method Request method
     * @param  {Object}  data   Data to submit
     * @return {Promise}
     */
    api(path, method, data) {
        return this.authProvider.api(path, method, data);
    }
}

export default SocialAuthClient;
