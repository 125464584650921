import angular from 'angular';

function inlineTemplateDirective($templateCache) {
    return {
        restrict: 'E',
        priority: 400, // Same as ng-include.
        compile: function(element, attrs) {
            let templateName = attrs.src;

            if (!templateName) {
                throw new Error('pfInlineTemplate: Expected template name');
            }

            let template = $templateCache.get(templateName);

            if (angular.isUndefined(template)) {
                throw new Error('pfInlineTemplate: Unknown template ' + templateName);
            }

            element.html(template);
        }
    };
}

inlineTemplateDirective.$inject = ['$templateCache'];

export default inlineTemplateDirective;
