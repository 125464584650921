import EntryViewerHeaderMobileTemplate from './entry-viewer-header-mobile.component.html';
import './entry-viewer-header-mobile.component.scss';

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerHeaderMobileComponent
 * @description Display list of profile in the entry viewer sidebar
 *
 * @param {object}      entry           Current entry data
 * @param {boolean}     isEditable      Is the entry editable?
 * @param {boolean}     isLoading       Is the data currently loading?
 * @param {boolean}     isPreview       Is the user viewing a preview?
 * @param {callback}    onLike          Trigger when user likes an entry.
 */
export const EntryViewerHeaderMobileComponent = {
    bindings: {
        entry: '<',
        isEditable: '<',
        isLoading: '<',
        isPreview: '<',
        onLike: '&',
    },
    controller: angular.noop,
    controllerAs: '$ctrl',
    template: EntryViewerHeaderMobileTemplate,
};
