import angular from 'angular';

/**
 * @ngdoc service
 * @name EntryDraftsService
 * @module portfolium.core.services.entries
 * @description Service for fetching, saving, and publishing entry drafts
 * @class EntryDraftsService
 */
export class EntryDraftsService {
    constructor(
        $http,
        $mdDialog,
        $pfEntryAddAttachment,
        $pfEntryDetails,
        $pfFilestackSigner,
        $pfToast,
        $pfUser,
        fullNameFilter,
        $filter,
        PF_ENTRY_VISIBILITY_TYPES,
    ) {
        this.$http = $http;
        this.$mdDialog = $mdDialog;
        this.$pfEntryAddAttachment = $pfEntryAddAttachment;
        this.$pfEntryDetails = $pfEntryDetails;
        this.$pfFilestackSigner = $pfFilestackSigner;
        this.$pfToast = $pfToast;
        this.fullNameFilter = fullNameFilter;
        this.$filter = $filter;
        this.currentUser = $pfUser.getUser();
        this.visibilityTypes = PF_ENTRY_VISIBILITY_TYPES;
    }

    /**
     * @description opens the confirm delete modal and deletes a draft from the database by ID
     * @param {Object} $event
     * @param {string} draftId='' Draft object to delete
     * @return {Promise}
     * @memberof EntryDraftsService
     **/
    deleteDraftConfirm($event, draftId = '') {
        // make $event optional
        if (_.isString($event) && _.isEmpty(draftId)) {
            draftId = $event;
            $event = undefined;
        }

        const title = this.$filter('i18n')('Delete draft');
        const textContent = this.$filter('i18n')('Are you sure you want to delete this draft?');
        const ariaLabel = this.$filter('i18n')('Delete draft');
        const ok = this.$filter('i18n')('Yes');
        const cancel = this.$filter('i18n')('Cancel');

        // Configure confirm modal
        let confirm = this.$mdDialog.confirm()
           .parent(angular.element(document.body))
           .title(title)
           .cancel(cancel)
           .textContent(textContent)
           .ariaLabel(ariaLabel)
           .targetEvent($event)
           .ok(ok);

        // Show confirm modal
        return this.$mdDialog.show(confirm).then(() => {
            // User confirmed, delete draft
            return this.deleteDraft(draftId).then(() => {
                // Show success toast
                this.$pfToast.success('Draft deleted.');
            }, () => {
                // Show error toast
                this.$pfToast.error('Something went wrong, please try again.');
            });
        });
    }

    /**
     * Delete a draft from the database by ID
     * @param {string} draftId Draft object to delete
     * @returns {Promise} Resolved when the draft is deleted
     * @memberof EntryDraftsService
     */
    deleteDraft(draftId = '') {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/drafts/draft/${draftId}`,
        }).then(response => response.data);
    }

    /**
     * Get a blank draft object to use in the entry editor. Pre-set model values
     * given valid config options and use the current user's profile privacy
     * preferences to set the necessary fields
     *
     * @param {object} [config={}] Optional config options for pre-setting values
     * @param {string} [config.bucketId] Profile bucket to associate the entry with
     * @param {string} [config.challengeId] Challenge to associate the entry with
     * @param {string[]} [config.tags] Tags to pre-populate on the draft
     * @param {string[]} [config.skills] Skills to pre-populate on the draft
     *
     * @returns {object} New draft object
     * @memberof EntryDraftsService
     */
    getBlankDraft({
        attachments,
        bucketId,
        challengeId,
        tags,
        skills,
    } = {}) {
        const isPrivateProfile = this.currentUser.isPrivate();
        const isUnderage = this.currentUser.isUnderage;

        let visibility = _.find(this.visibilityTypes, { name: 'public' }).id;
        let allow_comments = true;

        if (isPrivateProfile) {
            visibility = _.find(this.visibilityTypes, { name: 'connections' }).id;
        }

        if (isUnderage) {
            visibility = _.find(this.visibilityTypes, { name: 'hidden' }).id;
            allow_comments = false;
        }

        const draft = {
            id: null,
            entry_id: null,
            title: null,
            bucket_id: null,
            challenge_id: null,
            category: null,
            cover: null,
            description: null,
            skill_list: [],
            tags: [],
            collaborators: [],
            attachments: [],
            allow_comments,
            visibility,
            type: '6', // misc
            token: null,
            slug: null,
        };

        // Pre-populate tags
        if (_.isArray(tags) && tags.length) {
            // Map flat tags array to collection of objects
            const tagsArray = this._validateTags(tags);

            draft.tags = tagsArray;
        }

        // Pre-populate skills
        if (_.isArray(skills) && skills.length) {
            // Map flat skills array to collection of objects
            const skillsArray = this._validateSkills(skills);

            draft.skill_list = skillsArray;
        }

        if (_.isArray(attachments) && attachments.length) {
            // set attachments in the draft
            draft.attachments = attachments;
            // get preview photo
            const attachmentPreviewImage = this.$pfEntryAddAttachment.getAttachmentPreview(attachments[0]);
            // sanity check for a preview photo
            if (attachmentPreviewImage) {
                // User confirmed, delete attachment
                draft.cover = attachmentPreviewImage;
            }
        }

        // Pre-populate profile about record association
        if (bucketId) {
            draft.bucket_id = bucketId;
        }

        // Pre-populate challenge association
        if (_.isString(challengeId)) {
            draft.challenge_id = challengeId;
        }

        return {
            draft,
            entryDetails: this.getBlankEntryDetails(),
        };
    }

    /**
     * Get a blank entry details meta object for use in the entry preview for a new
     * draft
     *
     * @returns  {object} Entry details object
     * @memberof EntryDraftsService
     */
    getBlankEntryDetails() {
        return {
            comments: '0',
            contests: [],
            created_at: null,
            expert: '0',
            featured: '0',
            liked: '0',
            likes: '0',
            likers: [],
            profile: _.assign({}, {
                id: this.currentUser.id,
                avatar: { url_https: this.currentUser.avatar },
                firstname: this.currentUser.firstName,
                lastname: this.currentUser.lastName,
                username: this.currentUser.username,
                school: this.currentUser.school,
                major: this.currentUser.major,
                connected: '0',
            }),
            spotlighted: '0',
            verified: '0',
            views: '0',
        };
    }

    /**
     * Get total number of drafts for current user
     *
     * @returns {Promise<object>} Resolved with an object
     * @memberof EntryDraftsService
     */
    getDraftCount() {
        return this.$http({
            method: 'GET',
            url: `/proxy/drafts/counts`,
        })
        .then(response => response.data);
    }

    /**
     * Get a draft object for the entry editor by draft ID
     *
     * @param {string} draftId Draft ID to fetch
     * @returns {Promise<object>} Resolved with a draft
     * @memberof EntryDraftsService
     */
    getDraftByDraftId(draftId) {
        return this.$http({
            method: 'GET',
            url: `/proxy/drafts/draft/${draftId}`,
        })
        .then(response => response.data)
        .then(data => JSON.parse(data.data))
        .then(draft => {
            // check for strength here
            if (draft.strength) {
                // remove it from the draft
                delete draft.strength;
            }
            // return here
            return draft;
        })
        .then(draft => ({
            draft,
            entryDetails: this.getBlankEntryDetails(),
        }));
    }

    /**
     * Get a draft object for the entry editor by entry ID
     *
     * @param {string} entryId Entry ID to fetch
     * @returns {Promise<object>} Resolved with a draft
     * @memberof EntryDraftsService
     */
    getDraftByEntryId(entryId) {
        return this.$pfEntryDetails
            .getEntryDetailsById(entryId)
            .then(entry => this.mapEntryToDraft(entry));
    }

    /**
     * Publishes a submission draft to the project
     * @param  {Number}         entryId
     * @param  {Object}         submissionDraft
     * @param  {Object.attachments}   submissionDraft.attachments attachments list
     * @param  {Object.collaborators}   submissionDraft.collaborators collaborators list
     * @param  {Object.cover}   submissionDraft.cover cover list
     * @param  {Object.description}   submissionDraft.description description list
     * @param  {Object.includeDescription}   submissionDraft.includeDescription includeDescription flat
     * @param  {Object.skill_list}   submissionDraft.skill_list skill_list list
     * @param  {Object.tags}   submissionDraft.tags tags list
     * @return {Promise}
     */
    publishSubmissionDraft(entryId,
        {
            attachments,
            collaborators,
            cover,
            description,
            includeDescription,
            skill_list,
            tags,
        }
    ) {
        return this.$pfEntryDetails
            .getEntryDetailsById(entryId)
            .then(entry => this.mapEntryToDraft(entry))
            .then(({ draft }) => {
                // get filtered attachments
                const filteredAttachments = this.filterIncludes(attachments, true);
                // get thew new cover image
                const submissionCover = this.getSubmissionDraftCoverImage(cover, filteredAttachments);
                // create a new draft by merging the
                // existing project draft with the
                // items selected in the submission draft
                const newDraft = _.assign({}, draft,
                    {
                        id: entryId,
                        entry_id: entryId,
                        cover: submissionCover,
                        description: includeDescription ? description : null,
                        attachments: filteredAttachments,
                        tags: this.filterIncludes(tags),
                        skill_list: this.filterIncludes(skill_list),
                        collaborators: this.filterIncludes(collaborators),
                    });
                // publish the newly created draft
                return this.publishDraft(newDraft);
            });
    }

    getSubmissionDraftCoverImage(cover, attachments) {
        // sanity check for attachments
        if (attachments.length === 0) {
            // return whatever it is as cover image
            return cover;
        }
        // default preview image
        let previewImage = null;
        // default this flag
        let coverIsInAttachments = false;
        // find if any of the attachment is the same as cover
        _.forEach(attachments, attachment => {
            // is the preview photo current cover ?
            const isPreviewPhotoCurrentCover = this.$pfEntryAddAttachment.isPreviewPhotoCurrentCover(attachment, cover);
            // get preview
            const attachmentPreviewImage = this.$pfEntryAddAttachment.getAttachmentPreview(attachment);
            // sanity check for preview image
            if (_.isEmpty(previewImage)) {
                // update the preview image
                previewImage = attachmentPreviewImage;
            }
            // update the flag
            coverIsInAttachments = coverIsInAttachments || isPreviewPhotoCurrentCover;
            // was found?
            if (isPreviewPhotoCurrentCover) {
                // braek the loop
                return false;
            }
        });
        // sanity check for flag
        if (coverIsInAttachments) {
            // return the cover since it is the same
            return cover;
        }
        // return the preview image
        return previewImage;
    }

    getSubmissionDraftByEntryId(entryId) {
        return this.$pfEntryDetails
            .getEntryDetailsById(entryId)
            .then(entry => this.mapEntryToDraft(entry))
            .then(({ draft }) => {
                return _.assign(draft,
                    {
                        includeDescription: true,
                        attachments: _.map(draft.attachments, this.addInclude),
                        tags: _.map(draft.tags, this.addInclude),
                        collaborators: _.map(
                            _.map(draft.collaborators, this.addInclude), collaborator => {
                                return this.getCollaboratorsFullName(collaborator)
                            }

                        ),
                        skill_list: _.map(draft.skill_list, this.addInclude),
                    });
            });
    }

    getCollaboratorsFullName(collaborator) {
        // get full name
        const fullName = this.fullNameFilter(collaborator);
        // assign it
        return _.assign({}, collaborator, {
            fullName,
        });
    }

    removeInclude(item) {
        return _.omit(item, 'include');
    }

    filterIncludes(list, cleanIds = false) {
        // get the items that are desired to be included
        let filteredList = _.filter(list, { 'include': true })
        // sanity check for clean ids
        if (cleanIds) {
            // set null to every id in the list
            filteredList = _.map(filteredList, item => {
                return _.assign(item, { id: null });
            });
        }
        // remove the include from the list
        return _.map(filteredList, this.removeInclude);
    }

    addInclude(item)  {
        return _.assign({}, item, {
            include: true,
        });
    }

    /**
     * Transform entry details data into a draft object for the entry editor
     *
     * @param {object} entry Entry details object
     * @returns {object} Mapped draft and details data
     * @memberof EntryDraftsService
     */
    mapEntryToDraft(entry) {
        // Map entry data into usable model for the entry editor
        const draft = _.assign({}, {
            id: null,
            entry_id: entry.id,
            cover: entry.cover,
            title: entry.title,
            description: entry.description || '',
            category: {
                id: entry.fk_category_id,
                slug: entry.category_slug,
                category: entry.category,
            },
            collaborators: entry.collaborators || [],
            skill_list: entry.skill_list || [],
            tags: entry.tags || [],
            attachments: entry.attachments || [],
            allow_comments: entry.allow_comments === '1',
            type: entry.type,
            visibility: entry.visibility,
            slug: entry.slug || null,
            token: entry.token || null,
            bucket_id: null,
            challenge_id: null,
        });

        // Separate the entry details data used to preview the entry
        const entryDetails = _.assign({}, {
            comments: entry.comments,
            contests: entry.contests,
            created_at: entry.created_at,
            expert: entry.expert,
            featured: entry.featured,
            liked: entry.liked,
            likes: entry.likes,
            likers: entry.likers,
            profile: entry.profile,
            spotlighted: entry.spotlighted,
            verified: entry.verified,
            views: entry.views,
            draftId: entry.draft_id,
        });

        return {
            draft,
            entryDetails,
        };
    }

    /**
     * Get a list of drafts for the current user
     * @param {object} params
     * @returns {promise<object[]>} Resolved with a collection of draft objects
    */
   getDraftsList(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/drafts/me',
            params,
        })
        // get response data
        .then(response => response.data)
        // return mapped JSON data from draft
        .then(drafts => drafts.map(draft => JSON.parse(draft.data)));
    }

    /**
     * Publish a draft. This works the same for both existing entries and new drafts.
     * If the draft has an ID, the draft record will be deleted from the database.
     *
     * @param {object} [draft={}]   Draft object to publish
     * @param {object} [sharing={}] Sharing options for the project
     * @returns {Promise<object>}
     * @memberof EntryDraftsService
     */
    publishDraft(draft = {}, sharing = {}, recaptchaToken = null) {
        const data = {
            data: JSON.stringify(this.$pfFilestackSigner.sanitizeEntryDraft(draft)),
            recaptcha: recaptchaToken
        };

        const testUser = this.currentUser._data.type === '9';

        if (testUser) {
            data.recaptcha = 'dummy_token';
        }

        return this.$http({
            method: 'POST',
            url: `/proxy/drafts/publish`,
            data: _.assign(data, sharing),
        }).then(response => response.data)
        .catch(() => ({}));
    }

    /**
     * Persist a draft to the database. If the draft has no ID, a new draft will
     * be created and the response will contain a draft with the newly created ID.
     *
     * @param {object} [draft={}] Draft object to save
     * @returns {Promise<object>} Resolved with the updated draft
     * @memberof EntryDraftsService
     */
    saveDraft(draft = {}) {
        // return here
        return this.$http({
            method: 'POST',
            url: `/proxy/drafts/draft`,
            data: this.$pfFilestackSigner.sanitizeEntryDraft(draft),
            isJSONRequest: true,
        }).then(response => response.data);
    }

    /**
     * Validate and build collection of tag objects
     * @param {array} tags
     * @return {object[]}
     */
    _validateTags(tags) {
        let filteredTags = [];

        _.forEach(tags, (tag) => {
            // remove invalid characters
            tag = tag.replace(/[^a-zA-Z0-9_]+/g, '');
            // validate length
            if (tag.length > 0 && tag.length <= 191) {
                // add tag
                filteredTags.push({ tag });
            }
        });

        return filteredTags;
    }

    /**
     * Validate and build collection of skill objects
     * @param {array} skills
     * @return {object[]}
     */
    _validateSkills(skills) {
        let filteredSkills = [];

        _.forEach(skills, (skill) => {
            // validate length
            if (skill.length > 0 && skill.length <= 40) {
                // add skills
                filteredSkills.push({
                    id: null,
                    skill,
                });
            }
        });

        return filteredSkills;
    }
}

EntryDraftsService.$inject = [
    '$http',
    '$mdDialog',
    '$pfEntryAddAttachment',
    '$pfEntryDetails',
    '$pfFilestackSigner',
    '$pfToast',
    '$pfUser',
    'fullNameFilter',
    '$filter',
    'PF_ENTRY_VISIBILITY_TYPES',
];
