import _ from 'lodash';
import {
    UPDATE_PROFILE_USER,
    RESET_PROFILE_USER,
} from '../action/profile-user';

export const profileUser = (state = {}, action = {}) => {
    switch (action.type) {
        case UPDATE_PROFILE_USER:
            return _.assign({}, state, action.payload);
        case RESET_PROFILE_USER:
            return {};
        default:
            return state;
    }
};
