export const SETTINGS_PRIVACIES_REQUEST        = 'SETTINGS_PRIVACIES_REQUEST';
export const SETTINGS_TOGGLE_PRIVACY           = 'SETTINGS_TOGGLE_PRIVACY';
export const SETTINGS_UPDATE_PRIVACIES         = 'SETTINGS_UPDATE_PRIVACIES';
export const SETTINGS_GDPR_SUBMIT_REQUEST      = 'SETTINGS_GDPR_SUBMIT_REQUEST';
export const SETTINGS_UPDATE_GDPR_PORTABILITY  = 'SETTINGS_UPDATE_GDPR_PORTABILITY';

export const settingsPrivaciesRequest = () => {
    return {
        type: SETTINGS_PRIVACIES_REQUEST,
    };
};

export const settingsTogglePrivacy = settingKey => {
    return {
        type: SETTINGS_TOGGLE_PRIVACY,
        payload: {
            settingKey
        }
    };
};

export const settingsUpdatePrivacies = (bypassUrl, privacies) => {
    return {
        type: SETTINGS_UPDATE_PRIVACIES,
        payload: {
            bypassUrl,
            privacies
        }
    };
};

export const settingsGdprSubmitRequest = () => {
    return {
        type: SETTINGS_GDPR_SUBMIT_REQUEST,
    };
};

export const settingsUpdateGdprPortability = (gdprPortability) => {
    return {
        type: SETTINGS_UPDATE_GDPR_PORTABILITY,
        payload: {
            gdprPortability
        }
    };
};
