import ProfileAboutPanelFormTemplate from '../../template/profile-about-panel-form.html';
import {
    ABOUT_PANEL_TABS,
    toggleAboutPanelTab,
    saveForm,
} from '../action/profile-about-panel';

class ProfileAboutPanelFormController {
    constructor($q, $injector, $ngRedux, $mdDialog, $pfUser, $pfToast, $filter) {
        this._$q = $q;
        this._$injector = $injector;
        this._$ngRedux = $ngRedux;
        this._$mdDialog = $mdDialog;
        this._$pfToast = $pfToast;
        this._$pfUser = $pfUser;
        this._$filter = $filter;
    }

    $onInit() {
        // Make a copy of the form data for editing, without skills or entries
        this.vm = _.omit(_.cloneDeep(this.panel.data), ['skills', 'entries']);
        // Store a copy of the form data to compare against
        this.initialVm = _.omit(_.cloneDeep(this.panel.data), ['skills', 'entries']);
        if (!this.vm.image) {
            // default image source
            this.vm = _.set(this.vm, 'image.source', null);
        }
        // Set the isEdit flag
        this.isEdit = !!this.vm.id;
    }

    /**
     * Update about record via API call, then update application state
     * @return {Promise}
     */
    _saveData() {
        // Execute the onSubmit hook
        return this._$q.when(this._$injector.invoke(this.onSubmit, null, {
            data: this.vm,
        })).then((data) => {
            // Update application state
            this._$ngRedux.dispatch(saveForm(data));
        }, () => {
            // Show toast
            this._$pfToast.error('Something went wrong, please try again.');

            return this._$q.reject();
        });
    }

    /**
     * Close the panel without saving any changes
     * @return {Promise}
     */
    close() {
        return this.panelCtrl.close();
    }

    /**
     * Change panel tabs, checking the form for changes before switching
     * @param  {String}             tabName Tab to transition to
     * @param  {event}              $event  ngClick event
     * @return {void|Promise<void>}
     */
    activateTab(tabName, $event) {
        if (tabName === this.panel.activeTab) {
            // Already on the tab
            return;
        }

        if (!this.hasChanges()) {
            // Form has no changes, toggle tab
            this._$ngRedux.dispatch(toggleAboutPanelTab(tabName));

            return;
        }

        const title = this._$filter('i18n')('You have unsaved changes.');
        const ok = this._$filter('i18n')('Save');
        const cancel = this._$filter('i18n')('Discard');

        const confirm = this._$mdDialog.confirm()
            .title(title)
            .targetEvent($event)
            .ok(ok)
            .cancel(cancel);

        // Show a confirmation dialog to ask user to save changes
        return this._$mdDialog.show(confirm).then(() => {
            // Save the form changes
            return this._saveData().then(() => {
                // Toggle tab
                this._$ngRedux.dispatch(toggleAboutPanelTab(tabName));
            });
        }, () => {
            // Toggle tab without saving
            this._$ngRedux.dispatch(toggleAboutPanelTab(tabName));
        });
    }

    /**
     * Has the user made any changes to the form data?
     * @return {Boolean}
     */
    hasChanges() {
        return !_.isEqual(this.vm, this.initialVm);
    }

    /**
     * Is the form valid?
     * @return {Boolean}
     */
    isValid() {
        return this.form && this.form.$valid;
    }

    /**
     * Submit the form, and then close the panel on success
     * @return {Promise}
     */
    submit() {
        this.form.$setSubmitted();

        if (!this.isValid()) {
            return this._$q.reject();
        }

        this.loading = true;
        this._saveData().then(() => {
            if (this.isEdit) {
                // Close the panel if editing existing about
                return this.close().then(() => {
                    // Show toast
                    this._$pfToast.success('Saved.');
                });
            }

            // Switch to projects tab if creating new about
            this._$ngRedux.dispatch(toggleAboutPanelTab(ABOUT_PANEL_TABS.WORK_SAMPLES));
        }).finally(() => {
            this.loading = false;
        });
    }

    /**
     * Update image with new uploaded meta
     * @param  {object} meta image data
     */
    updateImageSrc(meta) {
        if (!meta) {
            this.vm.image = null;
            return;
        }
        const source = meta.key || null;
        this.vm.image = {
            source,
        }
    }

    /**
     * Displays error when trying to upload an image
     * @param  {string} err error message sent from the image uploader
     */
    updateImageError(err) {
        this._$pfToast.error(err);
    }
}

ProfileAboutPanelFormController.$inject = [
    '$q',
    '$injector',
    '$ngRedux',
    '$mdDialog',
    '$pfUser',
    '$pfToast',
    '$filter',
];

export const ProfileAboutPanelFormComponent = {
    require: {
        'panelCtrl': '^pfProfileAboutPanel',
    },
    bindings: {
        'panel': '<pfAboutPanel',
        'onSubmit': '<pfOnSubmit',
    },
    template: ProfileAboutPanelFormTemplate,
    controller: ProfileAboutPanelFormController,
};
