import _ from 'lodash';

import {
    FOLLOW_CATEGORIES,
    FOLLOW_CATEGORY,
    UNFOLLOW_CATEGORY,
} from './category-interests.actions';

export const categoryInterests = (state = [], action = {}) => {
    switch (action.type) {
        case FOLLOW_CATEGORIES:
            return _.uniq([
                ...state,
                ...action.payload.categories,
            ]);
        case FOLLOW_CATEGORY:
            return _.uniq([
                ...state,
                action.payload.categoryId,
            ]);
        case UNFOLLOW_CATEGORY:
            return _.without(state, action.payload.categoryId);
        default:
            return state;
    }
};
