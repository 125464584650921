import SchoolAutocompleteTemplate from './school-autocomplete.component.html';

/**
 * @ngdoc component
 * @name pfSchoolAutocomplete
 * @module portfolium.component.pfSchoolAutocomplete
 * @description controller for pfSchoolAutocomplete
 * @param {Object} ng-model scho0l name
 */
class SchoolAutocompleteController {
    /**
    * @ngdoc method
    * @name TalentMatchAvatarController#$constructor
    * @methodOf TalentMatchAvatarController
    * @description set $pfNetwork
    */
    constructor($pfNetwork, $element, $timeout){
        this.$pfNetwork = $pfNetwork;
        this.$element = $element;
        this.$timeout = $timeout;
    }

    /**
    * @ngdoc method
    * @name TalentMatchAvatarController#$onInit
    * @methodOf TalentMatchAvatarController
    * @description sets the school if it was one sent as a string
    */
    $onInit() {
        this.ngModel.$render = ()=>{
            if (this.pfGetName) {
                this.searchText = this.ngModel.$modelValue;
            }
        };
    }

    /**
    * @ngdoc method
    * @name TalentMatchAvatarController#$postLink
    * @methodOf TalentMatchAvatarController
    * @description This fixes when someone focus on the component
    *   the md autocomplete will then be focused
    */
    $postLink() {
        // In order to be focusable it needs to have a tabindexx
        // attribute
        this.$element.attr('tabindex','0');
        // On focus the component, then focus the input contained
        // in the md autocomplete
        this.$element.on("focus",()=>{
            this.$timeout(()=>{
                this.$element.find("md-autocomplete").find("input").focus();
            },0)
        });
    }

    /**
     * @ngdoc method
     * @name TalentMatchAvatarController#$getSchools
     * @methodOf TalentMatchAvatarController
     * @description Search networks by query string
     * @param  {String}  query Query string value
     * @return {Promise}
     */
    getSchools(query) {
        let params = { 'q': query };
        let accountType = this.pfAccountType;

        if (
            accountType === 'undergrad' ||
            accountType === 'grad' ||
            accountType === 'alumni'
        ) {
            // set type query value to university
            params.type = 'university';
        }

        if (
            accountType === 'communitycollege' ||
            accountType === 'k12'
        ) {
            params.type = accountType;
        }

        return this.$pfNetwork.querySchools(params)
            .then((schools = []) => {
                // Always let user add custom named schools
                schools.push({
                    name: query,
                    network: null,
                    custom: true
                });
                return schools;
            }
        );
    }

    /**
     * @ngdoc method
     * @name TalentMatchAvatarController#$getSchools
     * @methodOf TalentMatchAvatarController
     * @description Update the identity service when user chooses a school
     * @param  {Object|null} school New selected school value
     */
    handleSchoolChange(school) {
        if (school && (this.pfGetName || this.pfGetObject)) {
            // Return only a string if the pfGetName is true
            if (this.pfGetName) {
                this.ngModel.$setViewValue(school.name);
                return;
            }
            // Return default object if the pfGetObject is true
            if (this.pfGetObject) {
                this.ngModel.$setViewValue(school);
                return;
            }
        }

        // Special object, used for login
        let schoolNetwork = {};
        let network = _.isObject(school) && school.network || {};

        if (!_.isObject(school)) {
            // Selected value was cleared, reset network and school values
            schoolNetwork.network = {};
            schoolNetwork.school = '';

            return;
        }

        if (!!network.id) {
            // User selected a school that has an existing network, store network
            schoolNetwork.network = network;
        } else {
            // User selected a school with no network, reset network
            schoolNetwork.network = {};
        }

        // Store school value
        schoolNetwork.school = school.name;
        // update the ng model
        this.ngModel.$setViewValue(schoolNetwork);
        // calls the on change function
        this.pfOnChange();
    }
}

SchoolAutocompleteController.$inject = [
    '$pfNetwork',
    '$element',
    '$timeout'
];

export const SchoolAutocompleteComponent = {
    require: {
        ngModel: '^ngModel'
    },
    bindings: {
        'pfId': '@',
        'pfGetName': '<',
        'pfGetObject': '<',
        'pfAccountType': '<',
        'pfOnChange': '&'
    },
    template: SchoolAutocompleteTemplate,
    controller: SchoolAutocompleteController,
    controllerAs: '$ctrl'
};
