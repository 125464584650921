function socialAuthDirective(
    $pfSocialAuth,
    $pfAuth,
    $pfToast,
    $pfCookies,
    $location,
    $state,
    PF_COOKIES
) {
    return {
        restrict: 'A',
        scope: {
            'onUnauthedLogin': '&pfOnUnauthedLogin',
            'loading': '=?pfLoading'
        },
        link: function (scope, elem, attrs) {
            let client = attrs.pfSocialAuth;
            let socialAuthClient = $pfSocialAuth[client];

            if (!socialAuthClient) {
                throw new Error(`No social auth client found for '${client}'`);
            }

            // set redirect url as current path, if there is a return url cookie, $pfAuth.redirect() will use that instead
            const redirectUrl = $location.path();

            elem.on('click', () => {
                // Make sure user has OAuth token from client
                socialAuthClient.login().then((response) => {
                    let authToken = response.token;
                    let idToken = response.id_token;

                    scope.loading = true;

                    return $pfAuth.socialLogin(client, authToken, {
                        create: false,
                        id_token: idToken
                    }).then((response) => {
                        let profile = response.data;

                        if (response.status === 203) {
                            // No user record found with this auth token or email
                            // -- execute callback for unauthed login
                            scope.onUnauthedLogin({
                                data: {
                                    profile: profile,
                                    client: client,
                                    token: authToken,
                                    id_token: idToken
                                }
                            });

                            return;
                        }
                        return $pfAuth.redirect(redirectUrl);
                    }, (error) => {
                        // check for deleted account - status 423
                        if (error.status === 423) {
                            const {
                                token,
                                requested_at,
                                will_delete_at,
                            } = error.data;

                            return $pfAuth
                                .triggerAccountLockedError(token, requested_at, will_delete_at);
                        }

                        // coppa error
                        if (error.status === 412) {
                            return $pfAuth.triggerCoppaError();
                        }

                        $pfToast.error('Something went wrong, please try again.');
                    }).finally(() => {
                        scope.loading = false;
                    });
                });
            });
        }
    };
}

socialAuthDirective.$inject = [
    '$pfSocialAuth',
    '$pfAuth',
    '$pfToast',
    '$pfCookies',
    '$location',
    '$state',
    'PF_COOKIES',
];

export default socialAuthDirective;
