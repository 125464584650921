import angular from 'angular';

class EntryCardLikeController
{
    constructor($pfEntryCard) {
        this.$pfEntryCard = $pfEntryCard;
    }

    likeEntry() {
        // Immediately set view to reflect like
        this.liked = true;
        this.likes += 1;
        // Update entry model
        this._ngModelCtrl.$setViewValue({
            liked: this.liked,
            likes: this.likes
        });

        this.$pfEntryCard.likeEntry(this.entryId)
            .then(null, () => {
                // Reset view due to API failure
                this.liked = false;
                this.likes -= 1;
                // Update entry model
                this._ngModelCtrl.$setViewValue({
                    liked: this.liked,
                    likes: this.likes
                });
            });
    }
}

EntryCardLikeController.$inject = ['$pfEntryCard'];

function entryCardLikeDirective() {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope: true,
        bindToController: true,
        link: function (scope, elem, attrs, ngModelCtrl) {
            scope.likeCtrl._ngModelCtrl = ngModelCtrl;

            ngModelCtrl.$formatters.push((modelValue) => {
                return {
                    entryTitle: modelValue.title,
                    entryId: modelValue.id,
                    liked: modelValue.liked === '1',
                    likes: parseInt(modelValue.likes, 10)
                };
            });

            ngModelCtrl.$parsers.push((viewValue) => {
                return angular.extend(ngModelCtrl.$modelValue, {
                    likes: viewValue.likes.toString(),
                    liked: (viewValue) ? '1' : '0'
                });
            });

            ngModelCtrl.$render = function () {
                let model = ngModelCtrl.$viewValue;

                scope.likeCtrl.entryTitle = model.entryTitle;
                scope.likeCtrl.entryId = model.entryId;
                scope.likeCtrl.likes = model.likes;
                scope.likeCtrl.liked = model.liked;
            };

            elem.attr('tabindex', '-1');
        },
        controller: EntryCardLikeController,
        controllerAs: 'likeCtrl',
        template: `
            <md-button md-no-ink
                 pf-require-auth
                 class="md-icon-button"
                 ng-class="{'pf-liked': likeCtrl.liked}"
                 ng-click="!likeCtrl.liked && likeCtrl.likeEntry()"
                 aria-label="{{ likeCtrl.liked === true ? 'You liked' : 'Like' }} {{ ::likeCtrl.entryTitle }}.">
                <md-icon ng-if="likeCtrl.liked == true" class="material-icons pf-liked" aria-hidden="true">favorite</md-icon>
                <md-icon ng-if="likeCtrl.liked == false" aria-hidden="true">favorite_border</md-icon>
            </md-button>
        `
    };
}

export default entryCardLikeDirective;
