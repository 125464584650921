import moment from 'moment';

export class DateService {
    /**
     * Convert UTC time to local
     * @param  {String|Int} date the date to convert
     * @param  {String}     format if specified, it returns a formatted string
     * @return {String|Moment} return either a formatted string or a Moment object
     */
    utcToLocal(date, format) {
        // set the time to the local time zone
        const localTime = moment.utc(date).local();

        if (!_.isNil(format)) {
            // return local time in set format
            return localTime.format(format);
        }

        return localTime;
    }

    /**
     * Checks whether a date from the API is fake
     * @param  {String|Int}  date
     * @return {Boolean}
     */
    isFakeDate(date) {
        const fakeDateMySQLDateTime = '2100-01-01 00:00:00';
        const fakeDateUnixEpoch = 4102444800;

        if (_.isEqual(date, fakeDateMySQLDateTime)) {
            return true;
        }

        if (_.isEqual(date, fakeDateUnixEpoch)) {
            return true;
        }

        return false;
    }
}
