import angular from 'angular';

/**
 * @ngdoc service
 * @name FilestackSignerService
 * @module portfolium.core.services.filestack
 * @description This service is a wrapper on top of the Portfolium API signer service
 **/
export class FilestackSignerService {

    /**
     * Constructor
     */
    constructor($http) {
        // set the http
        this.$http = $http;
        // we need to set up some translations for the API calls
        this.ugc_type_translations = {
            'companyCover'      : 'company_cover',
            'companyLogo'       : 'company_logo',
            'profileAttribute'  : 'profile_attribute',
            'project_attachment': 'project_attachments',
        };
    }

    /**
     * Get the policy for an upload
     * @param  {string} filename
     * @return Promise
     */
    getUploadPolicy(preset, filename) {
        // sanity check
        if (this.ugc_type_translations.hasOwnProperty(preset)) {
            // reset the preset
            preset = this.ugc_type_translations[preset];
        }
        // call it
        return this.$http({
            method: 'GET',
            url: '/proxy/filestack_signer/upload',
            params: {
                ugc_type: preset,
                filename: filename,
            }
        }).then(response => {
            // return the promise
            return response.data;
        });
    }

    /**
     * Get the policy for a read
     * @param  {string} url
     * @return Promise
     */
    getReadPolicy(url) {
        // call it
        return this.$http({
            method: 'GET',
            url: '/proxy/filestack_signer/read',
            params: {
                url: url,
            }
        }).then(response => {
            // return the promise
            return response.data;
        });
    }

    /**
     * Get the policy for a pick
     * @param {string} type
     * @return Promise
     */
    getPickerPolicy(preset) {
        // sanity check
        if (this.ugc_type_translations.hasOwnProperty(preset)) {
            // reset the preset
            preset = this.ugc_type_translations[preset];
        }
        // get the policy
        return this.$http({
            method: 'GET',
            url: '/proxy/filestack_signer/pick/' + preset
         }).then(response => {
            // return the promise
            return response.data;
        });
    }

    /**
     * Get the policy for a remove
     * @param  {string} handle
     * @return Promise
     */
    getRemovePolicy(key) {
        // call it
        return this.$http({
            method: 'GET',
            url: '/proxy/filestack_signer/remove',
            params: {
                key: key,
            }
        }).then(response => {
            // return the promise
            return response.data;
        });
    }

    /**
     * Build the convert url with security
     * @param  {string} transformUrl
     * @param  {string} convertTo
     * @param  {string} path
     * @param  {string} container
     * @param  {string} security
     * @param  {string} filehandle
     * @return string
     */
    buildConvertUrl(transformUrl, convertTo, path, container, { policy, signature }, fileHandle) {
        // create the constant url here and go ...
        let url = `${transformUrl}/output=format:${convertTo}/store=path:"${path}",container:"${container}"`;
        // add the security parameters here
        if (policy && signature) {
            // set the policy here
            url = `${url}/security=policy:${policy},signature:${signature}`;
        }
        // add the handle
        url = `${url}/${fileHandle}`;
        // return here
        return url;
    }

    /**
     * Sign a preview url
     * @param  {Object} preview
     * @param  {string} url
     * @return string
     */
    signPreviewUrl(preview, url) {
        // sanity check that the entire security package is there
        if (preview.security && preview.security.policy && preview.security.signature) {
            // need this
            let {security} = preview;
            // attach the policy and signature
            url = this.signUrl(url, security);
        }
        // return
        return url;
    }

    /**
     * Is this url signed
     * @param  {string} url
     * @return bool
     */
    isSignedUrl(url) {
        // get the query string
        return url.includes("?policy=") && url.includes("&signature=");
    }

    /**
     * Sign a url
     * @param  {string} url
     * @param  {Object} security
     * @return string
     */
    signUrl(url, security) {
        // sign the url with the necessary policy data as get parameters
        return `${url}?policy=${security.policy}&signature=${security.signature}`;
    }

    /**
     * Sanitize a draft here
     * @param  {Object} draft
     * @return {Object}
     */
    sanitizeEntryDraft(draft = {}) {
        // we need a copy of the draft here
        let copy = angular.copy(draft);
        // check for attachments ya dingus
        if (copy.attachments) {
            // loop through the attachements
            copy.attachments.forEach(attachment => {
                // sanitize the file
                if (attachment.meta.file) {
                    // remove the clean
                    delete attachment.meta.file.security;
                    // sanity check that we have added a clean url
                    if (attachment.meta.file.clean_url) {
                        // set the url
                        attachment.meta.file.url = attachment.meta.file.clean_url;
                    }
                    // remove the clean
                    delete attachment.meta.file.clean_url;
                }
                // need this
                let target = 'preview';
                // sanity check
                if (attachment.meta && attachment.meta.image) {
                    // set the target
                    target = 'image';
                }
                // sanity check
                if (target && attachment.meta[target]) {
                    // sanitize this ish
                    delete attachment.meta[target].file.security;
                    // sanity check that we have added a clean url
                    if (attachment.meta[target].file.clean_url) {
                        // set the url
                        attachment.meta[target].file.url = attachment.meta[target].file.clean_url;
                    }
                    // delete the clean
                    delete attachment.meta[target].file.clean_url;
                }
            });
        }
        // check for the cover
        if (copy.cover) {
            // sanitize this ish
            delete copy.cover.file.security;
            // sanity check that we have added a clean url
            if (copy.cover.file.clean_url) {
                // set the url
                copy.cover.file.url = copy.cover.file.clean_url;
            }
            // delete the clean
            delete copy.cover.file.clean_url;
        }
        // return
        return copy;
    }

    /**
     * Sanitize a job here
     * @param  {Object} job
     * @return {Object}
     */
    sanitizeJob(job = {}) {
        // we need a copy of the draft here
        let copy = angular.copy(job);

        // sanity check
        if (!copy.image) {
            return copy;
        }

        // set image_uri with image source
        if (!copy.image.image_uri) {
            // set the data
            copy.image.image_uri = copy.image.source;
        }
        // return
        return copy;
    }

    /**
     * Return a simple error message
     * @return string
     */
    simpleErrorMessage() {
        // return an error message
        return 'There was an issue signing your upload. Please try again';
    }

    /**
     * Return an error message
     * @return string
     */
    errorMessage(error) {
        // sanity check
        if (error.data.error) {
            // return
            return error.data.error;
        }
        // return an error message
        return error;
    }
}

// inject the fun buddies.
FilestackSignerService.$inject = [
    '$http',
];
