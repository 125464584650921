import angular from 'angular';

import {ProfileStrengthCompComponent} from './profile-strength-comp.component';
import {ProfileStrengthCompTipsComponent} from './profile-strength-tips/profile-strength-comp-tips.component';

const MODULE_NAME = 'portfolium.component.profileStrengthComp';

import './profile-strength-comp.component.scss';

(function() {
    angular.module(MODULE_NAME, [])
        .component('pfProfileStrengthComp', ProfileStrengthCompComponent)
        .component('pfProfileStrengthCompTips', ProfileStrengthCompTipsComponent);
}());

export default MODULE_NAME;
