export const searchNetworksActionTypes = {
    'PAGE_INIT': 'SEARCH_NETWORKS_PAGE_INIT',
    'PAGE_NEXT': 'SEARCH_NETWORKS_PAGE_NEXT',
    'PAGE_PREVIOUS': 'SEARCH_NETWORKS_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'SEARCH_NETWORKS_PAGE_REQUEST',
    'PAGE_SUCCESS': 'SEARCH_NETWORKS_PAGE_SUCCESS',
    'PAGE_ERROR': 'SEARCH_NETWORKS_PAGE_ERROR',
    'TOGGLE_SELECTION': 'SEARCH_NETWORKS_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'SEARCH_NETWORKS_CLEAR_SELECTION',
    'TOGGLE_ALL': 'SEARCH_NETWORKS_TOGGLE_ALL',
    'ADD_FILTER': 'SEARCH_NETWORKS_ADD_FILTER',
    'REMOVE_FILTER': 'SEARCH_NETWORKS_REMOVE_FILTER',
};
