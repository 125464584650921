/**
 * Factory function for filter actions
 * @param {Object} config Configuration options
 * @param {Object} config.actionTypes Filter action types
 * @param {String} config.actionTypes.ADD_FILTER Add filter action
 * @param {String} config.actionTypes.REMOVE_FILTER Remove filter action
 * @param {String} config.actionTypes.RESET_FILTERS Reset filters action
 * @return {Object} Object containing all the public actions for filtering
 */
export const getFilterActions = ({actionTypes}) => {
    const {
        ADD_FILTER,
        REMOVE_FILTER,
        RESET_FILTERS,
    } = actionTypes;

    /**
     * Add a new key/value filter to the query
     * @param {String} name Name of the filter
     * @param {String} value Value of the filter
     */
    const addKeyValueFilter = (name, value) => {
        return {
            type: ADD_FILTER,
            payload: {
                type: 'keyValue',
                name,
                value,
            },
        };
    };

    /**
     * Remove a key/value filter from the query
     * @param {String} name Name of the filter to remove
     */
    const removeKeyValueFilter = (name) => {
        return {
            type: REMOVE_FILTER,
            payload: {
                type: 'keyValue',
                name,
            },
        };
    };

    /**
     * Add a new collection filter to the query
     * @param {String} name Name of the filter
     * @param {String} value Value of the filter
     */
    const addCollectionFilter = (name, value) => {
        return {
            type: ADD_FILTER,
            payload: {
                type: 'collection',
                name,
                value,
            },
        };
    };

    /**
     * Remove an existing collection filter from the query
     * @param {String} name Name of the filter
     * @param {String} value Value to remove from filter
     */
    const removeCollectionFilter = (name, value) => {
        return {
            type: REMOVE_FILTER,
            payload: {
                type: 'collection',
                name,
                value,
            },
        };
    };

    /**
     * Remove all currently applied filters from the query
     */
    const resetFilters = () => {
        return {
            type: RESET_FILTERS,
        };
    };

    return {
        addKeyValueFilter,
        removeKeyValueFilter,
        addCollectionFilter,
        removeCollectionFilter,
        resetFilters,
    };
};
