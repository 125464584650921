import angular from 'angular';

import './product-tour.scss';
import ProductTourService from './product-tour.service';

const moduleName = 'portfolium.component.productTour';

(function () {
    angular.module(moduleName, [])
        .service('$pfProductTour', ProductTourService);
}());

export default moduleName;
