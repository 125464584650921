import angular from 'angular';
import { SitewideSearchPanelController } from './sitewide-search-panel.controller';
import {
    openPanel,
    closePanel,
} from './sitewide-search.actions';

/**
 * @ngdoc service
 * @name SiteWideSearchPanelService
 * @module portfolium.component.pfSitewideSearch
 * @description Provides a desktop panel for sitewide search
 */
export class SiteWideSearchPanelService {
    constructor(
        $mdPanel,
        $mdMedia,
        $q,
        $ngRedux,
        $timeout
    ) {
        this.$mdPanel = $mdPanel;
        this.$mdMedia = $mdMedia;
        this.$q = $q;
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;

        this.panel = null;
    }

    /**
     * Create a new panel instance
     * @param  {Element} elem     Target element to open panel below to
     * @return {MdPanelRef}
     */
    _createPanel(element) {

        const position = this.$mdPanel
            .newPanelPosition()
            .relativeTo(element)
            .addPanelPosition(this.$mdPanel.xPosition.ALIGN_START, this.$mdPanel.yPosition.BELOW)
            .addPanelPosition(this.$mdPanel.xPosition.CENTER, this.$mdPanel.yPosition.BELOW);

        /**
         * Handle click outside here simce the default one
         * doesnt satifies
         * @param  {Event} event
         */
        const mouseupHandler = (event) => {
            // sanity check that it is not search input
            if (element[0] !== event.target) {
                // close when click outside
                this.close();
            }
        };

        const target = angular.element(document.body);

        const config = {
            controller: SitewideSearchPanelController,
            controllerAs: '$ctrl',
            locals: {
                input: element
            },
            template: `
                <pf-sitewide-search-menu
                     role="presentation"
                     pf-role="listbox"
                     pf-aria-label="Search Options"
                     query="$ctrl.searchValue"
                     pf-item-clicked="$ctrl.itemClicked()"
                     focused-element="$ctrl.selectedItem">
                </pf-sitewide-search-menu>`,
            panelClass: 'pf-panel pf-sitewide-search-menu-panel',
            attachTo: angular.element(document.body),
            position: position,
            hasBackdrop: false,
            trapFocus: true,
            clickOutsideToClose: false,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 1000,
            propagateContainerEvents: true,
            onDomAdded: () => {
                // add listener to mouseup event on body
                target.on('click', mouseupHandler);
            },
            onDomRemoved: () => {
                // remove listener once done
                target.off('click', mouseupHandler);
                element.focus();
            },
        };
        const panel = this.$mdPanel.create(config);

        return panel;
    }

    initializePanel(element) {
        // create panel
        this.panel = this._createPanel(element);

        this.$timeout(()=>{
            this.panel.attach();
        })
    }

    /**
     * Open the panel
     * @param  {Element} elem     Target element to open panel below to
     * @return {Promise<MdPanelRef>}
     */
    open(element) {
        this.$ngRedux.dispatch(openPanel());

        // Open the panel
        return this.panel.show();
    }

    /**
     * close panel
     * @return {Promise}
     */
    close() {
        this.$ngRedux.dispatch(closePanel());
        return this.panel.hide();
    }

    get isOpen() {
        if (this.panel) {
            return true;
        }
        return false;
    }
}

SiteWideSearchPanelService.$inject = [
    '$mdPanel',
    '$mdMedia',
    '$q',
    '$ngRedux',
    '$timeout',
];
