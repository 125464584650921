export class AnalyticsTracker {

    /**
     * FOR NOW WE'RE GOING TO SET THIS TO NULL
     *
     * Every function below will bail out on the sanity check.
     * We did this because we used to use Segment.com for tracking
     * We don't anymore, but we want to leave all the code in, in case
     * we want to add something back in the future, we just need to adjust what
     * this class does.
     */
    constructor() {
        this.analytics = null;
    }

    track(event, properties, options, callback) {
        if (!this.analytics) {
            return;
        }

        this.analytics.track(event, properties, options, callback);
    }

    page(category, name, properties, options, callback) {
        if (!this.analytics) {
            return;
        }

        this.analytics.page(category, name, properties, options, callback);
    }

    identify(userId, traits, options, callback) {
        if (!this.analytics) {
            return;
        }

        this.analytics.identify(userId, traits, options, callback);
    }

    reset() {
        if (!this.analytics) {
            return;
        }

        this.analytics.reset();
    }

    ready(callback) {
        if (!this.analytics) {
            return;
        }

        this.analytics.ready(callback);
    }
}
