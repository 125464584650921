import NotificationCenterPanelTemplate from '../../template/notification-center-panel.html';
import {
    NOTIFICATION_CENTER_PANEL_TABS,
} from '../action/notification-center-panel';

class NotificationCenterPanelController {
    constructor(
        $scope,
        $pfUser,
        $ngRedux,
        $pfNotificationCenter
    ) {
        this._$pfUser = $pfUser;
        this._$ngRedux = $ngRedux;
        this._$pfNotificationCenter = $pfNotificationCenter;

        // Subscribe to state updates
        const unsubscribe = $ngRedux.connect(this._mapStateToThis)(this);

        // Unsubscribe when panel is closed
        $scope.$on('$destroy', unsubscribe);
    }

    $onInit() {
        // Set tabs
        this.tabs = NOTIFICATION_CENTER_PANEL_TABS;

        // Reset notifications to 0 on panel open, then update store/cookie
        const unreadCount = {
            notifications: 0,
            messages: this.messageStore.unread
        };

        this._$pfNotificationCenter.storeNotificationCenterCount(unreadCount);
    }

    _mapStateToThis(state) {
        return {
            panel: state.notificationCenter.notificationCenterPanel,
            messageStore: state.notificationCenter.notificationCenterMessages,
            notificationStore: state.notificationCenter.notificationCenterNotifications,
        };
    }

    /**
     * Is the user currently on a mobile device?
     * @return {Boolean}
     */
    get isMobile() {
        return this._$pfUser.isMobile();
    }

    /**
     * Checks for unread notifications
     * @return {Boolean} does the user have unread notifications?
     */
    hasUnreadNotifications() {
        return (this.notificationStore.unread > 0);
    }

    /**
     * Checks for unread messages
     * @return {Boolean} does the user have unread messages?
     */
    hasUnreadMessages() {
        return (this.messageStore.unread > 0);
    }

    /**
     * Close the panel
     * @return {Promise} Resolved when panel is closed
     */
    close() {
        return this.mdPanelRef.close();
    }
}

NotificationCenterPanelController.$inject = [
    '$scope',
    '$pfUser',
    '$ngRedux',
    '$pfNotificationCenter',
];

export const NotificationCenterPanelComponent = {
    bindings: {
        'mdPanelRef': '<mdPanelRef',
    },
    template: NotificationCenterPanelTemplate,
    controller: NotificationCenterPanelController,
};
