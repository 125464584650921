import angular from 'angular';

import {BalanceMeterBarComponent} from './balance-meter-bar.component';

import './balance-meter-bar.component.scss';

const MODULE_NAME = 'portfolium.component.balanceMeterBar';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfBalanceMeterBar', BalanceMeterBarComponent);
}());

export default MODULE_NAME;
