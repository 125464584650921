import MobileMenuFooterTemplate from '../../template/mobile-menu-footer.html';

/**
 * @ngdoc component
 * @name mobileMenuFooterComponent
 * @module portfolium.component.mobileMenu
 **/
 export const MobileMenuFooterComponent = {
    bindings: {
        'closePanel': '&',
    },
    template: MobileMenuFooterTemplate,
};
