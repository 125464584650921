class StringToLinkService
{
    constructor() {
        this.re_domain = new RegExp(/([\s])(([a-z0-9]([a-z0-9\-]{0,}[a-z0-9])?\.)+[a-z]{2,}([\/\?#][^\s]*)?)(?=\s)/igm);
        this.re_url = new RegExp(/((?:^|[^"'\(]))(((https?|ftp):\/\/)[^\s/$.?#].[^\s]*)/igm);
        this.re_markdown = new RegExp(/\[([^\]]+)\]\(([^\)`]+)\)/ig);
        this.re_email = new RegExp(/(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/gi);
        this.re_hashtag = new RegExp(/(^|[\s])#(\w*)/igm);
    }

    /**
     * convert a hashtag to link
     * @param {String} str - the text that contains hashtag
     * @return {String} string with converted hashtag
     */
    hashtag(str) {
        if (!_.isString(str)) {
            return str;
        }
        return str.replace(this.re_hashtag, '$1<a href="/discover/tag/$2" ui-sref="discover.tag({tag: \'$2\'})">#$2</a>');
    }

    /**
     * convert an email address to link
     * @param {String} str - the text that contains email address
     * @return {String} string with converted email addresses
     */
    email(str) {
        if (!_.isString(str)) {
            return str;
        }
        return str.replace(this.re_email, '<a href="mailto:$&" rel="nofollow">$&</a>');
    }

    /**
     * convert a markdown url to link
     * @param {String} str - the text that contains markdown url
     * @return {String} string with converted markdown urls
     */
    markdown(str) {
        if (!_.isString(str)) {
            return str;
        }
        // get markdown
        str = str.replace(this.re_markdown, '<a href="$2" target="_blank" rel="nofollow">$1</a>');
        // handle href urls without http://
        const href = new RegExp(/(?!href=)\"(([a-z0-9]([a-z0-9\-]{0,}[a-z0-9])?\.)+[a-z]{2,})\"/igm);
        return str.replace(href, '"http://$1"');
    }

    /**
     * convert a url to link
     * @param {String} str - the text that contains url
     * @return {String} string with converted urls
     */
    url(str) {
        if (!_.isString(str)) {
            return str;
        }
        return str.replace(this.re_url, '$1<a href="$2" target="_blank" rel="nofollow">$2</a>');
    }

    /**
     * convert a domain (doesn't start with http://) to link
     * @param {String} str - the text that contains domain
     * @return {String} string with converted domains
     */
    domain(str) {
        if (!_.isString(str)) {
            return str;
        }
        return str.replace(this.re_domain, '$1<a href="http://$2" target="_blank" rel="nofollow">$2</a>');
    }
}

StringToLinkService.$inject = [];

export default StringToLinkService;
