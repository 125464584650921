import moment from 'moment';
import CompanyListCardTemplate from './company-list-card.component.html';
import './company-list-card.component.scss';

/**
 * @ngdoc component
 * @name CompanyListCardComponent
 * @module portfolium.component.company
 * @param {Object} companies companies to show
 * @param {boolean} fetching whether is or not fetching data
 * @param {number} limit number of companies to show
 * @module portfolium.component.job
 **/
export const CompanyListCardComponent = {
    bindings: {
        'companies': '<',
        'fetching': '<',
        'limit': '<',
    },
    template: CompanyListCardTemplate,
    controllerAs: '$ctrl'
};
