import {
    SETTINGS_LOCATIONS_REQUEST,
    SETTINGS_LOCATIONS_ADD,
    SETTINGS_UPDATE_LOCATION,
    SETTINGS_UPDATE_LOCATIONS,
    SETTINGS_REMOVE_LOCATION,
    SETTINGS_LOCATIONS_MAKE_LOCATION_CURRENT,
    SETTINGS_ORDER_LOCATIONS
} from './settings-location.actions';

export const settingsLocations = (state = {}, action = {}) => {

    switch (action.type) {
        case SETTINGS_LOCATIONS_REQUEST:
            return _.assign({}, state, {
                loading: true
            });
        case SETTINGS_LOCATIONS_ADD:
            return _.assign({}, state, {
                locations: [...state.locations,
                        action.payload.location]
            });
        case SETTINGS_UPDATE_LOCATION:
            return _.assign({}, state, {
                locations: [...(_.filter(state.locations,(location) => {
                            return location.id !== action.payload.location.id;
                        })),
                        action.payload.location]
            });
        case SETTINGS_UPDATE_LOCATIONS:
            return _.assign({}, state, {
                loading: false,
                locations: action.payload.locations
            });
        case SETTINGS_REMOVE_LOCATION:
            return _.assign({}, state, {
                locations: _.filter(state.locations,(location) => {
                    return location.id !== action.payload.locationId;
                })
            });
        case SETTINGS_ORDER_LOCATIONS:
            return _.assign({}, state, {
                locations: _.orderBy(state.locations,
                    ['current','premise_number','thoroughfare_name'],
                    ['desc','asc','asc']
                )
            });
        case SETTINGS_LOCATIONS_MAKE_LOCATION_CURRENT:
            return _.assign({}, state, {
                locations: state.locations.map((location)=>{
                    let isCurrent = '0';
                    if (location.id === action.payload.locationId) {
                        isCurrent = '1';
                    }

                    return _.assign({}, location, {
                        current: isCurrent
                    })
                })
            });
        default:
            return state;
    }
};
