import ContentReportingModalTemplate from './content-reporting-modal.component.html';

/**
 * @ngdoc controller
 * @module portfolium.component
 * @name ContentReportingModalController
 **/
class ContentReportingModalController {
    constructor(
        $q,
        $state,
        $pfToast,
        $pfUserBlocking,
        $pfContentReporting,
        PF_CONTENT_REPORTING_STEPS
    ) {
        this.$q = $q;
        this.$state = $state;
        this.$pfToast = $pfToast;
        this.$pfUserBlocking = $pfUserBlocking;
        this.$pfContentReporting = $pfContentReporting;
        this.PF_CONTENT_REPORTING_STEPS = PF_CONTENT_REPORTING_STEPS;
    }

    $onInit() {
        // default loading
        this.loading = false;
        // default first step
        this.currentStep = this.PF_CONTENT_REPORTING_STEPS.report;
        // default aria label
        this.ariaLabel = this.header;
        // default blocked user redirect
        this.redirectToProfile = false;
    }

    $onDestroy() {
        if (this.redirectToProfile) {
            // turn this on when blocked page is built
            this.$state.reload();
        }
    }

    /**
     * Checks reporting service for profile report type
     * @returns {Boolean}
     */
    isProfileReportType() {
        return this.$pfContentReporting.isProfileReportType(this.reportTypeData.type);
    }

    /**
     * Determine if the passed in step is the current step
     * @param {String} step
     * @returns {Boolean}
     */
    isCurrentStep(step) {
        return this.currentStep === step;
    }

    /**
     * Handle loading state, setup and go to the next step of the report flow
     */
    goToNextStep() {
        // stop loading
        this.loading = false;
        // set next step to feedback
        this.currentStep = this.PF_CONTENT_REPORTING_STEPS.feedback;
        // set up the feedback aria label
        this.ariaLabel = 'Content reported, you can submit additional feedback here.';
    }

    /**
     * Send off the report, maybe block some fool, then switch to the next step
     * @param {Object} formData
     * @param {String} formData.reason
     * @param {Boolean=} formData.blockUser
     * @returns {Promise}
     */
    handleContentReport({
        reason,
        blockUser: block_user = false
    }) {
        // start loading spinner, this disables the button
        this.loading = true;
        // get the report type
        const { type } = this.reportTypeData;
        /**
         * if this is a profile report and they decided to
         * block the user, flip the redirect flag
         */
        this.redirectToProfile = this._shouldRedirectToProfile(block_user);
        // send the report
        return this.$pfContentReporting
            .sendContentReport(
                this.contentData,
                {
                    type,
                    reason,
                }, {
                    block_user
                }
            ).then((response) => {
                // pull out content violation ID
                const { data: { id: contentViolationId } } = response;
                // store contentViolationId so we can submit additional feedback later
                this.contentViolationId = contentViolationId;
                // resolve it
                return this.$q.resolve(response)
            }, (error) => {
                // handle it
                return this._handleApiError(error);
            }).then((response) => {
                // go to next step
                this.goToNextStep();
                // set success message
                const successMessage = block_user ? 'Report submitted and user blocked' : 'Report submitted';
                // notify the fools
                this.$pfToast.success(successMessage);
                // resolve it
                return this.$q.resolve(response);
            });
    }

    /**
     * Send additional feedback for the reported content
     * @param {String} feedback
     * @returns {Promise}
     */
    handleAdditionalFeedback(feedback) {
        // send additional feedback
        return this.$pfContentReporting
            .sendAdditionalFeedback(this.contentViolationId, feedback)
            .then((response) => {
                // close it
                this.onCancel();
                // toast it
                this.$pfToast.success('Feedback submitted');
                // resolve it
                return this.$q.resolve(response);
            }, (error) => {
                // handle it
                return this._handleApiError(error);
            });
    }

    /**
     * Perform API error actions
     * @param {Object} error Error response
     * @returns {Promise}
     */
    _handleApiError(error) {
        // stop all the downloading!
        this.loading = false;
        // notify the fools
        this.$pfToast.error('Oops, something went wrong');
        // reject it
        return this.$q.reject(error);
    }

    /**
     * Determines if we should go through the user blocking flow
     * @param {Boolean} blockUser value of the "Block this user" checkbox
     * @returns {Boolean}
     */
    _shouldRedirectToProfile(blockUser) {
        if (!this.isProfileReportType()) {
            return false;
        }

        if (_.isNil(blockUser)) {
            return false;
        }

        if (!_.isBoolean(blockUser)) {
            return false;
        }

        return blockUser;
    }
}

ContentReportingModalController.$inject = [
    '$q',
    '$state',
    '$pfToast',
    '$pfUserBlocking',
    '$pfContentReporting',
    'PF_CONTENT_REPORTING_STEPS'
];

export const ContentReportingModalComponent = {
    bindings: {
        header: '@',
        contentData: '<',
        reportTypeData: '<',
        onCancel: '&',
    },
    template: ContentReportingModalTemplate,
    controller: ContentReportingModalController,
    controllerAs: '$ctrl',
    bindToController: true
};
