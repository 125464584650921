const jobLimits = {
    mobile: {
        initial: 4,
        total: 8,
    },
    tablet: {
        initial: 6,
        total: 12,
    },
    desktop: {
        initial: 8,
        total: 16,
    },
    total: 16
};

const companyLimits = {
    mobile: {
        initial: 4,
        total: 8,
    },
    tablet: {
        initial: 4,
        total: 8,
    },
    desktop: {
        initial: 6,
        total: 12,
    },
    total: 12
};

export const suggestionsLimits = {
    jobLimits,
    companyLimits,
};
