export const BREAKPOINTS =  {
    'sm': 600,
    'md': 960,
    'lg': 1280,
    'xl': 1920,
    'handsetPortraitSm': 360,
    'handsetPortraitMd': 400,
    'handsetPortraitLg': 480,
    'handsetLandscapeSm': 600,
    'handsetLandscapeMd': 720,
    'tabletPortraitLg': 720,
    'tabletLandscapeLg': 1024,
};
