class ProfileStrengthTipsController
{
    constructor($scope, $pfGameEngine, $pfGameEngineAction, $pfEventTracker) {
        this.$pfGameEngineAction = $pfGameEngineAction;
        this.$pfEventTracker = $pfEventTracker;

        // Watch game engine results object for changes, then update actions list
        $scope.$watch(() => {
            return $pfGameEngine.results;
        }, (newVal) => {
            this.gameEngine = newVal;
            this.actions = this.getActions();
        });
    }

    /**
     * @description Build the list of actions based on game engine recommendations
     * @return {Array} Collection of action objects
     */
    getActions() {
        let limit = this.limit || 3;

        if (!this.gameEngine || !this.gameEngine.current_level) {
            return;
        }

        const requiredActions = [];
        let bonusActions = [];
        const nextLevelName = (this.gameEngine.next_level) ? this.gameEngine.next_level.name : null;
        const suggestions = (nextLevelName) ? _.filter(this.gameEngine.suggestions[nextLevelName], {complete: false}) : [];
        const bonuses = _.filter(this.gameEngine.suggestions.bonus, {complete: false});

        // Fetch required actions for current level
        _.forEach(suggestions, (suggestion) => {
            const action = this.$pfGameEngineAction.getActionByName(suggestion.name);

            if (!action) {
                return;
            }

            action.type = suggestion.type;

            requiredActions.push(action);
        });

        // Fetch bonus actions
        _.forEach(bonuses, (suggestion) => {
            const action = this.$pfGameEngineAction.getActionByName(suggestion.name);
            const dupeAction = _.find(requiredActions, {name: suggestion.name});

            if (!action || dupeAction) {
                return;
            }

            action.type = suggestion.type;
            action.weight = suggestion.weight;

            bonusActions.push(action);
        });

        // Order bonus actions by weight desc
        bonusActions = _.orderBy(bonusActions, ['weight'], ['desc']);

        // Append bonus actions to required actions and return
        return requiredActions.concat(bonusActions).splice(0, limit);
    }

    /**
     * @description Wrapper method for executing an action
     * @param  {Object}  action Action object
     * @return {Promise}        Promise resolved when action's execute method is completed
     */
    executeAction(action) {
        // Track event
        this.$pfEventTracker.track('Clicked Suggestion Link', {
            actionName: action.name,
            currentLevel: this.gameEngine.current_level.name,
            currentPoints: this.gameEngine.points
        });

        return action.execute();
    }
}

ProfileStrengthTipsController.$inject = ['$scope', '$pfGameEngine', '$pfGameEngineAction', '$pfEventTracker'];

function profileStrengthTipsDirective() {
    return {
        restrict: 'E',
        scope: true,
        bindToController: true,
        link: function (scope, elem, attrs, tipsCtrl) {
            let limit = parseInt(scope.$eval(attrs.pfTipLimit), 10) || null;

            tipsCtrl.limit = limit;
        },
        controller: ProfileStrengthTipsController,
        controllerAs: 'tipsCtrl',
        template: `
            <div class="pf-profile-strength-tips" ng-if="::tipsCtrl.gameEngine && tipsCtrl.actions.length">
                <h3 class="pf-body">{{ "Profile Strength Tips:" | i18n }}</h3>
                <ul>
                    <li ng-repeat="action in tipsCtrl.actions track by action.name">
                        <a href ng-click="tipsCtrl.executeAction(action)">{{::action.label | i18n}}</a>
                    </li>
                </ul>
            </div>
        `
    };
}

export default profileStrengthTipsDirective;
