export const JOB_TYPES = [
    {
        id: '1',
        name: '',
        displayName: 'All'
    },
    {
        id: '2',
        name: 'fulltime',
        displayName: 'Full-time'
    },
    {
        id: '3',
        name: 'parttime',
        displayName: 'Part-time'
    },
    {
        id: '4',
        name: 'internship',
        displayName: 'Internship'
    },
    {
        id: '5',
        name: 'coop',
        displayName: 'Co-op'
    },
    {
        id: '6',
        name: 'unknown',
        displayName: 'Unknown'
    }
];
