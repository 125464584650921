export const companyJobsActionTypes = {
    'PAGE_INIT': 'COMPANY_JOBS_PAGE_INIT',
    'PAGE_NEXT': 'COMPANY_JOBS_PAGE_NEXT',
    'PAGE_PREVIOUS': 'COMPANY_JOBS_PAGE_PREVIOUS',
    'PAGE_REQUEST': 'COMPANY_JOBS_PAGE_REQUEST',
    'PAGE_SUCCESS': 'COMPANY_JOBS_PAGE_SUCCESS',
    'PAGE_ERROR': 'COMPANY_JOBS_PAGE_ERROR',
    'TOGGLE_SELECTION': 'COMPANY_JOBS_TOGGLE_SELECTION',
    'CLEAR_SELECTION': 'COMPANY_JOBS_CLEAR_SELECTION',
    'TOGGLE_ALL': 'COMPANY_JOBS_TOGGLE_ALL',
};
