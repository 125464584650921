import RecaptchaTemplate from './recaptcha.component.html';

/**
 * @ngdoc RecaptchaController
 * @name RecaptchaController
 * @module portfolium.components.recaptcha
 **/
class RecaptchaController {
    /**
     *
     * @param vcRecaptchaService {vcRecaptchaService}
     * @param $pfEnv {EnvironmentService}
     * @param $pfFeatures {FeatureSwitchService}
     * @param PF_FEATURE_SWITCHES {array:FEATURE_SWITCHES}
     */
    constructor(vcRecaptchaService, $pfEnv, $pfFeatures, PF_FEATURE_SWITCHES) {
        this.vcRecaptchaService = vcRecaptchaService;
        this.$pfEnv = $pfEnv;
        this.$pfFeatures = $pfFeatures;
        this.PF_FEATURE_SWITCHES = PF_FEATURE_SWITCHES;
    }

    get recaptchaKey() {
        return this.$pfEnv.getEnv('PF_RECAPTCHA_CLIENT_ID');
    }

    get isEnabled() {
        return !this.$pfFeatures.isOn(this.PF_FEATURE_SWITCHES.DISABLE_RECAPTCHA);
    }

    $onChanges(changes) {
        /**
         * Reload the captcha if flag returns true and the captcha exists (known by widgetId)
         */
        if (changes.shouldResetCaptcha.currentValue && !_.isNil(this.widgetId)) {
            this.vcRecaptchaService.reload(this.widgetId);
        }
    }

    onCreate(widgetId) {
        this.widgetId = widgetId;
    }

    handleChange(token = null) {
        this.onChange({token});
    }
}

RecaptchaController.$inject = ['vcRecaptchaService', '$pfEnv', '$pfFeatures', 'PF_FEATURE_SWITCHES'];

/**
 * @ngdoc component
 * @name RecaptchaComponent
 * @module portfolium.components.recaptcha
 * @description recaptcha
 */
export const RecaptchaComponent = {
    bindings: {
        onChange: '&',
        shouldResetCaptcha: '<',
    },
    require: {
        form: '^form'
    },
    controller: RecaptchaController,
    controllerAs: '$ctrl',
    template: RecaptchaTemplate,

};
