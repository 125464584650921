import AccomplishmentTemplate from '../../template/form/accomplishment.html';
import AthleticTemplate from '../../template/form/athletic.html';
import BadgeTemplate from '../../template/form/badge.html';
import CertificationTemplate from '../../template/form/certification.html';
import ClubTemplate from '../../template/form/club.html';
import CourseTemplate from '../../template/form/course.html';
import EducationTemplate from '../../template/form/education.html';
import ExperienceTemplate from '../../template/form/experience.html';
import IntroductionTemplate from '../../template/form/introduction.html';
import PublicationTemplate from '../../template/form/publication.html';
import VolunteerTemplate from '../../template/form/volunteer.html';

export const templateCache = ($templateCache) => {
    // Profile about panel forms
    $templateCache.put('profile-about-panel/template/form/accomplishment.html', AccomplishmentTemplate);
    $templateCache.put('profile-about-panel/template/form/athletic.html', AthleticTemplate);
    $templateCache.put('profile-about-panel/template/form/badge.html', BadgeTemplate);
    $templateCache.put('profile-about-panel/template/form/certification.html', CertificationTemplate);
    $templateCache.put('profile-about-panel/template/form/club.html', ClubTemplate);
    $templateCache.put('profile-about-panel/template/form/course.html', CourseTemplate);
    $templateCache.put('profile-about-panel/template/form/education.html', EducationTemplate);
    $templateCache.put('profile-about-panel/template/form/experience.html', ExperienceTemplate);
    $templateCache.put('profile-about-panel/template/form/introduction.html', IntroductionTemplate);
    $templateCache.put('profile-about-panel/template/form/publication.html', PublicationTemplate);
    $templateCache.put('profile-about-panel/template/form/volunteer.html', VolunteerTemplate);
};

templateCache.$inject = ['$templateCache'];
