import angular from 'angular';

import jobCardDirective from './js/job-card-directive';

import StandardTemplate from './template/card/standard.html'
import ListTemplate from './template/card/list.html'
import FeedTemplate from './template/card/feed.html'
import './job-card.module.scss';

const MODULE_NAME = 'portfolium.component.job.jobCard';

(function() {
    let app = angular.module(MODULE_NAME, []);
    // Directives
    app.directive('pfJobCard', jobCardDirective);

    // Templates
    app.run([
        '$templateCache',
        $templateCache => {
            $templateCache.put(
                'template/components/job-card/template/card/standard.html',
                StandardTemplate,
            );
            $templateCache.put(
                'template/components/job-card/template/card/feed.html',
                FeedTemplate,
            );
            $templateCache.put(
                'template/components/job-card/template/card/list.html',
                ListTemplate,
            );
        },
    ]);
})();

export default MODULE_NAME;
