export const SETTINGS_IMPORT_DATA_CANCEL_IMPORT = 'SETTINGS_IMPORT_DATA_CANCEL_IMPORT';
export const SETTINGS_IMPORT_DATA_NEXT_STEP = 'SETTINGS_IMPORT_DATA_NEXT_STEP';
export const SETTINGS_IMPORT_DATA_SET_IMPORT_SOURCES = 'SETTINGS_IMPORT_DATA_SET_IMPORT_SOURCES';
export const SETTINGS_IMPORT_DATA_SET_SELECTED_DATA = 'SETTINGS_IMPORT_DATA_SET_SELECTED_DATA';
export const SETTINGS_IMPORT_DATA_SET_SELECTED_SOURCE = 'SETTINGS_IMPORT_DATA_SET_SELECTED_SOURCE';

/**
 * Cancel the current import, reset back to step 1
 */
export const cancelImport = () => {
    return {
        type: SETTINGS_IMPORT_DATA_CANCEL_IMPORT,
    };
};

/**
 * Move to the next step of the wizard
 */
export const nextStep = () => {
    return {
        type: SETTINGS_IMPORT_DATA_NEXT_STEP,
    };
};

/**
 * Store the user's selection for an import source/data type
 * @param {ImportSourceDataType} type Type of data being imported for selected source
 * @param {any[]} selected Raw data being selected
 */
export const setSelectedData = (type = {}, selected = []) => {
    return {
        type: SETTINGS_IMPORT_DATA_SET_SELECTED_DATA,
        payload: {
            type,
            selected,
        },
    };
};

/**
 * Set the supported import source list
 * @param {ImportSource[]} sources Supported import sources
 */
export const setSupportedImportSources = (sources = []) => {
    return {
        type: SETTINGS_IMPORT_DATA_SET_IMPORT_SOURCES,
        payload: {
            sources,
        },
    };
};

/**
 * Select an import source
 * @param {ImportSource} source Selected import source
 */
export const setSelectedSource = (source = {}) => {
    return {
        type: SETTINGS_IMPORT_DATA_SET_SELECTED_SOURCE,
        payload: {
            source,
        },
    };
};
