export const SETTINGS_TM_NETWORK_REQUEST      = 'SETTINGS_TM_NETWORK_REQUEST';
export const SETTINGS_TM_NETWORK_UPDATE       = 'SETTINGS_TM_NETWORK_UPDATE';
export const SETTINGS_TM_SUBSCRIPTION_REQUEST = 'SETTINGS_TM_SUBSCRIPTION_REQUEST';
export const SETTINGS_TM_SUBSCRIPTION_UPDATE  = 'SETTINGS_TM_SUBSCRIPTION_UPDATE';
export const SETTINGS_SET_TRIAL_STATUS        = 'SETTINGS_SET_TRIAL_STATUS';
export const SETTINGS_SET_TRIAL_UNTIL         = 'SETTINGS_SET_TRIAL_UNTIL';

export const settingsTMNetworkRequest = () => {
    return {
        type: SETTINGS_TM_NETWORK_REQUEST,
    };
};

export const settingsTMNetworksUpdate = (networks) => {
    return {
        type: SETTINGS_TM_NETWORK_UPDATE,
        payload: {
            networks: networks
        }
    };
};

export const settingsTMSubscriptionRequest = () => {
    return {
        type: SETTINGS_TM_SUBSCRIPTION_REQUEST,
    };
};

export const settingsTMSubscriptionUpdate = (subscription) => {
    return {
        type: SETTINGS_TM_SUBSCRIPTION_UPDATE,
        payload: {
            subscription: subscription
        }
    };
};

export const settingsTMTrialStatusUpdate = (trialStatus) => {
    return {
        type: SETTINGS_SET_TRIAL_STATUS,
        payload: {
            trialStatus: trialStatus
        }
    };
};

export const settingsTMTrialUntilUpdate = (trialUntil) => {
    return {
        type: SETTINGS_SET_TRIAL_UNTIL,
        payload: {
            trialUntil: trialUntil
        }
    };
};
