import { MIMETYPES_CONFIG } from '../services/filestack/mimetypes-config.constant.js';

/**
 * Entry (Project) classifications
 * AKA in the API: [
 *     user created project
 *     user created assignment
 *     user created requirement
 * ]
 */
const ENTRY_CLASSIFICATIONS = {
    default: 'default',
    assignment: 'assignment',
    requirement: 'requirement',
};

const ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES = {
    default: _.omit(MIMETYPES_CONFIG, ['video']),
    assignment: MIMETYPES_CONFIG,
    requirement: MIMETYPES_CONFIG,
};

export { ENTRY_CLASSIFICATIONS, ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES };
