import angular from 'angular';
import FooterNavTemplate from '../../template/footer-nav.html';

class FooterNavbarController {
    constructor($pfUser, $pfEnv) {
        this.currentUser = $pfUser.getUser();
        this.$pfEnv = $pfEnv;
    }
}

FooterNavbarController.$inject = ['$pfUser', '$pfEnv'];

export const footerNavbarDirective = ($pfUser, $pfSubscription) => {
    return {
        restrict: 'E',
        controller: FooterNavbarController,
        controllerAs: 'footerNavCtrl',
        link: function (scope, elem) {
            const $body = angular.element(document.body);
            this.currentUser = $pfUser.getUser();
            const user = $pfUser.getUser();

            if (!user) {
                // Make sure only authed users see the navbar
                elem.remove();
            } else {
                $body.addClass('pf-has-footer-navbar');
            }

            scope.$on('$destroy', () => {
                $body.removeClass('pf-has-footer-navbar');
            });
        },
        template: function () {
            // get the current user
            const user = $pfUser.getUser();

            if (user) {
                // check if the user is a company admin to show a different mobile footer
                if (!$pfSubscription.isSubscriber()) {
                    return FooterNavTemplate;
                }
            }
        }
    };
};

footerNavbarDirective.$inject = ['$pfUser', '$pfSubscription'];
