function userConnectCardDirective($templateCache, imageFilter) {
    let templateBase = 'template/components/user-connect/template/card';

    return {
        restrict: 'E',
        scope: {
            'profile': '=pfProfile'
        },
        bindToController: true,
        link: function (scope, elem, attrs) {
            let {connectCtrl} = scope;

            connectCtrl.avatar = imageFilter(connectCtrl.profile.avatar.url_https, 192, 192);
            connectCtrl.coverImage = imageFilter(connectCtrl.profile.cover.url_https, 144, 256);

            elem.attr('tabindex', -1);
        },
        controller: ['$pfUser', '$pfSubscription', '$stateParams', '$pfAuth', function ($pfUser, $pfSubscription, $stateParams, $pfAuth) {
            this.user = $pfUser.getUser();

            this.isEdit = function () {
                return $pfAuth.isAuthenticatedUserByUsername($stateParams.username);
            };

            this.isAuthedUser = function () {
                return this.user && this.user.id === this.profile.id;
            };

            this.isSubscriber = function () {
                return $pfSubscription.isSubscriber();
            };

            // If non-subscriber and authed, see all connection buttons. If Subscriber, see only those connection buttons on your profile connections page
            this.showConnectionButton = function () {
                if ((!this.isAuthedUser() && !this.isSubscriber()) || (this.isSubscriber() && this.isEdit())) {
                    return true;
                }
                return false;
            };

            /**
              * @description checks for default cover image
              * @return {Void||Boolean}
              */
            this.isDefaultCoverImage = function(cover) {
                return (!_.isEmpty(cover)) && _.isEmpty(cover.source);
            }

            /**
              * @description checks for and returns a background-image object
              * @return {Void||Object} Default image returns empty object
              */
            this.getCoverImage = function(cover) {
                if (this.isDefaultCoverImage(cover)) {
                    return {};
                }

                return {
                    'background-image': `url(${cover.url_https})`,
                };
            }
        }],
        controllerAs: 'connectCtrl',
        template: function (elem, attrs) {
            let template = $templateCache.get(`${templateBase}/standard.html`);

            if (_.isString(attrs.pfCardType)) {
                template = $templateCache.get(`${templateBase}/${attrs.pfCardType}.html`);
            }

            if (!template) {
                throw new Error('[pfUserConnectCard] Template not found!');
            }

            return template;
        }
    };
}

userConnectCardDirective.$inject = ['$templateCache', 'imageFilter'];

export default userConnectCardDirective;
