import dynamicImage from 'components/dynamic-image';

/**
 * Build template to display user avatar, name, and school
 * @param  {string} result Result object containing user data
 * @return {string}
 */
function connection(result) {
    // jQuery objects for building template markup
    let userSearchElement = $('<div class="user">'),
        userAvatar = $('<img height="40" width="40" class="avatar small">'),
        br = $('<br>');

    // Add user avatar
    if (result.avatar && result.avatar.dynamic_https) {
        userAvatar.attr(
            'src',
            dynamicImage(result.avatar.dynamic_https, 'crop', '80x80'),
        );
        userSearchElement.append(userAvatar);
    }
    // Add user data
    userSearchElement.append(result.firstname + ' ' + result.lastname);
    userSearchElement.append(br);
    userSearchElement.append(result.school || '&nbsp;');

    // Return template
    return userSearchElement[0].outerHTML;
}

export default {
    connection: connection,
};
