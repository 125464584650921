import angular from 'angular';

import { EDU } from '../constant/environments.js';

class EnvironmentService {
    constructor($window) {
        this._environment = $window.Portfolium.environment;
        this._portfolium = $window.Portfolium
    }

    /**
     * Get the current environment name
     * This is set to the value of the server environment variable: PF_ENVIRONMENT
     * We set ENVIRONMENT to PF_ENVIRONMENT in public/index.php
     * then, window.Portfolium.environment is set to ENVIRONMENT in
     * application/views/elements/utility/js/top.php
     * @return {String} Environment name
     */
    get name() {
        if (!_.isString(this._environment.PF_ENVIRONMENT)) {
            return '';
        }

        return this._environment.PF_ENVIRONMENT;
    }

    /**
     * Get the current environments EDU url
     * @return {String} Current Environment url
     */
    get eduUrl() {
        // get the environment variable (sent through PHP)
        // this will be set in cloudgate environment, and eventually in local dev environment
        const { PF_EDU_URL } = this._environment;
        if (PF_EDU_URL) {
            return PF_EDU_URL;
        }

        if (__QA__ && this.name === 'demo') {
            return EDU.demo;
        }

        if (__PROD__) {
            return EDU.production;
        }

        return EDU.local;
    }

    /**
     * Get the current environment url
     * @return {String} Environment URL
     */
    get url() {
        let url = angular.copy(this._environment.PF_BASE_URL);

        if (!_.isString(url)) {
            return '';
        }

        if (url.substr(-1) === '/') {
            // Strip trailing slash
            url = url.substr(0, url.length - 1);
        }

        return url;
    }

    /**
     * Get the current environment domain
     * @return {String} Environment domain
     */
    get domain() {
        let url = angular.copy(this._environment.PF_BASE_URL);

        if (!_.isString(url)) {
            return '';
        }

        if (url.substr(-1) === '/') {
            // Strip trailing slash
            url = url.substr(0, url.length - 1);
        }

        // strip http(s)://
        url = url.replace(/https?:\/\//, '');

        return url;
    }

    /**
     * Is the current environment production?
     * @return {Boolean}
     */
    get isProduction() {
        return this.name === 'production';
    }

    /**
     * Is the current environment non-production?
     * @return {Boolean}
     */
    get isNonProduction() {
        return this.name !== 'production';
    }

    /**
     * Is the current environment dev?
     * @return {Boolean}
     */
    get isDev() {
        return !this.isProduction && !this.isQa && !this.isLocal;
    }

    /**
     * Is the current environment qa?
     * @return {Boolean}
     */
    get isQa() {
        return this.name === 'qa';
    }

    /**
     * Is the current environment local?
     * @return {Boolean}
     */
    get isLocal() {
        return this.name === 'local';
    }

    /**
     * Is the cloudgate environment?
     * @return {Boolean}
     */
    get isCloudgate() {
        // the APP_DOMAIN env var is only set in cloudgate
        return !!this._environment.APP_DOMAIN && !this.isLocal;
    }

    /**
     * Get the current assets environment url
     * @return {String} Assets Environment URL
     */
    get assetsUrl() {
        const url = this.getEnv('PF_ASSETS_URL');

        if (_.isNil(url)) {
            console.error('Invalid assets URL');
        }

        return url;
    }

    /**
     * Get the specified environment variable
     * @return {String|null} Environment variable name
     */
    getEnv(name) {
        const envVar = this._environment[name];
        if (_.isEmpty(envVar) || _.isNil(envVar)) {
            return null;
        }

        return envVar;
    }

    getRegion() {
        return this.getEnv('AWS_REGION');
    }

    /**
     * Evaluate if we should display jobs menu items in UI
     * @returns {boolean}
     */
    jobsEnabled() {
        if (!_.isEmpty(this._portfolium)) {
            const { current_user } = this._portfolium;
            if (!_.isEmpty(current_user)) {
                return !current_user.disable_jobs
            }
        }

        return true;
    }

    /**
     * Check if the user is in a walled garden network
     * @returns {boolean}
     */
    inWalledGarden() {
        if (!_.isEmpty(this._portfolium)) {
            const { current_user } = this._portfolium;
            if (!_.isEmpty(current_user)) {
                return !!current_user.in_walled_garden
            }
        }

        return false;
    }

    /**
     * Is the specified environment variable set?
     * @return {Boolean}
     */
    isEnvSet(name) {
        return !!this._environment[name];
    }

    /**
     * Check for valid domain of passed in url
     * @param {String} url
     * @return {String}
     */
    isValidDomain(url) {
        if (_.isNil(url)) {
            return false;
        }
        const { hostname } = new URL(url);
        return hostname === this.domain;
    }
}

EnvironmentService.$inject = ['$window'];

export default EnvironmentService;
