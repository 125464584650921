import angular from 'angular';

class RegisterController {
    constructor(
        $scope,
        $state,
        $pfAuth,
        $pfSignInIdentity,
        $pfSignInAuth,
        $pfToast,
        $pfPreviousState,
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$pfAuth = $pfAuth;
        this.$pfSignInIdentity = $pfSignInIdentity;
        this.$pfSignInAuth = $pfSignInAuth;
        this.$pfToast = $pfToast;
        this.$pfPreviousState = $pfPreviousState;
        // Preset model value if it was passed in
        this.email = this.$pfSignInIdentity.isEmail($state.params.identity)
            ? angular.copy($state.params.identity)
            : null;
        // Check state params to see if identity should be cleared out
        if ($state.params.clear === true) {
            this.$pfSignInIdentity.clearIdentity();
            this.email = null;
        }
        // Setup email listener
        $scope.$watch('registerCtrl.email', this.handleEmailChange.bind(this));

        // Recaptcha init, copied over from client/auth-pages/forgot-password/forgot-password.component.js
        // default recaptcha token
        this.recaptchaToken = null;
        // default reset captcha flag
        this.resetCaptcha = true;
    }

    get isValid() {
        return this.$scope.signInCtrl.isValid();
    }

    get isExistingUser() {
        return this.$pfSignInIdentity.isExistingUser;
    }

    get isUserOnboarded() {
        return this.$pfSignInIdentity.isUserOnboarded;
    }

    get isPrepoppedNetwork() {
        return this.$pfSignInIdentity.isPrepoppedNetwork;
    }

    get isEmployer() {
        return this.$pfSignInIdentity.isEmployer;
    }

    get isRedirect() {
        let { state: previous } = this.$pfPreviousState.get();

        let redirected =
            previous === 'loginModal.identity' || previous === 'login.identity';
        return redirected;
    }

    /**
     * Clear out the network and profile data when user changes email field
     * @param  {String} newVal New email value
     * @param  {String} oldVal Old email value
     */
    handleEmailChange(newVal, oldVal) {
        if (newVal !== oldVal) {
            this.$pfSignInIdentity.clearIdentity();
        }
    }

    /**
     * Verify a user's email address and then resend their account onboarding email
     * @param  {String} email The users email
     * @return {Promise}
     */
    sendEmail(email) {
        if (!this.isValid) {
            return;
        }

        this.loading = true;

        return this.$pfAuth
            .getProfileByIndentity(email)
            .then(
                response => {
                    let profile = response.data;

                    if (response.status === 204) {
                        return this.$scope.signInCtrl.next(
                            'register.resendFailure',
                        );
                    }

                    return this.resendOnboardingEmail(profile.id);
                },
                response => {
                    this.$pfToast.error(
                        'Something went wrong, please try again.',
                    );
                },
            )
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * Resend a user's account onboarding email
     * @param  {Int} id A user's account id
     * @return {Promise}
     */
    resendOnboardingEmail(id = null) {
        let userId = id ? id : this.$pfSignInIdentity.profile.id,
            networkId = this.$pfSignInIdentity.network.id;

        this.loading = true;

        return this.$pfAuth
            .resendOnboardingEmail(userId, networkId)
            .then(
                () => {
                    return this.$scope.signInCtrl.next(
                        'register.resendSuccess',
                    );
                },
                response => {
                    if (response.status === 403) {
                        this.$pfToast.error(response.data.error);

                        return;
                    }

                    this.$pfToast.error(
                        'Something went wrong, please try again.',
                    );
                },
            )
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * Route to the enter your school email page
     */
    showResendEmail() {
        this.$scope.signInCtrl.next('register.resendEmail');
    }

    /**
     * Route to the confirm new account page
     */
    showConfirm() {
        this.$scope.signInCtrl.next('register.confirm', null, false);
    }

    /**
     * Transition to the forgot password page, without validating the form
     * @return {Promise}
     */
    showForgotPassword() {
        return this.$scope.signInCtrl.next('login.forgotPassword', null, false);
    }

    /**
     * Route to the confirm new account page
     */
    createExisting() {
        this.$scope.registerCtrl.showCaptcha(() => {
            let { email } = this.$scope.registerCtrl;
            let type = this.$pfSignInAuth.PF_ACCOUNT_TYPES.unknown.name;

            this.loading = true;

            return this.$pfSignInAuth.createAccount(email, true, type, this.recaptchaToken)
                .finally(() => {
                    this.loading = false;
                });
        });
    }

    setRecaptchaToken({ token }) {
        this.recaptchaToken = token;
    }

    showCaptcha(action) {
        this.captchaAction = action;
        this.$scope.signInCtrl.next('register.captcha', { action }, false);
    }

    runCaptchaAction(captchaToken) {
        if (!this.isValid) {
            return;
        }

        this.captchaAction();
    }
}

RegisterController.$inject = [
    '$scope',
    '$state',
    '$pfAuth',
    '$pfSignInIdentity',
    '$pfSignInAuth',
    '$pfToast',
    '$pfPreviousState',
];

export default RegisterController;
