/**
 * If we get a 401 or 403 - token expired error from api, log user out
 */
export const authInterceptor = (
    $q,
    $window,
    $location,
    $pfCookies,
    $pfEnv,
    PF_COOKIES,
    PF_API_ERRORS,
) => {
    return {
        'responseError': (rejection) => {
            // make sure we have a valid error from the api
            if (!_.isObject(rejection.data) || (_.isEmpty(rejection.data.error))) {
                return $q.reject(rejection);
            }

            // see if the error matches the predefined error codes/messages
            if (PF_API_ERRORS[rejection.status] === rejection.data.error) {
                const url = $location.url();
                // Set the return url cookie
                $pfCookies.put(
                    PF_COOKIES.RETURN_URL,
                    url,
                    {
                        path: '/',
                        domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                    }
                );

                $window.location.href = '/logout/retry';
            }
            return $q.reject(rejection);
        },
    };
}

authInterceptor.$inject = [
    '$q',
    '$window',
    '$location',
    '$pfCookies',
    '$pfEnv',
    'PF_COOKIES',
    'PF_API_ERRORS',
];
