import uuid from 'node-uuid';
import utilities from 'components/utilities';

/**
 * @ngdoc service
 * @module portfolium.entryEditor.entryAttachments
 * @name $pfEntryAttachment
 * @description Utilities for the attachments
 */
export class EntryAttachmentService {
    constructor(
        $pfEnv,
        PF_ENTRY_ATTACHMENTS_TYPES,
        PF_MIMETYPES_CONFIG,
        PF_ENTRY_CLASSIFICATIONS,
        PF_ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES,
    ) {
        this.$pfEnv = $pfEnv;
        this.PF_ENTRY_ATTACHMENTS_TYPES = PF_ENTRY_ATTACHMENTS_TYPES;
        this.PF_MIMETYPES_CONFIG = PF_MIMETYPES_CONFIG;
        this.PF_ENTRY_CLASSIFICATIONS = PF_ENTRY_CLASSIFICATIONS;
        this.PF_ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES = PF_ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES;
    }

    /**
     * Creates a copy of the draft attachment before editing them
     * @param  {Array}  attachments Draft attachments
     * @return {Array}
     */
    createAttachmentsCopy(attachments = []) {
        const tempAttachments = attachments.map(attachment => {
            const _id = uuid.v4();
            return _.assign({}, attachment, { _id, loading: false });
        });
        return tempAttachments;
    }

    /**
     * Generates a valid EntryAttachment type 'website' with meta as WebsiteAttachmentMeta
     * following the contract https://github.com/portfolium/web/wiki/Entry-UX:--Attachments-Contract
     * @param  {object} meta website attachment information
     * @param  {string} meta.title title of the attachment
     * @param  {string} meta.url url of the attachment
     * @param  {string} meta.description description of the attachment
     * @param  {string} meta.site_name site name, comes from the API
     * @param  {string} meta.domain site domain name
     * @param  {object} filestackImages data sent from the filestack API to
     *                            create a image file type object
     * @param  {object} filestackImages.siteImage site preview image data
     * @param  {object} filestackImages.iconImage site icon data
     * @return {object}
     */
    createWebsiteAttachmentFromOgTagData(
        {
            domain,
            site_name,
            title,
            url,
            type = this.PF_ENTRY_ATTACHMENTS_TYPES.WEBSITE.name,
            description,
        },
        filestackImages = {}
    ) {
        let preview = null;
        if (_.has(filestackImages, 'siteImage')) {
            preview = this.createImageFromMeta(filestackImages.siteImage);
        }

        let favicon = null;
        let cdnUrl = this.$pfEnv.getEnv('PF_CDN_URL');
        if (_.has(filestackImages, 'iconImage.key') && _.isString(cdnUrl)) {
            favicon = cdnUrl.concat(`/${filestackImages.iconImage.key}`);
        }
        // build domain object
        const metaDomain = {
            domain,
            name: site_name || null,
            favicon,
        };
        return {
            type,
            title: title || url,
            caption: description || null,
            meta: {
                source_url: url,
                preview,
                domain: metaDomain,
            },
        };
    }

    /**
     * Map filestack metadata into an EntryAttachment object with DocumentAttachmentMeta
     * as per the data contract: https://github.com/portfolium/web/wiki/Entry-UX:--Attachments-Contract
     * If `previewFilestackMeta` is provided, include a preview image on the object
     *
     * @param {object} filestackMeta Metadata returned by the Filestack API/SDK
     * @returns {object} Formatted document attachment object
     * @memberof EntryAttachmentService
     */
    createDocumentAttachmentFromMeta(filestackMeta, previewFilestackMeta = null) {
        // need this
        let preview = null;
        // create the file here
        const file = this.createFileFromMeta(filestackMeta);
        // sanity check
        if (previewFilestackMeta) {
            // create an image from the meta here
            preview = this.createImageFromMeta(previewFilestackMeta);
        }
        // return here
        return {
            type: this.PF_ENTRY_ATTACHMENTS_TYPES.DOCUMENT.name,
            title: filestackMeta.filename,
            caption: null,
            meta: {
                file,
                preview,
            },
        };
    }

    /**
     * Map filestack metadata into a File object, as per the data contract:
     * https://github.com/portfolium/web/wiki/Entry-UX:--Attachments-Contract
     *
     * @param {object} filestackMeta Metadata returned by the Filestack API/SDK
     * @returns {object} Formatted file object
     * @memberof EntryAttachmentService
     */
    createFileFromMeta(filestackMeta) {
        // return
        return {
            filename: filestackMeta.filename,
            filestack: {
                handle: filestackMeta.handle,
                key: filestackMeta.key,
            },
            mimetype: filestackMeta.mimetype,
            size: filestackMeta.size,
            url: filestackMeta.url,
            clean_url: filestackMeta.url,
            security: filestackMeta.security,
        };
    }

    /**
     * Create a new EntryAttachment object from mapping filestack
     * cropFile metadata into an EntryAttachment object with ImageAttachmentMeta
     * as per the data contract: https://github.com/portfolium/web/wiki/Entry-UX:--Attachments-Contract
     *
     * @param {object} attachment [description]
     * @param {object} filestackMeta Metadata returned by the Filestack API/SDK
     * @returns {object} Formatted attachment object
     * @memberof EntryAttachmentService
     */
    updateAttachmentImageFromCrop(attachment, filestackMeta) {
        // create the image from the meta
        const image = this.createImageFromMeta(filestackMeta);
        // get the meta here and assign
        const meta = _.assign({}, attachment.meta, {image});
        // return here ...
        return _.assign({}, attachment, {meta});
    }

    /**
     * Map filestack metadata into an Image object, as per the data contract:
     * https://github.com/portfolium/web/wiki/Entry-UX:--Attachments-Contract
     *
     * @param {object} filestackMeta Metadata returned by the Filestack API/SDK
     * @returns {object} Formatted image object
     * @memberof EntryAttachmentService
     */
    createImageFromMeta(filestackMeta) {
        // get the file from the meta
        const file = this.createFileFromMeta(filestackMeta);
        // return
        return {
            height: null,
            width: null,
            file,
        };
    }

    /**
     * Map filestack metadata into an EntryAttachment object with ImageAttachmentMeta,
     * as per the data contract: https://github.com/portfolium/web/wiki/Entry-UX:--Attachments-Contract
     *
     * @param {object} filestackMeta Metadata returned by the Filestack API/SDK
     * @returns {object} Formatted image attachment object
     * @memberof EntryAttachmentService
     */
    createImageAttachmentFromMeta(filestackMeta) {
        const image = this.createImageFromMeta(filestackMeta);

        return {
            type: this.PF_ENTRY_ATTACHMENTS_TYPES.IMAGE.name,
            title: filestackMeta.filename,
            caption: null,
            meta: {
                alt_text: '',
                image,
            },
        };
    }

    /**
     * Remove the extra temporary properties set in the attachment objects
     * @param  {object[]} attachments List of entry attachments with temp data
     * @return {object[]} cleaned attachments list
     */
    cleanAttachments(attachments) {
        // Dont include attachments that are loading
        const filteredAttachments = _.filter(
                attachments,
                attachment => !attachment.loading
            );
        // Clean attachments withouth extra attributes
        const cleanedAttachmentsAttachments = filteredAttachments
            .map(attachment => {
                return _.omit(
                    attachment,
                    [
                        '_id',
                        'loading',
                        'percentage',
                    ],
                );
            });

        // Return attachments withouth extra attributes
        // and guarantee uniqueness
        return _.uniqWith(cleanedAttachmentsAttachments, this.guaranteeUniqueness);
    }

    /**
     *
     * @param  {object} arrVal attachment
     * @param  {object} othVal attachment
     * @return {boolean}
     */
    guaranteeUniqueness(arrVal, othVal) {
        return _.isEqual(
            _.omit(arrVal.meta, ['preview']),
            _.omit(othVal.meta, ['preview'])
        );
    }

    /**
     * Checks if a url exists in the current list
     *     of attachments.
     * @param  {string}   url   url
     * @param  {object[]} attachments attachments
     * @return {boolean}
     */
    hasUrlAlreadyBeenAdded(url, attachments) {
        const foundUrl = _.find(attachments,
            attachment => {
                const {
                    meta,
                    type,
                } = attachment;

                if (type === this.PF_ENTRY_ATTACHMENTS_TYPES.WEBSITE.name) {
                    if (_.isEmpty(meta)) {
                        return false;
                    }
                    const {source_url} = meta;
                    if (_.isEmpty(source_url)) {
                        return false;
                    }
                    return source_url === url;
                }
                return false;
            });
        return !_.isEmpty(foundUrl);
    }

    /**
     * Get allowed file types by entry classification
     * @param {string} entryClassification
     */
    getAllowedFileMimetypesByEntryClassification(
        entryClassification = this.PF_ENTRY_CLASSIFICATIONS.default,
    ) {
        return Object.keys(this.PF_ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES[entryClassification]);
    }
}

EntryAttachmentService.$inject = [
    '$pfEnv',
    'PF_ENTRY_ATTACHMENTS_TYPES',
    'PF_MIMETYPES_CONFIG',
    'PF_ENTRY_CLASSIFICATIONS',
    'PF_ENTRY_CLASSIFICATIONS_ALLOWED_FILE_TYPES',
];
