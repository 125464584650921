/**
 * @ngdoc directive
 * @name pfJobApplyPreAuth
 * @module portfolium.component.jobApply
 * @restrict A
 * @description
 * Handle clicks on the job apply button before the pf-require-auth directive
 */
export const jobApplyPreAuthDirective = (
    $pfUser,
    $pfJobApply,
    $pfCookies,
    PF_COOKIES,
) => {
    return {
        restrict: 'A',
        // Make sure this runs before the require auth directive
        priority: -200,
        require: '^pfJobApplyBtn',
        link: (scope, elem, attrs, ctrl) => {
            const user = $pfUser.getUser();
            const {job} = ctrl;

            const targetJobId = $pfCookies.get(PF_COOKIES.EXTERNAL_JOB_SEEKER);
            const campaignTargetMet = targetJobId === job.id;

            // the user is already logged in
            if (user) {
                return;
            }

            // only proceed for sourced and internal jobs
            if (!ctrl.isSourced() && !ctrl.isExternal()) {
                return;
            }

            // Only add event listener for unauthed users from an external referrer
            elem.on('click', ($event) => {
                // Block default event
                $event.preventDefault();
                // Stop click event from bubbling up
                $event.stopImmediatePropagation();

                ctrl.applyToJob($event, campaignTargetMet);
            });
        },
    };
};

jobApplyPreAuthDirective.$inject = [
    '$pfUser',
    '$pfJobApply',
    '$pfCookies',
    'PF_COOKIES',
];
