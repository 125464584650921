import AttachmentOembedTemplate from './attachment-oembed.component.html';

/**
 * @ngdoc controller
 * @name AttachmentOembedController
 * @module portfolium.component.mediaSlider
 **/
class AttachmentOembedController {
    constructor(
        $pfEmbeddedUrls,
        $sce,
    ) {
        this.$pfEmbeddedUrls = $pfEmbeddedUrls;
        this.$sce = $sce;
    }

    $onInit() {
        this.validateUrl();
    }

    /**
     * Determine if the attachment is a valid oembed
     *
     * @return {object<sce>|undefined}
     * @memberof AttachmentOembedController
     */
    validateUrl() {
        // sanity check
        if (!_.has(this.attachment, 'meta.oembed.embed')) {
            return;
        }
        this.embedUrl = this.$sce.trustAsResourceUrl(this.attachment.meta.oembed.embed);
    }

}

AttachmentOembedController.$inject = [
    '$pfEmbeddedUrls',
    '$sce',
];

/**
 * @ngdoc component
 * @name AttachmentOembedComponent
 * @module portfolium.component.mediaSlider
 * @description Attachment slides in media slider from url for iframe oembeds
 *
 * @param {object}   pfAttachment The attachment object
 **/
export const AttachmentOembedComponent = {
    require: {
        attachmentViewerCtrl: '^pfAttachmentViewer',
    },
    bindings: {
        attachment: '<pfAttachment',
    },
    controller: AttachmentOembedController,
    controllerAs: '$ctrl',
    template: AttachmentOembedTemplate,
};
