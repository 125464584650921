import angular from 'angular';

import {AuthProviderProvider} from './auth-provider.provider';
import {authProviders} from './auth-providers.config';

const MODULE_NAME = 'portfolium.auth.authProviders';

(function () {
    angular.module(MODULE_NAME,[])
    // Services
    .provider('$pfAuthProvider', AuthProviderProvider)
    // Config
    .config(authProviders);
}());

export default MODULE_NAME;
