import PmailValidationTemplate from './pmail-validation.html';
import './pmail-validation.scss';

import {
    PMAIL_CENTER_PANEL_STEPS,
    togglePmailCenterPanelStep,
    removePmailRecipient,
} from '../pmail-center.action';

class PmailValidationController {
    constructor(
        $ngRedux,
        $pfSubscriptionUpsell,
    ) {
        this._$ngRedux = $ngRedux;
        this._$pfSubscriptionUpsell = $pfSubscriptionUpsell;
    }

    $onChanges(changes) {
        const recipients = changes.validRecipients.currentValue;

        if (recipients.length < 1 && !this.showBlockedRecipients) {
            // Close modal if all recipients are deleted
            this.close();
        }
    }

    /**
     * @description Should low pmail section be shown
     * @return {Boolean}
     */
    showLowPmail() {
        return this.pmail.remaining < 50;
    }

    showValidRecipients() {
        return !!this.validRecipients.length;
    }

    /**
     * @description Should blocked recipients section be shown
     * @return {Boolean}
     */
    get showBlockedRecipients() {
        return !!this.blockedRecipients.length;
    }

    /**
     * @description Pluralize the passed in string
     * @param {String} singularString
     * @param {Integer} count Number of singularStrings
     */
    pluralize(singularString, count) {
        if (count === 1) {
            return singularString;
        } else {
            return singularString + 's';
        }
    }

    /**
     * @description Determine if subscriber has enough pmail to send currently selected messages
     * @return {Boolean} Is pmail valid
     */
    isPmailValid() {
        return this.requiredPmail <= parseInt(this.pmail.remaining);
    }

    /**
    * @description Can the subscriber continue to composer view
    * @return {Boolean}
    */
    canContinue() {
        if (this.validRecipients.length > 0) {
            return true;
        }

        return false;
    }

    /**
     * @description Open upsell modal
     */
    openModal() {
        this._$pfSubscriptionUpsell.openSchoolsModal();
    }

    /**
     * @description Remove selected recipient from recipient list
     * @param {Integer} id Recipient ID
     */
    removeRecipient(id) {
        this._$ngRedux.dispatch(removePmailRecipient(id));
    }

    /**
     * @description Validate and continue to pmail composer
     */
    nextStep() {
        // diapatch composer view
        this._$ngRedux.dispatch(togglePmailCenterPanelStep(PMAIL_CENTER_PANEL_STEPS.COMPOSER));
    }

    /**
     * @description Close the panel
     * @return {Promise} Resolved when panel is closed
     */
    close() {
        return this.panelCtrl.close();
    }
}

PmailValidationController.$inject = [
    '$ngRedux',
    '$pfSubscriptionUpsell',
];

/**
 * @ngdoc function
 * @name PmailValidation
 * @description Shows pmail cost and allows subscriber to reduce recipients. Shows blocked (out of network) recipients
 */
export const PmailValidationComponent = {
    require: {
        'panelCtrl': '^pfPmailCenterPanel',
    },
    bindings: {
        'validRecipients': '<',
        'blockedRecipients': '<',
        'pmail': '<',
        'requiredPmail': '<',
    },
    template: PmailValidationTemplate,
    controller: PmailValidationController,
};
