import {PHRASES_TAG_TEAMMATES, SKILLS_TAG_TEAMMATES} from '../phrases/tag-teammates';

class TagTeammatesService
{
    constructor() {
        this.phrases = {
            PHRASES_TAG_TEAMMATES: PHRASES_TAG_TEAMMATES,
            SKILLS_TAG_TEAMMATES: SKILLS_TAG_TEAMMATES,
        };
    }

    /**
     * Check for matches in the text with the strings in the phrases array
     * @param   {String|Array}      text            The text that will be matches against the phrases; can be a string or array of strings
     * @param   {String|Array}      phrases         Either an array of strings or regular expressions, a string that corresponds to
     *                                              a property in this.phrases, or a string/regex to match with the text param
     * @return  {Boolean}
     */
    match(text, phrases) {
        let hasMatch = false;
        let texts = text;

        if (!(_.isString(text) || _.isArray(text)) || !(_.isString(phrases) || _.isArray(phrases))) {
            return hasMatch;
        }

        if (_.isString(text)) {
            // convert to array
            texts = [text];
        }

        if (_.isString(phrases)) {
            // does 'phrases' map to a property in this.phrases?
            if (this.phrases.hasOwnProperty(phrases)) {
                phrases = this.phrases[phrases];
            } else {
                // convert the string to an array
                phrases = [phrases];
            }
        }

        let re = new RegExp(_.compact(phrases).join('|'), 'gim');

        _.each(texts, (text) => {
            if (!_.isString(text)) {
                // not a string, exit forEach loop
                return;
            }
            let matched = text.match(re);
            if (matched !== null) {
                // we have a match!
                hasMatch = true;
                // exit the forEach loop
                return false;
            }
        });

        return hasMatch;
    }
}

export default TagTeammatesService;
