import {
    SETTINGS_BLOCKED_ACCOUNTS_FETCH,
    SETTINGS_BLOCKED_ACCOUNTS_UPDATE_USER_LIST,
    SETTINGS_BLOCKED_ACCOUNTS_UNBLOCK_USER,
    SETTINGS_BLOCKED_ACCOUNTS_SET_PENDING_CONNECTION,
} from './settings-blocked-accounts.actions';

let defaultState = {
    userList: [],
    loading: false,
};

export const settingsBlockedAccounts = (
    state = defaultState,
    action = {}
) => {
    switch (action.type) {
        case SETTINGS_BLOCKED_ACCOUNTS_FETCH:
            return _.assign({}, state, {
                loading: true
            });
        case SETTINGS_BLOCKED_ACCOUNTS_UPDATE_USER_LIST:
            return _.assign({}, state, {
                loading: false,
                userList: action.payload.userList
            });
        case SETTINGS_BLOCKED_ACCOUNTS_UNBLOCK_USER:
            return _.assign({}, state, {
                userList: _.map(state.userList, (user) => {
                    if (user.id === action.payload.userId) {
                        return _.assign({}, user, { blocked: false });
                    }
                    return user;
                })
            });
        case SETTINGS_BLOCKED_ACCOUNTS_SET_PENDING_CONNECTION:
            return _.assign({}, state, {
                userList: _.map(state.userList, (user) => {
                    if (user.id === action.payload.userId) {
                        return _.assign({}, user, { pendingConnection: true });
                    }
                    return user;
                })
            });
        default:
            return state;
    }
};
