import angular from 'angular';
import MediaSliderTemplate from '../../template/media-slider.html';

class MediaSliderController {
    constructor(
        $element,
        $timeout,
        $pfUser,
        $pfEntryEditor,
        $pfAttachmentViewer,
        $pfAttachmentMedia,
        $pfFilestackSigner,
        PF_ENTRY_ATTACHMENTS_TYPES,
        PF_ATTACHMENT_VIEWER_TYPES,
    ) {
        this.$element = $element;
        this.$timeout = $timeout;
        this.$pfUser = $pfUser;
        this.$pfEntryEditor = $pfEntryEditor;
        this.edpSliderActive = true; // this is false when fullscreen is open
        this.edpSlider = 'slick.pf-media-slider__slider';
        this.edpSliderFullscreen = 'slick.pf-media-slider__slider--fullscreen';
        this.resumeVideo = false;
        this.slickSlider = null;
        this.slickConfigLoaded = false;
        this.slideCount = 0;
        this.slickCurrentIndex = 0;
        this.isSlickEnabled = true;
        this.imageLoading = false;
        this.canShowToolbar = undefined;
        this.$pfAttachmentViewer = $pfAttachmentViewer;
        this.$pfAttachmentMedia = $pfAttachmentMedia;
        this.$pfFilestackSigner = $pfFilestackSigner;
        this.PF_ENTRY_ATTACHMENTS_TYPES = PF_ENTRY_ATTACHMENTS_TYPES;
        this.PF_ATTACHMENT_VIEWER_TYPES = PF_ATTACHMENT_VIEWER_TYPES;
        // this.slidesWithMedia is used to identify each media api and the slide it belongs to,
        // so we know which api to call, depending on which slide is showing
        this.slidesWithMedia = {};
        this.slickConfig = {
            dots: false,
            lazyLoad: 'ondemand',
            accessibility: false,
            autoplay: false,
            infinite: true,
            autoplaySpeed: 4000,
            focusOnSelect: true,
            pauseOnFocus: false,
            arrows: false,
            swipe: !this.isMobile,
            draggable: !this.isMobile,
            method: {},
            event: {
                init: (event, slick) => {
                    this.slickSlider = slick;
                    // Toggle download button
                    this.setCurrentSlideAttachmentData(slick.currentSlide);
                    this.slideCount = slick.slideCount;

                    // first image starts loading here
                    this.imageLoading = this.canShowLoading(slick, slick.currentSlide);

                    // register keyboard bindings for slider
                    this.remoteControl(angular.element(this.edpSlider));

                    // show toolbar if needed
                    this.showToolbar();
                },
                afterChange: (event, slick, currentSlide, nextSlide) => {
                    this.slickCurrentIndex = currentSlide;
                    // Toggle download button
                    this.setCurrentSlideAttachmentData(slick.currentSlide);
                    // resume autoSlide, if needed
                    const autoplay = slick.slickGetOption('autoplay');
                    if (this.edpSliderActive && slick.paused && autoplay) {
                        slick.play();
                    }
                },
                beforeChange: (event, slick, currentSlide, nextSlide) => {
                    // show progress indicator, if needed
                    this.imageLoading = this.canShowLoading(slick, nextSlide);
                },
                lazyLoaded: (event, slick, image, imageSource) => {
                    this.imageLoading = false;
                },
            }
        };
    }

    $onInit() {
        this.ownerFirstname = _.get(this.entry, 'profile.firstname');
        this.currentAttachment = {
            url: null,
            filename: null,
            mimetype: null,
        }
    }

    $onChanges(changed) {
        if (changed.entry && !_.isEmpty(this.entry)) {
            this.$timeout(() => {
                this.slickConfigLoaded = true;
            });
        }
    }

    getScrollSelector() {
        return this.isMobile ? 'md-content.md-portfolium-theme' : '#pf-entry-viewer-container';
    }

    // /////////////////////////////////////////////////////////////////////////////
    // VIDEO METHODS
    // /////////////////////////////////////////////////////////////////////////////

    /**
     * @description Register the slides with embedded media IDs so we know which slides
     *              have playable media. These IDs will be used to reference the media
     *              apis in the $pfAttachmentMedia service
     * @param  {Object}  elementId The embedded media element id
     * @return {Void}
     */
    registerEmbeddedMediaId(elementId) {
        let slideIndex = null;

        // find the slide by elementId
        const slide = this.$element.find(`[pf-media-id="${elementId}"]`);
        if (slide.length > 0) {
            slideIndex = slide.attr('data-slick-index');
        }
        if (slideIndex) {
            this.slidesWithMedia = this.slidesWithMedia || {};

            slideIndex = parseInt(slideIndex);
            this.slidesWithMedia[slideIndex] = elementId;
        }
    }

    /**
     * @description play the video
     * @param  {Integer} slideIndex The slide index; used to get the corresponding api from slidesWithMedia
     * @param  {Object}  slidesWithMedia The object that holds the APIs for corresponding slides
     * @return {Void}
     */
    playVideo(slideIndex, slidesWithMedia) {
        if (!_.isUndefined(slideIndex) && _.isObject(slidesWithMedia)) {
            const mediaId = slidesWithMedia[slideIndex];
            this.$pfAttachmentMedia.play(mediaId);
        }
    }

    /**
     * @description stop the currently playing video
     * @return {Void}
     */
    stopVideo() {
        this.$pfAttachmentMedia.stop();
    }

    /**
     * @description seek the video
     * @param  {Integer} slideIndex The slide index; used to get the corresponding api from slidesWithMedia
     * @param  {Object}  slidesWithMedia The object that holds the APIs for corresponding slides
     * @param  {Integer}  seconds The time in seconds to seek to
     * @param  {Boolean}  andPlay The flag to indicate whether the video should play
     * @return {Void}
     */
    seekVideo(slideIndex, slidesWithMedia, seconds, andPlay) {
        if (!_.isUndefined(slideIndex) && _.isObject(slidesWithMedia)) {
            const mediaId = slidesWithMedia[slideIndex];
            this.$pfAttachmentMedia.setCurrentTime(mediaId, seconds, andPlay);
        }
    }

    /**
     * @description get the current time on the video
     * @param  {Integer} slideIndex The slide index; used to get the corresponding api from slidesWithMedia
     * @param  {Object}  slidesWithMedia The object that holds the APIs for corresponding slides
     * @param  {Function}  callback The callback will be passed the seconds parameter
     * @return {Void}
     */
    getTimeFromVideo(slideIndex, slidesWithMedia, callback) {
        if (!_.isUndefined(slideIndex) && _.isObject(slidesWithMedia)) {
            const mediaId = slidesWithMedia[slideIndex];
            callback(this.$pfAttachmentMedia.getCurrentTime(mediaId));
        }
    }

    /**
     * @description is the video currently playing
     * @param  {Integer} slideIndex The slide index; used to get the corresponding api from slidesWithMedia
     * @param  {Object}  slidesWithMedia The object that holds the APIs for corresponding slides
     * @param  {Function}  callback The callback will be passed a boolean parameter
     * @return {Void}
     */
    isPlayingVideo(slideIndex, slidesWithMedia, callback) {
        if (!_.isUndefined(slideIndex) && _.isObject(slidesWithMedia)) {
            const mediaId = slidesWithMedia[slideIndex];
            callback(this.$pfAttachmentMedia.isPlaying(mediaId));
        }
    }

    /**
     * @description callback for when the video is paused
     * @param  {Object}   slickConfig The slick slider config
     * @param  {Boolean}  isSlickEnabled Is the slider enabled?
     * @return {Void}
     */
    onVideoPause(slickConfig, isSlickEnabled) {
        if (slickConfig && slickConfig.autoplay && isSlickEnabled) {
            slickConfig.method.slickPlay();
        }
    }

    /**
     * @description callback for when the video starts playing
     * @param  {Object}   slickConfig The slick slider config
     * @param  {Boolean}  isSlickEnabled Is the slider enabled?
     * @return {Void}
     */
    onVideoPlay(slickConfig, isSlickEnabled) {
        if (slickConfig && isSlickEnabled) {
            slickConfig.method.slickPause();
        }
    }

    // /////////////////////////////////////////////////////////////////////////////
    // END - VIDEO METHODS
    // /////////////////////////////////////////////////////////////////////////////

    // /////////////////////////////////////////////////////////////////////////////
    // ENTRY UTILITIES
    // /////////////////////////////////////////////////////////////////////////////

    /**
     * @description Is the user looking at their own entry
     * @return {Boolean}
     */
    isMyEntry() {
        const user = this.$pfUser.getUser();

        if (!user) {
            return false;
        }

        return this.entry.fk_user_id === user.id;
    }

    /**
     * Is this an assignment
     * @return {Boolean}
     */
    isAssignment() {
        if (_.isNil(this.entry)) {
            return;
        }
        return this.entry.faculty_assigned === '1';
    }

    /**
     * @description Redirect to the entry wizard
     * @return {Promise}
     */
    editEntry() {
        return this.$pfEntryEditor.editEntry(this.entry.id, {
            source: 'edp',
        });
    }

    /**
     * @description Determine if this entry has any supported media to show in the slider
     * @return {Boolean}
     */
    hasSupportedMedia() {
        if (_.isEmpty(this.entry.attachments)) {
            return false;
        }

        const supported = _.filter(this.entry.attachments, (attachment) => {
            const type = this.$pfAttachmentViewer.getAttachmentType(attachment);
            return this.attachmentType !== this.PF_ATTACHMENT_VIEWER_TYPES.UNSUPPORTED.name;
        });

        return supported.length > 0;
    }

    // /////////////////////////////////////////////////////////////////////////////
    // END - ENTRY UTILITIES
    // /////////////////////////////////////////////////////////////////////////////

    // /////////////////////////////////////////////////////////////////////////////
    // SLIDER CONTROLS
    // /////////////////////////////////////////////////////////////////////////////

    /**
     * @description Can we show the fullscreen button
     * @return {Boolean}
     */
    canShowFullscreenButton() {
        return !this.isMobile;
    }

    /**
     * @description Determine if the media can be downloaded
     * @return {Boolean}
     */
    canDownload() {
        // can't download on mobile
        if (this.isMobile) {
            return false;
        }

        // is current url set?
        return !_.isEmpty(this.currentAttachment.url);
    }

    /**
     * @description Can we show the toolbar
     * @return {Boolean}
     */
    showToolbar() {
        // don't show if there are no attachments
        if (_.isEmpty(this.entry.attachments)) {
            this.canShowToolbar = false;
            return;
        }

        // don't show if there are no supported attachments
        if (!this.hasSupportedMedia()) {
            this.canShowToolbar = false;
            return;
        }

        if (this.isMobile && this.slideCount <= 1) {
            this.canShowToolbar = false;
            return;
        }

        this.canShowToolbar = true;
    }

    /**
     * @description Get the download url for the attachment, if the type permits it
     * @param  {Object} attachment the attachment object
     * @return {String} The download URL of the attachment
     */
    getCurrentAttachmentData(attachment) {
        return JSON.stringify({
            url: this.$pfAttachmentViewer.getSupportedUrl(attachment),
            mimetype: this.$pfAttachmentViewer.getMimeType(attachment),
        });
    }

    /**
     * @description Get the current slide URL. We look at each slide for a `pf-attachment-data` attribute
     * @param {Integer}  currentSlide   Current slide
     * @return {String}  url            Url
     */
    getCurrentSlideAttachmentData(currentSlide) {
        const slide = this.slickSlider.$slides.get(currentSlide);
        if (!slide) {
            return null;
        }
        const attachmentData = angular.element(slide).attr('pf-attachment-data');
        if (_.isNil(attachmentData)) {
            return null;
        }

        return JSON.parse(attachmentData);
    }

    /**
     * @description Set the current slide attachment data. If attachment url is set,
     *              it enables the download button
     * @param {Integer}  currentSlide   Current slide
     */
    setCurrentSlideAttachmentData(currentSlide) {
        if (!this.hasSupportedMedia()) {
            return this.currentAttachment = {
                url: null,
                filename: null,
                mimetype: null,
            };
        }
        // Get attachment data of current slide
        let {
            url,
            mimetype
        } = this.getCurrentSlideAttachmentData(currentSlide);
        // default filename
        let filename = null;
        // sanity check for valid url
        if (url && url.match(/https?/i)) {
            // get the filename
            let parts = url.split('/');
            // get filename and remove the hash that's added by the server
            filename = parts[parts.length - 1].replace(/^[a-z0-9]*_/i, '');
        } else {
            url = null;
        }

        // set the current attachment
        this.currentAttachment = {
            url,
            filename,
            mimetype,
        }
        // can we download the attachment?
        if (this.canDownload() && !this.$pfFilestackSigner.isSignedUrl(url)) {
            // get the read policy here and put into the url
            this.$pfFilestackSigner.getReadPolicy(url).then(security => {
                // set the url
                this.currentAttachment.url = this.$pfFilestackSigner.signUrl(url, security);
            });
        }
    }

    /**
     * @description Go to the previous slide in the media slider
     * @param {Object}  slickConfig   Media slider config object
     * @return {Void}
     */
    previousSlide(slickConfig) {
        // stop video if playing
        this.stopVideo(this.slickCurrentIndex, this.slidesWithMedia);

        slickConfig.method.slickPrev();
    }

    /**
     * @description Go to the next slide in the media slider
     * @param {Object}  slickConfig   Media slider config object
     * @return {Void}
     */
    nextSlide(slickConfig) {
        // stop video if playing
        this.stopVideo(this.slickCurrentIndex, this.slidesWithMedia);

        slickConfig.method.slickNext();
    }

    /**
     * @description Show loading indicator on image slides that are lazy loaded
     * @param {Object}  slick   Media slider object
     * @param {Integer} index   Current slide index
     * @return {Boolean}
     */
    canShowLoading(slick, index) {
        const slide = angular.element(slick.$slides.get(index)).find('img');

        if (slide.length > 0) {
            // we can show the loading indicator if the data-lazy attribute exists
            // slick slider removes data-lazy and adds src attribute when it's loaded
            return !_.isUndefined(slide.attr('data-lazy'));
        }

        return false;
    }

    /**
     * @description bind keyboard right/left keys to control slider
     * @param  {Object}  slider The slider
     * @param  {Boolean}  off If true, remove the binding
     * @return {Void}
     */
    remoteControl(slider, off) {
        const controller = (e) => {
            if (e.target && e.target.type === 'text') {
                return;
            }
            switch (e.keyCode) {
                case 13:
                case 32:
                    // return and space keys
                    // allow enter/space to trigger buttons
                    if (e.target.localName === 'button' || e.target.localName === 'a') {
                        break;
                    }

                    const slidesWithMedia = (this.edpSliderActive) ? this.slidesWithMedia : this.fullscreenSlidesWithMedia;

                    this.isPlayingVideo(this.slickCurrentIndex, slidesWithMedia, (playing) => {
                        if (playing) {
                            this.stopVideo(this.slickCurrentIndex, slidesWithMedia);
                        } else {
                            this.playVideo(this.slickCurrentIndex, slidesWithMedia);
                        }
                    });

                    break;
                case 37:
                    // left arrow
                    this.previousSlide(this.slickConfig);
                    break;
                case 39:
                    // right arrow
                    this.nextSlide(this.slickConfig);
                    break;
            }
        };

        if (slider && !off) {
            $('body').on('keydown.edp', controller);
        } else {
            $('body').off('keydown.edp');
        }
    }

    // /////////////////////////////////////////////////////////////////////////////
    // END - SLIDER CONTROLS
    // /////////////////////////////////////////////////////////////////////////////

    /**
     * get template for info popover
     * @return {string}
     */
    getPopoverTemplate() {
        return `<pf-media-slider-caption-popover
                     attachment="$ctrl.content">
                </pf-media-slider-caption-popover>`;
    }

    /**
     * get current attachment
     * @return {void|object}
     */
    getCurrentAttachment() {
        // sanity check for entry
        if (_.isNil(this.entry)) {
            return;
        }
        // sanity check for attachments
        if (_.isNil(this.entry.attachments)) {
            return;
        }
        // return current attachment
        return this.entry.attachments[this.slickCurrentIndex];
    }
}

MediaSliderController.$inject = [
    '$element',
    '$timeout',
    '$pfUser',
    '$pfEntryEditor',
    '$pfAttachmentViewer',
    '$pfAttachmentMedia',
    '$pfFilestackSigner',
    'PF_ENTRY_ATTACHMENTS_TYPES',
    'PF_ATTACHMENT_VIEWER_TYPES',
];

export const MediaSliderComponent = {
    bindings: {
        'entry': '<pfEntry',
        'isMobile': '<pfIsMobile',
        'isEditable': '<'
    },
    template: MediaSliderTemplate,
    controller: MediaSliderController,
    controllerAs: '$mediaCtrl',
    bindToController: true
};
