import 'arrive';
import Shepherd from 'tether-shepherd';

class ProductTourService {
    constructor($q, $timeout, $transitions) {
        this.$q = $q;
        this.$timeout = $timeout;
        this.$transitions = $transitions;
    }

    singleStepTour(target, options = {}) {
        // Promisify the return value
        const deferred = this.$q.defer();
        // Create the tour instance with default params
        const tour = new Shepherd.Tour({
            defaults: {
                scrollTo: false,
            },
        });

        // Add a single step to the tour with user defined params
        tour.addStep({
            id: 1,
            text: options.content,
            classes: `shepherd-theme-arrows ${options.classes}`,
            attachTo: {
                element: target,
                on: options.position,
            },
            buttons: [{
                text: 'Got it',
                action: tour.next,
            }],
            tetherOptions: {
                constraints: [{
                    pin: options.pin === true,
                    to: 'scrollParent',
                }],
            },
        });

        // Make sure to hide the tour when changing states
        const watcher = this.$transitions.onStart({}, () => {
            tour.hide();
        });

        // Clean up bindings and wathers when the tour is finished
        tour.on('complete', () => {
            $(document).unbindArrive(target);
            watcher();
            if (options.advanceOn && options.advanceOn.event) {
                $(document).off(options.advanceOn.event, options.advanceOn.target);
            }
            deferred.resolve();
        });

        // Allow for an outside event to dismiss the tour step
        if (options.advanceOn && options.advanceOn.event) {
            $(document).on(options.advanceOn.event, options.advanceOn.target, () => {
                tour.next();
            });
        }

        // Watch the DOM until the target element is rendered, then start the tour
        $(document).arrive(target, () => {
            // Allow for a user-defined delay
            const delay = this.$timeout(() => true, options.delay || 0);
            // Make sure to hide the tour if the element is removed from the DOM
            $(target).on('$destroy', () => {
                tour.hide();
            });
            // Start the tour!
            delay.then(() => {
                tour.start();
            });
        });

        return deferred.promise;
    }
}

ProductTourService.$inject = ['$q', '$timeout', '$transitions'];

export default ProductTourService;
