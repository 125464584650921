/**
 * Clear all cached social auth data when the user logs out. Prevents expired or
 * revoked access tokens from being used to login, and helps prevent session
 * related security issues.
 */
function socialAuthClearDirective($pfSocialAuth) {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.on('click', () => {
                $pfSocialAuth.clearLocalData();
            });
        }
    };
}

socialAuthClearDirective.$inject = ['$pfSocialAuth'];

export default socialAuthClearDirective;
