export class ExportProfileService {
    constructor($http, $window) {
        this.$http = $http;
        this.$window = $window;
    }

    /**
     * @name exportToPdf
     * @description Send export data to CI controller for input into flashdata, then download PDF
     * @param  {String} objective Users about/objectve
     * @param  {String} entryIds Selected entry Ids to be included in PDF
     * @return {Promise<Array>}
     */
    exportToPdf(objective, entryIds) {
        return this.$http({
            method: 'POST',
            url: `/user/export`,
            data: {
                objective: objective,
                entry_ids: entryIds,
            }
        }).then(() => {
            // Download PDF
            this.$window.location.href = '/user/generate_pdf';
        });
    }

    /**
     * @name saveAsPdf
     * @description Download full profile PDF
     * @param  {String} objective Users about/objectve
     * @param  {String} entryIds Selected entry Ids to be included in PDF
     * @return {Promise<Array>}
     */
    saveAsPdf(username = null) {
        if (!_.isEmpty(username)) {
            this.$window.location.href = `/${username}/save_pdf`;
        } else {
            this.$window.location.href = '/user/save_pdf';
        }
    }
}

ExportProfileService.$inject = ['$http', '$window'];
