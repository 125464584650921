import angular from 'angular';

/**
 * @module portfolium.component.resume
 * @name $pfResumeUpload
 * @description
 * Expose functionality to handle resume upload throughout the app
 **/
export class ResumeUploadService
{
    constructor(
        $q,
        $mdDialog,
        $pfUser,
        $pfProfile,
        $pfToast,
        $pfFilestackPreset,
        $pfFilestackSigner,
        $pfFilestackConvert,
    ) {
        this.$q = $q;
        this.$mdDialog = $mdDialog;
        this.$pfUser = $pfUser;
        this.$pfProfile = $pfProfile;
        this.$pfToast = $pfToast;
        this.$pfFilestackPreset = $pfFilestackPreset;
        this.$pfFilestackConvert = $pfFilestackConvert;
        this.$pfFilestackSigner = $pfFilestackSigner;
        this.presetName = 'resume';
    }

    /**
     * Open the upload resume dialog
     * @param  {Event}    $event        ngClick event
     * @param  {Boolean}  checkResume   If true, show a toast instead of modal if the user already has a resume
     * @param  {Function} onSuccess     The function to call when the modal is closing
     * @return {Promise}            mdDialog promise
     */
    openDialog($event, checkResume, onSuccess) {
        const user = this.$pfUser.getUser();

        const dialog = {
            parent: angular.element(document.body),
            bindToController: true,
            controller: angular.noop,
            controllerAs: '$ctrl',
            targetEvent: $event,
            focusOnOpen: false,
            multiple: true,
            fullscreen: this.$pfUser.isMobile(),
            locals: {
                onSuccess: onSuccess,
            },
            onComplete: () => {
                // since we have no button to focus on, set focusOnOpen to false and manually do it to the window
                // see: https://github.com/angular/material/issues/1190
                angular.element('md-dialog').focus();
            },
            template: `
                <md-dialog class="pf-dialog pf-dialog--white pf-dialog--resume-upload"
                     aria-label="Upload Resume">
                    <pf-resume-upload-modal pf-on-success="$ctrl.onSuccess()"></pf-resume-upload-modal>
                </md-dialog>
            `,
        };

        // if the user already has a resume and checkResume is true
        if (checkResume && user.resume) {
            return this.$pfToast.success("You've already uploaded your resume");
        }

        return this.$mdDialog.show(dialog);
    }

    /**
     * @description Upload the file as a resume
     * @param  {Object} file    Blob object returned by filepicker
     * @return  {Promise}
     */
    storeResume(file) {
        // get the security policy for an upload
        return this.$pfFilestackSigner.getUploadPolicy('resume', file.name).then(security => {
            // upload the nugget here
            return this.$pfFilestackPreset.upload(
                'resume',
                file,
                {},
                { path: security.path },
                security
            ).then(meta => {
                // set the security
                meta.security = security;
                // convert to PDF here
                this.convertToPDF(meta).then((meta) => {
                    // get user resume_privacy setting
                    this.$pfProfile.getUserMe().then((user) => {
                        // need this and check the privy
                        const resumePrivacy = user.resume_privacy === '1';
                        // process/save resume to DB
                        return this.$pfProfile.updateResume({
                            file_uri: meta.key,
                            resume_privacy: resumePrivacy
                        });
                    }, () => {
                        // throw up some toast
                        this.$pfToast.error('Your resume could not be uploaded. Please try again.');
                    });
                    // update
                    return;
                });
            }, (err) => {
                // throw up some toast
                this.$pfToast.error('Your resume could not be uploaded. Please try again.');
            });
        }).catch(error => {
            // throw up some toast
            this.$pfToast.error(this.$pfFilestackSigner.simpleErrorMessage());
        });
    }

    /**
     * If a the filestack meta is a word docuemnt, convert it to pdf.
     * @param  {object} filestackMeta
     * @return {Promise<object>}      return the pdf filestack meta
     */
    convertToPDF(filestackMeta) {
        // sanity check if this is a work document
        const isWordDocument = this.$pfFilestackConvert.mimetypeBelongsToType(filestackMeta.mimetype, 'document');
        // sanity check
        if (isWordDocument) {
            // create the store path here
            const path = this.$pfFilestackPreset.convertPath(filestackMeta.key, 'pdf');
            // convert this beast to a PDF
            return this.$pfFilestackPreset.convert(this.presetName, filestackMeta.handle, 'pdf', path, filestackMeta.security);
        }
        // return and resolve
        return this.$q.resolve(filestackMeta);
    }
}

ResumeUploadService.$inject = [
    '$q',
    '$mdDialog',
    '$pfUser',
    '$pfProfile',
    '$pfToast',
    '$pfFilestackPreset',
    '$pfFilestackSigner',
    '$pfFilestackConvert',
];
