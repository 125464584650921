import angular from 'angular';

import signInController from './controller/sign-in-controller';
import loginController from './controller/login-controller';
import registerController from './controller/register-controller';
import networkController from './controller/network-controller';

import CaptchaTemplate from '../template/captcha.html'
import ConfirmTemplate from '../template/confirm.html'
import ExistingTemplate from '../template/existing.html'
import ForgotPasswordTemplate from '../template/forgot-password.html'
import ForgotSuccessTemplate from '../template/forgot-success.html'
import IdentityTemplate from '../template/identity.html'
import NetworkTemplate from '../template/network.html'
import PasswordTemplate from '../template/password.html'
import RegisterTemplate from '../template/register.html'
import ResendEmailTemplate from '../template/resend-email.html'
import ResendFailureTemplate from '../template/resend-failure.html'
import ResendSuccessTemplate from '../template/resend-success.html'
import SimpleSignInModalTemplate from '../template/simple-sign-in-modal.html'
import SimpleSignInTemplate from '../template/simple-sign-in.html'
import EmailTemplate from '../template/email.html'
import LoginTemplate from '../template/login.html'

function Routes($stateProvider) {
    $stateProvider
        .state('simpleSignIn', {
            abstract: true,
            parent: 'layout',
            template: SimpleSignInTemplate,
            controller: signInController,
            controllerAs: 'signInCtrl',
            onEnter: [
                '$location',
                '$pfCookies',
                '$pfEnv',
                'PF_COOKIES',
                ($location, $pfCookies, $pfEnv, PF_COOKIES) => {
                    let returnUrl = $location.search().return_url;
                    if (returnUrl) {
                        // Set the return url cookie
                        $pfCookies.put(PF_COOKIES.RETURN_URL, returnUrl, {
                            path: '/',
                            domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                        });
                    }
                },
            ],
            resolve: {
                subdomainNetwork: [
                    '$location',
                    '$pfNetwork',
                    '$pfSignInIdentity',
                    ($location, $pfNetwork, $pfSignInIdentity) => {
                        let networkId = $location.search().network_id;

                        if (networkId) {
                            return $pfNetwork
                                .getNetworkById(networkId)
                                .then(network => {
                                    $pfSignInIdentity.network = network || {};

                                    return network;
                                });
                        }

                        return {};
                    },
                ],
            },
        })
        .state('simpleSignInModal', {
            template: '<div ui-view></div>',
            resolve: {
                subdomainNetwork: [
                    '$location',
                    '$pfNetwork',
                    '$pfSignInIdentity',
                    ($location, $pfNetwork, $pfSignInIdentity) => {
                        let networkId = $location.search().network_id;

                        if (networkId) {
                            return $pfNetwork
                                .getNetworkById(networkId)
                                .then(network => {
                                    $pfSignInIdentity.network = network || {};

                                    return network;
                                });
                        }

                        return {};
                    },
                ],
            },
            onEnter: [
                '$mdDialog',
                '$location',
                '$pfCookies',
                '$pfEnv',
                'PF_COOKIES',
                '$pfPreviousState',
                (
                    $mdDialog,
                    $location,
                    $pfCookies,
                    $pfEnv,
                    PF_COOKIES,
                    $pfPreviousState,
                ) => {
                    // remember the previous state with memoized name
                    $pfPreviousState.memo('signInModalInvoker');
                    // Set the return url cookie
                    let returnUrl = $location.absUrl();
                    $pfCookies.put(PF_COOKIES.RETURN_URL, returnUrl, {
                        path: '/',
                        domain: $pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                    });
                    // Open dialog
                    $mdDialog
                        .show({
                            parent: angular.element(document.body),
                            template: SimpleSignInModalTemplate,
                            controller: signInController,
                            controllerAs: 'signInCtrl',
                            clickOutsideToClose: true,
                            resolve: {
                                subdomainNetwork: function() {
                                    return {};
                                },
                            },
                        })
                        .catch(() => {})
                        .finally(function() {
                            // return to previous state
                            $pfPreviousState.go('signInModalInvoker');
                        });
                },
            ],
            onExit: [
                '$pfSignInHistory',
                $pfSignInHistory => {
                    // Reset the routing history for login/register
                    $pfSignInHistory.clearHistory();
                },
            ],
        })
        .state('login', {
            parent: 'simpleSignIn',
            url: '/login',
            template: LoginTemplate,
            controller: loginController,
            controllerAs: 'loginCtrl',
            deepStateRedirect: {
                default: 'login.identity',
            },
            data: {
                appName: 'auth',
                appClass: 'auth',
            },
            params: {
                identity: null,
                clear: false,
            },
        })
        .state('loginModal', {
            parent: 'simpleSignInModal',
            template: LoginTemplate,
            controller: loginController,
            controllerAs: 'loginCtrl',
            deepStateRedirect: {
                default: 'loginModal.identity',
            },
            data: {
                appName: 'auth',
            },
            params: {
                identity: null,
                clear: false,
                modalAttrs: null,
            },
        })
        .state('register', {
            parent: 'simpleSignIn',
            url: '/join',
            template: RegisterTemplate,
            controller: registerController,
            controllerAs: 'registerCtrl',
            deepStateRedirect: {
                default: 'register.email',
            },
            data: {
                appName: 'auth',
                appClass: 'auth',
            },
            params: {
                identity: null,
                clear: false,
            },
        })
        .state('registerModal', {
            parent: 'simpleSignInModal',
            template: RegisterTemplate,
            controller: registerController,
            controllerAs: 'registerCtrl',
            deepStateRedirect: {
                default: 'registerModal.email',
            },
            data: {
                appName: 'auth',
            },
            params: {
                identity: null,
                clear: false,
            },
        });

    // Dynamically create shared child states for both login and loginModal
    _.forEach(['login', 'loginModal'], parent => {
        $stateProvider
            .state(`${parent}.identity`, {
                template: IdentityTemplate,
            })
            .state(`${parent}.password`, {
                template: PasswordTemplate,
            })
            .state(`${parent}.forgotPassword`, {
                template: ForgotPasswordTemplate,
            })
            .state(`${parent}.forgotSuccess`, {
                template: ForgotSuccessTemplate,
            });
    });

    // Dynamically create shared child states for both login and loginModal
    _.forEach(['register', 'registerModal'], parent => {
        $stateProvider
            .state(`${parent}.email`, {
                template: EmailTemplate,
            })
            .state(`${parent}.network`, {
                template: NetworkTemplate,
                controller: networkController,
                controllerAs: 'networkCtrl',
            })
            .state(`${parent}.resendEmail`, {
                template: ResendEmailTemplate,
            })
            .state(`${parent}.resendSuccess`, {
                template: ResendSuccessTemplate,
            })
            .state(`${parent}.resendFailure`, {
                template: ResendFailureTemplate,
            })
            .state(`${parent}.confirm`, {
                template: ConfirmTemplate,
            })
            .state(`${parent}.existing`, {
                template: ExistingTemplate,
            })
            .state(`${parent}.captcha`, {
                template: CaptchaTemplate,
            });
    });
}

Routes.$inject = ['$stateProvider'];

export default Routes;
