import angular from 'angular';

import pfCompanyConnect from './company-connect/company-connect.module';
import {CompanyResultComponent} from './company-result/company-result.component';
import {CompanyResultCardComponent} from './company-result-card/company-result-card.component';
import {CompanyListCardComponent} from './company-list-card/company-list-card.component';

const MODULE_NAME = 'portfolium.component.company';

(function () {
    angular.module(MODULE_NAME, [
            pfCompanyConnect,
        ])
        // Components
        .component('pfCompanyResult', CompanyResultComponent)
        .component('pfCompanyListCard', CompanyListCardComponent)
        .component('pfCompanyResultCard', CompanyResultCardComponent)
}());

export default MODULE_NAME;
