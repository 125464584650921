import FormWizardGroupController from './form-wizard-group-controller';

function FormWizardGroupDirective() {
    return {
        require: ['form', '^pfFormWizard'],
        restrict: 'A',
        scope: {
            name: '@pfFormWizardGroup',
            nextAction: '&',
        },
        transclude: true,
        replace: true,
        link: function(scope, elem, attrs, ctrls) {
            let formCtrl = ctrls[0];
            let formWizardCtrl = ctrls[1];

            // Add parent controllers to scope
            scope.formCtrl = formCtrl;
            scope.formWizardCtrl = formWizardCtrl;

            // Set element on scope, for use with scrolling
            scope.elem = elem;

            // Add group to collection
            formWizardCtrl.addGroup(scope);
        },
        controller: FormWizardGroupController,
        template:
            '<fieldset ng-form name="{{ name }}" ng-transclude ng-show="visible"></fieldset>',
    };
}

export default FormWizardGroupDirective;
