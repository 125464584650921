import moment from 'moment';

class AuthService {
    constructor(
        $http,
        $q,
        $pfUser,
        $window,
        $pfCookies,
        $pfEventTracker,
        $state,
        $pfEnv,
        $pfHelpChat,
        PF_COOKIES,
    ) {
        this.$http = $http;
        this.$q = $q;
        this.$pfUser = $pfUser;
        this.$window = $window;
        this.$pfCookies = $pfCookies;
        this.$pfEventTracker = $pfEventTracker;
        this.$state = $state;
        this.$pfEnv = $pfEnv;
        this.$pfHelpChat = $pfHelpChat;
        this.PF_COOKIES = PF_COOKIES;
    }

    /**
     * Check is a user is the currently authenticated user
     * @param   {String}  userId User ID to check
     * @returns {Boolean}
     */
    isAuthenticatedUser(userId) {
        let user = this.$pfUser.getUser();
        return !!user && user.id === userId;
    }

    /**
     * Check is a user is the currently authenticated user
     * @param   {String}  userId User ID to check
     * @returns {Boolean}
     */
    isAuthenticatedUserByUsername(username) {
        let user = this.$pfUser.getUser();

        return user && user.username.toLowerCase() === username.toLowerCase();
    }

    /**
     * Get a public profile record by either email or username
     * @param  {String}  identity Email or username of profile to fetch
     * @return {Promise}
     */
    getProfileByIndentity(identity) {
        return this.$http({
            method: 'GET',
            url: `/proxy/users/identity`,
            params: {
                identity
            }
        });
    }

    /**
     * Authenticate a user and create a new session
     * @param  {String}  identity   User's email or username
     * @param  {String}  password   User's password
     * @param  {String=null}  recaptchaToken  Recaptcha token required if 4+ failed login attempts
     * @return {Promise}
     */
    login(
        identity,
        password,
        recaptchaToken = null
    ) {
        return this.$http({
            method: 'POST',
            url: `/proxy/auth/login`,
            data: {
                identity: identity,
                password: password,
                recaptcha: recaptchaToken,
            }
        })
        .then((response) => {
            let auth = response.data;
            // Chain session creation
            return this.createSession(auth);
        });
    }

    /**
     * Redirects to logout php page which handles the redirect stuff
     * as removing the session data
     * @param  {String} redirectUrl url code igniter route to redirect after
     *       being log out. It goes to goodby page by default
     * @return {Promise}
     */
    goToLogout(redirectUrl = null) {
        // default logout url
        let logoutUrl = '/logout';
        // sanity check for redirect url
        if (!_.isNil(redirectUrl)) {
            // append the redirect url
            logoutUrl += `?reversed_route=${redirectUrl}`;
        }
        // delete local storage
        this.logoutTasks();
        // log out
        return this.$window.location.assign(logoutUrl);
    }

    /**
     * Perform some cleanup tasks when the user logs out
     * @return {Void}
     */
    logoutTasks() {
        // Reset the user id and traits stored in cookies/localStorage
        this.$pfEventTracker.reset();
        // logout of the helpchat services
        this.$pfHelpChat.logout();
    }

    /**
     * Authenticate a user with a social service, then create a new session
     * @param  {string}  client Name of client service (google)
     * @param  {String}  token  OAuth access token from client service
     * @param  {Object}  [data] Form data
     * @return {Promise}
     */
    socialLogin(client, token, data = {}) {
        data.token = token;

        // get referral cookie
        const referral = this.$pfCookies.get(this.PF_COOKIES.REFERRAL);
        // sanity check for referral
        if (!_.isEmpty(referral)) {
            // add the referral cookie value to the POST data
            data.referral = referral;
        }

        return this.$http({
            method: 'POST',
            url: `/proxy/auth/${client}`,
            data: data
        })
        .then((response) => {
            if (response.status === 203) {
                return this.$q.resolve(response);
            }

            // Chain session creation
            return this.createSession(response.data);
        });
    }

    /**
     * Create a new web session after signing up or logging in
     * @param  {Object}  auth Auth object from login/create response
     * @return {Promise}
     */
    createSession(auth) {
        this.$pfEventTracker.track('Login Completed');

        return this.$http({
            method: 'POST',
            url: '/session/create',
            data: {
                auth,
            },
        }).then(() => {
            return auth;
        });
    }

    /**
     * Send a password reset request
     * @param  {String} Email of profile to reset
     * @return {Promise}
     */
    forgotPassword(email, recaptcha) {
        return this.$http({
            method: 'POST',
            url: `/proxy/auth/forgot`,
            data: {
                email,
                recaptcha,
            },
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Use the reset password token from the forgot password email to reset a user's password.
     * @param  {String} token User token
     * @param  {String} password New user password
     * @param  {String} confirm Confirmed new user password
     * @return {Promise}
     */
    resetPassword(token, password, confirm) {
        return this.$http({
            method: 'POST',
            url: `/proxy/auth/reset`,
            data: {
                token,
                password,
                confirm,
            },
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * Re-send partner school onboarding email
     * @param  {String} userId Email of profile to resend
     * @param  {Int} networkId Network of profile to reset
     * @return {Promise}
     */
    resendOnboardingEmail(userId, networkId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/auth/access/${userId}/${networkId}`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * On successful auth, redirect and break out of frames
     * @param  {String} url A url to redirect to
     */
    redirect(url = '/activity', useReturnCookie = true) {
        // check if the useReturnCookie flag is enabled and there is a return cookie value
        if (useReturnCookie) {
            // get the return url from cookie
            const returnUrl = this.$pfCookies.get(this.PF_COOKIES.RETURN_URL);
            // set url
            url = returnUrl;
            // delete the return cookie
            this.$pfCookies.remove(this.PF_COOKIES.RETURN_URL, {
                domain: this.$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
                path: '/',
            });
        }
        // sanity check for valid domain
        if (!this.$pfEnv.isValidDomain(url)) {
            // override the redirect URL
            url = '/activity';
        }
        // check if the window is itself (NOT iFrame)
        if (this.$window.self === this.$window.top) {
            // redirect window to return URL
            this.$window.location.assign(url);
        } else {
            // iFrame buster - redirect the top window
            this.$window.top.location.href = url;
        }
    }

    /**
     * Handle account locked error (user deleted their account)
     */
    triggerAccountLockedError(token, requestedAt, willDeleteAt) {
        // Forget previous state marker so the

        // set cookie so we have data on the 'miss you' page
        const expiresOn = moment().add(30, 'minutes').toDate();
        this.$pfCookies.put(
            this.PF_COOKIES.GDPR_ACCOUNT_DELETED,
            JSON.stringify({
                token,
                requested_at: requestedAt,
                will_delete_at: willDeleteAt,
            }),
        {
            expires: expiresOn,
            path: '/',
            domain: this.$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
        });

        // redirect to 'miss you' page
        return this.goToLogout('miss-you');
    }

    /**
     * If you're under 13 and try to login, this is what you get
     */
    triggerCoppaError() {
        this.goToLogout('coppa-error');
    }
}

AuthService.$inject = [
    '$http',
    '$q',
    '$pfUser',
    '$window',
    '$pfCookies',
    '$pfEventTracker',
    '$state',
    '$pfEnv',
    '$pfHelpChat',
    'PF_COOKIES',
];

export default AuthService;
