export class AddressService {
    constructor($http, $filter) {
        this.$http = $http;
        this.$filter = $filter;
    }

    /**
     * @description Get User Address
     * @returns {Promise}
     */
    getAddresses() {
        return this.$http({
            method: 'GET',
            url: `/proxy/addresses/me`,
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Add an Address
     * @returns {Promise}
     */
    addAddress(data) {
        return this.$http({
            method: 'POST',
            url: `/proxy/addresses/create`,
            data: data
        })
        .then((response) => {
            return response.data;
        },(response)=> {
            return response.data;
        });
    }

    /**
     * @description Update currenet address
     * @returns {Promise}
     */
    updateAddress(data) {
        return this.$http({
            method: 'POST',
            url: `/proxy/addresses/address/${data.id}`,
            data: data
        })
        .then((response) => {
            return response.data;
        },(response)=> {
            return response.data;
        });
    }

    /**
     * @description Set a user's existing address as the address where they
     * currently reside
     * @param   {Int}  emailId Email Id
     * @returns {Promise}
     */
    makeAddressCurrent(addressId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/addresses/current/${addressId}`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Delete Address by using the Portfolium API
     * @param   {Int}  emailId Email Id
     * @returns {Promise}
     */
    deleteAddress(addressId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/addresses/address/${addressId}`
        })
        .then((response) => {
            return response.data;
        });
    }

    /**
     * @description Formate an Address received from the Google Maps API
     * @param   {Int}  emailId Email Id
     * @returns {Object}
     */
    formatAddress(address) {
        return {
            'street': this.$filter('address')(address, 'street'),
            'street_extra': this.$filter('address')(address, 'street_extra'),
            'city': this.$filter('address')(address, 'city'),
            'state': this.$filter('address')(address, 'state'),
            'country': this.$filter('address')(address, 'country'),
            'zipcode': this.$filter('address')(address, 'zipcode'),
        }
    }
}

AddressService.$inject = [
    '$http',
    '$filter',
];
