import {combineReducers} from 'redux';
import {
    ATTACHMENT_VIEWER_MEDIA_PLAYING,
    ATTACHMENT_VIEWER_MEDIA_PAUSED,
} from './media-player.actions';

const initialPlayerState = {
    playing: null,
}

const mediaPlayer = (state = initialPlayerState, action = {}) => {
    switch (action.type) {
        case ATTACHMENT_VIEWER_MEDIA_PLAYING:
            return _.assign({}, state, {
                playing: action.payload.mediaId,
            });
        case ATTACHMENT_VIEWER_MEDIA_PAUSED:
            return _.assign({}, state, {
                playing: null,
            });
        default:
            return state;
    }
}

export const attachmentViewer = combineReducers({
    mediaPlayer,
});
