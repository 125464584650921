import ShareComponentTemplate from './share.component.html';

class ShareController {
    constructor(
        $pfSocialShare,
        $element,
        $pfUser,
        $pfEventTracker,
    ) {
        this._$pfSocialShare = $pfSocialShare;
        this._$pfEventTracker = $pfEventTracker;
        this._$element = $element;
        this.isMobile = $pfUser.isMobile();
        this.currentUser = $pfUser.getUser();
    }

    $onInit() {
        // Make sure the custom element can't be focused
        this._$element.attr('tabindex', '-1');
        this.isProjectOwner = false;
    }

    $onChanges() {
        if (this.type === 'entry' && this.currentUser && (this.data.profile.id === this.currentUser.id)) {
            this.isProjectOwner = true;
        }
    }

    /**
     * Check if we are sharing an entry
     * @return {Boolean}
     */
    get isEntry() {
        return this.type === 'entry';
    }

    /**
     * Open the social media menu
     * @param {Object} $mdMenu The current menu object
     * @param {Object} $event  Click event
     */
    openMenu($mdMenu, $event) {
        // track the menu open event
        if (this.trackingSource) {
            this._$pfEventTracker.trackServer('Project Share Menu Opened', {
                source: this.trackingSource,
                isProjectOwner: this.isProjectOwner,
            });
        }
        $mdMenu.open($event);
    }

    /**
     * Share the entry to a social media service
     * @param {String} provider Social media provider
     */
    share(provider) {
        this._$pfSocialShare.shareData(provider, this.data, this.type, this.type === 'entry', this.trackingSource);
    }
}

ShareController.$inject = [
    '$pfSocialShare',
    '$element',
    '$pfUser',
    '$pfEventTracker',
];

/**
 * @ngdoc component
 * @module portfolium.component.socialShare
 * @name ShareComponent
 * @description Display a social share menu
 *
 * @param {object}      data           Current entry data
 * @param {string}      type           The type of item that's being shared
 * @param {string}      trackingSource The location where this component is
 *                                     being shown (for analytics)
 */
export const ShareComponent = {
    bindings: {
        data: '<',
        type: '<',
        trackingSource: '<',
    },
    controller: ShareController,
    controllerAs: '$ctrl',
    template: ShareComponentTemplate,
};
