/**
 * @ngdoc service
 * @name EdpService
 * @module portfolium.core
 * @description Service for fetching and interacticing with anentry
 * @class EdpService
 */
export class EdpService {
    constructor(
        $http,
        $pfSubscription,
    ) {
        // Bind services
        this.$http = $http;
        this.$pfSubscription = $pfSubscription;
    }

    /**
     * Retrieves an entry
     * @param  {String|Integer}  idOrSlug Entry slug or id
     * @param  {String}  ad   Entry privacy bypass token
     * @param  {Object}  params Http params
     * @return {Promise}
     */
    getEntry(idOrSlug, params = {}) {
        let url = `/proxy/entries/details/${idOrSlug}`;
        if (_.isString(idOrSlug)) {
            url += '/slug';
        }

        return this.$http({
            method: 'GET',
            url: url,
            params: _.assign({
                comments: false,
                contests: true,
                assignment: true,
                subscriber: this.$pfSubscription.isSubscriber(),
            }, params),
        })
        .then(response => {
            return response.data;
        });
    }

    /**
     * Delete an entry
     * @param  {Integer}  id Entry id
     * @return {Promise}
     */
    deleteEntry(id) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/entries/entry/${id}`
        })
        .then(response => {
            return response.data;
        });
    }

    /**
     * Like an entry
     * @param  {String}  entryId Entry ID
     * @return {Promise}
     */
    likeEntry(entryId) {
        return this.$http({
            method: 'POST',
            url: `/proxy/entries/like/${entryId}`
        })
        .then(response => {
            return response.data;
        });
    }

    /**
     * Get paginated comments for an entry
     * @param  {String}  entryId Entry ID
     * @return {Promise}
     */
    getComments(entryId, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/entries/comments/${entryId}`,
            params,
        })
        .then(response => {
            return response.data;
        });
    }

    /**
     * Comment on an entry
     * @param  {String}  entryId Entry ID
     * @param  {String}  comment The comment text
     * @return {Promise}
     */
    commentEntry(entryId, comment) {
        return this.$http({
            method: 'POST',
            url: `/proxy/entries/comment/${entryId}`,
            data: {
                comment: comment
            }
        })
        .then(response => {
            return response.data;
        });
    }

    /**
     * Delete a comment
     * @param  {String}  commentId Comment ID
     * @return {Promise}
     */
    deleteComment(commentId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/entries/comment/${commentId}`,
        })
        .then(response => {
            return response.data;
        });
    }

    /**
     * Remove the authenticated user as a teammate from an entry
     * @param  {String}  entryId Entry ID
     * @param  {String}  userId User ID
     * @return {Promise}
     */
    removeSelfAsTeammate(entryId, userId) {
        return this.$http({
            method: 'DELETE',
            url: `/proxy/entries/untag/${entryId}`,
        })
        .then(response => {
            // update state
            return response.data;
        });
    }
}

EdpService.$inject = [
    '$http',
    '$pfSubscription',
];
