import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import { router } from 'redux-ui-router';

import { currentUser } from 'components/auth/current-user.reducer';
import { entities } from 'core/entities/entities.reducer';
import { userEntries } from 'core/reducers/user-entries.reducer';
import { sitewideSearch } from 'components/sitewide-search/sitewide-search.reducer';
import { attachmentViewer } from 'components/attachment-viewer/media-player.reducer';
import { userConnections } from 'components/user-connect/user-connect-btn/user-connect.reducer';
import { socialUserData } from 'components/social-auth/social-auth.reducer';
import { notificationCenter } from '../../../components/notification-center/js/reducer';
import { profileAboutPanel } from '../../../components/profile-about-panel/js/reducer/profile-about-panel';
import { profileQuickEdit } from '../../../components/profile-quick-edit/profile-quick-edit.reducer';
import { profile } from '../../../profile/js/reducer';
import { competencyLibrary } from '../../../talent-match/competency-library/js/reducer';
import { jobWizard } from '../../../talent-match/job-wizard/js/reducer';
import { jobSummary } from '../../../talent-match/job-summary/js/reducer';
import { entryPanel } from '../../../entry-details/entry-details.reducer';
import { entryEditor } from '../../../entry-editor/entry-editor.reducer';
import { entryEditorMiddleware } from '../../../entry-editor/entry-editor.middleware';
import { discover } from '../../../discover/discover.reducer';
import { search } from '../../../search/search.reducer';
import { companyDetails } from '../../../company/company-details.reducer';
import { pmailCenter } from '../../../components/pmail-center/pmail-center.reducer';
import { settings } from '../../../settings/settings.reducer';
import { network } from '../../../network/network.reducer';
import { myJobs } from '../../../my-jobs/my-jobs.reducer';
import { jobApplyCenter } from '../../../components/job/job-apply-center/job-apply-center.reducer';
import { exportProfile } from '../../../components/export-profile/export-profile.reducer';
import { challenges } from '../../../challenges/challenges.reducer';
import { suggestions } from '../../../dashboard/js/suggestions.reducer';
import { dataImporterSelection } from '../../../settings/settings-import-data/data-importer/data-importer-selection.reducer';
import { assignment } from '../../../assignment/assignment.reducer';
import { messages } from '../../../messages/messages.reducer';
import { pathways } from '../../../pathways/pathways.reducer';
import { tasks } from '../../../tasks/tasks.reducer';
import { course } from '../../../course/course.reducer';

export const createStore = $ngReduxProvider => {
    const reducers = combineReducers({
        competencyLibrary,
        currentUser,
        dataImporterSelection,
        discover,
        entities,
        userEntries,
        entryPanel,
        entryEditor,
        jobWizard,
        jobSummary,
        notificationCenter,
        pmailCenter,
        profileAboutPanel,
        profileQuickEdit,
        profile,
        router,
        search,
        companyDetails,
        settings,
        network,
        myJobs,
        jobApplyCenter,
        exportProfile,
        challenges,
        suggestions,
        assignment,
        messages,
        sitewideSearch,
        attachmentViewer,
        userConnections,
        socialUserData,
        pathways,
        tasks,
        course,
    });

    const moduleMiddlewares = [
        entryEditorMiddleware
    ];

    const middlewares = ['ngUiRouterMiddleware', thunk, ...moduleMiddlewares];

    const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
        : t => t;

    const storeEnhancers = [reduxDevTools];

    $ngReduxProvider.createStoreWith(reducers, middlewares, storeEnhancers);
};

createStore.$inject = ['$ngReduxProvider'];
