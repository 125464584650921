import {
    SETTINGS_ACCOUNT_DISABLE_REQUEST,
    SETTINGS_ACCOUNT_DISABLE_REQUEST_SUCCESS,
    SETTINGS_ACCOUNT_DISABLE_REQUEST_ERROR,
    SETTINGS_ACCOUNT_DISABLE_RESET_ERROR,
} from './settings-account-disable.actions';

const initialState = {
    loading: false,
    error: null,
};

export const settingsAccountDisable = (state = initialState, action = {}) => {
    switch (action.type) {
        case SETTINGS_ACCOUNT_DISABLE_REQUEST:
            return _.assign({}, state, {
                loading: true,
            });
        case SETTINGS_ACCOUNT_DISABLE_REQUEST_SUCCESS:
            return _.assign({}, state, {
                loading: false,
                error: null,
            });
        case SETTINGS_ACCOUNT_DISABLE_REQUEST_ERROR:
            const { error } = action.payload;
            return _.assign({}, state, {
                loading: false,
                error,
            });
        case SETTINGS_ACCOUNT_DISABLE_RESET_ERROR:
            return _.assign({}, state, {
                error: null,
            });
        default:
            return state;
    }
};
