import {
    SOCIAL_AUTH_SET_TWITTER_USER_DATA,
    SOCIAL_AUTH_RESET_USER_DATA,
} from './social-auth.actions';

const initialUserDataState = {
    twitter: null,
};

export const socialUserData = (state = initialUserDataState, action = {}) => {
    switch (action.type) {
        case SOCIAL_AUTH_SET_TWITTER_USER_DATA:
            return _.assign({}, state, {
                twitter: action.payload.userData,
            });
        case SOCIAL_AUTH_RESET_USER_DATA:
            return initialUserDataState;
        default:
            return state;
    }
}
