import angular from 'angular';

import { CandyBarComponent } from './candy-bar.component';

const MODULE_NAME = 'portfolium.component.candy-bar';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfCandyBar', CandyBarComponent);
})();

export default MODULE_NAME;
