export class SuggestionsService {
    constructor($http) {
        this.$http = $http;
    }

    /**
     * Pad an array with empty objects
     * @param  {Array}  arr An array of feed data
     * @param  {Number} n Number to pad out to
     * @return {Array}
     */
    padArrayWithDefault(arr = [], n = 0) {
        while (arr.length < n) {
            arr.push({});
        }

        return arr;
    }

    /**
     * Get a list of suggested companies for the current user
     * @return {Promise}
     */
    getSuggestedCompanies(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/suggestions/companies',
            params: params,
        }).then((response) => response.data);
    }

    /**
     * Get a list of suggested skills for the current user
     * @param {object} [params] search parameters
     * @param {boolean} [params.major=true] get skills by major
     * @param {boolean} [params.exclude_existing=true] exclude existing skills
     * @param {integer} [params.category_id] get skills by category ID
     * @return {promise}
     */
    getSuggestedSkills(params = {
        major: true,
        exclude_existing: true
    }) {
        return this.$http({
            method: 'GET',
            url: '/proxy/suggestions/skills',
            params,
        }).then((response) => response.data);
    }

    /**
     * Get a list of tags, either by query or category ID. Default results are trending skills from category 'Other'
     * @param  {object}  params Tag search parameters
     * @param  {string}  [params.q] Search query
     * @param  {integer} [params.category_id] Used to get trending tags by category
     * @return {Promise}
     */
    getTags(params = {
        q: q = null,
        category_id: category_id = null,
    }) {
        return this.$http({
            method: 'GET',
            url: '/proxy/suggestions/tags',
            params,
        }).then((response) => {
            return response.data;
        });
    }

    /**
     * Get a list of suggested entries for the current user
     * @return {Promise}
     */
    getSuggestedEntries(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/suggestions/entries',
            params: params,
        }).then((response) => response.data);
    }

    /**
     * Get a list of suggested profiles based on the current user's major
     * @return {Promise}
     */
    getSuggestedProfiles(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/suggestions/users',
            params: params,
        }).then((response) => response.data);
    }

    /**
     * Get a list of suggested collaborators for the passed in user ID
     * @param {string} userId ID of user
     * @param {object} [params] parameters
     * @param {object} [params.limit] pagination limit
     * @param {object} [params.offset] pagination offset
     * @param {object} [params.sort] type of sorting to use with returned data
     * @return {promise}
     */
    getSuggestedCollaborators(userId, params = {}) {
        return this.$http({
            method: 'GET',
            url: `/proxy/collaborators/user/${userId}`,
            params,
        }).then((response) => response.data);
    }

    /**
     * Get a list of suggested jobs
     * @return {Promise}
     */
    getSuggestedJobs(params = {}) {
        return this.$http({
            method: 'GET',
            url: '/proxy/suggestions/jobs',
            params: params,
        }).then((response) => response.data);
    }
}

SuggestionsService.$inject = ['$http'];
