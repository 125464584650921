import _ from 'lodash';

import {
    PROFILE_QUICK_EDIT_UPDATE_CURRENT_USER,
    PROFILE_QUICK_EDIT_UPDATE_ABOUTS,
    PROFILE_QUICK_EDIT_UPDATE_EDUCATIONS,
    PROFILE_QUICK_EDIT_UPDATE_EXPERIENCES,
    PROFILE_QUICK_EDIT_UPDATE_LOCATIONS,
    PROFILE_QUICK_EDIT_ON_SUBMIT,
    PROFILE_QUICK_EDIT_RESET_CURRENT_USER,
    PROFILE_QUICK_EDIT_RESET_ABOUTS,
} from './profile-quick-edit.actions';

export const initialState = {
    currentUser: {},
    abouts: {},
    locations: [],
};

export const profileQuickEdit = (state = initialState, action = {}) => {
    switch (action.type) {
        case PROFILE_QUICK_EDIT_UPDATE_CURRENT_USER:
            return _.assign({}, state, {
                currentUser: _.assign({}, state.currentUser, action.payload.currentUser),
            });
        case PROFILE_QUICK_EDIT_UPDATE_ABOUTS:
        case PROFILE_QUICK_EDIT_UPDATE_EDUCATIONS:
        case PROFILE_QUICK_EDIT_UPDATE_EXPERIENCES:
        case PROFILE_QUICK_EDIT_UPDATE_LOCATIONS:
        case PROFILE_QUICK_EDIT_ON_SUBMIT:
            return _.assign({}, state, action.payload);
        case PROFILE_QUICK_EDIT_RESET_CURRENT_USER:
        case PROFILE_QUICK_EDIT_RESET_ABOUTS:
            return _.assign({}, initialState);
        default:
            return state;
    }
};
