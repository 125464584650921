import FieldLabelTemplate from './field-label.component.html';
import './field-label.component.scss';

/**
 * @ngdoc component
 * @name FieldLabelComponent
 * @module portfolium.components.forms
 * @param {string} for ID of the form field this label is tied to
 * @param {string} class Any additional classes
 **/
export const FieldLabelComponent = {
    transclude: true,
    bindings: {
        'for': '@pfFor',
        'class': '@pfClass',
    },
    template: FieldLabelTemplate,
}
