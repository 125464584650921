import BaseModalController from '../../components/modal/js/controller/base-modal-controller';

/**
 * @ngdoc service
 * @name ContentReportingService
 * @module portfolium.core
 * @description Used to report content violations
 **/
 export class ContentReportingService {
     constructor(
        $q,
        $http,
        $location,
        $mdDialog,
        $filter,
        fullNameFilter,
        PF_CONTENT_REPORTING_TYPES,
        PF_CONTENT_REPORTING_REASONS
    ) {
        this.$q = $q;
        this.$http = $http;
        this.$location = $location;
        this.$mdDialog = $mdDialog;
        this.$filter = $filter;
        this.fullNameFilter = fullNameFilter;
        this.PF_CONTENT_REPORTING_TYPES = PF_CONTENT_REPORTING_TYPES;
        this.PF_CONTENT_REPORTING_REASONS = PF_CONTENT_REPORTING_REASONS;
    }

    /**
     * Checks given type against reporting type constant
     * @param {String} reportType string name of report type
     * @returns {Boolean}
     */
    isProfileReportType(reportType) {
        return reportType === this.PF_CONTENT_REPORTING_TYPES.profile;
    }

    /**
     * Open the project report modal
     * @param  {Event}  targetEvent click source event
     * @param  {String}  entryId Entry identifier
     * @param  {String}  version Entry version
     */
    openProjectReportModal(targetEvent, entryId, version) {
        // get type data
        const reportTypeData = this._getReportTypeData(
            this.PF_CONTENT_REPORTING_TYPES.project
        );
        // build content data for project
        const contentData = {
            target_id: entryId,
            version
        };
        // call generic open modal
        this._openContentReportingModal(
            targetEvent,
            reportTypeData,
            contentData
        );
    }

    /**
     * Open the profile report modal
     * @param  {Event}  targetEvent click source event
     * @param  {Object}  user reported user
     */
    openProfileReportModal(targetEvent, user) {
        // get type data
        const reportTypeData = this._getReportTypeData(
            this.PF_CONTENT_REPORTING_TYPES.profile
        );
        // build content data for profile
        const contentData = {
            target_id: user.id,
            user,
        };
        // set custom header
        const header = `${this.$filter('i18n')('Why are you reporting')} ${this.fullNameFilter(user)}?`;
        // call generic open modal
        this._openContentReportingModal(
            targetEvent,
            reportTypeData,
            contentData,
            header
        );
    }

    /**
     * Open the badge report modal
     * @param  {Event}  targetEvent click source event
     * @param  {String}  badgeId badge identifier
     */
    openBadgeReportModal(targetEvent, badgeId) {
        // get type data
        const reportTypeData = this._getReportTypeData(
            this.PF_CONTENT_REPORTING_TYPES.badge
        );
        // build content data for profile
        const contentData = {
            target_id: badgeId
        };
        // set custom header
        const header = this.$filter('i18n')('Why are you reporting this badge?');
        // call generic open modal
        this._openContentReportingModal(
            targetEvent,
            reportTypeData,
            contentData,
            header
        );
    }

    /**
     * Open the user badge report modal
     * @param  {Event}  targetEvent click source event
     * @param  {String}  badgeId badge identifier
     */
    openUserBadgeReportModal(targetEvent, badgeId) {
        // get type data
        const reportTypeData = this._getReportTypeData(
            this.PF_CONTENT_REPORTING_TYPES.userBadge
        );
        // build content data for profile
        const contentData = {
            target_id: badgeId
        };
        // set custom header
        const header = this.$filter('i18n')('Why are you reporting this badge?');
        // call generic open modal
        this._openContentReportingModal(
            targetEvent,
            reportTypeData,
            contentData,
            header
        );
    }

    /**
     * Send the report off to the API
     * @param  {Object}  contentData contains specific content data needed for the report
     * @param  {String}  contentData.target_id Content identifier (Entry ID, Badge ID, User ID)
     * @param  {String=} contentData.version Version of the project being reported. Only used for project reporting
     * @param  {Object}  reportTypeData contains generic report type data
     * @param  {String}  reportTypeData.reason The selected reporting reason
     * @param  {String}  reportTypeData.reportType Type of report beng sent ('entry', 'user', 'badge')
     * @param  {Object}  options
     * @param  {String=} options.block_user Block user after reporting profile
     * @return {Promise}
     */
    sendContentReport({
        target_id,
        version = null,
    }, {
        reason,
        type,
    }, {
        block_user = false,
    }) {
        // get the current url
        const url = this.$location.absUrl();
        // report it
        return this.$http({
            method: 'POST',
            url: '/proxy/violations/report',
            data: {
                type,
                target_id,
                url,
                version,
                reason,
                block_user,
            },
        }).then((response) => {
            // tell em its aight
            return this.$q.resolve(response);
        }, (error) => {
            // reject that shit son
            return this.$q.reject(error);
        });
    }

    /**
     * Send additional feedback about the reported content, this will take in content report data from the sendContentReport response
     * @param  {Number} contentViolationId ID of the content violation, returned as a response from the initial sendContentReport post
     * @param  {String} comment Additional feedback for the reported content
     * @return {Promise}
     */
    sendAdditionalFeedback(contentViolationId, comment) {
        // report it
        return this.$http({
            method: 'POST',
            url: `/proxy/violations/comment/${contentViolationId}`,
            data: {
                comment
            },
        }).then(() => {
            // resolve it
            return this.$q.resolve('Feedback submitted!');
        }, () => {
            // reject it
            return this.$q.reject('Oops, something went wrong.');
        });
    }

    /**
     * Retrieve relevant report data by type
     * Don't call this method directly
     * @param  {String}  reportType
     * @return {Object} report data object
     */
    _getReportTypeData(reportType) {
        return _.find(this.PF_CONTENT_REPORTING_REASONS, ['type', reportType]);
    }

    /**
     * Open a content reporting modal with the provided information
     * Do not call this method directly
     * @param  {Event}  targetEvent  The click source event
     * @param  {Object}  reportTypeData contains generic report type data
     * @param  {Object}  contentData contains specific content data needed for the report
     */
    _openContentReportingModal(
        targetEvent,
        reportTypeData,
        contentData,
        header = 'Why are you reporting this?'
    ) {
        const dialogSettings = {
            template: `
                <pf-content-reporting-modal
                    header="{{ ::$ctrl.header }}"
                    content-data="::$ctrl.contentData"
                    report-type-data="::$ctrl.reportTypeData"
                    on-cancel="$ctrl.cancel()">
                </pf-content-reporting-modal>
            `,
            locals: {
                header,
                contentData,
                reportTypeData,
            },
            controller: BaseModalController,
            bindToController: true,
            controllerAs: '$ctrl',
            hasBackdrop: true,
            clickOutsideToClose: true,
            targetEvent,
            autoWrap: false,
        };

        this.$mdDialog.show(dialogSettings);
    }
}

ContentReportingService.$inject = [
    '$q',
    '$http',
    '$location',
    '$mdDialog',
    '$filter',
    'fullNameFilter',
    'PF_CONTENT_REPORTING_TYPES',
    'PF_CONTENT_REPORTING_REASONS',
];
