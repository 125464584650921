import { combineReducers } from 'redux';
import { currentPathway } from './pathway-details/pathway-details.reducer';
import { currentPathwayBadge } from './badge-details/badge-details.reducer';
import { currentRequirement } from './pathway-requirement/pathway-requirement.reducer';

export const pathways = combineReducers({
    currentPathway,
    currentPathwayBadge,
    currentRequirement,
});
