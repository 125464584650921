export const loadingSplash = ($transitions, $rootScope) => {
    // Set the loaded state to true
    $transitions.pfAppLoaded = true;
    // Listen for state change start
    const startWatcher = $transitions.onStart({}, () => {
        // App is resolving for UI router, set loaded state to false
        $rootScope.pfAppLoaded = false;
        // Cancel the listener
        startWatcher();
    });
    // Listen for state change success
    const successWatcher = $transitions.onSuccess({}, () => {
        // Resolves are all done, mark loaded state as true
        $rootScope.pfAppLoaded = true;
        // Cancel the listener
        successWatcher();
    });
};

loadingSplash.$inject = ['$transitions', '$rootScope'];
