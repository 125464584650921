import angular from 'angular';

export class ClipboardService {
    constructor($window, $document, $pfToast) {
        this.$window = $window;
        this.$document = $document;
        this.$pfToast = $pfToast;

        // set default messages
        this.successMsg = 'Copied to Clipboard!';
        this.failureMsg = 'Could not copy text';
    }

    /**
     * @description Copy the text in the html element defined by the selector to the clipboard
     * @param  {String}  selector   The element selector that contains the text to be copied
     * @param  {String}  successMsg The text to show upon successful copy
     * @param  {String}  failureMsg The text to show if copy was unsuccessful
     * @return {Void}
     */
    copyElementText(selector, successMsg, failureMsg) {
        let elemToCopy = angular.element(selector);

        // set message text
        successMsg = successMsg || this.successMsg;
        failureMsg = failureMsg || this.failureMsg;

        let range = document.createRange();
        range.selectNode(elemToCopy[0]);
        const sel = this.$window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        let successful = document.execCommand('copy');

        if (successful) {
            this.$pfToast.success(successMsg);
        } else {
            this.$pfToast.error(failureMsg);
        }
    }

    /**
     * @description Copy the text to the clipboard
     * @param  {String}  text       The text to be copied
     * @param  {String}  successMsg The text to show upon successful copy
     * @param  {String}  failureMsg The text to show if copy was unsuccessful
     * @return {Void}
     */
    copyText(text, successMsg, failureMsg) {
        // set message text
        successMsg = successMsg || this.successMsg;
        failureMsg = failureMsg || this.failureMsg;

        let node = this._createNode(text);
        this.$document[0].body.appendChild(node);
        let successful = this._copyNode(node);
        this.$document[0].body.removeChild(node);

        if (successful) {
            this.$pfToast.success(successMsg);
        } else {
            this.$pfToast.error(failureMsg);
        }
    }

    /**
     * Determine is browser is iOS Safari.
     * https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari/29696509#29696509
     */
    _isIOS() {
        const ua = this.$window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        return iOS && webkit && !ua.match(/CriOS/i);
    }

    /**
     * @description Create a fake textarea node
     * @param  {String}  text       The text to add to the text area
     * @return {Node}
     */
    _createNode(text) {
        let node = this.$document[0].createElement('textarea');
        node.style.position = 'absolute';
        node.textContent = text;
        node.setAttribute('readonly', 'false');
        node.setAttribute('contenteditable', 'true');
        node.style.width = '100px';
        node.style.height = '100px';
        node.style.left = '-10000px';
        node.style.top =
            (this.$window.pageYOffset ||
                this.$document[0].documentElement.scrollTop) + 'px';
        return node;
    }

    /**
     * @description Copy text in selected node
     * @param  {Node}  node       The node to copy
     * @return {Boolean}
     */
    _copyNode(node) {
        let copied = false;
        let selection = null;
        // Set inline style to override css styles
        this.$document[0].body.style.webkitUserSelect = 'initial';
        if (this._isIOS()) {
            const range = document.createRange();
            range.selectNodeContents(node);
            selection = this.$window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            node.setSelectionRange(0, 99999);
        } else {
            selection = this.$document[0].getSelection();
            selection.removeAllRanges();
            node.select();
        }

        if (this.$document[0].execCommand('copy')) {
            selection.removeAllRanges();
            copied = true;
        }
        // Reset inline style
        this.$document[0].body.style.webkitUserSelect = '';

        return copied;
    }
}

ClipboardService.$inject = ['$window', '$document', '$pfToast'];
