import AttachmentDocumentTemplate from './attachment-document.component.html';

/**
 * @ngdoc controller
 * @name AttachmentDocumentController
 * @module portfolium.component.mediaSlider
 **/
class AttachmentDocumentController {
    constructor(
        $pfFilestackViewer,
    ) {
        this.$pfFilestackViewer = $pfFilestackViewer;
    }

    /**
     * Open the filestack mobile view
     *
     * @param {object} $event The click event
     * @param {object} file The filestack file
     * @memberof AttachmentDocumentController
     */
    openFilestackMobile($event, file) {
        this.$pfFilestackViewer.openMobileView($event, file);
    }
}

AttachmentDocumentController.$inject = [
    '$pfFilestackViewer',
];

/**
 * @ngdoc component
 * @name AttachmentDocumentComponent
 * @module portfolium.component.mediaSlider
 * @description Attachment slides in media slider for documents
 *
 * @param {object}   pfAttachment The attachment object
 * @param {boolean}  pfIsMobile   A flag used to determine if the browser is on a mobile device
 **/
export const AttachmentDocumentComponent = {
    bindings: {
        attachment: '<pfAttachment',
        isMobile: '<pfIsMobile',
    },
    controller: AttachmentDocumentController,
    controllerAs: '$ctrl',
    template: AttachmentDocumentTemplate,
};
