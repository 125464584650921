export const POPOVER_TYPES = {
    text: 'text',
    profile: 'profile',
    entryStrength: 'entry-strength',
}

export const POPOVER_STARTING_POSITIONS = {
    top: 'top',
    bottom: 'bottom',
}
