export const toggleNotificationCenterDirective = ($pfNotificationCenterPanel) => {
    return {
        restrict: 'A',
        link: (scope, elem, attrs, ctrl) => {
            elem.on('click', () => {
                $pfNotificationCenterPanel.open();
            });
        },
    };
};

toggleNotificationCenterDirective.$inject = ['$pfNotificationCenterPanel'];
