import uuid from 'node-uuid';
import AttachmentViewerTemplate from './attachment-viewer.component.html';

/**
 * @ngdoc controller
 * @name AttachmentViewerController
 * @module portfolium.component.attachmentViewer
 **/
class AttachmentViewerController {
    constructor(
        $element,
        $sce,
        $pfAttachmentViewer,
        $pfAttachmentMedia,
        $pfMediaTypes,
        PF_ATTACHMENT_VIEWER_TYPES,
    ) {
        this.$element = $element;
        this.$sce = $sce;
        this.$pfAttachmentViewer = $pfAttachmentViewer;
        this.$pfAttachmentMedia = $pfAttachmentMedia;
        this.$pfMediaTypes = $pfMediaTypes;
        this.PF_ATTACHMENT_VIEWER_TYPES = PF_ATTACHMENT_VIEWER_TYPES;
        // some variables to configure the mediaelement player
        this.elementId = null;
        this.player = null;
        this.provider = {};
        this.attachmentType = undefined;
    }

    $onInit() {
        // set the attachment type
        this.attachmentType = this.$pfAttachmentViewer.getAttachmentType(this.attachment);
        // if the media type is not supported, remove this component from
        // the DOM before any of the sub-components are initialized
        if (this.attachmentType === this.PF_ATTACHMENT_VIEWER_TYPES.UNSUPPORTED.name) {
            this.$element.remove();
        }
        this.isAssignment = !_.isNil(this.isAssignment) ? this.isAssignment : false;
    }

    $onDestroy() {
        this.$pfAttachmentMedia.unregisterPlayer(this.elementId);
    }

    /**
     * @description Register the element with the MediaElement framework
     * @param {Element} element    The element that contains media to register
     * @param {Boolean} usePlayer  Use MediaElement standalone api or MediaElementPlayer
     * @param {Object}  options    The mediaelement options
     * @return {Void}
     */
    registerMediaElement(element, usePlayer = false, options = {}) {
        // set id attribute on the element
        if (!this.elementId) {
            this.elementId = this.createId();
            element.attr('id', this.elementId);
            // save the id on this element so we can access this
            // component from the service
            this.$element.attr('pf-media-id', this.elementId);
        }

        options = _.assign({}, options, {
            pauseOtherPlayers: false,
            enableKeyboard: false,
            success: (mediaElement, node, instance) => {
                // set the mediaElement; used for player component binding
                this.mediaElement = mediaElement;
                // call registerId callback to let the parent components know
                // what the element id is to access the api
                this.registerId({elementId: this.elementId});
                // register the player api with the service
                this.$pfAttachmentMedia.registerPlayer(this.elementId, mediaElement, instance);
                // mediaLoaded callback
                this.onMediaLoaded();
            },
        });
        // use standalone api or player
        if (usePlayer) {
            this.player = new MediaElementPlayer(this.elementId, options);
        } else {
            this.player = new MediaElement(this.elementId, options);
        }
    }

    /**
     * @description Get the provider data
     * @param {Object} attachment The attachment to validate
     * @return {Object}
     */
    getProvider(attachment) {
        if (_.isEmpty(attachment)) {
            return {};
        }

        if (!_.isEmpty(this.provider)) {
            return this.provider;
        }

        return this.$pfAttachmentViewer.getWebsiteProvider(attachment);
    }

     /**
      * Create a unique id
      *
      * @return {string}
      * @memberof MediaEmbedController
      */
     createId() {
         const id = uuid.v4();
         return `pf-${id}`;
     }
}

AttachmentViewerController.$inject = [
    '$element',
    '$sce',
    '$pfAttachmentViewer',
    '$pfAttachmentMedia',
    '$pfMediaTypes',
    'PF_ATTACHMENT_VIEWER_TYPES',
];

/**
 * @ngdoc component
 * @name AttachmentViewerComponent
 * @module portfolium.component.attachmentViewer
 * @description Attachment viewer for media slider and other places we show attachments
 *
 * @param {object}    pfAttachment     The attachment object
 * @param {boolean}   pfIsMobile       A flag used to determine if the browser is on a mobile device
 * @param {boolean}   pfIsFullscreen   A flag used to determine if the view is fullscreen
 * @param {boolean}   pfIsAssignment   A flag used to determine if the current project is an assignment
 * @param {boolean}   pfInSlider       A flag used to determine if this component is in a slick slider
 * @param {function}  pfRegisterId     Callback to return the media element id
 * @param {function}  pfOnMediaLoaded  Callback for when this component and any associated media is loaded
 * @param {string}    pfMediaId        NOT A BINDING; This is set in registerMediaElement() and is used
 *                                     to access the media element from the $pfAttachmentMedia service
 **/
export const AttachmentViewerComponent = {
    bindings: {
        attachment: '<pfAttachment',
        isMobile: '<pfIsMobile',
        isFullscreen: '<pfIsFullscreen',
        isAssignment: '<pfIsAssignment',
        inSlider: '<pfInSlider',
        registerId: '&pfRegisterId',
        onClick: '&pfOnClick',
        onMediaLoaded: '&pfOnMediaLoaded',
    },
    controller: AttachmentViewerController,
    controllerAs: '$ctrl',
    template: AttachmentViewerTemplate,
};
