import _ from 'lodash';
import {
    SETTINGS_CLEARANCES_REQUEST,
    SETTINGS_UPDATE_CLEARANCES,
    SETTINGS_UPDATE_CLEARANCE,
} from './settings-clearances.actions';

export const settingsClearances = (state = {}, action = {}) => {

    switch (action.type) {
        case SETTINGS_CLEARANCES_REQUEST:
            return _.assign({}, state, {
                loading: true
            });
        case SETTINGS_UPDATE_CLEARANCES:
            return _.assign({}, state, {
                loading: false,
                clearances: action.payload.clearances
            });
        case SETTINGS_UPDATE_CLEARANCE:
            return _.assign({}, state, {
                clearances: state.clearances.map((clearance)=>{
                    let {granted} = clearance;
                    if (clearance.id === action.payload.clearanceId) {
                        granted = action.payload.isGranted;
                    }

                    return _.assign({}, clearance, {
                        granted: granted,
                    })
                })
            });
        default:
            return state;
    }
};
