import angular from 'angular';

import {TalentMatchAvatarComponent} from './talent-match-avatar.component';

import './talent-match-avatar.component.scss';

const MODULE_NAME = 'portfolium.component.talentMatchAvatar';

(function () {
    angular.module(MODULE_NAME, [])
        // Components
        .component('pfTalentMatchAvatar', TalentMatchAvatarComponent);
}());

export default MODULE_NAME;
