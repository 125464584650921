import UserCardExtraDenseTemplate from './user-card-extra-dense.component.html';
import '../user-card.component.scss';

import { UserCardController } from '../user-card.controller';

/**
 * @ngdoc component
 * @name UserCardExtraDenseComponent
 * @module portfolium.components.userCard
 * @param {object} user user object
 * @param {boolean} showStats should user stats be shown
 * @param {object} mdPanelRef panel reference
 * @param {string} linkTarget target attribute type for links
 **/
export const UserCardExtraDenseComponent = {
    bindings: {
        user: '<',
        showStats: '<',
        mdPanelRef: '<',
        linkTarget: '@',
    },
    template: UserCardExtraDenseTemplate,
    controller: UserCardController,
    controllerAs: '$ctrl',
};
