import angular from 'angular';

import {CONNECTION_STATUS} from '../../../user-connect/js/model/connection-status';
import {NOTIFICATION_TYPE} from '../model/notification-type';

import {clearNotificationsByTypeAndStatus} from '../action/notification-center-notifications';

import {
    NOTIFICATION_CENTER_PANEL_TABS,
    openNotificationCenterPanel,
    closeNotificationCenterPanel,
} from '../action/notification-center-panel';


export class NotificationCenterPanelService {
    constructor(
        $mdPanel,
        $ngRedux,
        $timeout,
        $pfUser,
        $mdDialog,
        $mdMedia
    ) {
        this._$mdPanel = $mdPanel;
        this._$ngRedux = $ngRedux;
        this._$timeout = $timeout;
        this._$pfUser = $pfUser;
        this._$mdDialog = $mdDialog;
        this._$mdMedia = $mdMedia;
    }

    /**
     * Create a new panel instance
     * @return {MdPanelRef}
     */
    _createPanel() {
        const isMobile = this._$mdMedia('xs');
        const width = 440;
        const windowHeight = angular.element(window).height();
        const windowWidth = angular.element(window).width();
        const animationPosition = {
            top: (isMobile) ? windowHeight : 0,
            left: (isMobile) ? 0 : windowWidth + width,
        };
        const position = this._$mdPanel.newPanelPosition()
            .absolute()
            .top(0)
            .right(0);
        const animation = this._$mdPanel.newPanelAnimation()
            .openFrom(animationPosition)
            .closeTo(animationPosition)
            .withAnimation(this._$mdPanel.animation.SLIDE);
        // TEMPLATE: layout="row" and layout-fill used to fix firefox scrolling issue
        const config = {
            controller: angular.noop,
            controllerAs: '$ctrl',
            template: `
                <pf-notification-center-panel
                     md-panel-ref="$ctrl.mdPanelRef"
                     layout="row"
                     layout-fill
                     flex>
                </pf-notification-center-panel>
            `,
            panelClass: 'pf-panel pf-notification-center-panel--sidenav pf-notification-center-panel--sidenav--white',
            attachTo: angular.element(document.body),
            position: position,
            animation: animation,
            hasBackdrop: true,
            trapFocus: true,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 39000,
            onDomRemoved: () => {
                // Update application state
                this._$ngRedux.dispatch(closeNotificationCenterPanel());

                // Remove all connection request notifications from the store that have been accepted
                this._$ngRedux.dispatch(clearNotificationsByTypeAndStatus({
                    type: NOTIFICATION_TYPE.connectionRequest,
                    status: CONNECTION_STATUS.accepted
                }));

                // Remove all connection request notifications from the store that have been rejected
                this._$ngRedux.dispatch(clearNotificationsByTypeAndStatus({
                    type: NOTIFICATION_TYPE.connectionRequest,
                    status: CONNECTION_STATUS.rejected
                }));

                // Reset panel reference
                this.panel = undefined;
                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
        };
        const panel = this._$mdPanel.create(config);

        return panel;
    }

    /**
     * Open the notification center panel
     * @return {Promise<MdPanelRef>}
     */
    open() {
        // If panel is already open, close and destroy it, intended for mobile mode use
        if (this.panel) {
            return this.panel.close().then(()=>{
                this.panel = undefined;
            });
        }

        // Initialize state
        this._$ngRedux.dispatch(openNotificationCenterPanel({
            activeTab: NOTIFICATION_CENTER_PANEL_TABS.NOTIFICATIONS,
        }));

        // Set panel reference
        this.panel = this._createPanel();

        // Hide any dialogs if they are open
        return this._$mdDialog.hide().then(() => {
            // Open the panel
            return this.panel.open();
        });
    }
}

NotificationCenterPanelService.$inject = ['$mdPanel', '$ngRedux', '$timeout', '$pfUser', '$mdDialog', '$mdMedia'];
