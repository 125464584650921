/**
 * @ngdoc directive
 * @name textStyleDirective
 * @module portfolium.core
 * @restrict A
 * @description
 * Styles a text element in accordance to the latest Portfolium Design System typography guidelines
 * @param {string} pfTextStyle The scale modifier, i.e. heading-1, heading-6, subtitle-1, body-1
 *                             (see main/sass/base/typography/typography.scss)
 * @param {string} color       The color modifier, i.e. black, white, error
 *                             (see main/sass/base/typography/_color.scss)
 * @param {string} emphasis    The emphasis modifier, i.e. high, medium
 *                             (see main/sass/base/typography/_emphasis.scss)
 * @param {string} weight      The weight modifier, i.e. regular, medium, bold
 *                             (see main/sass/base/typography/_weight.scss)
 * @param {string} alignment   The alignment modifier, i.e. left, center, right
 *                             (see main/sass/base/typography/_alignment.scss)
 * @usage
 * <span pf-text-style="heading-1" emphasis="medium">Get some</span>
 * <span pf-text-style="body-2" color="error" weight="bold" alignment="center">
 *     Something bad happened!
 * </span>
 */

export const textStyleDirective = (
    PF_TEXT_STYLES_MAP,
    PF_TEXT_STYLES_TYPES,
) => {
    /**
     * @description Validation helper
     * @param {string} modifier The name of the class modifier, i.e. 'heading-1' (from TEXT_STYLES_MAP)
     * @param {string} type The name of the modifier type, i.e. 'color', 'weight' (from PF_TEXT_STYLES_TYPES)
     * @return {boolean}
     */
    const isValid = (modifier, type) => {
        return _.isString(modifier)
            && _.has(PF_TEXT_STYLES_MAP, `${type}.${modifier}`);
    };

    /**
     * @description Transform attribute value to class name
     * @param {string} modifier The name of the class modifier, i.e. 'heading-1' (from TEXT_STYLES_MAP)
     * @param {string} type The name of the modifier type, i.e. 'color', 'weight' (from PF_TEXT_STYLES_TYPES)
     * @return {boolean}
     */
    const transformAttrValueToClass = (modifier, type) => {
        if (!_.isString(modifier) && !_.isString(type)) {
            return '';
        }
        return _.get(PF_TEXT_STYLES_MAP, `${type}.${modifier}`, '');
    };

    const updateClassWithValue = (element, type) => {
        let lastClass;

        const updateClassFn = (newModifier) => {
            const value = transformAttrValueToClass(newModifier, type);
            if (!_.isEmpty(value)) {
                if (lastClass) {
                    element.removeClass(lastClass);
                }
                lastClass = value;
                element.addClass(lastClass);
            }
        };
        return updateClassFn;
    };

    const createObserver = (scope, element, attrs, modifier, type) => {
        const updateFn = updateClassWithValue(element, type);
        const unwatch = attrs.$observe(attrs.$normalize(type), updateFn);

        updateFn(modifier);
        scope.$on('$destroy', () => {
            unwatch();
        });
    };

    return {
        restrict: 'A',
        scope: {
            pfTextStyle: '@',
            color: '@',
            emphasis: '@',
            weight: '@',
            alignment: '@',
        },
        link: function (scope, elem, attrs) {
            // add base text class
            elem.addClass('pf-text');

            // add scale class
            if (isValid(scope.pfTextStyle, PF_TEXT_STYLES_TYPES.SCALE)) {
                createObserver(scope, elem, attrs, scope.pfTextStyle, PF_TEXT_STYLES_TYPES.SCALE);
            } else {
                throw new Error('[textStyleDirective] Invalid pfTextStyle value');
            }

            // add modifier class
            _.each([
                PF_TEXT_STYLES_TYPES.COLOR,
                PF_TEXT_STYLES_TYPES.WEIGHT,
                PF_TEXT_STYLES_TYPES.ALIGNMENT,
                PF_TEXT_STYLES_TYPES.EMPHASIS,
            ], (type) => {
                // exit if the attr is not defined
                if (_.isNil(scope[type])) {
                    return;
                }

                const modifier = scope[type];
                if (isValid(modifier, type)) {
                    createObserver(scope, elem, attrs, modifier, type);
                } else {
                    throw new Error(`[textStyleDirective] Invalid ${type} value`);
                }
            });
        }
    };
};

textStyleDirective.$inject = [
    'PF_TEXT_STYLES_MAP',
    'PF_TEXT_STYLES_TYPES',
];
