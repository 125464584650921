import FollowCategoryButtonTemplate from './follow-category-button.component.html'
import './follow-category-button.component.scss';
import {followCategory, unfollowCategory} from '../category-interests.actions';

/**
 * @ngdoc controller
 * @module portfolium.component.categoryInterests
 * @name FollowCategoryButtonController
 */
class FollowCategoryButtonController {
    constructor($ngRedux, $pfEntryCategories, $pfToast) {
        this.$ngRedux = $ngRedux;
        this.$pfEntryCategories = $pfEntryCategories;
        this.$pfToast = $pfToast;
    }

    get isFollowing() {
        return this.interests.indexOf(this.categoryId) > -1;
    }

    $onInit() {
        this._unsubscribe = this.$ngRedux.connect((state) => {
            return {
                interests: state.currentUser.interests,
            };
        })(this);
    }

    $onDestroy() {
        this._unsubscribe();
    }

    /**
     * Follow or unfollow based on the current state
     */
    toggle() {
        if (this.isFollowing) {
            this.unfollow();
        } else {
            this.follow();
        }
    }

    /**
     * Follow the category
     */
    follow() {
        // Update app state immediately
        this.$ngRedux.dispatch(followCategory(this.categoryId));

        // Make API request
        this.$pfEntryCategories.followCategoryById(this.categoryId)
            .catch(() => {
                // Handle error case
                this.$pfToast.error('Something went wrong, please try again');
                this.$ngRedux.dispatch(unfollowCategory(this.categoryId))
            });
    }

    /**
     * Unfollow the category
     */
    unfollow() {
        // Update app state immediately
        this.$ngRedux.dispatch(unfollowCategory(this.categoryId));

        // Make API request
        this.$pfEntryCategories.unfollowCategoryById(this.categoryId)
            .catch(() => {
                // Handle error case
                this.$pfToast.error('Something went wrong, please try again');
                this.$ngRedux.dispatch(followCategory(this.categoryId));
            });
    }
}

FollowCategoryButtonController.$inject = [
    '$ngRedux',
    '$pfEntryCategories',
    '$pfToast',
];

/**
 * @ngdoc component
 * @module portfolium.component.categoryInterests
 * @name pfFollowCategoryButton
 */
export const FollowCategoryButtonComponent = {
    bindings: {
        categoryId: '<',
    },
    controller: FollowCategoryButtonController,
    controllerAs: '$ctrl',
    template: FollowCategoryButtonTemplate,
};
