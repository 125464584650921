import angular from 'angular';

/**
 * @ngdoc service
 * @name PopoverPanelService
 * @module portfolium.component.popover
 * @description Used in conjuction with toggle-popover-directive. Recieves a template,
 * content, and an element to attach to. Can be customized with different positions, container types,
 * and offset
 */
export class PopoverPanelService {
    constructor(
        $mdPanel,
        $mdMedia,
        PF_POPOVER_STARTING_POSITIONS
    ) {
        this._$mdPanel = $mdPanel;
        this.$mdMedia = $mdMedia;
        this.PF_POPOVER_STARTING_POSITIONS = PF_POPOVER_STARTING_POSITIONS;
    }

    /**
     * Create a new panel instance
     * @param  {string|object} config.content Content to be used in template
     * @param  {element} [config.element] Target element to attach popover to
     * @param  {string}  [config.template] Custom template for popover to use.
     * @param  {integer} [config.offsetY=4] Defines offsetY of popover
     * @param  {boolean} [config.fixed=false] Sets position as fixed/center along the target element
     * @param  {boolean} [config.usePointer=false] Tells service to use pointer popover template
     * @param  {string}  [config.startingPosition=bottom] Defines at which position to show panel around the element
     *                                                    Positions can be any of those in popover.constant POPOVER_STARTING_POSITIONS
     * @return {MdPanelRef}
     */
    _createPanel({
        mdPanelRef,
        content,
        element,
        template,
        linkTarget,
        usePointer,
        startingPosition,
        offsetY,
        fixed,
    }) {
        let panelClass = 'pf-panel pf-popover';

        if (usePointer) {
            panelClass += ' pf-popover--pointer';
        }

        // get panel position
        let position = this._getPanelPosition(
            element,
            startingPosition,
            offsetY,
            fixed,
        );

        const panelConfig = {
            controller: angular.noop,
            controllerAs: '$ctrl',
            locals: {
                content,
                linkTarget,
            },
            template,
            panelClass,
            attachTo: angular.element(document.body),
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 1000,
            propagateContainerEvents: false,
            onDomRemoved: () => {
                // Make sure the panel is destroyed when closed
                this.panel.destroy();
            },
        };

        return this._$mdPanel.create(panelConfig);
    }

    /**
     * TODO If we need to add more positions, break this out into a panel positioning service
     */
    _getPanelPosition(element, startingPosition, offsetY = 4, fixed = false) {
        // set up default position
        let position = this._$mdPanel.newPanelPosition()
            .relativeTo(element)
            .withOffsetY(panelRef => {
                if (panelRef._actualPosition.y === this._$mdPanel.yPosition.BELOW) {
                    return `${offsetY}px`;
                }
                if (panelRef._actualPosition.y === this._$mdPanel.yPosition.ABOVE) {
                    return `-${offsetY}px`;
                }
            });

        // Fixed/Top
        if (
            fixed &&
            startingPosition === this.PF_POPOVER_STARTING_POSITIONS.top
        ) {
            return position.addPanelPosition(this._$mdPanel.xPosition.CENTER, this._$mdPanel.yPosition.ABOVE);
        }

        // Default Fixed to Bottom
        if (fixed) {
            return position.addPanelPosition(this._$mdPanel.xPosition.CENTER, this._$mdPanel.yPosition.BELOW);
        }

        // Starting Top Freeflow
        if (startingPosition === this.PF_POPOVER_STARTING_POSITIONS.top) {
            return position.addPanelPosition(this._$mdPanel.xPosition.ALIGN_START, this._$mdPanel.yPosition.ABOVE)
                .addPanelPosition(this._$mdPanel.xPosition.CENTER, this._$mdPanel.yPosition.ABOVE)
                .addPanelPosition(this._$mdPanel.xPosition.ALIGN_END, this._$mdPanel.yPosition.ABOVE)
                .addPanelPosition(this._$mdPanel.xPosition.ALIGN_START, this._$mdPanel.yPosition.BELOW)
                .addPanelPosition(this._$mdPanel.xPosition.CENTER, this._$mdPanel.yPosition.BELOW)
                .addPanelPosition(this._$mdPanel.xPosition.ALIGN_END, this._$mdPanel.yPosition.BELOW);
        }

        // Default to Starting Bottom Freeflow
        return position.addPanelPosition(this._$mdPanel.xPosition.ALIGN_START, this._$mdPanel.yPosition.BELOW)
            .addPanelPosition(this._$mdPanel.xPosition.CENTER, this._$mdPanel.yPosition.BELOW)
            .addPanelPosition(this._$mdPanel.xPosition.ALIGN_END, this._$mdPanel.yPosition.BELOW)
            .addPanelPosition(this._$mdPanel.xPosition.ALIGN_START, this._$mdPanel.yPosition.ABOVE)
            .addPanelPosition(this._$mdPanel.xPosition.CENTER, this._$mdPanel.yPosition.ABOVE)
            .addPanelPosition(this._$mdPanel.xPosition.ALIGN_END, this._$mdPanel.yPosition.ABOVE);
    }

    /**
     * Open the popover panel
     * @param  {object}  config  Configuration object for popover
     * @return {promise<MdPanelRef>}
     */
    open(config) {
        if (this.panel) {
            return this.panel.close().then(()=>{
                // create panel
                this.panel = this._createPanel(config);
                // Open the panel
                return this.panel.open();
            });
        }

        // create panel
        this.panel = this._createPanel(config);
        // Open the panel
        return this.panel.open();
    }
}

PopoverPanelService.$inject = [
    '$mdPanel',
    '$mdMedia',
    'PF_POPOVER_STARTING_POSITIONS'
];
