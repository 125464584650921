export class CookiesService {
    constructor($cookies, $window, PF_COOKIES, PF_COOKIES_OPTIONS) {
        this.$cookies = $cookies;
        this.$window = $window;
        this.PF_COOKIES = PF_COOKIES;
        this.PF_COOKIES_OPTIONS = PF_COOKIES_OPTIONS;
    }

    getUsersCookieOptions() {
        // get Portfolium session data
        const { Portfolium } = this.$window;
        // sanity check for info is there
        if (!_.isEmpty(Portfolium)) {
            // get current user session data
            const { current_user } = Portfolium;
            // sanity check for info is there
            if (!_.isEmpty(current_user)) {
                // get cookie options from session data
                const { cookie_options } = current_user;
                // sanity check for info is there and return if it is
                if (!_.isEmpty(cookie_options)) {
                    // make the values be boolean
                    return _.mapValues(cookie_options, option => {
                        // boolean it up
                        return option === 'true' || option === true;
                    });
                }
            }
        }
        // return null if something is wrong
        return null;
    }

    get(name) {
        // default cookie instance
        let cookieInstance = null;
        // sanity check if we can use it
        if (this.isCookieActive(name)) {
            // update the cookie instance
            cookieInstance = this.$cookies.get(name);
        }
        // return cookie instance
        return cookieInstance;
    }

    // default value for the cookie is samesite: none
    put(name, value = '', options) {
        // sanity check if we can use it
        if (this.isCookieActive(name)) {
            if (!('samesite' in options)) {
                options.samesite = 'none';
            }
            if (!('secure' in options)) {
                options.secure = true;
            }
            // set the cookie
            this.$cookies.put(name, value, options);
        }
    }

    remove(name, options = {}) {
        // remove the cookie
        this.$cookies.remove(name, options);
    }

    isCookieActive(name) {
        // get cookie options
        const cookieOptions = this.getUsersCookieOptions();
        // default is acookie active to show
        let isCookieActiveToShow = true;
        // loop over options
        _.forEach(cookieOptions, (isCookieActive, cookieOption) => {
            // check if the cookie is in the no active group
            let isCookieInOption = this.isCookieInOption(name, cookieOption);
            // sanity check for cookie option is no active
            if (isCookieInOption) {
                // set the new value
                isCookieActiveToShow = isCookieActive;
                // found break it
                return false;
            }
        });
        // return the flag
        return isCookieActiveToShow;
    }

    isFunctionalCookiesEnable() {
        // get cookie options
        const cookieOptions = this.getUsersCookieOptions();
        // sanity check for cookie options
        if (
            !_.isEmpty(cookieOptions) &&
            !_.isNil(cookieOptions.functional_cookies)
        ) {
            // return the flag
            return cookieOptions.functional_cookies;
        }
        // reurn functional flag
        return true;
    }

    isAnalyticalCookiesEnable() {
        // get cookie options
        const cookieOptions = this.getUsersCookieOptions();
        // sanity check for cookie options
        if (
            !_.isEmpty(cookieOptions) &&
            !_.isNil(cookieOptions.analytical_cookies)
        ) {
            // return the flag
            return cookieOptions.analytical_cookies;
        }
        // reurn analytical flag
        return true;
    }

    isCookieInOption(name, option) {
        // get the list of cookies for group
        const cookiesGroupList = this.PF_COOKIES_OPTIONS[option];
        // default find index
        let find = false;
        // sanity check
        if (cookiesGroupList && cookiesGroupList.length > 0) {
            // find in group
            find = _.reduce(
                cookiesGroupList,
                (result, value, index) => {
                    // get name of the iterating cookie
                    const currentName = this.PF_COOKIES[value];
                    // check if it is the one
                    return result || currentName === name;
                },
                false,
            );
        }
        // return if it was found
        return find;
    }
}

CookiesService.$inject = [
    '$cookies',
    '$window',
    'PF_COOKIES',
    'PF_COOKIES_OPTIONS',
];
