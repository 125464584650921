class SignInController {
    constructor(
        $transitions,
        $q,
        $state,
        $timeout,
        $mdDialog,
        $pfSignInAuth,
        $pfSignInIdentity,
        $pfSignInHistory,
        $pfAuth,
        $pfNetwork,
        $pfToast,
        $pfEventTracker,
        $pfCookies,
        $pfEnv,
        PF_COOKIES,
        $window,
        domainFilter,
        subdomainNetwork,
    ) {
        this.$state = $state;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.$pfSignInAuth = $pfSignInAuth;
        this.$pfSignInIdentity = $pfSignInIdentity;
        this.$pfSignInHistory = $pfSignInHistory;
        this.$pfAuth = $pfAuth;
        this.$pfNetwork = $pfNetwork;
        this.$pfToast = $pfToast;
        this.$pfEventTracker = $pfEventTracker;
        this.$pfCookies = $pfCookies;
        this.$pfEnv = $pfEnv;
        this.PF_COOKIES = PF_COOKIES;
        this.domainFilter = domainFilter;
        this.subdomainNetwork = subdomainNetwork;
        this.globals = $window.Portfolium || {};
        // Clear out identity data from service
        this.$pfSignInIdentity.clearIdentity();
        // Preset model values
        this.form = {};
        this.direction = 'forward';
        this.loading = false;
        this.stateCustomText = _.has(this.$state.params.modalAttrs, 'title')
            ? this.$state.params.modalAttrs.title
            : null;
        // Listen for state change start event
        $transitions.onStart({}, this.handleStateChange.bind(this));
        this.customText =
            this.globals.flashData.alert.alert || this.stateCustomText;
    }

    get profile() {
        return this.$pfSignInIdentity.profile;
    }

    get isSubdomainNetwork() {
        return this.subdomainNetwork && !!this.subdomainNetwork.id;
    }

    get isSingleSignOnNetwork() {
        if (this.isSubdomainNetwork) {
            return (
                !!this.subdomainNetwork.id &&
                this.subdomainNetwork.sso === '1' &&
                this.subdomainNetwork.sso_details &&
                !!this.subdomainNetwork.sso_details.url
            );
        }

        return this.$pfSignInIdentity.isSingleSignOnNetwork;
    }

    get sso() {
        if (this.isSubdomainNetwork) {
            return (
                this.isSingleSignOnNetwork &&
                this.subdomainNetwork.sso === '1' &&
                this.subdomainNetwork.sso_details
            );
        }

        return (
            this.isSingleSignOnNetwork &&
            this.$pfSignInIdentity.network.sso === '1' &&
            this.$pfSignInIdentity.network.sso_details
        );
    }

    get network() {
        if (this.isSubdomainNetwork) {
            return this.subdomainNetwork;
        }

        return this.$pfSignInIdentity.network;
    }

    get hasPreviousStep() {
        return this.$pfSignInHistory.getHistory().length > 0;
    }

    get showProfile() {
        return !!this.profile.id;
    }

    get showNetwork() {
        return !!this.network.id;
    }

    get showEmptyNetwork() {
        return this.$state.current.name === 'register.network';
    }

    get showNetworkBgImage() {
        return this.showNetwork && !_.isNull(this.network.background.source);
    }

    get currentDate() {
        return new Date();
    }

    /**
     * State change listener to make sure modal is closed when state changes
     * away from simpleSignInModal
     * @param  {Transition}  trans    $stateChangeStart event
     */
    handleStateChange(trans) {
        const toState = trans.to();
        if (
            this.$pfSignInHistory.isModal() &&
            !toState.$$state().includes.simpleSignInModal
        ) {
            this.$mdDialog.hide();
        }
    }

    /**
     * Close the dialog (if state is within a dialog)
     * @return {undefined|Promise}
     */
    closeDialog() {
        if (!this.$mdDialog) {
            return;
        }

        return this.$mdDialog.cancel();
    }

    /**
     * Move directly to another state, without effecting history or updating
     * animation. If inside modal, make sure toState path is updated with modal
     * state name
     * @param  {String}  toStateName   State name to transition to
     * @param  {Object}  toStateParams State params to pass to new state
     * @return {Promise}
     */
    go(toStateName, toStateParams) {
        return this.$pfSignInHistory
            .next(toStateName, toStateParams, {
                bypassHistory: true,
            })
            .then(() => {
                this.form.$setPristine();
            });
    }

    /**
     * Move forwards to another step
     * @param  {String}   toStateName   State name to transition to
     * @param  {Object}   toStateParams State params to pass to new state
     * @param  {Boolean}  validate      Whether or not to validate the form
     * @return {Promise}
     */
    next(toStateName, toStateParams, validate = true) {
        this.direction = 'forward';

        if (validate && !this.isValid()) {
            return;
        }

        return this.$timeout(() => {
            return this.$pfSignInHistory
                .next(toStateName, toStateParams)
                .then(() => {
                    this.form.$setPristine();
                });
        });
    }

    /**
     * Move backwards to last step
     * @return {Promise}
     */
    back() {
        this.direction = 'backward';

        return this.$timeout(() => {
            return this.$pfSignInHistory.back().then(() => {
                this.form.$setPristine();
            });
        });
    }

    /**
     * Validate form and set dirty/pristine flags
     * @return {Boolean} Is the form valid?
     */
    isValid() {
        if (!this.form.$valid) {
            return false;
        }

        this.form.$setPristine();

        return true;
    }

    /**
     * Check if the email exists in the system and handle routing accordingly
     * @param  {String}  identity Value to check for a valid identity
     * @return {Promise}
     */
    checkIdentity(identity) {
        if (!this.isValid()) {
            return;
        }
        this.loading = true;
        // Check the user's identity and then handle routing based on response
        return this.$pfAuth
            .getProfileByIndentity(identity)
            .then(
                response => {
                    let profile = response.data;

                    // Save the profile in memory
                    this.$pfSignInIdentity.profile = profile;
                    if (response.status === 204) {
                        this.$pfEventTracker.track('Login Started', {
                            matched: false,
                        });
                        // No matching user record found
                        return this.onUserDoesntMatch(identity);
                    }

                    if (this.$pfSignInIdentity.isUserOnboarded) {
                        this.$pfEventTracker.track('Login Started', {
                            matched: true,
                            onboarded: true,
                        });
                        // Matched user that is already onboarded
                        return this.onUserOnboarded(profile, identity);
                    }

                    this.$pfEventTracker.track('Login Started', {
                        matched: true,
                        onboarded: false,
                    });
                    // Matched user that hasn't onboarded
                    return this.onUserNotOnboarded(profile, identity);
                },
                ({ status }) => {
                    // 'coppaError'
                    if (status === 412) {
                        return this.$pfAuth.triggerCoppaError();
                    }
                },
            )
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * Routing logic for an identity value that isn't found in the database
     * @param  {String}  identity Username or email
     * @return {Promise}
     */
    onUserDoesntMatch(identity) {
        if (!this.$pfSignInIdentity.isEmail(identity)) {
            // User attempted to sign in with a username and it didn't match
            this.$pfToast.error(
                'There is no account with this username. Please enter an email or try again.',
            );

            return this.$q.reject();
        }

        // Save a cookie since this is an unknown Email
        this.$pfCookies.put(this.PF_COOKIES.LOGIN_EMAIL, identity, {
            path: '/',
            domain: this.$pfEnv.getEnv('PF_COOKIE_DOMAIN'),
        });

        if (this.isSubdomainNetwork) {
            this.$pfSignInIdentity.network = this.subdomainNetwork;

            if (this.$pfSignInIdentity.isPrepoppedNetwork) {
                // User came from a prepopped network subdomain page,
                // route to existing network step
                return this.next('register.existing', {
                    identity: identity,
                    clear: false,
                });
            }

            // User came from a non-prepoppoed network subdomain page,
            // create an account immediately
            return this.$pfSignInAuth.createAccount(identity);
        }

        // Route to network step
        return this.next('register.network', {
            identity: identity,
            clear: false,
        });
    }

    /**
     * Routing logic for a user that is found in the database and has already
     * onboarded
     * @param  {Object}  profile  Profile object from API
     * @param  {String}  identity Username or email
     * @return {Promise}
     */
    onUserOnboarded(profile, identity) {
        // Get the user's network (if they have one)
        return this.getNetwork(profile.id).finally(() => {
            return new Promise((resolveInner) => {
                setTimeout(resolveInner, 0);
            });
        });
    }

    /**
     * Routing logic for a user that is found in the database but hasn't onboarded
     * @param  {Object}  profile  Profile object from API
     * @param  {String}  identity Username or email
     * @return {Promise}
     */
    onUserNotOnboarded(profile, identity) {
        // Get the user's network
        return this.getNetwork(profile.id).then(
            () => {
                // Got network -- route to existing user step
                return this.next('register.existing', {
                    identity: identity,
                    clear: false,
                });
            },
            () => {
                // No network or request failed -- route to network step
                return this.next('register.network', {
                    identity: identity,
                    clear: false,
                });
            },
        );
    }

    /**
     * Get a the user's network by user ID
     * @param  {String}  userId User ID to look up
     * @return {Promise}
     */
    getNetwork(userId) {
        if (!userId) {
            return this.$q.reject();
        }

        return this.$pfNetwork.getNetworksByUserId(userId).then(networks => {
            // Use the first network for now
            // @todo: get primary network instead
            let network = networks[0] || {};
            // Save a copy of the network
            this.$pfSignInIdentity.network = network;

            return network;
        });
    }

    /**
     * Callback for when a user authenticates with a social account but has no
     * Portfolium account. In this case, redirect to register step with email
     * pre-filled
     * @param  {Object}  data Social profile, social client, and auth token data
     * @return {Promise}
     */
    onUnauthedSocialLogin(data) {
        // Save social and network data on identity service
        this.$pfSignInIdentity.social = data;

        // sanity check if the network was set
        if (data.profile.network) {
            this.$pfSignInIdentity.network = data.profile.network;
        }

        if (this.isSubdomainNetwork) {
            this.$pfSignInIdentity.network = this.subdomainNetwork;

            if (this.$pfSignInIdentity.isPrepoppedNetwork) {
                // User came from a prepopped network subdomain page,
                // route to existing network step
                return this.next('register.existing', null, false);
            }

            // User came from a non-prepoppoed network subdomain page,
            // create an account immediately
            return this.$pfSignInAuth.createAccount(data.profile.email);
        }

        // Route to network step (use social data as email)
        return this.next('register.network', null, false);
    }

    /**
     * Redirect to Single Sign-On page
     */
    ssoLogin() {
        let redirectUrl;

        if (!this.sso) {
            return;
        }

        redirectUrl = this.domainFilter(this.sso.url);

        // redirect the URL and ignore the return cookie if one was set
        this.$pfAuth.redirect(redirectUrl, false);
    }
}

SignInController.$inject = [
    '$transitions',
    '$q',
    '$state',
    '$timeout',
    '$mdDialog',
    '$pfSignInAuth',
    '$pfSignInIdentity',
    '$pfSignInHistory',
    '$pfAuth',
    '$pfNetwork',
    '$pfToast',
    '$pfEventTracker',
    '$pfCookies',
    '$pfEnv',
    'PF_COOKIES',
    '$window',
    'domainFilter',
    'subdomainNetwork',
];

export default SignInController;
