import EntryViewerCommentTemplate from './entry-viewer-comment.component.html';
import './entry-viewer-comment.component.scss';

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerCommentController
 *
 */
class EntryViewerCommentController {
    constructor(
        $pfAuth,
        PF_USER_CARD_TEMPLATES,
        PF_POPOVER_STARTING_POSITIONS,
    ) {
        this.$pfAuth = $pfAuth;
        this.PF_USER_CARD_TEMPLATES = PF_USER_CARD_TEMPLATES;
        this.PF_POPOVER_STARTING_POSITIONS = PF_POPOVER_STARTING_POSITIONS;
    }

    /**
     * Can the current user delete this comment
     * @return {Boolean}
     */
    canDeleteComment() {
        if (!this.comment) {
            return;
        }

        if (!_.isNil(this.isEntryOwner) && this.isEntryOwner) {
            return true;
        }

        // Does current user own comment?
        return this.$pfAuth
            .isAuthenticatedUser(this.comment.fk_user_id);
    }

    /**
     * Request to remove comment
     * @param  {number} commentId
     */
    deleteComment(commentId) {
        this.onDeleteComment({commentId: this.comment.id});
    }
}

EntryViewerCommentController.$inject = [
    '$pfAuth',
    'PF_USER_CARD_TEMPLATES',
    'PF_POPOVER_STARTING_POSITIONS',
];

/**
 * @ngdoc component
 * @module portfolium.component.entryViewer
 * @name EntryViewerCommentComponent
 * @description Display a single comment
 *
 * @param {Boolean} isEntryOwner is currently authed user the entry owner
 * @param {Boolean} isPreview is this a ...preview?
 * @param {Object} comment Comment to display
 * @param {Callback} onDeleteComment request to delete a comment
 */
export const EntryViewerCommentComponent = {
    bindings: {
        isEntryOwner: '<',
        isPreview: '<',
        comment: '<',
        onDeleteComment: '&',
    },
    controller: EntryViewerCommentController,
    controllerAs: '$ctrl',
    template: EntryViewerCommentTemplate,
};
