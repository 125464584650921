import SkillPillListTemplate from './skill-pill-list.component.html';

/**
 * @ngdoc component
 * @name SkillPillListComponent
 * @module portfolium.entryEditor
 * @param {array} skills Skill name
 **/
export const SkillPillListComponent = {
    bindings: {
        skills: '<',
        limit: '<',
    },
    template: SkillPillListTemplate,
    controller: () => { },
};
