const BADGE_REPORTING_REASONS = [
    'I think this is a fake badge',
    'I think this badge is inappropriate',
    'I think this badge violates Terms of Use',
];

export const CONTENT_REPORTING_TYPES = {
    project: 'entry',
    profile: 'user',
    badge: 'badge',
    userBadge: 'user_badge',
};

export const CONTENT_REPORTING_REASONS = [
    {
        type: CONTENT_REPORTING_TYPES.project,
        reasons: [
            'I think it contains spam',
            'I think it contains a copyright or trademark violation',
            'I think it contains offensive material',
            'I think it contains adult content',
        ],
    },
    {
        type: CONTENT_REPORTING_TYPES.profile,
        reasons: [
            'I think this profile violates the Terms of Service',
            'I don\'t think this profile represents a real individual',
            'I think this person is impersonating someone',
            'I think this account may have been hacked',
        ],
    },
    {
        type: CONTENT_REPORTING_TYPES.badge,
        reasons: BADGE_REPORTING_REASONS,
    },
    {
        type: CONTENT_REPORTING_TYPES.userBadge,
        reasons: BADGE_REPORTING_REASONS,
    },
];
