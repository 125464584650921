import CandyBarTemplate from './candy-bar.component.html';
import './candy-bar.component.scss';

/**
 * TODO: When needed, update this to behave like the candybar on EDU
 */

/**
 * @ngdoc component
 * @name CandyBarComponent
 * @module portfolium.component
 * @description
 */
export const CandyBarComponent = {
    transclude: true,
    template: CandyBarTemplate,
};
