import SearchInputTemplate from './search-input.component.html';
import './search-input.component.scss';

/**
 * @ngdoc controller
 * @name SearchInputController
 * @module portfolium.component.forms.formComponents
 */
class SearchInputController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        this.$input = this.$element.find('input').eq(0);

        // set up some defaults
        this.role = this.role || 'textbox';

        this.screenReaderLabel = this.screenReaderLabel || 'Search';
        this.loadingDiameter = this.loadingDiameter || 32;
    }

    get maxLength() {
        return this._maxLength || '100';
    }

    get ngModelOptions() {
        const debounceTime = this.debounce && parseInt(this.debounce, 10);

        return {
            debounce: {
                'default': _.isNumber(debounceTime) ? debounceTime : 300,
                'blur': 0,
            },
        };
    }

    get placeholder() {
        return this._placeholder || 'Search...';
    }

    /**
     * Clear the current search query
     */
    clearSearch() {
        // Cleae the model
        this.searchText = '';
        // Set focus back to search input
        this.$input.focus();
        // Trigger change event
        this.onChange({query: ''});
    }

    /**
     * Focus callback
     */
    onFocus() {
        this.$element.addClass('pf-form-control-focus');
        this.handleFocus();
    }

    /**
     * Blur callback
     */
    onBlur() {
        this.$element.removeClass('pf-form-control-focus');
        this.handleBlur();
    }
}

SearchInputController.$inject = [
    '$element',
];

/**
 * @ngdoc component
 * @name pfSearchInput
 * @module portfolium.component.forms.formComponents
 * @description
 * Default search input box component, with loading state and clear button
 * @param {string} [max-length='100'] Maximum characters allowed for the search input
 * @param {string} [id] Id of the element
 * @param {string} [placeholder='Search...'] Placeholder text for the input
 * @param {string} [debounce='300'] Delay (in milliseconds) before updating search query value
 * @param {string} [ariaLabel='Search...'] Aria label for the search input, this could be more descriptive than the placeholder
 * @param {boolean} [disabled=false] Flag to enable/disable the search input
 * @param {number} [loadingDiameter] diameter of the loading spinner
 * @param {boolean} [loading=false] Flag to show/hide loading spinner
 * @param {Function<any>} [on-change] Callback function to execute when query is updated.
 *     A `query` argument is passed to this function with the updated value
 * @param {string} search-text Query string value
 * @param {string} inputClass custom class applied to input
 * @param {string} [role='textbox'] What is the role of this input
 * @param {string} ariaControls Which element this input control
 * @param {string} ariaHasPopup Does this input have a pop up element associated with it
 * @param {string} ariaExpanded Is the associated pop up element open or closed
 * @param {string} ariaDescribedby Is there a descriptive tooltip or something?
 */
export const SearchInputComponent = {
    bindings: {
        _maxLength: '<maxLength',
        _placeholder: '<placeholder',
        id: '@pfInputId',
        debounce: '@',
        screenReaderLabel: '@pfScreenReaderLabel',
        role: '@pfRole',
        ariaControls: '@pfAriaControls',
        ariaHasPopup: '@pfAriaHasPopup',
        ariaExpanded: '@pfAriaExpanded',
        ariaDescribedby: '@pfAriaDescribedby',
        selectedMenuItem: '<pfSelectedMenuItem',
        disabled: '<',
        loadingDiameter: '<',
        loading: '<',
        searchText: '<',
        inputClass: '<',
        onChange: '&',
        handleFocus: '&onFocus',
        handleBlur: '&onBlur',
    },
    controller: SearchInputController,
    controllerAs: '$ctrl',
    template: SearchInputTemplate,
}
