export const UPDATE_COMPETENCY_OPTIONS = 'UPDATE_COMPETENCY_OPTIONS';

export const updateCompetencyOptions = (competencies, selected) => {
    return (dispatch, getState) => {
        const {competencyLibrary} = getState();

        dispatch({
            type: UPDATE_COMPETENCY_OPTIONS,
            payload: {
                categories: competencyLibrary.competencyCategories,
                competencies,
                selected,
            },
        });
    };
};
