import '../../auth-modal.scss'

export class RedirectAuthModalController {
    constructor(
        $pfToast,
        $pfEnv,
        $mdDialog,
        $pfAuthProvider,
        provider,
        data
    ) {
        this.$pfToast = $pfToast;
        this.$mdDialog = $mdDialog;
        this.$pfAuthProvider = $pfAuthProvider;

        // set up the provider
        this.providerName = provider.displayName;
        this.providerLogo = provider.logoUrl;
        this.provider = $pfAuthProvider.get(provider.name);

        this.selectedUrl = '';
        this.data = data;
    }

    /**
     * @description Close the modal, rejecting the promise
     */
    close() {
        this.$mdDialog.hide();
    }

    redirect() {
        window.location = this.selectedUrl;
    }
}

RedirectAuthModalController.$inject = [
    '$pfToast',
    '$pfEnv',
    '$mdDialog',
    '$pfAuthProvider',
    'provider',
    'data'
];
