import BaseModalController from 'components/modal/js/controller/base-modal-controller';

export class GenericModalController extends BaseModalController {
    constructor($mdDialog) {
        super($mdDialog);
    }
}

GenericModalController.$inject = [
    '$mdDialog',
];
