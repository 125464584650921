import {
    removePendingUser,
} from 'components/user-connect/user-connect-btn/user-connect.actions';
import UserBlockingModalTemplate from './user-blocking-modal.component.html';

/**
 * @ngdoc controller
 * @module portfolium.component
 * @name UserBlockingModalController
 **/
class UserBlockingModalController {
    constructor($pfToast, $pfUserBlocking, $state, $ngRedux) {
        this.$pfToast = $pfToast;
        this.$pfUserBlocking = $pfUserBlocking;
        this.$state = $state;
        this.$ngRedux = $ngRedux;
    }

    handleUserBlock() {
        // block em
        return this.$pfUserBlocking
            .blockUser(this.user.id)
            .then(() => {
                // cancel pending connection request (since api does this)
                this.$ngRedux.dispatch(
                    removePendingUser(this.user.id)
                );
                // toast it
                this.$pfToast.success('User blocked');
                // close it
                this.onCancel();
                // relaod state
                this.$state.reload();
            }, () => {
                // toast it
                this.$pfToast.error('Oops, something went wrong');
            });
    }
}

UserBlockingModalController.$inject = ['$pfToast', '$pfUserBlocking', '$state', '$ngRedux'];

export const UserBlockingModalComponent = {
    bindings: {
        user: '<pfUser',
        onCancel: '&pfOnCancel',
    },
    template: UserBlockingModalTemplate,
    controller: UserBlockingModalController,
    controllerAs: '$ctrl',
    bindToController: true
};
