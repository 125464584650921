import moment from 'moment';

class ClassYearService
{
    constructor() {

    }

    /**
     * Get the current class year
     * @param   {Integer}  delta    The value to add to the returned class year
     * @return  {Integer}           The current class year
     */
    classYear(delta = 0) {
        const cutOffMonth = 'June';
        const cutOffDate = 15;

        // get current year
        const currentYear = moment().year();
        // get the graduation cutoff date as a moment object
        const gradCutOff = moment().year(currentYear).month(cutOffMonth).date(cutOffDate);
        // return the grad year
        return (moment().valueOf() < gradCutOff.valueOf()) ? (currentYear + delta) : (currentYear + delta + 1);
    }

    /**
     * Get the freshman class year
     * @return  {Integer}    The freshman class year
     */
    freshmanClassYear() {
        return this.classYear(3);
    }

    /**
     * Get the sophmore class year
     * @return  {Integer}    The sophmore class year
     */
    sophmoreClassYear() {
        return this.classYear(2);
    }

    /**
     * Get the junior class year
     * @return  {Integer}    The junior class year
     */
    juniorClassYear() {
        return this.classYear(1);
    }

    /**
     * Get the senior class year
     * @return  {Integer}    The senior class year
     */
    seniorClassYear() {
        return this.classYear(0);
    }
}

export default ClassYearService;
