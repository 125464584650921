export const TOGGLE_ABOUT_SECTION_VISIBILITY  = 'TOGGLE_ABOUT_SECTION_VISIBILITY';
export const TOGGLE_ABOUT_SECTION_VISIBLE_ITEM  = 'TOGGLE_ABOUT_SECTION_VISIBLE_ITEM';
export const UPDATE_ABOUT_SECTION_ORDER = 'UPDATE_ABOUT_SECTION_ORDER';

export const toggleVisibleItem = (id, sectionName) => {
    return {
        type: TOGGLE_ABOUT_SECTION_VISIBLE_ITEM,
        sectionName,
        payload: id,
    };
};

export const updateAboutSectionOrder = (order) => {
    return {
        type: UPDATE_ABOUT_SECTION_ORDER,
        payload: order,
    };
};
