import moment from 'moment';

/**
 * @ngdoc service
 * @name ChallengesUtilitiesService
 * @module portfolium.core
 **/
 export class ChallengesUtilitiesService {
     constructor($filter) {
        this.$filter = $filter;
    }

    /**
     * Return the days left in challenge
     * @param {object} challenge challenge details
     */
    daysLeft(challenge) {
        if (_.isNil(challenge) || !_.isObject(challenge)) {
            return undefined;
        }

        const date = moment();
        const localDueDate = this.$filter('momentUtcDateLocalTime')(challenge.expires_on);

        return moment(localDueDate).diff(date, 'days');
    }

    /**
     * Is the challenge expired?
     * @param {object} challenge challenge details
     */
    isChallengeExpired(challenge) {
        if (_.isNil(challenge) || !_.isObject(challenge)) {
            return undefined;
        }

        const date = moment();
        const localDueDate = this.$filter('momentUtcDateLocalTime')(challenge.expires_on);
        const daysLeft = moment(localDueDate).diff(date, 'days');
        const minutesLeft = moment(localDueDate).diff(date, 'minute');

        if (
            (daysLeft > 0) ||
            (daysLeft === 0 && minutesLeft > 0)
        ) {
            return false;
        }

        if (daysLeft <= 0 && minutesLeft <= 0) {
            return true;
        }
    }

}

ChallengesUtilitiesService.$inject = ['$filter'];
