export class VimeoService {
    constructor($q, $injector, $pfUrlUtils) {
        this.$q = $q;
        this.$injector = $injector;
        this.$pfUrlUtils = $pfUrlUtils;

        this.provider = 'vimeo';
        // these params will get added to the embed URL
        this.urlParams = {};
    }

    /**
     * Get the URL used to embed the media
     * @param [String]   url        The media URL
     * @return [String]             The url used for embedded media
     */
    getEmbedUrl(url) {
        // MediaElement framework validates vimeo urls
        return this.$pfUrlUtils.enforceHttps(url);
    }
}

VimeoService.$inject = [
    '$q',
    '$injector',
    '$pfUrlUtils',
];
