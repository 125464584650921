/**
 * @ngdoc service
 * @name EntryDetailsService
 * @module portfolium.core.services.entries
 * @description Service for fetching full entry details
 * @class EntryDetailsService
 */
export class EntryDetailsService {
    constructor($http, $q, PF_ENTRY_CLASSIFICATIONS) {
        this.$http = $http;
        this.$q = $q;
        this.PF_ENTRY_CLASSIFICATIONS = PF_ENTRY_CLASSIFICATIONS;
    }

    /**
     * Get a draft object for the entry editor by entry ID
     *
     * @param {string} entryId Entry ID to fetch
     * @param {object} [params] Optional query params
     * @returns {Promise<object>} Resolved with an entry details object
     * @memberof EntryDetailsService
     */
    getEntryDetailsById(entryId, params = {}) {
        if (!_.isString(entryId)) {
            return this.$q.reject();
        }

        return this.$http({
            method: 'GET',
            url: `/proxy/entries/builder/${entryId}`,
            params,
        }).then(response => response.data);
    }

    /**
     * Get a draft object for the entry editor by entry ID
     *
     * @param {string} slug Entry slug to fetch
     * @param {object} [params] Optional query params
     * @returns {Promise<object>} Resolved with an entry details object
     * @memberof EntryDetailsService
     */
    getEntryDetailsBySlug(entrySlug, params = {}) {
        if (!_.isString(entrySlug)) {
            return this.$q.reject();
        }

        return this.$http({
            method: 'GET',
            url: `/proxy/entries/builder/${entrySlug}/slug`,
            params,
        }).then(response => response.data);
    }

    /**
     * Get the classification of the entry being worked on
     * @param {object} entry
     * @returns {string} entry classifiaction
     */
    getEntryClassification(entry) {
        const hasAssignmentData = !_.isEmpty(entry.assignment);
        const isRequirement =
            hasAssignmentData && _.isEmpty(entry.assignment.course_id);
        const isAssignment = hasAssignmentData && !isRequirement;

        /**
         * If an assignment/requirement is NOT submittable, it is being treated as "user created project"
         */
        const isSubmittable = hasAssignmentData && entry.assignment.is_submittable === '1';

        if (isAssignment && isSubmittable) {
            return this.PF_ENTRY_CLASSIFICATIONS.assignment;
        }

        if (isRequirement && isSubmittable) {
            return this.PF_ENTRY_CLASSIFICATIONS.requirement;
        }

        return this.PF_ENTRY_CLASSIFICATIONS.default;
    }
}

EntryDetailsService.$inject = ['$http', '$q', 'PF_ENTRY_CLASSIFICATIONS'];
