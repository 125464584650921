/**
 * This directive allows the developer to fire an event when the element is clicked.
 * A delay ensures the request has time to fire before page reload.
 */
export const eventTrackerDirective = (
    $timeout,
    $window,
    $pfEventTracker
) => {
    const delay = 300;

    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            let {href} = attrs;
            let sref = attrs.uiSref;
            let trackServer = attrs.pfEventTrackerServer;
            let eventName = scope.$eval(attrs.pfEventTracker);
            let properties = scope.$eval(attrs.pfEventProperties);

            if (!_.isString(eventName) || !eventName.length) {
                throw new Error('[pfEventTracker] You must provie an event name!');
            }

            elem.on('click', ($event) => {
                if (trackServer) {
                    $pfEventTracker.trackServer(eventName, properties);
                } else {
                    // Track the event
                    $pfEventTracker.track(eventName, properties);
                }

                if (href && !sref && elem.target !== '_blank') {
                    // Block the standard click event
                    $event.preventDefault();

                    // Redirect after short delay
                    $timeout(() => {
                        $window.location.href = href;
                    }, delay);
                }
            });
        }
    };
};

eventTrackerDirective.$inject = [
    '$timeout',
    '$window',
    '$pfEventTracker',
];
