export const TEXT_STYLES_TYPES = {
    SCALE: 'scale',
    // modifier types
    COLOR: 'color',
    WEIGHT: 'weight',
    ALIGNMENT: 'alignment',
    EMPHASIS: 'emphasis',
}

export const TEXT_STYLES_MAP = {
    [TEXT_STYLES_TYPES.SCALE]: {
        'heading-1': 'pf-text--heading-1',
        'heading-2': 'pf-text--heading-2',
        'heading-3': 'pf-text--heading-3',
        'heading-4': 'pf-text--heading-4',
        'heading-5': 'pf-text--heading-5',
        'heading-6': 'pf-text--heading-6',
        'subtitle-1': 'pf-text--subtitle-1',
        'subtitle-2': 'pf-text--subtitle-2',
        'body-1': 'pf-text--body-1',
        'body-2': 'pf-text--body-2',
        'button-1': 'pf-text--button-1',
        'caption-1': 'pf-text--caption-1',
        'overline-1': 'pf-text--overline-1',
    },
    [TEXT_STYLES_TYPES.COLOR]: {
        'primary': 'pf-text--color-primary',
        'black': 'pf-text--color-black',
        'white': 'pf-text--color-white',
        'error': 'pf-text--color-error',
    },
    [TEXT_STYLES_TYPES.WEIGHT]: {
        'regular': 'pf-text--weight-regular',
        'medium': 'pf-text--weight-medium',
        'bold': 'pf-text--weight-bold',
    },
    [TEXT_STYLES_TYPES.ALIGNMENT]: {
        'left': 'pf-text--alignment-left',
        'center': 'pf-text--alignment-center',
        'right': 'pf-text--alignment-right',
    },
    [TEXT_STYLES_TYPES.EMPHASIS]: {
        'high': 'pf-text--emphasis-high',
        'medium': 'pf-text--emphasis-medium',
        'low': 'pf-text--emphasis-low',
    },
};
