import angular from 'angular';

import {
    panelOpen,
    panelClose,
} from './export-profile.actions.js';

/**
 * @ngdoc service
 * @name ExportProfilePanelService
 * @module portfolium.component.exportProfile
 **/
export class ExportProfilePanelService {
    constructor(
        $mdPanel,
        $ngRedux,
        $mdDialog,
        $mdMedia
    ) {
        this.$mdPanel = $mdPanel;
        this.$ngRedux = $ngRedux;
        this.$mdDialog = $mdDialog;
        this.$mdMedia = $mdMedia;
    }

    /**
     * Create a new panel instance
     * @return {MdPanelRef}
     */
    _createPanel() {
        const isMobile = !this.$mdMedia('gt-xs');
        const width = 512;
        const windowHeight = angular.element(window).height();
        const animationPosition = {
            top: (isMobile) ? windowHeight : 0,
            left: (isMobile) ? 0 : -width,
        };
        const position = this.$mdPanel.newPanelPosition()
            .absolute()
            .top(0)
            .left(0);
        const animation = this.$mdPanel.newPanelAnimation()
            .openFrom(animationPosition)
            .closeTo(animationPosition)
            .withAnimation(this.$mdPanel.animation.SLIDE);

        const config = {
            controller: angular.noop,
            controllerAs: '$ctrl',
            template: `
                <pf-export-profile
                     md-panel-ref="$ctrl.mdPanelRef">
                </pf-export-profile>
            `,
            panelClass: 'pf-panel pf-sidenav-panel',
            attachTo: angular.element(document.body),
            position: position,
            animation: animation,
            hasBackdrop: true,
            trapFocus: true,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 70,
            onDomRemoved: () => {
                // Update application state
                this.$ngRedux.dispatch(panelClose());

                // Reset panel
                this.panel = undefined;

                // Make sure the panel is destroyed when closed
                panel.destroy();
            },
        };
        const panel = this.$mdPanel.create(config);

        return panel;
    }

    /**
     * Open the panel
     * @return {Promise<MdPanelRef>}
     */
    open() {
        // If panel is already open, close and destroy it, intended for mobile mode use
        if (this.panel) {
            return this.panel.close().then(()=>{
                this.panel = undefined;
            });
        }

        // Set panel reference
        this.panel = this._createPanel();

        // Hide any dialogs if they are open
        return this.$mdDialog.hide().then(() => {
            // Open the panel
            return this.panel.open().then(() => {
                // Initialize state
                return this.$ngRedux.dispatch(panelOpen());
            });
        });
    }
}

ExportProfilePanelService.$inject = ['$mdPanel', '$ngRedux', '$mdDialog', '$mdMedia'];
