import HeroImageTemplate from './hero-image.component.html';
import './hero-image.component.scss';

/**
 * @ngdoc controller
 * @name heroImageController
 * @param {Object} heroImage image to show
 * @module portfolium.component.heroImage
 **/
class HeroImageController {
    /**
      * @ngdoc method
      * @name isDefaultCoverImage
      * @description checks for default cover image
      * @return {Void||Boolean}
      */
    get isDefaultCoverImage() {
        if (!this.heroImage) {
            return undefined;
        }

        if (this.heroImage.source === null) {
            return true
        }

        return false;
    }

    /**
      * @ngdoc method
      * @name getCoverImage
      * @description checks for and returns a background-image object
      * @return {Void||Object} Default image returns empty object
      */
    get getCoverImage() {
        if (!this.heroImage) {
            return undefined;
        }

        if (this.isDefaultCoverImage) {
            return {};
        }

        return {
            'background-image': `url(${this.heroImage.url_https.replace(/ /g,"%20")})`,
        };
    }
}

/**
 * @ngdoc component
 * @name heroImageComponent
 * @module portfolium.component.heroImage
 **/
export const HeroImageComponent = {
    bindings: {
        'heroImage': '<',
    },
    bindToController: true,
    template: HeroImageTemplate,
    controller: HeroImageController,
    controllerAs: '$ctrl'
};
