/**
 * @ngdoc controller
 * @name ProfileQuickEditBtnController
 * @module portfolium.components.profileQuickEdit
 */
class ProfileQuickEditBtnController {
    constructor($pfProfileQuickEdit, $pfEventTracker) {
        this.$pfProfileQuickEdit = $pfProfileQuickEdit;
        this.$pfEventTracker = $pfEventTracker;
    }

    /**
     * Open the edit profile edit modal
     * @param  {Event}     $event     Event
     * @return {Promise}
     */
    editProfile($event) {
        // set the data to track
        const trackingProperties = {
            clickSource: this.section,
        };
        // sent the tracking info to the API
        this.$pfEventTracker.trackServer('Overview Edit Clicked', trackingProperties);
        // open the dialog to edit
        return this.$pfProfileQuickEdit.openDialog($event);
    }
}

ProfileQuickEditBtnController.$inject = [
    '$pfProfileQuickEdit',
    '$pfEventTracker',
];

export const ProfileQuickEditBtnComponent = {
    controller: ProfileQuickEditBtnController,
    bindings: {
        section: '<',
    },
    template: `
        <md-button md-no-ink
            class="pf-icon-text-button pf-outline pf-profile-quick-edit-btn"
            aria-label="Edit profile"
            ng-click="$ctrl.editProfile($event)">
           <md-icon>mode_edit</md-icon>{{ "Edit" | i18n }}
        </md-button>
    `,
};
