import SocialOnboardTemplate from './social-onboard.component.html';

/**
 * @ngdoc component
 * @name SocialOnboardController
 * @module portfolium.socialAuth
 */
class SocialOnboardController
{
    constructor($pfAuth, $pfToast, $pfSocialAuth) {
        this.$pfAuth = $pfAuth;
        this.$pfToast = $pfToast;
        this.$pfSocialAuth = $pfSocialAuth;
    }

    $onInit() {
        this.disabled = false;
        this.showError = false;

        if (!this.client) {
            throw new Error(`No social auth client found for '${this.client}'`);
        }

        this.socialAuthClient = this.$pfSocialAuth[this.client];
    }

    /**
     * Link a social account
     * @return {Promise}
     */
    link() {
        return this.socialAuthClient.login()
            .then((resp) => {
                return this.$pfSocialAuth.addSpoke(
                    this.client,
                    resp.token,
                    false,
                    null,
                    {id_token: resp.id_token}
                ).then(() => {
                    this.disabled = true;
                    // callback to proceed to next step
                    this.onLinkSuccess();
                }, ({ status }) => {
                    if (status === 409) {
                        this.showError = true;
                    }
                    this.$pfToast.error('Something went wrong, please try again');
                });
            });
    }
}

SocialOnboardController.$inject = ['$pfAuth', '$pfToast', '$pfSocialAuth'];

/**
 * @ngdoc component
 * @name pfSocialOnboard
 * @module portfolium.socialAuth
 * @description
 * Onboard with a social network
 * @param {String} client the name of the social network
 * @param {onLinkSuccess} cb - The callback that handles the linking acceptance
 */
export const SocialOnboardComponent = {
    bindings: {
        'client': '@pfSocialAuthClient',
        'onLinkSuccess': '&pfOnLinkSuccess',
    },
    controller: SocialOnboardController,
    controllerAs: '$ctrl',
    template: SocialOnboardTemplate,
};
