/**
  * @ngdoc service
  * @name MediaTypesService
  * @module portfolium.core
  */
export class MediaTypesService {
    constructor(
        PF_MEDIA_TYPES,
        $pfIdentifyMedia,
    ) {
        this.PF_MEDIA_TYPES = PF_MEDIA_TYPES;
        this.$pfIdentifyMedia = $pfIdentifyMedia;
    }

    get previewSupportedExtensions() {
        return this.PF_MEDIA_TYPES.imagePreview;
    }

    get mimetypes() {
        return this.PF_MEDIA_TYPES.supportedMimetypes;
    }

    get pdfMimetypes() {
        return this.filterMimetypeByType('pdf');
    }

    get wordMimetypes() {
        return this.filterMimetypeByType('word');
    }

    get excelMimetypes() {
        return this.filterMimetypeByType('excel');
    }

    get powerpointMimetypes() {
        return this.filterMimetypeByType('powerpoint');
    }

    get designMimetypes() {
        return this.filterMimetypeByType('design');
    }

    get audioMimetypes() {
        return this.filterMimetypeByType('audio');
    }

    get imageMimetypes() {
        return this.filterMimetypeByType('image');
    }

    get googleDocsMimetypes() {
        return _.map(this.PF_MEDIA_TYPES.googleDocsTypes, 'mimetype');
    }

    /**
     * Search  media types by supported mimetype and return the corresponding
     *  file type
     * @param  {String}         mimetype Mimetype of file
     * @return {String|Boolean}          Name of file type, or false
     */
    getEntryFieldByMimetype(mimetype) {
        const types = angular.copy(this.PF_MEDIA_TYPES.supportedMimetypes);

        const type = _.find(types, (typeObj) => {
            return typeObj.mimetypes.indexOf(mimetype.toLowerCase()) >= 0;
        });

        if (_.isEmpty(type)) {
            return false;
        }

        return type.name || false;
    }

    /**
     * Checks if a mimetype is an image
     * @param  {String}  mimetype
     * @return {Boolean}
     */
    isMimetypeImage(mimetype) {
        // get file type
        const type = this.getEntryFieldByMimetype(mimetype);
        // check if not design since this type also have image/ in
        // its mimetypes
        if (type === 'design') {
            return false;
        }
        // check if it is an image
        if (mimetype.toLowerCase().indexOf('image/') >= 0) {
            return true;
        }
        // is not an image for sure by now
        return false;
    }

    /**
     * Checks if an extension is valid for FileStack
     * to be converted as an image
     * @param  {String}  extension file extension
     * @return {Boolean}
     */
    isExtensionPreviewSupported(extension) {
        return this.previewSupportedExtensions.indexOf(extension) >= 0;
    }

    /**
     * Return mimetypes object for a type
     * @param  {String} type Types of files (word, excel, pdf, ...)
     * @return {Object}
     */
    filterMimetypeByType(type) {
        const filtered = _.filter(this.PF_MEDIA_TYPES.supportedMimetypes, { type: type });

        if (_.isEmpty(filtered)) {
            return [];
        }

        return filtered[0].mimetypes || [];
    }

    /**
     * check if a mime type is one of the Google drive types
     * @param  {String}  mimetype file mimetype
     * @return {Boolean}
     */
    isGoogleDocs(mimetype) {
        return this.googleDocsMimetypes.indexOf(mimetype) >= 0;
    }

    /**
     * If a filename does not have an extension, find its extension
     *     on google docs types and return its extension.
     * @param  {String}  filename file name
     * @param  {String}  mimetype file mimetype
     * @return {String}          [description]
     */
    getGoogleDocExtension(filename, mimetype) {
        const filenameSplit = filename.split('.');
        const extension = filenameSplit[filenameSplit.length - 1].toLowerCase();
        const fileType = _.filter(this.PF_MEDIA_TYPES.googleDocsTypes, {mimetype});

        // sanity check to see if found the mimetype in the
        // google docs type constant
        if (_.isEmpty(fileType[0])) {
            return '';
        }

        // sanity check to see if the extension found is different as
        // the extension found
        if (fileType[0].extension === extension) {
            return '';
        }

        // return the dot with the extension found
        return '.' + fileType[0].extension;
    }

    /**
     * Get extension of a filename
     * @param  {String} filename name of file
     * @return {String}          extension
     */
    getExtension(filename) {
        const filenameClean = filename.split('?')[0];
        const filenameSplit = filenameClean.split('.');
        const extension = filenameSplit[filenameSplit.length - 1].toLowerCase();

        return extension;
    }

    /**
     * Determines if an attachment is supported by FileStack to
     * craete image preview
     * @return {Boolean}
     */
    isFSPreviewSupported(filename) {
        const extension = this.getExtension(filename);
        const isSupported = this.PF_MEDIA_TYPES.imagePreview.indexOf(extension) >= 0;

        return isSupported;
    }

    /**
     * Determines if an attachment is supported by FileStack to
     * craete iframe preview
     * @param {String} type type of the attachment
     * @return {Boolean}
     */
    isFSIframeSupported(filename) {
        const extension = this.getExtension(filename);
        const isSupported = this.PF_MEDIA_TYPES.iFrameSupportedExtensions.indexOf(extension) >= 0;

        return isSupported;
    }

    /**
     * Determines if a filename is an image supported
     * to display in html img tag
     * @param  {String}  filename
     * @return {Boolean}
     */
    isPreviewSupported(filename) {
        const extension = this.getExtension(filename);
        if (_.isEmpty(extension)) {
            return false;
        }
        const isSupported = this.PF_MEDIA_TYPES.imgHTMLSupportedExtensions.indexOf(extension) >= 0;

        return isSupported;
    }
}

MediaTypesService.$inject = [
    'PF_MEDIA_TYPES',
    '$pfIdentifyMedia',
];
