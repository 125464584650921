import { combineReducers } from 'redux';
import { assignments } from './course-assignments/course-assignments.reducer';

import {
    COURSE_SET,
    COURSE_RESET,
} from './course.actions';

const initialState = {};

export const meta = (state = initialState, action = {}) => {
    switch (action.type) {
        case COURSE_SET:
            const { course } = action.payload;
            return _.assign({}, state, course);
        case COURSE_RESET:
            return _.assign({}, initialState);
        default:
            return state;
    }
};

export const course = combineReducers({
    meta,
    assignments,
});
