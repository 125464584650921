export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const MARK_ALL_NOTIFICATIONS_AS_READ = 'MARK_ALL_NOTIFICATIONS_AS_READ';
export const UPDATE_UNREAD_NOTIFICATIONS = 'UPDATE_UNREAD_NOTIFICATIONS';
export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS';
export const CLEAR_NOTIFICATIONS_BY_TYPE_AND_STATUS = 'CLEAR_NOTIFICATIONS_BY_TYPE_AND_STATUS';

export const addNotifications = (payload) => {
    return {
        type: ADD_NOTIFICATIONS,
        payload,
    };
};

export const markNotificationAsRead = (payload) => {
    return {
        type: MARK_NOTIFICATION_AS_READ,
        payload,
    };
};

export const markAllNotificationsAsRead = () => {
    return {
        type: MARK_ALL_NOTIFICATIONS_AS_READ,
    };
};

export const updateUnreadNotifications = (payload) => {
    return {
        type: UPDATE_UNREAD_NOTIFICATIONS,
        payload,
    };
};

export const updateNotificationStatus = (payload) => {
    return {
        type: UPDATE_NOTIFICATION_STATUS,
        payload,
    };
};

export const clearNotificationsByTypeAndStatus = (payload) => {
    return {
        type: CLEAR_NOTIFICATIONS_BY_TYPE_AND_STATUS,
        payload,
    };
};
