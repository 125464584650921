/**
 * @ngdoc component
 * @name UserCardController
 * @module portfolium.components.userCard
 * @description This controls all user card components that have a user object bound to them
 **/
export class UserCardController {
    /**
     * Is the user data available yet?
     * @return {boolean}
     */
    hasUserData() {
        return !_.isEmpty(this.user);
    }

    /**
     * Does the passed in user have a major set?
     * @return {undefined|boolean}
     */
    hasMajor() {
        if (!this.hasUserData()) {
            return undefined;
        }

        return !_.isNil(this.user.major);
    }

    /**
     * Does the passed in user have a school set?
     * @return {undefined|boolean}
     */
    hasSchool() {
        if (!this.hasUserData()) {
            return undefined;
        }

        return !_.isNil(this.user.school);
    }

    /**
     * Does the passed in user have a projects count?
     * @return {undefined|boolean}
     */
    hasProjects() {
        if (!this.hasUserData()) {
            return undefined;
        }

        if (_.isArray(this.user.entries)) {
            return false;
        }

        return !_.isNil(this.user.entries);
    }

    /**
     * Does the passed in user have a skills count?
     * @return {undefined|boolean}
     */
    hasSkills() {
        if (!this.hasUserData()) {
            return undefined;
        }

        if (_.isArray(this.user.skills)) {
            return false;
        }

        return !_.isNil(this.user.skills);
    }

    /**
     * Does the passed in user have a connections count?
     * @return {undefined|boolean}
     */
    hasConnections() {
        if (!this.hasUserData()) {
            return undefined;
        }

        if (_.isArray(this.user.connections)) {
            return false;
        }

        return !_.isNil(this.user.connections);
    }

    /**
     * Is current user connected to passed in user
     * @return {undefined|boolean}
     */
    isConnected() {
        if (!this.hasUserData()) {
            return undefined;
        }

        if (_.isNil(this.user.connected)) {
            return false;
        }

        return this.user.connected === '1';
    }

    /**
     * Should user stats be shown?
     * @return {boolean}
     */
    showUserStats() {
        return !_.isNil(this.showStats) && this.showStats === true;
    }
}

